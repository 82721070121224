// @flow

import React, { PureComponent } from 'react';
import type { RouterHistory } from 'react-router-dom';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { colors } from 'shared/styleguide';
import Table from 'shared/components/common/Table';
import Button from 'shared/components/common/Button';
import Pill from 'shared/components/common/Pill';

type Props = {
  history: RouterHistory,
  data: {
    id: string,
    itemNumber: string,
    name: string,
    quantity: number,
    quantityCompleted: number,
  }[],
};

type State = {
  listView: boolean,
};

const ButtonWrapper = styled.div`
  text-align: right;
  padding-bottom: 16px;
`;

class ItemProgress extends PureComponent<Props, State> {
  state = {
    listView: false,
  };

  handleToggleView = () => {
    const { listView } = this.state;

    this.setState({
      listView: !listView,
    });
  };

  render() {
    const { history, data } = this.props;
    const { listView } = this.state;

    return (
      <div>
        <ButtonWrapper>
          <Button onClick={this.handleToggleView} width="auto">
            {listView
              ? i18n.t('Switch to Bar View')
              : i18n.t('Switch to List View')}
          </Button>
        </ButtonWrapper>
        {listView ? (
          <Table
            data={data}
            onRowClick={rowData => history.push(`/item/${rowData.id}`)}
            columns={[
              {
                Header: i18n.t('Inventory #'),
                accessor: 'itemNumber',
                width: 160,
                headerStyle: {
                  textAlign: 'center',
                },
                Cell: ({ value }: *) => <Pill>{value}</Pill>,
              },
              {
                Header: i18n.t('Item Name'),
                accessor: 'name',
              },
              {
                Header: i18n.t('Quantity to Produce'),
                accessor: 'quantity',
                width: 160,
                Cell: ({ value }) =>
                  i18n.t('{{quantity, number}}', {
                    quantity: value,
                  }),
              },
              {
                Header: i18n.t('Quantity Produced'),
                accessor: 'quantityCompleted',
                width: 160,
                Cell: ({ value }) =>
                  i18n.t('{{quantity, number}}', {
                    quantity: value,
                  }),
              },
              {
                Header: i18n.t('% Complete'),
                accessor: 'quantity',
                width: 160,
                Cell: ({ row }) =>
                  Math.round((row.quantityCompleted / row.quantity) * 100) +
                  '%',
              },
            ]}
          />
        ) : (
          <ResponsiveContainer
            minHeight={250}
            height={Math.max(data.length * 80, 250)}
          >
            <BarChart
              layout="vertical"
              data={
                data.length
                  ? data.map(record => ({
                      name: record.name,
                      // 'Quantity to Produce' here is used to determine the actual Bar's size, not what's on the label
                      'Quantity to Produce':
                        record.quantity - record.quantityCompleted >= 0
                          ? record.quantity - record.quantityCompleted
                          : 0,
                      'Quantity Produced': record.quantityCompleted,
                      'Total Quantity to Produce': record.quantity,
                    }))
                  : [
                      {
                        name: 'No Data Available for Selected Dates',
                        'Quantity to Produce': 0,
                        'Quantity Produced': 0,
                        'Total Quantity to Produce': 0,
                      },
                    ]
              }
              width={500}
            >
              <XAxis
                type="number"
                tickFormatter={tick =>
                  i18n.t('{{value, number}}', { value: tick })
                }
                tick={{ fontSize: 14 }}
              />
              <YAxis
                dataKey="name"
                type="category"
                width={200}
                tick={{ fontSize: 14 }}
              />
              <Legend align="right" />
              <Tooltip
                cursor={{ fill: colors.veryLightBlue }}
                formatter={(value, name, { payload }) => {
                  if (
                    payload['Quantity Produced'] === 0 &&
                    payload['Total Quantity to Produce'] === 0
                  ) {
                    if (
                      name === 'Quantity Produced' ||
                      name === 'Quantity to Produce'
                    ) {
                      return 0;
                    }

                    return payload['name'];
                  } else if (name === 'Quantity Produced') {
                    const percentage = Math.round(
                      (payload['Quantity Produced'] /
                        payload['Total Quantity to Produce']) *
                        100,
                    );

                    return [
                      i18n.t('{{value, number}} ({{percentage, number}}%)', {
                        value,
                        percentage,
                      }),
                      name,
                    ];
                  }

                  // display the value of 'Total Quantity to Produce' as 'Quantity to Produce' to the user
                  // the actual field name of 'Total Quantity to Produce' is not displayed
                  return [
                    i18n.t('{{totalQty, number}}', {
                      totalQty: payload['Total Quantity to Produce'],
                    }),
                    'Quantity to Produce',
                  ];
                }}
              />
              <Bar
                dataKey="Quantity Produced"
                fill={colors.sapphire}
                barSize={20}
                stackId="a"
              />
              <Bar
                dataKey="Quantity to Produce"
                fill={colors.coolGrey}
                barSize={20}
                stackId="a"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}

export default ItemProgress;

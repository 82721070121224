// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  type DropResult,
  type DroppableProvided,
  type DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { i18n } from 'shared/utils';
import { colors } from 'shared/styleguide';
import Button from 'shared/components/common/Button';
import AddUpdateMachineModal from 'main-app/components/AddUpdateMachineModal';
import DeleteMachineModal from 'main-app/components/DeleteMachineModal';
import UpdateMachine from 'main-app/mutations/UpdateMachine';
import type { MachineBoard_machineEdges as MachineEdgesFragment } from './__generated__/MachineBoard_machineEdges';
import MachineCard from './MachineCard';

type Props = {
  machineEdges: Array<?MachineEdgesFragment>,
};

type State = {
  machines: any[],
  addModalOpen: boolean,
  updateModalRecord: ?Object,
  deleteModalRecord: Object,
};

const ButtonWrapper = styled.div`
  padding: 16px 16px 24px 16px;

  @media (min-width: 1000px) {
    align-self: flex-end;
    padding: 0 0 24px;
  }
`;

const DropArea = styled.div`
  background-color: ${({ isDraggingOver }) =>
    isDraggingOver ? colors.paleGreyTwo : 'transparent'};
  display: flex;
  flex-direction: column;
  transition: background-color 0.1s ease;
  user-select: none;
  min-height: 300px;
  padding-bottom: 50px;
`;

class MachineBoard extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      machines: props.machineEdges,
      addModalOpen: false,
      updateModalRecord: null,
      deleteModalRecord: null,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { machineEdges } = this.props;

    if (prevProps.machineEdges !== machineEdges) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        machines: machineEdges,
      });
    }
  }

  handleDragStart = () => {};

  handleDragEnd = (result: DropResult) => {
    const { machines } = this.state;
    const { source, destination } = result;

    // If didn't move anywhere, bail early
    if (!destination || source.index === destination.index) {
      return;
    }

    const updatedMachines = Array.from(machines);
    const [target] = updatedMachines.splice(source.index, 1);

    updatedMachines.splice(destination.index, 0, target);

    this.setState(
      {
        machines: updatedMachines,
      },
      () => this.handlePersistState(target, updatedMachines, destination),
    );
  };

  handlePersistState = async (target, machines, destination) => {
    const mutationInput: any = {
      id: target.node.id,
    };

    if (machines.length === 1) {
      mutationInput.productionOrder = 1000;
    } else if (destination.index === 0) {
      mutationInput.productionOrder =
        machines[destination.index + 1].node.productionOrder - 0.5;
    } else if (destination.index === machines.length - 1) {
      mutationInput.productionOrder =
        machines[machines.length - 2].node.productionOrder + 0.5;
    } else {
      const before = machines[destination.index - 1].node.productionOrder;
      const after = machines[destination.index + 1].node.productionOrder;
      mutationInput.productionOrder = (before + after) / 2;
    }

    return UpdateMachine.commit({
      variables: {
        input: mutationInput,
      },
    });
  };

  handleOpenUpdateModal = (machine: Object) => {
    this.setState({
      updateModalRecord: machine,
    });
  };

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleOpenDeleteModal = (machine: Object) => {
    this.setState({
      deleteModalRecord: machine,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      updateModalRecord: null,
      deleteModalRecord: null,
    });
  };

  render() {
    const {
      machines,
      addModalOpen,
      updateModalRecord,
      deleteModalRecord,
    } = this.state;

    return (
      <>
        <ButtonWrapper>
          <Button onClick={this.handleOpenCreateModal} theme="border-white">
            {i18n.t('Create Work Center')}
          </Button>
        </ButtonWrapper>
        <DragDropContext
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
        >
          <Droppable droppableId="machines">
            {(
              dropProvided: DroppableProvided,
              dropSnapshot: DroppableStateSnapshot,
            ) => (
              <DropArea
                isDraggingOver={dropSnapshot.isDraggingOver}
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
              >
                {machines.map((machine, i) => (
                  <MachineCard
                    index={i}
                    key={machine.node.id}
                    machine={machine.node}
                    onUpdate={this.handleOpenUpdateModal}
                    onDelete={this.handleOpenDeleteModal}
                  />
                ))}
                {dropProvided.placeholder}
              </DropArea>
            )}
          </Droppable>
        </DragDropContext>
        {addModalOpen && (
          <AddUpdateMachineModal
            onClose={this.handleCloseAllModals}
            machine={null}
          />
        )}
        {updateModalRecord && (
          <AddUpdateMachineModal
            onClose={this.handleCloseAllModals}
            machine={updateModalRecord}
          />
        )}
        {deleteModalRecord && (
          <DeleteMachineModal
            onClose={this.handleCloseAllModals}
            machine={deleteModalRecord}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(MachineBoard, {
  machineEdges: graphql`
    fragment MachineBoard_machineEdges on MachineEdge @relay(plural: true) {
      node {
        id
        name
        productionOrder
        type {
          id
          name
        }
        ...MachineCard_machine
      }
    }
  `,
});

// @flow

// TODO: Bring back commented out code when Transition Actions are ready
// Without the commented code, this component only carries the
// deletion logic for Transitions
import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import Panel from 'shared/components/common/Panel';
// import styled from 'styled-components';
import Icon from 'shared/components/common/Icon';
// import { fonts, colors } from 'shared/styleguide';
// import {
//   Formik,
//   Form,
//   FieldArray,
//   SelectUserField,
//   SelectTagField,
// } from 'shared/components/form';
// import * as Yup from 'yup';
// import AddTagMutation from 'main-app/mutations/AddTag';
// import Button from 'shared/components/common/Button';
import * as Actions from 'main-app/store/Actions';
// import Divider from 'shared/components/common/Divider';
import DeleteRecordModal from '../Modals/DeleteRecordModal';

type Props = {
  onClose: () => void,
  link: *,
  panelStyle: Object,
};

type State = {
  // showSelectUserField: boolean,
  showDeleteRecordModal: boolean,
};

// TODO: add this back in when Transition Actions are ready
// const FormContent = styled.div`
//   height: 100%;
//   overflow-y: auto;
// `;

// const Text = styled.div`
//   ${fonts.bodyRegular}
//   margin-bottom: 12px;
// `;

// const PanelButton = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   height: 48px;
//   margin-bottom: 16px;
//   border: solid 1px ${colors.lightBlueGrey};
//   background-color: ${colors.white};
//   ${fonts.bodyBold}
//   color: ${colors.azul};

//   &:hover, svg:hover {
//     cursor: pointer;
//   }

//   &:last-child {
//     margin-bottom: 0;
//   }
// `;

// const TagRow = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   &:first-child {
//     margin-top: 16px;
//   }
// `;

// const SectionLabel = styled.div`
//   ${fonts.bodyBold}
//   padding-bottom: 16px;
// `;

class AddUpdateActionPanel extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // showSelectUserField: Boolean(props.link.data?.defaultUser?.value),
      showDeleteRecordModal: false,
    };
  }

  // handleShowSelectUserField = () => {
  //   this.setState({ showSelectUserField: true });
  // };

  handleShowDeleteRecordModal = () => {
    this.setState({ showDeleteRecordModal: true });
  };

  handleCloseDeleteRecordModal = (cb?: () => void) => {
    this.setState({ showDeleteRecordModal: false }, () => {
      if (cb) {
        cb();
      }
    });
  };

  handleDeleteLink = () => {
    const { link, onClose } = this.props;

    link.remove();
    this.handleCloseDeleteRecordModal(onClose);
    Actions.alertNotification(
      {
        title: 'Transition Deleted Successfully!',
        body: null,
      },
      'success',
    );
  };

  render() {
    const { onClose, panelStyle } = this.props;
    const { showDeleteRecordModal } = this.state;
    // TODO: add this back in when Transition Actions are ready
    // const { onClose, link, panelStyle } = this.props;
    // const { showSelectUserField, showDeleteRecordModal } = this.state;
    // const addTags =
    //   (link.data?.addTags?.length && link.data.addTags.map(tag => tag)) || [];
    // const removeTags =
    //   (link.data?.removeTags?.length && link.data.removeTags.map(tag => tag)) ||
    //   [];

    return (
      <Panel
        title={i18n.t('Action Settings')}
        headerRight={
          <>
            <Icon
              type="circle-trash"
              size={24}
              onClick={this.handleShowDeleteRecordModal}
              style={{
                marginRight: 12,
              }}
            />
            <Icon
              type="circle-close"
              size={24}
              onClick={() => {
                onClose();
              }}
            />
          </>
        }
        style={{
          ...panelStyle,
        }}
      >
        {/* TODO: add this back in when Transition Actions are ready */}
        {/* <Text>
          {i18n.t(
            'Actions that you create here will take place when a job transitions between these two states.',
          )}
        </Text>
        <Formik
          initialValues={{
            defaultUser: link.data?.defaultUser?.value
              ? {
                  data: link.data?.defaultUser?.data || {
                    id: link.data?.defaultUser?.value,
                  },
                  value: link.data?.defaultUser?.value,
                }
              : null,
            addTags,
            removeTags,
          }}
          validationSchema={Yup.object().shape({
            defaultUser: Yup.object().nullable(),
            addTags: Yup.array().of(
              Yup.object().shape({
                value: Yup.string().required('Required'),
              }),
            ),
            removeTags: Yup.array().of(
              Yup.object().shape({
                value: Yup.string().required('Required'),
              }),
            ),
          })}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            try {
              setSubmitting(true);

              values.addTags.forEach(async (tag: Object) => {
                if (tag.__isNew__) {
                  const newTag = await AddTagMutation.commit({
                    variables: {
                      input: {
                        name: tag.value,
                        type: 'JOB',
                      },
                    },
                  });

                  tag.value = newTag.addTag.tagEdge.node.id; // eslint-disable-line require-atomic-updates
                  delete tag.__isNew__;
                }
              });

              link.data = { ...link.data, ...values };

              const totalActions =
                link.data.addTags.length +
                link.data.removeTags.length +
                (link.data.defaultUser?.value ? 1 : 0);

              link.removeLabels();
              link.addLabel(totalActions || '+');
              onClose();
              Actions.alertNotification(
                {
                  title: i18n.t('Transition Actions Sucessfully Updated'),
                  body: i18n.t(
                    'Please remember to click "Publish Workflow" to save any changes.',
                  ),
                },
                'success',
              );
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(
                e.message,
                i18n.t('Something Went Wrong'),
              );
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              style={{
                position: 'relative',
                paddingBottom: 52,
                height: 'calc(100% - 118px)',
              }}
            >
              <FormContent>
                {showSelectUserField ? (
                  <SelectUserField
                    name="defaultUser"
                    label={i18n.t('User to Notify')}
                    error={errors.defaultUser}
                  />
                ) : (
                  <PanelButton onClick={this.handleShowSelectUserField}>
                    <Icon
                      type="circle-plus"
                      size={24}
                      style={{ margin: '12px 16px' }}
                    />
                    {i18n.t('Add Notification')}
                  </PanelButton>
                )}
                <FieldArray
                  name="addTags"
                  render={(arrayHelpers, errors) => (
                    <>
                      {!values.addTags?.length ? null : (
                        <>
                          <Divider style={{ marginTop: 8, marginBottom: 24 }} />
                          <SectionLabel>
                            {i18n.t('Tags to be Added to the Job')}
                          </SectionLabel>
                          {values.addTags.map((tag, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TagRow key={`add-tag-${i}`}>
                              <Icon
                                type="circle-minus"
                                size={24}
                                onClick={() => arrayHelpers.remove(i)}
                                style={{ marginRight: 16, marginBottom: 16 }}
                              />
                              <SelectTagField
                                name={`addTags.${i}`}
                                creatable
                                clearable
                                label={null}
                                error={
                                  errors?.addTags[i] === 'string'
                                    ? errors?.addTags[i]
                                    : null
                                }
                                creatingNewRecord={
                                  values.addTags[i] &&
                                  values.addTags[i]?.__isNew__
                                }
                              />
                            </TagRow>
                          ))}
                        </>
                      )}
                      <PanelButton
                        onClick={() =>
                          arrayHelpers.push({
                            tag: null,
                          })
                        }
                      >
                        <Icon
                          type="circle-plus"
                          size={24}
                          style={{ margin: '12px 16px' }}
                        />
                        {i18n.t('Add Tag')}
                      </PanelButton>
                    </>
                  )}
                />
                <FieldArray
                  name="removeTags"
                  render={(arrayHelpers, errors) => (
                    <>
                      {!values.removeTags?.length ? null : (
                        <>
                          <Divider
                            style={{ marginTop: 16, marginBottom: 24 }}
                          />
                          <SectionLabel>
                            {i18n.t('Tags to be Removed from the Job')}
                          </SectionLabel>
                          {values.removeTags.map((tag, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <TagRow key={`add-tag-${i}`}>
                              <Icon
                                type="circle-minus"
                                size={24}
                                onClick={() => arrayHelpers.remove(i)}
                                style={{ marginRight: 16, marginBottom: 16 }}
                              />
                              <SelectTagField
                                name={`removeTags.${i}`}
                                clearable
                                label={null}
                                error={
                                  errors?.removeTags[i] === 'string'
                                    ? errors?.removeTags[i]
                                    : null
                                }
                              />
                            </TagRow>
                          ))}
                        </>
                      )}
                      <PanelButton
                        onClick={() =>
                          arrayHelpers.push({
                            tag: null,
                          })
                        }
                      >
                        <Icon
                          type="circle-plus"
                          size={24}
                          style={{ margin: '12px 16px' }}
                        />
                        {i18n.t('Remove Tag')}
                      </PanelButton>
                    </>
                  )}
                />
                <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                  <Divider style={{ marginBottom: 16 }} />
                  <Button
                    type="submit"
                    theme="blue"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    width="100%"
                  >
                    {i18n.t('Save')}
                  </Button>
                </div>
              </FormContent>
            </Form>
          )}
        /> */}
        {showDeleteRecordModal && (
          <DeleteRecordModal
            onDeleteRecord={this.handleDeleteLink}
            onClose={this.handleCloseDeleteRecordModal}
            type="link"
          />
        )}
      </Panel>
    );
  }
}

export default AddUpdateActionPanel;

// @flow

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';

const fadeAnimation = keyframes`
  0%, 100% { 
    opacity: 0;
  }
  
  50% { 
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  padding: 24px 0;
  text-align: center;
  ${fonts.bodyRegular};
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${colors.battleshipGrey};
  animation: ${fadeAnimation} 2s infinite ease-in-out both;
  transform: translateZ(0);
`;

export default () => <Wrapper>{i18n.t('Loading More Results...')}</Wrapper>;

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import { QueryRenderer, graphql } from 'react-relay';
import {
  Route,
  Switch,
  Redirect,
  type Match,
  type RouterHistory,
} from 'react-router-dom';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { i18n } from 'shared/utils';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import PageDetailsSummary from 'main-app/components/PageDetailsSummary';
import Icon from 'shared/components/common/Icon';
import InfoWidget from 'shared/components/common/InfoWidget';
import TabNav from 'shared/components/common/TabNav';
import Loader from 'shared/components/common/Loader';
import TabContentWrapper from 'shared/components/common/TabContent/Wrapper';
import MainContent from 'shared/components/common/TabContent/MainContent';
import AddUpdateItemModal from 'main-app/components/AddUpdateItemModal';
import DeleteItemModal from 'main-app/components/DeleteItemModal';
import ItemJobs from './ItemJobs';
import ItemLinkedJobs from './ItemLinkedJobs';
import ItemSubComponentsTree from './ItemSubComponentsTree';
import ItemAttachments from './ItemAttachments';
import ItemSidePanel from './ItemSidePanel';
import ItemTransactionLog from './ItemTransactionLog';
import ItemLots from './ItemLots';

type Props = {
  history: RouterHistory,
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

type State = {
  updateItemModalOpen: boolean,
  deleteItemModalOpen: boolean,
};

class Item extends PureComponent<Props, State> {
  menuTriggerRef = null;

  state = {
    updateItemModalOpen: false,
    deleteItemModalOpen: false,
  };

  handleOpenUpdateItemModal = () => {
    this.setState({
      updateItemModalOpen: true,
    });
  };

  handleOpenDeleteItemModal = () => {
    this.setState({
      deleteItemModalOpen: true,
    });
  };

  handleDeleteSuccess = () => {
    const { history } = this.props;

    history.replace('/items');
  };

  handleCloseAllModals = () => {
    this.setState({
      updateItemModalOpen: false,
      deleteItemModalOpen: false,
    });
  };

  render() {
    const { match, history } = this.props;
    const { updateItemModalOpen, deleteItemModalOpen } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query ItemQuery($id: ID!) {
            item(id: $id) {
              id
              name
              itemNumber
              createdAt
              lotTracking
              totalQty
              safetyQuantity
              totalValue
              quantityUOM {
                id
                name
              }
              childItems(first: null)
                @connection(key: "Item_childItems", filters: []) {
                edges {
                  ...ItemSubComponentsTree_itemChildEdges
                }
              }
              lots(first: null) @connection(key: "Item_lots", filters: []) {
                edges {
                  ...ItemLots_itemLotEdges
                }
              }
              ...ItemSidePanel_item
              ...AddUpdateItemModal_item
              ...DeleteItemModal_item
              ...ItemTransactionLog_item
              ...AddItemMovementModal_item
            }

            viewer {
              id
              permissions
            }

            forms(types: [ITEM]) {
              edges {
                node {
                  id
                  type
                  ...AddUpdateItemModal_defaultForm
                }
              }
            }
          }
        `}
        variables={{
          id: match.params.id,
        }}
        render={query => (
          <Page>
            <PageHeader
              intro={i18n.t('INVENTORY')}
              title={
                query.props
                  ? query.props.item.name
                  : i18n.t('Details Loading...')
              }
              right={
                query.props && [
                  <ContextMenuTrigger
                    id="item-details-menu"
                    ref={r => (this.menuTriggerRef = r)}
                  >
                    <Icon
                      type="circle-context-menu"
                      onClick={e => {
                        if (this.menuTriggerRef) {
                          this.menuTriggerRef.handleContextClick(e);
                        }
                      }}
                    />
                  </ContextMenuTrigger>,
                ]
              }
            />
            {!query.props ? (
              <Loader />
            ) : (
              <>
                <PageDetailsSummary
                  items={[
                    <InfoWidget
                      title={i18n.t('Inventory #')}
                      content={query.props.item.itemNumber}
                    />,
                    <InfoWidget
                      title={i18n.t('Total Quantity')}
                      content={
                        <>
                          {(query.props.item.totalQty &&
                            i18n.t('{{quantity, number}}', {
                              quantity: query.props.item.totalQty,
                            })) ||
                            0}
                          {query.props.item.totalQty <
                          query.props.item.safetyQuantity ? (
                            <span style={{ color: 'red' }}> (low)</span>
                          ) : null}
                        </>
                      }
                    />,
                    <InfoWidget
                      title={i18n.t('Total Value')}
                      content={
                        (query.props.item.totalValue &&
                          i18n.t('{{quantity, number}}', {
                            quantity: query.props.item.totalValue,
                          })) ||
                        0
                      }
                    />,
                    <InfoWidget
                      title={i18n.t('Created')}
                      content={moment(query.props.item.createdAt).format(
                        'MM/DD/YYYY',
                      )}
                    />,
                  ]}
                />
                <TabNav
                  history={history}
                  activePath={match.url}
                  tabs={[
                    {
                      label: i18n.t('Ledger'),
                      path: `/item/${match.params.id}`,
                    },
                    {
                      label: i18n.t('Lots'),
                      path: `/item/${match.params.id}/lots`,
                    },
                    {
                      label: i18n.t('Last Jobs'),
                      path: `/item/${match.params.id}/jobs`,
                    },
                    {
                      label: i18n.t('Last Tooling'),
                      path: `/item/${match.params.id}/linked-jobs`,
                    },
                    {
                      label: i18n.t('Components'),
                      path: `/item/${match.params.id}/components`,
                    },
                    {
                      label: i18n.t('Attachments'),
                      path: `/item/${match.params.id}/attachments`,
                    },
                  ]}
                />
                <TabContentWrapper>
                  <ItemSidePanel item={query.props?.item} />
                  <MainContent>
                    <Switch>
                      <Route
                        exact
                        path="/item/:id"
                        render={props => (
                          <ItemTransactionLog
                            {...props}
                            history={history}
                            item={query.props?.item}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/item/:id/lots"
                        render={props => (
                          <ItemLots
                            {...props}
                            item={query.props?.item}
                            itemUOM={query.props.item.quantityUOM.name}
                            itemId={query.props.item.id}
                            itemLotEdges={query.props?.item?.lots?.edges}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/item/:id/jobs"
                        render={props => (
                          <ItemJobs {...props} itemId={query.props.item.id} />
                        )}
                      />
                      <Route
                        exact
                        path="/item/:id/linked-jobs"
                        render={props => (
                          <ItemLinkedJobs
                            {...props}
                            itemId={query.props.item.id}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/item/:id/components"
                        render={props => (
                          <ItemSubComponentsTree
                            {...props}
                            parentItemId={query.props.item.id}
                            itemChildEdges={query.props.item.childItems.edges}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/item/:id/attachments"
                        component={ItemAttachments}
                      />
                      <Route
                        exact
                        render={() => (
                          <Redirect to={`/item/${match.params.id}`} />
                        )}
                      />
                    </Switch>
                  </MainContent>
                </TabContentWrapper>
                <ContextMenu id="item-details-menu">
                  {query.props.viewer.permissions.includes('UPDATE_ITEM') && (
                    <MenuItem onClick={this.handleOpenUpdateItemModal}>
                      {i18n.t('Edit Item')}
                    </MenuItem>
                  )}
                  <MenuItem onClick={this.handleOpenDeleteItemModal}>
                    {i18n.t('Delete Item')}
                  </MenuItem>
                </ContextMenu>
                {updateItemModalOpen && (
                  <AddUpdateItemModal
                    onClose={this.handleCloseAllModals}
                    item={query.props.item}
                    defaultForm={query.props.forms.edges[0]?.node || null}
                  />
                )}
                {deleteItemModalOpen && (
                  <DeleteItemModal
                    history={history}
                    item={query.props.item}
                    onClose={this.handleCloseAllModals}
                    onSuccess={this.handleDeleteSuccess}
                  />
                )}
              </>
            )}
          </Page>
        )}
      />
    );
  }
}

export default Item;

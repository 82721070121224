// @flow

import React, { PureComponent } from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';

type Props = {
  align: 'left' | 'right',
  visible: boolean,
  onClickAway?: () => void,
  children: React$Node,
};

const Wrapper = styled.div`
  position: fixed;
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 4px rgba(106, 109, 123, 0.2);
  top: 0;
  left: ${p => (p.align === 'left' ? 0 : 'auto')};
  right: ${p => (p.align === 'right' ? 0 : 'auto')};
  height: 100%;
  width: 100%;
  z-index: 2; /* FIXME: we shouldn't have this here */
  transition: transform 100ms;
  padding: 0 16px 16px 16px;
  overflow-y: auto;

  @media (min-width: 1000px) {
    width: 450px;
  }
`;

const transitionStyles: any = {
  entering: {
    transform: 'translateX(100%)',
  },
  entered: {
    transform: 'translateX(0)',
  },
  exiting: {
    transform: 'translateX(0)',
  },
  exited: {
    transform: 'translateX(100%)',
  },
};

class SlideInPanel extends PureComponent<Props> {
  static defaultProps = {
    onClickAway: undefined,
  };

  ref = null;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickAway);
    document.addEventListener('touchend', this.handleClickAway);
  }

  componentWillUnmount() {
    this.teardownClickAwayListeners();
  }

  teardownClickAwayListeners = () => {
    document.removeEventListener('mousedown', this.handleClickAway);
    document.removeEventListener('touchend', this.handleClickAway);
  };

  handleClickAway = (e: Event) => {
    const { onClickAway } = this.props;

    if (onClickAway && this.ref && !this.ref.contains(e.target)) {
      onClickAway();
    }
  };

  render() {
    const { visible, children, align } = this.props;

    return (
      <Transition in={visible} timeout={100} unmountOnExit>
        {state => (
          <Wrapper
            ref={r => (this.ref = r)}
            align={align}
            style={transitionStyles[state]}
          >
            {children}
          </Wrapper>
        )}
      </Transition>
    );
  }
}

export default SlideInPanel;

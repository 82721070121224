/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ApplicationType = "MAIN_APP" | "OPERATOR_PORTAL";
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
export type OverUnderRule = "ALLOW_OVERS" | "NO_OVERS" | "NO_UNDERS";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateJobYieldModal_jobYield$ref: FragmentReference;
declare export opaque type AddUpdateJobYieldModal_jobYield$fragmentType: AddUpdateJobYieldModal_jobYield$ref;
export type AddUpdateJobYieldModal_jobYield = {|
  +id: string,
  +quantity: number,
  +itemNumberUp: number,
  +brokerCustomerOrderNumber: ?string,
  +formFieldValues: any,
  +overUnderRule: ?OverUnderRule,
  +overQuantity: ?number,
  +item: {|
    +id: string,
    +quantityUOM: {|
      +id: string,
      +name: string,
      +symbol: string,
    |},
  |},
  +form: {|
    +id: string,
    +name: string,
    +type: FormType,
    +systemKey: ?string,
    +productionType: ?{|
      +id: string
    |},
    +fields: $ReadOnlyArray<{|
      +id: string,
      +systemKey: ?string,
      +type: FormFieldType,
      +name: string,
      +description: ?string,
      +placeholder: ?string,
      +isRequired: boolean,
      +isCreatable: boolean,
      +applicationType: ApplicationType,
      +displayOrder: number,
      +properties: ?any,
      +options: $ReadOnlyArray<{|
        +name: string,
        +value: string,
      |}>,
    |}>,
  |},
  +$refType: AddUpdateJobYieldModal_jobYield$ref,
|};
export type AddUpdateJobYieldModal_jobYield$data = AddUpdateJobYieldModal_jobYield;
export type AddUpdateJobYieldModal_jobYield$key = {
  +$data?: AddUpdateJobYieldModal_jobYield$data,
  +$fragmentRefs: AddUpdateJobYieldModal_jobYield$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemKey",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AddUpdateJobYieldModal_jobYield",
  "type": "JobYield",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "quantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "itemNumberUp",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "brokerCustomerOrderNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "formFieldValues",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "overUnderRule",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "overQuantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "item",
      "storageKey": null,
      "args": null,
      "concreteType": "Item",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "quantityUOM",
          "storageKey": null,
          "args": null,
          "concreteType": "UOM",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "symbol",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "form",
      "storageKey": null,
      "args": null,
      "concreteType": "Form",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "productionType",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductionType",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "fields",
          "storageKey": null,
          "args": null,
          "concreteType": "FormField",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "description",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "placeholder",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isRequired",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isCreatable",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "applicationType",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "displayOrder",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "properties",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "options",
              "storageKey": null,
              "args": null,
              "concreteType": "FormFieldOption",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "value",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd684ed3565b887b497b4200b901966a';
module.exports = node;

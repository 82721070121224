/**
 * @flow
 * @relayHash 3f628d82f0ebf096b76fc10896019fac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WorkflowProgressPanelQueryVariables = {|
  lineProgressStartAt: any,
  lineProgressEndAt: any,
|};
export type WorkflowProgressPanelQueryResponse = {|
  +metricsWorkflowProgress: $ReadOnlyArray<{|
    +name: string,
    +quantity: number,
    +quantityCompleted: number,
  |}>
|};
export type WorkflowProgressPanelQuery = {|
  variables: WorkflowProgressPanelQueryVariables,
  response: WorkflowProgressPanelQueryResponse,
|};
*/


/*
query WorkflowProgressPanelQuery(
  $lineProgressStartAt: DateTime!
  $lineProgressEndAt: DateTime!
) {
  metricsWorkflowProgress(startAt: $lineProgressStartAt, endAt: $lineProgressEndAt) {
    name
    quantity
    quantityCompleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "lineProgressStartAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lineProgressEndAt",
    "type": "DateTime!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "metricsWorkflowProgress",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "lineProgressEndAt"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "lineProgressStartAt"
      }
    ],
    "concreteType": "MetricsWorkflowProgress",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "quantity",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "quantityCompleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WorkflowProgressPanelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "WorkflowProgressPanelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "WorkflowProgressPanelQuery",
    "id": null,
    "text": "query WorkflowProgressPanelQuery(\n  $lineProgressStartAt: DateTime!\n  $lineProgressEndAt: DateTime!\n) {\n  metricsWorkflowProgress(startAt: $lineProgressStartAt, endAt: $lineProgressEndAt) {\n    name\n    quantity\n    quantityCompleted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '640fb0680e8e2df6d70271e9ad68077c';
module.exports = node;

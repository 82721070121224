/**
 * @flow
 * @relayHash 50c823fdaafc5eea0d3ed8410bb313d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AdditionalFieldInfo_formField$ref = any;
export type ApplicationType = "MAIN_APP" | "OPERATOR_PORTAL";
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
export type UOMType = "LENGTH" | "QUANTITY" | "WEIGHT";
export type UpdateItemInput = {|
  id: string,
  name?: ?string,
  upc?: ?string,
  partNumber?: ?string,
  itemCategoryId?: ?string,
  description?: ?string,
  quantityUomId?: ?string,
  dimensionUomId?: ?string,
  dimensionWidth?: ?number,
  dimensionLength?: ?number,
  dimensionHeight?: ?number,
  weightUomId?: ?string,
  weight?: ?number,
  formFieldValues?: ?any,
  lotTracking?: ?boolean,
  cost?: ?number,
  safetyQuantity?: ?number,
|};
export type UpdateItemMutationVariables = {|
  input: UpdateItemInput
|};
export type UpdateItemMutationResponse = {|
  +updateItem: {|
    +itemEdge: {|
      +node: {|
        +id: string,
        +itemNumber: string,
        +name: string,
        +upc: ?string,
        +partNumber: ?string,
        +description: ?string,
        +category: ?{|
          +id: string,
          +name: string,
        |},
        +quantityUOM: {|
          +id: string,
          +name: string,
          +type: UOMType,
          +symbol: string,
        |},
        +weightUOM: ?{|
          +id: string,
          +name: string,
          +type: UOMType,
          +symbol: string,
        |},
        +dimensionUOM: ?{|
          +id: string,
          +name: string,
          +type: UOMType,
          +symbol: string,
        |},
        +childItems: {|
          +edges: ?$ReadOnlyArray<?{|
            +node: {|
              +id: string,
              +name: string,
            |}
          |}>
        |},
        +form: {|
          +id: string,
          +name: string,
          +type: FormType,
          +systemKey: ?string,
          +productionType: ?{|
            +id: string
          |},
          +fields: $ReadOnlyArray<{|
            +id: string,
            +systemKey: ?string,
            +type: FormFieldType,
            +name: string,
            +description: ?string,
            +placeholder: ?string,
            +isRequired: boolean,
            +isCreatable: boolean,
            +applicationType: ApplicationType,
            +displayOrder: number,
            +properties: ?any,
            +options: $ReadOnlyArray<{|
              +name: string,
              +value: string,
              +id: string,
            |}>,
            +$fragmentRefs: AdditionalFieldInfo_formField$ref,
          |}>,
        |},
        +formFieldValues: any,
        +dimensionLength: ?number,
        +dimensionWidth: ?number,
        +dimensionHeight: ?number,
        +weight: ?number,
        +lotTracking: ?boolean,
        +cost: ?number,
        +totalValue: ?number,
      |}
    |}
  |}
|};
export type UpdateItemMutation = {|
  variables: UpdateItemMutationVariables,
  response: UpdateItemMutationResponse,
|};
*/


/*
mutation UpdateItemMutation(
  $input: UpdateItemInput!
) {
  updateItem(input: $input) {
    itemEdge {
      node {
        id
        itemNumber
        name
        upc
        partNumber
        description
        category {
          id
          name
        }
        quantityUOM {
          id
          name
          type
          symbol
        }
        weightUOM {
          id
          name
          type
          symbol
        }
        dimensionUOM {
          id
          name
          type
          symbol
        }
        childItems {
          edges {
            node {
              id
              name
            }
          }
        }
        form {
          id
          name
          type
          systemKey
          productionType {
            id
          }
          fields {
            id
            systemKey
            type
            name
            description
            placeholder
            isRequired
            isCreatable
            applicationType
            displayOrder
            properties
            options {
              name
              value
              id
            }
            ...AdditionalFieldInfo_formField
          }
        }
        formFieldValues
        dimensionLength
        dimensionWidth
        dimensionHeight
        weight
        lotTracking
        cost
        totalValue
      }
    }
  }
}

fragment AdditionalFieldInfo_formField on FormField {
  id
  name
  type
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upc",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "partNumber",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v4/*: any*/)
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "ItemCategory",
  "plural": false,
  "selections": (v8/*: any*/)
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v10/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "symbol",
    "args": null,
    "storageKey": null
  }
],
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "quantityUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": (v11/*: any*/)
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "weightUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": (v11/*: any*/)
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "dimensionUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": (v11/*: any*/)
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "childItems",
  "storageKey": null,
  "args": null,
  "concreteType": "ItemChildConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ItemChildEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "Item",
          "plural": false,
          "selections": (v8/*: any*/)
        }
      ]
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemKey",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "productionType",
  "storageKey": null,
  "args": null,
  "concreteType": "ProductionType",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "placeholder",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isRequired",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isCreatable",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicationType",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayOrder",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "properties",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "options",
  "storageKey": null,
  "args": null,
  "concreteType": "FormFieldOption",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "value",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/)
  ]
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "formFieldValues",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensionLength",
  "args": null,
  "storageKey": null
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensionWidth",
  "args": null,
  "storageKey": null
},
v28 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensionHeight",
  "args": null,
  "storageKey": null
},
v29 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weight",
  "args": null,
  "storageKey": null
},
v30 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lotTracking",
  "args": null,
  "storageKey": null
},
v31 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cost",
  "args": null,
  "storageKey": null
},
v32 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalValue",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateItem",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "itemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Item",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "form",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Form",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v10/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fields",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FormField",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v16/*: any*/),
                          (v10/*: any*/),
                          (v4/*: any*/),
                          (v7/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          {
                            "kind": "FragmentSpread",
                            "name": "AdditionalFieldInfo_formField",
                            "args": null
                          }
                        ]
                      }
                    ]
                  },
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateItemMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateItem",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "itemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Item",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "form",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Form",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v10/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fields",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FormField",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v16/*: any*/),
                          (v10/*: any*/),
                          (v4/*: any*/),
                          (v7/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateItemMutation",
    "id": null,
    "text": "mutation UpdateItemMutation(\n  $input: UpdateItemInput!\n) {\n  updateItem(input: $input) {\n    itemEdge {\n      node {\n        id\n        itemNumber\n        name\n        upc\n        partNumber\n        description\n        category {\n          id\n          name\n        }\n        quantityUOM {\n          id\n          name\n          type\n          symbol\n        }\n        weightUOM {\n          id\n          name\n          type\n          symbol\n        }\n        dimensionUOM {\n          id\n          name\n          type\n          symbol\n        }\n        childItems {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        form {\n          id\n          name\n          type\n          systemKey\n          productionType {\n            id\n          }\n          fields {\n            id\n            systemKey\n            type\n            name\n            description\n            placeholder\n            isRequired\n            isCreatable\n            applicationType\n            displayOrder\n            properties\n            options {\n              name\n              value\n              id\n            }\n            ...AdditionalFieldInfo_formField\n          }\n        }\n        formFieldValues\n        dimensionLength\n        dimensionWidth\n        dimensionHeight\n        weight\n        lotTracking\n        cost\n        totalValue\n      }\n    }\n  }\n}\n\nfragment AdditionalFieldInfo_formField on FormField {\n  id\n  name\n  type\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '153c4e67ea0d1f81addcf8ac1c83debd';
module.exports = node;

// @flow

import styled from 'styled-components';
import { lighten } from 'polished';
import { colors, fonts } from 'shared/styleguide';

export const MENU_WIDTH = 236;

export const Wrapper = styled.div`
  z-index: 2; /* FIXME: We normally don't use z-index in this codebase. Revisit when we figure out how to address z-indexing in react-calendar-timeline package */
  position: fixed;
  top: 0;
  max-width: ${MENU_WIDTH}px;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background: ${colors.white};
  border-right: 1px solid ${colors.paleGrey};

  transition: transform 300ms ease;
  transform: translateX(${p => (p.show ? 0 : '-100%')});

  @media (min-width: 1000px) {
    transition: none;

    max-width: ${MENU_WIDTH}px;

    /* Ensure the menu is always in view in Desktop mode */
    margin-left: ${MENU_WIDTH}px;
    transform: translateX(-${MENU_WIDTH}px);
  }
`;

export const InnerWrapper = styled.div`
  padding: 16px 0;
  position: relative;
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;

  /* scrollbar */
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${lighten(0.2, 'rgba(0, 0, 0, 0.2)')};
    }
  }
`;

export const TopArea = styled.div`
  width: 100%;
  padding: 0 16px 40px 24px;
  text-align: left;
`;

export const BottomSpacer = styled.div`
  display: none;

  @media (min-width: 1000px) {
    display: block;
    height: 56px;
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
  ${fonts.menuTitle}
  color: ${colors.azul};

  @media(min-width: 1000px) {
    display: block;
  }
`;

export const MenuList = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  flex: ${p => (p.bottom ? 0 : 1)};
`;

export const MenuItemWrapper = styled.div`
  ${fonts.bodyBold};
  border-left: 4px solid ${p => (p.active ? colors.azul : 'transparent')};

  /* An <a> tag is wrapped around the entire menu item to allow new tab clicks */
  a {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 16px 0 12px;
    text-decoration: none;

    svg {
      width: 29px;
      height: 29px;

      &:hover {
        cursor: pointer;
      }
    }

    &:visited {
      text-decoration: none;
    }

    /* The <span> within the a tag is used for hover and active-mode effects */
    span {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      border-radius: 29px;
      background-color: ${p => (p.active ? colors.paleGreyTwo : 'transparent')};
    }
  }

  &:hover {
    cursor: pointer;

    a span {
      background-color: ${colors.paleGreyTwo};
    }
  }
`;

export const MenuItemTitle = styled.div`
  color: ${p =>
    p.active || p.theme === 'blue' ? colors.azul : colors.charcoalGrey};
  flex: 1;
  padding-left: 16px;
`;

// @flow

import React, { PureComponent, Suspense } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { graphql, QueryRenderer } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import AlertNotifications from 'shared/components/common/AlertNotifications';
import config from './config';
import initAnalytics from './initAnalytics';

type Props = {
  children: *,
  alertNotifications: Array<Object>,
};

class MainAppBootstrapper extends PureComponent<Props> {
  postAuthInitiated = false;

  publicRoutes = ['/signin', '/signup'];

  isPublicRoute = () => {
    return this.publicRoutes.indexOf(window.location.pathname) > -1;
  };

  handlePostAuth = (viewer: Object) => {
    if (!this.postAuthInitiated) {
      this.postAuthInitiated = true;
      initAnalytics(viewer);
    }
  };

  render() {
    const { children, alertNotifications } = this.props;

    // NOTE: <Translation> is here to ensure the entire app re-renders upon language change.
    // This allows us to comfortably use the t() method without HOCs or render props
    return (
      <Suspense fallback={null}>
        <Translation>
          {t => (
            <QueryRenderer
              environment={relayEnvironment}
              dataFrom="STORE_THEN_NETWORK"
              variables={null}
              query={graphql`
                query MainAppBootstrapperQuery {
                  viewer {
                    id
                    role
                    firstName
                    lastName
                    email
                    userNumber
                    organization {
                      name
                    }
                    company {
                      name
                      namespace
                    }
                    updatedAt
                    createdAt
                  }
                }
              `}
              render={({ error, props }) => {
                if (error) {
                  return <p>{error.message}</p>;
                }

                if (!props) {
                  return null;
                }

                // If user isn't logged in and not viewing a public route, route to sign in page
                if (!props.viewer && !this.isPublicRoute()) {
                  window.location = '/signin';
                  return null;
                }

                if (props.viewer) {
                  this.handlePostAuth(props.viewer);
                }

                // If user IS logged in and viewing the signin or signup page, route to home
                if (
                  props.viewer &&
                  (window.location.pathname === '/signin' ||
                    window.location.pathname === '/signup')
                ) {
                  window.location = '/';
                  return null;
                }

                return (
                  <>
                    {children}
                    <AlertNotifications
                      alertNotifications={alertNotifications}
                    />
                    {config.env !== 'production' ? (
                      <div
                        style={{
                          position: 'fixed',
                          top: 8,
                          left: 16,
                          border: '1px solid #ccc',
                          background: 'rgba(255, 255, 255, 0.8)',
                          fontSize: 14,
                          fontWeight: 700,
                          color: '#f00',
                          padding: 5,
                          borderRadius: 5,
                          zIndex: 2,
                        }}
                      >
                        {`You're on the ${
                          window.location.href.indexOf('localhost') > -1
                            ? 'LOCAL'
                            : config.env.toUpperCase()
                        } app`}
                      </div>
                    ) : null}
                  </>
                );
              }}
            />
          )}
        </Translation>
      </Suspense>
    );
  }
}

// $FlowFixMe
export default withRouter(
  connect(state => ({
    alertNotifications: state.alertNotifications,
  }))(MainAppBootstrapper),
);

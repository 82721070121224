/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateItemModal_item$ref = any;
type DeleteItemModal_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ItemsTable_itemEdges$ref: FragmentReference;
declare export opaque type ItemsTable_itemEdges$fragmentType: ItemsTable_itemEdges$ref;
export type ItemsTable_itemEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +itemNumber: string,
    +name: string,
    +partNumber: ?string,
    +category: ?{|
      +id: string,
      +name: string,
    |},
    +quantityUOM: {|
      +id: string,
      +symbol: string,
    |},
    +totalQty: number,
    +safetyQuantity: ?number,
    +totalValue: ?number,
    +$fragmentRefs: AddUpdateItemModal_item$ref & DeleteItemModal_item$ref,
  |},
  +$refType: ItemsTable_itemEdges$ref,
|}>;
export type ItemsTable_itemEdges$data = ItemsTable_itemEdges;
export type ItemsTable_itemEdges$key = {
  +$data?: ItemsTable_itemEdges$data,
  +$fragmentRefs: ItemsTable_itemEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ItemsTable_itemEdges",
  "type": "ItemEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Item",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "itemNumber",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "partNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "category",
          "storageKey": null,
          "args": null,
          "concreteType": "ItemCategory",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "quantityUOM",
          "storageKey": null,
          "args": null,
          "concreteType": "UOM",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "symbol",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalQty",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "safetyQuantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalValue",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateItemModal_item",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "DeleteItemModal_item",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a4211660e17478a52ae16df2726bf6d';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeleteAttachmentModal_attachment$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AttachmentsGrid_attachmentEdges$ref: FragmentReference;
declare export opaque type AttachmentsGrid_attachmentEdges$fragmentType: AttachmentsGrid_attachmentEdges$ref;
export type AttachmentsGrid_attachmentEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +name: string,
    +urlPath: string,
    +createdAt: any,
    +user: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
    |},
    +$fragmentRefs: DeleteAttachmentModal_attachment$ref,
  |},
  +$refType: AttachmentsGrid_attachmentEdges$ref,
|}>;
export type AttachmentsGrid_attachmentEdges$data = AttachmentsGrid_attachmentEdges;
export type AttachmentsGrid_attachmentEdges$key = {
  +$data?: AttachmentsGrid_attachmentEdges$data,
  +$fragmentRefs: AttachmentsGrid_attachmentEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AttachmentsGrid_attachmentEdges",
  "type": "AttachmentEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Attachment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "urlPath",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "DeleteAttachmentModal_attachment",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ed93c2c1348c1d7552f611b74873b83';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { graphql, createFragmentContainer } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import DeleteJobLinkedItemMutation from 'main-app/mutations/DeleteJobLinkedItem';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteJobLinkedItemModal_item as ItemFragment } from './__generated__/DeleteJobLinkedItemModal_item';

type Props = {
  item: ItemFragment,
  onClose: () => void,
  jobId: string,
};

type State = {
  deleting: boolean,
};

class DeleteJobLinkedItemModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  handleDelete = () => {
    const { item, jobId, onClose } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        const input: any = {
          itemId: item.id,
          jobId,
        };

        try {
          await DeleteJobLinkedItemMutation.commit({
            variables: {
              input,
            },
          });

          onClose();

          Actions.alertNotification(
            {
              title: 'Tooling Unlinked Successfully!',
              body: null,
            },
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });
          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { onClose, item } = this.props;
    const { deleting } = this.state;
    return (
      <Modal maxWidth={700}>
        <ModalHeader header={i18n.t('Unlink Tooling?')} onClose={onClose} />
        <ModalBody withPadding>
          {i18n.t(
            'Are you sure you want to remove {{ name }} from the job ticket?',
            {
              name: item.name,
            },
          )}
          <br />
          <br />
          {i18n.t(
            'This will remove the ability for the operator to reference the information during production and may also affect your reporting if the tool is used.',
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="blue"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Unlink Tooling')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteJobLinkedItemModal, {
  item: graphql`
    fragment DeleteJobLinkedItemModal_item on Item {
      id
      name
    }
  `,
});

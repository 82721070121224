// @flow

import type { FilterOption } from 'main-app/components/FilterControls';
import { i18n } from 'shared/utils';

export default (): Array<FilterOption> => [
  {
    label: i18n.t('Customer Number'),
    name: 'customerNumbers',
    type: 'text',
    multi: true,
  },
  {
    label: i18n.t('Salesperson'),
    name: 'salesUserIds',
    type: 'user',
    multi: true,
  },
];

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  type DropResult,
  type DroppableProvided,
  type DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { i18n } from 'shared/utils';
import { colors } from 'shared/styleguide';
import Button from 'shared/components/common/Button';
import UpdateFormField from 'main-app/mutations/UpdateFormField';
import AddFormFieldModal from 'main-app/components/AddFormFieldModal';
import DeleteFormFieldModal from 'main-app/components/DeleteFormFieldModal';
import type { FormFieldBoard_form as FormFragment } from './__generated__/FormFieldBoard_form';
import FormFieldCard from './FormFieldCard';

type Props = {
  form: FormFragment,
};

type State = {
  formFields: $PropertyType<FormFragment, 'fields'>,
  addModalOpen: boolean,
  deleteModalRecord: ?Object,
};

const ButtonWrapper = styled.div`
  display: inform-block;
  padding-bottom: 16px;
`;

const DropArea = styled.div`
  background-color: ${({ isDraggingOver }) =>
    isDraggingOver ? colors.paleGreyTwo : 'transparent'};
  display: flex;
  flex-direction: column;
  transition: background-color 0.1s ease;
  user-select: none;
  min-height: 300px;
  padding-bottom: 50px;
`;

class FormFieldBoard extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formFields: props.form.fields,
      addModalOpen: false,
      deleteModalRecord: null,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { form } = this.props;

    if (prevProps.form.fields !== form.fields) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        formFields: form.fields,
      });
    }
  }

  handleDragStart = () => {};

  handleDragEnd = (result: DropResult) => {
    const { formFields } = this.state;
    const { source, destination } = result;

    // If didn't move anywhere, bail early
    if (!destination || source.index === destination.index) {
      return;
    }

    const fields = Array.from(formFields);
    const [target] = fields.splice(source.index, 1);

    fields.splice(destination.index, 0, target);

    this.setState(
      {
        formFields: fields,
      },
      () => this.handlePersistState(target, fields, destination),
    );
  };

  handlePersistState = async (target, formFields, destination) => {
    const mutationInput: any = {
      id: target.id,
    };

    if (formFields.length === 1) {
      mutationInput.displayOrder = 1000;
    } else if (destination.index === 0) {
      mutationInput.displayOrder =
        formFields[destination.index + 1].displayOrder - 0.5;
    } else if (destination.index === formFields.length - 1) {
      mutationInput.displayOrder =
        formFields[formFields.length - 2].displayOrder + 0.5;
    } else {
      const before = formFields[destination.index - 1].displayOrder;
      const after = formFields[destination.index + 1].displayOrder;
      mutationInput.displayOrder = (before + after) / 2;
    }

    return UpdateFormField.commit({
      variables: {
        input: mutationInput,
      },
    });
  };

  handleCreateSuccess = () => {
    this.handleCloseAllModals();

    // scroll to the bottom so that user can view newly-created field
    window.scrollTo(0, document.body.scrollHeight);
  };

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleOpenDeleteModal = (formField: Object) => {
    this.setState({
      deleteModalRecord: formField,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      deleteModalRecord: null,
    });
  };

  render() {
    const { form } = this.props;
    const { formFields, addModalOpen, deleteModalRecord } = this.state;

    return (
      <>
        <ButtonWrapper>
          <Button onClick={this.handleOpenCreateModal} width="auto">
            {i18n.t('Create Form Field')}
          </Button>
        </ButtonWrapper>
        <DragDropContext
          onDragStart={this.handleDragStart}
          onDragEnd={this.handleDragEnd}
        >
          <Droppable droppableId="form-fields">
            {(
              dropProvided: DroppableProvided,
              dropSnapshot: DroppableStateSnapshot,
            ) => (
              <DropArea
                isDraggingOver={dropSnapshot.isDraggingOver}
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
              >
                {formFields.map((formField, i) => (
                  <FormFieldCard
                    index={i}
                    key={formField.id}
                    formField={formField}
                    onDelete={this.handleOpenDeleteModal}
                  />
                ))}
                {dropProvided.placeholder}
              </DropArea>
            )}
          </Droppable>
        </DragDropContext>
        {addModalOpen && (
          <AddFormFieldModal
            onClose={this.handleCloseAllModals}
            onSuccess={this.handleCreateSuccess}
            formId={form.id}
          />
        )}
        {deleteModalRecord && (
          <DeleteFormFieldModal
            onClose={this.handleCloseAllModals}
            formField={deleteModalRecord}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(FormFieldBoard, {
  form: graphql`
    fragment FormFieldBoard_form on Form {
      id
      fields {
        id
        displayOrder
        ...FormFieldCard_formField
      }
    }
  `,
});

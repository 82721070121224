// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import {
  ContextMenuTrigger,
  ContextMenu,
  MenuItem as ContextMenuItem,
} from 'react-contextmenu';
import { MenuItemWrapper, MenuItemTitle } from './styled';

type Props = {
  icon: React$Node,
  title: string,
  theme?: 'blue' | 'default',
  active?: boolean,
  linkTo?: string,
  onClick?: Event => void,
  contextMenuItems?: Array<{ label: string, onClick: () => void }>,
};

const MenuItemLink = ({
  linkTo,
  onClick,
  icon,
  title,
  active,
  theme,
}: $Shape<Props>) => (
  <Link to={linkTo || '#'} onClick={onClick}>
    <span>
      {icon}
      <MenuItemTitle active={active} theme={theme}>
        {title}
      </MenuItemTitle>
    </span>
  </Link>
);

const menuTriggerRefs = {};

const MenuItem = (props: Props) => {
  const { active, theme, contextMenuItems } = props;
  const contextMenuId = contextMenuItems
    ? `context-menu-${contextMenuItems.map(a => a.label).join('_')}`
    : 'context-menu';

  return (
    <MenuItemWrapper active={active}>
      {contextMenuItems ? (
        <>
          <ContextMenuTrigger
            ref={r => (menuTriggerRefs[contextMenuId] = r)}
            id={contextMenuId}
          >
            <MenuItemLink
              {...props}
              theme={theme}
              onClick={e => {
                if (menuTriggerRefs[contextMenuId]) {
                  menuTriggerRefs[contextMenuId].handleContextClick(e);
                }
              }}
            />
          </ContextMenuTrigger>
          <ContextMenu id={contextMenuId}>
            {contextMenuItems.map(({ label, onClick }) => (
              <ContextMenuItem key={label} onClick={onClick}>
                {label}
              </ContextMenuItem>
            ))}
          </ContextMenu>
        </>
      ) : (
        <MenuItemLink {...props} />
      )}
    </MenuItemWrapper>
  );
};

MenuItem.defaultProps = {
  theme: 'default',
  active: false,
  linkTo: undefined,
  onClick: undefined,
  contextMenuItems: undefined,
};

export default MenuItem;

/**
 * @flow
 * @relayHash f461b588c1801a7b23901614c83d076c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddUpdateAPIKeyModal_apiKey$ref = any;
type DeleteAPIKeyModal_apiKey$ref = any;
type UserWidget_user$ref = any;
export type APIKeysQueryVariables = {||};
export type APIKeysQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +organization: {|
      +id: string,
      +apiKeys: $ReadOnlyArray<{|
        +id: string,
        +name: string,
        +secret: string,
        +lastUsedAt: ?any,
        +createdAt: any,
        +actingUser: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +$fragmentRefs: UserWidget_user$ref,
        |},
        +$fragmentRefs: AddUpdateAPIKeyModal_apiKey$ref & DeleteAPIKeyModal_apiKey$ref,
      |}>,
    |},
  |}
|};
export type APIKeysQuery = {|
  variables: APIKeysQueryVariables,
  response: APIKeysQueryResponse,
|};
*/


/*
query APIKeysQuery {
  viewer {
    id
    organization {
      id
      apiKeys {
        id
        name
        secret
        lastUsedAt
        createdAt
        actingUser {
          id
          firstName
          lastName
          ...UserWidget_user
        }
        ...AddUpdateAPIKeyModal_apiKey
        ...DeleteAPIKeyModal_apiKey
      }
    }
  }
}

fragment UserWidget_user on User {
  id
  firstName
  lastName
  ...UserAvatar_user
}

fragment AddUpdateAPIKeyModal_apiKey on APIKey {
  id
  name
  actingUser {
    id
  }
  createdAt
}

fragment DeleteAPIKeyModal_apiKey on APIKey {
  id
  name
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "secret",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastUsedAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "APIKeysQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "apiKeys",
                "storageKey": null,
                "args": null,
                "concreteType": "APIKey",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actingUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "UserWidget_user",
                        "args": null
                      }
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "AddUpdateAPIKeyModal_apiKey",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "DeleteAPIKeyModal_apiKey",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "APIKeysQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "apiKeys",
                "storageKey": null,
                "args": null,
                "concreteType": "APIKey",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "actingUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "APIKeysQuery",
    "id": null,
    "text": "query APIKeysQuery {\n  viewer {\n    id\n    organization {\n      id\n      apiKeys {\n        id\n        name\n        secret\n        lastUsedAt\n        createdAt\n        actingUser {\n          id\n          firstName\n          lastName\n          ...UserWidget_user\n        }\n        ...AddUpdateAPIKeyModal_apiKey\n        ...DeleteAPIKeyModal_apiKey\n      }\n    }\n  }\n}\n\nfragment UserWidget_user on User {\n  id\n  firstName\n  lastName\n  ...UserAvatar_user\n}\n\nfragment AddUpdateAPIKeyModal_apiKey on APIKey {\n  id\n  name\n  actingUser {\n    id\n  }\n  createdAt\n}\n\nfragment DeleteAPIKeyModal_apiKey on APIKey {\n  id\n  name\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24e63af350ed5e4896d4977eff9a6694';
module.exports = node;

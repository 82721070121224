// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      id: string,
    },
  },
  context: {
    orderId?: ?string,
  },
};

const mutation = graphql`
  mutation DeleteJobMutation($input: DeleteJobInput!) {
    deleteJob(input: $input) {
      deletedId
    }
  }
`;

export default {
  commit: ({ context, ...options }: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: ROOT_ID,
          connectionKeys: [
            { key: 'PaginatedJobsContainer_jobs' },
            {
              key: 'Order_jobs',
              filters: {
                orderIds: [context.orderId],
              },
            },
          ],
          pathToConnection: [ROOT_ID, 'jobs'],
          deletedIDFieldName: 'deletedId',
        },
      ],
    });
  },
};

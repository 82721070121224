// @flow

import { graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      id: string,
    },
  },
  context: {
    jobId: string,
  },
};

const mutation = graphql`
  mutation DeleteJobYieldMutation($input: DeleteJobYieldInput!) {
    deleteJobYield(input: $input) {
      deletedId
    }
  }
`;

export default {
  commit: ({ context, ...options }: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      updater: store => {
        const root = store
          .getRoot()
          .getLinkedRecord('job', { id: context.jobId });

        const conn1 = ConnectionHandler.getConnection(
          root,
          'JobDetails_job_yields',
        );
        const conn2 = ConnectionHandler.getConnection(
          root,
          'AddUpdateRunModal_job_yields',
        );
        const conn3 = ConnectionHandler.getConnection(
          root,
          'ReopenJobModal_job_yields',
        );
        const conn4 = ConnectionHandler.getConnection(
          root,
          'CloseJobModal_job_yields',
        );
        const conn5 = ConnectionHandler.getConnection(
          root,
          'JobSubComponents_job_yields',
        );

        if (conn1) {
          const edges = conn1
            .getLinkedRecords('edges')
            .filter(
              edge =>
                edge.getLinkedRecord('node')._dataID !==
                options.variables.input.id,
            );

          conn1.setLinkedRecords(edges, 'edges');
        }

        if (conn2) {
          const edges = conn2
            .getLinkedRecords('edges')
            .filter(
              edge =>
                edge.getLinkedRecord('node')._dataID !==
                options.variables.input.id,
            );

          conn2.setLinkedRecords(edges, 'edges');
        }

        if (conn3) {
          const edges = conn3
            .getLinkedRecords('edges')
            .filter(
              edge =>
                edge.getLinkedRecord('node')._dataID !==
                options.variables.input.id,
            );

          conn3.setLinkedRecords(edges, 'edges');
        }

        if (conn4) {
          const edges = conn4
            .getLinkedRecords('edges')
            .filter(
              edge =>
                edge.getLinkedRecord('node')._dataID !==
                options.variables.input.id,
            );

          conn4.setLinkedRecords(edges, 'edges');
        }

        if (conn5) {
          const edges = conn5
            .getLinkedRecords('edges')
            .filter(
              edge =>
                edge.getLinkedRecord('node')._dataID !==
                options.variables.input.id,
            );

          conn5.setLinkedRecords(edges, 'edges');
        }
      },
    });
  },
};

// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n, Analytics, WorkflowUtils } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import ScheduleJobMutation from 'main-app/mutations/ScheduleJob';
import Button from 'shared/components/common/Button';
import Divider from 'shared/components/common/Divider';
import Icon from 'shared/components/common/Icon';
import IconWithLabel from 'shared/components/common/Icon/IconWithLabel';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldArray,
  FieldLabel,
  FieldGroup,
  FieldGroupRow,
  SelectMachineField,
} from 'shared/components/form';
import type { AutoScheduleJobModal_job as JobFragment } from './__generated__/AutoScheduleJobModal_job';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  job: JobFragment,
};

const StateHeader = styled.div`
  ${fonts.headerBold};
  color: ${colors.charcoalGrey};
  padding-bottom: 24px;
`;

const Disclaimer = styled.div`
  ${fonts.bodyRegular};
  color: ${colors.greenBlue};
  padding-bottom: 24px;
`;

const ItemRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-bottom: 16px;

  @media (min-width: 1000px) {
    align-items: center;
  }
`;

class AutoScheduleJobModal extends PureComponent<Props> {
  static defaultProps = {
    onSuccess: undefined,
  };

  render() {
    const { job, onClose, onSuccess } = this.props;
    const sortedJobStates = WorkflowUtils.getSortedJobStates(job);

    return (
      <Modal maxWidth={700}>
        <Formik
          validationSchema={Yup.object().shape({
            runs: Yup.array().of(
              Yup.object().shape({
                machine: Yup.object().nullable(),
                notes: Yup.string(),
              }),
            ),
            jobYields: Yup.array().of(
              Yup.object().shape({
                jobYield: Yup.object().required('Required'),
              }),
            ),
          })}
          initialValues={{
            runs: job.states
              .filter(
                state => state.isEnabled && state.workflowState.type === 'RUN',
              )
              .sort((jobStateA, jobStateB) =>
                sortedJobStates
                  ? (sortedJobStates[jobStateA.id]?.order || 0) -
                    (sortedJobStates[jobStateB.id]?.order || 0)
                  : 0,
              )
              .map(state => ({
                machine: '',
                notes: '',
                state,
              })),
            jobYields: (job.yields.edges || []).filter(Boolean).map(item => ({
              jobYield: { value: item.node.id },
            })),
          }}
          isInitialValid
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const input: any = {
              jobId: job.id,
              stateRunOverrides: values.runs.map(run => ({
                jobStateId: run.state.id,
                machineId: run.machine?.value || null,
                notes: run.notes,
              })),
              jobYieldIds: values.jobYields
                .filter(item => item.jobYield?.value)
                .map(item => item.jobYield.value),
            };

            try {
              const response = await ScheduleJobMutation.commit({
                variables: {
                  input,
                },
              });

              const scheduleJobResponse = response.scheduleJob.runEdges.map(
                ({ node }) => node,
              );

              onClose();

              if (onSuccess) {
                onSuccess(scheduleJobResponse);
              }

              Actions.alertNotification(
                i18n.t('Runs Successfully Scheduled'),
                'success',
              );

              Analytics.trackEvent('Auto Schedule Runs');
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(e.message, 'error');
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <ModalHeader
                header={i18n.t('Auto Schedule Runs')}
                onClose={onClose}
              />
              <ModalBody withPadding>
                <Disclaimer>
                  {i18n.t(
                    'Auto scheduling will schedule all runs in an optimized sequence. Existing runs on the schedule will not be moved. Leave Work Center fields blank to auto-select work centers for each run.',
                  )}
                </Disclaimer>
                <FieldArray
                  name="runs"
                  render={arrayHelpers =>
                    values.runs.map((run, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={`run-${i}`}>
                        <StateHeader>
                          {i18n.t('Step {{stateNumber}}: {{stateName}}', {
                            stateNumber: i + 1,
                            stateName: run.state.workflowState.name,
                          })}
                        </StateHeader>
                        <FieldGroupRow
                          left={
                            <SelectMachineField
                              name={`runs.${i}.machine`}
                              placeholder={i18n.t('Any Work Center')}
                              errors={errors.runs && errors.runs[i]?.machine}
                              queryVariables={{
                                machineTypeId:
                                  run.state.workflowState.machineType?.id,
                                workflowIds: [job.workflow.id],
                              }}
                            />
                          }
                          right={
                            <FieldGroup
                              label={i18n.t('Note for Operator')}
                              name={`runs.${i}.notes`}
                              placeholder={i18n.t('Type here...')}
                              errors={errors.runs && errors.runs[i]?.notes}
                            />
                          }
                        />
                        <Divider style={{ marginBottom: 24 }} />
                      </React.Fragment>
                    ))
                  }
                />
                <Divider style={{ marginBottom: 24 }} />
                <FieldLabel>{i18n.t('Item(s) On Run')}</FieldLabel>
                <FieldArray
                  name="jobYields"
                  render={arrayHelpers => (
                    <>
                      {!values.jobYields?.length
                        ? null
                        : values.jobYields.map((item, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <ItemRow key={`item-${i}`}>
                              <Icon
                                type="circle-minus"
                                size={24}
                                onClick={() => arrayHelpers.remove(i)}
                                style={{ marginRight: 16 }}
                              />
                              <FieldGroup
                                type="select"
                                name={`jobYields.${i}.jobYield`}
                                size="medium"
                                options={(job?.yields.edges || [])
                                  .filter(Boolean)
                                  .filter(itemEdge => {
                                    const alreadyChosenItem = values.jobYields.find(
                                      (a, j) => {
                                        return (
                                          a.jobYield?.value ===
                                            itemEdge.node.id && j !== i
                                        );
                                      },
                                    );

                                    return !alreadyChosenItem;
                                  })
                                  .map(itemEdge => ({
                                    label: itemEdge.node.item.name,
                                    value: itemEdge.node.id,
                                  }))}
                                style={{ paddingBottom: 0 }}
                              />
                            </ItemRow>
                          ))}
                      <IconWithLabel
                        type="circle-plus"
                        label={i18n.t('Add Another Item')}
                        onClick={() =>
                          arrayHelpers.push({
                            jobYield: null,
                          })
                        }
                      />
                    </>
                  )}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {i18n.t('Create Runs')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(AutoScheduleJobModal, {
  job: graphql`
    fragment AutoScheduleJobModal_job on Job {
      id
      workflow {
        id
        states {
          id
          type
          name
        }
        transitions {
          toWorkflowState {
            id
          }
          fromWorkflowState {
            id
          }
        }
      }
      states {
        id
        isEnabled
        workflowState {
          id
          name
          type
          machineType {
            id
          }
        }
      }
      yields(first: null)
        @connection(key: "AutoScheduleJobModal_job_yields", filters: []) {
        edges {
          node {
            id
            item {
              id
              name
            }
          }
        }
      }
    }
  `,
});

// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import type { RouterHistory } from 'react-router';
import { i18n } from 'shared/utils';
import Loader from 'shared/components/common/Loader';
import ItemLinkedJobsTable from './ItemLinkedJobsTable';

type Props = {
  history: RouterHistory,
  itemId: string,
};

const ItemLinkedJobs = ({ itemId, history }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    variables={{
      itemId,
    }}
    query={graphql`
      query ItemLinkedJobsQuery($itemId: ID!) {
        item(id: $itemId) {
          id
          linkedJobs(first: null)
            @connection(key: "Item_linkedJobs", filters: []) {
            edges {
              ...ItemLinkedJobsTable_itemLinkedJobEdges
            }
          }
        }
      }
    `}
    render={({ props }) => {
      if (!props) {
        return <Loader />;
      }
      return (
        <ItemLinkedJobsTable
          history={history}
          itemLinkedJobEdges={props?.item.linkedJobs.edges || null}
          emptyListMessage={i18n.t(
            'Jobs using this item as tooling will show here.',
          )}
        />
      );
    }}
  />
);

export default ItemLinkedJobs;

// @flow

import { NodeModel, DiagramEngine } from 'storm-react-diagrams';
import IconPortModel from './IconPortModel';

class IconNodeModel extends NodeModel {
  constructor(icon: string) {
    super(icon);
    this.data = {};
    if (icon === 'square-job-created') {
      this.addPort(new IconPortModel('right', icon));
    } else if (icon === 'square-job-completed') {
      this.addPort(new IconPortModel('left', icon));
    } else {
      this.addPort(new IconPortModel('right', icon));
      this.addPort(new IconPortModel('left', icon));
    }
  }

  deSerialize(ob: IconNodeModel, engine: DiagramEngine) {
    super.deSerialize(ob, engine);
    this.data = ob.data;
  }

  serialize() {
    return { ...super.serialize(), data: this.data };
  }
}

export default IconNodeModel;

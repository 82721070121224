/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ItemLotsTable_itemLotEdges$ref: FragmentReference;
declare export opaque type ItemLotsTable_itemLotEdges$fragmentType: ItemLotsTable_itemLotEdges$ref;
export type ItemLotsTable_itemLotEdges = $ReadOnlyArray<{|
  +quantity: ?number,
  +node: {|
    +id: string,
    +lotNumber: string,
    +binNumber: ?string,
    +quantity: number,
    +expiresAt: ?any,
  |},
  +$refType: ItemLotsTable_itemLotEdges$ref,
|}>;
export type ItemLotsTable_itemLotEdges$data = ItemLotsTable_itemLotEdges;
export type ItemLotsTable_itemLotEdges$key = {
  +$data?: ItemLotsTable_itemLotEdges$data,
  +$fragmentRefs: ItemLotsTable_itemLotEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ItemLotsTable_itemLotEdges",
  "type": "ItemLotEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Lot",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lotNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "binNumber",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "expiresAt",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ff1b4feb5110c49210b4fb5008af193';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import { QueryRenderer, graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import DataSheet from 'react-datasheet';
import relayEnvironment from 'shared/gql/relayEnvironment';
import * as Actions from 'main-app/store/Actions';
import { i18n } from 'shared/utils';
import { fonts, colors } from 'shared/styleguide';
import { DateRangePicker } from 'shared/components/form';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import Loader from 'shared/components/common/Loader';
import Button from 'shared/components/common/Button';
import IconWithLabel from 'shared/components/common/Icon/IconWithLabel';
import EmptyListResults from 'shared/components/common/EmptyListResults';

type Props = {
  history: RouterHistory,
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

type State = {
  startAt: ?moment$Moment,
  endAt: ?moment$Moment,
};

const ExportReportWrapper = styled.div`
  display: none;
  @media (min-width: 1000px) {
    display: block;
  }
`;

const SheetWrapper = styled.div`
  margin-top: 16px;
  background-color: ${colors.white};
  overflow-x: auto;
`;

const CellHeader = styled.div`
  padding: 5px;
  ${fonts.bodyBold};
  color: ${colors.charcoalGrey};
`;

const Cell = styled.div`
  padding: 5px;
  ${fonts.bodyRegular};
  color: ${colors.charcoalGrey};
`;

class Report extends PureComponent<Props, State> {
  menuTriggerRef = null;

  state = {
    startAt: moment().startOf('month'),
    endAt: moment(),
  };

  handleDateChange = ({ startDate, endDate }: *) => {
    this.setState({
      startAt: startDate,
      endAt: endDate,
    });
  };

  handleExportReport = (reportId: string) => {
    const { startAt, endAt } = this.state;
    window.open(
      '/api/v1/csv-export-report/' +
        reportId +
        '?startAt=' +
        moment(startAt).format() +
        '&endAt=' +
        moment(endAt).format(),
    );

    Actions.alertNotification(
      i18n.t('Report successfully exported'),
      'success',
    );
  };

  handleGoBack = () => {
    const { history } = this.props;

    history.goBack();
  };

  massageReport = (compiledReport: any) => {
    const grid: any = [
      [
        { readOnly: true, value: '' },
        ...Object.keys(compiledReport[0]).map(key => ({
          value: key,
          readOnly: true,
          valueViewer: ({ value }) => <CellHeader>{value}</CellHeader>,
        })),
      ],
    ];

    for (let i = 0; i < compiledReport.length; i++) {
      const values = [
        {
          readOnly: true,
          value: i + 1,
          valueViewer: ({ value }) => <CellHeader>{value}</CellHeader>,
        },
      ];

      for (const k in compiledReport[i]) {
        values.push({
          value: compiledReport[i][k],
        });
      }

      grid.push(values);
    }

    return grid;
  };

  render() {
    const { match } = this.props;
    const { startAt, endAt } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query ReportQuery($id: ID!, $startAt: DateTime!, $endAt: DateTime!) {
            report(id: $id) {
              id
              name
              description
              compiled(startAt: $startAt, endAt: $endAt)
            }
          }
        `}
        variables={{
          id: match.params.id,
          startAt: startAt ? moment(startAt).format() : null,
          endAt: endAt ? moment(endAt).format() : null,
        }}
        render={query => (
          <Page>
            <PageHeader
              intro={i18n.t('REPORT')}
              title={
                query.props
                  ? query.props.report.name
                  : i18n.t('Report Loading...')
              }
              right={[
                <DateRangePicker
                  value={{
                    startDate: moment(startAt),
                    endDate: moment(endAt),
                  }}
                  onChange={this.handleDateChange}
                />,
                query.props?.report.compiled?.length ? (
                  <ExportReportWrapper>
                    <Button
                      theme="border-white"
                      disabled={!query.props}
                      onClick={() =>
                        this.handleExportReport(query.props.report.id)
                      }
                    >
                      {i18n.t('Export Report')}
                    </Button>
                  </ExportReportWrapper>
                ) : (
                  <React.Fragment />
                ),
              ]}
            />
            {!query.props ? (
              <Loader />
            ) : query.props.report.compiled?.length ? (
              <>
                <IconWithLabel
                  label={i18n.t('Go back')}
                  onClick={this.handleGoBack}
                  type="circle-arrow-left"
                  iconPosition="left"
                />
                <SheetWrapper>
                  <DataSheet
                    data={this.massageReport(query.props.report.compiled)}
                    valueRenderer={cell => cell.value}
                    valueViewer={({ value }) => <Cell>{value}</Cell>}
                    dataEditor={({ value }) => <Cell>{value}</Cell>}
                    overflow="nowrap"
                  />
                </SheetWrapper>
              </>
            ) : (
              <EmptyListResults
                graphic="report"
                message={i18n.t('No Data Found for this Date Range.')}
              />
            )}
          </Page>
        )}
      />
    );
  }
}

export default Report;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      parentItemId: string,
      childItemId: string,
    },
  },
};

const mutation = graphql`
  mutation DeleteItemChildMutation($input: DeleteItemChildInput!) {
    deleteItemChild(input: $input) {
      deletedItemId
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    const { parentItemId } = options.variables.input;

    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: parentItemId,
          connectionKeys: [{ key: 'Item_childItems' }],
          pathToConnection: ['item', 'childItems'],
          deletedIDFieldName: 'deletedItemId',
        },
      ],
    });
  },
};

// @flow

import React, { PureComponent } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import debounce from 'lodash.debounce';
import type { RouterHistory } from 'react-router';
import { BrowserUtils } from 'shared/utils';
import LoadingMore from 'shared/components/common/LoadingMore';
import EmptySearchResults from 'shared/components/common/EmptySearchResults';
import JobsTable from 'shared/components/common/JobsTable';
import type { PaginatedJobsContainer_jobs as JobsFragment } from './__generated__/PaginatedJobsContainer_jobs';

type Props = {
  history: RouterHistory,
  filtersDirty: boolean,
  onCreateNew: () => void,
  jobs: JobsFragment,
  editEnabled: boolean,
  deleteEnabled: boolean,
  relay: {
    environment: Object,
    hasMore: () => boolean,
    isLoading: () => boolean,
    loadMore: (pageSize: number, callback: Function) => void,
  },
};

class PaginatedJobsContainer extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onScrollEndReached = debounce(this.onScrollEndReached, 100);
  }
  componentDidMount = () => {
    document.addEventListener('scroll', this.onScrollEndReached);
  };

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.onScrollEndReached);
  };

  onScrollEndReached = () => {
    const { relay } = this.props;

    if (BrowserUtils.scrollEndReached(200)) {
      if (relay.hasMore()) {
        relay.loadMore(30);
      }
    }
  };

  render() {
    const {
      history,
      onCreateNew,
      jobs,
      relay,
      filtersDirty,
      editEnabled,
      deleteEnabled,
    } = this.props;

    if ((!jobs.jobs.edges || !jobs.jobs.edges.length) && filtersDirty) {
      return <EmptySearchResults />;
    }

    return (
      <>
        <JobsTable
          history={history}
          jobEdges={jobs.jobs.edges}
          onCreateNew={onCreateNew}
          deleteEnabled={deleteEnabled}
          editEnabled={editEnabled}
        />
        {relay.hasMore() && relay.isLoading() ? <LoadingMore /> : null}
      </>
    );
  }
}

export default createPaginationContainer(
  PaginatedJobsContainer,
  {
    jobs: graphql`
      fragment PaginatedJobsContainer_jobs on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 30 }
          cursor: { type: "String" }
          search: { type: "String" }
          sortBy: { type: "JobSort" }
          jobNumbers: { type: "[String!]" }
          importJobNumbers: { type: "[String!]" }
          customerIds: { type: "[ID!]" }
          name: { type: "String" }
          statuses: { type: "[JobStatus!]" }
          userIds: { type: "[ID!]" }
          itemName: { type: "String" }
          itemPartNumber: { type: "String" }
          createdAtStart: { type: "Date" }
          createdAtEnd: { type: "Date" }
        ) {
        jobs(
          first: $count
          after: $cursor
          search: $search
          sortBy: $sortBy
          jobNumbers: $jobNumbers
          importJobNumbers: $importJobNumbers
          customerIds: $customerIds
          name: $name
          statuses: $statuses
          userIds: $userIds
          itemName: $itemName
          itemPartNumber: $itemPartNumber
          createdAtStart: $createdAtStart
          createdAtEnd: $createdAtEnd
        ) @connection(key: "PaginatedJobsContainer_jobs", filters: []) {
          totalCount
          edges {
            cursor
            node {
              id
            }
            ...JobsTable_jobEdges
          }
        }
      }
    `,
  },
  {
    query: graphql`
      query PaginatedJobsContainerQuery(
        $count: Int!
        $cursor: String
        $search: String
        $sortBy: JobSort
        $jobNumbers: [String!]
        $importJobNumbers: [String!]
        $customerIds: [ID!]
        $name: String
        $statuses: [JobStatus!]
        $userIds: [ID!]
        $itemName: String
        $itemPartNumber: String
        $createdAtStart: Date
        $createdAtEnd: Date
      ) {
        ...PaginatedJobsContainer_jobs
          @arguments(
            count: $count
            cursor: $cursor
            search: $search
            sortBy: $sortBy
            jobNumbers: $jobNumbers
            importJobNumbers: $importJobNumbers
            customerIds: $customerIds
            name: $name
            statuses: $statuses
            userIds: $userIds
            itemName: $itemName
            itemPartNumber: $itemPartNumber
            createdAtStart: $createdAtStart
            createdAtEnd: $createdAtEnd
          )
      }
    `,
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return { ...fragmentVariables, count, cursor };
    },
  },
);

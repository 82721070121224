// @flow
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n, WorkflowUtils } from 'shared/utils';
import { transparentize } from 'polished';
import type { RunList_job as JobFragment } from './__generated__/DraggableJobCard_job';

type Props = {
  job: JobFragment,
  onViewOnSchedule: Object => void,
};

const Wrapper = styled.div`
  border-radius: 5px;
  padding: 8px 16px;
  margin: 0 8px 8px 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: ${transparentize(0.95, colors.charcoalGrey)};
`;

const Label = styled.div`
  ${fonts.smallBold};
  color: ${colors.charcoalGrey};
  text-overflow: ellipsis;
`;

const List = styled.ol`
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    margin: 8px 0 0 0;
  }

  li:first-of-type {
    margin: 0;
  }
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  ${fonts.smallRegular}
`;

const Divider = styled.div`
  width: calc(100% - 16px);
  height: 2px;
  border-radius: 1px;
  background-color: ${colors.paleGrey};
  margin: 0 8px 8px 8px;
`;

const RunList = ({ job, onViewOnSchedule }: Props) => {
  const sortedJobStates = WorkflowUtils.getSortedJobStates(job);
  const states = (job.states || [])
    .filter(state => state.isEnabled && state.workflowState.type === 'RUN')
    .sort((jobStateA, jobStateB) =>
      sortedJobStates
        ? (sortedJobStates[jobStateA.id]?.order || 0) -
          (sortedJobStates[jobStateB.id]?.order || 0)
        : 0,
    )
    .map(state => (
      <Wrapper key={state.id}>
        <List>
          <ListItem key="list-name">
            <Label>{state.workflowState.name}</Label>
            <Label>
              {state.runs.length || i18n.t('0')}{' '}
              {i18n.t(`Run${state.runs.length === 1 ? '' : 's'}`)}
            </Label>
          </ListItem>
          {(state.runs || []).map((run, i) => (
            <ListItem key={run.id}>
              <div>
                <Link
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    onViewOnSchedule(run);
                  }}
                >
                  {i18n.t(
                    `${i + 1}.  ${moment(run.scheduledStartAt).format(
                      'L, h:mm:ss a',
                    )}`,
                  )}
                </Link>
              </div>
              <div>{run.status}</div>
            </ListItem>
          ))}
        </List>
      </Wrapper>
    ));

  return (
    <>
      <Divider />
      {states}
    </>
  );
};

export default createFragmentContainer(RunList, {
  job: graphql`
    fragment RunList_job on Job {
      id
      ...WorkflowUtils_job @relay(mask: false)
      states {
        id
        workflowState {
          id
          name
        }
        runs {
          id
          scheduledStartAt
          status
        }
      }
    }
  `,
});

// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import UpdateJobStatusMutation from 'main-app/mutations/UpdateJobStatus';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import type { ReopenJobModal_job as JobFragment } from './__generated__/ReopenJobModal_job';

type Props = {
  onClose: () => void,
  job: JobFragment,
};

const ReopenJobModal = ({ onClose, job }: Props) => {
  const jobYields = job.yields.edges
    ? job.yields.edges.filter(Boolean).map(edge => edge.node)
    : [];

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          notes: '',
        }}
        validationSchema={Yup.object().shape({
          notes: Yup.string(),
        })}
        isInitialValid
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          try {
            await UpdateJobStatusMutation.commit({
              variables: {
                input: {
                  id: job.id,
                  status: 'OPEN',
                  jobYieldQuantities: jobYields.map(jobYield => ({
                    jobYieldId: jobYield.id,
                    itemId: jobYield.item.id,
                    finalQuantity: parseFloat(jobYield.finalQuantity),
                    lotTracking: jobYield.item.lotTracking,
                  })),
                  notes: values.notes,
                },
              },
            });

            onClose();

            Actions.alertNotification(
              i18n.t('Job Successfully Re-Opened'),
              'success',
            );
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          dirty,
        }) => (
          <Form>
            <ModalHeader header={i18n.t('Re-Open Job?')} onClose={onClose} />
            <ModalBody withPadding>
              <div style={{ marginBottom: 24 }}>
                {i18n.t(
                  'Are you sure you want to re-open this Job? Re-opening the job will reset the quantity produced for each item to 0',
                )}
              </div>
              {Boolean(jobYields.length) && (
                <FieldGroup
                  name="notes"
                  label={i18n.t('Enter a Note')}
                  error={errors.notes}
                  type="textarea"
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                theme="blue"
                disabled={!isValid && values.jobYieldQuantities.length}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {i18n.t('Re-Open Job')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

export default createFragmentContainer(ReopenJobModal, {
  job: graphql`
    fragment ReopenJobModal_job on Job {
      id
      yields(first: null)
        @connection(key: "ReopenJobModal_job_yields", filters: []) {
        edges {
          node {
            id
            finalQuantity
            item {
              id
              lotTracking
            }
          }
        }
      }
    }
  `,
});

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateFormMutation($input: UpdateFormInput!) {
    updateForm(input: $input) {
      formEdge {
        node {
          id
          name
          description
          type
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

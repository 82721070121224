// @flow

import React from 'react';
import Panel from 'shared/components/common/Panel';
import { i18n } from 'shared/utils';
import moment from 'moment';
import { DateRangePicker } from 'shared/components/form';
import type { RouterHistory } from 'react-router';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import OverallProgress from './OverallProgress';

type Props = {
  history: RouterHistory,
  overallProgressStartAt: string,
  overallProgressEndAt: string,
  setFilters: (RouterHistory, Object) => void,
};

export default ({
  history,
  overallProgressStartAt,
  overallProgressEndAt,
  setFilters,
}: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    cacheConfig={{
      force: true,
    }}
    query={graphql`
      query OverallProgressPanelQuery(
        $overallProgressStartAt: DateTime!
        $overallProgressEndAt: DateTime!
      ) {
        metricsOverallProgress(
          startAt: $overallProgressStartAt
          endAt: $overallProgressEndAt
        ) {
          quantity
          quantityCompleted
        }
      }
    `}
    variables={{
      overallProgressStartAt,
      overallProgressEndAt,
    }}
    render={query => {
      if (!query.props) {
        return (
          <Panel style={{ marginTop: 24, height: 259 }}>
            <Loader />
          </Panel>
        );
      }

      return (
        <Panel
          style={{
            marginTop: 24,
          }}
          title={i18n.t('Overall Production Metrics')}
          headerRight={
            <DateRangePicker
              value={{
                startDate: moment(overallProgressStartAt),
                endDate: moment(overallProgressEndAt),
              }}
              onChange={({ startDate, endDate }) =>
                setFilters(history, {
                  overallProgressStartAt: moment(startDate)
                    .startOf('day')
                    .format(),
                  overallProgressEndAt: moment(endDate)
                    .endOf('day')
                    .format(),
                })
              }
            />
          }
        >
          <OverallProgress data={query.props.metricsOverallProgress} />
        </Panel>
      );
    }}
  />
);

// @flow

import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import ReactTooltip from 'react-tooltip';

type Props = {
  data: {
    name: string,
    value: number,
    id: string,
  }[],
};

type State = {
  activeIndex: number,
  showPieChart: boolean,
};

const COLORS = [
  colors.blueBlue,
  colors.greenBlue,
  colors.salmon,
  colors.macaroniAndCheese,
  colors.sapphire,
  colors.turquoise,
  colors.paleRed,
  colors.cobalt,
  colors.bluegreen,
];

const CustomPieChartWrapper = styled.div`
  width: 100%;
  height: 400px;

  g text {
    z-index: 1;
    overflow: none;
  }
`;

class CustomPieChart extends PureComponent<Props, State> {
  state = {
    activeIndex: 0,
    showPieChart: false,
  };
  customPieChartWrapper: any = React.createRef();

  componentDidMount() {
    this.setState({ showPieChart: true });
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      payload,
      percent,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const fill =
      payload.name !== 'No Data Available for the Selected Dates'
        ? COLORS[payload.index % COLORS.length]
        : colors.coolGrey;

    if (!this.customPieChartWrapper.current) {
      return;
    }

    const wrapperWidth = this.customPieChartWrapper.current.clientWidth;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <foreignObject
          x={cos >= 0 ? ex + 12 : 0}
          y={ey - 14}
          width={(cos >= 0 ? wrapperWidth - ex : ex) - 12}
          height={20}
          data-for={'pie-chart-sector-tooltip-' + payload.id}
          data-tip
        >
          <div
            style={{
              width: (cos >= 0 ? wrapperWidth - ex : ex) - 12,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: cos >= 0 ? 'left' : 'right',
            }}
          >
            {`${payload.name}`}
          </div>
        </foreignObject>
        {payload.name !== 'No Data Available for the Selected Dates' && (
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey + 2}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
          >
            {`${(percent * 100).toFixed(2)}%`}
          </text>
        )}
      </g>
    );
  };

  handlePieEnter = (data: any, index: number) => {
    this.setState({
      activeIndex: index,
    });
  };

  handlePieAnimationEnd = () => {
    ReactTooltip.rebuild();
  };

  render() {
    const { data } = this.props;
    const { activeIndex, showPieChart } = this.state;

    return (
      <CustomPieChartWrapper ref={this.customPieChartWrapper}>
        {showPieChart && (
          <>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={this.renderActiveShape}
                  data={data
                    .sort((a, b) => b.value - a.value)
                    .map((record, i) => ({ ...record, index: i }))}
                  innerRadius={60}
                  outerRadius={80}
                  fill={colors.coolGrey}
                  dataKey="value"
                  onMouseEnter={this.handlePieEnter}
                  onAnimationEnd={this.handlePieAnimationEnd}
                />
              </PieChart>
            </ResponsiveContainer>
          </>
        )}
        {data.map(record => (
          <ReactTooltip
            key={record.id}
            id={'pie-chart-sector-tooltip-' + record.id}
            place="top"
            type="info"
          >
            {record.name}
          </ReactTooltip>
        ))}
      </CustomPieChartWrapper>
    );
  }
}

export default CustomPieChart;

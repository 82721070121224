// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import SetupStartModal from 'shared/components/common/SetupStartModal';
import SetupPauseModal from 'shared/components/common/SetupPauseModal';
import SetupEndModal from 'shared/components/common/SetupEndModal';
import RunStartModal from 'shared/components/common/RunStartModal';
import RunPauseModal from 'shared/components/common/RunPauseModal';
import RunEndModal from 'shared/components/common/RunEndModal';
import RunControls from 'shared/components/common/RunControls';
import type { OperatorControls_run as RunFragment } from './__generated__/OperatorControls_run';

type Props = {
  run: RunFragment,
  onRunChanged: () => void,
};

type State = {
  setupStartModalOpen: boolean,
  setupPauseModalOpen: boolean,
  setupEndModalOpen: boolean,
  runStartModalOpen: boolean,
  runPauseModalOpen: boolean,
  runEndModalOpen: boolean,
};

class OperatorControls extends PureComponent<Props, State> {
  state = {
    setupStartModalOpen: false,
    setupPauseModalOpen: false,
    setupEndModalOpen: false,
    runStartModalOpen: false,
    runPauseModalOpen: false,
    runEndModalOpen: false,
  };

  handleOpenSetupStartModal = () => {
    this.setState({ setupStartModalOpen: true });
  };

  handleOpenSetupPauseModal = () => {
    this.setState({ setupPauseModalOpen: true });
  };

  handleOpenSetupEndModal = () => {
    this.setState({ setupEndModalOpen: true });
  };

  handleOpenRunStartModal = () => {
    this.setState({ runStartModalOpen: true });
  };

  handleOpenRunPauseModal = () => {
    this.setState({ runPauseModalOpen: true });
  };

  handleOpenRunEndModal = () => {
    this.setState({ runEndModalOpen: true });
  };

  handleCloseAllModals = () => {
    const { onRunChanged } = this.props;

    this.setState(
      {
        setupStartModalOpen: false,
        setupPauseModalOpen: false,
        setupEndModalOpen: false,
        runStartModalOpen: false,
        runPauseModalOpen: false,
        runEndModalOpen: false,
      },
      onRunChanged,
    );
  };

  render() {
    const {
      setupStartModalOpen,
      setupPauseModalOpen,
      setupEndModalOpen,
      runStartModalOpen,
      runPauseModalOpen,
      runEndModalOpen,
    } = this.state;
    const { run } = this.props;

    return (
      <>
        <RunControls
          run={run}
          onSetupStart={this.handleOpenSetupStartModal}
          onSetupPause={this.handleOpenSetupPauseModal}
          onSetupEnd={this.handleOpenSetupEndModal}
          onRunStart={this.handleOpenRunStartModal}
          onRunPause={this.handleOpenRunPauseModal}
          onRunEnd={this.handleOpenRunEndModal}
        />
        {setupStartModalOpen && (
          <SetupStartModal onClose={this.handleCloseAllModals} run={run} />
        )}
        {setupPauseModalOpen && (
          <SetupPauseModal onClose={this.handleCloseAllModals} run={run} />
        )}
        {setupEndModalOpen && (
          <SetupEndModal onClose={this.handleCloseAllModals} run={run} />
        )}
        {runStartModalOpen && (
          <RunStartModal onClose={this.handleCloseAllModals} run={run} />
        )}
        {runPauseModalOpen && (
          <RunPauseModal onClose={this.handleCloseAllModals} run={run} />
        )}
        {runEndModalOpen && (
          <RunEndModal onClose={this.handleCloseAllModals} run={run} />
        )}
      </>
    );
  }
}

export default createFragmentContainer(OperatorControls, {
  run: graphql`
    fragment OperatorControls_run on Run {
      id
      ...RunControls_run
      ...SetupStartModal_run
      ...SetupPauseModal_run
      ...SetupEndModal_run
      ...RunStartModal_run
      ...RunPauseModal_run
      ...RunEndModal_run
    }
  `,
});

/**
 * @flow
 * @relayHash aaa4869a1031f4d8c3142e06c24c9461
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddUpdateUserModal_user$ref = any;
export type CompanyUserRole = "ADMIN" | "SUPERUSER" | "USER" | "WORKER";
export type UsersQueryVariables = {||};
export type UsersQueryResponse = {|
  +users: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +userNumber: string,
        +email: any,
        +firstName: string,
        +lastName: string,
        +role: CompanyUserRole,
        +$fragmentRefs: AddUpdateUserModal_user$ref,
      |}
    |}>
  |}
|};
export type UsersQuery = {|
  variables: UsersQueryVariables,
  response: UsersQueryResponse,
|};
*/


/*
query UsersQuery {
  users(companyUserRoles: [ADMIN, USER, WORKER]) {
    edges {
      node {
        id
        userNumber
        email
        firstName
        lastName
        role
        ...AddUpdateUserModal_user
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment AddUpdateUserModal_user on User {
  id
  firstName
  lastName
  role
  email
  userNumber
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userNumber",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "role",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = [
  {
    "kind": "Literal",
    "name": "companyUserRoles",
    "value": [
      "ADMIN",
      "USER",
      "WORKER"
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UsersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "users",
        "name": "__Users_users_connection",
        "storageKey": null,
        "args": null,
        "concreteType": "UserConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "AddUpdateUserModal_user",
                    "args": null
                  }
                ]
              },
              (v7/*: any*/)
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UsersQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "users",
        "storageKey": "users(companyUserRoles:[\"ADMIN\",\"USER\",\"WORKER\"])",
        "args": (v9/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              (v7/*: any*/)
            ]
          },
          (v8/*: any*/)
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "users",
        "args": (v9/*: any*/),
        "handle": "connection",
        "key": "Users_users",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UsersQuery",
    "id": null,
    "text": "query UsersQuery {\n  users(companyUserRoles: [ADMIN, USER, WORKER]) {\n    edges {\n      node {\n        id\n        userNumber\n        email\n        firstName\n        lastName\n        role\n        ...AddUpdateUserModal_user\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AddUpdateUserModal_user on User {\n  id\n  firstName\n  lastName\n  role\n  email\n  userNumber\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "users"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5e6b3973f04a4f73d384ac3734f0365';
module.exports = node;

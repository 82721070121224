/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateOrderYieldModal_orderYield$ref = any;
type DeleteOrderYieldModal_orderYield$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderYieldPanels_orderYieldEdges$ref: FragmentReference;
declare export opaque type OrderYieldPanels_orderYieldEdges$fragmentType: OrderYieldPanels_orderYieldEdges$ref;
export type OrderYieldPanels_orderYieldEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +orderedQuantity: number,
    +order: {|
      +id: string
    |},
    +item: {|
      +id: string,
      +itemNumber: string,
      +name: string,
      +description: ?string,
      +partNumber: ?string,
      +formFieldValues: any,
      +quantityUOM: {|
        +id: string,
        +symbol: string,
      |},
    |},
    +$fragmentRefs: AddUpdateOrderYieldModal_orderYield$ref & DeleteOrderYieldModal_orderYield$ref,
  |},
  +$refType: OrderYieldPanels_orderYieldEdges$ref,
|}>;
export type OrderYieldPanels_orderYieldEdges$data = OrderYieldPanels_orderYieldEdges;
export type OrderYieldPanels_orderYieldEdges$key = {
  +$data?: OrderYieldPanels_orderYieldEdges$data,
  +$fragmentRefs: OrderYieldPanels_orderYieldEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrderYieldPanels_orderYieldEdges",
  "type": "OrderYieldEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "OrderYield",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "orderedQuantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "order",
          "storageKey": null,
          "args": null,
          "concreteType": "Order",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "item",
          "storageKey": null,
          "args": null,
          "concreteType": "Item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "itemNumber",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "description",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "partNumber",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "formFieldValues",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "quantityUOM",
              "storageKey": null,
              "args": null,
              "concreteType": "UOM",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "symbol",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateOrderYieldModal_orderYield",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "DeleteOrderYieldModal_orderYield",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c8cc851e54892b0ae056a034faf692b';
module.exports = node;

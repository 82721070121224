// @flow

import React from 'react';
import { i18n } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';
import styled from 'styled-components';

const DEFAULT_AVATARS = [
  'user',
  'user-2',
  'user-3',
  'user-4',
  'user-5',
  'bear',
  'dog',
  'giraffee',
  'horse',
  'pig',
  'tiger',
  'bird',
  'fox',
  'gorilla',
  'lion',
  'rhino',
  'cat',
  'gator',
  'hippo',
  'panda',
  'sheep',
];

type Props = {
  handleClick: (name: string) => void,
  selection: string,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 12px;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  margin: 24px 0 12px 0;
  color: ${colors.charcoalGrey};
  ${fonts.largeBold}
  text-align: center;

  @media (min-width: 1000px) {
    text-align: left;
  }
`;

const CDN_URL =
  'https://bucketeer-a4493a50-2635-4655-b662-2d5095810a9f.s3.amazonaws.com/public/static/default-avatars/';

const DefaultAvatars = (props: Props) => {
  const { handleClick, selection } = props;

  const defaultAvatars = DEFAULT_AVATARS.map(name => {
    return (
      <Image
        key={name}
        src={CDN_URL + name + '.png'}
        alt={name}
        onClick={() => handleClick(name)}
        style={
          selection === name
            ? {
                border: `1px solid ${colors.azul}`,
              }
            : {}
        }
      />
    );
  });

  return (
    <>
      <Text>{i18n.t('Or select one of our avatars')}</Text>
      <Wrapper>{defaultAvatars}</Wrapper>
    </>
  );
};

export default DefaultAvatars;

// @flow

import React from 'react';
import { i18n } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Divider from 'shared/components/common/Divider';
import type { RunYields_run as RunFragment } from './__generated__/RunYields_run';

type Props = {
  run: RunFragment,
};

const RunYields = (props: Props) => {
  const { run } = props;

  return (
    <>
      {(run.yields.edges || []).filter(Boolean).map((yieldEdge, i) => (
        <React.Fragment key={yieldEdge.node.id}>
          <Divider
            style={
              i === 0
                ? { marginBottom: 16 }
                : { marginTop: 16, marginBottom: 16 }
            }
          />
          <KeyValueGrid
            singleColumn
            fields={[
              {
                label: i18n.t('Item'),
                value: yieldEdge.node.item.name,
              },
              {
                label: i18n.t('Total Setup Quantity'),
                value: i18n.t('{{quantity, number}} {{uomSymbol, string}}', {
                  quantity: yieldEdge.setupQuantity || i18n.t('N/A'),
                  uomSymbol: yieldEdge.node.item.quantityUOM.symbol,
                }),
              },
              {
                label: i18n.t('Total Run Quantity'),
                value: i18n.t('{{quantity, number}} {{uomSymbol, string}}', {
                  quantity: yieldEdge.runQuantity || i18n.t('N/A'),
                  uomSymbol: yieldEdge.node.item.quantityUOM.symbol,
                }),
              },
              {
                label: i18n.t('Defect Quantity'),
                value: i18n.t('{{quantity, number}} {{uomSymbol, string}}', {
                  quantity: yieldEdge.defectQuantity || i18n.t('N/A'),
                  uomSymbol: yieldEdge.node.item.quantityUOM.symbol,
                }),
              },
            ]}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default createFragmentContainer(RunYields, {
  run: graphql`
    fragment RunYields_run on Run {
      id
      yields(first: null) {
        edges {
          defectQuantity
          setupQuantity
          runQuantity
          node {
            id
            item {
              id
              name
              quantityUOM {
                id
                symbol
              }
            }
          }
        }
      }
    }
  `,
});

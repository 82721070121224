/**
 * @flow
 * @relayHash 67ccfd57498122d14335eeeabb2d3a04
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ItemLots_itemLotEdges$ref = any;
export type ItemTransactionType = "ADD" | "DEDUCT" | "TRANSFER";
export type DeductItemQtyInput = {|
  itemId: string,
  lotId: string,
  quantity: number,
  notes?: ?string,
|};
export type DeductItemQtyMutationVariables = {|
  input: DeductItemQtyInput
|};
export type DeductItemQtyMutationResponse = {|
  +deductItemQty: {|
    +itemEdge: {|
      +node: {|
        +id: string,
        +totalQty: number,
        +totalValue: ?number,
        +logs: $ReadOnlyArray<{|
          +id: string,
          +user: {|
            +id: string
          |},
          +lot: {|
            +id: string
          |},
          +operation: ItemTransactionType,
          +quantity: number,
          +notes: ?string,
          +createdAt: any,
        |}>,
        +lots: {|
          +edges: ?$ReadOnlyArray<?{|
            +$fragmentRefs: ItemLots_itemLotEdges$ref
          |}>
        |},
      |}
    |}
  |}
|};
export type DeductItemQtyMutation = {|
  variables: DeductItemQtyMutationVariables,
  response: DeductItemQtyMutationResponse,
|};
*/


/*
mutation DeductItemQtyMutation(
  $input: DeductItemQtyInput!
) {
  deductItemQty(input: $input) {
    itemEdge {
      node {
        id
        totalQty
        totalValue
        logs {
          id
          user {
            id
          }
          lot {
            id
          }
          operation
          quantity
          notes
          createdAt
        }
        lots {
          edges {
            ...ItemLots_itemLotEdges
          }
        }
      }
    }
  }
}

fragment ItemLots_itemLotEdges on ItemLotEdge {
  quantity
  node {
    id
    lotNumber
    binNumber
    quantity
    expiresAt
  }
  ...ItemLotsTable_itemLotEdges
}

fragment ItemLotsTable_itemLotEdges on ItemLotEdge {
  quantity
  node {
    id
    lotNumber
    binNumber
    quantity
    expiresAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeductItemQtyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalQty",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalValue",
  "args": null,
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "logs",
  "storageKey": null,
  "args": null,
  "concreteType": "ItemTransactionLog",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": (v5/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "lot",
      "storageKey": null,
      "args": null,
      "concreteType": "Lot",
      "plural": false,
      "selections": (v5/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "operation",
      "args": null,
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeductItemQtyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deductItemQty",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeductItemQtyPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "itemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Item",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "lots",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItemLotConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ItemLotEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "ItemLots_itemLotEdges",
                            "args": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeductItemQtyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deductItemQty",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "DeductItemQtyPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "itemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Item",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "lots",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItemLotConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ItemLotEdge",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Lot",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "lotNumber",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "binNumber",
                                "args": null,
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "expiresAt",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeductItemQtyMutation",
    "id": null,
    "text": "mutation DeductItemQtyMutation(\n  $input: DeductItemQtyInput!\n) {\n  deductItemQty(input: $input) {\n    itemEdge {\n      node {\n        id\n        totalQty\n        totalValue\n        logs {\n          id\n          user {\n            id\n          }\n          lot {\n            id\n          }\n          operation\n          quantity\n          notes\n          createdAt\n        }\n        lots {\n          edges {\n            ...ItemLots_itemLotEdges\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ItemLots_itemLotEdges on ItemLotEdge {\n  quantity\n  node {\n    id\n    lotNumber\n    binNumber\n    quantity\n    expiresAt\n  }\n  ...ItemLotsTable_itemLotEdges\n}\n\nfragment ItemLotsTable_itemLotEdges on ItemLotEdge {\n  quantity\n  node {\n    id\n    lotNumber\n    binNumber\n    quantity\n    expiresAt\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c2889eacfceff5b6612a16ddc5dcfc7';
module.exports = node;

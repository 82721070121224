/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OverUnderRule = "ALLOW_OVERS" | "NO_OVERS" | "NO_UNDERS";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateJobModal_job$ref: FragmentReference;
declare export opaque type AddUpdateJobModal_job$fragmentType: AddUpdateJobModal_job$ref;
export type AddUpdateJobModal_job = {|
  +id: string,
  +name: string,
  +notes: ?string,
  +user: ?{|
    +id: string
  |},
  +workflow: {|
    +id: string,
    +name: string,
    +states: $ReadOnlyArray<{|
      +id: string,
      +isGangRun: ?boolean,
    |}>,
  |},
  +order: ?{|
    +id: string
  |},
  +yields: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +quantity: number,
        +itemNumberUp: number,
        +brokerCustomerOrderNumber: ?string,
        +formFieldValues: any,
        +overUnderRule: ?OverUnderRule,
        +overQuantity: ?number,
        +item: {|
          +id: string,
          +name: string,
          +quantityUOM: {|
            +id: string,
            +name: string,
            +symbol: string,
          |},
        |},
      |}
    |}>
  |},
  +$refType: AddUpdateJobModal_job$ref,
|};
export type AddUpdateJobModal_job$data = AddUpdateJobModal_job;
export type AddUpdateJobModal_job$key = {
  +$data?: AddUpdateJobModal_job$data,
  +$fragmentRefs: AddUpdateJobModal_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "AddUpdateJobModal_job",
  "type": "Job",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflow",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "states",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isGangRun",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "order",
      "storageKey": null,
      "args": null,
      "concreteType": "Order",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "yields",
      "storageKey": null,
      "args": null,
      "concreteType": "JobYieldConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "JobYieldEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobYield",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "itemNumberUp",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "brokerCustomerOrderNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "formFieldValues",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "overUnderRule",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "overQuantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "item",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "quantityUOM",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UOM",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "symbol",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '68efdb932751864f115b488d322fd417';
module.exports = node;

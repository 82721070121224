// @flow

/* eslint-disable react/forbid-component-props */

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import moment from 'moment';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n, WorkflowUtils } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import Icon from 'shared/components/common/Icon';
import Tooltip from 'shared/components/common/Tooltip';
import type { DraggableJobCard_job as JobFragment } from './__generated__/DraggableJobCard_job';
import dragIcon from './img/dragIcon.svg';
import RunList from './RunList';

type Props = {
  job: JobFragment,
  onViewOnSchedule: Object => void,
};

type State = {
  showRuns: boolean,
};

const OuterWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid ${colors.lightBlueGrey};
  margin-bottom: 8px;
  display: flex;
  flex-flow: column;
  background-color: ${colors.white};
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 5px;
  padding: 8px;

  &:hover {
    cursor: pointer;
    background-color: ${colors.paleGreyTwo};
  }
`;

const DragIcon = styled(dragIcon)`
  width: 14px;
  height: 20px;
  margin-right: 16px;
`;

const Name = styled.div`
  padding: 0 16px;
  ${fonts.smallBold};
  color: ${colors.charcoalGrey};
  text-overflow: ellipsis;
  flex: 1;
`;

const PromiseDate = styled.div`
  margin: 4px 0 0 0;
  ${fonts.smallRegular};
  color: ${p => (p.late ? colors.cherry : colors.battleshipGrey)};
  text-overflow: ellipsis;
  flex: 1;
`;

const ProgressWrapper = styled.div`
  padding-top: 8px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const ProgressCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 1px solid ${colors.battleshipGrey};
  background-color: ${p => (p.active ? colors.battleshipGrey : colors.white)};
  margin-right: 8px;
  margin-bottom: 4px;
`;

const TooltipWrapper = styled.div`
  strong {
    display: block;
    ${fonts.bodyBold};
    padding-bottom: 8px;
  }
`;

class DraggableJobCard extends PureComponent<Props, State> {
  state = {
    showRuns: false,
  };

  handleClickShowRuns = () => {
    this.setState(prevState => ({
      showRuns: !prevState.showRuns,
    }));
  };

  render() {
    const { job, onViewOnSchedule } = this.props;
    const { showRuns } = this.state;
    const sortedJobStates = WorkflowUtils.getSortedJobStates(job);

    return (
      <OuterWrapper>
        <Wrapper
          className="draggable-job"
          data-job-id={job.id}
          data-job-parallel_workflow={job.workflow.isParallelWorkflow}
          onClick={this.handleClickShowRuns}
        >
          <DragIcon className="draggable-icon" />
          <Pill width="auto">{job.jobNumber}</Pill>
          <Name>
            {job.name}
            {job.order?.promisedAt && (
              <PromiseDate
                late={moment().isAfter(moment(job.order?.promisedAt))}
              >
                {i18n.t('Promise Date  ')}
                {moment(job.order?.promisedAt).format('L')}
              </PromiseDate>
            )}
            <ProgressWrapper>
              {job.states
                .filter(
                  state =>
                    state.isEnabled && state.workflowState.type === 'RUN',
                )
                .sort((jobStateA, jobStateB) =>
                  sortedJobStates
                    ? (sortedJobStates[jobStateA.id]?.order || 0) -
                      (sortedJobStates[jobStateB.id]?.order || 0)
                    : 0,
                )
                .map(state => (
                  <React.Fragment key={state.id}>
                    <Tooltip
                      title={
                        <TooltipWrapper>
                          <strong>{state.workflowState.name}</strong>
                          {i18n.t('{{totalRuns}} Run(s)', {
                            totalRuns: state.runs.length,
                          })}
                        </TooltipWrapper>
                      }
                    >
                      <ProgressCircle
                        key={state.id}
                        active={state.runs.length}
                      />
                    </Tooltip>
                  </React.Fragment>
                ))}
            </ProgressWrapper>
          </Name>
          <Icon
            type="circle-new-window"
            size={24}
            onClick={() => window.open(`/job/${job.id}`)}
          />
        </Wrapper>
        {showRuns && <RunList onViewOnSchedule={onViewOnSchedule} job={job} />}
      </OuterWrapper>
    );
  }
}

export default createFragmentContainer(DraggableJobCard, {
  job: graphql`
    fragment DraggableJobCard_job on Job {
      id
      jobNumber
      name
      ...RunList_job
      ...WorkflowUtils_job @relay(mask: false)
      workflow {
        id
        isParallelWorkflow
      }
      order {
        id
        promisedAt
      }
      states {
        id
        isEnabled
        workflowState {
          id
          name
          type
        }
        runs {
          id
          createdAt
          status
        }
      }
    }
  `,
});

// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import { renderCosmeticFieldValue } from 'shared/components/form/FormBuilder';
import SidePanel from 'shared/components/common/SidePanel';
import PanelSection from 'shared/components/common/SidePanel/PanelSection';
import Divider from 'shared/components/common/Divider';
import MultilineText from 'shared/components/common/MultilineText';
import type { ItemSidePanel_item as ItemFragment } from './__generated__/ItemSidePanel_item';

type Props = {
  item: ItemFragment,
};

const ItemSidePanel = ({ item }: Props) => (
  <>
    <SidePanel>
      <PanelSection
        title="Measurement Info"
        fields={[
          {
            label: i18n.t('Dimensions'),
            value: `${item.dimensionWidth || 0} x ${item.dimensionLength ||
              0} x ${item.dimensionHeight || 0} ${item.dimensionUOM?.symbol ||
              ''}`,
          },
          {
            label: i18n.t('Weight'),
            value: `${item.weight || ''} ${item.weightUOM?.symbol || ''}`,
          },
          {
            label: i18n.t('Unit of Measurement'),
            value: `${item.quantityUOM.name} (${item.quantityUOM.symbol})`,
          },
        ]}
      />
      <Divider />
      <PanelSection
        title="Product Info"
        fields={[
          {
            label: i18n.t('Category'),
            value: item.category?.name || '',
          },
          {
            label: i18n.t('UPC'),
            value: item.upc || '',
          },
          {
            label: i18n.t('Part #'),
            value: item.partNumber || '',
          },
          {
            label: i18n.t('Cost Per Item'),
            value: item.cost || '',
          },
          {
            label: i18n.t('Description'),
            value: <MultilineText>{item.description}</MultilineText>,
          },
          {
            label: i18n.t('Lot Tracking'),
            value: item.lotTracking ? i18n.t('Enabled') : i18n.t('Disabled'),
          },
          {
            label: i18n.t('Safety Quantity'),
            value: item.safetyQuantity,
          },
        ].concat(
          item.form.fields.map(customField => ({
            label: customField.name,
            value: renderCosmeticFieldValue({
              formFieldType: customField.type,
              value: item.formFieldValues[customField.id] || '',
            }),
          })),
        )}
      />
    </SidePanel>
  </>
);

export default createFragmentContainer(ItemSidePanel, {
  item: graphql`
    fragment ItemSidePanel_item on Item {
      id
      itemNumber
      upc
      weight
      dimensionWidth
      dimensionLength
      dimensionHeight
      partNumber
      description
      safetyQuantity
      category {
        id
        name
      }
      quantityUOM {
        id
        name
        symbol
      }
      weightUOM {
        id
        symbol
      }
      dimensionUOM {
        id
        symbol
      }
      lotTracking
      cost
      formFieldValues
      form {
        id
        ...FormBuilder_form
        fields {
          id
          systemKey
          name
          type
        }
      }
    }
  `,
});

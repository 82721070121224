// @flow

/* eslint-disable react/sort-comp */

import React, { PureComponent, createRef } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import debounce from 'lodash.debounce';
import moment from 'moment';
import * as Actions from 'main-app/store/Actions';
import { i18n, localizeEnum } from 'shared/utils';
import { colors } from 'shared/styleguide';
import UpdateRunMutation from 'main-app/mutations/UpdateRun';
import memoize from 'memoize-one';
import ChartView from './ChartView';
import type { ProductionScheduleChart_data as ProductionScheduleChartFragment } from './__generated__/ProductionScheduleChart_data.graphql';
import { DowntimeInfoBox, InfoBox } from './InfoBox';
import type { DowntimeInfoBoxProps, InfoBoxProps } from './InfoBox';

type Props = {
  initialStartTime: moment$Moment,
  initialEndTime: moment$Moment,
  data: ProductionScheduleChartFragment,
  relay: Function,
  selectedJobId: ?string,
  onRunSelect: Object => void,
  onRunDeselect: void => void,
  onRunDoubleClick: Object => void,
};

type State = {
  addRunModalProps: ?{
    job: Object,
    defaultMachine: Object,
    defaultRunAt: number,
    onSuccess: () => void,
  },
  machines: Array<Object>,
  scheduleEvents: Array<Object>,
  downtimeInfoBox?: DowntimeInfoBoxProps,
  infoBox?: InfoBoxProps,
};

class ProductionScheduleChart extends PureComponent<Props, State> {
  chartViewRef: any = createRef();
  activeRefetch = null;

  state = {
    addRunModalProps: null,
    machines: [],
    scheduleEvents: [],
    downtimeInfoBox: undefined,
    infoBox: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = this.computeState();

    this.handleVisibleTimeChange = debounce(this.handleVisibleTimeChange, 1000);
  }

  computeState = () => {
    const { data } = this.props;
    const { addRunModalProps } = this.state;

    return {
      addRunModalProps,
      machines: this.massageMachines(data.machines),
      scheduleEvents: data.scheduleEvents.edges
        ? data.scheduleEvents.edges.filter(Boolean).map(({ node }) => node)
        : [],
      downtimeInfoBox: undefined,
      infoBox: undefined,
    };
  };

  resetState = () => {
    this.setState(this.computeState(), () => {
      const { relay } = this.props;

      this.activeRefetch = relay.refetch(
        fragmentVariables => fragmentVariables,
        null,
        () => {
          this.activeRefetch = null;
          this.setState(this.computeState());
        },
      );
    });
  };

  massageRunForChart = (
    run: Object,
    overrideName?: string,
    overrideStartTime?: string = '',
    overrideEndTime?: string = '',
    overrideColorMap?: Object = {},
  ) => {
    const startAt = moment(overrideStartTime || run.scheduledStartAt);
    let endAt = moment(overrideEndTime || run.scheduledEndAt);

    if (endAt.isBefore(startAt)) {
      endAt = moment();
    }

    return {
      id: 'RUN' + run.id,
      runId: run.id,
      machineId: run.machine.id,
      jobId: run.job.id,
      name: `${run.job.importJobNumber || run.job.jobNumber}  ${run.job.name}`,
      type: 'RUN_SCHEDULED',
      startAt,
      endAt,
    };
  };

  massageMachines = machines => {
    if (machines && machines.edges) {
      return machines.edges.filter(Boolean).map(({ node }, i) => ({
        ...node,
        title: node.name,
        bgColor: i % 2 === 0 ? colors.paleGreyThree : colors.white,
      }));
    }

    return [];
  };

  hideInfoBox = () => {
    const { infoBox } = this.state;
    if (infoBox) {
      return this.setState({ infoBox: undefined });
    }
  };

  hideDowntimeInfoBox = () => {
    const { downtimeInfoBox } = this.state;
    if (downtimeInfoBox) {
      return this.setState({ downtimeInfoBox: undefined });
    }
  };

  handleEventMove = (id, startAt, newGroupOrder) => {
    const { scheduleEvents, machines } = this.state;

    this.hideInfoBox();
    const machine = machines[newGroupOrder];
    const event = scheduleEvents.find(event => event.id === id);
    const eventMachine = machines.find(
      machine => machine.id === event?.machineId,
    );

    // Only job runs can be moved
    if (!event || !event.runId) {
      return;
    }

    if (
      event &&
      eventMachine &&
      event?.machineId !== machine.id &&
      eventMachine?.type.id !== machine.type.id
    ) {
      Actions.alertNotification(
        i18n.t(
          `Sorry! You can't move a {{machineType1}} run to a {{machineType2}} work center.`,
          {
            machineType1: localizeEnum(eventMachine.type.name || ''),
            machineType2: localizeEnum(machine.type.name),
          },
        ),
        'error',
      );
      return;
    }

    let endAt;

    if (this.activeRefetch) {
      this.activeRefetch.dispose();
    }

    this.setState(
      {
        scheduleEvents: scheduleEvents.map(event => {
          if (event.id === id) {
            endAt =
              startAt +
              (moment(event.endAt).valueOf() - moment(event.startAt).valueOf());

            return {
              ...event,
              startAt,
              endAt,
              machineId: machine.id,
            };
          }

          return event;
        }),
      },
      async () => {
        await UpdateRunMutation.commit({
          variables: {
            input: {
              id: event.runId,
              machineId: machine.id,
              scheduledStartAt: moment(startAt).format(),
              scheduledEndAt: endAt ? moment(endAt).format() : undefined,
            },
          },
        });
      },
    );
  };

  handleEventResize = (id, time, edge) => {
    const { scheduleEvents } = this.state;

    let updatedRun;
    this.hideInfoBox();
    if (this.activeRefetch) {
      this.activeRefetch.dispose();
    }

    let foundEvent: any;

    this.setState(
      {
        scheduleEvents: scheduleEvents.map(event => {
          if (event.id === id) {
            foundEvent = event;

            updatedRun = {
              ...event,
              startAt: edge === 'left' ? time : event.startAt,
              endAt: edge === 'left' ? event.endAt : time,
            };

            return updatedRun;
          }

          return event;
        }),
      },
      async () => {
        await UpdateRunMutation.commit({
          variables: {
            input: {
              id: foundEvent.runId,
              scheduledStartAt: moment(updatedRun.startAt).format(),
              scheduledEndAt: updatedRun.endAt
                ? moment(updatedRun.endAt).format()
                : undefined,
            },
          },
        });
      },
    );
  };

  handleCloseAllModals = () => {
    this.setState({
      addRunModalProps: null,
    });
  };

  handleVisibleTimeChange = (start, end) => {
    const { relay } = this.props;

    this.activeRefetch = relay.refetch(
      fragmentVariables => ({
        ...fragmentVariables,
        startAt: moment(start)
          .add(-2, 'week')
          .format(),
        endAt: moment(end)
          .add(2, 'week')
          .format(),
      }),
      null,
      () => {
        this.activeRefetch = null;
        this.setState(this.computeState());
      },
    );
  };

  hydrateJobsAfterAutoSchedule = runs => {
    const { scheduleEvents } = this.state;

    this.setState({
      scheduleEvents: [
        ...scheduleEvents,
        ...runs.map(run => this.massageRunForChart(run)),
      ],
    });
  };

  dropJobOntoSchedule = (pageX, pageY, job) => {
    const { scheduleEvents, machines } = this.state;
    const { start, machineId } = this.chartViewRef.computeDropParams(
      pageX,
      pageY,
    );
    const machine = machines.find(machine => machine.id === machineId);

    if (!machine) {
      return;
    }

    // Ensure that there isn't already a run scheduled within the confines of this run
    const runsOnMachine = scheduleEvents.filter(
      event => event.group === machineId,
    );

    if (
      !job.states
        .filter(state => state.isEnabled)
        .some(
          jobState =>
            jobState.workflowState.machineType?.id === machine.type.id,
        )
    ) {
      Actions.alertNotification(
        i18n.t(
          `Sorry! This work center is not associated to the job. Please try again.`,
          {
            machineTypeName: localizeEnum(machine.type.name),
          },
        ),
        'error',
      );

      return;
    }

    if (!machine.workflows.find(workflow => workflow.id === job.workflow.id)) {
      Actions.alertNotification(
        i18n.t(
          `Sorry! This job can't be scheduled here. The ${machine.name} work center is not part of this job's workflow.`,
        ),
        'error',
      );

      return;
    }

    // TODO: Take into account estimated end time as well. May need to do this on the backend as it's impossible to guess what the real end-time is from the frontend
    const conflictingRun = runsOnMachine.some(run => {
      const runStart = moment(run.startAt).valueOf();
      const runEnd = moment(run.endAt).valueOf();

      return start >= runStart && start <= runEnd;
    });

    if (conflictingRun) {
      Actions.alertNotification(
        i18n.t(
          'Sorry! There is already a job scheduled at this time. Please try again.',
        ),
        'error',
      );
      return;
    }

    if (this.activeRefetch) {
      this.activeRefetch.dispose();
    }

    // This setState update will bring the "Add Run" modal into view
    this.setState({
      addRunModalProps: {
        job,
        defaultMachine: machine,
        defaultRunAt: start,
        onSuccess: (run: Object) => {
          const { scheduleEvents } = this.state;

          this.setState({
            scheduleEvents: [...scheduleEvents, this.massageRunForChart(run)],
          });
        },
      },
    });
  };

  getMachineFromId = memoize((machines: Array<Object>, id: string) => {
    return machines.find(i => i.id === id);
  });

  getSchedulerEventFromId = memoize(
    (scheduleEvents: Array<Object>, id: string) => {
      return scheduleEvents.find(i => i.id === id);
    },
  );

  getInfoBoxPropsFromItemId = memoize(
    (
      scheduleEvents: Array<any>,
      machines: Array<any>,
      id: string,
      eventType: 'move' | 'resize' | 'hover',
      time: number,
      edge?: 'left' | 'right',
      newGroupOrder?: number,
    ): ?InfoBoxProps => {
      const event = this.getMachineFromId(scheduleEvents, id);
      if (!event) return;
      const originalMachine = this.getMachineFromId(machines, event.machineId);
      if (!originalMachine) return;
      const isNewMachine =
        newGroupOrder && machines[newGroupOrder].id !== originalMachine.id;
      const machine =
        isNewMachine && newGroupOrder
          ? machines[newGroupOrder]
          : originalMachine;
      const originalStartTime = moment(event.startAt);
      const originalEndTime = moment(event.endAt);
      const newStartTime =
        eventType === 'move' || (edge && edge === 'left')
          ? moment(`${time}`, 'x')
          : originalStartTime;
      const newEndTime =
        edge && edge === 'right' ? moment(`${time}`, 'x') : originalEndTime;
      const duration =
        eventType === 'move'
          ? // if move then duration did not change than original duration
            moment.duration(originalEndTime.diff(originalStartTime))
          : // if resizing then duration is different than original and should be calculated from new times
            moment.duration(newEndTime.diff(newStartTime));
      return {
        eventType,
        name: event.name,
        date: newStartTime,
        workCenterName: machine.name,
        duration: duration,
      };
    },
  );

  handleItemDrag = (args: {
    eventType: 'move' | 'resize',
    itemId: any,
    time: number,
    edge: 'left' | 'right',
    newGroupOrder: number,
  }) => {
    const { eventType, itemId, time, edge, newGroupOrder } = args;
    const { scheduleEvents, machines } = this.state;
    const infoBox = this.getInfoBoxPropsFromItemId(
      scheduleEvents,
      machines,
      itemId,
      eventType,
      time,
      edge,
      newGroupOrder,
    );
    if (!infoBox) return;
    this.setState({
      infoBox,
    });
  };

  handleMouseEnterRun = (run: Object) => {
    const { machines, scheduleEvents } = this.state;
    if (run.type === 'DOWNTIME') {
      const machine = this.getMachineFromId(machines, run.machineId);
      if (!machine) return;
      const downtimeInfoBox: DowntimeInfoBoxProps = {
        downtimeName: run.name,
        date: run.startAt,
        duration: moment.duration(run.endAt.diff(run.startAt)),
        workCenterName: machine.name,
      };
      this.setState({
        downtimeInfoBox,
      });
    } else {
      const infoBoxProps = this.getInfoBoxPropsFromItemId(
        scheduleEvents,
        machines,
        run.id,
        'hover',
        run.startAt.valueOf(),
      );
      if (!infoBoxProps) return;
      this.setState({
        infoBox: infoBoxProps,
      });
    }
  };

  handleMouseLeaveRun = () => {
    this.hideInfoBox();
    this.hideDowntimeInfoBox();
  };

  render() {
    const {
      machines,
      scheduleEvents,
      addRunModalProps,
      infoBox,
      downtimeInfoBox,
    } = this.state;
    const {
      initialStartTime,
      initialEndTime,
      selectedJobId,
      onRunSelect,
      onRunDeselect,
      onRunDoubleClick,
    } = this.props;

    return (
      <>
        <ChartView
          ref={r => (this.chartViewRef = r)}
          onEventMove={this.handleEventMove}
          onEventResize={this.handleEventResize}
          onVisibleTimeChange={this.handleVisibleTimeChange}
          onCloseAllModals={this.handleCloseAllModals}
          initialStartTime={initialStartTime}
          initialEndTime={initialEndTime}
          machines={machines}
          scheduleEvents={scheduleEvents}
          selectedJobId={selectedJobId}
          addRunModalProps={addRunModalProps}
          onRunSelect={onRunSelect}
          onRunDeselect={onRunDeselect}
          onRunDoubleClick={onRunDoubleClick}
          onItemDrag={this.handleItemDrag}
          onMouseEnterRun={this.handleMouseEnterRun}
          onMouseLeaveRun={this.handleMouseLeaveRun}
        />
        {infoBox && <InfoBox {...infoBox} />}
        {downtimeInfoBox && <DowntimeInfoBox {...downtimeInfoBox} />}
      </>
    );
  }
}

export default createRefetchContainer(
  ProductionScheduleChart,
  {
    data: graphql`
      fragment ProductionScheduleChart_data on Query
        @argumentDefinitions(
          machineTypeId: { type: "ID", defaultValue: null }
          workflowIds: { type: "[ID!]", defaultValue: null }
          startAt: { type: "DateTime!" }
          endAt: { type: "DateTime!" }
        ) {
        machines(
          machineTypeId: $machineTypeId
          workflowIds: $workflowIds
          sortBy: { field: PRODUCTION_ORDER, direction: ASC }
        ) {
          edges {
            node {
              id
              name
              type {
                id
                name
              }
              currentDowntime {
                id
              }
              workflows {
                id
              }
              ...AddUpdateRunModal_defaultMachine
            }
          }
        }

        # FIXME: add workflowIds to this
        scheduleEvents(first: null, startAt: $startAt, endAt: $endAt)
          @connection(
            key: "ProductionScheduleChart_scheduleEvents"
            filters: []
          ) {
          edges {
            node {
              id
              runId
              machineId
              jobId
              name
              type
              startAt
              endAt
            }
          }
        }
      }
    `,
  },
  graphql`
    query ProductionScheduleChartQuery(
      $machineTypeId: ID
      $workflowIds: [ID!]
      $startAt: DateTime!
      $endAt: DateTime!
    ) {
      ...ProductionScheduleChart_data
        @arguments(
          machineTypeId: $machineTypeId
          workflowIds: $workflowIds
          startAt: $startAt
          endAt: $endAt
        )
    }
  `,
);

// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import UpdateJobStatusMutation from 'main-app/mutations/UpdateJobStatus';
import AddLotMutation from 'shared/mutations/AddLot';
import Button from 'shared/components/common/Button';
import Divider from 'shared/components/common/Divider';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldArray,
  FieldGroup,
  SelectLotField,
} from 'shared/components/form';
import type { CloseJobModal_job as JobFragment } from './__generated__/CloseJobModal_job';

type Props = {
  onClose: () => void,
  job: JobFragment,
};

const CloseJobModal = ({ onClose, job }: Props) => {
  const jobYields = job.yields.edges
    ? job.yields.edges.filter(Boolean).map(edge => edge.node)
    : [];

  const isLotTrackingEnabled = jobYields.some(
    jobYield => jobYield.item.lotTracking,
  );

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          jobYieldQuantities: jobYields.map(jobYield => ({
            jobYield,
            finalQuantity: jobYield.finalQuantity || jobYield.quantity,
          })),
          toLot: '',
          notes: '',
        }}
        validationSchema={Yup.object().shape({
          jobYieldQuantities: Yup.array().of(
            Yup.object().shape({
              jobYield: Yup.object()
                .nullable()
                .required(),
              finalQuantity: Yup.number().required('Required'),
            }),
          ),
          toLot: Yup.string().when('$isLotTrackingEnabled', {
            is: () => isLotTrackingEnabled,
            then: Yup.string()
              .nullable()
              .required('Required'),
            otherwise: Yup.string(),
          }),
          notes: Yup.string(),
        })}
        isInitialValid
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          try {
            let lotId = isLotTrackingEnabled ? values.toLot?.value : null;

            if (values.toLot && values.toLot.__isNew__) {
              const { addLot } = await AddLotMutation.commit({
                variables: {
                  input: {
                    lotNumber: values.toLot?.value,
                  },
                },
              });

              lotId = addLot.lotEdge.node.id;
            }

            await UpdateJobStatusMutation.commit({
              variables: {
                input: {
                  id: job.id,
                  status: 'CLOSED',
                  jobYieldQuantities: values.jobYieldQuantities.map(record => ({
                    jobYieldId: record.jobYield.id,
                    itemId: record.jobYield.item.id,
                    finalQuantity: parseFloat(record.finalQuantity),
                    lotTracking: record.jobYield.item.lotTracking,
                  })),
                  notes: values.notes,
                  lotId,
                },
              },
            });

            onClose();

            Actions.alertNotification(
              i18n.t('Job Successfully Closed'),
              'success',
            );
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          dirty,
        }) => (
          <Form>
            <ModalHeader header={i18n.t('Close Job?')} onClose={onClose} />
            <ModalBody withPadding>
              <div style={{ marginBottom: 24 }}>
                {i18n.t(
                  'Are you sure you want to close this Job? This usually happens when production is completed offline or production is abandoned.',
                )}
              </div>
              {Boolean(values.jobYieldQuantities.length) && (
                <>
                  {i18n.t(
                    `Adjust the produced quantities for each item after closing the Job.`,
                  )}
                  <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                </>
              )}
              <FieldArray
                name="jobYieldQuantities"
                render={arrayHelpers =>
                  values.jobYieldQuantities.map((record, i) => (
                    <FieldGroup
                      key={record.jobYield.id}
                      label={i18n.t('Produced Quantity for {{itemName}}', {
                        itemName: record.jobYield.item.name,
                      })}
                      name={`jobYieldQuantities.${i}.finalQuantity`}
                      extendedLabel={record.jobYield.item.quantityUOM.symbol}
                      size="small"
                    />
                  ))
                }
              />
              {Boolean(values.jobYieldQuantities.length) &&
                isLotTrackingEnabled && (
                  <>
                    <SelectLotField
                      name="toLot"
                      label={i18n.t('Add to Lot')}
                      creatable
                      clearable
                      error={errors.toLot}
                      creatingNewRecord={values.toLot && values.toLot.__isNew__}
                    />
                    <FieldGroup
                      name="notes"
                      label={i18n.t('Enter a Note')}
                      error={errors.notes}
                      type="textarea"
                    />
                  </>
                )}
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                theme="blue"
                disabled={!isValid && values.jobYieldQuantities.length}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {i18n.t('Close Job')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

export default createFragmentContainer(CloseJobModal, {
  job: graphql`
    fragment CloseJobModal_job on Job {
      id
      yields(first: null)
        @connection(key: "CloseJobModal_job_yields", filters: []) {
        edges {
          node {
            id
            quantity
            finalQuantity
            item {
              id
              name
              quantityUOM {
                id
                symbol
              }
              lotTracking
            }
          }
        }
      }
    }
  `,
});

// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddItemChildMutation from 'main-app/mutations/AddItemChild';
import UpdateItemChildMutation from 'main-app/mutations/UpdateItemChild';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  SelectItemField,
  FieldGroup,
} from 'shared/components/form';

type Props = {
  onClose: () => void,
  parentItemId: string,
  excludeIds: string[],
  childItem: ?Object,
};

const AddUpdateItemChildModal = ({
  onClose,
  parentItemId,
  excludeIds,
  childItem,
}: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        childItem: childItem || null,
        usageQty: childItem?.usageQty || '',
      }}
      validationSchema={Yup.object().shape({
        childItem: Yup.object()
          .nullable()
          .required('Required'),
        usageQty: Yup.number(),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        const { usageQty } = values;

        const mutation = childItem
          ? UpdateItemChildMutation
          : AddItemChildMutation;

        try {
          await mutation.commit({
            variables: {
              input: {
                parentItemId,
                usageQty: parseFloat(usageQty),
                childItemId: childItem ? childItem.id : values?.childItem.value,
              },
            },
          });

          onClose();
          Analytics.trackEvent(`${childItem ? 'Update' : 'Add'} Component`, {
            subComponentName: childItem
              ? childItem.name
              : values?.childItem?.data?.name,
          });
          Actions.alertNotification(
            i18n.t(`Component Successfully ${childItem ? 'Updated' : 'Added'}`),
            'success',
          );
        } catch (e) {
          setSubmitting(false);

          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader header={i18n.t('Add Component')} onClose={onClose} />
          <ModalBody withPadding>
            {childItem ? (
              <FieldGroup
                label={i18n.t('Item Name')}
                name="childItem"
                error={errors.childItem}
                value={childItem.name}
                disabled
              />
            ) : (
              <SelectItemField
                name="childItem"
                label={i18n.t('Search For Item')}
                placeholder={i18n.t('Select Item...')}
                error={errors.childItem}
                excludeIds={[parentItemId, ...excludeIds]}
              />
            )}
            <FieldGroup
              label={i18n.t('Estimated Usage')}
              name="usageQty"
              type="number"
              placeholder={i18n.t('Enter the estimated usage')}
              extendedLabel={
                childItem
                  ? childItem?.quantityUOM.symbolvalues
                  : values?.childItem?.data.quantityUOM?.symbol
              }
              error={errors.usageQty}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t('Add Component')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default AddUpdateItemChildModal;

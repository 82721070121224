// @flow

import React from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddWorkflowMutation from 'main-app/mutations/AddWorkflow';
import UpdateWorkflowPartialMutation from 'main-app/mutations/UpdateWorkflowPartial';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import type { AddUpdateWorkflowModal_workflow as WorkflowFragment } from './__generated__/AddUpdateWorkflowModal_workflow';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  workflow: ?WorkflowFragment,
};

const LANGUAGE = {
  update: {
    title: i18n.t('Edit Workflow'),
    submitButton: i18n.t('Update Workflow'),
    successTitle: i18n.t('Workflow Successfully Updated'),
  },
  add: {
    title: i18n.t('Create Workflow'),
    submitButton: i18n.t('Create Workflow'),
    successTitle: i18n.t('Workflow Successfully Created'),
  },
};

const AddUpdateWorkflowModal = ({ workflow, onClose, onSuccess }: Props) => {
  const language = LANGUAGE[workflow ? 'update' : 'add'];

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          name: workflow?.name || '',
          isDefault: workflow?.isDefault || false,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const mutation = workflow
            ? UpdateWorkflowPartialMutation
            : AddWorkflowMutation;

          const input: any = {
            name: values.name,
            isDefault: values.isDefault,
          };

          if (workflow) {
            input.workflowId = workflow.id;
          }

          try {
            await mutation.commit({
              variables: {
                input,
              },
            });

            if (onSuccess) {
              onSuccess();
            }
            Actions.alertNotification(
              {
                title: language.successTitle,
                body: null,
              },
              'success',
            );
            onClose();
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <ModalHeader header={language.title} onClose={onClose} />
            <ModalBody withPadding>
              <FieldGroup
                name="name"
                label={i18n.t('Workflow Name')}
                placeholder={i18n.t('Workflow Name')}
                error={errors.name}
              />
              <FieldGroup
                name="isDefault"
                label={i18n.t('Default Workflow')}
                type="toggle"
                tooltip={i18n.t(
                  'The default workflow will be auto selected when creating a job.',
                )}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                theme="blue"
                disabled={!isValid}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {language.submitButton}
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

AddUpdateWorkflowModal.defaultProps = {
  onSuccess: undefined,
};

export default createFragmentContainer(AddUpdateWorkflowModal, {
  workflow: graphql`
    fragment AddUpdateWorkflowModal_workflow on Workflow {
      id
      name
      isDefault
    }
  `,
});

// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      id: string,
    },
  },
};

const mutation = graphql`
  mutation DeleteOrderMutation($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      deletedId
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: ROOT_ID,
          connectionKeys: [{ key: 'PaginatedOrdersContainer_orders' }],
          pathToConnection: [ROOT_ID, 'orders'],
          deletedIDFieldName: 'deletedId',
        },
      ],
    });
  },
};

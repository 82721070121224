// @flow

import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import Icon from 'shared/components/common/Icon';

type KeyValueField = {
  label: string,
  value: string | React$Node,
  onClick?: () => void,
  onDoubleClick?: () => void,
};

type Props = {
  fields: Array<KeyValueField>,
  style?: Object,
  title: string,
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 32px;
`;

const Title = styled.div`
  ${fonts.smallRegular};
  color: ${colors.battleshipGrey};
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: none;

  li {
    ${fonts.bodyRegular};
    color: ${colors.charcoalGrey};
    padding-bottom: 8px;
    word-break: break-word;

    /* Title labels are bold */
    &:nth-child(even) {
      ${fonts.bodyBold};
      padding-top: 8px;
    }

    &:nth-last-child(-n + 1) {
      padding-bottom: 16px;
    }
  }

  @media (min-width: 1000px) {
    li {
      padding-right: 16px;
    }
  }
`;

/**
 * A one column panel that shows a series of key/value pairs of data
 * Useful for things like quickly outputting all of the information within a custom form
 * @param {Object} props
 * @return {React$Node}
 */

const PanelSection = ({ fields, title, style }: Props) => {
  // react hooks allow state in function components (stateless components)
  const [visible, setVisibility] = useState(true);

  return (
    <Wrapper style={style}>
      <Header>
        {title && <Title>{title}</Title>}
        <Icon
          type={visible ? 'circle-arrow-up' : 'circle-arrow-down'}
          size={24}
          onClick={() => setVisibility(!visible)}
        />
      </Header>

      {visible &&
        fields.map(field => {
          const { onClick, onDoubleClick } = field;

          return [
            <li key={`${field.label}-label`}>{field.label}</li>,
            <li
              key={`${field.label}-value`}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
            >
              {field.value}
            </li>,
          ];
        })}
    </Wrapper>
  );
};

PanelSection.defaultProps = {
  style: undefined,
};

export default PanelSection;

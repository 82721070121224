// @flow

import React, { PureComponent } from 'react';

type Props = {
  children: *,
};

type State = {};

class TrayWidget extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children } = this.props;

    return (
      <div style={{ width: '100%' }} className="tray">
        {children}
      </div>
    );
  }
}

export default TrayWidget;

// @flow

import { AbstractNodeFactory, DiagramEngine } from 'storm-react-diagrams';
import React from 'react';
import IconWidget from './IconWidget';
import IconNodeModel from './IconNodeModel';

class IconNodeFactory extends AbstractNodeFactory {
  constructor(icon: string) {
    super(icon);
    this.icon = icon;
  }

  generateReactWidget = (diagramEngine: DiagramEngine, node: IconNodeModel) => {
    const {
      data: {
        name,
        runMinutesOverride,
        form,
        user,
        isRequired,
        status,
        isEnabled,
        jobStateId,
        type,
      },
    } = node;

    const IconWidgetProps = {
      icon: this.icon,
      name,
      userLabel: user?.label,
      formLabel: form?.label,
      runMinutesOverride,
      isRequired,
      status,
      diagramEngine,
      node,
      leftPortComplete: false,
      rightPortComplete: false,
      isEnabled,
      jobStateId,
      type,
    };

    if (
      node.data.status !== 'COMPLETE' &&
      node.ports?.left?.links &&
      Object.values(node.ports.left.links).every(
        (link: any) => link.data?.complete,
      )
    ) {
      IconWidgetProps.leftPortComplete = true;
    }
    if (
      node.data.status === 'COMPLETE' &&
      node.ports?.left?.links &&
      Object.values(node.ports.left.links).every(
        (link: any) => link.data?.complete,
      )
    ) {
      IconWidgetProps.leftPortComplete = true;
    }
    if (
      node.data.status === 'COMPLETE' &&
      node.ports?.right?.links &&
      Object.values(node.ports.right.links).every(
        (link: any) => link.data?.complete,
      )
    ) {
      IconWidgetProps.rightPortComplete = true;
    }

    return <IconWidget {...IconWidgetProps} />;
  };

  getNewInstance = () => new IconNodeModel(this.icon);
}

export default IconNodeFactory;

// @flow

import React, { PureComponent } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import type { RouterHistory } from 'react-router';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddUpdateJobLinkedItemModal from 'main-app/components/AddUpdateJobLinkedItemModal';
import DeleteJobLinkedItemModal from 'main-app/components/DeleteJobLinkedItemModal';
import type { JobLinkedItemsTable_jobLinkedItemEdges as JobLinkedItemEdgesFragment } from './__generated__/JobLinkedItemsTable_jobLinkedItemEdges';

type Props = {
  history: RouterHistory,
  jobLinkedItemEdges: JobLinkedItemEdgesFragment,
  jobId: string,
};

type State = {
  deleteJobLinkedItemRecord: ?Object,
  updateJobLinkedItemRecord: ?Object,
};

class JobLinkedItemsTable extends PureComponent<Props, State> {
  menuTriggerRefs = {};
  state = {
    deleteJobLinkedItemRecord: null,
    updateJobLinkedItemRecord: null,
  };

  getItemById = (itemId: string) => {
    const { jobLinkedItemEdges } = this.props;

    const edge = jobLinkedItemEdges.find(edge => {
      return edge?.node?.id === itemId;
    });

    const item = {
      ...edge?.node,
      usageQty: edge?.usageQty || 0,
    };

    return item || null;
  };

  handleOpenUpdateModal = (e, data, target) => {
    const itemId = target.getAttribute('item-id');

    this.setState({
      updateJobLinkedItemRecord: this.getItemById(itemId),
    });
  };

  handleOpenDeleteModal = (e, data, target) => {
    const itemId = target.getAttribute('item-id');

    this.setState({
      deleteJobLinkedItemRecord: this.getItemById(itemId),
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      deleteJobLinkedItemRecord: null,
      updateJobLinkedItemRecord: null,
    });
  };

  render() {
    const { history, jobLinkedItemEdges, jobId } = this.props;
    const { deleteJobLinkedItemRecord, updateJobLinkedItemRecord } = this.state;

    const edges = jobLinkedItemEdges
      ? jobLinkedItemEdges.filter(Boolean).map(a => ({
          ...a.node,
          usageQty: a.usageQty,
        }))
      : [];

    if (!edges.length) {
      return (
        <EmptyListResults
          graphic="tooling"
          message={i18n.t(
            'Item tooling associated to jobs can be created and managed here. Operators will have access to tooling information.',
          )}
        />
      );
    }

    return (
      <>
        <Table
          data={edges}
          onRowClick={rowData => history.push(`/item/${rowData.id}`)}
          columns={[
            {
              Header: i18n.t('Inventory #'),
              accessor: 'itemNumber',
              width: 130,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value, row }: *) => <Pill>{value}</Pill>,
            },
            {
              Header: i18n.t('Tool Name'),
              accessor: 'name',
              width: 240,
            },
            {
              Header: i18n.t('Description'),
              accessor: 'description',
              minWidth: 240,
            },
            {
              Header: i18n.t('Est. Usage'),
              accessor: 'usageQty',
              minWidth: 240,
              Cell: ({ value, row }: *) => (
                <span>{`${(value || 0).toLocaleString()} ${row._original
                  ?.quantityUOM?.symbol || ''}`}</span>
              ),
            },
            {
              Header: i18n.t('Part #'),
              accessor: 'partNumber',
              maxWidth: 135,
            },
            {
              Header: i18n.t('Category'),
              accessor: 'category.name',
              maxWidth: 165,
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              Cell: ({ value }: *) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="linked-items-menu"
                  attributes={{
                    'item-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={(e, data, target) => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(
                          e,
                          data,
                          target,
                        );
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ]}
        />
        <ContextMenu id="linked-items-menu">
          <MenuItem onClick={this.handleOpenUpdateModal}>
            {i18n.t('Edit Estimated Usage')}
          </MenuItem>
          <MenuItem onClick={this.handleOpenDeleteModal}>
            {i18n.t('Remove From Job Ticket')}
          </MenuItem>
        </ContextMenu>
        {updateJobLinkedItemRecord && (
          <AddUpdateJobLinkedItemModal
            job={null}
            jobId={jobId}
            item={updateJobLinkedItemRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
        {deleteJobLinkedItemRecord && (
          <DeleteJobLinkedItemModal
            item={deleteJobLinkedItemRecord}
            onClose={this.handleCloseAllModals}
            jobId={jobId}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(JobLinkedItemsTable, {
  jobLinkedItemEdges: graphql`
    fragment JobLinkedItemsTable_jobLinkedItemEdges on JobLinkedItemEdge
      @relay(plural: true) {
      usageQty
      node {
        id
        itemNumber
        name
        partNumber
        description
        category {
          id
          name
        }
        quantityUOM {
          id
          symbol
        }
        ...DeleteJobLinkedItemModal_item
      }
    }
  `,
});

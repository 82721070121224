// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import AddCustomerMutation from 'main-app/mutations/AddCustomer';
import UpdateCustomerMutation from 'main-app/mutations/UpdateCustomer';
import Button from 'shared/components/common/Button';
import Divider from 'shared/components/common/Divider';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
  SelectUserField,
} from 'shared/components/form';
import type { AddUpdateCustomerModal_customer as CustomerFragment } from './__generated__/AddUpdateCustomerModal_customer';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  onReopen?: () => void,
  createAnotherCustomer?: boolean,
  customer: ?CustomerFragment,
};

const LANGUAGE = {
  update: {
    title: i18n.t('Edit Customer'),
    submitButton: i18n.t('Update Customer'),
    successTitle: i18n.t('Customer Successfully Updated'),
    successBody: userAssigned =>
      userAssigned && i18n.t('The salesperson has been notified.'),
  },
  add: {
    title: i18n.t('Create Customer'),
    submitButton: i18n.t('Create Customer'),
    successTitle: i18n.t('Customer Successfully Created'),
    successBody: userAssigned =>
      userAssigned
        ? i18n.t('The salesperson has been notified. Now create a Order!')
        : i18n.t('Now create a Order!'),
  },
};

const copyShippingToBillingAddress = (values, setFieldValue) => {
  setFieldValue('billingAddressLine1', values.shippingAddressLine1);
  setFieldValue('billingAddressLine2', values.shippingAddressLine2);
  setFieldValue('billingAddressLine3', values.shippingAddressLine3);
  setFieldValue('billingAddressCity', values.shippingAddressCity);
  setFieldValue('billingAddressState', values.shippingAddressState);
  setFieldValue('billingAddressZip', values.shippingAddressZip);
};

const AddUpdateCustomerModal = ({
  onClose,
  onSuccess,
  onReopen,
  createAnotherCustomer,
  customer,
}: Props) => {
  const language = LANGUAGE[customer ? 'update' : 'add'];

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          createAnotherCustomer,
          name: customer?.name || '',
          primaryContactName: customer?.primaryContactName || '',
          secondaryContactName: customer?.secondaryContactName || '',
          shippingAddressLine1: customer?.shippingAddressLine1 || '',
          shippingAddressLine2: customer?.shippingAddressLine2 || '',
          shippingAddressLine3: customer?.shippingAddressLine3 || '',
          shippingAddressCity: customer?.shippingAddressCity || '',
          shippingAddressState: customer?.shippingAddressState || '',
          shippingAddressZip: customer?.shippingAddressZip || '',
          billingAddressLine1: customer?.billingAddressLine1 || '',
          billingAddressLine2: customer?.billingAddressLine2 || '',
          billingAddressLine3: customer?.billingAddressLine3 || '',
          billingAddressCity: customer?.billingAddressCity || '',
          billingAddressState: customer?.billingAddressState || '',
          billingAddressZip: customer?.billingAddressZip || '',
          email: customer?.email || '',
          phone: customer?.phone || '',
          phone2: customer?.phone2 || '',
          fax: customer?.fax || '',
          salesUser: customer?.salesUser && {
            value: customer?.salesUser?.id,
            data: customer?.salesUser,
          },
          notes: customer?.notes || '',
        }}
        validationSchema={Yup.object().shape({
          createAnotherCustomer: Yup.boolean(),
          name: Yup.string().required('Required'),
          email: Yup.string().email('Invalid Email'),
          shippingAddressZip: Yup.string(),
          billingAddressZip: Yup.string(),
          salesUser: Yup.object().nullable(),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const mutation = customer
            ? UpdateCustomerMutation
            : AddCustomerMutation;

          const input: any = {
            name: values.name,
            primaryContactName: values.primaryContactName,
            secondaryContactName: values.secondaryContactName,
            shippingAddressLine1: values.shippingAddressLine1,
            shippingAddressLine2: values.shippingAddressLine2,
            shippingAddressLine3: values.shippingAddressLine3,
            shippingAddressCity: values.shippingAddressCity,
            shippingAddressState: values.shippingAddressState,
            shippingAddressZip: values.shippingAddressZip,
            billingAddressLine1: values.billingAddressLine1,
            billingAddressLine2: values.billingAddressLine2,
            billingAddressLine3: values.billingAddressLine3,
            billingAddressCity: values.billingAddressCity,
            billingAddressState: values.billingAddressState,
            billingAddressZip: values.billingAddressZip,
            email: values.email || null,
            phone: values.phone,
            phone2: values.phone2,
            fax: values.fax,
            salesUserId: values?.salesUser?.value || null,
            notes: values.notes,
          };

          if (customer) {
            input.id = customer.id;
          }

          try {
            const response = await mutation.commit({
              variables: {
                input,
              },
            });

            const newCustomer = (
              response.updateCustomer || response.addCustomer
            ).customerEdge.node;

            if (values.createAnotherCustomer && onReopen) {
              onReopen();
            } else {
              onClose();

              if (onSuccess) {
                onSuccess(newCustomer);
              }
            }

            Analytics.trackEvent(`${customer ? 'Update' : 'Create'} Customer`, {
              customerName: values.name,
              shippingCity: values.shippingAddressCity,
              shippingState: values.shippingAddressState,
              billingCity: values.billingAddressCity,
              billingState: values.billingAddressState,
              salesperson:
                values.salesUser?.data?.firstName +
                ' ' +
                values.salesUser?.data?.lastName,
            });
            Actions.alertNotification(
              {
                title: language.successTitle,
                body: language.successBody(values.salesUser?.value),
              },
              'success',
            );
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <ModalHeader header={language.title} onClose={onClose} />
            <ModalBody withPadding>
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="name"
                    label={i18n.t('Customer')}
                    placeholder={i18n.t('Customer Name')}
                    error={errors.name}
                  />
                }
                right={
                  <SelectUserField
                    name="salesUser"
                    label={i18n.t('Salesperson')}
                    clearable
                    error={errors.salesUser}
                  />
                }
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="primaryContactName"
                    label={i18n.t('Primary Contact')}
                    placeholder={i18n.t('Primary Contact')}
                    error={errors.primaryContactName}
                  />
                }
                right={
                  <FieldGroup
                    name="secondaryContactName"
                    label={i18n.t('Secondary Contact')}
                    placeholder={i18n.t('Secondary Contact')}
                    error={errors.secondaryContactName}
                  />
                }
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="email"
                    label={i18n.t('Email Address')}
                    placeholder={i18n.t('Enter Email')}
                    error={errors.email}
                  />
                }
                right={
                  <FieldGroup
                    name="phone"
                    label={i18n.t('Phone Number')}
                    placeholder={i18n.t('Enter Phone Number')}
                    error={errors.phone}
                  />
                }
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="phone2"
                    label={i18n.t('Secondary Phone Number')}
                    placeholder={i18n.t('Enter Phone Number')}
                    error={errors.phone2}
                  />
                }
                right={
                  <FieldGroup
                    name="fax"
                    label={i18n.t('Fax')}
                    placeholder={i18n.t('Enter Fax Number')}
                    error={errors.fax}
                  />
                }
              />
              <Divider style={{ marginTop: 8, marginBottom: 24 }} />
              <FieldGroup
                name="shippingAddressLine1"
                label={i18n.t('Shipping Address')}
                placeholder={i18n.t('Shipping Address Line 1')}
                error={errors.shippingAddressLine1}
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="shippingAddressLine2"
                    label={i18n.t('Address 2')}
                    placeholder={i18n.t('Shipping Address Line 2')}
                    error={errors.shippingAddressLine2}
                  />
                }
                right={
                  <FieldGroup
                    name="shippingAddressLine3"
                    label={i18n.t('Address 3')}
                    placeholder={i18n.t('Shipping Address Line 3')}
                    error={errors.shippingAddressLine3}
                  />
                }
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="shippingAddressCity"
                    label={i18n.t('City')}
                    placeholder={i18n.t('City')}
                    error={errors.shippingAddressCity}
                  />
                }
                center={
                  <FieldGroup
                    name="shippingAddressState"
                    label={i18n.t('State')}
                    placeholder={i18n.t('State')}
                    error={errors.shippingAddressState}
                  />
                }
                right={
                  <FieldGroup
                    name="shippingAddressZip"
                    label={i18n.t('Postal Code')}
                    placeholder={i18n.t('Zip Code')}
                    error={errors.shippingAddressZip}
                  />
                }
              />
              <Divider style={{ marginTop: 8, marginBottom: 24 }} />
              <FieldGroup
                label={i18n.t('Same as Shipping Address')}
                onChange={value => {
                  if (value) {
                    copyShippingToBillingAddress(values, setFieldValue);
                  }
                }}
                style={{ marginBottom: 16 }}
                type="toggle"
                sideLabel="right"
                rawField
              />
              <FieldGroup
                name="billingAddressLine1"
                label={i18n.t('Billing Address')}
                placeholder={i18n.t('Billing Address Line 1')}
                error={errors.billingAddressLine1}
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="billingAddressLine2"
                    label={i18n.t('Address 2')}
                    placeholder={i18n.t('Billing Address Line 2')}
                    error={errors.billingAddressLine2}
                  />
                }
                right={
                  <FieldGroup
                    name="billingAddressLine3"
                    label={i18n.t('Address 3')}
                    placeholder={i18n.t('Billing Address Line 3')}
                    error={errors.billingAddressLine3}
                  />
                }
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="billingAddressCity"
                    label={i18n.t('City')}
                    placeholder={i18n.t('City')}
                    error={errors.billingAddressCity}
                  />
                }
                center={
                  <FieldGroup
                    name="billingAddressState"
                    label={i18n.t('State')}
                    placeholder={i18n.t('State')}
                    error={errors.billingAddressState}
                  />
                }
                right={
                  <FieldGroup
                    name="billingAddressZip"
                    label={i18n.t('Postal Code')}
                    placeholder={i18n.t('Postal Code')}
                    error={errors.billingAddressZip}
                  />
                }
              />
              <Divider style={{ marginTop: 8, marginBottom: 24 }} />
              <FieldGroup
                name="notes"
                type="textarea"
                label={i18n.t('Notes')}
                placeholder={i18n.t('Enter notes here...')}
                error={errors.notes}
              />
            </ModalBody>
            <ModalFooter>
              {!customer && (
                <FieldGroup
                  label={i18n.t('Create Another Customer')}
                  sideLabel="right"
                  name="createAnotherCustomer"
                  error={errors.createAnotherCustomer}
                  type="toggle"
                />
              )}
              <Button
                type="submit"
                theme="blue"
                disabled={!isValid}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {language.submitButton}
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

AddUpdateCustomerModal.defaultProps = {
  onSuccess: undefined,
  onReopen: undefined,
  createAnotherCustomer: false,
};

export default createFragmentContainer(AddUpdateCustomerModal, {
  customer: graphql`
    fragment AddUpdateCustomerModal_customer on Customer {
      id
      name
      primaryContactName
      secondaryContactName
      shippingAddressLine1
      shippingAddressLine2
      shippingAddressLine3
      shippingAddressCity
      shippingAddressState
      shippingAddressZip
      billingAddressLine1
      billingAddressLine2
      billingAddressLine3
      billingAddressCity
      billingAddressState
      billingAddressZip
      email
      phone
      phone2
      fax
      notes
      salesUser {
        id
        firstName
        lastName
      }
    }
  `,
});

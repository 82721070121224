// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteOrderYieldMutation from 'main-app/mutations/DeleteOrderYield';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteOrderYieldModal_orderYield as OrderYieldFragment } from './__generated__/DeleteOrderYieldModal_orderYield';

type Props = {
  onClose: () => void,
  orderId: string,
  orderYield: OrderYieldFragment,
};

type State = {
  deleting: boolean,
};

class DeleteOrderYieldModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  handleDelete = () => {
    const { orderId, orderYield, onClose } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteOrderYieldMutation.commit({
            variables: {
              input: {
                id: orderYield.id,
              },
            },
            context: {
              orderId,
            },
          });

          onClose();
          Actions.alertNotification(
            i18n.t(`Item Successfully Removed`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { orderYield, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Remove Item: {{itemName}}?', {
            itemName: orderYield.item.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            `Removing "${orderYield.item.name}" will also remove it from associated Job Tickets.`,
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Remove Item')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteOrderYieldModal, {
  orderYield: graphql`
    fragment DeleteOrderYieldModal_orderYield on OrderYield {
      id
      item {
        id
        name
      }
    }
  `,
});

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      parentType: string,
      parentId: string,
    },
  },
  uploadables: Object,
};

const mutation = graphql`
  mutation AddAttachmentsMutation($input: AddAttachmentsInput!) {
    addAttachments(input: $input) {
      attachmentEdges {
        node {
          id
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, options);
  },
};

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobSubComponentsTree_jobYieldEdges$ref: FragmentReference;
declare export opaque type JobSubComponentsTree_jobYieldEdges$fragmentType: JobSubComponentsTree_jobYieldEdges$ref;
export type JobSubComponentsTree_jobYieldEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +quantity: number,
    +item: {|
      +id: string,
      +itemNumber: string,
      +name: string,
      +partNumber: ?string,
      +upc: ?string,
      +totalQty: number,
      +quantityUOM: {|
        +id: string,
        +symbol: string,
      |},
      +category: ?{|
        +id: string,
        +name: string,
      |},
      +lotTracking: ?boolean,
      +childItems: {|
        +edges: ?$ReadOnlyArray<?{|
          +usageQty: ?number,
          +node: {|
            +id: string,
            +itemNumber: string,
            +name: string,
            +partNumber: ?string,
            +upc: ?string,
            +totalQty: number,
            +quantityUOM: {|
              +id: string,
              +symbol: string,
            |},
            +category: ?{|
              +id: string,
              +name: string,
            |},
            +lotTracking: ?boolean,
          |},
        |}>
      |},
    |},
  |},
  +$refType: JobSubComponentsTree_jobYieldEdges$ref,
|}>;
export type JobSubComponentsTree_jobYieldEdges$data = JobSubComponentsTree_jobYieldEdges;
export type JobSubComponentsTree_jobYieldEdges$key = {
  +$data?: JobSubComponentsTree_jobYieldEdges$data,
  +$fragmentRefs: JobSubComponentsTree_jobYieldEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemNumber",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "partNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upc",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalQty",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "quantityUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "symbol",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "ItemCategory",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lotTracking",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "JobSubComponentsTree_jobYieldEdges",
  "type": "JobYieldEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "JobYield",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "item",
          "storageKey": null,
          "args": null,
          "concreteType": "Item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "childItems",
              "storageKey": null,
              "args": null,
              "concreteType": "ItemChildConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ItemChildEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "usageQty",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Item",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '161a15feeed138e5e23b5f1cd2e22996';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      itemId: string,
    },
  },
};

const mutation = graphql`
  mutation TransferItemMutation($input: TransferItemInput!) {
    transferItem(input: $input) {
      itemEdge {
        node {
          id
          logs {
            id
            user {
              id
            }
            lot {
              id
            }
            fromLot {
              id
            }
            operation
            quantity
            notes
            createdAt
          }
          lots(first: null) {
            edges {
              ...ItemLots_itemLotEdges
            }
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    const { itemId } = options.variables.input;

    return createRelayMutation(mutation, {
      ...options,
      updater: store => {
        // Retrieve the root query
        const root = store.getRoot().getLinkedRecord('item', {
          id: itemId,
        });

        if (root) {
          // Retrieve the root query's items array
          const conn = ConnectionHandler.getConnection(root, 'Item_lots');

          if (conn) {
            const edges = conn
              .getLinkedRecords('edges')
              .filter(
                edge =>
                  edge.getLinkedRecord('node')._dataID !==
                  options.variables.input.itemId,
              );

            conn.setLinkedRecords(edges, 'edges');
          }
        }
      },
    });
  },
};

// @flow

import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { colors, fonts } from 'shared/styleguide';
import leftIcon from './img/left.svg';
import rightIcon from './img/right.svg';
import minusIcon from './img/minus.svg';
import plusIcon from './img/plus.svg';

export const EventColorMap = {
  DOWNTIME: {
    bgColor: colors.cherry,
    fontColor: colors.white,
  },
  RUN_PAUSED: {
    bgColor: '#fcdf8f',
    fontColor: colors.battleshipGrey,
  },
  RUN_IN_PROGRESS: {
    bgColor: '#eaeefd',
    fontColor: colors.azul,
  },
  RUN_COMPLETE: {
    bgColor: colors.turquoise,
    fontColor: colors.white,
  },
  RUN_SCHEDULED: {
    bgColor: '#ececed',
    fontColor: colors.charcoalGrey,
  },
};

export const PageWrapper = styled.div`
  flex: 1;
  padding: 12px 0 12px 0;

  @media (min-width: 1000px) {
    padding: 24px 0 24px 0;
  }
`;

export const FiltersDesktop = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  li {
    width: 200px;
    padding: 0 16px 0 0;

    &:last-child {
      padding: 0;
    }
  }

  @media (min-width: 1000px) {
    display: flex;
  }
`;

export const FiltersMobile = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 24px 0;

  li {
    width: 100%;
    padding-bottom: 16px;

    &:last-child {
      padding: 0;
    }
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const MachineWrapper = styled.div`
  color: ${p => (p.isDown ? colors.cherry : colors.charcoalGrey)};
  line-height: normal;
  padding: 8px 0 0 16px;

  header {
    ${fonts.largeBold};
    padding-bottom: 4px;
  }

  span {
    display: block;
    ${fonts.smallRegular};
    color: ${p => (p.isDown ? colors.cherry : colors.battleshipGrey)};
  }
`;

export const LeftIcon = styled(leftIcon)`
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const RightIcon = styled(rightIcon)`
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const PlusIcon = styled(plusIcon)`
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const MinusIcon = styled(minusIcon)`
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const ChartControlsList = styled.ul`
  list-style: none;
  padding: 12px 10px 2px 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  li {
    padding-right: 24px;

    &:last-child {
      padding-right: 0;
    }
  }
`;

export const AutoScheduleDropzone = styled.div`
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  border: 2px dashed ${colors.azul};
  border-radius: 5px;
  padding: 24px;
  ${fonts.bodyBold};
  color: ${colors.azul};
`;

const RunWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fonts.bodyBold};
  padding-left: 8px;

  span {
    padding-left: 8px;
    ${fonts.smallRegular};
  }
`;

export const renderRun = ({
  item,
  timelineContext,
  itemContext,
  getItemProps,
  getResizeProps,
}: *) => {
  const colorMap = EventColorMap[item.type];
  let borderColor = 'transparent';
  let boxShadow = '';

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  if (itemContext.selected) {
    borderColor = darken(0.2, colorMap.bgColor);
    boxShadow = '0 2px 4px 0 rgba(66, 68, 76, 0.3)';
  }

  return (
    <div
      {...getItemProps({
        style: {
          backgroundColor: colorMap.bgColor,
          background: colorMap.bgColor,
          borderColor,
          color: colorMap.fontColor,
          borderStyle: 'solid',
          border: `2px solid ${borderColor}`,
          borderRadius: 30,
          borderRightWidth: itemContext.selected ? 16 : 0,
          boxShadow,
          opacity:
            item.selectedJobId && item.selectedJobId !== item.jobId ? 0.3 : 1,
        },
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
      <RunWrapper
        style={{
          height: itemContext.dimensions.height,
        }}
        onMouseEnter={() => item.handleMouseEnter(item)}
        onMouseLeave={item.handleMouseLeave}
      >
        <span>{item.name}</span>
      </RunWrapper>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    </div>
  );
};

export const SidebarHeaderWrapper = styled.div`
  border-right: 2px solid ${colors.lightBlueGrey};
  background-color: ${colors.background};
`;

// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { fetchQuery, graphql } from 'relay-runtime';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import relayEnvironment from 'shared/gql/relayEnvironment';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Loader from 'shared/components/common/Loader';

type Props = {
  itemId: string,
};

type State = {
  loading: boolean,
  item: any,
};

const LoaderWrapper = styled.div`
  height: 240px;
`;

const QUERY = graphql`
  query ItemCardQuery($itemId: ID!) {
    item(id: $itemId) {
      id
      description
      name
      partNumber
      upc
      category {
        id
        name
      }
      quantityUOM {
        id
        name
        symbol
      }
    }
  }
`;

class ItemCard extends PureComponent<Props, State> {
  state = {
    loading: true,
    item: {},
  };

  componentDidMount() {
    const { itemId } = this.props;
    this.loadItem(itemId);
  }

  componentDidUpdate(prevProps: Props) {
    const { itemId } = this.props;

    if (itemId && prevProps.itemId !== itemId) {
      this.loadItem(itemId);
    }
  }

  loadItem = async (itemId: string) => {
    const { item } = await fetchQuery(relayEnvironment, QUERY, {
      itemId,
    });

    this.setState({
      loading: false,
      item,
    });
  };

  render() {
    const { loading, item } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    return (
      <KeyValueGrid
        singleColumn
        style={{
          padding: 16,
          borderRadius: 10,
          backgroundColor: colors.paleGreyTwo,
          marginBottom: 8,
        }}
        fields={[
          {
            label: i18n.t('UPC'),
            value: item?.upc,
          },
          {
            label: i18n.t('Part #'),
            value: item?.partNumber,
          },
          {
            label: i18n.t('Category'),
            value: item?.category?.name || '',
          },
          {
            label: i18n.t('Description'),
            value: item?.description,
          },
        ]}
      />
    );
  }
}

export default ItemCard;

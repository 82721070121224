// @flow

import React, { PureComponent } from 'react';
import { i18n, BrowserUtils } from 'shared/utils';
import AppliedTag from 'shared/components/common/AppliedTag';
import ApplyFilterDesktop from './ApplyFilterDesktop';
import ApplyFilterMobile from './ApplyFilterMobile';
import {
  Wrapper,
  InnerWrapper,
  ButtonWrapper,
  MobileTotalFilters,
} from './styled';
import type { FilterOption, MassagedFilter } from '..';

type Props = {
  filtersDirty: boolean,
  filterOptions: Array<FilterOption>,
  savedFilterId?: ?string,
  massagedFilters: Array<MassagedFilter>,
  onApplyFilters: (Array<Object>) => void,
  onRemoveFilter: (string, any) => void,
  onResetFilters: () => void,
};

type State = {
  applyFilterOpen: boolean,
  windowWidth: number,
};

class TopControlBar extends PureComponent<Props, State> {
  applyFilterButton: *;
  mounted = true;

  static defaultProps = {
    savedFilterId: null,
  };

  state = {
    applyFilterOpen: false,
    windowWidth: BrowserUtils.getWidth(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.handleWindowResize);
  }

  /**
   * Since we use JS to do responsive stuff here (i.e. show a modal when on mobile),
   * we want to close both panels blindly on window resize. This avoids the wrong panels/modals
   * showing up in the wrong screensize
   */
  handleWindowResize = () => {
    if (!this.mounted) {
      return;
    }

    this.setState({
      applyFilterOpen: false,
      windowWidth: BrowserUtils.getWidth(),
    });
  };

  handleOpenApplyPanel = (e?: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({
      applyFilterOpen: true,
    });
  };

  handleCloseApplyPanel = (e?: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({
      applyFilterOpen: false,
    });
  };

  handleToggleApplyPanel = (e?: Event) => {
    const { applyFilterOpen } = this.state;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({
      applyFilterOpen: !applyFilterOpen,
    });
  };

  handleApplyFilters = (filters: Array<Object>) => {
    const { onApplyFilters } = this.props;
    onApplyFilters(filters);
    this.handleCloseApplyPanel();
  };

  render() {
    const {
      massagedFilters,
      filtersDirty,
      filterOptions,
      savedFilterId,
      onResetFilters,
      onApplyFilters,
      onRemoveFilter,
    } = this.props;
    const { windowWidth, applyFilterOpen } = this.state;
    const appliedFilterCount = massagedFilters.length;

    return (
      <Wrapper>
        <InnerWrapper>
          <ButtonWrapper>
            <AppliedTag
              theme="light-grey"
              icon="filter"
              width={windowWidth >= 1000 ? undefined : 'auto'}
              getRef={r => (this.applyFilterButton = r)}
              onClick={this.handleToggleApplyPanel}
            >
              {i18n.t('Add Filter')}
            </AppliedTag>
          </ButtonWrapper>
          {(filtersDirty || appliedFilterCount > 0 || savedFilterId) && (
            <ButtonWrapper>
              <AppliedTag
                icon="reset"
                theme="border-white"
                width="auto"
                onClick={onResetFilters}
              >
                {i18n.t('Reset')}
              </AppliedTag>
            </ButtonWrapper>
          )}
        </InnerWrapper>
        {appliedFilterCount > 0 && (
          <MobileTotalFilters>
            {i18n.t(`{{count}} Filter Applied`, {
              count: appliedFilterCount,
            })}
          </MobileTotalFilters>
        )}
        {(() => {
          if (!applyFilterOpen) {
            return null;
          }

          if (windowWidth >= 1000) {
            return (
              <ApplyFilterDesktop
                filterOptions={filterOptions}
                onApplyFilters={this.handleApplyFilters}
                onClose={this.handleCloseApplyPanel}
              />
            );
          }

          return (
            <ApplyFilterMobile
              onClose={this.handleCloseApplyPanel}
              filterOptions={filterOptions}
              massagedFilters={massagedFilters}
              onApplyFilters={onApplyFilters}
              onRemoveFilter={onRemoveFilter}
            />
          );
        })()}
      </Wrapper>
    );
  }
}

export default TopControlBar;

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteAPIKeyMutation from 'main-app/mutations/DeleteAPIKey';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteAPIKeyModal_apiKey as APIKeyFragment } from './__generated__/DeleteAPIKeyModal_apiKey';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  apiKey: APIKeyFragment,
};

type State = {
  deleting: boolean,
};

class DeleteAPIKeyModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  static defaultProps = {
    onSuccess: undefined,
  };

  handleDelete = () => {
    const { apiKey, onClose, onSuccess } = this.props;

    const confirmDelete = window.confirm(
      i18n.t(
        'Are you ABSOLUTELY sure you want to delete this API Key? This cannot be undone!',
      ),
    );

    if (!confirmDelete) {
      return;
    }

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteAPIKeyMutation.commit({
            variables: {
              input: {
                id: apiKey.id,
              },
            },
          });

          onClose();

          if (onSuccess) {
            onSuccess();
          }

          Analytics.trackEvent('Delete API Key');

          Actions.alertNotification(
            i18n.t(`API Key Successfully Deleted`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(
            i18n.t('Something went wrong. Please try again later.'),
            'error',
          );
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { apiKey, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete API Key: {{apiKeyName}}?', {
            apiKeyName: apiKey.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            'This API Key will be deleted permanently. If any of your systems are using this API Key, this could result in downtime.',
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete API Key')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteAPIKeyModal, {
  apiKey: graphql`
    fragment DeleteAPIKeyModal_apiKey on APIKey {
      id
      name
    }
  `,
});

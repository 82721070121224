// @flow

import React from 'react';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import Icon, { type Props as IconProps } from '.';

type Props = {
  label: string,
  iconPosition?: 'left' | 'right',
  ...IconProps,
  ...*,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-direction: ${p => (p.iconPosition === 'left' ? 'row-reverse' : 'row')};
  align-self: flex-start;
  width: auto;
`;

const IconLabel = styled.div`
  ${fonts.bodyRegular};
  color: ${colors.battleshipGrey};
  padding-right: ${p => (p.iconPosition === 'left' ? 0 : 8)}px;
  padding-left: ${p => (p.iconPosition === 'left' ? 8 : 0)}px;

  @media (min-width: 1000px) {
    &:hover {
      cursor: pointer;
    }
  }
`;

const IconWithLabel = ({
  label,
  onClick,
  iconPosition,
  ...circleIconProps
}: Props) => (
  <Wrapper iconPosition={iconPosition}>
    <IconLabel onClick={onClick} iconPosition={iconPosition}>
      {label}
    </IconLabel>
    <Icon {...circleIconProps} onClick={onClick} />
  </Wrapper>
);

IconWithLabel.defaultProps = {
  iconPosition: 'right',
};

export default IconWithLabel;

// @flow

import { i18n } from 'shared/utils';

export default () => [
  {
    label: i18n.t('Lot # · Low to High'),
    value: { field: 'LOT_NUMBER', direction: 'ASC' },
  },
  {
    label: i18n.t('Lot # · High to Low'),
    value: { field: 'LOT_NUMBER', direction: 'DESC' },
  },
  {
    label: i18n.t('Bin # · Low to High'),
    value: { field: 'BIN_NUMBER', direction: 'ASC' },
  },
  {
    label: i18n.t('Bin # · High to Low'),
    value: { field: 'BIN_NUMBER', direction: 'DESC' },
  },
  {
    label: i18n.t('Expiration Date · Oldest'),
    value: { field: 'EXPIRES_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Expiration Date · Most Recent'),
    value: { field: 'EXPIRES_AT', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Modified · Oldest'),
    value: { field: 'UPDATED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Modified · Most Recent'),
    value: { field: 'UPDATED_AT', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Created - Oldest'),
    value: { field: 'CREATED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Created - Most Recent'),
    value: { field: 'CREATED_AT', direction: 'DESC' },
  },
];

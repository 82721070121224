/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type LotSidePanel_lot$ref: FragmentReference;
declare export opaque type LotSidePanel_lot$fragmentType: LotSidePanel_lot$ref;
export type LotSidePanel_lot = {|
  +id: string,
  +lotNumber: string,
  +binNumber: ?string,
  +expiresAt: ?any,
  +createdAt: ?any,
  +$refType: LotSidePanel_lot$ref,
|};
export type LotSidePanel_lot$data = LotSidePanel_lot;
export type LotSidePanel_lot$key = {
  +$data?: LotSidePanel_lot$data,
  +$fragmentRefs: LotSidePanel_lot$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "LotSidePanel_lot",
  "type": "Lot",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lotNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "binNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expiresAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2bb91c35ae7d810af3e6fcef33129919';
module.exports = node;

/**
 * @flow
 * @relayHash b4e1562396ce8228553ce989224282e5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddUpdateLotModal_lot$ref = any;
type LotItems_lotItemEdges$ref = any;
type LotSidePanel_lot$ref = any;
export type Permission = "CREATE_API_KEY" | "CREATE_CUSTOMER" | "CREATE_ITEM" | "CREATE_JOB" | "CREATE_LOT" | "CREATE_ORDER" | "DELETE_API_KEY" | "DELETE_CUSTOMER" | "DELETE_ITEM" | "DELETE_JOB" | "DELETE_LOT" | "DELETE_ORDER" | "READ_API_KEY" | "READ_CUSTOMER" | "READ_ITEM" | "READ_JOB" | "READ_LOT" | "READ_ORDER" | "UPDATE_API_KEY" | "UPDATE_CUSTOMER" | "UPDATE_ITEM" | "UPDATE_JOB" | "UPDATE_LOT" | "UPDATE_ORDER";
export type LotQueryVariables = {|
  id: string
|};
export type LotQueryResponse = {|
  +lot: {|
    +id: string,
    +lotNumber: string,
    +binNumber: ?string,
    +quantity: number,
    +expiresAt: ?any,
    +createdAt: ?any,
    +items: {|
      +edges: ?$ReadOnlyArray<?{|
        +$fragmentRefs: LotItems_lotItemEdges$ref
      |}>
    |},
    +$fragmentRefs: LotSidePanel_lot$ref & AddUpdateLotModal_lot$ref,
  |},
  +viewer: ?{|
    +id: string,
    +permissions: $ReadOnlyArray<Permission>,
  |},
|};
export type LotQuery = {|
  variables: LotQueryVariables,
  response: LotQueryResponse,
|};
*/


/*
query LotQuery(
  $id: ID!
) {
  lot(id: $id) {
    id
    lotNumber
    binNumber
    quantity
    expiresAt
    createdAt
    items {
      edges {
        ...LotItems_lotItemEdges
        cursor
        node {
          __typename
          id
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    ...LotSidePanel_lot
    ...AddUpdateLotModal_lot
  }
  viewer {
    id
    permissions
  }
}

fragment LotItems_lotItemEdges on LotItemEdge {
  quantity
  node {
    id
    name
    itemNumber
    partNumber
    category {
      id
      name
    }
    quantityUOM {
      id
      name
      symbol
    }
  }
  ...LotItemsTable_lotItemEdges
}

fragment LotSidePanel_lot on Lot {
  id
  lotNumber
  binNumber
  expiresAt
  createdAt
}

fragment AddUpdateLotModal_lot on Lot {
  id
  lotNumber
  binNumber
  expiresAt
}

fragment LotItemsTable_lotItemEdges on LotItemEdge {
  quantity
  node {
    id
    itemNumber
    name
    partNumber
    category {
      id
      name
    }
    quantityUOM {
      id
      symbol
    }
    ...AddItemMovementModal_item
  }
}

fragment AddItemMovementModal_item on Item {
  id
  itemNumber
  totalQty
  quantityUOM {
    id
    name
  }
  logs {
    id
    user {
      id
    }
    lot {
      id
    }
    fromLot {
      id
    }
    operation
    quantity
    notes
    createdAt
  }
  lots {
    edges {
      quantity
      node {
        id
        lotNumber
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  lotTracking
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lotNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "binNumber",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expiresAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "viewer",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    }
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v13 = [
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "LotQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lot",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Lot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "__Lot_items_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "LotItemConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LotItemEdge",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Item",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "LotItems_lotItemEdges",
                    "args": null
                  }
                ]
              },
              (v10/*: any*/)
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "LotSidePanel_lot",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "AddUpdateLotModal_lot",
            "args": null
          }
        ]
      },
      (v11/*: any*/)
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LotQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lot",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Lot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "items",
            "storageKey": null,
            "args": null,
            "concreteType": "LotItemConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "LotItemEdge",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Item",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v12/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "itemNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "partNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ItemCategory",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v12/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "quantityUOM",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UOM",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "symbol",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "totalQty",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "logs",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ItemTransactionLog",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "user",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "User",
                            "plural": false,
                            "selections": (v13/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "lot",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Lot",
                            "plural": false,
                            "selections": (v13/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "fromLot",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Lot",
                            "plural": false,
                            "selections": (v13/*: any*/)
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "operation",
                            "args": null,
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "notes",
                            "args": null,
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "lots",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ItemLotConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ItemLotEdge",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Lot",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v9/*: any*/)
                                ]
                              },
                              (v8/*: any*/)
                            ]
                          },
                          (v10/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedHandle",
                        "alias": null,
                        "name": "lots",
                        "args": null,
                        "handle": "connection",
                        "key": "Item_lots",
                        "filters": []
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lotTracking",
                        "args": null,
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ]
                  },
                  (v8/*: any*/)
                ]
              },
              (v10/*: any*/)
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "items",
            "args": null,
            "handle": "connection",
            "key": "Lot_items",
            "filters": []
          }
        ]
      },
      (v11/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "LotQuery",
    "id": null,
    "text": "query LotQuery(\n  $id: ID!\n) {\n  lot(id: $id) {\n    id\n    lotNumber\n    binNumber\n    quantity\n    expiresAt\n    createdAt\n    items {\n      edges {\n        ...LotItems_lotItemEdges\n        cursor\n        node {\n          __typename\n          id\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    ...LotSidePanel_lot\n    ...AddUpdateLotModal_lot\n  }\n  viewer {\n    id\n    permissions\n  }\n}\n\nfragment LotItems_lotItemEdges on LotItemEdge {\n  quantity\n  node {\n    id\n    name\n    itemNumber\n    partNumber\n    category {\n      id\n      name\n    }\n    quantityUOM {\n      id\n      name\n      symbol\n    }\n  }\n  ...LotItemsTable_lotItemEdges\n}\n\nfragment LotSidePanel_lot on Lot {\n  id\n  lotNumber\n  binNumber\n  expiresAt\n  createdAt\n}\n\nfragment AddUpdateLotModal_lot on Lot {\n  id\n  lotNumber\n  binNumber\n  expiresAt\n}\n\nfragment LotItemsTable_lotItemEdges on LotItemEdge {\n  quantity\n  node {\n    id\n    itemNumber\n    name\n    partNumber\n    category {\n      id\n      name\n    }\n    quantityUOM {\n      id\n      symbol\n    }\n    ...AddItemMovementModal_item\n  }\n}\n\nfragment AddItemMovementModal_item on Item {\n  id\n  itemNumber\n  totalQty\n  quantityUOM {\n    id\n    name\n  }\n  logs {\n    id\n    user {\n      id\n    }\n    lot {\n      id\n    }\n    fromLot {\n      id\n    }\n    operation\n    quantity\n    notes\n    createdAt\n  }\n  lots {\n    edges {\n      quantity\n      node {\n        id\n        lotNumber\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  lotTracking\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "lot",
            "items"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ddbeb9d1caadb024c56045cddabe8572';
module.exports = node;

/**
 * @flow
 * @relayHash b67395e89b678eaf0b6b5dd70dd501dd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
export type AddFormInput = {|
  name: string,
  description?: ?string,
|};
export type AddFormMutationVariables = {|
  input: AddFormInput
|};
export type AddFormMutationResponse = {|
  +addForm: {|
    +formEdge: {|
      +node: {|
        +id: string,
        +name: string,
        +description: ?string,
        +type: FormType,
      |}
    |}
  |}
|};
export type AddFormMutation = {|
  variables: AddFormMutationVariables,
  response: AddFormMutationResponse,
|};
*/


/*
mutation AddFormMutation(
  $input: AddFormInput!
) {
  addForm(input: $input) {
    formEdge {
      node {
        id
        name
        description
        type
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddFormInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addForm",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddFormPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "formEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "FormEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Form",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddFormMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddFormMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddFormMutation",
    "id": null,
    "text": "mutation AddFormMutation(\n  $input: AddFormInput!\n) {\n  addForm(input: $input) {\n    formEdge {\n      node {\n        id\n        name\n        description\n        type\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e5e2499d4bfae17e6507f154cfbe19c';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import * as Actions from 'main-app/store/Actions';
import { i18n } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import config from 'main-app/config';
import { fonts, colors } from 'shared/styleguide';
import Icon from 'shared/components/common/Icon';
import DeleteAttachmentModal from 'main-app/components/DeleteAttachmentModal';
import type { AttachmentsGrid_attachmentEdges as AttachmentEdgesFragment } from './__generated__/AttachmentsGrid_attachmentEdges';

type Props = {
  attachmentEdges: AttachmentEdgesFragment,
  parentId: string,
};

type State = {
  deleteAttachmentRecord: ?Object,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: 1000px) {
    justify-content: flex-start;
  }
`;

const ContextIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: none;
`;

const Attachment = styled.div`
  width: 48%;

  @media (min-width: 700px) {
    width: 170px;
    padding: 16px;
    transition: background-color 300ms ease;

    &:hover {
      cursor: pointer;
      background-color: ${colors.paleGrey};

      ${ContextIconWrapper} {
        display: block;
      }
    }
  }
`;

const FileIconWrapper = styled.div`
  position: relative;
  padding: 32px 0;
  text-align: center;
  border-radius: 10px;
  border: 1px solid ${colors.coolGrey};
  background-color: ${colors.white};
`;

const DetailWrapper = styled.div`
  padding: 8px 0;
  ${fonts.smallRegular};
  color: ${colors.charcoalGrey};

  strong {
    display: block;
    ${fonts.smallBold};
    padding-bottom: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  i {
    padding-top: 8px;
    display: block;
  }
`;

class AttachmentsGrid extends PureComponent<Props, State> {
  state = {
    deleteAttachmentRecord: null,
  };
  menuTriggerRefs = {};

  getAttachmentById = (attachmentId: string) => {
    const { attachmentEdges } = this.props;

    const attachment = attachmentEdges.find(edge => {
      return edge?.node?.id === attachmentId;
    });

    return attachment?.node || null;
  };

  handleOpenUrl = (urlPath: string) => {
    window.open(config.cdnUrl + '/' + urlPath);
  };

  handleOpenAttachment = (e, data, target) => {
    const attachmentId = target.getAttribute('attachment-id');
    const attachment = this.getAttachmentById(attachmentId);

    if (attachment) {
      this.handleOpenUrl(attachment.urlPath);
    }
  };

  handleCopyLink = (e, data, target) => {
    e.preventDefault();
    e.stopPropagation();
    const attachmentId = target.getAttribute('attachment-id');
    const attachment = this.getAttachmentById(attachmentId);

    if (!attachment) {
      return;
    }

    const url = config.cdnUrl + '/' + attachment.urlPath;

    const dummyInput = document.createElement('input');
    document.body.appendChild(dummyInput); // $FlowIgnore
    dummyInput.setAttribute('id', `${attachmentId}-dummy-input`);
    document.getElementById(`${attachmentId}-dummy-input`).value = url;
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);

    Actions.alertNotification(
      i18n.t('{{fileName}} attachment link copied', {
        fileName: attachment.name,
      }),
      'success',
    );
  };

  handleOpenDeleteModal = (e, data, target) => {
    e.preventDefault();
    e.stopPropagation();
    const attachmentId = target.getAttribute('attachment-id');
    const attachment = this.getAttachmentById(attachmentId);

    this.setState({
      deleteAttachmentRecord: attachment,
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({ deleteAttachmentRecord: null });
  };

  render() {
    const { attachmentEdges, parentId } = this.props;
    const { deleteAttachmentRecord } = this.state;
    const attachments = attachmentEdges.filter(Boolean).map(edge => edge.node);

    return (
      <>
        <Wrapper>
          {attachments.map(attachment => {
            const fileExtension = attachment.name.split('.').pop();

            return (
              <Attachment
                onClick={() => this.handleOpenUrl(attachment.urlPath)}
                key={attachment.id}
              >
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[attachment.id] = r)}
                  id="attachments-grid-menu"
                  attributes={{
                    'attachment-id': attachment.id,
                  }}
                >
                  <FileIconWrapper>
                    <div
                      className={`fiv-viv fiv-icon-${fileExtension}`}
                      style={{
                        fontSize: 48,
                      }}
                    />
                    <ContextIconWrapper>
                      <Icon
                        type="circle-context-menu"
                        size={24}
                        attributes={{
                          'attachment-id': attachment.id,
                        }}
                        onClick={(e, data, target) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (this.menuTriggerRefs[attachment.id]) {
                            this.menuTriggerRefs[
                              attachment.id
                            ].handleContextClick(e, data, target);
                          }
                        }}
                      />
                    </ContextIconWrapper>
                  </FileIconWrapper>
                  <DetailWrapper>
                    <strong>{attachment.name}</strong>
                    {moment(attachment.createdAt).format('MM/DD/YY hh:mm A')}
                    {attachment.user && (
                      <i>
                        {i18n.t('Uploaded by: {{fullName}}', {
                          fullName:
                            attachment.user.firstName +
                            ' ' +
                            attachment.user.lastName,
                        })}
                      </i>
                    )}
                  </DetailWrapper>
                </ContextMenuTrigger>
              </Attachment>
            );
          })}
        </Wrapper>
        <ContextMenu id="attachments-grid-menu">
          <MenuItem onClick={this.handleOpenAttachment}>
            {i18n.t('Open')}
          </MenuItem>
          <MenuItem onClick={this.handleCopyLink}>
            {i18n.t('Copy Link')}
          </MenuItem>
          <MenuItem onClick={this.handleOpenDeleteModal}>
            {i18n.t('Delete')}
          </MenuItem>
        </ContextMenu>
        {deleteAttachmentRecord && (
          <DeleteAttachmentModal
            onClose={this.handleCloseDeleteModal}
            attachment={deleteAttachmentRecord}
            parentId={parentId}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(AttachmentsGrid, {
  attachmentEdges: graphql`
    fragment AttachmentsGrid_attachmentEdges on AttachmentEdge
      @relay(plural: true) {
      node {
        id
        name
        urlPath
        createdAt
        user {
          id
          firstName
          lastName
        }
        ...DeleteAttachmentModal_attachment
      }
    }
  `,
});

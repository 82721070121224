// @flow

import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { Log, i18n, Analytics } from 'shared/utils';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import * as Actions from 'main-app/store/Actions';
import { hideMenu as hideContextMenu } from 'react-contextmenu';
import DeleteUserSavedFilterMutation from 'main-app/mutations/DeleteUserSavedFilter';
import AppliedTag from 'shared/components/common/AppliedTag';
import SpecialFieldsMap from './SpecialFieldsMap';
import {
  Wrapper,
  InnerWrapper,
  SaveButtonWrapper,
  DesktopAppliedFiltersListWrapper,
} from './styled';
import AppliedFiltersList from './AppliedFiltersList';
import TopControlBar from './TopControlBar';
import SortBySelector from './SortBySelector';
import SavedFilterSelector from './SavedFilterSelector';
import SaveModal from './SaveModal';

export type FilterOption = {
  label: string,
  type:
    | 'text'
    | 'date'
    | 'date-range'
    | 'select'
    | 'user'
    | 'customer'
    | 'order'
    | 'item-category',
  name: string,
  multi?: boolean,
  selectOptions?: Array<{
    label: string,
    value: any,
  }>,
  componentProps?: Object,
};

export type SortOption = {
  label: string,
  value: {
    field: string,
    direction: string,
  },
};

export type FilterValue = {
  [key: string]: string | Array<string>,
  ...{
    sort: string,
    direction: 'ASC' | 'DESC',
  },
  ...*,
};

export type MassagedFilter = {
  filterOption: FilterOption,
  name: string,
  value: string,
  cosmeticValue: string | React$Node,
};

export type SavedFilter = {
  id: string,
  name: string,
  type: string,
  filter: string,
};

export const SORT_PROPERTY_NAMES = ['sort', 'direction'];

type Props = {
  value: FilterValue,
  defaultValue: FilterValue,
  filterOptions: Array<FilterOption>,
  sortOptions?: Array<SortOption>,
  savedFilterType?: string,
  onFiltersChange: FilterValue => void,
  style?: Object,
};

type State = {
  saveFilterModalOpen: boolean,
};

class FilterControls extends PureComponent<Props, State> {
  static defaultProps = {
    savedFilterType: undefined,
    sortOptions: undefined,
    style: undefined,
  };

  state = {
    saveFilterModalOpen: false,
  };

  /**
   * This method has two purposes.
   * 1. Converts the filters object (aka this.props.value) into an easy-traversible array of objects
   * 2. Generates a `cosmeticValue` property with a human-readable label instead of the filter's value
   * @example
   * if this.props.value is: { foo: ['1', '2', '3'] }
   * it becomes: [{ name: 'foo', value: '1' }, { name: 'foo', value: '2' }, { name: 'foo', value: '3' }]
   * @example
   * if this.props.value has this filter: { userIds: ['abc123'] }
   * it becomes: [{ name: 'userIds', value: 'abc123', cosmeticValue: 'John Smith'}]
   * @param {Object} filters
   * @return {Array<Object>}
   */
  getMassagedFilters = (): Array<MassagedFilter> => {
    const { value, filterOptions } = this.props;
    const massagedFilters = [];

    for (const filterName in value) {
      // Don't add sort-related fields into the massaged filters array
      if (SORT_PROPERTY_NAMES.indexOf(filterName) > -1) {
        continue;
      }

      // Retrieve the filterOption data
      const filterOption = filterOptions.find(a => a.name === filterName);
      if (!filterOption) {
        // NOTE: this should never happen
        continue;
      }

      if (filterOption.multi) {
        if (Array.isArray(value[filterName])) {
          for (const filterValue of value[filterName]) {
            pushMassagedFilter(filterOption, filterName, filterValue);
          }
        }
      } else {
        pushMassagedFilter(filterOption, filterName, value[filterName]);
      }
    }

    return massagedFilters;

    function pushMassagedFilter(filterOption, filterName, filterValue: any) {
      const specialField = SpecialFieldsMap[filterOption.type];
      const selectOption = filterOption.selectOptions
        ? filterOption.selectOptions.find(a => a.value === filterValue)
        : null;

      const massagedFilter = {
        filterOption,
        name: filterName,
        value: filterValue,
        cosmeticValue: filterValue,
      };

      if (selectOption) {
        massagedFilter.cosmeticValue = selectOption.label;
      } else if (specialField) {
        massagedFilter.cosmeticValue = (
          <specialField.cosmeticValue value={filterValue} />
        );
      }

      massagedFilters.push(massagedFilter);
    }
  };

  getSavedFilterId = (): ?string => {
    const { value } = this.props;

    if (value && value.savedFilterId) {
      return (value.savedFilterId: any);
    }

    return null;
  };

  filtersDirty = (): boolean => {
    const { value, defaultValue } = this.props;
    return !Map(value).equals(Map(defaultValue));
  };

  handleUpdateSorting = (sort: string, direction: 'ASC' | 'DESC') => {
    this.handleApplyFilters([
      {
        name: 'sort',
        value: sort,
      },
      {
        name: 'direction',
        value: direction,
      },
    ]);
  };

  handleApplyFilters = (filters: Array<{ name: string, value: any }>) => {
    const { filterOptions, value, onFiltersChange } = this.props;
    const currentFilter = Map(value);
    let updatedFilter = currentFilter;

    for (const appliedFilter of filters) {
      const filterOption =
        filterOptions.find(a => a.name === appliedFilter.name) || {};
      let filterValue = appliedFilter.value;

      // Massage date range value into a single string
      if (appliedFilter.value.startDate || appliedFilter.value.endDate) {
        filterValue = [
          appliedFilter.value.startDate
            ? appliedFilter.value.startDate.format('YYYY-MM-DD')
            : '',
          appliedFilter.value.endDate
            ? appliedFilter.value.endDate.format('YYYY-MM-DD')
            : '',
        ].join(' - ');
      }

      if (filterOption.multi) {
        const currentVal = updatedFilter.get(appliedFilter.name);

        if (!currentVal || currentVal.indexOf(filterValue) < 0) {
          updatedFilter = updatedFilter.set(
            appliedFilter.name,
            (currentVal || []).concat(filterValue),
          );
        }
      } else {
        updatedFilter = updatedFilter.set(appliedFilter.name, filterValue);
      }
    }

    if (currentFilter !== updatedFilter) {
      onFiltersChange(updatedFilter.toJSON());

      Analytics.trackEvent('Apply Filter', {
        appliedFilters: Array.from(updatedFilter.keys()),
      });
    }
  };

  handleRemoveFilter = (filterName: string, filterValue: string) => {
    const { filterOptions, value, onFiltersChange } = this.props;
    const filterOption = filterOptions.find(a => a.name === filterName) || {};
    const currentFilter = Map(value);
    let updatedFilter = currentFilter;

    if (filterOption.multi) {
      const currentVal: any = updatedFilter.get(filterName);
      let updatedVal = [];

      if (currentVal) {
        updatedVal = currentVal.filter(a => a !== filterValue);
        updatedFilter = updatedFilter.set(filterName, updatedVal);
      }

      if (!updatedVal.length) {
        updatedFilter = updatedFilter.delete(filterName);
      }
    } else {
      updatedFilter = updatedFilter.delete(filterName);
    }

    if (currentFilter !== updatedFilter) {
      onFiltersChange(updatedFilter.toJSON());
      Analytics.trackEvent('Remove Filter', {
        appliedFilters: Array.from(updatedFilter.keys()),
      });
    }
  };

  handleResetFilters = () => {
    const { onFiltersChange } = this.props;
    onFiltersChange({});
    Analytics.trackEvent('Reset Filter');
  };

  handleToggleSaveModal = (open: boolean) => {
    this.setState({
      saveFilterModalOpen: open,
    });
  };

  handleSelectSavedFilter = (savedFilter: SavedFilter) => {
    const { filterOptions, onFiltersChange } = this.props;

    try {
      const parsedFilter = JSON.parse(savedFilter.filter);
      const cleanedFilter = {};

      for (const k in parsedFilter) {
        const filterOption = filterOptions.find(a => a.name === k);

        const invalidFilterOption =
          !filterOption ||
          (filterOption.multi && !Array.isArray(parsedFilter[k]));
        const invalidSortOption = SORT_PROPERTY_NAMES.indexOf(k) < 0;

        if (invalidFilterOption && invalidSortOption) {
          continue;
        }

        cleanedFilter[k] = parsedFilter[k];
      }

      if (!Object.keys(cleanedFilter).length) {
        return;
      }

      onFiltersChange({
        ...cleanedFilter,
        savedFilterId: savedFilter.id,
      });

      Analytics.trackEvent('Apply Saved Filter', {
        filterName: savedFilter.name,
        filterType: Object.keys(cleanedFilter),
      });
    } catch (e) {
      Log.warn(e);
    }
  };

  handleDeleteSavedFilter = async (savedFilter: SavedFilter) => {
    const currentSavedFilterId = this.getSavedFilterId();
    const name = savedFilter.name;

    const confirmDelete = window.confirm(
      i18n.t(`Are you sure you want to delete the filters "{{name}}"?`, {
        name,
      }),
    );

    if (!confirmDelete) {
      return;
    }

    hideContextMenu();

    try {
      await DeleteUserSavedFilterMutation.commit({
        variables: {
          input: {
            id: savedFilter.id,
          },
        },
      });

      // Reset the filters if the currently applied filter is the same one being deleted
      if (currentSavedFilterId === savedFilter.id) {
        this.handleResetFilters();
      }

      Actions.alertNotification(
        i18n.t(`Successfully deleted Saved Filter "{{name}}"`, {
          name,
        }),
        'success',
      );
    } catch (e) {
      Actions.alertNotification(e.message, 'error');
    }
  };

  render() {
    const {
      savedFilterType,
      value,
      filterOptions,
      sortOptions,
      style,
    } = this.props;
    const { saveFilterModalOpen } = this.state;
    const massagedFilters = this.getMassagedFilters();
    const savedFilterId = this.getSavedFilterId();
    const filtersDirty = this.filtersDirty();
    const savedFiltersEnabled = Boolean(savedFilterType);

    return (
      <QueryRenderer
        environment={relayEnvironment}
        query={graphql`
          query FilterControlsUserSavedFiltersQuery(
            $type: UserSavedFilterType
            $skipSavedFilters: Boolean!
          ) {
            userSavedFilters(type: $type, first: null)
              @skip(if: $skipSavedFilters)
              @connection(key: "FilterControls_userSavedFilters", filters: []) {
              edges {
                node {
                  id
                  name
                  type
                  filter
                }
              }
            }
          }
        `}
        variables={{
          type: savedFilterType,
          skipSavedFilters: !savedFiltersEnabled,
        }}
        render={query => {
          const savedFilters = query.props?.userSavedFilters?.edges || [];

          return (
            <Wrapper style={style}>
              <InnerWrapper>
                <TopControlBar
                  filtersDirty={filtersDirty}
                  filterValue={value}
                  filterOptions={filterOptions}
                  savedFilterId={savedFilterId}
                  massagedFilters={massagedFilters}
                  onApplyFilters={this.handleApplyFilters}
                  onSaveFilter={() => this.handleToggleSaveModal(true)}
                  onRemoveFilter={this.handleRemoveFilter}
                  onResetFilters={this.handleResetFilters}
                />
                {savedFiltersEnabled ? (
                  <SavedFilterSelector
                    savedFilterId={savedFilterId}
                    savedFilters={savedFilters.map(a => a.node)}
                    onSelectSavedFilter={this.handleSelectSavedFilter}
                    onDeleteSavedFilter={this.handleDeleteSavedFilter}
                  />
                ) : null}
                {sortOptions && (
                  <SortBySelector
                    value={value}
                    sortOptions={sortOptions}
                    onSortChange={this.handleUpdateSorting}
                  />
                )}
              </InnerWrapper>
              <DesktopAppliedFiltersListWrapper>
                {filtersDirty && (
                  <AppliedFiltersList
                    massagedFilters={massagedFilters}
                    onRemoveFilter={this.handleRemoveFilter}
                    saveButton={
                      savedFiltersEnabled && (
                        <SaveButtonWrapper>
                          <AppliedTag
                            icon="save"
                            theme="blue-filled"
                            onClick={() => this.handleToggleSaveModal(true)}
                          >
                            {i18n.t('Save Filters')}
                          </AppliedTag>
                        </SaveButtonWrapper>
                      )
                    }
                  />
                )}
              </DesktopAppliedFiltersListWrapper>
              {savedFilterType && saveFilterModalOpen && (
                <SaveModal
                  savedFilterType={savedFilterType}
                  savedFilter={
                    savedFilterId
                      ? savedFilters.find(a => a.node.id === savedFilterId)
                      : null
                  }
                  filterValue={value}
                  onSaveFilterComplete={this.handleSelectSavedFilter}
                  onClose={() => this.handleToggleSaveModal(false)}
                />
              )}
            </Wrapper>
          );
        }}
      />
    );
  }
}

export default FilterControls;

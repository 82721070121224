/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateJobYieldModal_jobYield$ref = any;
type DeleteJobYieldModal_jobYield$ref = any;
type FormBuilder_form$ref = any;
type YieldProgressBar_jobYield$ref = any;
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobYieldPanels_jobYieldEdges$ref: FragmentReference;
declare export opaque type JobYieldPanels_jobYieldEdges$fragmentType: JobYieldPanels_jobYieldEdges$ref;
export type JobYieldPanels_jobYieldEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +quantity: number,
    +itemNumberUp: number,
    +brokerCustomerOrderNumber: ?string,
    +formFieldValues: any,
    +form: {|
      +id: string,
      +fields: $ReadOnlyArray<{|
        +id: string,
        +systemKey: ?string,
        +name: string,
        +type: FormFieldType,
      |}>,
      +$fragmentRefs: FormBuilder_form$ref,
    |},
    +item: {|
      +id: string,
      +itemNumber: string,
      +name: string,
      +description: ?string,
      +partNumber: ?string,
      +formFieldValues: any,
      +quantityUOM: {|
        +id: string,
        +symbol: string,
      |},
      +form: {|
        +id: string,
        +fields: $ReadOnlyArray<{|
          +id: string,
          +systemKey: ?string,
          +name: string,
          +type: FormFieldType,
        |}>,
        +$fragmentRefs: FormBuilder_form$ref,
      |},
    |},
    +$fragmentRefs: AddUpdateJobYieldModal_jobYield$ref & DeleteJobYieldModal_jobYield$ref & YieldProgressBar_jobYield$ref,
  |},
  +$refType: JobYieldPanels_jobYieldEdges$ref,
|}>;
export type JobYieldPanels_jobYieldEdges$data = JobYieldPanels_jobYieldEdges;
export type JobYieldPanels_jobYieldEdges$key = {
  +$data?: JobYieldPanels_jobYieldEdges$data,
  +$fragmentRefs: JobYieldPanels_jobYieldEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "formFieldValues",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "form",
  "storageKey": null,
  "args": null,
  "concreteType": "Form",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fields",
      "storageKey": null,
      "args": null,
      "concreteType": "FormField",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "systemKey",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "FormBuilder_form",
      "args": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "JobYieldPanels_jobYieldEdges",
  "type": "JobYieldEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "JobYield",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "itemNumberUp",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "brokerCustomerOrderNumber",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "item",
          "storageKey": null,
          "args": null,
          "concreteType": "Item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "itemNumber",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "description",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "partNumber",
              "args": null,
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "quantityUOM",
              "storageKey": null,
              "args": null,
              "concreteType": "UOM",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "symbol",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            (v3/*: any*/)
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateJobYieldModal_jobYield",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "DeleteJobYieldModal_jobYield",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "YieldProgressBar_jobYield",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '93f61402957c5f1c217aa8f213221dcf';
module.exports = node;

// @flow

import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

export const Wrapper = styled.div`
  flex: 1;
  padding-bottom: 16px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  padding-right: 8px;

  @media (min-width: 1000px) {
    display: block;
  }
`;

export const MobileTotalFilters = styled.div`
  display: block;
  ${fonts.bodyBold}
  color: ${colors.azul};
  padding-top: 16px;

  /* Filter summaries only show on mobile */
  @media(min-width: 1000px) {
    display: none;
  }
`;

export const DesktopWrapper = styled.div`
  z-index: 2;
  border: 1px solid ${colors.lightBlueGrey};
  background-color: ${colors.white};
  box-shadow: -1px 2px 7px 0 rgba(66, 68, 76, 0.5);
  border-radius: 5px;
  padding: 16px;
  margin-top: 5px;
  width: 100%;
  list-style: none;
  width: 100%;
  max-width: 520px;
  box-shadow: -1px 2px 7px 0 rgba(66, 68, 76, 0.5);
  position: absolute;
`;

// ****************************************************
// * ApplyFilterMobile styles
// ****************************************************
export const MobileFormSeparator = styled.div`
  text-align: center;
  padding-bottom: 16px;
`;

export const MobileButtonWrapper = styled.div`
  ${fonts.bodyBold} text-align: center;
`;

export const MobileAppliedFiltersWrapper = styled.div`
  padding: 16px;
  border-top: 1px solid ${colors.lightBlueGrey};

  button {
    margin: 0 8px 8px 0;
  }
`;

// ****************************************************
// * ApplyFilterDesktop styles
// ****************************************************
export const DesktopFormWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const DesktopFormSeparator = styled.div`
  padding: 16px;
`;

export const DesktopButtonWrapper = styled.div`
  display: block;
  text-align: right;
`;

// @flow

import React from 'react';
import { i18n } from 'shared/utils';
import { FieldGroup } from 'shared/components/form';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';

const Wrapper = styled.div`
  display: flex;
`;

const Box = styled.div`
  padding-left: ${props => (props.left ? '8px' : '0px')};
  padding-right: ${props => (props.right ? '8px' : '0px')};
  width: 100%;
`;

const ToggleWrapper = styled.div`
  flex-shrink: ${props => (props.isOpen ? 1 : 2)};
  width: 100%;
`;

const Closed = styled.div`
  width: 100%;
  background-color: ${colors.paleGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.coolGrey}
  margin-bottom: 16px;
  border-radius: 3px;
`;

type Props = {
  name: string,
  label: string,
  isOpen: boolean,
  hoursStartFieldName: string,
  hoursEndFieldName: string,
  hoursStartError: any,
  hoursEndError: any,
};

export default function OperatingHoursField(props: Props) {
  const {
    name,
    label,
    isOpen,
    hoursStartFieldName,
    hoursEndFieldName,
    hoursStartError,
    hoursEndError,
  } = props;
  return (
    <Wrapper>
      <ToggleWrapper isOpen={isOpen}>
        <FieldGroup name={name} label={label} type="toggle" />
      </ToggleWrapper>
      {isOpen ? (
        <React.Fragment>
          <Box left>
            <FieldGroup
              name={hoursStartFieldName}
              label={i18n.t('Open Time')}
              type="time"
              error={hoursStartError}
            />
          </Box>
          <Box left>
            <FieldGroup
              name={hoursEndFieldName}
              label={i18n.t('Close Time')}
              type="time"
              error={hoursEndError}
            />
          </Box>
        </React.Fragment>
      ) : (
        <Closed>{`${i18n.t('Closed on')} ${label}`}</Closed>
      )}
    </Wrapper>
  );
}

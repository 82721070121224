/**
 * @flow
 * @relayHash 7e7fd32ea8d958346ba39fd00b19433b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicationType = "MAIN_APP" | "OPERATOR_PORTAL";
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
export type AddFormFieldInput = {|
  formId: string,
  name: string,
  description?: ?string,
  placeholder?: ?string,
  type: FormFieldType,
  isCreatable?: ?boolean,
  properties?: ?any,
|};
export type AddFormFieldMutationVariables = {|
  input: AddFormFieldInput
|};
export type AddFormFieldMutationResponse = {|
  +addFormField: {|
    +form: {|
      +id: string,
      +name: string,
      +type: FormType,
      +description: ?string,
      +displayOrder: number,
      +isTemplate: boolean,
      +updatedAt: any,
      +createdAt: any,
      +fields: $ReadOnlyArray<{|
        +id: string,
        +type: FormFieldType,
        +name: string,
        +description: ?string,
        +applicationType: ApplicationType,
        +placeholder: ?string,
        +displayOrder: number,
        +systemKey: ?string,
        +isRequired: boolean,
        +updatedAt: any,
        +createdAt: any,
        +isCreatable: boolean,
        +properties: ?any,
        +options: $ReadOnlyArray<{|
          +id: string,
          +name: string,
          +value: string,
        |}>,
      |}>,
    |}
  |}
|};
export type AddFormFieldMutation = {|
  variables: AddFormFieldMutationVariables,
  response: AddFormFieldMutationResponse,
|};
*/


/*
mutation AddFormFieldMutation(
  $input: AddFormFieldInput!
) {
  addFormField(input: $input) {
    form {
      id
      name
      type
      description
      displayOrder
      isTemplate
      updatedAt
      createdAt
      fields {
        id
        type
        name
        description
        applicationType
        placeholder
        displayOrder
        systemKey
        isRequired
        updatedAt
        createdAt
        isCreatable
        properties
        options {
          id
          name
          value
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddFormFieldInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "displayOrder",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addFormField",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddFormFieldPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "form",
        "storageKey": null,
        "args": null,
        "concreteType": "Form",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isTemplate",
            "args": null,
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fields",
            "storageKey": null,
            "args": null,
            "concreteType": "FormField",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "applicationType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "placeholder",
                "args": null,
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "systemKey",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isRequired",
                "args": null,
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isCreatable",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "properties",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "options",
                "storageKey": null,
                "args": null,
                "concreteType": "FormFieldOption",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "value",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddFormFieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v8/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddFormFieldMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v8/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddFormFieldMutation",
    "id": null,
    "text": "mutation AddFormFieldMutation(\n  $input: AddFormFieldInput!\n) {\n  addFormField(input: $input) {\n    form {\n      id\n      name\n      type\n      description\n      displayOrder\n      isTemplate\n      updatedAt\n      createdAt\n      fields {\n        id\n        type\n        name\n        description\n        applicationType\n        placeholder\n        displayOrder\n        systemKey\n        isRequired\n        updatedAt\n        createdAt\n        isCreatable\n        properties\n        options {\n          id\n          name\n          value\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6ff31b9ea943bcb356bdf1930a83c35';
module.exports = node;

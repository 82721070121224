// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddItemMovementModal from 'main-app/components/AddItemMovementModal';
import ItemLotsTable from './ItemLotsTable';
import type { ItemLots_itemLotEdges as ItemLotEdgesFragment } from './__generated__/ItemLots_itemLotEdges';

type Props = {
  history: RouterHistory,
  item: Object,
  itemId: string,
  itemUOM: string,
  itemLotEdges: ItemLotEdgesFragment,
};

type State = {
  addMovementModalOpen: boolean,
  createAnotherMovement: boolean,
};

const ButtonWrapper = styled.div`
  padding-bottom: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

class ItemLots extends PureComponent<Props, State> {
  state = {
    addMovementModalOpen: false,
    createAnotherMovement: false,
  };

  handleToggleItemMovementModal = () => {
    const { addMovementModalOpen } = this.state;

    this.setState({
      addMovementModalOpen: !addMovementModalOpen,
      createAnotherMovement: false,
    });
  };

  handleAddMovementModalReopen = () => {
    this.setState(
      {
        addMovementModalOpen: false,
        createAnotherMovement: true,
      },
      () => {
        this.setState({
          addMovementModalOpen: true,
        });
      },
    );
  };

  handleCloseAllModals = () => {
    this.setState({
      addMovementModalOpen: false,
      createAnotherMovement: false,
    });
  };

  render() {
    const { itemLotEdges, item, itemId, itemUOM, history } = this.props;
    const { addMovementModalOpen, createAnotherMovement } = this.state;

    if (!item?.lotTracking) {
      return (
        <EmptyListResults
          graphic="itemLots"
          message={i18n.t('Lot tracking has been disabled for this item.')}
        />
      );
    }

    return (
      <>
        <Wrapper>
          <ButtonWrapper>
            <Button
              theme="blue"
              width="auto"
              onClick={this.handleToggleItemMovementModal}
            >
              {i18n.t('Create Item Movement')}
            </Button>
          </ButtonWrapper>
          <ItemLotsTable
            history={history}
            itemLotEdges={itemLotEdges}
            UOM={itemUOM}
          />
        </Wrapper>
        {addMovementModalOpen && (
          <AddItemMovementModal
            onClose={this.handleCloseAllModals}
            onSuccess={movement => history.push(`/item/${itemId}`)}
            onReopen={this.handleAddMovementModalReopen}
            createAnotherMovement={createAnotherMovement}
            item={item}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(ItemLots, {
  itemLotEdges: graphql`
    fragment ItemLots_itemLotEdges on ItemLotEdge @relay(plural: true) {
      quantity
      node {
        id
        lotNumber
        binNumber
        quantity
        expiresAt
      }
      ...ItemLotsTable_itemLotEdges
    }
  `,
});

/**
 * @flow
 * @relayHash d234d88cb6209e154e8525d537cb2f93
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OverallProgressPanelQueryVariables = {|
  overallProgressStartAt: any,
  overallProgressEndAt: any,
|};
export type OverallProgressPanelQueryResponse = {|
  +metricsOverallProgress: {|
    +quantity: number,
    +quantityCompleted: number,
  |}
|};
export type OverallProgressPanelQuery = {|
  variables: OverallProgressPanelQueryVariables,
  response: OverallProgressPanelQueryResponse,
|};
*/


/*
query OverallProgressPanelQuery(
  $overallProgressStartAt: DateTime!
  $overallProgressEndAt: DateTime!
) {
  metricsOverallProgress(startAt: $overallProgressStartAt, endAt: $overallProgressEndAt) {
    quantity
    quantityCompleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "overallProgressStartAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "overallProgressEndAt",
    "type": "DateTime!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "metricsOverallProgress",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "overallProgressEndAt"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "overallProgressStartAt"
      }
    ],
    "concreteType": "MetricsOverallProgress",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "quantity",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "quantityCompleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OverallProgressPanelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OverallProgressPanelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "OverallProgressPanelQuery",
    "id": null,
    "text": "query OverallProgressPanelQuery(\n  $overallProgressStartAt: DateTime!\n  $overallProgressEndAt: DateTime!\n) {\n  metricsOverallProgress(startAt: $overallProgressStartAt, endAt: $overallProgressEndAt) {\n    quantity\n    quantityCompleted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'efced231e43aca68a77242f927ac95d2';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateJobLinkedItemMutation($input: UpdateJobLinkedItemInput!) {
    updateJobLinkedItem(input: $input) {
      jobEdge {
        ...JobsTable_jobEdges
        node {
          ...JobLinkedItems_job
          id
          importJobNumber
          jobNumber
          name
          status
          linkedItems(first: null) {
            edges {
              usageQty
              node {
                id
                itemNumber
                name
                upc
                partNumber
                description
                category {
                  id
                  name
                }
                quantityUOM {
                  id
                  name
                  type
                  symbol
                }
                weightUOM {
                  id
                  name
                  type
                  symbol
                }
                dimensionUOM {
                  id
                  name
                  type
                  symbol
                }
                formFieldValues
                dimensionLength
                dimensionWidth
                dimensionHeight
                weight
              }
            }
          }
          yields(first: null) {
            totalCount
            edges {
              node {
                id
                finalQuantity
              }
            }
          }
          user {
            id
            firstName
            lastName
          }
          order {
            id
            promisedAt
            orderNumber
            status
            customer {
              id
              name
            }
          }
          workflow {
            id
            name
          }
          states {
            id
            workflowState {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

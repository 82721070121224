// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteRunMutation from 'main-app/mutations/DeleteRun';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteRunModal_run as RunFragment } from './__generated__/DeleteRunModal_run';

type Props = {
  onClose: () => void,
  jobId: string,
  run: RunFragment,
  onSuccess?: () => void,
};

type State = {
  deleting: boolean,
};

class DeleteRunModal extends PureComponent<Props, State> {
  static defaultProps = {
    onSuccess: undefined,
  };

  state = {
    deleting: false,
  };

  handleDelete = () => {
    const { jobId, run, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteRunMutation.commit({
            variables: {
              input: {
                id: run.id,
              },
            },
            context: {
              jobId,
            },
          });

          onClose();

          Actions.alertNotification(
            i18n.t(`Run Successfully Deleted`),
            'success',
          );

          Analytics.trackEvent('Delete Run', {
            runId: run.id,
          });

          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { run, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete Run: {{runName}}?', {
            runName: run.machine.name + ' / ' + run.machine.type.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            'Deleting this run will remove it from all associated items and could affect the status of the Job.',
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Run')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteRunModal, {
  run: graphql`
    fragment DeleteRunModal_run on Run {
      id
      machine {
        id
        name
        type {
          id
          name
        }
      }
    }
  `,
});

// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { i18n, WorkflowUtils } from 'shared/utils';
import Panel from 'shared/components/common/Panel';
import YieldProgressBar from 'shared/components/common/YieldProgressBar';
import { fonts, colors } from 'shared/styleguide';
import { Trans } from 'react-i18next';
import type { RunYieldProgressBarPanels_job as JobFragment } from './__generated__/RunYieldProgressBarPanels_job';

type Props = {
  job: JobFragment,
};

const Wrapper = styled.div`
  color: ${colors.charcoalGrey};

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const SectionTitle = styled.div`
  ${fonts.bodyBold}
`;

const SectionText = styled.div`
  ${fonts.bodyRegular}
`;

const HeaderBottomWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${SectionText} {
    text-align: right;
  }
`;

const massageRunEdgesToJobStates = job => {
  const massagedJobStates = {};
  const runEdges = (job.runs.edges || []).filter(Boolean);
  const runJobStates = job.states.filter(
    jobState => jobState.isEnabled && jobState.workflowState.type === 'RUN',
  );

  for (const runEdge of runEdges) {
    const {
      yields: { edges },
      runTotalSeconds,
      jobState,
    } = runEdge.node;
    const runYieldEdges = (edges || []).filter(Boolean);

    // Populate Job State Panels with info from Runs
    if (runYieldEdges.length) {
      // RunYieldEdges contains RunYield information on the edge and JobYield as it's node
      for (const runYieldEdge of runYieldEdges) {
        const { node: jobYield, runQuantity } = runYieldEdge;

        if (!massagedJobStates[jobState.id]) {
          massagedJobStates[jobState.id] = {
            ...jobState,
            jobYields: {
              [jobYield.id]: {
                jobYield,
                totalRunQuantity: runQuantity,
              },
            },
          };
        } else {
          if (massagedJobStates[jobState.id].jobYields[jobYield.id]) {
            massagedJobStates[jobState.id].jobYields[
              jobYield.id
            ].totalRunQuantity += runQuantity;
          } else {
            massagedJobStates[jobState.id].jobYields[jobYield.id] = {
              jobYield,
              totalRunQuantity: runQuantity,
            };
          }
        }
      }
    }
    if (!massagedJobStates[jobState.id]) {
      massagedJobStates[jobState.id] = {
        ...jobState,
        runTotalSeconds: runTotalSeconds || 0,
      };
    } else {
      massagedJobStates[jobState.id].runTotalSeconds += runTotalSeconds || 0;
    }
  }

  // Populate Empty Job State Panels with default info
  for (const jobState of runJobStates) {
    if (!massagedJobStates[jobState.id]) {
      massagedJobStates[jobState.id] = {
        ...jobState,
      };
    }
  }

  return Object.values(massagedJobStates);
};

const convertSecondsToHoursMinutes = seconds => {
  const hours = Math.floor(seconds / 3600);
  const remainder = seconds % 3600;
  const minutes = Math.floor(remainder / 60);

  return { hours, minutes };
};

const createDurationLabel = jobState => {
  const jobStateName = jobState.workflowState.name;
  const runTotalSeconds = jobState.runTotalSeconds;

  if (runTotalSeconds) {
    const { hours, minutes } = convertSecondsToHoursMinutes(runTotalSeconds);

    return i18n.t('{{hours, number}}H {{minutes, number}}M', {
      hours,
      minutes,
    });
  }

  return (
    <Trans>
      Total duration of all completed runs in <strong>{jobStateName}</strong>{' '}
      will show here.
    </Trans>
  );
};

const RunYieldProgressBarPanels = ({ job }: Props) => {
  const jobStates: Object[] = massageRunEdgesToJobStates(job);
  const sortedJobStates = WorkflowUtils.getSortedJobStates(job);

  return (
    <Wrapper>
      {jobStates
        .sort((jobStateA, jobStateB) =>
          sortedJobStates
            ? (sortedJobStates[jobStateA.id]?.order || 0) -
              (sortedJobStates[jobStateB.id]?.order || 0)
            : 0,
        )
        .map(jobState => {
          const jobStateName = jobState.workflowState.name;
          const { isGangRun } = jobState.workflowState;
          const jobYields = jobState.jobYields;
          const totalRunDuration = createDurationLabel(jobState);

          // Show JobYields from Runs from the JobState if available
          if (jobYields) {
            const jobYieldIds = Object.keys(jobYields);
            const edges = jobYieldIds.map(jobYieldId => jobYields[jobYieldId]);

            return (
              <Panel
                key={jobState.id}
                title={jobStateName}
                headerBottom={
                  <HeaderBottomWrapper>
                    <SectionTitle>
                      {i18n.t('Total Actual Run Duration')}
                    </SectionTitle>
                    <SectionText>{totalRunDuration}</SectionText>
                  </HeaderBottomWrapper>
                }
              >
                <SectionTitle style={{ marginBottom: 16 }}>
                  {i18n.t('Total Quantities Produced')}
                </SectionTitle>
                {edges.map(edge => {
                  return (
                    <YieldProgressBar
                      key={edge.jobYield.id}
                      jobYield={edge.jobYield}
                      runQuantity={edge.totalRunQuantity}
                      isGangRun={isGangRun}
                    />
                  );
                })}
              </Panel>
            );
          }

          return (
            <Panel
              key={jobState.id}
              title={jobStateName}
              headerBottom={
                <HeaderBottomWrapper>
                  <SectionTitle>
                    {i18n.t('Total Actual Run Duration')}
                  </SectionTitle>
                  <SectionText>{totalRunDuration}</SectionText>
                </HeaderBottomWrapper>
              }
            >
              <SectionText>
                <Trans>
                  Total quantities for items in <strong>{jobStateName}</strong>{' '}
                  will show here.
                </Trans>
              </SectionText>
            </Panel>
          );
        })}
    </Wrapper>
  );
};

export default createFragmentContainer(RunYieldProgressBarPanels, {
  job: graphql`
    fragment RunYieldProgressBarPanels_job on Job {
      id
      ...WorkflowUtils_job @relay(mask: false)
      states {
        id
        isEnabled
        workflowState {
          id
          name
          type
        }
      }
      runs(first: null) @connection(key: "Runs_job_runs", filters: []) {
        edges {
          node {
            id
            runTotalSeconds
            jobState {
              id
              workflowState {
                id
                isGangRun
                name
              }
            }
            yields(first: null)
              @connection(key: "Runs_job_yields", filters: []) {
              edges {
                runQuantity
                node {
                  id
                  ...YieldProgressBar_jobYield
                }
              }
            }
          }
        }
      }
    }
  `,
});

/**
 * @flow
 * @relayHash 2c3dd82df442961ca7d9b0c0ea66c374
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ItemProgressPanelQueryVariables = {|
  itemProgressStartAt: any,
  itemProgressEndAt: any,
  itemProgressWorkflowId?: ?string,
|};
export type ItemProgressPanelQueryResponse = {|
  +metricsItemProgress: $ReadOnlyArray<{|
    +id: string,
    +itemNumber: string,
    +name: string,
    +quantity: number,
    +quantityCompleted: number,
  |}>
|};
export type ItemProgressPanelQuery = {|
  variables: ItemProgressPanelQueryVariables,
  response: ItemProgressPanelQueryResponse,
|};
*/


/*
query ItemProgressPanelQuery(
  $itemProgressStartAt: DateTime!
  $itemProgressEndAt: DateTime!
  $itemProgressWorkflowId: ID
) {
  metricsItemProgress(startAt: $itemProgressStartAt, endAt: $itemProgressEndAt, workflowId: $itemProgressWorkflowId) {
    id
    itemNumber
    name
    quantity
    quantityCompleted
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "itemProgressStartAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "itemProgressEndAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "itemProgressWorkflowId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "metricsItemProgress",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endAt",
        "variableName": "itemProgressEndAt"
      },
      {
        "kind": "Variable",
        "name": "startAt",
        "variableName": "itemProgressStartAt"
      },
      {
        "kind": "Variable",
        "name": "workflowId",
        "variableName": "itemProgressWorkflowId"
      }
    ],
    "concreteType": "MetricsItemProgress",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "itemNumber",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "quantity",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "quantityCompleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ItemProgressPanelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ItemProgressPanelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ItemProgressPanelQuery",
    "id": null,
    "text": "query ItemProgressPanelQuery(\n  $itemProgressStartAt: DateTime!\n  $itemProgressEndAt: DateTime!\n  $itemProgressWorkflowId: ID\n) {\n  metricsItemProgress(startAt: $itemProgressStartAt, endAt: $itemProgressEndAt, workflowId: $itemProgressWorkflowId) {\n    id\n    itemNumber\n    name\n    quantity\n    quantityCompleted\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a22cbb81d4e59a484a17441797f6211';
module.exports = node;

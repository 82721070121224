// @flow

import { i18n } from 'shared/utils';
import type { FilterOption } from 'main-app/components/FilterControls';

export default (): Array<FilterOption> => [
  {
    label: i18n.t('Order #'),
    name: 'orderNumbers',
    type: 'text',
    multi: true,
  },
  {
    label: i18n.t('Customer PO #'),
    name: 'customerOrderNumbers',
    type: 'text',
    multi: true,
  },
  {
    label: i18n.t('Customer'),
    name: 'customerIds',
    type: 'customer',
    multi: true,
  },
  {
    label: i18n.t('Salesperson'),
    name: 'salesUserIds',
    type: 'user',
    multi: true,
  },
  {
    label: i18n.t('Status'),
    name: 'statuses',
    type: 'select',
    multi: true,
    selectOptions: [
      { label: i18n.t('Open'), value: 'OPEN' },
      { label: i18n.t('Closed'), value: 'CLOSED' },
    ],
  },
  {
    label: i18n.t('Date Ordered'),
    name: 'orderedAt',
    type: 'date-range',
  },
  {
    label: i18n.t('Date Required'),
    name: 'requiredAt',
    type: 'date-range',
  },
  {
    label: i18n.t('Date Promised'),
    name: 'promisedAt',
    type: 'date-range',
  },
];

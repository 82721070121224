/**
 * @flow
 * @relayHash 35ad9f9216dfd57c8424750a4aaf689d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ItemCardQueryVariables = {|
  itemId: string
|};
export type ItemCardQueryResponse = {|
  +item: {|
    +id: string,
    +description: ?string,
    +name: string,
    +partNumber: ?string,
    +upc: ?string,
    +category: ?{|
      +id: string,
      +name: string,
    |},
    +quantityUOM: {|
      +id: string,
      +name: string,
      +symbol: string,
    |},
  |}
|};
export type ItemCardQuery = {|
  variables: ItemCardQueryVariables,
  response: ItemCardQueryResponse,
|};
*/


/*
query ItemCardQuery(
  $itemId: ID!
) {
  item(id: $itemId) {
    id
    description
    name
    partNumber
    upc
    category {
      id
      name
    }
    quantityUOM {
      id
      name
      symbol
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "itemId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "item",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "itemId"
      }
    ],
    "concreteType": "Item",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "partNumber",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "upc",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": null,
        "concreteType": "ItemCategory",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "quantityUOM",
        "storageKey": null,
        "args": null,
        "concreteType": "UOM",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "symbol",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ItemCardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ItemCardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ItemCardQuery",
    "id": null,
    "text": "query ItemCardQuery(\n  $itemId: ID!\n) {\n  item(id: $itemId) {\n    id\n    description\n    name\n    partNumber\n    upc\n    category {\n      id\n      name\n    }\n    quantityUOM {\n      id\n      name\n      symbol\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d3457fd5beb6b832fc46d257dbe1dc2';
module.exports = node;

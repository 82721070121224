// @flow

// TODO: integrate centralized logging
class Log {
  static info(...args: *) {
    console.log(...args); // eslint-disable-line
  }

  static warn(...args: *) {
    console.warn(...args); // eslint-disable-line
  }

  static error(...args: *) {
    console.error(...args); // eslint-disable-line
  }
}

export default Log;

// @flow

import React from 'react';
import type { Match } from 'react-router-dom';
import Attachments from 'main-app/components/Attachments';

type Props = {
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

export default ({ match }: Props) => <Attachments jobId={match.params.id} />;

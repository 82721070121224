// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n } from 'shared/utils';
import { graphql, createFragmentContainer } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import AddJobLinkedItemMutation from 'main-app/mutations/AddJobLinkedItem';
import UpdateJobLinkedItemMutation from 'main-app/mutations/UpdateJobLinkedItem';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  SelectItemField,
  FieldGroup,
} from 'shared/components/form';
import ItemCard from 'shared/components/common/QueryWidget/ItemCard';
import type { AddUpdateJobLinkedItemModal_job as JobFragment } from './__generated__/AddUpdateJobLinkedItemModal_job';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  job: JobFragment,
  item?: Object,
  excludeIds?: Array<string>,
  jobId: string,
};

const LANGUAGE = {
  update: {
    header: i18n.t('Update Tooling'),
    submitButton: i18n.t('Update Tooling'),
    successMessage: i18n.t('Tooling Successfully Updated'),
  },
  add: {
    header: i18n.t('Link Tooling to Job Ticket'),
    submitButton: i18n.t('Link Tooling'),
    successMessage: i18n.t('Tooling Successfully Linked'),
  },
};

const AddUpdateJobLinkedItemModal = (props: Props) => {
  const { onClose, onSuccess, job, excludeIds, jobId, item } = props;
  const action = job ? 'add' : 'update';
  const language = (() => LANGUAGE[action])();

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          item: item ? item : null,
          usageQty: item ? item.usageQty : '',
        }}
        validationSchema={Yup.object().shape({
          item: Yup.object()
            .nullable()
            .required('Required'),
          usageQty: Yup.number(),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const mutation =
            action === 'add'
              ? AddJobLinkedItemMutation
              : UpdateJobLinkedItemMutation;

          const input: any = {
            itemId: action === 'add' ? values.item.value : item?.id,
            jobId: action === 'add' ? job.id : jobId,
            usageQty: parseFloat(values.usageQty || 0),
          };

          try {
            const response = await mutation.commit({
              variables: {
                input,
              },
            });

            const newJobLinkedItem =
              action === 'add'
                ? response.addJobLinkedItem.jobEdge.node
                : response.updateJobLinkedItem.jobEdge.node;

            onClose();

            if (onSuccess) {
              onSuccess(newJobLinkedItem);
            }

            Actions.alertNotification(
              {
                title: language.successMessage,
                body: null,
              },
              'success',
            );
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form>
              <ModalHeader header={language.header} onClose={onClose} />
              <ModalBody withPadding>
                {action === 'add' ? (
                  <>
                    <SelectItemField
                      name="item"
                      label={i18n.t('Search For Item')}
                      error={errors.item}
                      excludeIds={excludeIds}
                    />
                    {values.item && <ItemCard itemId={values.item.value} />}
                  </>
                ) : (
                  <FieldGroup
                    label={i18n.t('Item Name')}
                    name="item"
                    value={item?.name}
                    disabled
                  />
                )}
                <FieldGroup
                  label={i18n.t('Estimated Usage')}
                  name="usageQty"
                  type="number"
                  placeholder={i18n.t('Enter the estimated usage')}
                  extendedLabel={values.item?.data?.quantityUOM?.symbol}
                  error={errors.usageQty}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {language.submitButton}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

AddUpdateJobLinkedItemModal.defaultProps = {
  onSuccess: undefined,
  excludeIds: [],
  item: null,
};

export default createFragmentContainer(AddUpdateJobLinkedItemModal, {
  job: graphql`
    fragment AddUpdateJobLinkedItemModal_job on Job {
      id
      linkedItems(first: null)
        @connection(key: "Job_linkedItems", filters: []) {
        edges {
          usageQty
          node {
            id
            name
          }
        }
      }
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import type { RouterHistory } from 'react-router';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import type { ItemLotsTable_itemLotEdges as ItemLotEdgesFragment } from './__generated__/ItemLotsTable_itemLotEdges';

type Props = {
  history: RouterHistory,
  itemLotEdges: ItemLotEdgesFragment,
  emptyListMessage?: string,
  UOM?: string,
};

class ItemLotsTable extends PureComponent<Props> {
  static defaultProps = {
    emptyListMessage: null,
    UOM: 'Quantity',
  };

  menuTriggerRefs = {};

  getLotById = (lotId: string) => {
    const { itemLotEdges } = this.props;
    const lot = itemLotEdges.find(edge => {
      return edge?.node?.id === lotId;
    });

    return lot?.node || null;
  };

  handleOpenNewTab = (e, data, target) => {
    const lotId = target.getAttribute('lot-id');

    window.open(`/lot/${lotId}`);
  };

  render() {
    const { history, itemLotEdges, emptyListMessage, UOM } = this.props;

    const edges = itemLotEdges
      ? itemLotEdges.filter(Boolean).map(lot => ({
          id: lot.node.id,
          lotNumber: lot.node.lotNumber,
          binNumber: lot.node?.binNumber,
          expiresAt: lot.node?.expiresAt,
          quantity: lot.quantity,
        }))
      : [];

    if (!edges.length) {
      return (
        <EmptyListResults
          graphic="itemLots"
          message={
            emptyListMessage ||
            i18n.t(
              'Lots containing this item will display here for reference. Create Item Movements to add, remove, or transfer between lots.',
            )
          }
        />
      );
    }

    return (
      <>
        <Table
          data={edges}
          onRowClick={rowData => history.push(`/lot/${rowData.id}`)}
          columns={[
            {
              Header: i18n.t('Lot #'),
              accessor: 'lotNumber',
              width: 200,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value, row }: *) => <Pill>{value}</Pill>,
            },
            {
              Header: i18n.t('Quantity'),
              accessor: 'quantity',
              Cell: ({ value }) =>
                `${i18n.t('{{quantity, number}}', { quantity: value }) ||
                  0} ${UOM || ''}` || '',
            },
            {
              Header: i18n.t('Bin Number'),
              accessor: 'binNumber',
              Cell: ({ value }) => value || '',
            },
            {
              Header: i18n.t('Expiration Date'),
              accessor: 'expiresAt',
              Cell: ({ value }) =>
                value ? moment(value).format('MM/DD/YYYY') : '',
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              Cell: ({ value }: *) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="lots-menu"
                  attributes={{
                    'lot-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={(e, data, target) => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(
                          e,
                          data,
                          target,
                        );
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ]}
        />
        <ContextMenu id="lots-menu">
          <MenuItem onClick={this.handleOpenNewTab}>
            {i18n.t('Open In New Tab')}
          </MenuItem>
        </ContextMenu>
      </>
    );
  }
}

export default createFragmentContainer(ItemLotsTable, {
  itemLotEdges: graphql`
    fragment ItemLotsTable_itemLotEdges on ItemLotEdge @relay(plural: true) {
      quantity
      node {
        id
        lotNumber
        binNumber
        quantity
        expiresAt
      }
    }
  `,
});

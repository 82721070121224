// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      name: string,
      type: string,
      filter: Object,
    },
  },
};

const mutation = graphql`
  mutation AddUserSavedFilterMutation($input: AddUserSavedFilterInput!) {
    addUserSavedFilter(input: $input) {
      userSavedFilterEdge {
        node {
          id
          name
          type
          filter
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: ROOT_ID,
          connectionInfo: [
            {
              key: 'FilterControls_userSavedFilters',
              rangeBehavior: 'append',
              // filters: {
              //   type: options.variables.type,
              // },
            },
          ],
          edgeName: 'userSavedFilterEdge',
        },
      ],
    });
  },
};

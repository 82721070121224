// @flow

import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import Panel from 'shared/components/common/Panel';
import OrdersTable from 'shared/components/common/OrdersTable';
import type { RouterHistory } from 'react-router-dom';
import type { OrdersDue_orderEdges as OrderEdgesFragment } from './__generated__/OrdersDue_orderEdges';

type Props = {
  history: RouterHistory,
  orderEdges: OrderEdgesFragment,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${p => (p.hasEdges ? '250px' : 'auto')};
  overflow: auto;
`;

const OrdersDue = ({ history, orderEdges }: Props) => {
  const totalEdges = orderEdges?.length;

  return (
    <Panel
      title={i18n.t('{{totalOrdersDue}} Order(s) Due Soon', {
        totalOrdersDue: totalEdges || 0,
      })}
      headerStyle={{
        border: 'none',
        marginBottom: 0,
      }}
    >
      <Wrapper hasEdges={Boolean(totalEdges)}>
        <OrdersTable
          history={history}
          orderEdges={orderEdges}
          emptyListMessage={i18n.t(
            'Orders due soon (within 30 days) will show here.',
          )}
        />
      </Wrapper>
    </Panel>
  );
};

export default createFragmentContainer(OrdersDue, {
  orderEdges: graphql`
    fragment OrdersDue_orderEdges on OrderEdge @relay(plural: true) {
      ...OrdersTable_orderEdges
    }
  `,
});

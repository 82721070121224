// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import type { RunYieldsList_runYieldEdges as RunYieldEdges } from './__generated__/RunYieldsList_runYieldEdges';

type Props = {
  runYieldEdges: RunYieldEdges,
};

const Wrapper = styled.div`
  border-top: 2px solid ${colors.paleGrey};
  padding-top: 16px;

  ul {
    padding: 0;
    margin: 0;

    li {
      ${fonts.smallRegular}
    }
  }
`;

const Title = styled.div`
  ${fonts.bodyBold};
  color: ${colors.charcoalGrey};
  padding-bottom: 16px;
`;

const ItemTitle = styled.div`
  ${fonts.bodyBold};
  color: ${colors.charcoalGrey};
  padding-bottom: 8px;
`;

const ItemInfo = styled.div`
  color: ${colors.battleshipGrey};
  padding-bottom: 8px;
  padding-left: 8px;
`;

const List = styled.ul`
  list-style: none;
`;

const RunYieldsList = ({ runYieldEdges }: Props) => {
  return runYieldEdges && runYieldEdges.length ? (
    <Wrapper>
      <Title>{i18n.t('Item(s)')}</Title>
      <List>
        {runYieldEdges.map(
          edge =>
            edge &&
            edge.node && (
              <li key={edge.node.id}>
                <ItemTitle>{edge.node.item.name}</ItemTitle>
                <ItemInfo>
                  {edge.totalItemQuantity
                    ? i18n.t(
                        '{{totalItemQuantity, number}} {{uomSymbol, string}}',
                        {
                          totalItemQuantity: edge.totalItemQuantity,
                          uomSymbol: edge.node.item.quantityUOM.symbol,
                        },
                      )
                    : null}
                </ItemInfo>
              </li>
            ),
        )}
      </List>
    </Wrapper>
  ) : null;
};

export default createFragmentContainer(RunYieldsList, {
  runYieldEdges: graphql`
    fragment RunYieldsList_runYieldEdges on RunYieldEdge @relay(plural: true) {
      totalItemQuantity
      node {
        id
        item {
          id
          name
          quantityUOM {
            id
            symbol
          }
        }
      }
    }
  `,
});

/**
 * @flow
 * @relayHash 82ea610d04b57f981a4e8610972a4234
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UOMType = "LENGTH" | "QUANTITY" | "WEIGHT";
export type UpdateItemChildInput = {|
  parentItemId: string,
  childItemId: string,
  usageQty: number,
|};
export type UpdateItemChildMutationVariables = {|
  input: UpdateItemChildInput
|};
export type UpdateItemChildMutationResponse = {|
  +updateItemChild: {|
    +itemEdge: {|
      +node: {|
        +id: string,
        +itemNumber: string,
        +name: string,
        +upc: ?string,
        +quantityUOM: {|
          +id: string,
          +name: string,
          +type: UOMType,
          +symbol: string,
        |},
        +childItems: {|
          +edges: ?$ReadOnlyArray<?{|
            +usageQty: ?number,
            +node: {|
              +id: string,
              +name: string,
            |},
          |}>
        |},
        +formFieldValues: any,
      |}
    |}
  |}
|};
export type UpdateItemChildMutation = {|
  variables: UpdateItemChildMutationVariables,
  response: UpdateItemChildMutationResponse,
|};
*/


/*
mutation UpdateItemChildMutation(
  $input: UpdateItemChildInput!
) {
  updateItemChild(input: $input) {
    itemEdge {
      node {
        id
        itemNumber
        name
        upc
        quantityUOM {
          id
          name
          type
          symbol
        }
        childItems {
          edges {
            usageQty
            node {
              id
              name
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        formFieldValues
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateItemChildInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upc",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "quantityUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "type",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "symbol",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "ItemChildEdge",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "usageQty",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Item",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "formFieldValues",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateItemChildMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateItemChild",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateItemChildPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "itemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Item",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": "childItems",
                    "name": "__Item_childItems_connection",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItemChildConnection",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  },
                  (v8/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateItemChildMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateItemChild",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateItemChildPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "itemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Item",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "childItems",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItemChildConnection",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  },
                  {
                    "kind": "LinkedHandle",
                    "alias": null,
                    "name": "childItems",
                    "args": null,
                    "handle": "connection",
                    "key": "Item_childItems",
                    "filters": []
                  },
                  (v8/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateItemChildMutation",
    "id": null,
    "text": "mutation UpdateItemChildMutation(\n  $input: UpdateItemChildInput!\n) {\n  updateItemChild(input: $input) {\n    itemEdge {\n      node {\n        id\n        itemNumber\n        name\n        upc\n        quantityUOM {\n          id\n          name\n          type\n          symbol\n        }\n        childItems {\n          edges {\n            usageQty\n            node {\n              id\n              name\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        formFieldValues\n      }\n    }\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "updateItemChild",
            "itemEdge",
            "node",
            "childItems"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd86d040cab31e4812fc019e9fa937f89';
module.exports = node;

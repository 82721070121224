/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type TrackRunQuantityType = "ALL" | "NONE" | "PARTIAL";
export type WorkflowStateType = "JOB_COMPLETED" | "JOB_CREATED" | "JOB_ITEM_COUNT" | "RUN" | "TASK";
export type WorkflowTransitionActionType = "ADD_JOB_TAG" | "ADD_NOTE" | "REMOVE_JOB_TAG" | "SEND_API_REQUEST" | "SEND_EMAIL";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Diagram_workflow$ref: FragmentReference;
declare export opaque type Diagram_workflow$fragmentType: Diagram_workflow$ref;
export type Diagram_workflow = {|
  +id: string,
  +name: string,
  +isDefault: boolean,
  +states: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +type: ?WorkflowStateType,
    +form: ?{|
      +id: string,
      +name: string,
    |},
    +machineType: ?{|
      +id: string,
      +name: string,
    |},
    +runQuantityPerHour: ?number,
    +runMinutesOverride: ?number,
    +postRunDelayMinutes: ?number,
    +isRequired: ?boolean,
    +isGangRun: ?boolean,
    +trackRunQuantityType: ?TrackRunQuantityType,
    +isTrackingSetupQuantity: ?boolean,
    +isPromptingFinalQuantity: ?boolean,
    +isAssignable: ?boolean,
    +defaultLot: ?{|
      +id: string,
      +lotNumber: string,
    |},
    +defaultUser: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
    |},
  |}>,
  +transitions: $ReadOnlyArray<{|
    +id: string,
    +toWorkflowState: {|
      +id: string
    |},
    +fromWorkflowState: {|
      +id: string
    |},
    +actions: $ReadOnlyArray<{|
      +id: string,
      +type: WorkflowTransitionActionType,
      +metadata: any,
    |}>,
  |}>,
  +$refType: Diagram_workflow$ref,
|};
export type Diagram_workflow$data = Diagram_workflow;
export type Diagram_workflow$key = {
  +$data?: Diagram_workflow$data,
  +$fragmentRefs: Diagram_workflow$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v4 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "Diagram_workflow",
  "type": "Workflow",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDefault",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "states",
      "storageKey": null,
      "args": null,
      "concreteType": "WorkflowState",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "form",
          "storageKey": null,
          "args": null,
          "concreteType": "Form",
          "plural": false,
          "selections": (v3/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "machineType",
          "storageKey": null,
          "args": null,
          "concreteType": "MachineType",
          "plural": false,
          "selections": (v3/*: any*/)
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "runQuantityPerHour",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "runMinutesOverride",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "postRunDelayMinutes",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isRequired",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isGangRun",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "trackRunQuantityType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isTrackingSetupQuantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isPromptingFinalQuantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isAssignable",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "defaultLot",
          "storageKey": null,
          "args": null,
          "concreteType": "Lot",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lotNumber",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "defaultUser",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "transitions",
      "storageKey": null,
      "args": null,
      "concreteType": "WorkflowTransition",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "toWorkflowState",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": false,
          "selections": (v4/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "fromWorkflowState",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": false,
          "selections": (v4/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "actions",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowTransitionAction",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "metadata",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '041d96a4a80c798147224d0eea3cc938';
module.exports = node;

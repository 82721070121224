// @flow

import React, { PureComponent } from 'react';
import Icon from 'shared/components/common/Icon';
import { fonts, colors } from 'shared/styleguide';
import styled, { css } from 'styled-components';
import { i18n } from 'shared/utils';
import { Toggle } from 'shared/components/form';
import Asterisk from 'shared/components/common/Asterisk';
import UpdateJobStateMutation from 'shared/mutations/UpdateJobState';
import * as Actions from 'main-app/store/Actions';
import DeleteRunsModal from 'main-app/components/DeleteRunsModal';
import IconNodeModel from './IconNodeModel';
import IconPortWidget from './IconPortWidget';
// import RunStates from './RunStates';

type Props = {
  baseClass?: string,
  className?: string,
  extraProps?: any,
  icon: string,
  size?: number,
  node: IconNodeModel,
  name: string,
  userLabel?: string,
  formLabel?: string,
  runMinutesOverride?: string,
  isRequired?: boolean,
  status: string,
  leftPortComplete?: boolean,
  rightPortComplete?: boolean,
  isEnabled: boolean,
  jobStateId: string,
  type: string,
};

type State = {
  openDeleteRunsModal: boolean,
};

const ToggleWrapper = styled.div`
  position: absolute;
  top: -70px;
  width: 100px;
`;

const ToggleLabel = styled.div`
  margin-top: 4px;
  ${fonts.smallRegular};
  ${colors.charcoalGrey};
`;

const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 4px;
  ${fonts.bodyBold};
  ${colors.charcoalGrey};
  word-break: break-word;
`;

const SubLabel = styled.div`
  margin-top: 4px;
  ${fonts.smallRegular};
  ${colors.battleshipGrey};
  display: none;
`;

const IconLabels = styled.div`
  position: absolute;
  width: 200px;
  top: 60px;
`;

const IconNodeDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
`;

const IconWidgetWrapper = styled.div`
  width: ${p => p.size};
  height: ${p => p.size};

  &:hover {
    ${SubLabel} {
      display: block;
    }
  }
`;

const IconWrapper = styled.div`
  position: relative;

  svg {
    filter: ${p =>
      p.status === 'IN_PROGRESS'
        ? `drop-shadow(0 2px 4px ${colors.coolGrey})`
        : 'none'};

    rect {
      fill: ${p =>
        p.status !== 'INCOMPLETE' ? p.color : colors.battleshipGrey};
      fill-opacity: ${p => (p.status === 'COMPLETE' ? 1 : 0.5)};
    }

    &:hover {
      cursor: pointer;

      rect {
        fill: ${p => p.color};
      }
    }
  }

  ${p =>
    p.status === 'IN_PROGRESS' &&
    css`
      path {
        animation-name: rotation;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: 50% 50%;
      }

      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `}

  .srd-port {
    background: ${colors.lightBlueGrey};
  }

  ${p =>
    p.leftPortComplete &&
    css`
      .srd-port:first-of-type {
        background: ${colors.charcoalGrey};
      }
    `}
  ${p =>
    p.rightPortComplete &&
    css`
      .srd-port:last-of-type {
        background: ${colors.charcoalGrey};
      }
    `}


  .port.srd-port--selected,
  .port.srd-port:hover {
    cursor: pointer;
    background: rgb(0, 192, 255);
    box-shadow: 0 0 4px 4px rgb(0, 192, 255, 0.5);
  }
`;

class IconWidget extends PureComponent<Props, State> {
  className: string;

  static defaultProps = {
    size: 56,
    userLabel: '',
    formLabel: '',
    runMinutesOverride: '',
    baseClass: '',
    className: '',
    extraProps: null,
    isRequired: true,
    leftPortComplete: false,
    rightPortComplete: false,
  };

  constructor(props: Props) {
    super(props);
    this.className = props.icon;
    this.state = {
      openDeleteRunsModal: false,
    };
  }

  getClassName() {
    const { baseClass, className } = this.props;

    return (
      (baseClass || this.className) + ' ' + (className ? className + ' ' : '')
    );
  }

  getProps() {
    const { extraProps } = this.props;
    return {
      ...((extraProps: any) || {}),
      className: this.getClassName(),
    };
  }

  onChange = (value: boolean) => {
    const { node } = this.props;
    node.data.isEnabled = value;
  };

  handleOpenDeleteRunsModal = () => {
    this.setState({
      openDeleteRunsModal: true,
    });
  };

  handleCloseDeleteRunsModal = () => {
    this.setState({
      openDeleteRunsModal: false,
    });
  };

  handleDeleteRuns = async (value: boolean) => {
    const { jobStateId } = this.props;
    this.onChange(value);

    try {
      await UpdateJobStateMutation.commit({
        variables: {
          input: {
            id: jobStateId,
            isEnabled: value,
          },
        },
      });

      this.setState({
        openDeleteRunsModal: false,
      });
    } catch (e) {
      Actions.alertNotification(e.message, 'error');
    }
  };

  bem(selector: string) {
    const { baseClass } = this.props;
    return (baseClass || this.className) + selector + ' ';
  }

  render() {
    const {
      icon,
      size,
      name,
      runMinutesOverride,
      formLabel,
      userLabel,
      node,
      isRequired,
      status,
      leftPortComplete,
      rightPortComplete,
      isEnabled,
      jobStateId,
      type,
    } = this.props;
    const { openDeleteRunsModal } = this.state;
    const displayLeftPort = icon !== 'square-job-created';
    const displayRightPort = icon !== 'square-job-completed';
    let color;
    let primaryLabel;

    if (node.data.type === 'JOB_CREATED') {
      color = '#00BD83';
      primaryLabel = 'Job Created';
    } else if (node.data.type === 'JOB_COMPLETED') {
      color = '#D46147';
      primaryLabel = 'Job Completed';
    } else if (node.data.type === 'TASK') {
      color = '#EFC036';
      primaryLabel = name || i18n.t('Untitled Task');
    } else if (node.data.type === 'RUN') {
      color = '#3050D5';
      primaryLabel = name || i18n.t('Untitled Run');
    }

    return (
      <IconWidgetWrapper>
        <IconNodeDetails>
          {status !== 'COMPLETE' &&
            status !== 'IN_PROGRESS' &&
            type !== 'JOB_COMPLETED' &&
            type !== 'JOB_CREATED' && (
              <ToggleWrapper>
                <Toggle
                  value={isEnabled}
                  onChange={async newValue => {
                    if (!newValue && type === 'RUN') {
                      this.handleOpenDeleteRunsModal();
                      return;
                    }

                    this.onChange(newValue);

                    try {
                      await UpdateJobStateMutation.commit({
                        variables: {
                          input: {
                            id: jobStateId,
                            isEnabled: newValue,
                          },
                        },
                      });
                    } catch (e) {
                      Actions.alertNotification(e.message, 'error');
                    }
                  }}
                />
                <ToggleLabel>
                  {i18n.t(isEnabled ? 'Enabled' : 'Disabled')}
                </ToggleLabel>
              </ToggleWrapper>
            )}
          <IconWrapper
            color={color}
            status={status}
            leftPortComplete={leftPortComplete}
            rightPortComplete={rightPortComplete}
          >
            <Icon type={icon} size={size} />
            {displayLeftPort && (
              <IconPortWidget
                name="left"
                node={node}
                style={{
                  position: 'absolute',
                  top: (size || 56) / 2 - 4,
                  left: -16,
                  borderRadius: '50%',
                  height: '8px',
                  width: '8px',
                }}
              />
            )}
            {displayRightPort && (
              <IconPortWidget
                name="right"
                node={node}
                style={{
                  position: 'absolute',
                  top: (size || 56) / 2 - 4,
                  left: size + 8,
                  borderRadius: '50%',
                  height: '8px',
                  width: '8px',
                }}
              />
            )}
          </IconWrapper>
          <IconLabels>
            <Label>
              {primaryLabel}
              {isRequired &&
                node.data.type !== 'JOB_CREATED' &&
                node.data.type !== 'JOB_COMPLETED' && <Asterisk>*</Asterisk>}
            </Label>
            {Boolean(formLabel) && (
              <SubLabel>
                {formLabel} {i18n.t('Form')}
              </SubLabel>
            )}
            {Boolean(userLabel) && (
              <SubLabel>
                {i18n.t('Assign to')} {userLabel}
              </SubLabel>
            )}
            {Boolean(runMinutesOverride) && (
              <SubLabel>
                {runMinutesOverride} {i18n.t('min duration')}
              </SubLabel>
            )}
            {/* TODO: add this when Run statuses are ready to be displayed in Job Details */}
            {/* {node.data.type === 'RUN' && (
              <RunStates scheduledRuns={node.data.scheduledRuns || []} />
            )} */}
          </IconLabels>
        </IconNodeDetails>
        {openDeleteRunsModal && type === 'RUN' && (
          <DeleteRunsModal
            onDelete={() => {
              this.handleDeleteRuns(!isEnabled);
            }}
            onClose={this.handleCloseDeleteRunsModal}
          />
        )}
      </IconWidgetWrapper>
    );
  }
}

export default IconWidget;

// @flow

import React from 'react';
import {
  Wrapper,
  Label,
  CloseIcon,
  SaveIcon,
  FilterIcon,
  ResetIcon,
} from './styled';

const IconMap = {
  save: SaveIcon,
  filter: FilterIcon,
  reset: ResetIcon,
};

type Props = {
  children: React$Node,
  onClick?: Function,
  onClose?: Function,
  icon?: $Keys<typeof IconMap>,
  theme?:
    | 'grey'
    | 'light-grey'
    | 'dark-grey'
    | 'blue'
    | 'blue-filled'
    | 'red'
    | 'border-white',
  width?: number | string,
  disabled?: boolean,
  getRef?: (*) => *,
};

const AppliedTag = ({
  theme,
  onClick,
  icon,
  width,
  disabled,
  getRef,
  children,
  onClose,
}: Props) => {
  const closeable = Boolean(onClose);
  const Icon = icon ? IconMap[icon] : null;

  return (
    <Wrapper
      theme={theme}
      onClick={onClick}
      width={width}
      disabled={disabled}
      ref={getRef}
      closeable={closeable}
    >
      <Label closeable={closeable}>
        {Icon && <Icon />}
        {children}
      </Label>
      {onClose ? (
        // eslint-disable-next-line react/forbid-component-props
        <CloseIcon className="close-icon" theme={theme} onClick={onClose} />
      ) : null}
    </Wrapper>
  );
};

AppliedTag.defaultProps = {
  onClick: undefined,
  onClose: undefined,
  icon: undefined,
  theme: 'grey',
  width: undefined,
  disabled: false,
  getRef: () => {},
};

export default AppliedTag;

// @flow

import React from 'react';
import moment from 'moment';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import SidePanel from 'shared/components/common/SidePanel';
import PanelSection from 'shared/components/common/SidePanel/PanelSection';
import UserWidget from 'shared/components/common/QueryWidget/UserWidget';
import Divider from 'shared/components/common/Divider';
import MultilineText from 'shared/components/common/MultilineText';
import type { OrderSidePanel_order as OrderFragment } from './__generated__/OrderSidePanel_order';

type Props = {
  order: OrderFragment,
  customer: Object,
};

const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
  font-weight: ${fonts.bodyBold};
  height: 30px;
`;

const AssigneeDivider = styled.div`
  border-bottom: 2px solid ${colors.paleGrey};
  padding-top: 24px;
`;

const OrderSidePanel = ({ order, customer }: Props) => (
  <>
    <SidePanel>
      {order.user && (
        <UserWidget user={order.user} title={i18n.t('Assignee')} />
      )}
      <AssigneeDivider />
      <PanelSection
        title="Order Info"
        fields={[
          {
            label: i18n.t('Customer'),
            value: (
              <LinkWrapper>
                <Link to={`/customer/${customer.id}`}>{customer.name}</Link>
              </LinkWrapper>
            ),
          },
          {
            label: i18n.t('Customer PO #'),
            value: order.customerOrderNumber || '',
          },
          {
            label: i18n.t('Date Ordered'),
            value: order.orderedAt
              ? moment(order.orderedAt).format('MM/DD/YYYY')
              : null,
          },
          {
            label: i18n.t('Date Promised'),
            value: order.promisedAt
              ? moment(order.promisedAt).format('MM/DD/YYYY')
              : null,
          },
          {
            label: i18n.t('Date Required'),
            value: order.requiredAt
              ? moment(order.requiredAt).format('MM/DD/YYYY')
              : null,
          },
          {
            label: i18n.t('Notes'),
            value: <MultilineText>{order.notes}</MultilineText>,
          },
        ]}
      />
      <Divider />
      <PanelSection
        title="Address"
        fields={[
          {
            label: i18n.t('Shipping Address'),
            value: (
              <div>
                {order.shippingAddressLine1}
                {order.shippingAddressLine2 && (
                  <div>{order.shippingAddressLine2}</div>
                )}
                {order.shippingAddressLine3 && (
                  <div>{order.shippingAddressLine3}</div>
                )}
                <div>
                  {order.shippingAddressCity} {order.shippingAddressState}{' '}
                  {order.shippingAddressZip}
                </div>
              </div>
            ),
          },
        ]}
      />
    </SidePanel>
  </>
);

export default createFragmentContainer(OrderSidePanel, {
  order: graphql`
    fragment OrderSidePanel_order on Order {
      id
      customerOrderNumber
      shippingAddressLine1
      shippingAddressLine2
      shippingAddressLine3
      shippingAddressCity
      shippingAddressState
      shippingAddressZip
      notes
      orderedAt
      promisedAt
      requiredAt
      user {
        ...UserWidget_user
      }
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import { QueryRenderer, graphql } from 'react-relay';
import {
  Route,
  Switch,
  Redirect,
  type Match,
  type RouterHistory,
} from 'react-router-dom';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { i18n } from 'shared/utils';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import PageDetailsSummary from 'main-app/components/PageDetailsSummary';
import Icon from 'shared/components/common/Icon';
import InfoWidget from 'shared/components/common/InfoWidget';
import UserWidget from 'shared/components/common/QueryWidget/UserWidget';
import TabNav from 'shared/components/common/TabNav';
import TabContentWrapper from 'shared/components/common/TabContent/Wrapper';
import MainContent from 'shared/components/common/TabContent/MainContent';
import QueryLoadRender from 'shared/components/common/QueryLoadRender';
import AddUpdateCustomerModal from 'main-app/components/AddUpdateCustomerModal';
import DeleteCustomerModal from 'main-app/components/DeleteCustomerModal';
import CustomerSidePanel from './CustomerSidePanel';
import CustomerOrders from './CustomerOrders';

type Props = {
  history: RouterHistory,
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

type State = {
  updateCustomerModalOpen: boolean,
  deleteCustomerModalOpen: boolean,
};

class Customer extends PureComponent<Props, State> {
  menuTriggerRef = null;

  state = {
    updateCustomerModalOpen: false,
    deleteCustomerModalOpen: false,
  };

  handleOpenUpdateCustomerModal = () => {
    this.setState({
      updateCustomerModalOpen: true,
    });
  };

  handleOpenDeleteCustomerModal = () => {
    this.setState({
      deleteCustomerModalOpen: true,
    });
  };

  handleDeleteSuccess = () => {
    const { history } = this.props;

    history.replace('/customers');
  };

  handleCloseAllModals = () => {
    this.setState({
      updateCustomerModalOpen: false,
      deleteCustomerModalOpen: false,
    });
  };

  render() {
    const { match, history } = this.props;
    const { updateCustomerModalOpen, deleteCustomerModalOpen } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query CustomerQuery($id: ID!, $customerIds: [ID!]!) {
            customer(id: $id) {
              id
              name
              primaryContactName
              phone
              salesUser {
                id
                ...UserWidget_user
              }
              createdAt
              ...CustomerSidePanel_customer
              ...CustomerOrders_customer
              ...AddUpdateCustomerModal_customer
              ...DeleteCustomerModal_customer
            }
            orders(first: null, customerIds: $customerIds)
              @connection(key: "Customer_orders", filters: ["customerIds"]) {
              edges {
                ...CustomerOrders_orderEdges
              }
            }
            viewer {
              id
              permissions
              company {
                id
              }
            }
          }
        `}
        variables={{
          id: match.params.id,
          customerIds: [match.params.id],
        }}
        render={query => (
          <Page>
            <PageHeader
              intro={i18n.t('CUSTOMER')}
              title={
                <QueryLoadRender
                  queryResponse={query}
                  error={() => i18n.t('Customer Not Found')}
                  loading={() => i18n.t('Customer Details Loading...')}
                  render={() => query.props.customer.name}
                />
              }
              right={
                query.props && (
                  <ContextMenuTrigger
                    id="customer-details-menu"
                    ref={r => (this.menuTriggerRef = r)}
                  >
                    <Icon
                      type="circle-context-menu"
                      onClick={e => {
                        if (this.menuTriggerRef) {
                          this.menuTriggerRef.handleContextClick(e);
                        }
                      }}
                    />
                  </ContextMenuTrigger>
                )
              }
            />
            <QueryLoadRender
              queryResponse={query}
              error={error => (
                <div>
                  {i18n.t(
                    `This record you're trying to view can't be displayed.`,
                  )}
                  <br />
                  {i18n.t(
                    `It may have been deleted or you don't have permission to view it right now.`,
                  )}
                </div>
              )}
              render={() => (
                <>
                  <PageDetailsSummary
                    items={[
                      <InfoWidget
                        title={i18n.t('Created')}
                        content={moment(query.props.customer.createdAt).format(
                          'MM/DD/YYYY',
                        )}
                      />,
                      <InfoWidget
                        title={i18n.t('Primary Contact')}
                        content={query.props.customer?.primaryContactName || ''}
                      />,
                      <InfoWidget
                        title={i18n.t('Phone Number')}
                        content={query.props.customer?.phone || ''}
                      />,
                      <InfoWidget
                        title={i18n.t('Salesperson')}
                        content={
                          query.props.customer?.salesUser ? (
                            <UserWidget user={query.props.customer.salesUser} />
                          ) : (
                            ''
                          )
                        }
                      />,
                    ]}
                  />
                  <TabNav
                    history={history}
                    activePath={match.url}
                    tabs={[
                      {
                        label: i18n.t('Customer Details'),
                        path: `/customer/${match.params.id}`,
                      },
                      // TODO: Bring this back
                      // {
                      //   label: i18n.t('Orders'),
                      //   path: `/customer/${match.params.id}/orders`,
                      // },
                    ]}
                  />
                  <TabContentWrapper>
                    <CustomerSidePanel customer={query.props?.customer} />
                    <MainContent>
                      <Switch>
                        <Route
                          exact
                          path="/customer/:id"
                          render={props => (
                            <CustomerOrders
                              {...props}
                              history={history}
                              customer={query.props?.customer}
                              orderEdges={query.props?.orders?.edges}
                              companyId={query.props?.viewer.company.id}
                            />
                          )}
                        />
                        <Route
                          exact
                          render={() => (
                            <Redirect to={`/customer/${match.params.id}`} />
                          )}
                        />
                      </Switch>
                    </MainContent>
                  </TabContentWrapper>
                  <ContextMenu id="customer-details-menu">
                    {query.props.viewer.permissions.includes(
                      'UPDATE_CUSTOMER',
                    ) && (
                      <MenuItem onClick={this.handleOpenUpdateCustomerModal}>
                        {i18n.t('Edit Customer')}
                      </MenuItem>
                    )}
                    <MenuItem onClick={this.handleOpenDeleteCustomerModal}>
                      {i18n.t('Delete Customer')}
                    </MenuItem>
                  </ContextMenu>
                  {updateCustomerModalOpen && (
                    <AddUpdateCustomerModal
                      onClose={this.handleCloseAllModals}
                      customer={query.props.customer}
                    />
                  )}
                  {deleteCustomerModalOpen && (
                    <DeleteCustomerModal
                      history={history}
                      customer={query.props.customer}
                      onClose={this.handleCloseAllModals}
                      onSuccess={this.handleDeleteSuccess}
                    />
                  )}
                </>
              )}
            />
          </Page>
        )}
      />
    );
  }
}

export default Customer;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateRunModal_defaultMachine$ref = any;
export type ScheduleEventType = "DOWNTIME" | "RUN_COMPLETE" | "RUN_IN_PROGRESS" | "RUN_PAUSED" | "RUN_SCHEDULED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProductionScheduleChart_data$ref: FragmentReference;
declare export opaque type ProductionScheduleChart_data$fragmentType: ProductionScheduleChart_data$ref;
export type ProductionScheduleChart_data = {|
  +machines: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +type: {|
          +id: string,
          +name: string,
        |},
        +currentDowntime: ?{|
          +id: string
        |},
        +workflows: $ReadOnlyArray<{|
          +id: string
        |}>,
        +$fragmentRefs: AddUpdateRunModal_defaultMachine$ref,
      |}
    |}>
  |},
  +scheduleEvents: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +runId: ?string,
        +machineId: ?string,
        +jobId: ?string,
        +name: string,
        +type: ScheduleEventType,
        +startAt: any,
        +endAt: any,
      |}
    |}>
  |},
  +$refType: ProductionScheduleChart_data$ref,
|};
export type ProductionScheduleChart_data$data = ProductionScheduleChart_data;
export type ProductionScheduleChart_data$key = {
  +$data?: ProductionScheduleChart_data$data,
  +$fragmentRefs: ProductionScheduleChart_data$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "ProductionScheduleChart_data",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "scheduleEvents"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "machineTypeId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "workflowIds",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "startAt",
      "type": "DateTime!",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "endAt",
      "type": "DateTime!",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "machines",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "machineTypeId",
          "variableName": "machineTypeId"
        },
        {
          "kind": "Literal",
          "name": "sortBy",
          "value": {
            "direction": "ASC",
            "field": "PRODUCTION_ORDER"
          }
        },
        {
          "kind": "Variable",
          "name": "workflowIds",
          "variableName": "workflowIds"
        }
      ],
      "concreteType": "MachineConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "MachineEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Machine",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "type",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MachineType",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "currentDowntime",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MachineDowntime",
                  "plural": false,
                  "selections": (v2/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "workflows",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Workflow",
                  "plural": true,
                  "selections": (v2/*: any*/)
                },
                {
                  "kind": "FragmentSpread",
                  "name": "AddUpdateRunModal_defaultMachine",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "scheduleEvents",
      "name": "__ProductionScheduleChart_scheduleEvents_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "ScheduleEventConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ScheduleEventEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ScheduleEvent",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "runId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "machineId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "jobId",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "startAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "endAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '043e964bd355aec45cc782c461bd3620';
module.exports = node;

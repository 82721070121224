// @flow

import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SlideInPanel from 'shared/components/common/SlideInPanel';
import Icon from 'shared/components/common/Icon';
import Loader from 'shared/components/common/Loader';
import styled from 'styled-components';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { colors, fonts } from 'shared/styleguide';
import { i18n, Analytics } from 'shared/utils';
import AddUpdateRunModal from 'main-app/components/AddUpdateRunModal';
import DeleteRunModal from 'main-app/components/DeleteRunModal';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Divider from 'shared/components/common/Divider';
import RunYields from 'shared/components/common/RunYields';
import RunLogs from 'shared/components/common/RunLogs';
import RunStatusPill from 'shared/components/common/Pill/RunStatusPill';
import OperatorControls from './OperatorControls';
import CollapsiblePanel from './CollapsiblePanel';
import RunYieldCard from './RunYieldCard';

type Props = {
  runId: ?string,
  visible: boolean,
  onClickAway: () => void,
  onRunChanged: () => void,
};

type State = {
  updateRunRecord: ?Object,
  deleteRunRecord: ?Object,
};

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-top: 24px;
  position: sticky;
  top: 0;
  background-color: ${colors.white};
`;

const Title = styled.div`
  ${fonts.subtitle};
  color: ${colors.charcoalGrey};
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  ${fonts.bodyBold}
  margin-bottom: 16px;

  span {
    margin-left: 8px;
    margin-right: 24px;
  }
`;

class RunDetailsPanel extends PureComponent<Props, State> {
  state = {
    updateRunRecord: null,
    deleteRunRecord: null,
  };
  slideInPanelRef = null;

  componentDidMount() {
    Analytics.trackEvent('View Run Detail');
  }

  handleOpenUpdateModal = (run: Object) => {
    this.setState({
      updateRunRecord: run,
    });
  };

  handleOpenDeleteModal = (run: Object) => {
    this.setState({
      deleteRunRecord: run,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateRunRecord: null,
      deleteRunRecord: null,
    });
  };

  handleRunChangedAndClose = () => {
    const { onRunChanged, onClickAway } = this.props;

    onRunChanged();
    onClickAway();
  };

  render() {
    const { updateRunRecord, deleteRunRecord } = this.state;
    const { visible, onClickAway, onRunChanged, runId } = this.props;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        variables={{ id: runId }}
        cacheConfig={{ force: true }}
        query={graphql`
          query RunDetailsPanelQuery($id: ID!) {
            viewer {
              id
              company {
                id
                scheduleAllowRunLogEdit
              }
            }

            run(id: $id) {
              id
              scheduledStartAt
              scheduledEndAt
              actualStartAt
              actualEndAt
              status
              notes
              ...OperatorControls_run
              ...RunYields_run
              ...RunLogs_run
              ...AddUpdateRunModal_run
              ...DeleteRunModal_run
              job {
                id
                jobNumber
                name
                ...AddUpdateRunModal_job
                order {
                  id
                  customer {
                    id
                    name
                  }
                }
                user {
                  id
                  firstName
                  lastName
                }
              }
              jobState {
                id
                workflowState {
                  id
                  name
                }
              }
              machine {
                id
                name
              }
              yields(first: null) {
                edges {
                  node {
                    id
                    quantity
                    ...RunYieldCard_runYield
                    item {
                      id
                      name
                      partNumber
                      description
                      quantityUOM {
                        id
                        symbol
                      }
                    }
                  }
                  defectQuantity
                  runQuantity
                  setupQuantity
                }
              }
            }
          }
        `}
        render={({ props }) => {
          const runDetailsKeyValueGridFields = props
            ? [
                {
                  label: i18n.t('Scheduled Start Time'),
                  value: moment(props.run.scheduledStartAt).format(
                    'MM/DD/YYYY h:mm a',
                  ),
                },
                {
                  label: i18n.t('Scheduled End Time'),
                  value: moment(props.run.scheduledEndAt).format(
                    'MM/DD/YYYY h:mm a',
                  ),
                },
                {
                  label: i18n.t('Actual Start Time'),
                  value: props.run.actualStartAt
                    ? moment(props.run.actualStartAt).format(
                        'MM/DD/YYYY h:mm a',
                      )
                    : '',
                },
                {
                  label: i18n.t('Actual End Time'),
                  value: props.run.actualEndAt
                    ? moment(props.run.actualEndAt).format('MM/DD/YYYY h:mm a')
                    : '',
                },
                {
                  label: i18n.t('Notes'),
                  value: props.run.notes || i18n.t('N/A'),
                },
              ]
            : [];

          return (
            <SlideInPanel
              ref={r => (this.slideInPanelRef = r)}
              visible={visible}
              align="right"
            >
              {!props ? (
                <>
                  <Header>
                    <div />
                    <Icon type="circle-close" onClick={onClickAway} />
                  </Header>
                  <Loader />
                </>
              ) : (
                <>
                  <Header>
                    <Title>
                      {props.run.machine.name +
                        i18n.t(' • ') +
                        props.run.jobState.workflowState.name}
                    </Title>
                    <Icon type="circle-close" onClick={onClickAway} />
                  </Header>
                  <IconsWrapper>
                    <Icon
                      type="circle-edit"
                      onClick={() => this.handleOpenUpdateModal(props.run)}
                    />
                    <span>{i18n.t('Edit')}</span>
                    <Icon
                      type="circle-trash"
                      onClick={() => this.handleOpenDeleteModal(props.run)}
                    />
                    <span>{i18n.t('Delete')}</span>
                  </IconsWrapper>
                  <Divider />
                  <CollapsiblePanel title="Run Details">
                    <RunStatusPill status={props.run.status} width="auto" />
                    <KeyValueGrid
                      singleColumn
                      style={{
                        paddingTop: 16,
                        paddingBottom: 24,
                      }}
                      fields={runDetailsKeyValueGridFields}
                    />
                    <RunYields run={props.run} />
                  </CollapsiblePanel>
                  <Divider style={{ marginTop: 16 }} />
                  <CollapsiblePanel title="Job Details">
                    <KeyValueGrid
                      singleColumn
                      style={{
                        paddingTop: 8,
                        paddingBottom: 24,
                      }}
                      fields={[
                        {
                          label: i18n.t('Job Ticket'),
                          value: (
                            <Link to={`/job/${props.run.job.id}`}>
                              {props.run.job.jobNumber}
                            </Link>
                          ),
                        },
                        {
                          label: i18n.t('Customer'),
                          value: props.run.job.order?.customer ? (
                            <Link
                              to={`/customer/${props.run.job.order.customer.id}`}
                            >
                              {props.run.job.order.customer.name}
                            </Link>
                          ) : (
                            i18n.t('N/A')
                          ),
                        },
                        {
                          label: i18n.t('Project Name'),
                          value: props.run.job.name,
                        },
                        {
                          label: i18n.t('Job Owner'),
                          value:
                            props.run.job.user.firstName +
                            i18n.t(' ') +
                            props.run.job.user.lastName,
                        },
                      ]}
                    />
                  </CollapsiblePanel>
                  <Divider />
                  <CollapsiblePanel title="Item(s)">
                    {props.run.yields.edges &&
                      props.run.yields.edges.map(runYield => (
                        <RunYieldCard
                          key={runYield.node.id}
                          runYield={runYield.node}
                        />
                      ))}
                  </CollapsiblePanel>
                  <Divider />
                  <CollapsiblePanel title="Operator Controls">
                    <OperatorControls
                      run={props.run}
                      onRunChanged={onRunChanged}
                    />
                  </CollapsiblePanel>
                  <Divider style={{ marginTop: 24 }} />
                  <CollapsiblePanel title="Run Log">
                    <RunLogs
                      run={props.run}
                      onRunUpdate={onRunChanged}
                      logEditEnabled={
                        props.viewer.company.scheduleAllowRunLogEdit
                      }
                    />
                  </CollapsiblePanel>
                </>
              )}
              {updateRunRecord && (
                <AddUpdateRunModal
                  job={updateRunRecord.job}
                  run={updateRunRecord}
                  defaultMachine={null}
                  onClose={this.handleCloseAllModals}
                  onRunChanged={this.handleRunChangedAndClose}
                  disableSelectJobStateField
                />
              )}
              {deleteRunRecord && (
                <DeleteRunModal
                  jobId={deleteRunRecord.job.id}
                  run={deleteRunRecord}
                  onClose={this.handleCloseAllModals}
                  onSuccess={onRunChanged}
                />
              )}
            </SlideInPanel>
          );
        }}
      />
    );
  }
}

export default RunDetailsPanel;

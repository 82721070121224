// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteDowntimeCategoryMutation from 'main-app/mutations/DeleteDowntimeCategory';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteDowntimeCategoryModal_downtimeCategory as DowntimeCategoryFragment } from './__generated__/DeleteDowntimeCategoryModal_downtimeCategory';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  downtimeCategory: DowntimeCategoryFragment,
};

type State = {
  deleting: boolean,
};

class DeleteDowntimeCategoryModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  static defaultProps = {
    onSuccess: undefined,
  };

  handleDelete = () => {
    const { downtimeCategory, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteDowntimeCategoryMutation.commit({
            variables: {
              input: {
                id: downtimeCategory.id,
              },
            },
            context: {
              parentId: downtimeCategory.parentCategory?.id,
            },
          });

          onClose();

          if (onSuccess) {
            onSuccess();
          }

          Analytics.trackEvent('Delete Downtime Category', {
            parentCategory: downtimeCategory.parentCategory?.name,
          });

          Actions.alertNotification(
            i18n.t(`Downtime Category Successfully Deleted`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(
            i18n.t('Something went wrong. Please try again later.'),
            'error',
          );
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { downtimeCategory, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t(
            'Delete Downtime Category: {{downtimeCategoryName}}?',
            {
              downtimeCategoryName: downtimeCategory.name,
            },
          )}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t('This downtime category will be deleted permanently.')}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Downtime Category')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteDowntimeCategoryModal, {
  downtimeCategory: graphql`
    fragment DeleteDowntimeCategoryModal_downtimeCategory on DowntimeCategory {
      id
      name
      parentCategory {
        id
        name
      }
    }
  `,
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type JobsTable_jobEdges$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderJobTickets_jobEdges$ref: FragmentReference;
declare export opaque type OrderJobTickets_jobEdges$fragmentType: OrderJobTickets_jobEdges$ref;
export type OrderJobTickets_jobEdges = $ReadOnlyArray<{|
  +$fragmentRefs: JobsTable_jobEdges$ref,
  +$refType: OrderJobTickets_jobEdges$ref,
|}>;
export type OrderJobTickets_jobEdges$data = OrderJobTickets_jobEdges;
export type OrderJobTickets_jobEdges$key = {
  +$data?: OrderJobTickets_jobEdges$data,
  +$fragmentRefs: OrderJobTickets_jobEdges$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderJobTickets_jobEdges",
  "type": "JobEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "JobsTable_jobEdges",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e82b652b1ff3dedc673c3e6778f5a853';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import type { RouterHistory } from 'react-router';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddItemMovementModal from 'main-app/components/AddItemMovementModal';
import type { LotItemsTable_lotItemEdges as LotItemEdgesFragment } from './__generated__/LotItemsTable_lotItemEdges';

type Props = {
  history: RouterHistory,
  lotItemEdges: LotItemEdgesFragment,
  emptyListMessage?: string,
};

type State = {
  transferItemRecord: ?Object,
};

class LotItemsTable extends PureComponent<Props, State> {
  static defaultProps = {
    emptyListMessage: null,
  };

  menuTriggerRefs = {};
  state = {
    transferItemRecord: null,
  };

  getItemById = (itemId: string) => {
    const { lotItemEdges } = this.props;

    const item = lotItemEdges.find(edge => {
      return edge?.node?.id === itemId;
    });

    return item?.node || null;
  };

  handleToggleItemMovementModal = (e, data, target) => {
    const itemId = target.getAttribute('item-id');

    this.setState({
      transferItemRecord: this.getItemById(itemId),
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      transferItemRecord: null,
    });
  };

  render() {
    const { history, lotItemEdges, emptyListMessage } = this.props;
    const { transferItemRecord } = this.state;

    const edges = lotItemEdges
      ? lotItemEdges.filter(Boolean).map(a => {
          return {
            ...a.node,
            quantity: a.quantity,
          };
        })
      : [];

    if (!edges.length) {
      return (
        <EmptyListResults
          graphic="itemLots"
          message={
            emptyListMessage ||
            i18n.t(
              `Items in this lot will display here for reference. Create an item movement to add items to this lot.`,
            )
          }
          createNewLabel={i18n.t('Create Item')}
        />
      );
    }

    return (
      <>
        <Table
          data={edges}
          onRowClick={rowData => history.push(`/item/${rowData.id}`)}
          columns={[
            {
              Header: i18n.t('Inventory #'),
              accessor: 'itemNumber',
              width: 120,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value, row }: *) => <Pill>{value}</Pill>,
            },
            {
              Header: i18n.t('Name'),
              accessor: 'name',
            },
            {
              Header: i18n.t('Quantity'),
              accessor: 'quantity',
              Cell: ({ value, row }: *) =>
                i18n.t('{{quantity, number}}', { quantity: value }),
            },
            {
              Header: i18n.t('Category'),
              accessor: 'category.name',
              width: 120,
            },
            {
              Header: i18n.t('Part #'),
              accessor: 'partNumber',
              width: 120,
            },
            {
              Header: i18n.t('UOM'),
              accessor: 'quantityUOM.symbol',
              width: 120,
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              Cell: ({ value }: *) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="items-menu"
                  attributes={{
                    'item-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={(e, data, target) => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(
                          e,
                          data,
                          target,
                        );
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ]}
        />
        <ContextMenu id="items-menu">
          <MenuItem onClick={this.handleToggleItemMovementModal}>
            {i18n.t('Create Item Movement')}
          </MenuItem>
        </ContextMenu>
        {transferItemRecord && (
          <AddItemMovementModal
            onClose={this.handleCloseAllModals}
            onSuccess={item => history.push(`/item/${item.id}`)}
            item={transferItemRecord}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(LotItemsTable, {
  lotItemEdges: graphql`
    fragment LotItemsTable_lotItemEdges on LotItemEdge @relay(plural: true) {
      quantity
      node {
        id
        itemNumber
        name
        partNumber
        category {
          id
          name
        }
        quantityUOM {
          id
          symbol
        }
        ...AddItemMovementModal_item
      }
    }
  `,
});

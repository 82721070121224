// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { i18n, Analytics } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import AddLotMutation from 'shared/mutations/AddLot';
import UpdateLotMutation from 'main-app/mutations/UpdateLot';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
} from 'shared/components/form';
import { computeValidationSchema } from 'shared/components/form/FormBuilder';
import type { AddUpdateLotModal_lot as LotFragment } from './__generated__/AddUpdateLotModal_lot';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  onReopen?: () => void,
  lot: LotFragment,
  createAnotherLot?: boolean,
};

const LANGUAGE = {
  nameFieldLabel: i18n.t('Lot Number'),
  createAnotherToggleLabel: i18n.t('Create Another Lot'),
  update: {
    title: i18n.t('Edit Lot'),
    submitButton: i18n.t('Update Lot'),
    successMessage: i18n.t('Lot Successfully Updated'),
  },
  add: {
    title: i18n.t('Create Lot'),
    submitButton: i18n.t('Create Lot'),
    successMessage: i18n.t('Lot Successfully Created'),
  },
};

class AddUpdateLotModal extends PureComponent<Props> {
  form: Object;
  initialValues: Object;
  validationSchema: Object;

  static defaultProps = {
    onSuccess: undefined,
    onReopen: undefined,
    createAnotherLot: false,
  };

  constructor(props: Props) {
    super(props);

    const { lot, createAnotherLot } = this.props;

    this.validationSchema = computeValidationSchema(null, {
      lotNumber: Yup.string().required('Required'),
      binNumber: Yup.string(),
      expiresAt: Yup.date().nullable(),
      createAnotherLot: Yup.boolean(),
    });

    this.initialValues = {
      lotNumber: lot?.lotNumber || '',
      binNumber: lot?.binNumber || '',
      expiresAt: lot?.expiresAt ? moment(lot?.expiresAt) : null,
      createAnotherLot,
    };
  }

  render() {
    const { onClose, onSuccess, onReopen, lot } = this.props;

    const language = (() => LANGUAGE[lot ? 'update' : 'add'])();

    return (
      <Modal maxWidth={700}>
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.validationSchema}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const mutation = lot ? UpdateLotMutation : AddLotMutation;

            const {
              lotNumber,
              binNumber,
              expiresAt,
              createAnotherLot,
            } = values;

            const input: any = {
              lotNumber: lotNumber,
              binNumber: binNumber,
              expiresAt: expiresAt,
            };

            if (lot) {
              input.id = lot.id;
              delete input.type;
            }

            try {
              const response = await mutation.commit({
                variables: {
                  input,
                },
              });

              const lotResponse = lot
                ? response.updateLot.lotEdge.node
                : response.addLot.lotEdge.node;

              if (createAnotherLot && onReopen) {
                onReopen();
              } else {
                onClose();
                if (onSuccess) {
                  onSuccess(lotResponse);
                }
              }

              Analytics.trackEvent(`${lot ? 'Update' : 'Create'} Lot`, {
                lotNumber: values.lotNumber,
                binNumber: values?.binNumber || null,
                expiresAt: moment(values?.expiresAt).format('L') || null,
              });
              Actions.alertNotification(language.successMessage, 'success');
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(e.message, 'Something Went Wrong');
            }
          }}
          render={({
            errors,
            values,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <ModalHeader header={language.title} onClose={onClose} />
              <ModalBody withPadding>
                <FieldGroup
                  label={LANGUAGE.nameFieldLabel}
                  name="lotNumber"
                  placeholder={i18n.t('Enter a lot number')}
                  error={errors.lotNumber}
                  disabled={lot && lot?.lotNumber === 'Default'}
                />
                <FieldGroupRow
                  left={
                    <FieldGroup
                      label={i18n.t('Bin Number')}
                      name="binNumber"
                      placeholder={i18n.t('Bin Number')}
                      error={errors.binNumber}
                      disabled={lot && lot?.lotNumber === 'Default'}
                    />
                  }
                  center={
                    <FieldGroup
                      label={i18n.t('Expiration Date')}
                      name="expiresAt"
                      placeholder={i18n.t('Expiration Date')}
                      error={errors.expiresAt}
                      type="date"
                      disabled={lot && lot?.lotNumber === 'Default'}
                    />
                  }
                />
              </ModalBody>
              <ModalFooter
                style={{
                  alignItems: 'center',
                }}
              >
                {!lot && (
                  <FieldGroup
                    label={LANGUAGE.createAnotherToggleLabel}
                    sideLabel="right"
                    name="createAnotherLot"
                    error={errors.createAnotherLot}
                    type="toggle"
                  />
                )}
                <Button
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {language.submitButton}
                </Button>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(AddUpdateLotModal, {
  lot: graphql`
    fragment AddUpdateLotModal_lot on Lot {
      id
      lotNumber
      binNumber
      expiresAt
    }
  `,
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type OrdersTable_orderEdges$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaginatedOrdersContainer_orders$ref: FragmentReference;
declare export opaque type PaginatedOrdersContainer_orders$fragmentType: PaginatedOrdersContainer_orders$ref;
export type PaginatedOrdersContainer_orders = {|
  +orders: {|
    +edges: ?$ReadOnlyArray<?{|
      +cursor: string,
      +$fragmentRefs: OrdersTable_orderEdges$ref,
    |}>
  |},
  +$refType: PaginatedOrdersContainer_orders$ref,
|};
export type PaginatedOrdersContainer_orders$data = PaginatedOrdersContainer_orders;
export type PaginatedOrdersContainer_orders$key = {
  +$data?: PaginatedOrdersContainer_orders$data,
  +$fragmentRefs: PaginatedOrdersContainer_orders$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PaginatedOrdersContainer_orders",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "orders"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 30
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "sortBy",
      "type": "OrderSort",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orderNumbers",
      "type": "[String!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "customerOrderNumbers",
      "type": "[String!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "customerIds",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "salesUserIds",
      "type": "[ID!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "statuses",
      "type": "[OrderStatus!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "promisedAtStart",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "promisedAtEnd",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orderedAtStart",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "orderedAtEnd",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "requiredAtStart",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "requiredAtEnd",
      "type": "Date",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "orders",
      "name": "__PaginatedOrdersContainer_orders_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "OrderConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "OrderEdge",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Order",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "OrdersTable_orderEdges",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6be49d9ffdf1bd94dd0f68cbf4f6ed6b';
module.exports = node;

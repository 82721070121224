// @flow
import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Divider from 'shared/components/common/Divider';
import { renderCosmeticFieldValue } from 'shared/components/form/FormBuilder';
import type { RunYieldCard_runYield as RunYieldFragment } from './__generated__/RunYieldCard_runYield';

type Props = {
  runYield: RunYieldFragment,
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.lightBlueGrey};
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 16px;
`;

const Title = styled.div`
  ${fonts.bodyBold};
`;

const CardDivider = styled(Divider)`
  margin: 8px 0;
`;

const RunYieldCard = ({ runYield }: Props) => {
  return (
    <Card>
      <Title>{runYield.item.name}</Title>
      <CardDivider />
      <KeyValueGrid
        singleColumn
        fields={[
          {
            label: i18n.t('Quantity To Produce'),
            value:
              runYield.quantity +
              i18n.t(' ') +
              runYield.item.quantityUOM.symbol,
          },
          {
            label: i18n.t('Part #'),
            value: runYield.item.partNumber || i18n.t('N/A'),
          },
          {
            label: i18n.t('Description'),
            value: runYield.item.description || i18n.t('N/A'),
          },
        ].concat(
          runYield.item.form.fields.map(customField => ({
            label: customField.name,
            value: renderCosmeticFieldValue({
              formFieldType: customField.type,
              value: runYield.item.formFieldValues[customField.id] || '',
            }),
          })),
        )}
      />
    </Card>
  );
};

export default createFragmentContainer(RunYieldCard, {
  runYield: graphql`
    fragment RunYieldCard_runYield on JobYield {
      id
      quantity
      item {
        id
        name
        partNumber
        description
        formFieldValues
        quantityUOM {
          id
          symbol
        }
        form {
          id
          ...FormBuilder_form
          fields {
            id
            systemKey
            name
            type
          }
        }
      }
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import UpdateCompanyCalendarEventMutation from 'main-app/mutations/UpdateCompanyCalendarEvent';
import AddCompanyCalendarEventMutation from 'main-app/mutations/AddCompanyCalendarEvent';
import DeleteCompanyCalendarEventMutation from 'main-app/mutations/DeleteCompanyCalendarEvent';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
} from 'shared/components/form';
import type { AddUpdateCalendarEventModal_companyCalendarEvent as CompanyCalendarEventFragment } from './__generated__/AddUpdateCalendarEventModal_companyCalendarEvent';

type Props = {
  onClose: () => void,
  companyCalendarEvent: ?CompanyCalendarEventFragment,
  startDate: ?moment$Moment,
  endDate: ?moment$Moment,
  isFullDay: ?boolean,
};

type State = {
  isDeleting: boolean,
};

const ButtonWrapper = styled.div`
  display: flex;

  button:first-of-type {
    margin-right: 16px;
  }
`;

const LANGUAGE = {
  update: {
    title: i18n.t('Edit Unavailability'),
    submitButton: i18n.t('Update Unavailability'),
    successMessage: i18n.t('Unavailability Successfully Updated'),
  },
  add: {
    title: i18n.t('Add Unavailability'),
    submitButton: i18n.t('Add Unavailability'),
    successMessage: i18n.t('Unavailability Successfully Added'),
  },
};

// using this function format in order to keep the correct scope for validation
function validateEndTime(value) {
  if (this.parent.isFullDay) return true;

  if (this.parent.startAtTime && !value) {
    return this.createError({ message: 'Required' });
  }

  if (
    moment(value, 'HH:mm:ss').isSameOrBefore(
      moment(this.parent.startAtTime, 'HH:mm:ss'),
    )
  ) {
    return this.createError({ message: 'Must be after the start time' });
  }

  return true;
}

class AddUpdateCalendarEventModal extends PureComponent<Props, State> {
  state = {
    isDeleting: false,
  };

  mergeDateWithTime = (date: moment$Moment, time: ?string) => {
    if (!time) {
      return moment(date).format();
    }

    return moment(
      moment(date).format('YYYY-MM-DD') +
        ' ' +
        moment(time, 'HH:mm:ss.SSSZ').format('HH:mm'),
    ).format();
  };

  handleDelete = () => {
    const { onClose, companyCalendarEvent } = this.props;

    const confirm = window.confirm(
      i18n.t('Are you sure you want to delete this unavailability?'),
    );

    if (!confirm || !companyCalendarEvent) {
      return;
    }

    this.setState(
      {
        isDeleting: true,
      },
      async () => {
        try {
          await DeleteCompanyCalendarEventMutation.commit({
            variables: {
              input: {
                id: companyCalendarEvent.id,
              },
            },
          });

          Actions.alertNotification(
            i18n.t('Unavailability Successfully Deleted'),
            'success',
          );

          onClose();
        } catch (e) {
          Actions.alertNotification(e, 'error');
        }
      },
    );
  };

  render() {
    const {
      companyCalendarEvent,
      onClose,
      startDate,
      endDate,
      isFullDay,
    } = this.props;
    const { isDeleting } = this.state;

    const language = companyCalendarEvent ? LANGUAGE.update : LANGUAGE.add;

    return (
      <Modal maxWidth={700} onClickAway={onClose}>
        <Formik
          initialValues={{
            name: companyCalendarEvent?.name || '',
            isFullDay: isFullDay || companyCalendarEvent?.isFullDay || false,
            isOpen: companyCalendarEvent?.isOpen || false,
            startAtDate:
              startDate || companyCalendarEvent?.startAt
                ? moment(startDate || companyCalendarEvent?.startAt)
                : '',
            endAtDate:
              endDate || companyCalendarEvent?.endAt
                ? moment(endDate || companyCalendarEvent?.endAt)
                : '',
            startAtTime:
              startDate || companyCalendarEvent?.startAt
                ? moment(startDate || companyCalendarEvent?.startAt).format(
                    'HH:mm',
                  )
                : '',
            endAtTime:
              endDate || companyCalendarEvent?.endAt
                ? moment(endDate || companyCalendarEvent?.endAt).format('HH:mm')
                : '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            startAtDate: Yup.date()
              .nullable()
              .required('Required'),
            endAtDate: Yup.date()
              .nullable()
              .required('Required'),
            isFullDay: Yup.bool(),
            startAtTime: Yup.string().when(['endAtTime', 'isFullDay'], {
              is: (endAtTime, isFullDay) =>
                isFullDay ? false : Boolean(endAtTime),
              then: Yup.string().required('Required'),
              otherwise: Yup.string(),
            }),
            endAtTime: Yup.string().test({
              name: 'endTime',
              test: validateEndTime,
            }),
          })}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const mutation = companyCalendarEvent
              ? UpdateCompanyCalendarEventMutation
              : AddCompanyCalendarEventMutation;

            const startAt = this.mergeDateWithTime(
              values.startAtDate,
              values.startAtTime,
            );

            const endAt = this.mergeDateWithTime(
              values.endAtDate,
              values.endAtTime,
            );

            const input: Object = {
              name: values.name,
              isFullDay: values.isFullDay || false,
              isOpen: values.isOpen || false,
              startAt: values.isFullDay
                ? moment(startAt)
                    .startOf('day')
                    .format()
                : startAt,
              endAt: values.isFullDay
                ? moment(endAt)
                    .endOf('day')
                    .format()
                : endAt,
            };

            if (companyCalendarEvent) {
              input.id = companyCalendarEvent.id;
            }

            try {
              await mutation.commit({
                variables: {
                  input,
                },
              });

              onClose();
              Actions.alertNotification(language.successMessage, 'success');
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(e.message, 'error');
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <ModalHeader header={language.title} onClose={onClose} />
              <ModalBody withPadding>
                <FieldGroup
                  label={i18n.t('Reason')}
                  name="name"
                  placeholder={i18n.t('Add a reason')}
                  error={errors.name}
                />
                {
                  // TODO: disabling isOpen check for now until we're ready to implement this into scheduling logic
                  /* <FieldGroup
                  label={i18n.t('Office Open?')}
                  name="isOpen"
                  type="toggle"
                /> */
                }
                <FieldGroup
                  label={i18n.t('All Day Event')}
                  name="isFullDay"
                  type="toggle"
                />
                <FieldGroupRow
                  left={
                    <FieldGroup
                      label={i18n.t('Unavailable Start Date')}
                      name="startAtDate"
                      placeholder={i18n.t('Select a date')}
                      type="date"
                      allowPastDates
                      selectsStart
                      error={errors.startAtDate}
                    />
                  }
                  right={
                    values.isFullDay ? (
                      <div />
                    ) : (
                      <FieldGroup
                        label={i18n.t('Unavailable Start Time')}
                        name="startAtTime"
                        type="time"
                        error={errors.startAtTime}
                      />
                    )
                  }
                />
                <FieldGroupRow
                  left={
                    <FieldGroup
                      label={i18n.t('Unavailable End Date')}
                      name="endAtDate"
                      placeholder={i18n.t('Select a date')}
                      type="date"
                      selectsEnd
                      minDate={values.startAtDate}
                      error={errors.endAtDate}
                    />
                  }
                  right={
                    values.isFullDay ? (
                      <div />
                    ) : (
                      <FieldGroup
                        label={i18n.t('Unavailable End Time')}
                        name="endAtTime"
                        type="time"
                        error={errors.endAtTime}
                      />
                    )
                  }
                />
              </ModalBody>
              <ModalFooter>
                <ButtonWrapper>
                  {companyCalendarEvent && (
                    <Button
                      theme="red"
                      onClick={this.handleDelete}
                      loading={isDeleting}
                    >
                      {i18n.t('Delete Unavailability')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    theme="blue"
                    disabled={!isValid || isDeleting}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {language.submitButton}
                  </Button>
                </ButtonWrapper>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(AddUpdateCalendarEventModal, {
  companyCalendarEvent: graphql`
    fragment AddUpdateCalendarEventModal_companyCalendarEvent on CompanyCalendarEvent {
      id
      name
      isFullDay
      isOpen
      startAt
      endAt
    }
  `,
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OperatingHoursModal_company$ref: FragmentReference;
declare export opaque type OperatingHoursModal_company$fragmentType: OperatingHoursModal_company$ref;
export type OperatingHoursModal_company = {|
  +id: string,
  +sundayHoursStart: ?string,
  +sundayHoursEnd: ?string,
  +mondayHoursStart: ?string,
  +mondayHoursEnd: ?string,
  +tuesdayHoursStart: ?string,
  +tuesdayHoursEnd: ?string,
  +wednesdayHoursStart: ?string,
  +wednesdayHoursEnd: ?string,
  +thursdayHoursStart: ?string,
  +thursdayHoursEnd: ?string,
  +fridayHoursStart: ?string,
  +fridayHoursEnd: ?string,
  +saturdayHoursStart: ?string,
  +saturdayHoursEnd: ?string,
  +$refType: OperatingHoursModal_company$ref,
|};
export type OperatingHoursModal_company$data = OperatingHoursModal_company;
export type OperatingHoursModal_company$key = {
  +$data?: OperatingHoursModal_company$data,
  +$fragmentRefs: OperatingHoursModal_company$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OperatingHoursModal_company",
  "type": "Company",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sundayHoursStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sundayHoursEnd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mondayHoursStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mondayHoursEnd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tuesdayHoursStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tuesdayHoursEnd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "wednesdayHoursStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "wednesdayHoursEnd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "thursdayHoursStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "thursdayHoursEnd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fridayHoursStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fridayHoursEnd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "saturdayHoursStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "saturdayHoursEnd",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f18a22768824ffc2e22902971c4362a0';
module.exports = node;

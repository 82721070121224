// @flow

import React from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  id: string,
  content: any,
};

/**
 * Create a React tooltip
 * @param {Object} data
 * @return {React$Node}
 */
export default ({ id, content }: Props) => (
  <ReactTooltip
    place="bottom"
    effect="solid"
    type="info"
    id={id}
    key={id + Math.random()}
  >
    {content}
  </ReactTooltip>
);

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateJobModal_workflow$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Toolbar_workflowEdges$ref: FragmentReference;
declare export opaque type Toolbar_workflowEdges$fragmentType: Toolbar_workflowEdges$ref;
export type Toolbar_workflowEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +isDefault: boolean,
    +$fragmentRefs: AddUpdateJobModal_workflow$ref,
  |},
  +$refType: Toolbar_workflowEdges$ref,
|}>;
export type Toolbar_workflowEdges$data = Toolbar_workflowEdges;
export type Toolbar_workflowEdges$key = {
  +$data?: Toolbar_workflowEdges$data,
  +$fragmentRefs: Toolbar_workflowEdges$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Toolbar_workflowEdges",
  "type": "WorkflowEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isDefault",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateJobModal_workflow",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bf226ec1d38ef8657380e60e19313593';
module.exports = node;

// @flow

import React from 'react';
import moment from 'moment';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import SidePanel from 'shared/components/common/SidePanel';
import Divider from 'shared/components/common/Divider';
import PanelSection from 'shared/components/common/SidePanel/PanelSection';
import type { LotSidePanel_lot as LotFragment } from './__generated__/LotSidePanel_lot';

type Props = {
  lot: LotFragment,
};

const LotSidePanel = ({ lot }: Props) => (
  <>
    <SidePanel>
      <PanelSection
        title={i18n.t('Lot Info')}
        fields={[
          {
            label: i18n.t('Lot Number'),
            value: lot.lotNumber || '',
          },
        ]}
      />
      <Divider />
      <PanelSection
        title={i18n.t('Lot Details')}
        fields={[
          {
            label: i18n.t('Bin Location'),
            value: lot.binNumber || 'N/A',
          },
          {
            label: i18n.t('Expiration Date'),
            value: moment(lot.expiresAt).isValid()
              ? moment(lot.expiresAt).format('MM/DD/YYYY')
              : 'N/A',
          },
        ]}
      />
    </SidePanel>
  </>
);

export default createFragmentContainer(LotSidePanel, {
  lot: graphql`
    fragment LotSidePanel_lot on Lot {
      id
      lotNumber
      binNumber
      expiresAt
      createdAt
    }
  `,
});

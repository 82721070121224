// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddJobYieldMutation from 'main-app/mutations/AddJobYield';
import UpdateJobYieldMutation from 'main-app/mutations/UpdateJobYield';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
  SelectItemField,
} from 'shared/components/form';
import type { AddUpdateJobYieldModal_jobYield as JobYieldFragment } from './__generated__/AddUpdateJobYieldModal_jobYield';
import type { AddUpdateJobYieldModal_job as JobFragment } from './__generated__/AddUpdateJobYieldModal_job';

type Props = {
  onClose: () => void,
  job: JobFragment,
  jobYield: ?JobYieldFragment,
  workflowHasGangRun?: boolean,
};

class AddUpdateJobYieldModal extends PureComponent<Props> {
  form: Object;
  validationSchema: Object;

  static defaultProps = {
    workflowHasGangRun: false,
  };

  constructor(props: Props) {
    super(props);

    this.validationSchema = Yup.object().shape({
      item: Yup.object()
        .nullable()
        .required('Required'),
      quantity: Yup.number().required('Required'),
      overUnderRule: Yup.object().nullable(),
      overQuantity: Yup.number(),
      itemNumberUp: props.workflowHasGangRun
        ? Yup.number().required('Required')
        : Yup.number().notRequired(),
      brokerCustomerOrderNumber: Yup.string(),
    });
  }

  render() {
    const { job, jobYield, onClose, workflowHasGangRun } = this.props;

    return (
      <Modal maxWidth={700}>
        <Formik
          initialValues={{
            item: jobYield?.item && {
              value: jobYield?.item.id,
              data: jobYield?.item,
            },
            quantity: jobYield?.quantity || '',
            overUnderRule: jobYield?.overUnderRule
              ? { value: jobYield?.overUnderRule }
              : null,
            overQuantity: jobYield?.overQuantity || '',
            itemNumberUp: jobYield?.itemNumberUp || '1',
            brokerCustomerOrderNumber:
              jobYield?.brokerCustomerOrderNumber || '',
          }}
          validationSchema={this.validationSchema}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const mutation = jobYield
              ? UpdateJobYieldMutation
              : AddJobYieldMutation;
            const {
              item,
              quantity,
              overUnderRule,
              overQuantity,
              itemNumberUp,
              brokerCustomerOrderNumber,
            } = values;

            const variables: any = {
              input: {
                itemId: item.value,
                overUnderRule: overUnderRule?.value || null,
                overQuantity:
                  overUnderRule?.value &&
                  overUnderRule?.value !== 'NO_OVERS' &&
                  overQuantity
                    ? parseFloat(overQuantity)
                    : null,
                quantity: quantity ? parseFloat(quantity) : null,
                itemNumberUp: itemNumberUp ? parseInt(itemNumberUp) : null,
                brokerCustomerOrderNumber,
              },
            };

            if (jobYield) {
              variables.input.id = jobYield.id;
            } else {
              variables.input.jobId = job.id;
            }

            try {
              const addJobYieldResponse = await mutation.commit({
                variables,
              });

              onClose();

              Actions.alertNotification(
                i18n.t(`Item Successfully ${jobYield ? 'Updated' : 'Added'}`),
                'success',
              );

              if (!jobYield) {
                Analytics.trackEvent('Add Item on Job Ticket', {
                  inventoryNumber:
                    addJobYieldResponse.addJobYield.jobYieldEdge.node.item
                      .itemNumber,
                  unitsToProduce:
                    addJobYieldResponse.addJobYield.jobYieldEdge.node.quantity,
                  numberUp:
                    addJobYieldResponse.addJobYield.jobYieldEdge.node
                      .itemNumberUp,
                });
              }
            } catch (e) {
              setSubmitting(false);

              Actions.alertNotification(e.message, 'error');
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <Form>
                <ModalHeader
                  header={i18n.t(jobYield ? 'Edit Item' : 'Add Item')}
                  onClose={onClose}
                />
                <ModalBody withPadding>
                  <SelectItemField
                    name="item"
                    label={i18n.t('Item')}
                    placeholder={i18n.t('Select Item...')}
                    error={errors.item}
                    queryVariables={
                      job.order?.id
                        ? {
                            orderIds: [job.order?.id],
                          }
                        : undefined
                    }
                    description={
                      job.order?.orderNumber
                        ? i18n.t(
                            'You can only select items from Order {{ orderNumber, string }}',
                            {
                              orderNumber: job.order?.orderNumber,
                            },
                          )
                        : null
                    }
                  />
                  <FieldGroupRow
                    left={
                      <FieldGroup
                        name="quantity"
                        label={i18n.t('Quantity to Produce')}
                        placeholder={i18n.t('#')}
                        type="number"
                        error={errors.quantity}
                        extendedLabel={values.item?.data?.quantityUOM.symbol}
                      />
                    }
                    right={
                      workflowHasGangRun && (
                        <FieldGroup
                          name="itemNumberUp"
                          label={i18n.t('# Up')}
                          placeholder={i18n.t('#')}
                          type="number"
                          error={errors.itemNumberUp}
                        />
                      )
                    }
                  />
                  <FieldGroupRow
                    left={
                      <FieldGroup
                        name="overUnderRule"
                        label={i18n.t('Allow Overs / Unders?')}
                        placeholder={i18n.t('Select one')}
                        type="select"
                        options={[
                          {
                            label: 'No Overs',
                            value: 'NO_OVERS',
                          },
                          {
                            label: 'No Unders',
                            value: 'NO_UNDERS',
                          },
                          {
                            label: 'Allow Overs',
                            value: 'ALLOW_OVERS',
                          },
                        ]}
                        error={errors.overUnderRule}
                      />
                    }
                    right={
                      values.overUnderRule?.value === 'NO_UNDERS' ||
                      values.overUnderRule?.value === 'ALLOW_OVERS' ? (
                        <FieldGroup
                          name="overQuantity"
                          label={i18n.t('Overs Quantity')}
                          placeholder={i18n.t('#')}
                          type="number"
                          error={errors.overQuantity}
                          extendedLabel={values.item?.data?.quantityUOM.symbol}
                        />
                      ) : (
                        <div />
                      )
                    }
                  />
                  <FieldGroup
                    name="brokerCustomerOrderNumber"
                    label={i18n.t('Broker PO #')}
                    placeholder={i18n.t('Order #')}
                    error={errors.brokerCustomerOrderNumber}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    theme="blue"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {i18n.t(jobYield ? 'Update Item' : 'Add Item')}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(AddUpdateJobYieldModal, {
  jobYield: graphql`
    fragment AddUpdateJobYieldModal_jobYield on JobYield {
      id
      quantity
      itemNumberUp
      brokerCustomerOrderNumber
      formFieldValues
      overUnderRule
      overQuantity
      item {
        id
        quantityUOM {
          id
          name
          symbol
        }
      }
      form {
        id
        ...FormBuilder_form @relay(mask: false)
      }
    }
  `,
  job: graphql`
    fragment AddUpdateJobYieldModal_job on Job {
      id
      order {
        id
        orderNumber
      }
      workflow {
        id
        states {
          id
          isGangRun
        }
      }
    }
  `,
});

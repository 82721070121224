/**
 * @flow
 * @relayHash b5aaa6b8dbcc379bc35a20514ee6dd0d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AttachmentParentType = "ITEM" | "JOB" | "ORDER";
export type AddAttachmentsInput = {|
  parentType: AttachmentParentType,
  parentId: string,
|};
export type AddAttachmentsMutationVariables = {|
  input: AddAttachmentsInput
|};
export type AddAttachmentsMutationResponse = {|
  +addAttachments: {|
    +attachmentEdges: $ReadOnlyArray<{|
      +node: {|
        +id: string
      |}
    |}>
  |}
|};
export type AddAttachmentsMutation = {|
  variables: AddAttachmentsMutationVariables,
  response: AddAttachmentsMutationResponse,
|};
*/


/*
mutation AddAttachmentsMutation(
  $input: AddAttachmentsInput!
) {
  addAttachments(input: $input) {
    attachmentEdges {
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddAttachmentsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addAttachments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddAttachmentsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "attachmentEdges",
        "storageKey": null,
        "args": null,
        "concreteType": "AttachmentEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Attachment",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddAttachmentsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddAttachmentsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddAttachmentsMutation",
    "id": null,
    "text": "mutation AddAttachmentsMutation(\n  $input: AddAttachmentsInput!\n) {\n  addAttachments(input: $input) {\n    attachmentEdges {\n      node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b88c41685fd8cfdd35cfe5f1d2ea061';
module.exports = node;

// @flow

import type { FilterOption } from 'main-app/components/FilterControls';
import { i18n } from 'shared/utils';

export default (): Array<FilterOption> => [
  {
    label: i18n.t('Job #'),
    name: 'jobNumbers',
    type: 'text',
    multi: true,
  },
  {
    label: i18n.t('Original Job #'),
    name: 'importJobNumbers',
    type: 'text',
    multi: true,
  },
  {
    label: i18n.t('Customer'),
    name: 'customerIds',
    type: 'customer',
    multi: true,
  },
  {
    label: i18n.t('Project Name'),
    name: 'name',
    type: 'text',
  },
  {
    label: i18n.t('Status'),
    name: 'statuses',
    type: 'select',
    multi: true,
    selectOptions: [
      { label: i18n.t('Open'), value: 'OPEN' },
      { label: i18n.t('Closed'), value: 'CLOSED' },
    ],
  },
  {
    label: i18n.t('Job Owner'),
    name: 'userIds',
    type: 'user',
    multi: true,
  },
  {
    label: i18n.t('Item Name'),
    name: 'itemName',
    type: 'text',
  },
  {
    label: i18n.t('Part #'),
    name: 'itemPartNumber',
    type: 'text',
  },
  {
    label: i18n.t('Date Created'),
    name: 'createdAt',
    type: 'date-range',
  },
];

// @flow
export default [
  {
    label: 'Add',
    value: 'ADD',
  },
  {
    label: 'Deduct',
    value: 'DEDUCT',
  },
  {
    label: 'Transfer',
    value: 'TRANSFER',
  },
];

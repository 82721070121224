// @flow

import React from 'react';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';

type Props = {
  onClose: () => void,
  onDeleteRecord: () => void,
  type: string,
};

const LANGUAGE = {
  node: {
    header: i18n.t('Delete State?'),
    submitButton: i18n.t('Delete State'),
    body: (
      <>
        {i18n.t('Are you sure you want to remove this State?')}
        <br />
        <br />
        {i18n.t(
          'This will remove the State and all of the associated Transitions and Transition Actions.',
        )}
      </>
    ),
  },
  link: {
    header: i18n.t('Delete Transition?'),
    submitButton: i18n.t('Delete Transition'),
    body: (
      <>
        {i18n.t('Are you sure you want to remove this Transition?')}
        <br />
        <br />
        {i18n.t(
          'This will remove the Transition and all of the associated Transition Actions.',
        )}
      </>
    ),
  },
};

const DeleteRecordModal = ({ onClose, onDeleteRecord, type }: Props) => {
  const language = LANGUAGE[type];

  return (
    <Modal maxWidth={700}>
      <ModalHeader header={language.header} onClose={onClose} />
      <ModalBody withPadding>{language.body}</ModalBody>
      <ModalFooter>
        <Button type="submit" theme="blue" onClick={onDeleteRecord}>
          {language.submitButton}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteRecordModal;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation DeleteFormFieldMutation($input: DeleteFormFieldInput!) {
    deleteFormField(input: $input) {
      form {
        id
        name
        type
        description
        displayOrder
        isTemplate
        updatedAt
        createdAt
        fields {
          id
          type
          name
          description
          applicationType
          placeholder
          displayOrder
          systemKey
          isRequired
          updatedAt
          createdAt
          isCreatable
          properties
          options {
            id
            name
            value
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

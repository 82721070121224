/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RunLogs_run$ref = any;
type RunYields_run$ref = any;
export type RunStatus = "COMPLETE" | "IN_PROGRESS" | "PAUSED" | "SCHEDULED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ViewRunModal_run$ref: FragmentReference;
declare export opaque type ViewRunModal_run$fragmentType: ViewRunModal_run$ref;
export type ViewRunModal_run = {|
  +id: string,
  +status: RunStatus,
  +notes: ?string,
  +scheduledStartAt: any,
  +scheduledEndAt: any,
  +actualStartAt: ?any,
  +actualEndAt: ?any,
  +jobState: {|
    +id: string,
    +workflowState: {|
      +id: string,
      +name: string,
    |},
  |},
  +machine: {|
    +id: string,
    +name: string,
    +type: {|
      +id: string,
      +name: string,
    |},
  |},
  +$fragmentRefs: RunLogs_run$ref & RunYields_run$ref,
  +$refType: ViewRunModal_run$ref,
|};
export type ViewRunModal_run$data = ViewRunModal_run;
export type ViewRunModal_run$key = {
  +$data?: ViewRunModal_run$data,
  +$fragmentRefs: ViewRunModal_run$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "ViewRunModal_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "scheduledStartAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "scheduledEndAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actualStartAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actualEndAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "jobState",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "workflowState",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": false,
          "selections": (v2/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "machine",
      "storageKey": null,
      "args": null,
      "concreteType": "Machine",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "type",
          "storageKey": null,
          "args": null,
          "concreteType": "MachineType",
          "plural": false,
          "selections": (v2/*: any*/)
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RunLogs_run",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RunYields_run",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b05a8837ff17e627b8c05d9c756ad8b';
module.exports = node;

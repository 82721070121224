// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import DownJobIcon from 'shared/components/common/Icon/img/downJob.svg';
import MovingIcon from 'shared/components/common/Icon/img/moving.svg';
import ResizeingIcon from 'shared/components/common/Icon/img/resizing.svg';

export type InfoBoxProps = {
  eventType: 'move' | 'resize' | 'hover',
  name: string,
  date: moment$Moment,
  workCenterName: string,
  duration: moment$MomentDuration,
};

export type DowntimeInfoBoxProps = {
  downtimeName: string,
  date: moment$Moment,
  workCenterName: string,
  duration: moment$MomentDuration,
};

const Box = styled.div`
  min-width: 310px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(66, 68, 76, 0.2);
  border: solid 1px ${colors.lightBlueGrey};
  background-color: ${colors.white};
  position: fixed;
  left: 270px;
  bottom: 20px;
  padding: 10px;
`;

const Devider = styled.hr`
  border: 1px ${colors.lightBlueGrey} solid;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Body = styled.div`
  ${fonts.bodyRegular}
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const RegularText = styled.span`
  ${fonts.bodyRegular};
  margin-left: 4px;
`;

const BoldText = styled.b`
  ${fonts.bodyBold};
  margin-left: 4px;
`;

const TextWrapper = styled.div`
  display: flex;
  margin-left: 4px;
  height: max-content;
  margin-top: 2px;
  color: ${p => (p.warning ? colors.cherry : colors.charcoalGrey)};
`;

function formatInfoBoxDate(date: moment$Moment): string {
  return date.format('dddd, MMM Do YYYY');
}

function formatInfoBoxDuration(duration: moment$MomentDuration): string {
  const days = duration.days() > 0 ? `${duration.days()}D ` : '';
  return `${days}${duration.hours()}H ${duration.minutes()}M`;
}

export const InfoBox = ({
  eventType,
  date,
  name,
  duration,
  workCenterName,
}: InfoBoxProps) => {
  const Icon = eventType === 'move' ? MovingIcon : ResizeingIcon;
  const actionText =
    eventType === 'move' ? i18n.t('Moving') : i18n.t('Resizing');
  const isHover = eventType === 'hover';
  return (
    <Box>
      <Header>
        {/* if hover event type hide icon */}
        {!isHover && <Icon />}
        <TextWrapper>
          {/* if hover event type hide action text */}
          {!isHover && <RegularText>{actionText}</RegularText>}
          <BoldText>{name}</BoldText>
        </TextWrapper>
      </Header>
      <Devider />
      <Body>
        <TextWrapper>
          <BoldText>{formatInfoBoxDate(date)}</BoldText>
          <RegularText>{i18n.t('on')}</RegularText>
          <BoldText>{workCenterName}</BoldText>
        </TextWrapper>
        <TextWrapper>
          <RegularText>{`${i18n.t('Run Duration')}: ${formatInfoBoxDuration(
            duration,
          )}`}</RegularText>
        </TextWrapper>
        <TextWrapper>
          <RegularText>
            {`${i18n.t('Scheduled Start Time')}: ${date.format('hh:mm a')}`}
          </RegularText>
        </TextWrapper>
      </Body>
    </Box>
  );
};

export const DowntimeInfoBox = ({
  downtimeName,
  date,
  workCenterName,
  duration,
}: DowntimeInfoBoxProps) => {
  return (
    <Box>
      <Header>
        <DownJobIcon />
        <TextWrapper warning>
          <RegularText>{i18n.t('Downtime')}</RegularText>
          <BoldText>{downtimeName}</BoldText>
        </TextWrapper>
      </Header>
      <Devider />
      <Body>
        <TextWrapper>
          <BoldText>{formatInfoBoxDate(date)}</BoldText>
          <RegularText>{i18n.t('on')}</RegularText>
          <BoldText>{workCenterName}</BoldText>
        </TextWrapper>
        <TextWrapper>
          <RegularText>{`${i18n.t(
            'Downtime Duration',
          )}: ${formatInfoBoxDuration(duration)}`}</RegularText>
        </TextWrapper>
      </Body>
    </Box>
  );
};

export default {
  InfoBox,
  DowntimeInfoBox,
};

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateRunModal_job$ref = any;
type AutoScheduleJobModal_job$ref = any;
type RunPanels_runEdges$ref = any;
type RunYieldProgressBarPanels_job$ref = any;
export type JobStatus = "CLOSED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Runs_job$ref: FragmentReference;
declare export opaque type Runs_job$fragmentType: Runs_job$ref;
export type Runs_job = {|
  +id: string,
  +jobNumber: string,
  +status: JobStatus,
  +workflow: {|
    +id: string,
    +isParallelWorkflow: boolean,
  |},
  +runs: {|
    +edges: ?$ReadOnlyArray<?{|
      +$fragmentRefs: RunPanels_runEdges$ref
    |}>
  |},
  +$fragmentRefs: AddUpdateRunModal_job$ref & AutoScheduleJobModal_job$ref & RunYieldProgressBarPanels_job$ref,
  +$refType: Runs_job$ref,
|};
export type Runs_job$data = Runs_job;
export type Runs_job$key = {
  +$data?: Runs_job$data,
  +$fragmentRefs: Runs_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Runs_job",
  "type": "Job",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "runs"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflow",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isParallelWorkflow",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "runs",
      "name": "__Runs_job_runs_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "RunConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RunEdge",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Run",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "RunPanels_runEdges",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "AddUpdateRunModal_job",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AutoScheduleJobModal_job",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RunYieldProgressBarPanels_job",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b9e72e974ea249710f153ce43c421736';
module.exports = node;

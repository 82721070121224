// @flow

import type { FilterOption } from 'main-app/components/FilterControls';
import { i18n } from 'shared/utils';

export default (): Array<FilterOption> => [
  {
    label: i18n.t('Lot #'),
    name: 'lotNumbers',
    type: 'text',
    multi: true,
  },
  {
    label: i18n.t('Bin Number'),
    name: 'binNumbers',
    type: 'text',
    multi: true,
  },
];

// @flow

import React, { PureComponent } from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router';
import qs from 'qs';
import moment from 'moment';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import Icon from 'shared/components/common/Icon';
import RunStatusPill from 'shared/components/common/Pill/RunStatusPill';
import RunYieldsList from 'shared/components/common/RunYieldsList';
import AddUpdateRunModal from 'main-app/components/AddUpdateRunModal';
import DeleteRunModal from 'main-app/components/DeleteRunModal';
import ViewRunModal from 'main-app/components/ViewRunModal';
import type { RunPanels_runEdges as RunEdges } from './__generated__/RunPanels_runEdges';

type Props = {
  history: RouterHistory,
  runEdges: RunEdges,
};

type State = {
  updateRunRecord: ?Object,
  deleteRunRecord: ?Object,
  viewRunRecord: ?Object,
};

const RunPanelsWrapper = styled.div``;

const RunPanel = styled.div`
  transition: background-color 300ms ease-in;
  background-color: ${colors.white};
  padding: 16px 16px 0 16px;
  border: 1px solid ${colors.lightBlueGrey};
  border-radius: 10px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1000px) {
    &:hover {
      cursor: pointer;
      background-color: ${colors.paleGreyTwo};
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`;

const Title = styled.div`
  ${fonts.bodyBold};
  color: ${colors.charcoalGrey};
`;

const Date = styled.div`
  ${fonts.smallRegular};
  color: ${colors.charcoalGrey};
`;

class RunPanels extends PureComponent<Props, State> {
  menuTriggerRefs = {};
  initialState = {
    updateRunRecord: null,
    deleteRunRecord: null,
    viewRunRecord: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = this.initialState;
  }

  handleViewOnSchedule = (run: Object) => {
    const { history } = this.props;

    history.push(
      '/production-schedule?' +
        qs.stringify(
          {
            defaultStartTime: moment(run.scheduledStartAt)
              .subtract(1, 'hour')
              .format(),
          },
          {
            encode: true,
          },
        ),
    );
  };

  handleOpenEditRunModal = (run: Object) => {
    this.setState({
      updateRunRecord: run,
    });
  };

  handleOpenDeleteRunModal = (run: Object) => {
    this.setState({
      deleteRunRecord: run,
    });
  };

  handleOpenViewRunModal = (run: Object) => {
    this.setState({
      viewRunRecord: run,
    });
  };

  handleCloseAllModals = () => {
    this.setState(this.initialState);
  };

  render() {
    const { runEdges } = this.props;
    const { viewRunRecord, updateRunRecord, deleteRunRecord } = this.state;

    if (!runEdges) {
      return null;
    }

    return (
      <RunPanelsWrapper>
        {runEdges.map(edge => {
          const run = edge?.node;

          if (!run) {
            return null;
          }

          return (
            <RunPanel
              key={run.id}
              onClick={e => {
                e.preventDefault();
                this.handleOpenViewRunModal(run);
              }}
            >
              <Row>
                <Title>
                  {run.machine.name} / {run.jobState.workflowState.name}
                </Title>
                <ContextMenuTrigger
                  id={`run-list-menu-${run.id}`}
                  ref={r => (this.menuTriggerRefs[run.id] = r)}
                >
                  <Icon
                    type="circle-context-menu"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (this.menuTriggerRefs[run.id]) {
                        this.menuTriggerRefs[run.id].handleContextClick(e);
                      }
                    }}
                  />
                </ContextMenuTrigger>
              </Row>
              <Row>
                <RunStatusPill status={run.status} width="auto" />
                <Date>
                  {run.scheduledStartAt
                    ? moment(run.scheduledStartAt).format('MM/DD/YYYY')
                    : null}
                </Date>
              </Row>
              <RunYieldsList runYieldEdges={run.yields.edges} />
              <ContextMenu id={`run-list-menu-${run.id}`}>
                <MenuItem
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.handleViewOnSchedule(run);
                  }}
                >
                  {i18n.t('View On Schedule')}
                </MenuItem>
                <MenuItem
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.handleOpenEditRunModal(run);
                  }}
                >
                  {i18n.t('Edit Run')}
                </MenuItem>
                <MenuItem
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.handleOpenDeleteRunModal(run);
                  }}
                >
                  {i18n.t('Delete Run')}
                </MenuItem>
              </ContextMenu>
            </RunPanel>
          );
        })}
        {updateRunRecord && (
          <AddUpdateRunModal
            job={updateRunRecord.job}
            run={updateRunRecord}
            defaultMachine={null}
            onClose={this.handleCloseAllModals}
          />
        )}
        {deleteRunRecord && (
          <DeleteRunModal
            jobId={deleteRunRecord.job.id}
            run={deleteRunRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
        {viewRunRecord && (
          <ViewRunModal
            run={viewRunRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
      </RunPanelsWrapper>
    );
  }
}

export default createFragmentContainer(RunPanels, {
  runEdges: graphql`
    fragment RunPanels_runEdges on RunEdge @relay(plural: true) {
      node {
        id
        status
        scheduledStartAt
        jobState {
          id
          workflowState {
            id
            name
          }
        }
        machine {
          id
          name
        }
        yields(first: null) @connection(key: "Runs_job_yields", filters: []) {
          edges {
            ...RunYieldsList_runYieldEdges
          }
        }
        job {
          id
          ...AddUpdateRunModal_job
        }
        ...AddUpdateRunModal_run
        ...DeleteRunModal_run
        ...ViewRunModal_run
      }
    }
  `,
});

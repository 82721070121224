// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteIntegrationMutation from 'main-app/mutations/DeleteIntegration';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteIntegrationModal_integration as IntegrationFragment } from './__generated__/DeleteIntegrationModal_integration';

type Props = {
  onClose: () => void,
  integration: IntegrationFragment,
  onSuccess?: () => void,
};

type State = {
  deleting: boolean,
};

class DeleteIntegrationModal extends PureComponent<Props, State> {
  static defaultProps = {
    onSuccess: undefined,
  };

  state = {
    deleting: false,
  };

  handleDelete = () => {
    const { integration, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteIntegrationMutation.commit({
            variables: {
              input: {
                id: integration.id,
              },
            },
          });

          onClose();

          Actions.alertNotification(
            i18n.t(`Integration Successfully Deleted`),
            'success',
          );

          Analytics.trackEvent('Delete Integration', {
            integrationId: integration.id,
          });

          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { integration, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete Integration: {{integrationName}}?', {
            integrationName: integration.source.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            'Are you sure you want to delete this integration? This action CANNOT be undone.',
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Integration')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteIntegrationModal, {
  integration: graphql`
    fragment DeleteIntegrationModal_integration on Integration {
      id
      source {
        id
        name
      }
    }
  `,
});

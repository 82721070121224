/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeleteJobSubComponentModal_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobSubComponentsTree_jobSubComponentEdges$ref: FragmentReference;
declare export opaque type JobSubComponentsTree_jobSubComponentEdges$fragmentType: JobSubComponentsTree_jobSubComponentEdges$ref;
export type JobSubComponentsTree_jobSubComponentEdges = $ReadOnlyArray<{|
  +usageQty: ?number,
  +node: {|
    +id: string,
    +itemNumber: string,
    +name: string,
    +partNumber: ?string,
    +upc: ?string,
    +description: ?string,
    +category: ?{|
      +id: string,
      +name: string,
    |},
    +totalQty: number,
    +quantityUOM: {|
      +id: string,
      +symbol: string,
    |},
    +lotTracking: ?boolean,
    +childItems: {|
      +edges: ?$ReadOnlyArray<?{|
        +usageQty: ?number,
        +node: {|
          +id: string,
          +itemNumber: string,
          +name: string,
          +partNumber: ?string,
          +upc: ?string,
          +totalQty: number,
          +quantityUOM: {|
            +id: string,
            +symbol: string,
          |},
          +category: ?{|
            +id: string,
            +name: string,
          |},
          +lotTracking: ?boolean,
        |},
      |}>
    |},
    +$fragmentRefs: DeleteJobSubComponentModal_item$ref,
  |},
  +$refType: JobSubComponentsTree_jobSubComponentEdges$ref,
|}>;
export type JobSubComponentsTree_jobSubComponentEdges$data = JobSubComponentsTree_jobSubComponentEdges;
export type JobSubComponentsTree_jobSubComponentEdges$key = {
  +$data?: JobSubComponentsTree_jobSubComponentEdges$data,
  +$fragmentRefs: JobSubComponentsTree_jobSubComponentEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "usageQty",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemNumber",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "partNumber",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upc",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "ItemCategory",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v3/*: any*/)
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalQty",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "quantityUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "symbol",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lotTracking",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "JobSubComponentsTree_jobSubComponentEdges",
  "type": "JobSubComponentEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Item",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "childItems",
          "storageKey": null,
          "args": null,
          "concreteType": "ItemChildConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ItemChildEdge",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Item",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v6/*: any*/),
                    (v9/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "DeleteJobSubComponentModal_item",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4592e3781508f6cf50c63d859d3097fa';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import type { RouterHistory } from 'react-router';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import AddUpdateJobSubComponentModal from 'main-app/components/AddUpdateJobSubComponentModal';
import BulkDeductQtyModal from './BulkDeductQtyModal';
import JobSubComponentsTree from './JobSubComponentsTree';
import type { JobSubComponents_job as JobFragment } from './__generated__/JobSubComponents_job';

type Props = {
  history: RouterHistory,
  job: JobFragment,
};

type State = {
  addJobSubComponentModalOpen: boolean,
  bulkDeductQtyModalOpen: boolean,
  components: Array<Object>,
};

const ButtonWrapper = styled.div`
  padding-bottom: 24px;
`;

class JobSubComponents extends PureComponent<Props, State> {
  state = {
    addJobSubComponentModalOpen: false,
    bulkDeductQtyModalOpen: false,
    components: [],
  };

  handleOpenAddJobSubComponentModal = () => {
    this.setState({
      addJobSubComponentModalOpen: true,
    });
  };

  handleOpenBulkDeductQtyModal = () => {
    this.setState({
      bulkDeductQtyModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addJobSubComponentModalOpen: false,
      bulkDeductQtyModalOpen: false,
    });
  };

  setComponents = (components: Array<?Object>) => {
    this.setState({
      components,
    });
  };

  render() {
    const { job, history } = this.props;
    const {
      addJobSubComponentModalOpen,
      bulkDeductQtyModalOpen,
      components,
    } = this.state;

    const excludeIds = (job.subComponents.edges || []).map(
      edge => edge?.node.id,
    );

    return (
      <>
        <ButtonWrapper>
          <Button
            width="auto"
            theme="blue"
            onClick={this.handleOpenAddJobSubComponentModal}
          >
            {i18n.t('Add Components')}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            width="auto"
            theme="red"
            onClick={this.handleOpenBulkDeductQtyModal}
          >
            {i18n.t('Deduct Quantities')}
          </Button>
        </ButtonWrapper>
        <JobSubComponentsTree
          history={history}
          jobId={job.id}
          jobSubComponentEdges={job.subComponents.edges}
          jobYieldEdges={job.yields.edges}
          setComponents={this.setComponents}
        />
        {addJobSubComponentModalOpen && (
          <AddUpdateJobSubComponentModal
            job={job}
            onClose={this.handleCloseAllModals}
            excludeIds={excludeIds}
          />
        )}
        {bulkDeductQtyModalOpen && (
          <BulkDeductQtyModal
            onClose={this.handleCloseAllModals}
            components={components}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(JobSubComponents, {
  job: graphql`
    fragment JobSubComponents_job on Job {
      id
      ...AddUpdateJobSubComponentModal_job
      subComponents(first: null)
        @connection(key: "JobSubComponents_subComponents", filters: []) {
        edges {
          ...JobSubComponentsTree_jobSubComponentEdges
          usageQty
          node {
            id
            name
            partNumber
          }
        }
      }
      yields(first: null)
        @connection(key: "JobSubComponents_job_yields", filters: []) {
        edges {
          ...JobSubComponentsTree_jobYieldEdges
        }
      }
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import type { RouterHistory } from 'react-router';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import OrderStatusPill from 'shared/components/common/Pill/OrderStatusPill';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddUpdateOrderModal from 'main-app/components/AddUpdateOrderModal';
import DeleteOrderModal from 'main-app/components/DeleteOrderModal';
import type { OrdersTable_orderEdges as OrderEdgesFragment } from './__generated__/OrdersTable_orderEdges';

type Props = {
  history: RouterHistory,
  orderEdges: OrderEdgesFragment,
  onCreateNew?: void => void,
  emptyListMessage?: string,
  deleteEnabled?: boolean,
  editEnabled?: boolean,
};

type State = {
  updateOrderRecord: ?Object,
  deleteOrderRecord: ?Object,
};

class OrdersTable extends PureComponent<Props, State> {
  static defaultProps = {
    onCreateNew: false,
    emptyListMessage: null,
    deleteEnabled: false,
    editEnabled: false,
  };

  menuTriggerRefs = {};
  state = {
    updateOrderRecord: null,
    deleteOrderRecord: null,
  };

  getOrderById = (orderId: string) => {
    const { orderEdges } = this.props;

    const order = orderEdges.find(edge => {
      return edge?.node?.id === orderId;
    });

    return order?.node || null;
  };

  handleGoToCustomer = (e, data, target) => {
    const { history } = this.props;
    const orderId = target.getAttribute('order-id');
    const order = this.getOrderById(orderId);

    if (!order) {
      return;
    }

    history.push(`/customer/${order.customer.id}`);
  };

  handleOpenNewTab = (e, data, target) => {
    const orderId = target.getAttribute('order-id');

    window.open(`/order/${orderId}`);
  };

  handleOpenUpdateModal = (e, data, target) => {
    const orderId = target.getAttribute('order-id');

    this.setState({
      updateOrderRecord: this.getOrderById(orderId),
    });
  };

  handleOpenDeleteModal = (e, data, target) => {
    const orderId = target.getAttribute('order-id');

    this.setState({
      deleteOrderRecord: this.getOrderById(orderId),
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateOrderRecord: null,
      deleteOrderRecord: null,
    });
  };

  render() {
    const {
      history,
      orderEdges,
      emptyListMessage,
      onCreateNew,
      deleteEnabled,
      editEnabled,
    } = this.props;
    const { updateOrderRecord, deleteOrderRecord } = this.state;

    const edges = orderEdges ? orderEdges.filter(Boolean).map(a => a.node) : [];

    if (!edges.length) {
      return (
        <EmptyListResults
          graphic="orders"
          message={
            emptyListMessage ||
            i18n.t(
              `Your orders will show here. Create a new order to get started.`,
            )
          }
          onCreateNew={onCreateNew}
          createNewLabel={i18n.t('Create Order')}
        />
      );
    }

    return (
      <>
        <Table
          data={edges}
          onRowClick={rowData => history.push(`/order/${rowData.id}`)}
          columns={[
            {
              Header: i18n.t('Order #'),
              accessor: 'orderNumber',
              width: 160,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value, row }: *) => (
                <Pill onClick={() => history.push(`/order/${row.id}`)}>
                  {value}
                </Pill>
              ),
            },
            {
              Header: i18n.t('Customer'),
              accessor: 'customer.name',
              Cell: ({ value }) => value || '',
            },
            {
              Header: i18n.t(`Customer's Order #`),
              accessor: 'customerOrderNumber',
              width: 160,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value, row }: *) =>
                value && (
                  <Pill onClick={() => history.push(`/order/${row.id}`)}>
                    {value}
                  </Pill>
                ),
            },
            {
              Header: i18n.t('Jobs'),
              accessor: 'totalJobs',
              width: 120,
              Cell: ({ value }) =>
                i18n.t('{{totalJobs}} job(s)', {
                  totalJobs: value || 0,
                }),
            },
            {
              Header: i18n.t('Status'),
              accessor: 'status',
              width: 120,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value }: *) => <OrderStatusPill status={value} />,
            },
            {
              Header: i18n.t('Date Promised'),
              accessor: 'promisedAt',
              width: 120,
              Cell: ({ value }) =>
                value ? moment(value).format('MM/DD/YYYY') : '',
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              Cell: ({ value }: *) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="orders-menu"
                  attributes={{
                    'order-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={(e, data, target) => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(
                          e,
                          data,
                          target,
                        );
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ]}
        />
        <ContextMenu id="orders-menu">
          {editEnabled && (
            <MenuItem onClick={this.handleOpenUpdateModal}>
              {i18n.t('Edit Order')}
            </MenuItem>
          )}
          <MenuItem onClick={this.handleOpenNewTab}>
            {i18n.t('Open In New Tab')}
          </MenuItem>
          <MenuItem onClick={this.handleGoToCustomer}>
            {i18n.t('View Customer Profile')}
          </MenuItem>
          {deleteEnabled && (
            <MenuItem onClick={this.handleOpenDeleteModal}>
              {i18n.t('Delete Order')}
            </MenuItem>
          )}
        </ContextMenu>
        {updateOrderRecord && (
          <AddUpdateOrderModal
            onClose={this.handleCloseAllModals}
            order={updateOrderRecord}
            customer={null}
          />
        )}
        {deleteOrderRecord && (
          <DeleteOrderModal
            history={history}
            order={deleteOrderRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(OrdersTable, {
  orderEdges: graphql`
    fragment OrdersTable_orderEdges on OrderEdge @relay(plural: true) {
      node {
        id
        orderNumber
        customerOrderNumber
        status
        orderedAt
        requiredAt
        promisedAt
        totalJobs
        customer {
          id
          name
        }
        ...AddUpdateOrderModal_order
        ...DeleteOrderModal_order
      }
    }
  `,
});

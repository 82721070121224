// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation AddOrderMutation($input: AddOrderInput!) {
    addOrder(input: $input) {
      orderEdge {
        node {
          id
          orderNumber
          customerOrderNumber
          totalJobs
          status
          shippingAddressLine1
          shippingAddressLine2
          shippingAddressLine3
          shippingAddressCity
          shippingAddressState
          shippingAddressZip
          orderedAt
          requiredAt
          promisedAt
          customer {
            id
            name
          }
          user {
            id
            firstName
            lastName
            ...UserAvatar_user
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: *) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: ROOT_ID,
          connectionInfo: [
            {
              key: 'PaginatedOrdersContainer_orders',
              rangeBehavior: 'prepend',
            },
            {
              key: 'Customer_orders',
              rangeBehavior: 'prepend',
              filters: {
                customerIds: [options.variables.input.customerId],
              },
            },
          ],
          edgeName: 'orderEdge',
        },
      ],
    });
  },
};

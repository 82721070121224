// @flow

import {
  LinkModel,
  LabelModel,
  BaseEvent,
  DiagramEngine,
} from 'storm-react-diagrams';
// import { CustomLabelModel } from '../CustomLabel';

class CustomLinkModel extends LinkModel {
  constructor() {
    super('custom-link');
    this.width = 4;
    this.curvyness = 50;
    this.data = {
      addTags: [],
      removeTags: [],
      defaultUser: {},
    };
    this.removeLabels();
  }

  serialize() {
    return {
      ...super.serialize(),
      width: this.width,
      curvyness: this.curvyness,
      data: this.data,
    };
  }

  deSerialize(ob: CustomLinkModel, engine: DiagramEngine) {
    super.deSerialize(ob, engine);
    this.width = ob.width;
    this.curvyness = ob.curvyness;
    this.data = ob.data;
  }

  addLabel(label: LabelModel | string) {
    this.labels = [];
    // TODO: add this back in when Transitions are ready
    // if (label instanceof LabelModel) {
    //   return super.addLabel(label);
    // }
    // const labelOb = new CustomLabelModel();
    // labelOb.setLabel(label);
    // return super.addLabel(labelOb);
  }

  removeLabels() {
    this.labels = [];
  }

  setWidth(width: number) {
    this.width = width;
    this.iterateListeners((listener: *, event: BaseEvent) => {
      if (listener.widthChanged) {
        listener.widthChanged({ ...event, width: width });
      }
    });
  }

  setColor(color: string) {
    this.color = color;
    this.iterateListeners((listener: *, event: BaseEvent) => {
      if (listener.colorChanged) {
        listener.colorChanged({ ...event, color: color });
      }
    });
  }
}

export default CustomLinkModel;

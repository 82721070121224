/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserAvatar_user$ref = any;
export type Permission = "CREATE_API_KEY" | "CREATE_CUSTOMER" | "CREATE_ITEM" | "CREATE_JOB" | "CREATE_LOT" | "CREATE_ORDER" | "DELETE_API_KEY" | "DELETE_CUSTOMER" | "DELETE_ITEM" | "DELETE_JOB" | "DELETE_LOT" | "DELETE_ORDER" | "READ_API_KEY" | "READ_CUSTOMER" | "READ_ITEM" | "READ_JOB" | "READ_LOT" | "READ_ORDER" | "UPDATE_API_KEY" | "UPDATE_CUSTOMER" | "UPDATE_ITEM" | "UPDATE_JOB" | "UPDATE_LOT" | "UPDATE_ORDER";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Toolbar_viewer$ref: FragmentReference;
declare export opaque type Toolbar_viewer$fragmentType: Toolbar_viewer$ref;
export type Toolbar_viewer = {|
  +id: string,
  +permissions: $ReadOnlyArray<Permission>,
  +company: {|
    +id: string,
    +name: ?string,
  |},
  +companies: $ReadOnlyArray<{|
    +id: string,
    +name: ?string,
  |}>,
  +$fragmentRefs: UserAvatar_user$ref,
  +$refType: Toolbar_viewer$ref,
|};
export type Toolbar_viewer$data = Toolbar_viewer;
export type Toolbar_viewer$key = {
  +$data?: Toolbar_viewer$data,
  +$fragmentRefs: Toolbar_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "Toolbar_viewer",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "company",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "companies",
      "storageKey": null,
      "args": null,
      "concreteType": "Company",
      "plural": true,
      "selections": (v1/*: any*/)
    },
    {
      "kind": "FragmentSpread",
      "name": "UserAvatar_user",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eadcde656b6edcb2e4bae35a61085103';
module.exports = node;

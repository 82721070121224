// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateWorkflowPartialMutation($input: UpdateWorkflowPartialInput!) {
    updateWorkflowPartial(input: $input) {
      workflowEdge {
        cursor
        node {
          id
          name
          isDefault
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

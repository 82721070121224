// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteFormFieldMutation from 'main-app/mutations/DeleteFormField';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteFormFieldModal_formField as FormFieldFragment } from './__generated__/DeleteFormFieldModal_formField';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  formField: FormFieldFragment,
};

type State = {
  deleting: boolean,
};

class DeleteFormFieldModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  static defaultProps = {
    onSuccess: undefined,
  };

  handleDelete = () => {
    const { formField, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteFormFieldMutation.commit({
            variables: {
              input: {
                id: formField.id,
              },
            },
          });

          onClose();

          if (onSuccess) {
            onSuccess();
          }

          Analytics.trackEvent('Delete Form Field', {
            fieldName: formField.name,
          });

          Actions.alertNotification(
            i18n.t(`Form Field Successfully Deleted`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { formField, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete Form Field: "{{name}}"?', {
            name: formField.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            `Are you sure you'd like to delete Form Field "{{name}}"? This could occur in loss of data.`,
            {
              name: formField.name,
            },
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Form Field')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteFormFieldModal, {
  formField: graphql`
    fragment DeleteFormFieldModal_formField on FormField {
      id
      name
    }
  `,
});

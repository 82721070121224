// @flow

import React from 'react';
import type { Match, RouterHistory } from 'react-router';
import { graphql, QueryRenderer } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Diagram from './Diagram';

type Props = {
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
  history: RouterHistory,
};

const Workflow = ({ match, history }: Props) => {
  return (
    <QueryRenderer
      environment={relayEnvironment}
      dataFrom="STORE_THEN_NETWORK"
      variables={{
        id: match.params.id,
      }}
      query={graphql`
        query WorkflowQuery($id: ID!) {
          workflow(id: $id) {
            id
            name
            isDefault
            ...Diagram_workflow
          }
        }
      `}
      render={({ props }: *) => {
        if (!props) {
          return <Loader />;
        }

        const { workflow } = props;

        return <Diagram workflow={workflow} history={history} />;
      }}
    />
  );
};

export default Workflow;

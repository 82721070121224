/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FormBuilder_form$ref = any;
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RunYieldCard_runYield$ref: FragmentReference;
declare export opaque type RunYieldCard_runYield$fragmentType: RunYieldCard_runYield$ref;
export type RunYieldCard_runYield = {|
  +id: string,
  +quantity: number,
  +item: {|
    +id: string,
    +name: string,
    +partNumber: ?string,
    +description: ?string,
    +formFieldValues: any,
    +quantityUOM: {|
      +id: string,
      +symbol: string,
    |},
    +form: {|
      +id: string,
      +fields: $ReadOnlyArray<{|
        +id: string,
        +systemKey: ?string,
        +name: string,
        +type: FormFieldType,
      |}>,
      +$fragmentRefs: FormBuilder_form$ref,
    |},
  |},
  +$refType: RunYieldCard_runYield$ref,
|};
export type RunYieldCard_runYield$data = RunYieldCard_runYield;
export type RunYieldCard_runYield$key = {
  +$data?: RunYieldCard_runYield$data,
  +$fragmentRefs: RunYieldCard_runYield$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "RunYieldCard_runYield",
  "type": "JobYield",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "quantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "item",
      "storageKey": null,
      "args": null,
      "concreteType": "Item",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "partNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "formFieldValues",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "quantityUOM",
          "storageKey": null,
          "args": null,
          "concreteType": "UOM",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "symbol",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "form",
          "storageKey": null,
          "args": null,
          "concreteType": "Form",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "fields",
              "storageKey": null,
              "args": null,
              "concreteType": "FormField",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "systemKey",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "FormBuilder_form",
              "args": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf1dccb0b1b687bedda81587bda84198';
module.exports = node;

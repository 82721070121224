// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { Formik, FieldGroup } from 'shared/components/form';
import Button from 'shared/components/common/Button';
import {
  DesktopWrapper,
  DesktopFormWrapper,
  DesktopFormSeparator,
  DesktopButtonWrapper,
} from './styled';
import FilterValueInput from './FilterValueInput';
import type { FilterOption } from '..';

type Props = {
  filterOptions: Array<FilterOption>,
  onApplyFilters: (Array<Object>) => void,
  onClose: () => void,
};

class ApplyFilterDesktopPanel extends PureComponent<Props> {
  ref = null;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickAway);
    document.addEventListener('touchend', this.handleClickAway);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickAway);
    document.removeEventListener('touchend', this.handleClickAway);
  }

  handleClickAway = (e: Event) => {
    const { onClose } = this.props;

    if (this.ref && !this.ref.contains(e.target)) {
      onClose();
    }
  };

  handleSubmit = ({ filterName, filterValue }: Object) => {
    const { onApplyFilters } = this.props;

    if (!filterName?.value || !filterValue) {
      return;
    }

    onApplyFilters([
      {
        name: filterName.value,
        value: filterValue?.value || filterValue,
      },
    ]);
  };

  render() {
    const { filterOptions } = this.props;

    return (
      <Formik
        initialValues={{
          filterName: {
            value: filterOptions[0].name,
          },
          filterValue: '',
        }}
        onSubmit={this.handleSubmit}
        render={({ values, errors, handleSubmit, setFieldValue }) => (
          <DesktopWrapper ref={r => (this.ref = r)}>
            <DesktopFormWrapper>
              <FieldGroup
                type="select"
                name="filterName"
                options={filterOptions.map(a => ({
                  label: a.label,
                  value: a.name,
                }))}
                onChange={() => {
                  setFieldValue('filterValue', '');
                }}
              />
              <DesktopFormSeparator>{i18n.t('Is')}</DesktopFormSeparator>
              <FilterValueInput
                filterName={values.filterName}
                filterOptions={filterOptions}
              />
            </DesktopFormWrapper>
            <DesktopButtonWrapper>
              <Button width={120} onClick={handleSubmit}>
                {i18n.t('Apply')}
              </Button>
            </DesktopButtonWrapper>
          </DesktopWrapper>
        )}
      />
    );
  }
}

export default ApplyFilterDesktopPanel;

// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddFormFieldMutation from 'main-app/mutations/AddFormField';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  formId: string,
};

const AddFormFieldModal = ({ formId, onClose, onSuccess }: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        name: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        const input: any = {
          formId: formId,
          name: values.name,
          type: 'TEXT', // always default to text
        };

        try {
          await AddFormFieldMutation.commit({
            variables: {
              input,
            },
          });

          onClose();

          Analytics.trackEvent('Create Form Field', {
            fieldName: input.name,
          });

          Actions.alertNotification(
            i18n.t('Form Field Successfully Added'),
            'success',
          );

          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader header={i18n.t('Create Form Field')} onClose={onClose} />
          <ModalBody withPadding>
            <FieldGroup
              name="name"
              label={i18n.t('Field Name')}
              placeholder={i18n.t('Field Name')}
              error={errors.name}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t('Create Form Field')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

AddFormFieldModal.defaultProps = {
  onSuccess: undefined,
};

export default AddFormFieldModal;

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteItemMutation from 'main-app/mutations/DeleteItem';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteItemModal_item as ItemFragment } from './__generated__/DeleteItemModal_item';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  item: ItemFragment,
};

type State = {
  deleting: boolean,
};

class DeleteItemModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  static defaultProps = {
    onSuccess: undefined,
  };

  handleDelete = () => {
    const { item, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteItemMutation.commit({
            variables: {
              input: {
                id: item.id,
              },
            },
          });

          onClose();

          if (onSuccess) {
            onSuccess();
          }

          Analytics.trackEvent('Delete Inventory', {
            inventoryName: item.name,
          });
          Actions.alertNotification(
            i18n.t(`Inventory Successfully Deleted`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { item, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete From Inventory: {{name}}?', {
            name: item.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            'You will not be able to access this inventory item upon deletion.',
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Item')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteItemModal, {
  item: graphql`
    fragment DeleteItemModal_item on Item {
      id
      name
    }
  `,
});

// @flow

import { graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      id: string,
    },
  },
  context: {
    orderId: string,
  },
};

const mutation = graphql`
  mutation DeleteOrderYieldMutation($input: DeleteOrderYieldInput!) {
    deleteOrderYield(input: $input) {
      deletedId
    }
  }
`;

export default {
  commit: ({ context, ...options }: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      updater: store => {
        const root = store
          .getRoot()
          .getLinkedRecord('order', { id: context.orderId });

        const conn1 = ConnectionHandler.getConnection(
          root,
          'OrderDetails_order_yields',
        );

        const conn2 = ConnectionHandler.getConnection(root, 'Order_yields');

        if (conn1) {
          const edges = conn1
            .getLinkedRecords('edges')
            .filter(
              edge =>
                edge.getLinkedRecord('node')._dataID !==
                options.variables.input.id,
            );

          conn1.setLinkedRecords(edges, 'edges');
        }

        if (conn2) {
          const edges = conn2
            .getLinkedRecords('edges')
            .filter(
              edge =>
                edge.getLinkedRecord('node')._dataID !==
                options.variables.input.id,
            );

          conn2.setLinkedRecords(edges, 'edges');
        }
      },
    });
  },
};

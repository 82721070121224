/* eslint-disable react/no-array-index-key */

// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import Icon from './Icon';

type Props = {
  children: React$Node,
  title?: string,
  headerLeft?: React$Node | Array<React$Node>,
  headerRight?: React$Node,
  headerBottom?: React$Node,
  style?: Object,
  headerStyle?: Object,
  iconType: string,
  notes?: ?string,
};

const Wrapper = styled.div`
  border-radius: 5px;
  border: 1px solid ${colors.paleGrey};
  background-color: ${colors.white};
  padding: 16px 16px 24px 16px;
  margin-bottom: 16px;
  word-break: break-word;
`;

const Header = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const HeaderTop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.div`
  ${fonts.largeBold};
  color: ${colors.charcoalGrey};
  display: flex;
  flex: 1;
  align-items: center;
`;

const LeftCell = styled.div`
  padding-right: 16px;
`;

const RightCell = styled.div`
  padding-left: 16px;
`;

const Notes = styled.div`
  border-top: 2px solid ${colors.paleGrey};
  padding-top: 16px;
  color: ${colors.charcoalGrey};
`;

const Transaction = styled.div`
  padding-bottom: 16px;
  color: ${colors.charcoalGrey};
`;

const Date = styled.div`
  color: ${colors.battleshipGrey};
`;

const TransactionRecord = ({
  children,
  headerLeft,
  headerRight,
  headerBottom,
  title,
  style,
  headerStyle,
  iconType,
  notes,
}: Props) => {
  const hasHeader = headerLeft || title || headerRight || headerBottom;

  return (
    <Wrapper style={style}>
      {hasHeader && (
        <Header style={headerStyle}>
          <HeaderTop>
            {headerLeft
              ? Array.isArray(headerLeft)
                ? headerLeft.map((a, i) => <LeftCell key={i}>{a}</LeftCell>)
                : headerLeft
              : null}
            <Title>
              <Icon type={iconType} size={32} />
              <RightCell>{title}</RightCell>
            </Title>
            {headerRight && <Date>{headerRight}</Date>}
          </HeaderTop>
        </Header>
      )}
      <Transaction>{children}</Transaction>
      {notes && (
        <Notes>
          <Title>Note</Title>
          {notes}
        </Notes>
      )}
    </Wrapper>
  );
};

TransactionRecord.defaultProps = {
  title: undefined,
  headerLeft: undefined,
  headerRight: undefined,
  headerBottom: undefined,
  style: {},
  headerStyle: {},
  notes: undefined,
};

export default TransactionRecord;

// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { i18n, nullUndefinedCheck, Analytics } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import * as Actions from 'main-app/store/Actions';
import AddOrderYieldMutation from 'main-app/mutations/AddOrderYield';
import AddJobYieldMutation from 'main-app/mutations/AddJobYield';
import AddItemMutation from 'main-app/mutations/AddItem';
import UpdateItemMutation from 'main-app/mutations/UpdateItem';
import AddItemCategoryMutation from 'main-app/mutations/AddItemCategory';
import Button from 'shared/components/common/Button';
import InfoTooltip from 'shared/components/common/InfoTooltip';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldLabel,
  FieldGroupRow,
  SelectUOMField,
  SelectItemCategoryField,
} from 'shared/components/form';
import {
  computeFieldValues,
  computeValidationSchema,
  createNewFieldOptions,
  DynamicFormField,
  renderAdditionalFieldInfo,
} from 'shared/components/form/FormBuilder';
import type { AddUpdateItemModal_item as ItemFragment } from './__generated__/AddUpdateItemModal_item';
import type { AddUpdateItemModal_defaultForm as DefaultFormFragment } from './__generated__/AddUpdateItemModal_defaultForm';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  onReopen?: () => void,
  item: ItemFragment,
  defaultForm: DefaultFormFragment,
  jobId?: ?string,
  orderId?: ?string,
  createAnotherItem?: boolean,
  workflowHasGangRun?: boolean,
};

const LANGUAGE = {
  ITEM: {
    nameFieldLabel: i18n.t('Item Name'),
    createAnotherToggleLabel: i18n.t('Create Another Item'),
    update: {
      title: i18n.t('Edit Item'),
      submitButton: i18n.t('Update Item'),
      successMessage: i18n.t('Item Successfully Updated'),
    },
    add: {
      title: i18n.t('Create Item'),
      submitButton: i18n.t('Create Item'),
      successMessage: i18n.t('Item Successfully Created'),
    },
  },
  ORDER: {
    nameFieldLabel: i18n.t('Item Name'),
    createAnotherToggleLabel: i18n.t('Create Another Item'),
    update: {},
    add: {
      title: i18n.t('Create and Add Item'),
      submitButton: i18n.t('Create and Add Item'),
      successMessage: i18n.t('Item Successfully Created and Added to Order'),
    },
  },
  JOB: {
    nameFieldLabel: i18n.t('Item Name'),
    createAnotherToggleLabel: i18n.t('Create Another Item'),
    update: {},
    add: {
      title: i18n.t('Create and Add Item'),
      submitButton: i18n.t('Create and Add Item'),
      successMessage: i18n.t('Item Successfully Created and Added to Job'),
    },
  },
};

const FieldRow = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media (min-width: 1000px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    li {
      width: ${p => p.itemWidth};
      padding-right: 8px;

      &:last-child {
        padding-right: 0;
      }
    }
  }
`;

const Divider = styled.div`
  border-top: 2px solid ${colors.lightBlueGrey};
  padding-bottom: 16px;
`;

const LotTrackingLabel = styled.div`
  padding-right: 8px;
`;

class AddUpdateItemModal extends PureComponent<Props> {
  form: Object;
  customFields: any;
  initialValues: Object;
  validationSchema: Object;
  yieldValidationSchema: Object;
  yieldInitialValues: Object;

  static defaultProps = {
    onSuccess: undefined,
    onReopen: undefined,
    jobId: undefined,
    orderId: undefined,
    createAnotherItem: false,
    workflowHasGangRun: false,
  };

  constructor(props: Props) {
    super(props);

    const { item, jobId, orderId, createAnotherItem } = this.props;

    this.form = props.item?.form || props.defaultForm;
    this.customFields = !this.form ? [] : this.form.fields;
    this.yieldValidationSchema = {};
    this.yieldInitialValues = {};

    if (jobId) {
      this.yieldValidationSchema = {
        jobYieldQuantity: Yup.number().required('Required'),
        jobYieldItemNumberUp: Yup.number().required('Required'),
        jobYieldOverUnderRule: Yup.object().nullable(),
        jobYieldOverQuantity: Yup.number(),
        jobYieldBrokerCustomerOrderNumber: Yup.string(),
      };

      this.yieldInitialValues = {
        jobYieldQuantity: '',
        jobYieldItemNumberUp: '1',
        jobYieldOverUnderRule: null,
        jobYieldOverQuantity: '',
        jobYieldBrokerCustomerOrderNumber: '',
      };
    }

    if (orderId) {
      this.yieldValidationSchema['orderedQuantity'] = Yup.number().required(
        'Required',
      );
      this.yieldInitialValues['orderedQuantity'] = '';
    }

    this.validationSchema = computeValidationSchema(this.customFields, {
      name: Yup.string().required('Required'),
      upc: Yup.string(),
      partNumber: Yup.string(),
      itemCategory: Yup.object().nullable(),
      description: Yup.string(),
      quantityUOM: Yup.object()
        .nullable()
        .required('Required'),
      dimensionUOM: Yup.object().nullable(),
      dimensionLength: Yup.number(),
      dimensionWidth: Yup.number(),
      dimensionHeight: Yup.number(),
      weightUOM: Yup.object().nullable(),
      weight: Yup.number(),
      createAnotherItem: Yup.boolean(),
      lotTracking: Yup.boolean(),
      cost: Yup.number(),
      ...this.yieldValidationSchema,
    });

    const initialCustomFieldValues = computeFieldValues(
      this.form.fields,
      item?.formFieldValues,
      true,
    );

    this.initialValues = {
      name: item?.name || '',
      upc: item?.upc || '',
      partNumber: item?.partNumber || '',
      itemCategory: item?.category ? { value: item?.category?.id } : null,
      description: item?.description || '',
      quantityUOM: item?.quantityUOM && {
        value: item.quantityUOM.id,
        data: item.quantityUOM,
      },
      dimensionUOM: item?.dimensionUOM && {
        value: item.dimensionUOM?.id,
        data: item.dimensionUOM,
      },
      weightUOM: item?.weightUOM && {
        value: item.weightUOM?.id,
        data: item.weightUOM,
      },
      dimensionLength: nullUndefinedCheck(item?.dimensionLength, ''),
      dimensionWidth: nullUndefinedCheck(item?.dimensionWidth, ''),
      dimensionHeight: nullUndefinedCheck(item?.dimensionHeight, ''),
      weight: nullUndefinedCheck(item?.weight, ''),
      lotTracking: item?.lotTracking || false,
      cost: item?.cost || '',
      safetyQuantity: item?.safetyQuantity || '',
      createAnotherItem,
      ...this.yieldInitialValues,
      ...initialCustomFieldValues,
    };
  }

  render() {
    const {
      onClose,
      onSuccess,
      onReopen,
      item,
      jobId,
      orderId,
      workflowHasGangRun,
    } = this.props;

    const language = (function() {
      if (jobId) {
        return LANGUAGE['JOB']['add'];
      } else if (orderId) {
        return LANGUAGE['ORDER']['add'];
      }

      return LANGUAGE.ITEM[item ? 'update' : 'add'];
    })();

    return (
      <Modal maxWidth={700}>
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.validationSchema}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const mutation = item ? UpdateItemMutation : AddItemMutation;

            const {
              name,
              upc,
              partNumber,
              itemCategory,
              description,
              quantityUOM,
              dimensionUOM,
              dimensionLength,
              dimensionWidth,
              dimensionHeight,
              weightUOM,
              weight,
              createAnotherItem,
              orderedQuantity,
              jobYieldQuantity,
              jobYieldItemNumberUp,
              jobYieldOverUnderRule,
              jobYieldOverQuantity,
              jobYieldBrokerCustomerOrderNumber,
              lotTracking,
              cost,
              safetyQuantity,
              ...customFieldValues
            } = values;

            const input: any = {
              name: name,
              upc: upc,
              partNumber: partNumber,
              itemCategoryId: itemCategory?.value || null,
              description: description,
              quantityUomId: quantityUOM.value,
              dimensionUomId: dimensionUOM?.value || null,
              dimensionLength: dimensionLength
                ? parseFloat(dimensionLength)
                : null,
              dimensionWidth: dimensionWidth
                ? parseFloat(dimensionWidth)
                : null,
              dimensionHeight: dimensionHeight
                ? parseFloat(dimensionHeight)
                : null,
              weightUomId: weightUOM?.value || null,
              weight: weight ? parseFloat(weight) : null,
              lotTracking: lotTracking,
              cost: cost ? parseFloat(cost) : null,
              safetyQuantity: safetyQuantity
                ? parseFloat(safetyQuantity)
                : null,
              formFieldValues: computeFieldValues(
                this.form.fields,
                customFieldValues,
              ),
            };

            if (item) {
              input.id = item.id;
              delete input.type;
            }

            try {
              if (this.form) {
                await createNewFieldOptions(this.form.fields, values);
              }

              if (values.itemCategory && values.itemCategory.__isNew__) {
                const {
                  addItemCategory,
                } = await AddItemCategoryMutation.commit({
                  variables: {
                    input: {
                      name: values.itemCategory?.value,
                    },
                  },
                });

                input.itemCategoryId = addItemCategory.itemCategoryEdge.node.id;
              }

              const response = await mutation.commit({
                variables: {
                  input,
                },
              });

              const itemResponse = item
                ? response.updateItem.itemEdge.node
                : response.addItem.itemEdge.node;

              if (orderId) {
                const addOrderYieldInput: any = {
                  orderId,
                  itemId: itemResponse.id,
                  orderedQuantity: parseFloat(orderedQuantity),
                };
                AddOrderYieldMutation.commit({
                  variables: {
                    input: addOrderYieldInput,
                  },
                });
              }

              if (jobId) {
                const addJobYieldInput: any = {
                  jobId,
                  itemId: itemResponse.id,
                  quantity: parseFloat(jobYieldQuantity),
                  itemNumberUp: parseFloat(jobYieldItemNumberUp),
                  overUnderRule: jobYieldOverUnderRule?.value || null,
                  overQuantity:
                    jobYieldOverUnderRule?.value &&
                    jobYieldOverUnderRule?.value !== 'NO_OVERS' &&
                    jobYieldOverQuantity
                      ? parseFloat(jobYieldOverQuantity)
                      : null,
                  brokerCustomerOrderNumber: jobYieldBrokerCustomerOrderNumber,
                };
                AddJobYieldMutation.commit({
                  variables: {
                    input: addJobYieldInput,
                  },
                });
              }

              if (createAnotherItem && onReopen) {
                onReopen();
              } else {
                onClose();
                if (onSuccess) {
                  onSuccess(itemResponse);
                }
              }

              Analytics.trackEvent(`${item ? 'Update' : 'Create'} Inventory`, {
                inventoryName: values.name,
                category: values.itemCategory?.value || null,
                unitOfMeasurement: values.quantityUOM?.data?.name,
              });
              Actions.alertNotification(language.successMessage, 'success');
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(e.message, 'Something Went Wrong');
            }
          }}
          render={({
            errors,
            values,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => {
            const formFieldValues = computeFieldValues(
              this.form.fields,
              values,
            );

            const lotTrackingLabel = !item
              ? i18n.t('Enable Lot Tracking')
              : item?.lotTracking
              ? i18n.t('Lot Tracking Enabled')
              : i18n.t('Lot Tracking Disabled');

            return (
              <Form>
                <ModalHeader header={language.title} onClose={onClose} />
                <ModalBody withPadding>
                  <FieldGroup
                    label={LANGUAGE.ITEM.nameFieldLabel}
                    name="name"
                    placeholder={i18n.t('Enter a name')}
                    error={errors.name}
                  />
                  <FieldGroupRow
                    left={
                      <FieldGroup
                        label={i18n.t('UPC')}
                        name="upc"
                        placeholder={i18n.t('UPC')}
                        error={errors.upc}
                      />
                    }
                    right={
                      <FieldGroup
                        label={i18n.t('Part #')}
                        name="partNumber"
                        placeholder={i18n.t('Part #')}
                        error={errors.partNumber}
                      />
                    }
                  />
                  <FieldGroupRow
                    left={
                      <SelectItemCategoryField
                        name="itemCategory"
                        error={errors.itemCategory}
                        creatable
                        clearable
                        creatingNewRecord={
                          values.itemCategory && values.itemCategory.__isNew__
                        }
                      />
                    }
                    right={
                      <FieldGroup
                        label={i18n.t('Cost Per Item')}
                        name="cost"
                        type="number"
                        placeholder={i18n.t('Cost Per Item')}
                        error={errors.cost}
                      />
                    }
                  />

                  <FieldGroup
                    label={i18n.t('Description')}
                    name="description"
                    placeholder={i18n.t('Enter Description')}
                    error={errors.description}
                  />
                  <SelectUOMField
                    label={i18n.t('Stock Unit of Measurement')}
                    description={i18n.t(
                      `How the item's stock is measured in your inventory.`,
                    )}
                    name="quantityUOM"
                    error={errors.quantityUOM}
                  />
                  <FieldGroup
                    label={i18n.t('Safety Quantity')}
                    name="safetyQuantity"
                    type="number"
                    placeholder={i18n.t('Safety Quantity')}
                    error={errors.safetyQuantity}
                  />

                  {values.quantityUOM?.data?.type === 'QUANTITY' ||
                  values.quantityUOM?.data?.type === 'WEIGHT' ? (
                    <>
                      <FieldLabel>
                        {i18n.t('Unit Dimensions (W x L x H)')}
                      </FieldLabel>
                      <FieldRow itemWidth="25%">
                        <li>
                          <SelectUOMField
                            label={null}
                            placeholder={i18n.t('Measurement')}
                            name="dimensionUOM"
                            error={errors.dimensionUOM}
                            queryVariables={{
                              type: 'LENGTH',
                            }}
                          />
                        </li>
                        <li>
                          <FieldGroup
                            name="dimensionWidth"
                            error={errors.dimensionWidth}
                            type="number"
                            placeholder="0"
                            extendedLabel={values.dimensionUOM?.data?.symbol}
                          />
                        </li>
                        <li>
                          <FieldGroup
                            name="dimensionLength"
                            error={errors.dimensionLength}
                            type="number"
                            placeholder="0"
                            extendedLabel={values.dimensionUOM?.data?.symbol}
                          />
                        </li>
                        <li>
                          <FieldGroup
                            name="dimensionHeight"
                            error={errors.dimensionHeight}
                            type="number"
                            placeholder="0"
                            extendedLabel={values.dimensionUOM?.data?.symbol}
                          />
                        </li>
                      </FieldRow>
                    </>
                  ) : null}
                  {values.quantityUOM?.data?.type === 'QUANTITY' ||
                  values.quantityUOM?.data?.type === 'LENGTH' ? (
                    <>
                      <FieldLabel>{i18n.t('Unit Weight')}</FieldLabel>
                      <FieldRow itemWidth="50%">
                        <li>
                          <SelectUOMField
                            label={null}
                            placeholder={i18n.t('Measurement')}
                            name="weightUOM"
                            type="number"
                            error={errors.weightUOM}
                            queryVariables={{
                              type: 'WEIGHT',
                            }}
                          />
                        </li>
                        <li>
                          <FieldGroup
                            name="weight"
                            error={errors.weight}
                            type="number"
                            placeholder="0"
                            extendedLabel={values.weightUOM?.data?.symbol}
                          />
                        </li>
                      </FieldRow>
                    </>
                  ) : null}
                  {this.customFields.map(formField => (
                    <React.Fragment key={formField.id}>
                      <DynamicFormField
                        key={formField.id}
                        form={this.form}
                        formField={formField}
                        creatingNewRecord={
                          values[formField.id] &&
                          values[formField.id]?.__isNew__
                        }
                        error={errors ? errors[formField.id] : null}
                      />
                      {renderAdditionalFieldInfo({
                        formFieldType: formField.type,
                        value: formFieldValues[formField.id],
                        props: {
                          style: {
                            marginBottom: 24,
                            marginTop: -8,
                          },
                        },
                      })}
                    </React.Fragment>
                  ))}
                  {orderId && (
                    <>
                      <Divider />
                      <FieldGroupRow
                        left={
                          <FieldGroup
                            label={i18n.t('Quantity Ordered')}
                            name="orderedQuantity"
                            type="number"
                            error={errors.orderedQuantity}
                            extendedLabel={values.quantityUOM?.data?.symbol}
                            placeholder={i18n.t('#')}
                          />
                        }
                        right={<></>}
                      />
                    </>
                  )}
                  {jobId && (
                    <>
                      <Divider />
                      <FieldGroupRow
                        left={
                          <FieldGroup
                            label={i18n.t('Quantity to Produce')}
                            name="jobYieldQuantity"
                            type="number"
                            error={errors.jobYieldQuantity}
                            extendedLabel={values.quantityUOM?.data?.symbol}
                            placeholder={i18n.t('#')}
                          />
                        }
                        right={
                          workflowHasGangRun && (
                            <FieldGroup
                              label={i18n.t('# Up')}
                              name="jobYieldItemNumberUp"
                              type="number"
                              error={errors.jobYieldItemNumberUp}
                              placeholder={i18n.t('#')}
                            />
                          )
                        }
                      />
                      <FieldGroupRow
                        left={
                          <FieldGroup
                            name="jobYieldOverUnderRule"
                            label={i18n.t('Allow Overs / Unders?')}
                            placeholder={i18n.t('Select one')}
                            type="select"
                            options={[
                              {
                                label: 'No Overs',
                                value: 'NO_OVERS',
                              },
                              {
                                label: 'No Unders',
                                value: 'NO_UNDERS',
                              },
                              {
                                label: 'Allow Overs',
                                value: 'ALLOW_OVERS',
                              },
                            ]}
                            error={errors.jobYieldOverUnderRule}
                          />
                        }
                        right={
                          values.jobYieldOverUnderRule?.value === 'NO_UNDERS' ||
                          values.jobYieldOverUnderRule?.value ===
                            'ALLOW_OVERS' ? (
                            <FieldGroup
                              name="jobYieldOverQuantity"
                              label={i18n.t('Overs Quantity')}
                              placeholder={i18n.t('#')}
                              type="number"
                              error={errors.jobYieldOverQuantity}
                              extendedLabel={
                                values.item?.data?.quantityUOM.symbol
                              }
                            />
                          ) : (
                            <div />
                          )
                        }
                      />
                      <FieldGroup
                        name="jobYieldBrokerCustomerOrderNumber"
                        label={i18n.t('Broker PO #')}
                        placeholder={i18n.t('Order #')}
                        error={errors.jobYieldBrokerCustomerOrderNumber}
                      />
                    </>
                  )}
                  <FieldGroup
                    label={
                      <>
                        <LotTrackingLabel>{lotTrackingLabel}</LotTrackingLabel>
                        <InfoTooltip
                          id="lot-tracking-tooltip"
                          content={i18n.t(
                            'Once lot tracking is enabled or disabled, this setting cannot be changed in the future.',
                          )}
                        />
                      </>
                    }
                    name="lotTracking"
                    error={errors.lotTracking}
                    sideLabel="right"
                    type="toggle"
                    disabled={Boolean(item)}
                  />
                </ModalBody>
                <ModalFooter
                  style={{
                    alignItems: 'center',
                  }}
                >
                  {!item && (
                    <FieldGroup
                      label={LANGUAGE.ITEM.createAnotherToggleLabel}
                      sideLabel="right"
                      name="createAnotherItem"
                      error={errors.createAnotherItem}
                      type="toggle"
                    />
                  )}
                  <Button
                    type="submit"
                    theme="blue"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {language.submitButton}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(AddUpdateItemModal, {
  item: graphql`
    fragment AddUpdateItemModal_item on Item {
      id
      name
      upc
      partNumber
      description
      category {
        id
        name
      }
      quantityUOM {
        id
        name
        type
        symbol
      }
      weightUOM {
        id
        name
        type
        symbol
      }
      dimensionUOM {
        id
        name
        type
        symbol
      }
      form {
        id
        ...FormBuilder_form @relay(mask: false)
        fields {
          id
          options {
            id
            name
            value
          }
          ...AdditionalFieldInfo_formField
        }
      }
      formFieldValues
      dimensionLength
      dimensionWidth
      dimensionHeight
      weight
      lotTracking
      cost
      safetyQuantity
      totalValue
    }
  `,
  defaultForm: graphql`
    fragment AddUpdateItemModal_defaultForm on Form {
      id
      type
      ...FormBuilder_form @relay(mask: false)
    }
  `,
});

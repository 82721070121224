/**
 * @flow
 * @relayHash 75d003dd077768d07f7d6c233e2a4e24
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobSubComponentsTreeItemQueryVariables = {|
  id: string
|};
export type JobSubComponentsTreeItemQueryResponse = {|
  +item: {|
    +id: string,
    +name: string,
    +itemNumber: string,
    +partNumber: ?string,
    +upc: ?string,
    +totalQty: number,
    +quantityUOM: {|
      +id: string,
      +symbol: string,
    |},
    +category: ?{|
      +id: string,
      +name: string,
    |},
    +lotTracking: ?boolean,
    +childItems: {|
      +edges: ?$ReadOnlyArray<?{|
        +usageQty: ?number,
        +node: {|
          +id: string,
          +itemNumber: string,
          +name: string,
          +partNumber: ?string,
          +upc: ?string,
          +totalQty: number,
          +quantityUOM: {|
            +id: string,
            +symbol: string,
          |},
          +category: ?{|
            +id: string,
            +name: string,
          |},
          +lotTracking: ?boolean,
        |},
      |}>
    |},
  |}
|};
export type JobSubComponentsTreeItemQuery = {|
  variables: JobSubComponentsTreeItemQueryVariables,
  response: JobSubComponentsTreeItemQueryResponse,
|};
*/


/*
query JobSubComponentsTreeItemQuery(
  $id: ID!
) {
  item(id: $id) {
    id
    name
    itemNumber
    partNumber
    upc
    totalQty
    quantityUOM {
      id
      symbol
    }
    category {
      id
      name
    }
    lotTracking
    childItems {
      edges {
        usageQty
        node {
          id
          itemNumber
          name
          partNumber
          upc
          totalQty
          quantityUOM {
            id
            symbol
          }
          category {
            id
            name
          }
          lotTracking
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemNumber",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "partNumber",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upc",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalQty",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "quantityUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "symbol",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "ItemCategory",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lotTracking",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "ItemChildEdge",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "usageQty",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Item",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JobSubComponentsTreeItemQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "item",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Item",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "childItems",
            "name": "__Item_childItems_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemChildConnection",
            "plural": false,
            "selections": (v11/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "JobSubComponentsTreeItemQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "item",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Item",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "childItems",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemChildConnection",
            "plural": false,
            "selections": (v11/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "childItems",
            "args": null,
            "handle": "connection",
            "key": "Item_childItems",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "JobSubComponentsTreeItemQuery",
    "id": null,
    "text": "query JobSubComponentsTreeItemQuery(\n  $id: ID!\n) {\n  item(id: $id) {\n    id\n    name\n    itemNumber\n    partNumber\n    upc\n    totalQty\n    quantityUOM {\n      id\n      symbol\n    }\n    category {\n      id\n      name\n    }\n    lotTracking\n    childItems {\n      edges {\n        usageQty\n        node {\n          id\n          itemNumber\n          name\n          partNumber\n          upc\n          totalQty\n          quantityUOM {\n            id\n            symbol\n          }\n          category {\n            id\n            name\n          }\n          lotTracking\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "item",
            "childItems"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'df465579de85ce3fd46ecf4940d26866';
module.exports = node;

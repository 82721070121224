// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import styled from 'styled-components';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import {
  Formik,
  Form,
  FieldGroup,
  FormAutoSaver,
} from 'shared/components/form';
import Loader from 'shared/components/common/Loader';
import Divider from 'shared/components/common/Divider';
import { fonts, colors } from 'shared/styleguide';
import { Link } from 'react-router-dom';
import UserAvatar from 'shared/components/common/UserAvatar';
import UpdateViewerAvatarModal from 'main-app/components/UpdateViewerAvatarModal';
import UpdateViewerPasswordModal from 'main-app/components/UpdateViewerPasswordModal';
import UpdateViewerMutation from 'main-app/mutations/UpdateViewer';
import * as Actions from 'main-app/store/Actions';

type Props = {};

type State = {
  updateViewerAvatarOpen: boolean,
  updateViewerPasswordOpen: boolean,
};

const DividerWrapper = styled(Divider)`
  width: 300px;
  height: 2px;
  border-radius: 1px;
  background-color: ${colors.lightBlueGrey};
  margin-bottom: 32px;
`;

const RoleWrapper = styled.div`
  span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.charcoalGrey};
  }

  span:first-of-type {
    margin-bottom: 16px;
  }

  span:last-of-type {
    height: 21px;
    font-size: 16px;
  }
`;

const LinkUpdateAvatarWrapper = styled.div`
  margin: 16px 0 24px 0;
`;

const LinkUpdatePasswordWrapper = styled.span`
  margin: 0 0 32px 0;
`;

const SpanText = styled.span`
  font-size: 14px;
  display: inline-block;
`;

const PrivacyPolicyLink = styled.a`
  display: block;
  width: 150px;
  padding: 48px 0;
  ${fonts.smallRegular};
`;

class Profile extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      updateViewerAvatarOpen: false,
      updateViewerPasswordOpen: false,
    };
  }

  handleUpdateViewerPasswordOpenModal = () => {
    this.setState({ updateViewerPasswordOpen: true });
  };

  handleUpdateViewerAvatarOpenModal = () => {
    this.setState({ updateViewerAvatarOpen: true });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateViewerAvatarOpen: false,
      updateViewerPasswordOpen: false,
    });
  };

  render() {
    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        variables={null}
        query={graphql`
          query ProfileQuery {
            viewer {
              id
              avatar
              firstName
              lastName
              email
              role
              ...UserAvatar_user
              ...UpdateViewerAvatarModal_user
            }
          }
        `}
        render={query => {
          if (!query.props) {
            return <Loader />;
          }
          const currentUser = query.props.viewer;
          const {
            updateViewerAvatarOpen,
            updateViewerPasswordOpen,
          } = this.state;

          return (
            <>
              <UserAvatar width={60} user={currentUser} />
              <LinkUpdateAvatarWrapper>
                <Link to="#" onClick={this.handleUpdateViewerAvatarOpenModal}>
                  <SpanText>{i18n.t('Update Avatar')}</SpanText>
                </Link>
              </LinkUpdateAvatarWrapper>
              <Formik
                initialValues={{
                  firstName: currentUser.firstName,
                  lastName: currentUser.lastName,
                  email: currentUser.email,
                  password: '',
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string()
                    .trim()
                    .required('Required'),
                  lastName: Yup.string()
                    .trim()
                    .required('Required'),
                  email: Yup.string()
                    .email('Please Enter A Valid Email')
                    .required('Required'),
                })}
                onSubmit={async (values: *, { setSubmitting }: *) => {}}
                render={({
                  values,
                  errors,
                  isValid,
                  isSubmitting,
                  handleSubmit,
                  setFieldValue,
                  handleBlur,
                  validateForm,
                }) => {
                  return (
                    <>
                      <FormAutoSaver
                        onSave={async values => {
                          if (!isValid) {
                            return;
                          }

                          try {
                            await UpdateViewerMutation.commit({
                              variables: {
                                input: {
                                  firstName: values.firstName.trim(),
                                  lastName: values.lastName.trim(),
                                  email: values.email.trim(),
                                },
                              },
                            });
                            Analytics.trackEvent('Update Profile', {
                              firstName: values.firstName.trim(),
                              lastName: values.lastName.trim(),
                              email: values.email.trim(),
                            });
                            Actions.alertNotification(
                              i18n.t('Your Profile Has Been Updated!'),
                              'success',
                            );
                          } catch (e) {
                            Actions.alertNotification(e.message, 'error');
                          }
                        }}
                      />
                      <Form>
                        <FieldGroup
                          name="firstName"
                          label={i18n.t('First Name')}
                          size="small"
                          error={errors.firstName}
                        />
                        <FieldGroup
                          name="lastName"
                          label={i18n.t('Last Name')}
                          size="small"
                          error={errors.lastName}
                        />
                        <FieldGroup
                          name="email"
                          label={i18n.t('Email')}
                          size="small"
                          error={errors.email}
                        />
                        <FieldGroup
                          name="password"
                          type="password"
                          label={i18n.t('Password')}
                          size="small"
                          disabled
                        />
                      </Form>
                    </>
                  );
                }}
              />
              <LinkUpdatePasswordWrapper>
                <Link to="#" onClick={this.handleUpdateViewerPasswordOpenModal}>
                  <SpanText>{i18n.t('Update Password')}</SpanText>
                </Link>
              </LinkUpdatePasswordWrapper>
              <DividerWrapper />
              <RoleWrapper>
                <span>{i18n.t('Role')}</span>
                <span>{currentUser.role}</span>
              </RoleWrapper>
              <PrivacyPolicyLink
                href="https://www.iubenda.com/privacy-policy/40212679"
                target="_blank"
              >
                {i18n.t('Read our Privacy Policy')}
              </PrivacyPolicyLink>
              {updateViewerAvatarOpen && (
                <UpdateViewerAvatarModal
                  onClose={this.handleCloseAllModals}
                  user={currentUser}
                />
              )}
              {updateViewerPasswordOpen && (
                <UpdateViewerPasswordModal
                  onClose={this.handleCloseAllModals}
                />
              )}
            </>
          );
        }}
      />
    );
  }
}

export default Profile;

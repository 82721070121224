/**
 * @flow
 * @relayHash 82b8a894c42e22d4e07d23611d60dd86
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateMachineTypeInput = {|
  id: string,
  name?: ?string,
|};
export type UpdateMachineTypeMutationVariables = {|
  input: UpdateMachineTypeInput
|};
export type UpdateMachineTypeMutationResponse = {|
  +updateMachineType: {|
    +machineTypeEdge: {|
      +cursor: string,
      +node: {|
        +id: string,
        +name: string,
      |},
    |}
  |}
|};
export type UpdateMachineTypeMutation = {|
  variables: UpdateMachineTypeMutationVariables,
  response: UpdateMachineTypeMutationResponse,
|};
*/


/*
mutation UpdateMachineTypeMutation(
  $input: UpdateMachineTypeInput!
) {
  updateMachineType(input: $input) {
    machineTypeEdge {
      cursor
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateMachineTypeInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMachineType",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMachineTypePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "machineTypeEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "MachineTypeEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "MachineType",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateMachineTypeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateMachineTypeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateMachineTypeMutation",
    "id": null,
    "text": "mutation UpdateMachineTypeMutation(\n  $input: UpdateMachineTypeInput!\n) {\n  updateMachineType(input: $input) {\n    machineTypeEdge {\n      cursor\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e17478b134855db71b2f9ad59c1312a';
module.exports = node;

// @flow

import React from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import type { RouterHistory } from 'react-router';
import { i18n } from 'shared/utils';
import Panel from 'shared/components/common/Panel';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Icon from 'shared/components/common/Icon';
import Divider from 'shared/components/common/Divider';
import type { OrderYieldPanels_orderYieldEdges as OrderYieldEdges } from './__generated__/OrderYieldPanels_orderYieldEdges';

type Props = {
  history: RouterHistory,
  orderYieldEdges: OrderYieldEdges,
  onEditItem?: Object => void,
  onDeleteItem?: Object => void,
};

const menuTriggerRefs: Object = {};

const OrderYieldPanels = ({
  history,
  orderYieldEdges,
  onEditItem,
  onDeleteItem,
}: Props) => {
  // Some views, such as on the operator portal, do not have edit or delete capabilities
  const hasContextMenu = Boolean(onEditItem || onDeleteItem);

  if (!orderYieldEdges) {
    return null;
  }

  return orderYieldEdges.map(edge => {
    const orderYield = edge?.node;

    if (!orderYield) {
      return null;
    }

    return (
      <Panel
        key={orderYield.id}
        style={{
          marginBottom: 16,
        }}
        title={orderYield.item.name}
        headerRight={
          hasContextMenu && (
            <ContextMenuTrigger
              id={`item-list-menu-${orderYield.id}`}
              ref={r => (menuTriggerRefs[orderYield.id] = r)}
            >
              <Icon
                type="circle-context-menu"
                onClick={e => {
                  if (menuTriggerRefs[orderYield.id]) {
                    menuTriggerRefs[orderYield.id].handleContextClick(e);
                  }
                }}
              />
            </ContextMenuTrigger>
          )
        }
      >
        <KeyValueGrid
          fields={[
            {
              label: i18n.t('Quantity Ordered'),
              value: i18n.t('{{orderedQuantity, number }} {{measurement}}', {
                orderedQuantity: orderYield.orderedQuantity,
                measurement: orderYield.item.quantityUOM.symbol,
              }),
            },
          ]}
        />

        <Divider
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
        />

        <KeyValueGrid
          fields={[
            {
              label: i18n.t('Inventory #'),
              value: (
                <Link key={orderYield.id} to={`/item/${orderYield.item.id}`}>
                  {orderYield.item.itemNumber}
                </Link>
              ),
            },
            {
              label: i18n.t('Part #'),
              value: i18n.t('{{partNumber}}', {
                partNumber: orderYield.item.partNumber,
              }),
            },
            {
              label: i18n.t('Description'),
              value: i18n.t('{{description}}', {
                description: orderYield.item.description,
              }),
            },
          ]}
        />

        <ContextMenu id={`item-list-menu-${orderYield.id}`}>
          {onEditItem && (
            <MenuItem onClick={() => onEditItem(orderYield)}>
              {i18n.t('Edit Item')}
            </MenuItem>
          )}
          {onDeleteItem && (
            <MenuItem onClick={() => onDeleteItem(orderYield)}>
              {i18n.t('Remove Item')}
            </MenuItem>
          )}
        </ContextMenu>
      </Panel>
    );
  });
};

export default createFragmentContainer(OrderYieldPanels, {
  orderYieldEdges: graphql`
    fragment OrderYieldPanels_orderYieldEdges on OrderYieldEdge
      @relay(plural: true) {
      node {
        id
        orderedQuantity
        order {
          id
        }
        item {
          id
          itemNumber
          name
          description
          partNumber
          formFieldValues
          quantityUOM {
            id
            symbol
          }
        }
        ...AddUpdateOrderYieldModal_orderYield
        ...DeleteOrderYieldModal_orderYield
      }
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import qs from 'qs';
import type { RouterHistory } from 'react-router';
import styled from 'styled-components';
import { i18n, Analytics } from 'shared/utils';
import Button from 'shared/components/common/Button';
import RunPanels from 'shared/components/common/RunPanels';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AutoScheduleJobModal from 'main-app/components/AutoScheduleJobModal';
import RunYieldProgressBarPanels from 'shared/components/common/YieldProgressBar/RunYieldProgressBarPanels';
import type { Runs_job as JobFragment } from './__generated__/Runs_job';

type Props = {
  history: RouterHistory,
  job: JobFragment,
};

type State = {
  autoScheduleModalOpen: boolean,
};

const ButtonWrapper = styled.div`
  padding-bottom: 24px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 7fr 4fr;
  column-gap: 24px;
`;

class Runs extends PureComponent<Props, State> {
  state = {
    autoScheduleModalOpen: false,
  };

  handleOpenAutoScheduleModal = () => {
    this.setState({
      autoScheduleModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      autoScheduleModalOpen: false,
    });
  };

  handleScheduleRun = (e: Event) => {
    const { history } = this.props;
    const { job } = this.props;

    Analytics.trackEvent('Schedule Run Button', {
      jobNumber: job.jobNumber,
    });

    history.push(
      '/production-schedule?' +
        qs.stringify(
          {
            jobsPanelOpen: true,
            jobNumbers: [job.jobNumber],
          },
          {
            encode: true,
            arrayFormat: 'brackets',
          },
        ),
    );
  };

  render() {
    const { job, history } = this.props;
    const { autoScheduleModalOpen } = this.state;

    const hasRuns = Boolean(job.runs.edges?.length);
    const hasParallelWorkflow = job.workflow.isParallelWorkflow;

    return (
      <>
        {job.status !== 'CLOSED' && (
          <ButtonWrapper>
            <Button width="auto" theme="blue" onClick={this.handleScheduleRun}>
              {i18n.t('Schedule Runs')}
            </Button>
            &nbsp;&nbsp;&nbsp;
            {!hasParallelWorkflow && !hasRuns && (
              <Button
                width="auto"
                theme="green"
                onClick={this.handleOpenAutoScheduleModal}
              >
                {i18n.t('Auto Schedule All Runs')}
              </Button>
            )}
          </ButtonWrapper>
        )}

        {!hasRuns ? (
          <EmptyListResults
            graphic="runs"
            message={i18n.t(
              'Runs scheduled for this job ticket will show in this column.',
            )}
          />
        ) : (
          <Wrapper>
            <RunPanels history={history} runEdges={job.runs.edges} />
            <RunYieldProgressBarPanels job={job} />
          </Wrapper>
        )}
        {autoScheduleModalOpen && (
          <AutoScheduleJobModal job={job} onClose={this.handleCloseAllModals} />
        )}
      </>
    );
  }
}

export default createFragmentContainer(Runs, {
  job: graphql`
    fragment Runs_job on Job {
      id
      jobNumber
      status
      workflow {
        id
        isParallelWorkflow
      }
      runs(first: null) @connection(key: "Runs_job_runs", filters: []) {
        edges {
          ...RunPanels_runEdges
        }
      }
      ...AddUpdateRunModal_job
      ...AutoScheduleJobModal_job
      ...RunYieldProgressBarPanels_job
    }
  `,
});

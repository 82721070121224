// @flow

import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import qs from 'qs';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import type { RouterHistory } from 'react-router-dom';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { colors, fonts } from 'shared/styleguide';
import { i18n, Analytics } from 'shared/utils';
import { SearchBar } from 'shared/components/form';
import SlideInPanel from 'shared/components/common/SlideInPanel';
import Icon from 'shared/components/common/Icon';
import Loader from 'shared/components/common/Loader';
import FilterControls, {
  type FilterValue,
} from 'main-app/components/FilterControls';
import JobList from './JobList';
import getFilterOptions from './getFilterOptions';
import getSortOptions from './getSortOptions';

type Props = {
  visible: boolean,
  onClickAway: () => void,
  filterValues: Object,
  onJobDropToSchedule: (*, Object) => void,
  onJobDropToAutoSchedule: Object => void,
  onJobDragStart: boolean => void,
  onJobDragEnd: () => void,
  onFiltersChange: Object => void,
  onViewOnSchedule: Object => void,
  history: RouterHistory,
};

type State = {
  search: string,
  graphqlSearchValue: string,
};

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-top: 24px;
  position: sticky;
  top: 0;
  background-color: ${colors.white};
  /*
    TODO: Need to remove z-index
    z-index currently needed to overlap filter buttons when hovered and scrolling
  */
  z-index: 100;
`;

const Title = styled.div`
  ${fonts.subtitle};
  color: ${colors.charcoalGrey};
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  div:nth-of-type(even) {
    margin-right: 16px;
  }
`;

const ProgressCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 1px solid ${colors.battleshipGrey};
  background-color: ${p => (p.active ? colors.battleshipGrey : colors.white)};
  margin-right: 8px;
`;

const Text = styled.div`
  ${fonts.smallRegular}
`;

const SearchBarWrapper = styled.div`
  padding-bottom: 8px;
`;

const DEFAULT_FILTER = {
  workflowIds: null,
  sort: 'PROMISED_AT',
  direction: 'ASC',
};

class JobListPanel extends PureComponent<Props, State> {
  slideInPanelRef = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
      graphqlSearchValue: '',
    };

    this.handleDebouncedSearch = debounce(this.handleDebouncedSearch, 500);
  }

  getFilters = (): FilterValue => {
    const { history, filterValues } = this.props;
    const { search } = history.location;

    if (!search) {
      return DEFAULT_FILTER;
    }

    const filters = {
      ...DEFAULT_FILTER,
      ...filterValues,
      ...qs.parse(search, { ignoreQueryPrefix: true }),
    };

    // doing this because if workflowIds = '', the query breaks, need to find better way
    if (!filters.workflowIds) {
      filters.workflowIds = null;
    }

    return filters;
  };

  compileQueryVariables = () => {
    const { graphqlSearchValue } = this.state;
    const filters = this.getFilters();

    const queryVariables = {
      ...filters,
      search: graphqlSearchValue.trim() || null,
      sortBy: {
        field: filters.sort,
        direction: filters.direction,
      },
    };

    if (queryVariables.createdAt) {
      const [start, end] = queryVariables.createdAt.split(' - ');
      queryVariables.createdAtStart = start;
      queryVariables.createdAtEnd = end;

      delete queryVariables.createdAt;
    }

    delete queryVariables.sort;
    delete queryVariables.direction;

    return queryVariables;
  };

  handleSearchInput = (search: string) => {
    this.setState(
      {
        search,
      },
      this.handleDebouncedSearch,
    );
  };

  handleDebouncedSearch = () => {
    const { search } = this.state;

    // Updating graphqlSearchValue will auto-kick-off a graphql query re-fetch
    this.setState({
      graphqlSearchValue: search,
    });

    Analytics.trackEvent('Search Applied', {
      listName: 'Job List Panel',
      searchString: search,
    });
  };

  render() {
    const {
      visible,
      onClickAway,
      onJobDropToSchedule,
      onJobDropToAutoSchedule,
      onJobDragStart,
      onJobDragEnd,
      onFiltersChange,
      onViewOnSchedule,
    } = this.props;

    const { search } = this.state;
    const filters = this.getFilters();

    return (
      <QueryRenderer
        environment={relayEnvironment}
        variables={{
          ...this.compileQueryVariables(),
          count: 30,
          cursor: null,
        }}
        cacheConfig={{ force: true }}
        query={graphql`
          query JobListPanelQuery(
            $search: String
            $sortBy: JobSort
            $jobNumbers: [String!]
            $customerIds: [ID!]
            $name: String
            $userIds: [ID!]
            $workflowIds: [ID!]
            $itemName: String
            $itemPartNumber: String
          ) {
            jobs(
              sortBy: $sortBy
              statuses: [OPEN]
              first: 100
              search: $search
              jobNumbers: $jobNumbers
              customerIds: $customerIds
              name: $name
              userIds: $userIds
              workflowIds: $workflowIds
              itemName: $itemName
              itemPartNumber: $itemPartNumber
            ) {
              edges {
                node {
                  id
                  states {
                    id
                    workflowState {
                      id
                      name
                      machineType {
                        id
                        name
                      }
                    }
                    runs {
                      id
                      createdAt
                      status
                    }
                  }
                  ...WorkflowUtils_job @relay(mask: false)
                }
                ...JobList_jobEdges
              }
            }
          }
        `}
        render={({ props }) => (
          <SlideInPanel
            ref={r => (this.slideInPanelRef = r)}
            visible={visible}
            align="right"
          >
            <Header>
              <Title>{i18n.t('Open Job Tickets')}</Title>
              <Icon type="circle-close" onClick={onClickAway} />
            </Header>
            {!props ? (
              <Loader />
            ) : (
              <div className="job-list-panel-content">
                <SearchBarWrapper>
                  <SearchBar
                    placeholder={i18n.t('Job #, Customer, or Project')}
                    value={search}
                    onChange={this.handleSearchInput}
                    autoFocus
                  />
                </SearchBarWrapper>
                <FilterControls
                  value={filters}
                  defaultValue={DEFAULT_FILTER}
                  filterOptions={getFilterOptions()}
                  sortOptions={getSortOptions()}
                  onFiltersChange={onFiltersChange}
                />
                <Legend>
                  <ProgressCircle />
                  <Text>{i18n.t('No Run')}</Text>
                  <ProgressCircle active />
                  <Text>{i18n.t('Run Created')}</Text>
                </Legend>
                <JobList
                  onJobDropToSchedule={onJobDropToSchedule}
                  onJobDropToAutoSchedule={onJobDropToAutoSchedule}
                  onJobDragStart={onJobDragStart}
                  onJobDragEnd={onJobDragEnd}
                  jobEdges={props.jobs.edges}
                  onViewOnSchedule={onViewOnSchedule}
                />
              </div>
            )}
          </SlideInPanel>
        )}
      />
    );
  }
}

export default JobListPanel;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ApplicationType = "MAIN_APP" | "OPERATOR_PORTAL";
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
import type { FragmentReference } from "relay-runtime";
declare export opaque type FormPreview_form$ref: FragmentReference;
declare export opaque type FormPreview_form$fragmentType: FormPreview_form$ref;
export type FormPreview_form = {|
  +id: string,
  +name: string,
  +type: FormType,
  +systemKey: ?string,
  +productionType: ?{|
    +id: string
  |},
  +fields: $ReadOnlyArray<{|
    +id: string,
    +systemKey: ?string,
    +type: FormFieldType,
    +name: string,
    +description: ?string,
    +placeholder: ?string,
    +isRequired: boolean,
    +isCreatable: boolean,
    +applicationType: ApplicationType,
    +displayOrder: number,
    +properties: ?any,
    +options: $ReadOnlyArray<{|
      +name: string,
      +value: string,
    |}>,
  |}>,
  +$refType: FormPreview_form$ref,
|};
export type FormPreview_form$data = FormPreview_form;
export type FormPreview_form$key = {
  +$data?: FormPreview_form$data,
  +$fragmentRefs: FormPreview_form$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemKey",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "FormPreview_form",
  "type": "Form",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "productionType",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductionType",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fields",
      "storageKey": null,
      "args": null,
      "concreteType": "FormField",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v2/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "placeholder",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isRequired",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isCreatable",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "applicationType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "displayOrder",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "properties",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "options",
          "storageKey": null,
          "args": null,
          "concreteType": "FormFieldOption",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "value",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cda0a2f6a44c2c98c62e24496cd23673';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateJobYieldModal_job$ref = any;
type JobStateGrid_jobState$ref = any;
type JobYieldPanels_jobYieldEdges$ref = any;
type UpdateJobStateModal_job$ref = any;
type UpdateJobStateModal_jobState$ref = any;
type WorkflowDiagram_job$ref = any;
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type JobStatus = "CLOSED" | "OPEN";
export type WorkflowStateType = "JOB_COMPLETED" | "JOB_CREATED" | "JOB_ITEM_COUNT" | "RUN" | "TASK";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobDetails_job$ref: FragmentReference;
declare export opaque type JobDetails_job$fragmentType: JobDetails_job$ref;
export type JobDetails_job = {|
  +id: string,
  +status: JobStatus,
  +workflow: {|
    +id: string,
    +states: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +isRequired: ?boolean,
      +type: ?WorkflowStateType,
      +isGangRun: ?boolean,
    |}>,
    +transitions: $ReadOnlyArray<{|
      +id: string,
      +toWorkflowState: {|
        +id: string
      |},
      +fromWorkflowState: {|
        +id: string
      |},
    |}>,
  |},
  +states: $ReadOnlyArray<{|
    +id: string,
    +isEnabled: boolean,
    +status: JobStateStatus,
    +workflowState: {|
      +id: string,
      +name: string,
      +type: ?WorkflowStateType,
      +isAssignable: ?boolean,
      +form: ?{|
        +id: string
      |},
    |},
    +$fragmentRefs: JobStateGrid_jobState$ref & UpdateJobStateModal_jobState$ref,
  |}>,
  +yields: {|
    +edges: ?$ReadOnlyArray<?{|
      +$fragmentRefs: JobYieldPanels_jobYieldEdges$ref
    |}>
  |},
  +order: ?{|
    +id: string,
    +orderNumber: string,
  |},
  +$fragmentRefs: AddUpdateJobYieldModal_job$ref & WorkflowDiagram_job$ref & UpdateJobStateModal_job$ref,
  +$refType: JobDetails_job$ref,
|};
export type JobDetails_job$data = JobDetails_job;
export type JobDetails_job$key = {
  +$data?: JobDetails_job$data,
  +$fragmentRefs: JobDetails_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v4 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "JobDetails_job",
  "type": "Job",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "yields"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflow",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "states",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isRequired",
              "args": null,
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isGangRun",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "transitions",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowTransition",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "toWorkflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v4/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "fromWorkflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v4/*: any*/)
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "states",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isEnabled",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "workflowState",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isAssignable",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "form",
              "storageKey": null,
              "args": null,
              "concreteType": "Form",
              "plural": false,
              "selections": (v4/*: any*/)
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "JobStateGrid_jobState",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "UpdateJobStateModal_jobState",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "yields",
      "name": "__JobDetails_job_yields_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "JobYieldConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "JobYieldEdge",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobYield",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "JobYieldPanels_jobYieldEdges",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "order",
      "storageKey": null,
      "args": null,
      "concreteType": "Order",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "orderNumber",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "AddUpdateJobYieldModal_job",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "WorkflowDiagram_job",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "UpdateJobStateModal_job",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab0fae2a1247b751f843f94215eb5b13';
module.exports = node;

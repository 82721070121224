// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { toggleMainMenu } from 'main-app/store/Actions';
import Icon from 'shared/components/common/Icon';
import Toolbar from '../Toolbar';
import { MENU_WIDTH } from './Menu/styled';
import Menu from './Menu';

type Props = {
  hideToolbar?: boolean,
  hideMenu?: boolean,
  noPadding?: boolean,
  children: React$Node,
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const InnerWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  /* Add a left-hand offset to accommodate the menu when in Desktop mode */
  @media (min-width: 1000px) {
    margin-left: ${p => (p.hideMenu ? 0 : MENU_WIDTH)}px;
  }
`;

const MobileMenuHeader = styled.div`
  height: 52px;
  padding: 10px 16px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.lightBlueGrey};
  width: 100%;

  @media (min-width: 1000px) {
    display: none;
  }
`;

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 24px;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 3px 5px 0 rgba(66, 68, 76, 0.05);
`;

const ContentWrapper = styled.div`
  position: relative;
  padding: ${p => (p.noPadding ? 0 : 12)}px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  min-height: min-content;

  @media (min-width: 1000px) {
    padding: ${p => (p.noPadding ? 0 : 24)}px;
    padding-bottom: 80px;
  }
`;

const Page = ({ hideToolbar, hideMenu, noPadding, children }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    dataFrom="STORE_THEN_NETWORK"
    variables={null}
    query={graphql`
      query PageQuery {
        forms(types: [ITEM]) {
          edges {
            node {
              id
              ...Toolbar_defaultForm
            }
          }
        }

        viewer {
          id
          firstName
          lastName
          permissions
          organization {
            name
          }
          company {
            name
          }
          companies {
            id
            name
          }
          updatedAt
          createdAt
          ...Toolbar_viewer
        }

        workflows {
          edges {
            node {
              id
              isDefault
            }
            ...Toolbar_workflowEdges
          }
        }
      }
    `}
    render={query => (
      <Wrapper>
        <InnerWrapper hideMenu={hideMenu}>
          {hideMenu ? null : (
            <MobileMenuHeader>
              <Icon type="circle-menu" onClick={() => toggleMainMenu()} />
            </MobileMenuHeader>
          )}
          {!hideToolbar && query.props?.viewer && (
            <ToolbarWrapper>
              <Toolbar
                viewer={query.props.viewer}
                defaultForm={query.props?.forms.edges[0]?.node || null}
                workflowEdges={query.props?.workflows?.edges}
              />
            </ToolbarWrapper>
          )}
          <ContentWrapper noPadding={noPadding}>{children}</ContentWrapper>
        </InnerWrapper>
        {hideMenu ? null : (
          <Menu
            viewer={query.props?.viewer || null}
            workflows={query.props?.workflows}
          />
        )}
      </Wrapper>
    )}
  />
);

Page.defaultProps = {
  hideToolbar: false,
  hideMenu: false,
  noPadding: false,
};

export default Page;

// @flow

import { graphql } from 'react-relay';
import { ConnectionHandler, ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      id: string,
    },
  },
  context: {
    jobId: string,
  },
};

const mutation = graphql`
  mutation DeleteRunMutation($input: DeleteRunInput!) {
    deleteRun(input: $input) {
      deletedId
    }
  }
`;

export default {
  commit: ({ context, ...options }: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: ROOT_ID,
          connectionKeys: [
            {
              key: 'ProductionScheduleChart_scheduleEvents',
            },
          ],
          pathToConnection: [ROOT_ID, 'runs'],
          deletedIDFieldName: 'deletedId',
        },
      ],
      updater: store => {
        const root = store
          .getRoot()
          .getLinkedRecord('job', { id: context.jobId });

        if (root) {
          const conn1 = ConnectionHandler.getConnection(root, 'Runs_job_runs');

          if (conn1) {
            const edges = conn1
              .getLinkedRecords('edges')
              .filter(
                edge =>
                  edge.getLinkedRecord('node')._dataID !==
                  options.variables.input.id,
              );

            conn1.setLinkedRecords(edges, 'edges');
          }
        }
      },
    });
  },
};

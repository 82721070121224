// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Button from 'shared/components/common/Button';
import Icon from 'shared/components/common/Icon';
import { SearchBar } from 'shared/components/form';
import PageHeader from '../PageHeader';

type Props = {
  title?: string,
  left?: React$Node,
  exportButtonLabel?: string,
  onExportButtonClick?: void => void,
  createButtonLabel?: string,
  onCreateButtonClick?: void => void,
  onSearchChange: string => void,
  searchPlaceholder: string,
  searchValue: string,
  searchDisabled?: boolean,
};

type State = {
  mobileSearchOpen: boolean,
};

const HeaderRight = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  /* Don't show the "Create" button or search bar on mobile */
  .desktop-create-button,
  .desktop-export-button,
  .desktop-search-bar {
    display: none;
  }

  @media (min-width: 1000px) {
    /* Don't show the "Toggle Search" icon on desktop */
    .mobile-search-icon {
      display: none;
    }

    /* Show the search bar on desktop */
    .desktop-search-bar {
      display: block;
      min-width: 300px;
    }

    /* Show the "Create" button on desktop */
    .desktop-create-button {
      display: initial;
      margin-right: 24px;
    }

    /* Show the "Export" button on desktop */
    .desktop-export-button {
      display: initial;
      margin-right: 24px;
    }
  }
`;

const MobileSearchWrapper = styled.div`
  padding-bottom: 16px;
`;

class PageHeaderList extends PureComponent<Props, State> {
  static defaultProps = {
    title: undefined,
    left: undefined,
    exportButtonLabel: undefined,
    onExportButtonClick: undefined,
    createButtonLabel: undefined,
    onCreateButtonClick: undefined,
    searchDisabled: false,
  };

  state = {
    mobileSearchOpen: false,
  };

  handleToggleMobileSearch = () => {
    const { mobileSearchOpen } = this.state;

    this.setState({
      mobileSearchOpen: !mobileSearchOpen,
    });
  };

  render() {
    const {
      title,
      left,
      exportButtonLabel,
      onExportButtonClick,
      createButtonLabel,
      onCreateButtonClick,
      searchPlaceholder,
      searchValue,
      onSearchChange,
      searchDisabled,
    } = this.props;
    const { mobileSearchOpen } = this.state;

    return (
      <>
        <PageHeader
          title={title}
          left={left}
          right={
            <HeaderRight>
              {exportButtonLabel && (
                <span className="desktop-export-button">
                  <Button onClick={onExportButtonClick} theme="border-white">
                    {exportButtonLabel}
                  </Button>
                </span>
              )}

              {createButtonLabel && (
                <span className="desktop-create-button">
                  <Button onClick={onCreateButtonClick} theme="border-white">
                    {createButtonLabel}
                  </Button>
                </span>
              )}
              {!searchDisabled && (
                <span className="mobile-search-icon">
                  <Icon
                    type={mobileSearchOpen ? 'circle-close' : 'circle-search'}
                    onClick={this.handleToggleMobileSearch}
                  />
                </span>
              )}
              <span className="desktop-search-bar">
                <SearchBar
                  placeholder={searchPlaceholder}
                  value={searchValue}
                  onChange={onSearchChange}
                  disabled={searchDisabled}
                />
              </span>
            </HeaderRight>
          }
        />
        {mobileSearchOpen && (
          <MobileSearchWrapper>
            <SearchBar
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={onSearchChange}
            />
          </MobileSearchWrapper>
        )}
      </>
    );
  }
}

export default PageHeaderList;

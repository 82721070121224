// @flow

import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { type Match, type RouterHistory } from 'react-router-dom';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import relayEnvironment from 'shared/gql/relayEnvironment';
import moment from 'moment';
import { i18n } from 'shared/utils';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import PageDetailsSummary from 'main-app/components/PageDetailsSummary';
import Icon from 'shared/components/common/Icon';
import InfoWidget from 'shared/components/common/InfoWidget';
import Loader from 'shared/components/common/Loader';
import TabContentWrapper from 'shared/components/common/TabContent/Wrapper';
import AddUpdateLotModal from 'main-app/components/AddUpdateLotModal';
import LotSidePanel from './LotSidePanel';
import LotItems from './LotItems';

type Props = {
  history: RouterHistory,
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

type State = {
  updateLotModalOpen: boolean,
};

class Lot extends PureComponent<Props, State> {
  menuTriggerRef = null;

  state = {
    updateLotModalOpen: false,
  };

  handleOpenUpdateLotModal = () => {
    this.setState({
      updateLotModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateLotModalOpen: false,
    });
  };

  render() {
    const { match, history } = this.props;
    const { updateLotModalOpen } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query LotQuery($id: ID!) {
            lot(id: $id) {
              id
              lotNumber
              binNumber
              quantity
              expiresAt
              createdAt
              items(first: null) @connection(key: "Lot_items", filters: []) {
                edges {
                  ...LotItems_lotItemEdges
                }
              }
              ...LotSidePanel_lot
              ...AddUpdateLotModal_lot
            }
            viewer {
              id
              permissions
            }
          }
        `}
        variables={{
          id: match.params.id,
        }}
        render={query => {
          return (
            <Page>
              <PageHeader
                intro={i18n.t('LOT')}
                title={
                  query.props
                    ? query.props.lot.lotNumber
                    : i18n.t('Details Loading...')
                }
                right={
                  query.props && [
                    <ContextMenuTrigger
                      id="item-details-menu"
                      ref={r => (this.menuTriggerRef = r)}
                    >
                      <Icon
                        type="circle-context-menu"
                        onClick={e => {
                          if (this.menuTriggerRef) {
                            this.menuTriggerRef.handleContextClick(e);
                          }
                        }}
                      />
                    </ContextMenuTrigger>,
                  ]
                }
              />
              <PageDetailsSummary
                items={[
                  <InfoWidget
                    title={i18n.t('Total Quantity')}
                    content={query.props?.lot.quantity || 0}
                  />,
                  <InfoWidget
                    title={i18n.t('Created')}
                    content={
                      moment(query.props?.lot.createdAt).isValid()
                        ? moment(query.props?.lot.createdAt).format(
                            'MM/DD/YYYY',
                          )
                        : ''
                    }
                  />,
                ]}
              />
              {!query.props ? (
                <Loader />
              ) : (
                <>
                  <TabContentWrapper>
                    <LotSidePanel lot={query.props?.lot} />
                    <LotItems
                      lotItemEdges={query.props?.lot?.items.edges}
                      history={history}
                    />
                  </TabContentWrapper>
                  <ContextMenu id="item-details-menu">
                    {query.props.viewer.permissions.includes('UPDATE_LOT') && (
                      <MenuItem onClick={this.handleOpenUpdateLotModal}>
                        {i18n.t('Edit Lot')}
                      </MenuItem>
                    )}
                  </ContextMenu>
                  {updateLotModalOpen && (
                    <AddUpdateLotModal
                      onClose={this.handleCloseAllModals}
                      lot={query.props.lot}
                    />
                  )}
                </>
              )}
            </Page>
          );
        }}
      />
    );
  }
}

export default Lot;

// @flow

import React, { PureComponent } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import type { RouterHistory } from 'react-router';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddUpdateItemModal from 'main-app/components/AddUpdateItemModal';
import DeleteItemModal from 'main-app/components/DeleteItemModal';
import type { ItemsTable_itemEdges as ItemEdgesFragment } from './__generated__/ItemsTable_itemEdges';
import type { ItemsTable_defaultForm as DefaultFormFragment } from './__generated__/ItemsTable_defaultForm';

type Props = {
  history: RouterHistory,
  itemEdges: ItemEdgesFragment,
  defaultForm: DefaultFormFragment,
  onCreateNew?: void => void,
  emptyListMessage?: string,
  deleteEnabled?: boolean,
  editEnabled?: boolean,
};

type State = {
  updateItemRecord: ?Object,
  deleteItemRecord: ?Object,
};

class ItemsTable extends PureComponent<Props, State> {
  static defaultProps = {
    onCreateNew: false,
    emptyListMessage: null,
    deleteEnabled: false,
    editEnabled: false,
  };

  menuTriggerRefs = {};
  state = {
    updateItemRecord: null,
    deleteItemRecord: null,
  };

  getItemById = (itemId: string) => {
    const { itemEdges } = this.props;

    const item = itemEdges.find(edge => {
      return edge?.node?.id === itemId;
    });

    return item?.node || null;
  };

  handleOpenNewTab = (e, data, target) => {
    const itemId = target.getAttribute('item-id');

    window.open(`/item/${itemId}`);
  };

  handleOpenUpdateModal = (e, data, target) => {
    const itemId = target.getAttribute('item-id');

    this.setState({
      updateItemRecord: this.getItemById(itemId),
    });
  };

  handleOpenDeleteModal = (e, data, target) => {
    const itemId = target.getAttribute('item-id');

    this.setState({
      deleteItemRecord: this.getItemById(itemId),
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateItemRecord: null,
      deleteItemRecord: null,
    });
  };

  render() {
    const {
      history,
      itemEdges,
      defaultForm,
      emptyListMessage,
      onCreateNew,
      deleteEnabled,
      editEnabled,
    } = this.props;
    const { updateItemRecord, deleteItemRecord } = this.state;

    const edges = itemEdges ? itemEdges.filter(Boolean).map(a => a.node) : [];

    if (!edges.length) {
      return (
        <EmptyListResults
          graphic="items"
          message={
            emptyListMessage ||
            i18n.t(
              `Your items will show here. Create a new item to get started!`,
            )
          }
          onCreateNew={onCreateNew}
          createNewLabel={i18n.t('Create Item')}
        />
      );
    }

    const lowQtyStyle = (totalQty, safetyQuantity) => {
      return totalQty && safetyQuantity && totalQty < safetyQuantity
        ? {
            color: 'red',
          }
        : {};
    };

    return (
      <>
        <Table
          data={edges}
          onRowClick={rowData => history.push(`/item/${rowData.id}`)}
          columns={[
            {
              Header: i18n.t('Inventory #'),
              accessor: 'itemNumber',
              width: 120,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value, row }: *) => <Pill>{value}</Pill>,
            },
            {
              Header: i18n.t('Name'),
              accessor: 'name',
              Cell: ({ value, row }: *) => (
                <div
                  style={lowQtyStyle(
                    row.totalQty,
                    row._original.safetyQuantity,
                  )}
                >
                  {value}
                </div>
              ),
            },
            {
              Header: i18n.t('Category'),
              accessor: 'category.name',
              width: 120,
              Cell: ({ value, row }: *) => (
                <div
                  style={lowQtyStyle(
                    row.totalQty,
                    row._original.safetyQuantity,
                  )}
                >
                  {value}
                </div>
              ),
            },
            {
              Header: i18n.t('Total Quantity'),
              accessor: 'totalQty',
              width: 120,
              Cell: ({ value, row }: *) => (
                <div
                  style={lowQtyStyle(
                    row.totalQty,
                    row._original.safetyQuantity,
                  )}
                >
                  {i18n.t('{{quantity, number}}', { quantity: value })}
                </div>
              ),
            },
            {
              Header: i18n.t('Total Value'),
              accessor: 'totalValue',
              width: 120,
              Cell: ({ value, row }: *) => (
                <div
                  style={lowQtyStyle(
                    row.totalQty,
                    row._original.safetyQuantity,
                  )}
                >
                  {i18n.t('{{quantity, number}}', { quantity: value })}
                </div>
              ),
            },
            {
              Header: i18n.t('Part #'),
              accessor: 'partNumber',
              width: 120,
              Cell: ({ value, row }: *) => (
                <div
                  style={lowQtyStyle(
                    row.totalQty,
                    row._original.safetyQuantity,
                  )}
                >
                  {i18n.t('{{quantity, number}}', { quantity: value })}
                </div>
              ),
            },
            {
              Header: i18n.t('UOM'),
              accessor: 'quantityUOM.symbol',
              width: 120,
              Cell: ({ value, row }: *) => (
                <div
                  style={lowQtyStyle(
                    row.totalQty,
                    row._original.safetyQuantity,
                  )}
                >
                  {i18n.t('{{quantity, number}}', { quantity: value })}
                </div>
              ),
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              Cell: ({ value }: *) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="items-menu"
                  attributes={{
                    'item-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={(e, data, target) => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(
                          e,
                          data,
                          target,
                        );
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ]}
        />
        <ContextMenu id="items-menu">
          {editEnabled && (
            <MenuItem onClick={this.handleOpenUpdateModal}>
              {i18n.t('Edit Item')}
            </MenuItem>
          )}
          <MenuItem onClick={this.handleOpenNewTab}>
            {i18n.t('Open In New Tab')}
          </MenuItem>
          {deleteEnabled && (
            <MenuItem onClick={this.handleOpenDeleteModal}>
              {i18n.t('Delete Item')}
            </MenuItem>
          )}
        </ContextMenu>
        {updateItemRecord && (
          <AddUpdateItemModal
            item={updateItemRecord}
            onClose={this.handleCloseAllModals}
            defaultForm={defaultForm}
          />
        )}
        {deleteItemRecord && (
          <DeleteItemModal
            history={history}
            item={deleteItemRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(ItemsTable, {
  itemEdges: graphql`
    fragment ItemsTable_itemEdges on ItemEdge @relay(plural: true) {
      node {
        id
        itemNumber
        name
        partNumber
        category {
          id
          name
        }
        quantityUOM {
          id
          symbol
        }
        totalQty
        safetyQuantity
        totalValue
        ...AddUpdateItemModal_item
        ...DeleteItemModal_item
      }
    }
  `,
  defaultForm: graphql`
    fragment ItemsTable_defaultForm on Form {
      id
      ...FormBuilder_form @relay(mask: false)
      ...AddUpdateItemModal_defaultForm
    }
  `,
});

// @flow

import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { colors, fonts, helpers } from 'shared/styleguide';
import xIcon from './img/xIcon.svg';
import filterIcon from './img/filterIcon.svg';
import saveIcon from './img/saveIcon.svg';
import resetIcon from './img/resetIcon.svg';

export const Wrapper = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  outline: none;
  color: ${p => getFontColor(p.theme)};
  ${fonts.smallRegular}
  border-radius: 5px;
  text-align: center;
  padding: ${p => (p.closeable ? '7px 20px 7px 16px' : '7px 8px')};
  background-color: ${p => getBackgroundColor(p.theme)};
  border: 1px solid ${p => getBorderColor(p.theme)};
  min-width: ${p => helpers.widthProp(p, '120px')};
  opacity: ${p => (p.disabled ? 0.5 : 1)};

  @media(min-width: 1000px) {
    &:hover {
      cursor: pointer;
      border-color: ${p => darken(0.1, getBorderColor(p.theme))};
      color: ${p => darken(0.1, getFontColor(p.theme))};
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15)};
    }
  }

  svg.close-icon {
    fill: ${p => getFontColor(p.theme)};
  }
`;

export const Label = styled.label`
  flex: 1;
  padding-right: ${p => (p.closeable ? '8px' : 0)};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  @media (min-width: 1000px) {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const CloseIcon = styled(xIcon)`
  position: absolute;
  right: 4px;
  width: 20px;
  height: 20px;
  fill: ${p => getFontColor(p.theme)};

  &:hover {
    background-color: ${p => darken(0.1, getBackgroundColor(p.theme))};
    cursor: pointer;
  }
`;

export const SaveIcon = styled(saveIcon)`
  width: 17px;
  height: 17px;
  margin-right: 8px;
`;

export const FilterIcon = styled(filterIcon)`
  width: 17px;
  height: 17px;
  margin-right: 8px;
`;

export const ResetIcon = styled(resetIcon)`
  width: 17px;
  height: 17px;
  margin-right: 8px;
`;

function getBackgroundColor(theme) {
  switch (theme) {
    case 'grey':
      return colors.paleGreyTwo;

    case 'light-grey':
      return colors.white;

    case 'dark-grey':
      return colors.charcoalGrey;

    case 'blue':
      return colors.white;

    case 'blue-filled':
      return colors.azul;

    case 'red':
      return transparentize(0.85, colors.cherry);

    case 'border-white':
      return colors.white;
  }

  return colors.paleGreyTwo;
}

function getBorderColor(theme) {
  switch (theme) {
    case 'grey':
      return colors.coolGrey;

    case 'light-grey':
      return colors.lightBlueGrey;

    case 'dark-grey':
      return colors.lightBlueGrey;

    case 'blue':
      return colors.azul;

    case 'blue-filled':
      return colors.azul;

    case 'red':
      return 'rgba(0, 0, 0, 0)';

    case 'border-white':
      return colors.charcoalGrey;
  }

  return colors.coolGrey;
}

function getFontColor(theme) {
  switch (theme) {
    case 'grey':
      return colors.battleshipGrey;

    case 'light-grey':
      return colors.coolGrey;

    case 'dark-grey':
      return colors.white;

    case 'blue':
      return colors.azul;

    case 'blue-filled':
      return colors.white;

    case 'red':
      return colors.cherry;

    case 'border-white':
      return colors.charcoalGrey;
  }

  return colors.battleshipGrey;
}

// @flow

import React, { PureComponent } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router';
import { i18n } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import Table from 'shared/components/common/Table';
import UserAvatar from 'shared/components/common/UserAvatar';
import Icon from 'shared/components/common/Icon';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddUpdateCustomerModal from 'main-app/components/AddUpdateCustomerModal';
import type { CustomerList_customerEdges as CustomerEdgesFragment } from './__generated__/CustomerList_customerEdges';

type Props = {
  history: RouterHistory,
  customerEdges: CustomerEdgesFragment,
  onCreateNew?: void => void,
  emptyListMessage?: string,
};

type State = {
  updateCustomerRecord: ?Object,
};

class CustomerList extends PureComponent<Props, State> {
  static defaultProps = {
    onCreateNew: false,
    emptyListMessage: null,
  };

  menuTriggerRefs = {};
  state = {
    updateCustomerRecord: null,
  };

  getCustomerById = (customerId: string) => {
    const { customerEdges } = this.props;

    const customer = customerEdges.find(edge => {
      return edge?.node?.id === customerId;
    });

    return customer?.node || null;
  };

  handleOpenNewTab = (e, data, target) => {
    const customerId = target.getAttribute('customer-id');

    window.open(`/customer/${customerId}`);
  };

  handleOpenUpdateModal = (e, data, target) => {
    const customerId = target.getAttribute('customer-id');

    this.setState({
      updateCustomerRecord: this.getCustomerById(customerId),
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateCustomerRecord: null,
    });
  };

  render() {
    const {
      history,
      customerEdges,
      onCreateNew,
      emptyListMessage,
    } = this.props;
    const { updateCustomerRecord } = this.state;

    const edges = customerEdges
      ? customerEdges.filter(Boolean).map(a => a.node)
      : [];

    if (!edges.length) {
      return (
        <EmptyListResults
          graphic="customers"
          message={
            emptyListMessage ||
            i18n.t(`Your customer's profiles will show here.`)
          }
          onCreateNew={onCreateNew}
          createNewLabel={i18n.t('Create Customer')}
        />
      );
    }

    return (
      <>
        <Table
          data={edges}
          onRowClick={rowData => history.push(`/customer/${rowData.id}`)}
          columns={[
            {
              Header: i18n.t('Customer #'),
              accessor: 'customerNumber',
              width: 160,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value, row }: *) => (
                <Pill onClick={() => history.push(row.customerNumber)}>
                  {value}
                </Pill>
              ),
            },
            {
              Header: i18n.t('Customer'),
              accessor: 'name',
            },
            {
              Header: i18n.t('Open Orders'),
              accessor: 'openOrders',
              width: 140,
              Cell: ({ value }: *) => value,
            },
            {
              Header: i18n.t('Salesperson'),
              accessor: 'salesUser',
              width: 100,
              headerStyle: {
                textAlign: 'center',
              },
              style: {
                textAlign: 'center',
              },
              Cell: ({ value }: *) => <UserAvatar user={value} width={28} />,
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              Cell: ({ value }) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="customers-menu"
                  attributes={{
                    'customer-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={(e, data, target) => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(
                          e,
                          data,
                          target,
                        );
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ]}
        />
        <ContextMenu id="customers-menu">
          <MenuItem onClick={this.handleOpenUpdateModal}>
            {i18n.t('Edit Customer')}
          </MenuItem>
          <MenuItem onClick={this.handleOpenNewTab}>
            {i18n.t('Open In New Tab')}
          </MenuItem>
        </ContextMenu>
        {updateCustomerRecord && (
          <AddUpdateCustomerModal
            history={history}
            customer={updateCustomerRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(CustomerList, {
  customerEdges: graphql`
    fragment CustomerList_customerEdges on CustomerEdge @relay(plural: true) {
      node {
        id
        customerNumber
        name
        openOrders
        salesUser {
          ...UserAvatar_user
        }
        ...AddUpdateCustomerModal_customer
      }
    }
  `,
});

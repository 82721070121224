// @flow

import React from 'react';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import type { YieldProgressBar_jobYield as JobYieldFragment } from './__generated__/YieldProgressBar_jobYield';

type Props = {
  isGangRun?: boolean,
  jobYield: JobYieldFragment,
  runQuantity?: number,
};

const Wrapper = styled.div`
  color: ${colors.charcoalGrey};

  &:first-of-type,
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;
`;

const Title = styled.div`
  ${fonts.smallBold};
  padding-bottom: 8px;
  text-align: left;
  width: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const OverUnderText = styled.div`
  text-align: right;
  margin-top: 8px;
  ${fonts.bodyRegular};
`;

const Info = styled.div`
  ${fonts.bodyRegular};
  color: ${p => (p.overprovisioned ? colors.cherry : colors.charcoalGrey)};
`;

const Percentage = styled.span`
  ${fonts.bodyRegular};
  color: ${p => (p.overprovisioned ? colors.cherry : colors.charcoalGrey)};
`;

const PercentageBar = styled.div`
  border-radius: 8px;
  height: 8px;
  background-color: ${p =>
    p.filled ? colors.battleshipGrey : colors.lightBlueGrey};
  width: ${p => (p.percentage !== undefined ? p.percentage : 100)}%;
`;

const YieldProgressBar = ({ isGangRun, jobYield, runQuantity }: Props) => {
  const showRunYieldData = runQuantity !== undefined;
  const title = showRunYieldData && jobYield.item.name;
  const quantity =
    ((showRunYieldData ? runQuantity : jobYield.finalQuantity) || 0) *
    (isGangRun ? jobYield.itemNumberUp : 1);
  const totalQuantity = jobYield.quantity;
  const overQuantity = jobYield.overQuantity;
  const percentage = totalQuantity
    ? Math.round((quantity / totalQuantity) * 100)
    : 0;
  const localizedQuantity = i18n.t('{{quantity, number}}', {
    quantity: quantity,
  });
  const localizedTotalQuantity = i18n.t('{{quantity, number}}', {
    quantity: totalQuantity,
  });
  const localizedOverQuantity = i18n.t('{{quantity, number}}', {
    quantity: jobYield.overQuantity,
  });

  const percentageChange =
    totalQuantity && overQuantity
      ? Math.round((overQuantity / totalQuantity) * 100)
      : 0;

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        <InfoWrapper>
          <Info>
            <Percentage overprovisioned={percentage > 100}>
              {localizedQuantity}
            </Percentage>
            {i18n.t(
              ' / {{localizedTotalQuantity, number}} {{uomSymbol, string}} produced',
              {
                localizedTotalQuantity,
                uomSymbol: jobYield.item.quantityUOM.symbol,
              },
            )}
          </Info>
          <Info overprovisioned={percentage > 100}>{`${percentage}%`}</Info>
        </InfoWrapper>
      </Header>
      <PercentageBar>
        <PercentageBar filled percentage={Math.min(percentage, 100)} />
      </PercentageBar>
      {!showRunYieldData && (
        <OverUnderText>
          {jobYield.overUnderRule === 'NO_OVERS'
            ? i18n.t('No Overs')
            : i18n.t(
                '{{percentageChange, string}} {{localizedOverQuantity, number}} {{uomSymbol, string}} overs // {{overUnderRule, string}}',
                {
                  percentageChange: `(${percentageChange}%)`,
                  localizedOverQuantity,
                  uomSymbol: jobYield.item.quantityUOM.symbol,
                  overUnderRule:
                    jobYield.overUnderRule === 'ALLOW_OVERS'
                      ? 'allow overs'
                      : 'no unders',
                },
              )}
        </OverUnderText>
      )}
    </Wrapper>
  );
};

YieldProgressBar.defaultProps = {
  isGangRun: false,
  runQuantity: undefined,
};

export default createFragmentContainer(YieldProgressBar, {
  jobYield: graphql`
    fragment YieldProgressBar_jobYield on JobYield {
      id
      itemNumberUp
      quantity
      finalQuantity
      overUnderRule
      overQuantity
      item {
        id
        name
        quantityUOM {
          id
          symbol
        }
      }
    }
  `,
});

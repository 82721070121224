// @flow

import React from 'react';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { fonts, colors } from 'shared/styleguide';
import Divider from 'shared/components/common/Divider';
import CustomPieChart from 'shared/components/common/CustomPieChart';

type Props = {
  machinePerformanceRates: {
    id: string,
    name: string,
    percentage: number,
  }[],
};

const Header = styled.div`
  ${fonts.bodyBold};
  color: ${colors.charcoalGrey};
`;

export default ({ machinePerformanceRates }: Props) => {
  const data = machinePerformanceRates.length
    ? machinePerformanceRates.map(machinePerformanceRate => ({
        id: machinePerformanceRate.id,
        name: machinePerformanceRate.name,
        value: machinePerformanceRate.percentage,
      }))
    : [
        {
          id: 'no-data-machine-performance-rate',
          name: i18n.t('No Data Available for the Selected Dates'),
          value: 1,
        },
      ];

  return (
    <>
      <Header>{i18n.t(`Work Center Performance Rates (%)`)}</Header>
      <Divider style={{ marginTop: 8 }} />
      <CustomPieChart data={data} />
    </>
  );
};

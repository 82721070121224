// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateJobStatusMutation($input: UpdateJobStatusInput!) {
    updateJobStatus(input: $input) {
      jobEdge {
        node {
          id
          jobNumber
          name
          status
          yields {
            totalCount
            edges {
              node {
                id
                finalQuantity
              }
            }
          }
          user {
            id
            firstName
            lastName
          }
          order {
            id
            promisedAt
            customer {
              id
              name
            }
          }
          workflow {
            id
            name
          }
          states {
            id
            formFieldValues
            status
            isEnabled
            workflowState {
              id
              name
              form {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

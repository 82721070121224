// @flow

import React from 'react';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';

type Props = {
  onClose: () => void,
  onDelete: () => void,
};

const DeleteRunsModal = ({ onClose, onDelete }: Props) => {
  const language = {
    header: i18n.t('Disable and Delete Scheduled Runs?'),
    submitButton: i18n.t('Delete Runs'),
    body: (
      <>
        {i18n.t('Are you sure you want to disable this state?')}
        <br />
        <br />
        {i18n.t(
          'This will delete all runs that are not complete that belong to this state.',
        )}
      </>
    ),
  };

  return (
    <Modal maxWidth={700}>
      <ModalHeader header={language.header} onClose={onClose} />
      <ModalBody withPadding>{language.body}</ModalBody>
      <ModalFooter>
        <Button type="submit" theme="blue" onClick={onDelete}>
          {language.submitButton}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteRunsModal;

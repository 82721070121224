/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserWidget_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderSidePanel_order$ref: FragmentReference;
declare export opaque type OrderSidePanel_order$fragmentType: OrderSidePanel_order$ref;
export type OrderSidePanel_order = {|
  +id: string,
  +customerOrderNumber: ?string,
  +shippingAddressLine1: ?string,
  +shippingAddressLine2: ?string,
  +shippingAddressLine3: ?string,
  +shippingAddressCity: ?string,
  +shippingAddressState: ?string,
  +shippingAddressZip: ?string,
  +notes: ?string,
  +orderedAt: ?any,
  +promisedAt: ?any,
  +requiredAt: ?any,
  +user: ?{|
    +$fragmentRefs: UserWidget_user$ref
  |},
  +$refType: OrderSidePanel_order$ref,
|};
export type OrderSidePanel_order$data = OrderSidePanel_order;
export type OrderSidePanel_order$key = {
  +$data?: OrderSidePanel_order$data,
  +$fragmentRefs: OrderSidePanel_order$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderSidePanel_order",
  "type": "Order",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "customerOrderNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine1",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine3",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressCity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressState",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressZip",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "orderedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "promisedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requiredAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "UserWidget_user",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '5a66514e0adc7b5a1830625b97ffc3ce';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import debounce from 'lodash.debounce';
import type { RouterHistory } from 'react-router';
import { BrowserUtils } from 'shared/utils';
import LoadingMore from 'shared/components/common/LoadingMore';
import EmptySearchResults from 'shared/components/common/EmptySearchResults';
import CustomerList from './CustomerList';
import type { PaginatedCustomersContainer_customers as CustomersFragment } from './__generated__/PaginatedCustomersContainer_customers';

type Props = {
  history: RouterHistory,
  filtersDirty: boolean,
  onCreateNew: () => void,
  customers: CustomersFragment,
  editEnabled: boolean,
  deleteEnabled: boolean,
  relay: {
    environment: Object,
    hasMore: () => boolean,
    isLoading: () => boolean,
    loadMore: (pageSize: number, callback: Function) => void,
  },
};

class PaginatedCustomersContainer extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onScrollEndReached = debounce(this.onScrollEndReached, 100);
  }

  componentDidMount = () => {
    document.addEventListener('scroll', this.onScrollEndReached);
  };

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.onScrollEndReached);
  };

  onScrollEndReached = () => {
    const { relay } = this.props;
    if (BrowserUtils.scrollEndReached(200)) {
      if (relay.hasMore()) {
        relay.loadMore(30);
      }
    }
  };

  render() {
    const {
      history,
      onCreateNew,
      customers,
      relay,
      filtersDirty,
      editEnabled,
      deleteEnabled,
    } = this.props;

    if (
      (!customers.customers.edges || !customers.customers.edges.length) &&
      filtersDirty
    ) {
      return <EmptySearchResults />;
    }

    return (
      <>
        <CustomerList
          history={history}
          onCreateNew={onCreateNew}
          customerEdges={customers.customers.edges}
          deleteEnabled={deleteEnabled}
          editEnabled={editEnabled}
        />
        {relay.hasMore() && relay.isLoading() ? <LoadingMore /> : null}
      </>
    );
  }
}

export default createPaginationContainer(
  PaginatedCustomersContainer,
  {
    customers: graphql`
      fragment PaginatedCustomersContainer_customers on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 30 }
          cursor: { type: "String" }
          search: { type: "String" }
          sortBy: { type: "CustomerSort" }
          customerNumbers: { type: "[String!]" }
          salesUserIds: { type: "[ID!]" }
        ) {
        customers(
          first: $count
          after: $cursor
          search: $search
          sortBy: $sortBy
          customerNumbers: $customerNumbers
          salesUserIds: $salesUserIds
        )
          @connection(
            key: "PaginatedCustomersContainer_customers"
            filters: []
          ) {
          edges {
            cursor
            ...CustomerList_customerEdges
          }
        }
      }
    `,
  },
  {
    query: graphql`
      query PaginatedCustomersContainerQuery(
        $count: Int!
        $cursor: String
        $search: String
        $sortBy: CustomerSort
        $customerNumbers: [String!]
        $salesUserIds: [ID!]
      ) {
        ...PaginatedCustomersContainer_customers
          @arguments(
            count: $count
            cursor: $cursor
            search: $search
            sortBy: $sortBy
            customerNumbers: $customerNumbers
            salesUserIds: $salesUserIds
          )
      }
    `,
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return { ...fragmentVariables, count, cursor };
    },
  },
);

// @flow

import React from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddOrderYieldMutation from 'main-app/mutations/AddOrderYield';
import UpdateOrderYieldMutation from 'main-app/mutations/UpdateOrderYield';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  SelectItemField,
} from 'shared/components/form';
import type { AddUpdateOrderYieldModal_orderYield as OrderYieldFragment } from './__generated__/AddUpdateOrderYieldModal_orderYield';
import type { AddUpdateOrderYieldModal_orderYieldEdges as OrderYieldEdgeFragment } from './__generated__/AddUpdateOrderYieldModal_orderYieldEdges';

type Props = {
  onClose: () => void,
  orderId: string,
  orderYield: ?OrderYieldFragment,
  orderYieldEdges: Array<?OrderYieldEdgeFragment>,
};

const AddUpdateOrderYieldModal = (props: Props) => {
  const { orderId, orderYield, orderYieldEdges, onClose } = props;
  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          item: orderYield?.item && {
            value: orderYield?.item.id,
            data: orderYield?.item,
          },
          orderedQuantity: orderYield?.orderedQuantity || '',
        }}
        validationSchema={Yup.object().shape({
          item: Yup.object()
            .nullable()
            .required('Required'),
          orderedQuantity: Yup.number()
            .moreThan(0, 'Please enter a value greater than 0')
            .required('Required'),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const mutation = orderYield
            ? UpdateOrderYieldMutation
            : AddOrderYieldMutation;
          const { item, orderedQuantity } = values;

          const variables: any = {
            input: {
              itemId: item.value,
              orderedQuantity: orderedQuantity && +orderedQuantity,
            },
          };

          if (orderYield) {
            variables.input.id = orderYield.id;
          } else {
            variables.input.orderId = orderId;
          }

          try {
            const orderYieldResponse = await mutation.commit({
              variables,
            });

            onClose();

            Actions.alertNotification(
              i18n.t(`Item Successfully ${orderYield ? 'Updated' : 'Added'}`),
              'success',
            );

            if (!orderYield) {
              Analytics.trackEvent('Add Item on Order', {
                inventoryNumber:
                  orderYieldResponse.addOrderYield.orderYieldEdge.node.item
                    .itemNumber,
                unitsOrdered:
                  orderYieldResponse.addOrderYield.orderYieldEdge.node
                    .orderedQuantity,
              });
            }
          } catch (e) {
            setSubmitting(false);

            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form>
              <ModalHeader
                header={i18n.t(orderYield ? 'Edit Item' : 'Add Item')}
                onClose={onClose}
              />
              <ModalBody withPadding>
                <SelectItemField
                  name="item"
                  label={i18n.t('Item')}
                  placeholder={i18n.t('Search Item or Part #')}
                  error={errors.item}
                  excludeIds={orderYieldEdges
                    .map((edge: Object) => edge.node.item.id)
                    .filter(id => id !== orderYield?.item?.id)}
                />
                <FieldGroup
                  name="orderedQuantity"
                  type="number"
                  label={i18n.t('Quantity Ordered')}
                  placeholder={i18n.t('0')}
                  error={errors.orderedQuantity}
                  extendedLabel={values.item?.data?.quantityUOM?.symbol}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {i18n.t(orderYield ? 'Update Item' : 'Add Item')}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default createFragmentContainer(AddUpdateOrderYieldModal, {
  orderYield: graphql`
    fragment AddUpdateOrderYieldModal_orderYield on OrderYield {
      id
      order {
        id
      }
      orderedQuantity
      item {
        id
        name
        quantityUOM {
          id
          name
          symbol
        }
      }
    }
  `,
  orderYieldEdges: graphql`
    fragment AddUpdateOrderYieldModal_orderYieldEdges on OrderYieldEdge
      @relay(plural: true) {
      node {
        id
        item {
          id
        }
      }
    }
  `,
});

// @flow

import React from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddMachineTypeMutation from 'main-app/mutations/AddMachineType';
import UpdateMachineTypeMutation from 'main-app/mutations/UpdateMachineType';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import type { AddUpdateMachineTypeModal_machineType as LineFragment } from './__generated__/AddUpdateMachineTypeModal_machineType';

type Props = {
  onClose: () => void,
  machineType: ?LineFragment,
};

const AddUpdateMachineTypeModal = ({ machineType, onClose }: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        name: machineType?.name || '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        const mutation = machineType
          ? UpdateMachineTypeMutation
          : AddMachineTypeMutation;

        const input: any = {
          name: values.name,
        };

        if (machineType) {
          input.id = machineType.id;
        }

        try {
          await mutation.commit({
            variables: {
              input,
            },
          });

          onClose();
          Analytics.trackEvent(
            `${machineType ? 'Update' : 'Create'} Work Center Type`,
            {
              machineTypeName: values.name,
            },
          );
          Actions.alertNotification(
            i18n.t(
              `Work Center Type Successfully ${
                machineType ? 'Updated' : 'Added'
              }`,
            ),
            'success',
          );
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader
            header={i18n.t(
              machineType ? 'Edit Work Center Type' : 'Create Work Center Type',
            )}
            onClose={onClose}
          />
          <ModalBody withPadding>
            <FieldGroup
              name="name"
              label={i18n.t('Work Center Type Name')}
              placeholder={i18n.t('Work Center Type Name')}
              error={errors.name}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t(
                machineType
                  ? 'Update Work Center Type'
                  : 'Create Work Center Type',
              )}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(AddUpdateMachineTypeModal, {
  machineType: graphql`
    fragment AddUpdateMachineTypeModal_machineType on MachineType {
      id
      name
    }
  `,
});

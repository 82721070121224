// @flow

import { LabelModel, DiagramEngine } from 'storm-react-diagrams';

class CustomLabelModel extends LabelModel {
  label: string;

  constructor() {
    super('custom-label');
  }

  setLabel(label: string) {
    this.label = label;
  }

  deSerialize(ob: CustomLabelModel, engine: DiagramEngine) {
    super.deSerialize(ob, engine);
    this.label = ob.label;
  }

  serialize() {
    return { ...super.serialize(), label: this.label };
  }
}

export default CustomLabelModel;

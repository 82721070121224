/**
 * @flow
 * @relayHash 22f678fcad9f97efedc57648b4249771
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateWorkflowPartialInput = {|
  workflowId: string,
  name: string,
  isDefault: boolean,
|};
export type UpdateWorkflowPartialMutationVariables = {|
  input: UpdateWorkflowPartialInput
|};
export type UpdateWorkflowPartialMutationResponse = {|
  +updateWorkflowPartial: {|
    +workflowEdge: {|
      +cursor: string,
      +node: {|
        +id: string,
        +name: string,
        +isDefault: boolean,
      |},
    |}
  |}
|};
export type UpdateWorkflowPartialMutation = {|
  variables: UpdateWorkflowPartialMutationVariables,
  response: UpdateWorkflowPartialMutationResponse,
|};
*/


/*
mutation UpdateWorkflowPartialMutation(
  $input: UpdateWorkflowPartialInput!
) {
  updateWorkflowPartial(input: $input) {
    workflowEdge {
      cursor
      node {
        id
        name
        isDefault
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateWorkflowPartialInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateWorkflowPartial",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWorkflowPartialPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "workflowEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "WorkflowEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Workflow",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isDefault",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateWorkflowPartialMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateWorkflowPartialMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateWorkflowPartialMutation",
    "id": null,
    "text": "mutation UpdateWorkflowPartialMutation(\n  $input: UpdateWorkflowPartialInput!\n) {\n  updateWorkflowPartial(input: $input) {\n    workflowEdge {\n      cursor\n      node {\n        id\n        name\n        isDefault\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aaed234cb76414e2ac90a41a1accf93f';
module.exports = node;

// @flow

import React from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import { i18n, Analytics } from 'shared/utils';
import AddDowntimeCategoryMutation from 'main-app/mutations/AddDowntimeCategory';
import UpdateDowntimeCategoryMutation from 'main-app/mutations/UpdateDowntimeCategory';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  SelectDowntimeCategoryField,
} from 'shared/components/form';
import Button from 'shared/components/common/Button';
import type { AddUpdateDowntimeCategoryModal_downtimeCategory as DowntimeCategoryFragment } from './__generated__/AddUpdateDowntimeCategoryModal_downtimeCategory';

type Props = {
  onClose: () => void,
  downtimeCategory: ?DowntimeCategoryFragment,
};

const LANGUAGE = {
  update: {
    title: i18n.t('Edit Downtime Category'),
    submitButton: i18n.t('Update Downtime Category'),
    successMessage: i18n.t('Downtime Category Successfully Updated'),
  },
  add: {
    title: i18n.t('Create Downtime Category'),
    submitButton: i18n.t('Create Downtime Category'),
    successMessage: i18n.t('Downtime Category Successfully Created'),
  },
};

const AddUpdateDowntimeCategoryModal = ({
  onClose,
  downtimeCategory,
}: Props) => {
  const language = LANGUAGE[downtimeCategory ? 'update' : 'add'];

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          parentCategory: downtimeCategory?.parentCategory && {
            value: downtimeCategory?.parentCategory?.id,
            data: downtimeCategory?.parentCategory,
          },
          name: downtimeCategory?.name || '',
        }}
        validationSchema={Yup.object().shape({
          parentCategory: Yup.object().nullable(),
          name: Yup.string().required('Required'),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const mutation = downtimeCategory
            ? UpdateDowntimeCategoryMutation
            : AddDowntimeCategoryMutation;

          const variables: any = {
            input: {
              parentDowntimeCategoryId: values.parentCategory?.value || null,
              name: values.name,
            },
          };

          if (downtimeCategory) {
            variables.input.id = downtimeCategory.id;
          }

          try {
            if (values.parentCategory && values.parentCategory.__isNew__) {
              const {
                addDowntimeCategory,
              } = await AddDowntimeCategoryMutation.commit({
                variables: {
                  input: {
                    name: values.parentCategory?.value,
                  },
                },
              });

              variables.input.parentDowntimeCategoryId =
                addDowntimeCategory.downtimeCategoryEdge.node.id;
            }

            await mutation.commit({
              variables,
            });

            onClose();

            Analytics.trackEvent(
              `${downtimeCategory ? 'Update' : 'Create'} Downtime Category`,
              {
                parentCategory: values.parentCategory?.data?.name,
              },
            );

            Actions.alertNotification(language.successMessage, 'success');
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <ModalHeader header={language.title} onClose={onClose} />
            <ModalBody withPadding>
              <FieldGroup
                name="name"
                label={i18n.t('Downtime Name')}
                placeholder={i18n.t('Enter a Name')}
                error={errors.name}
                tooltip={i18n.t(
                  `Downtime can be tracked by the operator at any time. Cost of downtime is estimated using the work center's Average Run Cost`,
                )}
              />
              {!downtimeCategory?.childCategories.totalCount ? (
                <SelectDowntimeCategoryField
                  name="parentCategory"
                  label={i18n.t('Downtime Category')}
                  creatable
                  clearable
                  error={errors.parentCategory}
                  creatingNewRecord={
                    values.parentCategory && values.parentCategory.__isNew__
                  }
                  queryVariables={{
                    parentsOnly: true,
                  }}
                  tooltip={i18n.t(
                    'Categorize your different downtime options into different buckets for more actionable reports.',
                  )}
                />
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                theme="blue"
                disabled={!isValid}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {language.submitButton}
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

export default createFragmentContainer(AddUpdateDowntimeCategoryModal, {
  downtimeCategory: graphql`
    fragment AddUpdateDowntimeCategoryModal_downtimeCategory on DowntimeCategory {
      id
      parentCategory {
        id
        name
      }
      childCategories(first: null) {
        totalCount
      }
      name
    }
  `,
});

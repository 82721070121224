// @flow

import React from 'react';
import Popper from '@material-ui/core/Popper';
import styled from 'styled-components';
import { fonts, zIndex } from 'shared/styleguide';

type Props = {
  children: any,
  title: React$Node,
};

const TooltipWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 4px 8px;
  color: white;
  margin-top: 4px;
  display: block;
  ${fonts.bodyRegular};
  border-radius: 4px;
`;

// eslint-disable-next-line valid-jsdoc
/**
 * tooltip inspired by material ui tooltip implementation
 * https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Tooltip/Tooltip.js
 */
export default ({ children, title }: Props) => {
  const childRef = React.useRef();
  const childProps = children.props;
  const [open, setIsOpen] = React.useState(false);
  const handleEnter = e => {
    if (childProps.onMouseEnter) {
      childProps.onMouseEnter(e);
    }
    setIsOpen(true);
  };
  const handleLeave = e => {
    if (childProps.onMouseLeave) {
      childProps.onMouseLeave(e);
    }
    setIsOpen(false);
  };
  const modifiedChildrenProps = {
    ...childProps,
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
  };
  return (
    <React.Fragment>
      {React.cloneElement(children, {
        ref: childRef,
        ...modifiedChildrenProps,
      })}
      <Popper
        style={{ zIndex: zIndex.tooltip }}
        anchorEl={childRef.current}
        open={open}
      >
        <TooltipWrapper>{title}</TooltipWrapper>
      </Popper>
    </React.Fragment>
  );
};

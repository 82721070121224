// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import type { RouterHistory } from 'react-router';
import { i18n } from 'shared/utils';
import JobsTable from 'shared/components/common/JobsTable';
import Loader from 'shared/components/common/Loader';

type Props = {
  history: RouterHistory,
  itemId: string,
};

const ItemJobs = ({ itemId, history }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    variables={{
      itemIds: [itemId],
    }}
    query={graphql`
      query ItemJobsQuery($itemIds: [ID!]) {
        jobs(
          itemIds: $itemIds
          sortBy: { field: UPDATED_AT, direction: DESC }
        ) {
          edges {
            ...JobsTable_jobEdges
          }
        }
      }
    `}
    render={({ props }) => {
      if (!props) {
        return <Loader />;
      }
      return (
        <JobsTable
          history={history}
          jobEdges={props?.jobs.edges || null}
          emptyListMessage={i18n.t(
            'Jobs associated with this item will show here.',
          )}
        />
      );
    }}
  />
);

export default ItemJobs;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateMachineMutation($input: UpdateMachineInput!) {
    updateMachine(input: $input) {
      machineEdge {
        cursor
        node {
          id
          name
          type {
            id
            name
          }
          purchaseCost
          lifespanYears
          runQuantityPerHour
          runCostPerHour
          updatedAt
          createdAt
          workflows {
            id
            name
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

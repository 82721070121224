/**
 * @flow
 * @relayHash be408f146316bfc849ab472fe8cec23d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Toolbar_defaultForm$ref = any;
type Toolbar_viewer$ref = any;
type Toolbar_workflowEdges$ref = any;
export type Permission = "CREATE_API_KEY" | "CREATE_CUSTOMER" | "CREATE_ITEM" | "CREATE_JOB" | "CREATE_LOT" | "CREATE_ORDER" | "DELETE_API_KEY" | "DELETE_CUSTOMER" | "DELETE_ITEM" | "DELETE_JOB" | "DELETE_LOT" | "DELETE_ORDER" | "READ_API_KEY" | "READ_CUSTOMER" | "READ_ITEM" | "READ_JOB" | "READ_LOT" | "READ_ORDER" | "UPDATE_API_KEY" | "UPDATE_CUSTOMER" | "UPDATE_ITEM" | "UPDATE_JOB" | "UPDATE_LOT" | "UPDATE_ORDER";
export type PageQueryVariables = {||};
export type PageQueryResponse = {|
  +forms: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +$fragmentRefs: Toolbar_defaultForm$ref,
      |}
    |}>
  |},
  +viewer: ?{|
    +id: string,
    +firstName: string,
    +lastName: string,
    +permissions: $ReadOnlyArray<Permission>,
    +organization: {|
      +name: ?string
    |},
    +company: {|
      +name: ?string
    |},
    +companies: $ReadOnlyArray<{|
      +id: string,
      +name: ?string,
    |}>,
    +updatedAt: any,
    +createdAt: any,
    +$fragmentRefs: Toolbar_viewer$ref,
  |},
  +workflows: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +isDefault: boolean,
      |},
      +$fragmentRefs: Toolbar_workflowEdges$ref,
    |}>
  |},
|};
export type PageQuery = {|
  variables: PageQueryVariables,
  response: PageQueryResponse,
|};
*/


/*
query PageQuery {
  forms(types: [ITEM]) {
    edges {
      node {
        id
        ...Toolbar_defaultForm
      }
    }
  }
  viewer {
    id
    firstName
    lastName
    permissions
    organization {
      name
      id
    }
    company {
      name
      id
    }
    companies {
      id
      name
    }
    updatedAt
    createdAt
    ...Toolbar_viewer
  }
  workflows {
    edges {
      node {
        id
        isDefault
      }
      ...Toolbar_workflowEdges
    }
  }
}

fragment Toolbar_defaultForm on Form {
  id
  ...AddUpdateItemModal_defaultForm
}

fragment Toolbar_viewer on User {
  id
  permissions
  company {
    id
    name
  }
  companies {
    id
    name
  }
  ...UserAvatar_user
}

fragment Toolbar_workflowEdges on WorkflowEdge {
  node {
    id
    isDefault
    ...AddUpdateJobModal_workflow
  }
}

fragment AddUpdateJobModal_workflow on Workflow {
  id
  name
  states {
    id
    isGangRun
  }
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}

fragment AddUpdateItemModal_defaultForm on Form {
  id
  type
  name
  systemKey
  productionType {
    id
  }
  fields {
    id
    systemKey
    type
    name
    description
    placeholder
    isRequired
    isCreatable
    applicationType
    displayOrder
    properties
    options {
      name
      value
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "types",
    "value": [
      "ITEM"
    ]
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "companies",
  "storageKey": null,
  "args": null,
  "concreteType": "Company",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v5/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDefault",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemKey",
  "args": null,
  "storageKey": null
},
v13 = [
  (v5/*: any*/),
  (v1/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "forms",
        "storageKey": "forms(types:[\"ITEM\"])",
        "args": (v0/*: any*/),
        "concreteType": "FormConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "FormEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Form",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "Toolbar_defaultForm",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "Toolbar_viewer",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "workflows",
        "storageKey": null,
        "args": null,
        "concreteType": "WorkflowConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "WorkflowEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Workflow",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v10/*: any*/)
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "Toolbar_workflowEdges",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "forms",
        "storageKey": "forms(types:[\"ITEM\"])",
        "args": (v0/*: any*/),
        "concreteType": "FormConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "FormEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Form",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v11/*: any*/),
                  (v5/*: any*/),
                  (v12/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "productionType",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductionType",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "fields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FormField",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v12/*: any*/),
                      (v11/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "placeholder",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isRequired",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isCreatable",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "applicationType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "displayOrder",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "properties",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "options",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FormFieldOption",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "value",
                            "args": null,
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": (v13/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": (v13/*: any*/)
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "avatar",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "workflows",
        "storageKey": null,
        "args": null,
        "concreteType": "WorkflowConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "WorkflowEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Workflow",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v10/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "states",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WorkflowState",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isGangRun",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PageQuery",
    "id": null,
    "text": "query PageQuery {\n  forms(types: [ITEM]) {\n    edges {\n      node {\n        id\n        ...Toolbar_defaultForm\n      }\n    }\n  }\n  viewer {\n    id\n    firstName\n    lastName\n    permissions\n    organization {\n      name\n      id\n    }\n    company {\n      name\n      id\n    }\n    companies {\n      id\n      name\n    }\n    updatedAt\n    createdAt\n    ...Toolbar_viewer\n  }\n  workflows {\n    edges {\n      node {\n        id\n        isDefault\n      }\n      ...Toolbar_workflowEdges\n    }\n  }\n}\n\nfragment Toolbar_defaultForm on Form {\n  id\n  ...AddUpdateItemModal_defaultForm\n}\n\nfragment Toolbar_viewer on User {\n  id\n  permissions\n  company {\n    id\n    name\n  }\n  companies {\n    id\n    name\n  }\n  ...UserAvatar_user\n}\n\nfragment Toolbar_workflowEdges on WorkflowEdge {\n  node {\n    id\n    isDefault\n    ...AddUpdateJobModal_workflow\n  }\n}\n\nfragment AddUpdateJobModal_workflow on Workflow {\n  id\n  name\n  states {\n    id\n    isGangRun\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n\nfragment AddUpdateItemModal_defaultForm on Form {\n  id\n  type\n  name\n  systemKey\n  productionType {\n    id\n  }\n  fields {\n    id\n    systemKey\n    type\n    name\n    description\n    placeholder\n    isRequired\n    isCreatable\n    applicationType\n    displayOrder\n    properties\n    options {\n      name\n      value\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '08f00d68ac773c3141ecc78cafd9f06a';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateRunModal_job$ref = any;
type AutoScheduleJobModal_job$ref = any;
type DraggableJobCard_job$ref = any;
export type RunStatus = "COMPLETE" | "IN_PROGRESS" | "PAUSED" | "SCHEDULED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobList_jobEdges$ref: FragmentReference;
declare export opaque type JobList_jobEdges$fragmentType: JobList_jobEdges$ref;
export type JobList_jobEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +states: $ReadOnlyArray<{|
      +id: string,
      +isEnabled: boolean,
      +workflowState: {|
        +id: string,
        +name: string,
        +machineType: ?{|
          +id: string,
          +name: string,
        |},
      |},
      +runs: $ReadOnlyArray<{|
        +id: string,
        +createdAt: any,
        +status: RunStatus,
      |}>,
    |}>,
    +workflow: {|
      +id: string,
      +isParallelWorkflow: boolean,
    |},
    +$fragmentRefs: DraggableJobCard_job$ref & AddUpdateRunModal_job$ref & AutoScheduleJobModal_job$ref,
  |},
  +$refType: JobList_jobEdges$ref,
|}>;
export type JobList_jobEdges$data = JobList_jobEdges;
export type JobList_jobEdges$key = {
  +$data?: JobList_jobEdges$data,
  +$fragmentRefs: JobList_jobEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "JobList_jobEdges",
  "type": "JobEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Job",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "states",
          "storageKey": null,
          "args": null,
          "concreteType": "JobState",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isEnabled",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "workflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "machineType",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "MachineType",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "runs",
              "storageKey": null,
              "args": null,
              "concreteType": "Run",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "workflow",
          "storageKey": null,
          "args": null,
          "concreteType": "Workflow",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isParallelWorkflow",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "DraggableJobCard_job",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateRunModal_job",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "AutoScheduleJobModal_job",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba4e430541f4a7e97c1217963e9a6e40';
module.exports = node;

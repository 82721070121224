// @flow

/* eslint-disable react/forbid-component-props */

import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import qs from 'qs';
import moment from 'moment';
import type { RouterHistory } from 'react-router';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import Loader from 'shared/components/common/Loader';
import Button from 'shared/components/common/Button';
import {
  SelectMachineTypeField,
  SelectWorkflowField,
} from 'shared/components/form';
import AutoScheduleJobModal from 'main-app/components/AutoScheduleJobModal';
import ProductionScheduleChart from './ProductionScheduleChart';
import {
  PageWrapper,
  FiltersDesktop,
  FiltersMobile,
  AutoScheduleDropzone,
} from './styled';
import JobListPanel from './JobListPanel';
import RunDetailsPanel from './RunDetailsPanel';

type Props = {
  history: RouterHistory,
};

type State = {
  jobsPanelOpen: boolean,
  runDetailsPanelOpen: boolean,
  selectedJobId: ?string,
  selectedRunId: ?string,
  jobDragging: boolean,
  autoScheduleModalJob: ?Object,
  jobHasParallelWorkflow: boolean,
};

class ProductionSchedule extends PureComponent<Props, State> {
  productionScheduleChart = null;

  // these are used to load the initial viewport for the production gantt chart
  initialStartTime = moment();
  initialEndTime = moment();

  constructor(props: Props) {
    super(props);

    const filters = this.getFilterValues();

    this.initialStartTime = filters.defaultStartTime
      ? moment(filters.defaultStartTime)
      : moment().add(-6, 'hour');
    this.initialEndTime = this.initialStartTime.clone().add(12, 'hour');

    this.state = {
      jobsPanelOpen: filters.jobsPanelOpen === 'true',
      runDetailsPanelOpen: false,
      jobDragging: false,
      autoScheduleModalJob: null,
      selectedJobId: null,
      selectedRunId: null,
      jobHasParallelWorkflow: false,
    };
  }

  setFilters = (filters: Object) => {
    const { history } = this.props;

    history.replace(
      '/production-schedule?' +
        qs.stringify(filters, {
          encode: true,
        }),
    );
  };

  handleViewOnSchedule = (run: Object) => {
    const filters = this.getFilterValues();

    window.location.href =
      '/production-schedule?' +
      qs.stringify(
        {
          defaultStartTime: moment(run.scheduledStartAt)
            .subtract(1, 'hour')
            .format(),
          jobsPanelOpen: true,
          ...filters,
        },
        {
          encode: true,
        },
      );
  };

  setFiltersForJobList = (filters: Object) => {
    const currentFilters = this.getFilterValues();

    if (currentFilters.machineTypeId) {
      filters.machineTypeId = currentFilters.machineTypeId;
    }

    this.setFilters(filters);
  };

  getFilterValues = (): Object => {
    const { history } = this.props;
    const { search } = history.location;
    const defaultVars = {
      machineTypeId: null,
    };

    if (!search) {
      return defaultVars;
    }

    return {
      ...defaultVars,
      ...qs.parse(search, { ignoreQueryPrefix: true }),
    };
  };

  getFilterValuesForJobList = () => {
    const filterValues = this.getFilterValues();

    filterValues.workflowIds = filterValues.workflowId
      ? [filterValues.workflowId]
      : null;

    delete filterValues.machineTypeId;
    delete filterValues.workflowId;

    return filterValues;
  };

  handleJobDragStart = (parallelWorkflow: boolean = false) => {
    this.setState({
      jobDragging: true,
      jobHasParallelWorkflow: parallelWorkflow,
    });
  };

  handleJobDragEnd = () => {
    this.setState({
      jobDragging: false,
      jobHasParallelWorkflow: false,
    });
  };

  handleOpenJobsPanel = () => {
    this.setState({
      jobsPanelOpen: true,
    });
  };

  handleRunSelect = (run: Object) => {
    this.setState({
      selectedJobId: run.jobId,
    });
  };

  handleRunDeselect = () => {
    this.setState({
      selectedJobId: null,
    });
  };

  handleRunDoubleClick = (run: Object) => {
    if (run.type === 'DOWNTIME') return;
    this.setState({
      runDetailsPanelOpen: true,
      selectedRunId: run.runId,
    });
  };

  handleCloseJobsPanel = () => {
    this.setState({
      jobsPanelOpen: false,
    });
  };

  handleCloseRunDetailsPanel = () => {
    this.setState({
      runDetailsPanelOpen: false,
      selectedRunId: null,
    });
  };

  handleRunChanged = () => {
    if (this.productionScheduleChart) {
      this.productionScheduleChart.resetState();
    }
  };

  handleDragJobToSchedule = (pageX: number, pageY: number, job: Object) => {
    if (this.productionScheduleChart) {
      this.productionScheduleChart.dropJobOntoSchedule(pageX, pageY, job);
    }
  };

  handleAutoScheduleSuccess = (runs: Array<Object>) => {
    if (this.productionScheduleChart) {
      this.productionScheduleChart.hydrateJobsAfterAutoSchedule(runs);
    }
  };

  handleJobDropToAutoSchedule = (job: Object) => {
    this.setState({
      autoScheduleModalJob: job,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      autoScheduleModalJob: null,
    });
  };

  renderFilters() {
    const filterValues = this.getFilterValues();

    // FIXME: eliminate the z-indexes here after figuring out what to do with react-calendar-timeline z-index issues
    return (
      <>
        <li style={{ zIndex: 2 }}>
          <SelectWorkflowField
            rawField
            label={null}
            message={null}
            placeholder={i18n.t('All Workflows')}
            error={null}
            style={{
              paddingBottom: 0,
            }}
            value={{
              value: filterValues.workflowId || '',
            }}
            onChange={line =>
              this.setFilters({
                ...filterValues,
                workflowId: line?.value || null,
              })
            }
          />
        </li>
        <li style={{ zIndex: 2 }}>
          <SelectMachineTypeField
            rawField
            label={null}
            message={null}
            placeholder={i18n.t('All Work Centers')}
            error={null}
            style={{
              paddingBottom: 0,
            }}
            value={{
              value: filterValues.machineTypeId || '',
            }}
            onChange={machineType =>
              this.setFilters({
                ...filterValues,
                machineTypeId: machineType?.value || null,
              })
            }
          />
        </li>
      </>
    );
  }

  render() {
    const {
      jobsPanelOpen,
      runDetailsPanelOpen,
      jobDragging,
      autoScheduleModalJob,
      selectedRunId,
      selectedJobId,
      jobHasParallelWorkflow,
    } = this.state;
    const { history } = this.props;
    const filterValues = this.getFilterValues();
    const queryVariables = {
      machineTypeId: filterValues.machineTypeId || null,
      workflowIds: filterValues.workflowId ? [filterValues.workflowId] : null,
      startAt: this.initialStartTime
        .clone()
        .add(-2, 'week')
        .format(),
      endAt: this.initialEndTime
        .clone()
        .add(2, 'week')
        .format(),
    };

    return (
      <QueryRenderer
        environment={relayEnvironment}
        variables={queryVariables}
        cacheConfig={{
          force: true,
        }}
        query={graphql`
          query ProductionScheduleQuery(
            $machineTypeId: ID
            $workflowIds: [ID!]
            $startAt: DateTime!
            $endAt: DateTime!
          ) {
            ...ProductionScheduleChart_data
              @arguments(
                machineTypeId: $machineTypeId
                workflowIds: $workflowIds
                startAt: $startAt
                endAt: $endAt
              )
          }
        `}
        render={({ props }: *) => {
          return (
            <Page noPadding>
              <PageWrapper>
                <PageHeader
                  title={i18n.t('Production Schedule')}
                  right={[
                    <FiltersDesktop>{this.renderFilters()}</FiltersDesktop>,
                    <Button
                      theme="border-white"
                      onClick={this.handleOpenJobsPanel}
                    >
                      {i18n.t('View Open Jobs')}
                    </Button>,
                  ]}
                  style={{
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 24,
                  }}
                />
                <FiltersMobile>{this.renderFilters()}</FiltersMobile>
                {!props ? (
                  <Loader />
                ) : (
                  <>
                    <ProductionScheduleChart
                      ref={r => (this.productionScheduleChart = r)}
                      data={props}
                      initialStartTime={this.initialStartTime}
                      initialEndTime={this.initialEndTime}
                      selectedJobId={selectedJobId}
                      onRunSelect={this.handleRunSelect}
                      onRunDeselect={this.handleRunDeselect}
                      onRunDoubleClick={this.handleRunDoubleClick}
                    />
                  </>
                )}
              </PageWrapper>
              <JobListPanel
                visible={jobsPanelOpen}
                onClickAway={this.handleCloseJobsPanel}
                onJobDropToSchedule={this.handleDragJobToSchedule}
                onJobDropToAutoSchedule={this.handleJobDropToAutoSchedule}
                onJobDragStart={this.handleJobDragStart}
                onJobDragEnd={this.handleJobDragEnd}
                onFiltersChange={filters => this.setFiltersForJobList(filters)}
                filterValues={this.getFilterValuesForJobList()}
                onViewOnSchedule={this.handleViewOnSchedule}
                history={history}
              />
              {runDetailsPanelOpen && (
                <RunDetailsPanel
                  runId={selectedRunId}
                  visible={runDetailsPanelOpen}
                  onClickAway={this.handleCloseRunDetailsPanel}
                  onRunChanged={this.handleRunChanged}
                />
              )}
              {!jobHasParallelWorkflow && jobDragging && (
                <AutoScheduleDropzone className="auto-schedule-dropzone">
                  {i18n.t('Drag and Drop Here to Auto Schedule')}
                </AutoScheduleDropzone>
              )}
              {autoScheduleModalJob && (
                <AutoScheduleJobModal
                  job={autoScheduleModalJob}
                  onClose={this.handleCloseAllModals}
                  onSuccess={this.handleAutoScheduleSuccess}
                />
              )}
            </Page>
          );
        }}
      />
    );
  }
}

export default ProductionSchedule;

// @flow

import React, { PureComponent } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import debounce from 'lodash.debounce';
import type { RouterHistory } from 'react-router';
import { BrowserUtils } from 'shared/utils';
import LoadingMore from 'shared/components/common/LoadingMore';
import EmptySearchResults from 'shared/components/common/EmptySearchResults';
import LotsTable from 'shared/components/common/LotsTable';
import type { PaginatedLotsContainer_lots as LotsFragment } from './__generated__/PaginatedLotsContainer_lots';

type Props = {
  history: RouterHistory,
  filtersDirty: boolean,
  onCreateNew: () => void,
  lots: LotsFragment,
  deleteEnabled: boolean,
  editEnabled: boolean,
  relay: {
    environment: Object,
    hasMore: () => boolean,
    isLoading: () => boolean,
    loadMore: (pageSize: number, callback: Function) => void,
  },
};

class PaginatedLotsContainer extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onScrollEndReached = debounce(this.onScrollEndReached, 100);
  }
  componentDidMount = () => {
    document.addEventListener('scroll', this.onScrollEndReached);
  };

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.onScrollEndReached);
  };

  onScrollEndReached = () => {
    const { relay } = this.props;
    if (BrowserUtils.scrollEndReached(200)) {
      if (relay.hasMore()) {
        relay.loadMore(30);
      }
    }
  };

  render() {
    const {
      history,
      onCreateNew,
      lots,
      relay,
      filtersDirty,
      editEnabled,
      deleteEnabled,
    } = this.props;

    if ((!lots.lots.edges || !lots.lots.edges.length) && filtersDirty) {
      return <EmptySearchResults />;
    }

    return (
      <>
        <LotsTable
          history={history}
          onCreateNew={onCreateNew}
          lotEdges={lots.lots.edges}
          deleteEnabled={deleteEnabled}
          editEnabled={editEnabled}
        />
        {relay.hasMore() && relay.isLoading() ? <LoadingMore /> : null}
      </>
    );
  }
}

export default createPaginationContainer(
  PaginatedLotsContainer,
  {
    lots: graphql`
      fragment PaginatedLotsContainer_lots on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 30 }
          cursor: { type: "String" }
          search: { type: "String" }
          sortBy: { type: "LotSort" }
          lotNumbers: { type: "[String!]" }
          binNumbers: { type: "[String!]" }
        ) {
        lots(
          first: $count
          after: $cursor
          search: $search
          sortBy: $sortBy
          lotNumbers: $lotNumbers
          binNumbers: $binNumbers
        ) @connection(key: "PaginatedLotsContainer_lots", filters: []) {
          edges {
            cursor
            ...LotsTable_lotEdges
          }
        }
      }
    `,
  },
  {
    query: graphql`
      query PaginatedLotsContainerQuery(
        $count: Int!
        $cursor: String
        $search: String
        $sortBy: LotSort
        $lotNumbers: [String!]
        $binNumbers: [String!]
      ) {
        ...PaginatedLotsContainer_lots
          @arguments(
            count: $count
            cursor: $cursor
            search: $search
            sortBy: $sortBy
            lotNumbers: $lotNumbers
            binNumbers: $binNumbers
          )
      }
    `,
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return { ...fragmentVariables, count, cursor };
    },
  },
);

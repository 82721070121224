// @flow

import React, { PureComponent } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import debounce from 'lodash.debounce';
import type { RouterHistory } from 'react-router';
import { BrowserUtils } from 'shared/utils';
import LoadingMore from 'shared/components/common/LoadingMore';
import EmptySearchResults from 'shared/components/common/EmptySearchResults';
import OrdersTable from 'shared/components/common/OrdersTable';
import type { PaginatedOrdersContainer_orders as OrdersFragment } from './__generated__/PaginatedOrdersContainer_orders';

type Props = {
  history: RouterHistory,
  filtersDirty: boolean,
  onCreateNew: () => void,
  orders: OrdersFragment,
  deleteEnabled: boolean,
  editEnabled: boolean,
  relay: {
    environment: Object,
    hasMore: () => boolean,
    isLoading: () => boolean,
    loadMore: (pageSize: number, callback: Function) => void,
  },
};

class PaginatedOrdersContainer extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onScrollEndReached = debounce(this.onScrollEndReached, 100);
  }
  componentDidMount = () => {
    document.addEventListener('scroll', this.onScrollEndReached);
  };

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.onScrollEndReached);
  };

  onScrollEndReached = () => {
    const { relay } = this.props;
    if (BrowserUtils.scrollEndReached(200)) {
      if (relay.hasMore()) {
        relay.loadMore(30);
      }
    }
  };

  render() {
    const {
      history,
      onCreateNew,
      orders,
      relay,
      filtersDirty,
      editEnabled,
      deleteEnabled,
    } = this.props;

    if ((!orders.orders.edges || !orders.orders.edges.length) && filtersDirty) {
      return <EmptySearchResults />;
    }

    return (
      <>
        <OrdersTable
          history={history}
          onCreateNew={onCreateNew}
          orderEdges={orders.orders.edges}
          deleteEnabled={deleteEnabled}
          editEnabled={editEnabled}
        />
        {relay.hasMore() && relay.isLoading() ? <LoadingMore /> : null}
      </>
    );
  }
}

export default createPaginationContainer(
  PaginatedOrdersContainer,
  {
    orders: graphql`
      fragment PaginatedOrdersContainer_orders on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 30 }
          cursor: { type: "String" }
          search: { type: "String" }
          sortBy: { type: "OrderSort" }
          orderNumbers: { type: "[String!]" }
          customerOrderNumbers: { type: "[String!]" }
          customerIds: { type: "[ID!]" }
          salesUserIds: { type: "[ID!]" }
          statuses: { type: "[OrderStatus!]" }
          promisedAtStart: { type: "Date" }
          promisedAtEnd: { type: "Date" }
          orderedAtStart: { type: "Date" }
          orderedAtEnd: { type: "Date" }
          requiredAtStart: { type: "Date" }
          requiredAtEnd: { type: "Date" }
        ) {
        orders(
          first: $count
          after: $cursor
          search: $search
          sortBy: $sortBy
          orderNumbers: $orderNumbers
          customerOrderNumbers: $customerOrderNumbers
          customerIds: $customerIds
          salesUserIds: $salesUserIds
          statuses: $statuses
          promisedAtStart: $promisedAtStart
          promisedAtEnd: $promisedAtEnd
          orderedAtStart: $orderedAtStart
          orderedAtEnd: $orderedAtEnd
          requiredAtStart: $requiredAtStart
          requiredAtEnd: $requiredAtEnd
        ) @connection(key: "PaginatedOrdersContainer_orders", filters: []) {
          edges {
            cursor
            ...OrdersTable_orderEdges
          }
        }
      }
    `,
  },
  {
    query: graphql`
      query PaginatedOrdersContainerQuery(
        $count: Int!
        $cursor: String
        $search: String
        $sortBy: OrderSort
        $orderNumbers: [String!]
        $customerOrderNumbers: [String!]
        $customerIds: [ID!]
        $statuses: [OrderStatus!]
        $promisedAtStart: Date
        $promisedAtEnd: Date
        $orderedAtStart: Date
        $orderedAtEnd: Date
        $requiredAtStart: Date
        $requiredAtEnd: Date
      ) {
        ...PaginatedOrdersContainer_orders
          @arguments(
            count: $count
            cursor: $cursor
            search: $search
            sortBy: $sortBy
            orderNumbers: $orderNumbers
            customerOrderNumbers: $customerOrderNumbers
            customerIds: $customerIds
            statuses: $statuses
            promisedAtStart: $promisedAtStart
            promisedAtEnd: $promisedAtEnd
            orderedAtStart: $orderedAtStart
            orderedAtEnd: $orderedAtEnd
            requiredAtStart: $requiredAtStart
            requiredAtEnd: $requiredAtEnd
          )
      }
    `,
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return { ...fragmentVariables, count, cursor };
    },
  },
);

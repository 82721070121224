/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateJobSubComponentModal_job$ref = any;
type JobSubComponentsTree_jobSubComponentEdges$ref = any;
type JobSubComponentsTree_jobYieldEdges$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobSubComponents_job$ref: FragmentReference;
declare export opaque type JobSubComponents_job$fragmentType: JobSubComponents_job$ref;
export type JobSubComponents_job = {|
  +id: string,
  +subComponents: {|
    +edges: ?$ReadOnlyArray<?{|
      +usageQty: ?number,
      +node: {|
        +id: string,
        +name: string,
        +partNumber: ?string,
      |},
      +$fragmentRefs: JobSubComponentsTree_jobSubComponentEdges$ref,
    |}>
  |},
  +yields: {|
    +edges: ?$ReadOnlyArray<?{|
      +$fragmentRefs: JobSubComponentsTree_jobYieldEdges$ref
    |}>
  |},
  +$fragmentRefs: AddUpdateJobSubComponentModal_job$ref,
  +$refType: JobSubComponents_job$ref,
|};
export type JobSubComponents_job$data = JobSubComponents_job;
export type JobSubComponents_job$key = {
  +$data?: JobSubComponents_job$data,
  +$fragmentRefs: JobSubComponents_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "JobSubComponents_job",
  "type": "Job",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "subComponents"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "yields"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": "subComponents",
      "name": "__JobSubComponents_subComponents_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "JobSubComponentConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "JobSubComponentEdge",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "usageQty",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Item",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "partNumber",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/)
              ]
            },
            (v2/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "JobSubComponentsTree_jobSubComponentEdges",
              "args": null
            }
          ]
        },
        (v3/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "yields",
      "name": "__JobSubComponents_job_yields_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "JobYieldConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "JobYieldEdge",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobYield",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "JobSubComponentsTree_jobYieldEdges",
              "args": null
            }
          ]
        },
        (v3/*: any*/)
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "AddUpdateJobSubComponentModal_job",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b9237785ee39e7cf557ed261a537b120';
module.exports = node;

// @flow

class BrowserUtils {
  /**
   * Cross-browser JS method for returning the browser's current width
   * @return {number}
   */
  static getWidth(): number {
    return Math.max(
      document.body?.scrollWidth || 0,
      document.documentElement?.scrollWidth || 0,
      document.body?.offsetWidth || 0,
      document.documentElement?.offsetWidth || 0,
      document.documentElement?.clientWidth || 0,
    );
  }

  static getHeight(): number {
    return Math.max(
      document.body?.scrollHeight || 0,
      document.documentElement?.scrollHeight || 0,
      document.body?.offsetHeight || 0,
      document.documentElement?.offsetHeight || 0,
      document.body?.clientHeight || 0,
      document.documentElement?.clientHeight || 0,
    );
  }

  static scrollEndReached(offset?: number = 0): boolean {
    const documentHeight = BrowserUtils.getHeight();
    const scrollY = BrowserUtils.getScrollXY()[1];

    return documentHeight - (scrollY + window.innerHeight) <= offset;
  }

  // below taken from http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
  static getScrollXY() {
    const { body, documentElement } = document;

    let scrollX = 0;
    let scrollY = 0;
    if (typeof window.pageYOffset == 'number') {
      // Netscape compliant
      scrollY = window.pageYOffset;
      scrollX = window.pageXOffset;
    } else if (body && (body.scrollLeft || body.scrollTop)) {
      // DOM compliant
      scrollY = body.scrollTop;
      scrollX = body.scrollLeft;
    } else if (
      documentElement &&
      (documentElement.scrollLeft || documentElement.scrollTop)
    ) {
      // IE6 standards compliant mode
      scrollY = documentElement.scrollTop;
      scrollX = documentElement.scrollLeft;
    }

    return [scrollX, scrollY];
  }
}

export default BrowserUtils;

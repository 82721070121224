// @flow

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import UserAvatar from 'shared/components/common/UserAvatar';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  ContextMenuTrigger,
  ContextMenu,
  MenuItem as ContextMenuItem,
} from 'react-contextmenu';
import { withRouter, type RouterHistory } from 'react-router-dom';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import AddUpdateJobModal from 'main-app/components/AddUpdateJobModal';
import AddUpdateOrderModal from 'main-app/components/AddUpdateOrderModal';
import AddUpdateCustomerModal from 'main-app/components/AddUpdateCustomerModal';
import AddUpdateItemModal from 'main-app/components/AddUpdateItemModal';
import AddUpdateLotModal from 'main-app/components/AddUpdateLotModal';
import SwitchSessionCompanyMutation from 'main-app/mutations/SwitchSessionCompany';
import * as Actions from 'main-app/store/Actions';
import type { Toolbar_defaultForm as DefaultFormFragment } from './__generated__/Toolbar_defaultForm';
import type { Toolbar_viewer as ViewerFragment } from './__generated__/Toolbar_viewer';
import type { Toolbar_workflowEdges as WorkflowEdgesFragment } from './__generated__/Toolbar_workflowEdges';

type Props = {
  history: RouterHistory,
  defaultForm: ?DefaultFormFragment,
  viewer: ?ViewerFragment,
  workflowEdges: WorkflowEdgesFragment,
};

type State = {
  newJobModalOpen: boolean,
  newOrderModalOpen: boolean,
  newCustomerModalOpen: boolean,
  newItemModalOpen: boolean,
  newLotModalOpen: boolean,
  createAnotherJob: boolean,
  createAnotherOrder: boolean,
  createAnotherCustomer: boolean,
  createAnotherItem: boolean,
  createAnotherLot: boolean,
};

const SETTINGS_MENU_ITEMS = [
  {
    label: i18n.t('My Profile'),
    path: `/settings/profile`,
  },
  // {
  //   label: i18n.t('Company'),
  //   path: `/settings/company`,
  // },
  // {
  //   label: i18n.t('Team'),
  //   path: `/settings/team`,
  // },
  {
    label: i18n.t('Users'),
    path: `/settings/users`,
  },
  {
    label: i18n.t('Workflows'),
    path: `/settings/workflows`,
  },
  {
    label: i18n.t('Work Centers'),
    path: `/settings/machines`,
  },
  {
    label: i18n.t('Work Center Types'),
    path: `/settings/machine-types`,
  },
  {
    label: i18n.t('Downtime'),
    path: `/settings/downtime-categories`,
  },
  {
    label: i18n.t('Operating Hours'),
    path: `/settings/operating-hours`,
  },
  {
    label: i18n.t('Forms'),
    path: `/settings/forms`,
  },
  {
    label: i18n.t('Integrations'),
    path: `/settings/integrations`,
  },
  {
    label: i18n.t('API Keys'),
    path: `/settings/api-keys`,
  },
];

const CompanyButton = styled.div`
  display: inline-block;
  padding: 8px 16px;
  border-radius: 100px;
  background-color: ${colors.paleGreyTwo};
  ${fonts.smallBold};
  color: ${colors.charcoalGrey};

  @media (min-width: 1000px) {
    display: inline-block;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Wrapper = styled.div`
  display: none;

  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CreateButtonWrapper = styled.div`
  margin-right: 24px;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusWrapper = styled.div`
  margin-right: 14px;
  font-size: 20px;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover,
  &:hover svg {
    cursor: pointer;
  }
`;

const Toolbar = ({ defaultForm, viewer, workflowEdges, history }: Props) => {
  const contextMenuItems = [];
  const INITIAL_STATE = {
    newJobModalOpen: false,
    newOrderModalOpen: false,
    newCustomerModalOpen: false,
    newItemModalOpen: false,
    newLotModalOpen: false,
    createAnotherJob: false,
    createAnotherOrder: false,
    createAnotherCustomer: false,
    createAnotherItem: false,
    createAnotherLot: false,
  };
  const companyButtonTriggerRef: any = useRef(null);
  const createButtonTriggerRef: any = useRef(null);
  const helpCenterTriggerRef: any = useRef(null);
  const profileSettingsTriggerRef: any = useRef(null);
  const [modalStatus: State, setModalStatus] = useState(INITIAL_STATE);
  const {
    newJobModalOpen,
    newOrderModalOpen,
    newCustomerModalOpen,
    newItemModalOpen,
    newLotModalOpen,
    createAnotherJob,
    createAnotherOrder,
    createAnotherCustomer,
    createAnotherItem,
    createAnotherLot,
  } = modalStatus;

  // Mimics the current class implementation of this.setState
  const setState = nextState => {
    setModalStatus(prevState => ({ ...prevState, ...nextState }));
  };

  /**
   * This click handler occurs in a React onClick event
   * With hooks, multiple setState calls within the same React event will be batched unless they're async
   */
  const handleCloseAllModals = () => {
    setState(INITIAL_STATE);
  };

  /**
   * These async handlers are async because awaiting setState will force a re-render after each setState call
   * This is desired so that we can clear the old form and open a new form
   * Because these handlers are not called within a React event, they would cause a re-render after each call
   * regardless if they were async
   */
  const handleAddJobModalReopen = async () => {
    await setState({ ...INITIAL_STATE, createAnotherJob: true });
    await setState({ newJobModalOpen: true });
  };

  const handleAddOrderModalReopen = async () => {
    await setState({ ...INITIAL_STATE, createAnotherOrder: true });
    await setState({ newOrderModalOpen: true });
  };

  const handleAddCustomerModalReopen = async () => {
    await setState({ ...INITIAL_STATE, createAnotherCustomer: true });
    await setState({ newCustomerModalOpen: true });
  };

  const handleAddItemModalReopen = async () => {
    await setState({ ...INITIAL_STATE, createAnotherItem: true });
    await setState({ newItemModalOpen: true });
  };

  const handleAddLotModalReopen = async () => {
    await setState({ ...INITIAL_STATE, createAnotherLot: true });
    await setState({ newLotModalOpen: true });
  };

  const handleSwitchCompany = async companyId => {
    try {
      await SwitchSessionCompanyMutation.commit({
        variables: {
          input: {
            companyId,
          },
        },
      });

      window.location = '/';
    } catch (e) {
      Actions.alertNotification(e.message, 'error');
    }
  };

  if (viewer && viewer.permissions.includes('CREATE_JOB')) {
    contextMenuItems.push({
      label: i18n.t('Create New Job Ticket'),
      icon: 'job',
      handleClick: () => setState({ newJobModalOpen: true }),
    });
  }

  if (viewer && viewer.permissions.includes('CREATE_ORDER')) {
    contextMenuItems.push({
      label: i18n.t('Create New Order'),
      icon: 'order',
      handleClick: () => setState({ newOrderModalOpen: true }),
    });
  }

  if (viewer && viewer.permissions.includes('CREATE_CUSTOMER')) {
    contextMenuItems.push({
      label: i18n.t('Create New Customer'),
      icon: 'customer',
      handleClick: () => setState({ newCustomerModalOpen: true }),
    });
  }

  if (viewer && viewer.permissions.includes('CREATE_ITEM')) {
    contextMenuItems.push({
      label: i18n.t('Create New Item'),
      icon: 'item',
      handleClick: () => setState({ newItemModalOpen: true }),
    });
  }

  if (viewer && viewer.permissions.includes('CREATE_LOT')) {
    contextMenuItems.push({
      label: i18n.t('Create New Lot'),
      icon: 'lot',
      handleClick: () => setState({ newLotModalOpen: true }),
    });
  }

  return (
    <Wrapper>
      <LeftWrapper>
        {viewer && (
          <>
            <ContextMenuTrigger
              ref={companyButtonTriggerRef}
              id="context-menu-company-button"
            >
              <CompanyButton
                onClick={e => {
                  if (companyButtonTriggerRef.current) {
                    companyButtonTriggerRef.current.handleContextClick(e);
                  }
                }}
              >
                {viewer.company.name}
              </CompanyButton>
            </ContextMenuTrigger>
            {viewer.companies.length && (
              <ContextMenu id="context-menu-company-button">
                {viewer.companies.map(company => (
                  <ContextMenuItem
                    key={company.id}
                    onClick={() => handleSwitchCompany(company.id)}
                  >
                    {company.name}
                  </ContextMenuItem>
                ))}
              </ContextMenu>
            )}
          </>
        )}
      </LeftWrapper>
      <RightWrapper>
        <ContextMenuTrigger
          ref={createButtonTriggerRef}
          id="context-menu-create-button"
        >
          <CreateButtonWrapper>
            <Button
              onClick={e => {
                if (createButtonTriggerRef.current) {
                  createButtonTriggerRef.current.handleContextClick(e);
                }
              }}
            >
              <ButtonContentWrapper>
                <PlusWrapper>+</PlusWrapper>
                <div>{i18n.t('Create New')}</div>
              </ButtonContentWrapper>
            </Button>
          </CreateButtonWrapper>
        </ContextMenuTrigger>
        <ContextMenu id="context-menu-create-button">
          {contextMenuItems.map(menuItem => (
            <ContextMenuItem
              key={'create-menu-' + menuItem.icon}
              onClick={menuItem.handleClick}
            >
              <MenuItemWrapper>
                <Icon
                  type={menuItem.icon}
                  size={24}
                  style={{ marginRight: 8 }}
                />
                {menuItem.label}
              </MenuItemWrapper>
            </ContextMenuItem>
          ))}
        </ContextMenu>
        <ContextMenuTrigger
          ref={helpCenterTriggerRef}
          id="context-menu-help-center"
        >
          <Icon
            type="circle-question"
            size={16}
            style={{ marginRight: 30 }}
            onClick={e => {
              if (helpCenterTriggerRef.current) {
                helpCenterTriggerRef.current.handleContextClick(e);
              }
            }}
          />
        </ContextMenuTrigger>
        <ContextMenuTrigger
          ref={profileSettingsTriggerRef}
          id="context-menu-profile-settings"
        >
          <MenuItemWrapper
            onClick={e => {
              if (profileSettingsTriggerRef.current) {
                profileSettingsTriggerRef.current.handleContextClick(e);
              }
            }}
          >
            <UserAvatar width={32} user={viewer} />
            <Icon type="more-down-arrow" size={24} />
          </MenuItemWrapper>
        </ContextMenuTrigger>
        <ContextMenu id="context-menu-profile-settings">
          {SETTINGS_MENU_ITEMS.map(menuItem => (
            <ContextMenuItem
              key={'context-menu-profile-settings-' + menuItem.label}
              onClick={() => history.push(menuItem.path)}
            >
              {menuItem.label}
            </ContextMenuItem>
          ))}
        </ContextMenu>
      </RightWrapper>
      {newJobModalOpen && (
        <AddUpdateJobModal
          onClose={handleCloseAllModals}
          onSuccess={job => history.push(`/job/${job.id}`)}
          onReopen={handleAddJobModalReopen}
          createAnotherJob={createAnotherJob}
          job={null}
          companyId={viewer?.company?.id}
          workflow={
            (workflowEdges &&
              workflowEdges.find(edge => edge?.node.isDefault)?.node) ||
            null
          }
        />
      )}
      {newOrderModalOpen && (
        <AddUpdateOrderModal
          onClose={handleCloseAllModals}
          onSuccess={order => history.push(`/order/${order.id}`)}
          onReopen={handleAddOrderModalReopen}
          createAnotherOrder={createAnotherOrder}
          order={null}
          companyId={viewer?.company?.id}
        />
      )}
      {newCustomerModalOpen && (
        <AddUpdateCustomerModal
          onClose={handleCloseAllModals}
          onSuccess={customer => history.push(`/customer/${customer.id}`)}
          onReopen={handleAddCustomerModalReopen}
          createAnotherCustomer={createAnotherCustomer}
          customer={null}
        />
      )}
      {newItemModalOpen && (
        <AddUpdateItemModal
          onClose={handleCloseAllModals}
          onSuccess={item => history.push(`/item/${item.id}`)}
          onReopen={handleAddItemModalReopen}
          createAnotherItem={createAnotherItem}
          item={null}
          defaultForm={defaultForm}
        />
      )}
      {newLotModalOpen && (
        <AddUpdateLotModal
          onClose={handleCloseAllModals}
          onSuccess={lot => history.push(`/lot/${lot.id}`)}
          onReopen={handleAddLotModalReopen}
          createAnotherLot={createAnotherLot}
          lot={null}
        />
      )}
    </Wrapper>
  );
};

export default createFragmentContainer(withRouter(Toolbar), {
  viewer: graphql`
    fragment Toolbar_viewer on User {
      id
      permissions
      company {
        id
        name
      }
      companies {
        id
        name
      }
      ...UserAvatar_user
    }
  `,
  defaultForm: graphql`
    fragment Toolbar_defaultForm on Form {
      id
      ...AddUpdateItemModal_defaultForm
    }
  `,
  workflowEdges: graphql`
    fragment Toolbar_workflowEdges on WorkflowEdge @relay(plural: true) {
      node {
        id
        isDefault
        ...AddUpdateJobModal_workflow
      }
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import Panel from 'shared/components/common/Panel';
import {
  Formik,
  Form,
  FieldGroup,
  SelectFormField,
  SelectUserField,
} from 'shared/components/form';
import * as Yup from 'yup';
import * as Actions from 'main-app/store/Actions';
import Button from 'shared/components/common/Button';
import Icon from 'shared/components/common/Icon';
import Divider from 'shared/components/common/Divider';
import styled from 'styled-components';
import { fonts } from 'shared/styleguide';
import IconNodeModel from '../IconNode/IconNodeModel';
import DeleteRecordModal from '../Modals/DeleteRecordModal';

type Props = {
  onClose: () => void,
  node: IconNodeModel,
  panelStyle: Object,
};

type State = {
  showDeleteRecordModal: boolean,
};

const FormContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  ${fonts.largeBold};
`;

class AddUpdateTaskPanel extends PureComponent<Props, State> {
  state = {
    showDeleteRecordModal: false,
  };

  handleShowDeleteRecordModal = () => {
    this.setState({ showDeleteRecordModal: true });
  };

  handleCloseDeleteRecordModal = (cb?: () => void) => {
    this.setState({ showDeleteRecordModal: false }, () => {
      if (cb) {
        cb();
      }
    });
  };

  handleDeleteNode = () => {
    const { node, onClose } = this.props;

    node.remove();
    this.handleCloseDeleteRecordModal(onClose);
    Actions.alertNotification(
      {
        title: 'State Deleted Successfully!',
        body: null,
      },
      'success',
    );
  };

  render() {
    const { node, onClose, panelStyle } = this.props;
    const { showDeleteRecordModal } = this.state;

    return (
      <Panel
        headerLeft={
          <HeaderLeft>
            <Icon type="circle-task" size={32} style={{ marginRight: 8 }} />
            {i18n.t('Task Settings')}
          </HeaderLeft>
        }
        headerRight={
          <>
            <Icon
              type="circle-trash"
              size={24}
              onClick={this.handleShowDeleteRecordModal}
              style={{
                marginRight: 12,
              }}
            />
            <Icon
              type="circle-close"
              size={24}
              onClick={() => {
                onClose();
              }}
            />
          </>
        }
        style={{
          ...panelStyle,
        }}
      >
        <Formik
          initialValues={{
            name: node.data?.name || '',
            form: node.data?.form?.data
              ? {
                  data: node.data?.form?.data,
                  value: node.data?.form?.data.id,
                  label: node.data?.form?.label,
                }
              : null,
            isAssignable: node.data?.isAssignable
              ? node.data?.isAssignable
              : false,
            defaultUser: node.data?.defaultUser?.data
              ? {
                  data: node.data?.defaultUser?.data,
                  value: node.data?.defaultUser?.data.id,
                  label: node.data?.defaultUser?.label,
                }
              : null,
            isRequired:
              node.data?.isRequired !== undefined
                ? node.data?.isRequired
                : true,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            form: Yup.object().nullable(),
            isAssignable: Yup.boolean().required(),
            defaultUser: Yup.object().nullable(),
            isRequired: Yup.boolean().required(),
          })}
          onSubmit={(values: *, { setSubmitting }: *) => {
            try {
              setSubmitting(true);
              node.data = { ...node.data, ...values, saved: true };
              onClose();
              Actions.alertNotification(
                {
                  title: i18n.t('Task Successfully Updated'),
                  body: i18n.t(
                    'Please remember to click "Publish Workflow" to save any changes.',
                  ),
                },
                'success',
              );
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(
                e.message,
                i18n.t('Something Went Wrong'),
              );
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              style={{
                position: 'relative',
                paddingBottom: 60,
                height: 'calc(100% - 60px)',
              }}
            >
              <FormContent>
                <FieldGroup
                  name="name"
                  label={i18n.t('Task Name')}
                  placeholder={i18n.t('Task Name')}
                  error={errors.name}
                />
                <SelectFormField
                  name="form"
                  label={i18n.t('Select Form')}
                  placeholder={i18n.t('Select A Form')}
                  error={errors.form}
                  queryVariables={{
                    types: ['JOB_STEP'],
                  }}
                  tooltip={i18n.t(
                    'Forms are available to fill out on the job ticket and referenced on the operator portal.',
                  )}
                />
                <Divider />
                <FieldGroup
                  type="toggle"
                  sideLabel="left"
                  name="isRequired"
                  label={i18n.t('Required')}
                  error={errors.isRequired}
                  tooltip={i18n.t(
                    `If on, we'll let you know that this task should be completed on the workflow.`,
                  )}
                />
                <Divider />
                <FieldGroup
                  type="toggle"
                  sideLabel="left"
                  name="isAssignable"
                  label={i18n.t('Assignable to User?')}
                  error={errors.isAssignable}
                />
                {values.isAssignable ? (
                  <SelectUserField
                    name="defaultUser"
                    label={i18n.t('Default Assignee')}
                    error={errors.defaultUser}
                  />
                ) : (
                  <div style={{ marginBottom: 12 }} />
                )}
                <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                  <Divider style={{ marginBottom: 16 }} />
                  <Button
                    type="submit"
                    theme="blue"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                    width="100%"
                  >
                    {i18n.t('Save')}
                  </Button>
                </div>
              </FormContent>
            </Form>
          )}
        />
        {showDeleteRecordModal && (
          <DeleteRecordModal
            onDeleteRecord={this.handleDeleteNode}
            onClose={this.handleCloseDeleteRecordModal}
            type="node"
          />
        )}
      </Panel>
    );
  }
}

export default AddUpdateTaskPanel;

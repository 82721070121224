// @flow

import React, { PureComponent } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import relayEnvironment from 'shared/gql/relayEnvironment';
import SortableTree, {
  toggleExpandedForAll,
  getVisibleNodeCount,
} from 'react-sortable-tree';
import ReactTooltip from 'react-tooltip';
import isEqual from 'lodash.isequal';
import { createFragmentContainer, graphql, fetchQuery } from 'react-relay';
import { i18n, createTooltip } from 'shared/utils';
import { Link } from 'react-router-dom';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import {
  Branch,
  Header,
  Column,
  ItemNumber,
  ButtonWrapper,
  TooltipTitle,
  TooltipContent,
} from 'shared/components/common/BOMTree';
import DeleteJobSubComponentModal from 'main-app/components/DeleteJobSubComponentModal';
import AddUpdateJobSubComponentModal from 'main-app/components/AddUpdateJobSubComponentModal';
import type { JobSubComponentsTree_jobSubComponentEdges as JobSubComponentEdgesFragment } from './__generated__/JobSubComponentsTree_jobSubComponentEdges';
import type { JobSubComponentsTree_jobYieldEdges as JobYieldEdgesFragment } from './__generated__/JobSubComponentsTree_jobYieldEdges';

type Props = {
  jobSubComponentEdges: JobSubComponentEdgesFragment,
  jobYieldEdges: JobYieldEdgesFragment,
  jobId: string,
  setComponents: (Array<?Object>) => void,
};

type State = {
  deleteJobSubComponentRecord: ?Object,
  updateJobSubComponentRecord: ?Object,
  treeData: ?Array<Object>,
  toggle: ?boolean,
};

const JobSubComponentsTreeItemQuery = graphql`
  query JobSubComponentsTreeItemQuery($id: ID!) {
    item(id: $id) {
      id
      name
      itemNumber
      partNumber
      upc
      totalQty
      quantityUOM {
        id
        symbol
      }
      category {
        id
        name
      }
      lotTracking
      childItems(first: null) @connection(key: "Item_childItems", filters: []) {
        edges {
          usageQty
          node {
            id
            itemNumber
            name
            partNumber
            upc
            totalQty
            quantityUOM {
              id
              symbol
            }
            category {
              id
              name
            }
            lotTracking
          }
        }
      }
    }
  }
`;

class JobSubComponentsTree extends PureComponent<Props, State> {
  menuTriggerRefs = {};
  state = {
    deleteJobSubComponentRecord: null,
    updateJobSubComponentRecord: null,
    treeData: [],
    toggle: true,
  };

  componentDidMount() {
    this.formatTreeData();
  }

  componentDidUpdate(prevProps) {
    const { jobSubComponentEdges } = this.props;
    const isSame = isEqual(
      prevProps.jobSubComponentEdges,
      jobSubComponentEdges,
    );

    if (prevProps.jobSubComponentEdges && jobSubComponentEdges && !isSame) {
      this.formatTreeData();
    }
  }

  formatTreeData = async () => {
    const { jobSubComponentEdges, jobYieldEdges, setComponents } = this.props;
    const yieldTreeData = await this.getBranches(
      [...jobYieldEdges],
      'yield',
      true,
    );
    const componentTreeData = await this.getBranches(
      [...jobSubComponentEdges],
      'component',
      true,
    );
    const treeData = [...yieldTreeData, ...componentTreeData];

    this.setState(
      {
        treeData,
        toggle: true,
      },
      () => setComponents(treeData),
    );
  };

  getBranchContent = (item: Object, type: string, root: ?boolean = false) => {
    if (!item) return null;

    const { id, itemNumber, name, quantityUOM, usageQty, productionQty } = item;

    return (
      <Branch>
        <ItemNumber>
          <Link to={`/item/${id}`}>{itemNumber}</Link>
        </ItemNumber>
        <Column>
          <Header>
            {i18n.t(type === 'yield' ? 'Item to be Produced' : 'Job Component')}
          </Header>
          {i18n.t(name || 'No Name')}
        </Column>
        {type === 'yield' && root ? (
          <Column>
            <Header>{i18n.t('Quantity to be Produced')}</Header>
            {i18n.t(
              `${productionQty.toLocaleString() || 0} ${quantityUOM?.symbol ||
                ''}`,
            )}
          </Column>
        ) : (
          <Column>
            <Header>
              {i18n.t('Est. Usage')}
              <div data-for="usage" data-tip key={id + Math.random()}>
                <Icon
                  type="circle-question"
                  size={16}
                  style={{
                    marginLeft: '8px',
                    marginTop: '2px',
                    width: '12px',
                    height: '12px',
                  }}
                />
              </div>
            </Header>
            {i18n.t(
              `${usageQty.toLocaleString() || 0} ${quantityUOM?.symbol || ''}`,
            )}
          </Column>
        )}
      </Branch>
    );
  };

  getBranches = async (
    data: Array<Object>,
    type: string,
    root: ?boolean = false,
    parentQty: ?number = 1,
  ) => {
    if (!data) return [];
    const branches = await Promise.all(
      data.map(async branch => {
        const node = branch.node.item || branch.node;
        const item = { ...node };
        item.usageQty =
          branch.usageQty && parentQty ? branch.usageQty * parentQty : 0;
        item.productionQty = root ? branch.node.quantity || branch.usageQty : 0;

        const content = this.getBranchContent(item, type, root);
        const record = {
          type,
          itemId: item.id,
          title: content,
          children: [],
          tooltip: item,
        };

        // if the item doesn't have childItems as a property, fetch more details with the item Id, and use the resulting item in the next map
        const fetchedItem = !item.childItems
          ? await this.asyncFetchItem(item.id)
          : item;

        const normalizedItem = fetchedItem.item || fetchedItem;
        const updatedItem = { ...normalizedItem };
        updatedItem.usageQty = item.usageQty;
        updatedItem.productionQty = item.productionQty;

        if (updatedItem?.childItems.edges.length) {
          const children = await Promise.all(
            updatedItem.childItems.edges.map(async edge => {
              const { item } = await this.asyncFetchItem(edge.node.id);

              const parentUsageQty = root
                ? updatedItem.productionQty
                : updatedItem.usageQty;

              const fetchedItem = {
                ...item,
                usageQty: edge.usageQty ? parentUsageQty * edge.usageQty : 0,
              };

              const fetchedItemContent = this.getBranchContent(
                fetchedItem,
                type,
              );

              const fetchedItemChildren = await this.getBranches(
                fetchedItem.childItems.edges,
                type,
                false,
                fetchedItem.usageQty || 1,
              );
              return {
                type,
                itemId: fetchedItem.id,
                title: fetchedItemContent,
                children: fetchedItemChildren,
                tooltip: fetchedItem,
              };
            }),
          );

          return {
            ...record,
            children,
          };
        }
        return record;
      }),
    );

    return branches;
  };

  asyncFetchItem = async (itemId: string) => {
    return await fetchQuery(relayEnvironment, JobSubComponentsTreeItemQuery, {
      id: itemId,
    });
  };

  getItemById = (itemId: string) => {
    const { jobSubComponentEdges } = this.props;

    const item = jobSubComponentEdges.find(edge => {
      return edge?.node?.id === itemId;
    });

    return item?.node || null;
  };

  handleOpenDeleteModal = (e, data, target) => {
    const itemId = target.getAttribute('item-id');

    this.setState({
      deleteJobSubComponentRecord: this.getItemById(itemId),
    });
  };

  handleUpdateJobSubcomponentModal = (e, data, target) => {
    const itemId = target.getAttribute('item-id');
    const itemUsageQty = target.getAttribute('item-usage_qty');
    const item = this.getItemById(itemId);

    this.setState({
      updateJobSubComponentRecord: {
        id: itemId,
        usageQty: itemUsageQty,
        name: item?.name,
      },
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      deleteJobSubComponentRecord: null,
      updateJobSubComponentRecord: null,
    });
  };

  generateChildrenTooltips = (data: Array<Object>, result = []) => {
    const tooltips = data.flatMap(node => {
      const isExistingTooltip = result.some(
        record => record.props.id === node.itemId,
      );
      if (!isExistingTooltip) {
        const content = (
          <div>
            <TooltipTitle>{i18n.t('Item Name')}</TooltipTitle>
            <TooltipContent>{node.tooltip.name || ''}</TooltipContent>
            <TooltipTitle>{i18n.t('Part #')}</TooltipTitle>
            <TooltipContent>{node.tooltip.partNumber || ''}</TooltipContent>
            <TooltipTitle>{i18n.t('UPC')}</TooltipTitle>
            <TooltipContent>{node.tooltip.upc || ''}</TooltipContent>
            <TooltipTitle>{i18n.t('Category')}</TooltipTitle>
            <TooltipContent>
              {node.tooltip?.category?.name || ''}
            </TooltipContent>
            <TooltipTitle>{i18n.t('Total Quantity')}</TooltipTitle>
            <TooltipContent>{`${node.tooltip.totalQty || 0} ${node.tooltip
              ?.quantityUOM?.symbol || ''}`}</TooltipContent>
          </div>
        );

        const tooltip = createTooltip({ id: node.itemId, content });
        result.push(tooltip);
      }
      if (node.children.length) {
        return this.generateChildrenTooltips(node.children, result);
      }
      return result;
    });

    return Array.from(new Set(tooltips));
  };

  getAllTooltips = (treeData: Array<Object>) => {
    const tooltips = this.generateChildrenTooltips(treeData);
    const warningTooltip = createTooltip({
      id: 'yield',
      content: i18n.t(
        'Unable to perform any actions as this is an item to be produced from this job.',
      ),
    });

    const usageTooltip = createTooltip({
      id: 'usage',
      content: i18n.t(
        'The total estimated usage is based off of the quantity to be produced.',
      ),
    });

    tooltips.push(warningTooltip);
    tooltips.push(usageTooltip);

    return tooltips;
  };

  toggleNodeExpansion = expanded => {
    this.setState(prevState => ({
      treeData: toggleExpandedForAll({
        treeData: prevState.treeData,
        expanded,
      }),
      toggle: !expanded,
    }));
  };

  render() {
    const { jobId } = this.props;
    const {
      deleteJobSubComponentRecord,
      updateJobSubComponentRecord,
      treeData,
      toggle,
    } = this.state;

    if (!treeData || !treeData.length) {
      return (
        <EmptyListResults
          graphic="items"
          message={i18n.t(
            'Components associated to jobs can be created and managed here. Operators will have access to component information.',
          )}
        />
      );
    }

    const tooltips = this.getAllTooltips(treeData);

    return (
      <>
        <Button
          width="auto"
          theme="border-white"
          onClick={() => this.toggleNodeExpansion(toggle)}
        >
          {i18n.t(`${toggle ? 'Expand' : 'Collapse'} all`)}
        </Button>
        <SortableTree
          treeData={treeData}
          onChange={treeData => {
            const treeDataLen = treeData.length;
            const visibleNodeCount = getVisibleNodeCount({ treeData });

            this.setState({
              treeData,
              toggle: treeDataLen === visibleNodeCount ? true : false,
            });
          }}
          canDrag={false}
          style={{ minHeight: '750px' }}
          rowHeight={74}
          generateNodeProps={row => {
            const tooltipIcon = (
              <div data-for={row.node.itemId} data-tip key={row.node.itemId}>
                <Icon
                  type="circle-question"
                  size={16}
                  style={{ marginRight: '24px', marginTop: '4px' }}
                />
              </div>
            );

            return {
              buttons: !row.parentNode
                ? [
                    <ButtonWrapper>
                      {tooltipIcon}
                      <div data-for={row.node.type} data-tip>
                        <ContextMenuTrigger
                          ref={r =>
                            (this.menuTriggerRefs[
                              `${row.node.itemId}-${row.node.type}`
                            ] = r)
                          }
                          id="components-menu"
                          attributes={{
                            'item-id': row.node.itemId,
                            'item-usage_qty': row.node.tooltip.usageQty,
                          }}
                          disable={row.node.type === 'yield'}
                        >
                          <Icon
                            type="circle-context-menu"
                            size={24}
                            onClick={(e, data, target) => {
                              if (
                                this.menuTriggerRefs[
                                  `${row.node.itemId}-${row.node.type}`
                                ]
                              ) {
                                this.menuTriggerRefs[
                                  `${row.node.itemId}-${row.node.type}`
                                ].handleContextClick(e, data, target);
                              }
                            }}
                          />
                        </ContextMenuTrigger>
                      </div>
                    </ButtonWrapper>,
                  ]
                : [tooltipIcon],
            };
          }}
          reactVirtualizedListProps={{
            onRowsRendered: () => ReactTooltip.rebuild(),
          }}
        />
        <ContextMenu id="components-menu">
          <MenuItem onClick={this.handleUpdateJobSubcomponentModal}>
            {i18n.t('Edit Estimated Usage')}
          </MenuItem>
          <MenuItem onClick={this.handleOpenDeleteModal}>
            {i18n.t('Remove From Job Ticket')}
          </MenuItem>
        </ContextMenu>
        {updateJobSubComponentRecord && (
          <AddUpdateJobSubComponentModal
            item={updateJobSubComponentRecord}
            jobId={jobId}
            job={null}
            onClose={this.handleCloseAllModals}
          />
        )}
        {deleteJobSubComponentRecord && (
          <DeleteJobSubComponentModal
            item={deleteJobSubComponentRecord}
            onClose={this.handleCloseAllModals}
            jobId={jobId}
          />
        )}
        {tooltips}
      </>
    );
  }
}

export default createFragmentContainer(JobSubComponentsTree, {
  jobSubComponentEdges: graphql`
    fragment JobSubComponentsTree_jobSubComponentEdges on JobSubComponentEdge
      @relay(plural: true) {
      usageQty
      node {
        id
        itemNumber
        name
        partNumber
        upc
        description
        category {
          id
          name
        }
        totalQty
        quantityUOM {
          id
          symbol
        }
        lotTracking
        childItems {
          edges {
            usageQty
            node {
              id
              itemNumber
              name
              partNumber
              upc
              totalQty
              quantityUOM {
                id
                symbol
              }
              category {
                id
                name
              }
              lotTracking
            }
          }
        }
        ...DeleteJobSubComponentModal_item
      }
    }
  `,
  jobYieldEdges: graphql`
    fragment JobSubComponentsTree_jobYieldEdges on JobYieldEdge
      @relay(plural: true) {
      node {
        id
        quantity
        item {
          id
          itemNumber
          name
          partNumber
          upc
          totalQty
          quantityUOM {
            id
            symbol
          }
          category {
            id
            name
          }
          lotTracking
          childItems {
            edges {
              usageQty
              node {
                id
                itemNumber
                name
                partNumber
                upc
                totalQty
                quantityUOM {
                  id
                  symbol
                }
                category {
                  id
                  name
                }
                lotTracking
              }
            }
          }
        }
      }
    }
  `,
});

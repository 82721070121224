// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation AddUserMutation($input: AddUserInput!) {
    addUser(input: $input) {
      userEdge {
        node {
          id
          email
          role
          firstName
          lastName
          userNumber
        }
      }
    }
  }
`;

export default {
  commit: (options: *) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: ROOT_ID,
          connectionInfo: [
            {
              key: 'Users_users',
              rangeBehavior: 'prepend',
            },
          ],
          edgeName: 'userEdge',
        },
      ],
    });
  },
};

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateWorkflowModal_workflow$ref: FragmentReference;
declare export opaque type AddUpdateWorkflowModal_workflow$fragmentType: AddUpdateWorkflowModal_workflow$ref;
export type AddUpdateWorkflowModal_workflow = {|
  +id: string,
  +name: string,
  +isDefault: boolean,
  +$refType: AddUpdateWorkflowModal_workflow$ref,
|};
export type AddUpdateWorkflowModal_workflow$data = AddUpdateWorkflowModal_workflow;
export type AddUpdateWorkflowModal_workflow$key = {
  +$data?: AddUpdateWorkflowModal_workflow$data,
  +$fragmentRefs: AddUpdateWorkflowModal_workflow$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AddUpdateWorkflowModal_workflow",
  "type": "Workflow",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isDefault",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '374251ff59a5003e62249aba327c5345';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MachineCard_machine$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MachineBoard_machineEdges$ref: FragmentReference;
declare export opaque type MachineBoard_machineEdges$fragmentType: MachineBoard_machineEdges$ref;
export type MachineBoard_machineEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +name: string,
    +productionOrder: number,
    +type: {|
      +id: string,
      +name: string,
    |},
    +$fragmentRefs: MachineCard_machine$ref,
  |},
  +$refType: MachineBoard_machineEdges$ref,
|}>;
export type MachineBoard_machineEdges$data = MachineBoard_machineEdges;
export type MachineBoard_machineEdges$key = {
  +$data?: MachineBoard_machineEdges$data,
  +$fragmentRefs: MachineBoard_machineEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MachineBoard_machineEdges",
  "type": "MachineEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Machine",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "productionOrder",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "type",
          "storageKey": null,
          "args": null,
          "concreteType": "MachineType",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "MachineCard_machine",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '610847dca0d4d6314dd6b5339b099a10';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import type { RouterHistory } from 'react-router';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddUpdateLotModal from 'main-app/components/AddUpdateLotModal';
import type { LotsTable_lotEdges as LotEdgesFragment } from './__generated__/LotsTable_lotEdges';

type Props = {
  history: RouterHistory,
  lotEdges: LotEdgesFragment,
  onCreateNew?: void => void,
  emptyListMessage?: string,
  editEnabled?: boolean,
  UOM?: string,
};

type State = {
  updateLotRecord: ?Object,
};

class LotsTable extends PureComponent<Props, State> {
  static defaultProps = {
    onCreateNew: false,
    emptyListMessage: null,
    editEnabled: false,
    UOM: '',
  };

  menuTriggerRefs = {};
  state = {
    updateLotRecord: null,
  };

  getLotById = (lotId: string) => {
    const { lotEdges } = this.props;
    const lot = lotEdges.find(edge => {
      return edge?.node?.id === lotId;
    });

    return lot?.node || null;
  };

  handleOpenNewTab = (e, data, target) => {
    const lotId = target.getAttribute('lot-id');

    window.open(`/lot/${lotId}`);
  };

  handleOpenUpdateModal = (e, data, target) => {
    const lotId = target.getAttribute('lot-id');

    this.setState({
      updateLotRecord: this.getLotById(lotId),
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateLotRecord: null,
    });
  };

  render() {
    const {
      history,
      lotEdges,
      emptyListMessage,
      onCreateNew,
      editEnabled,
      UOM,
    } = this.props;
    const { updateLotRecord } = this.state;

    const edges = lotEdges ? lotEdges.filter(Boolean).map(a => a.node) : [];

    if (!edges.length) {
      return (
        <EmptyListResults
          graphic="items"
          message={
            emptyListMessage ||
            i18n.t(`Your lots will show here. Create a new lot to get started!`)
          }
          onCreateNew={onCreateNew}
          createNewLabel={i18n.t('Create Lot')}
        />
      );
    }

    return (
      <>
        <Table
          data={edges}
          onRowClick={rowData => history.push(`/lot/${rowData.id}`)}
          columns={[
            {
              Header: i18n.t('Lot #'),
              accessor: 'lotNumber',
              width: 200,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value, row }: *) => <Pill>{value}</Pill>,
            },
            {
              Header: i18n.t('Quantity'),
              accessor: 'quantity',
              Cell: ({ value }) =>
                `${i18n.t('{{quantity, number}}', { quantity: value }) ||
                  0} ${UOM || ''}` || '',
            },
            {
              Header: i18n.t('Bin Number'),
              accessor: 'binNumber',
              Cell: ({ value }) => value || '',
            },
            {
              Header: i18n.t('Expiration Date'),
              accessor: 'expiresAt',
              Cell: ({ value }) =>
                value ? moment(value).format('MM/DD/YYYY') : '',
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              Cell: ({ value }: *) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="lots-menu"
                  attributes={{
                    'lot-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={(e, data, target) => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(
                          e,
                          data,
                          target,
                        );
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ]}
        />
        <ContextMenu id="lots-menu">
          {editEnabled && (
            <MenuItem onClick={this.handleOpenUpdateModal}>
              {i18n.t('Edit Lot')}
            </MenuItem>
          )}
          <MenuItem onClick={this.handleOpenNewTab}>
            {i18n.t('Open In New Tab')}
          </MenuItem>
        </ContextMenu>
        {updateLotRecord && (
          <AddUpdateLotModal
            lot={updateLotRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(LotsTable, {
  lotEdges: graphql`
    fragment LotsTable_lotEdges on LotEdge @relay(plural: true) {
      node {
        id
        lotNumber
        binNumber
        quantity
        expiresAt
        ...AddUpdateLotModal_lot
      }
    }
  `,
});

// @flow

import React from 'react';
import moment from 'moment';
import qs from 'qs';
import type { RouterHistory } from 'react-router';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { i18n } from 'shared/utils';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import Loader from 'shared/components/common/Loader';
import OrdersDue from './OrdersDue';
import OEEPanel from './OEEPanel';
import OverallProgressPanel from './OverallProgressPanel';
import WorkflowProgressPanel from './WorkflowProgressPanel';
import ItemProgressPanel from './ItemProgressPanel';

type Props = {
  history: RouterHistory,
};

const DEFAULT_FILTER = {
  oeeStartAt: moment()
    .startOf('day')
    .format(),
  oeeEndAt: moment()
    .add(1, 'day')
    .format(),
  overallProgressStartAt: moment()
    .startOf('day')
    .format(),
  overallProgressEndAt: moment()
    .add(1, 'day')
    .format(),
  itemProgressWorkflowId: '',
  itemProgressStartAt: moment()
    .startOf('day')
    .format(),
  itemProgressEndAt: moment()
    .add(1, 'day')
    .format(),
  lineProgressStartAt: moment()
    .startOf('day')
    .format(),
  lineProgressEndAt: moment()
    .add(1, 'day')
    .format(),
};

const getFilters = history => {
  const { search } = history.location;

  if (!search) {
    return DEFAULT_FILTER;
  }

  return {
    ...DEFAULT_FILTER,
    ...qs.parse(search, { ignoreQueryPrefix: true }),
  };
};

const setFilters = (history, updatedFilters) => {
  const filters = getFilters(history);

  history.replace(
    '/dashboard?' +
      qs.stringify(
        {
          ...filters,
          ...updatedFilters,
        },
        {
          encode: true,
        },
      ),
  );
};

export default ({ history }: Props) => {
  const filters = getFilters(history);

  return (
    <Page>
      <PageHeader title={i18n.t('Dashboard')} />
      <QueryRenderer
        environment={relayEnvironment}
        cacheConfig={{
          force: true,
        }}
        query={graphql`
          query DashboardQuery($promisedAtStart: Date!, $promisedAtEnd: Date!) {
            orders(
              promisedAtStart: $promisedAtStart
              promisedAtEnd: $promisedAtEnd
              statuses: [OPEN]
              sortBy: { field: PROMISED_AT, direction: ASC }
            ) {
              edges {
                node {
                  id
                }
                ...OrdersDue_orderEdges
              }
            }
          }
        `}
        variables={{
          promisedAtStart: moment(0).format('YYYY-MM-DD'),
          promisedAtEnd: moment()
            .add(30, 'days')
            .format('YYYY-MM-DD'),
        }}
        render={query => {
          if (!query.props) {
            return <Loader />;
          }

          const { orders } = query.props;

          return (
            <>
              <div style={{ paddingBottom: 24 }}>
                <OrdersDue history={history} orderEdges={orders.edges} />
              </div>
              <OEEPanel
                history={history}
                setFilters={setFilters}
                oeeStartAt={filters.oeeStartAt}
                oeeEndAt={filters.oeeEndAt}
              />
              <OverallProgressPanel
                history={history}
                setFilters={setFilters}
                overallProgressStartAt={filters.overallProgressStartAt}
                overallProgressEndAt={filters.overallProgressEndAt}
              />
              <WorkflowProgressPanel
                history={history}
                setFilters={setFilters}
                lineProgressStartAt={filters.lineProgressStartAt}
                lineProgressEndAt={filters.lineProgressEndAt}
              />
              <ItemProgressPanel
                history={history}
                setFilters={setFilters}
                itemProgressStartAt={filters.itemProgressStartAt}
                itemProgressEndAt={filters.itemProgressEndAt}
                itemProgressWorkflowId={filters.itemProgressWorkflowId}
              />
            </>
          );
        }}
      />
    </Page>
  );
};

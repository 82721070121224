/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateItemModal_defaultForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobDetails_defaultForm$ref: FragmentReference;
declare export opaque type JobDetails_defaultForm$fragmentType: JobDetails_defaultForm$ref;
export type JobDetails_defaultForm = {|
  +id: string,
  +$fragmentRefs: AddUpdateItemModal_defaultForm$ref,
  +$refType: JobDetails_defaultForm$ref,
|};
export type JobDetails_defaultForm$data = JobDetails_defaultForm;
export type JobDetails_defaultForm$key = {
  +$data?: JobDetails_defaultForm$data,
  +$fragmentRefs: JobDetails_defaultForm$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "JobDetails_defaultForm",
  "type": "Form",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AddUpdateItemModal_defaultForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '59525c51c278c3bca109762282a05a17';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      jobId: string,
      name: string,
      quantity: number,
      itemNumberUp: number,
      brokerCustomerOrderNumber: ?string,
      partNumber: ?string,
      description: ?string,
      overUnderRule: ?string,
      overQuantity: ?number,
      fromFieldValues: ?Object,
    },
  },
};

const mutation = graphql`
  mutation AddJobYieldMutation($input: AddJobYieldInput!) {
    addJobYield(input: $input) {
      jobYieldEdge {
        ...JobYieldPanels_jobYieldEdges
        cursor
        node {
          id
          quantity
          itemNumberUp
          brokerCustomerOrderNumber
          formFieldValues
          overUnderRule
          overQuantity
          job {
            id
            yields(first: null) {
              totalCount
            }
          }
          item {
            id
            itemNumber
            name
            partNumber
            description
            formFieldValues
            form {
              ...FormBuilder_form
            }
          }
          form {
            id
            ...FormBuilder_form
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: options.variables.input.jobId,
          connectionInfo: [
            {
              key: 'JobDetails_job_yields',
              rangeBehavior: 'append',
            },
            {
              key: 'CloseJobModal_job_yields',
              rangeBehavior: 'append',
            },
            {
              key: 'ReopenJobModal_job_yields',
              rangeBehavior: 'append',
            },
            {
              key: 'Runs_job_yields',
              rangeBehavior: 'append',
            },
            {
              key: 'AutoScheduleJobModal_job_yields',
              rangeBehavior: 'append',
            },
            {
              key: 'JobSubComponents_job_yields',
              rangeBehavior: 'append',
            },
          ],
          edgeName: 'jobYieldEdge',
        },
        {
          type: 'RANGE_ADD',
          parentID: options.variables.input.jobId,
          connectionInfo: [
            {
              key: 'AddUpdateRunModal_job_yields',
              rangeBehavior: 'append',
            },
          ],
          edgeName: 'jobYieldEdge',
        },
      ],
    });
  },
};

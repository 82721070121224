// @flow

import React, { PureComponent } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import type { RouterHistory } from 'react-router';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import defaultLogo from './img/defaultLogo.png';
import AddIntegrationModal from './AddIntegrationModal';
import DeleteIntegrationModal from './DeleteIntegrationModal';

type Props = {
  history: RouterHistory,
};

type State = {
  addModalOpen: boolean,
  deleteIntegrationRecord: ?Object,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 16px 16px 24px 16px;

  @media (min-width: 1000px) {
    align-self: flex-end;
    padding: 0 0 24px;
  }
`;

const ListWrapper = styled.div`
  flex: 1;
`;

const SourceLogo = styled.img`
  width: 100px;
`;

class Integrations extends PureComponent<Props, State> {
  menuTriggerRefs = {};

  state = {
    addModalOpen: false,
    deleteIntegrationRecord: null,
  };

  getIntegrationById = (integrationId: string, integrationEdges: Object[]) => {
    const record = integrationEdges.find(edge => {
      return edge?.node?.id === integrationId;
    });

    return record?.node || null;
  };

  handleDeleteIntegration = (
    integrationId: string,
    integrationEdges: Object[],
  ) => {
    const integration = this.getIntegrationById(
      integrationId,
      integrationEdges,
    );

    this.setState({
      deleteIntegrationRecord: integration,
    });
  };

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleCreateSuccess = (integrationId: string) => {
    const { history } = this.props;
    history.push(`/settings/integration/${integrationId}`);
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      deleteIntegrationRecord: null,
    });
  };

  render() {
    const { history } = this.props;
    const { addModalOpen, deleteIntegrationRecord } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query IntegrationsQuery {
            integrations(first: null)
              @connection(key: "Integrations_integrations") {
              edges {
                node {
                  id
                  source {
                    id
                    name
                    description
                    logo
                  }
                  ...DeleteIntegrationModal_integration
                }
              }
            }
          }
        `}
        variables={null}
        render={({ props }) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <Wrapper>
              <ButtonWrapper>
                <Button
                  onClick={this.handleOpenCreateModal}
                  theme="border-white"
                >
                  {i18n.t('Add Integration')}
                </Button>
              </ButtonWrapper>

              {!props.integrations.edges.length ? (
                <div>{i18n.t(`You haven't added any integrations yet.`)}</div>
              ) : (
                <ListWrapper>
                  <Table
                    onRowClick={rowData =>
                      history.push(`/settings/integration/${rowData.id}`)
                    }
                    data={props.integrations.edges.map(edge => edge.node)}
                    columns={[
                      {
                        Header: 'Integration',
                        accessor: 'source.logo',
                        width: 132,
                        Cell: ({ value }) => (
                          <SourceLogo src={value || defaultLogo} />
                        ),
                      },
                      {
                        Header: '',
                        accessor: 'source.name',
                        width: 160,
                      },
                      {
                        Header: i18n.t('Description'),
                        accessor: 'source.description',
                      },
                      {
                        Header: '',
                        accessor: 'id',
                        width: 48,
                        enforceWidth: true,
                        Cell: ({ value }) => (
                          <ContextMenuTrigger
                            ref={r => (this.menuTriggerRefs[value] = r)}
                            id="settings-integrations-menu"
                            attributes={{
                              'integration-id': value,
                            }}
                          >
                            <Icon
                              type="circle-context-menu"
                              size={24}
                              onClick={e => {
                                if (this.menuTriggerRefs[value]) {
                                  this.menuTriggerRefs[
                                    value
                                  ].handleContextClick(e);
                                }
                              }}
                            />
                          </ContextMenuTrigger>
                        ),
                      },
                    ]}
                  />
                </ListWrapper>
              )}
              <ContextMenu id="settings-integrations-menu">
                <MenuItem
                  onClick={(e, data, target) => {
                    this.handleDeleteIntegration(
                      target.getAttribute('integration-id'),
                      props.integrations.edges,
                    );
                  }}
                >
                  {i18n.t('Delete Integration')}
                </MenuItem>
              </ContextMenu>
              {addModalOpen && (
                <AddIntegrationModal
                  onClose={this.handleCloseAllModals}
                  onSuccess={this.handleCreateSuccess}
                />
              )}
              {deleteIntegrationRecord && (
                <DeleteIntegrationModal
                  integration={deleteIntegrationRecord}
                  onClose={this.handleCloseAllModals}
                />
              )}
            </Wrapper>
          );
        }}
      />
    );
  }
}

export default Integrations;

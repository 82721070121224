// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import slugify from 'slugify';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import SignUpMutation from 'main-app/mutations/SignUp';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
  // SelectProductionTypeField,
} from 'shared/components/form';
import Page from 'main-app/components/Page';
import PageHeaderLogo from 'main-app/components/PageHeaderLogo';
import Button from 'shared/components/common/Button';
import SignupGraphic from './img/signupGraphic.svg';

const Wrapper = styled.div`
  padding: 24px 16px 16px 16px;

  @media (min-width: 1000px) {
    padding-top: 48px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
`;

const FormWrapper = styled.div`
  @media (min-width: 1000px) {
    width: 480px;
  }
`;

const GraphicWrapper = styled.div`
  display: none;

  @media (min-width: 1000px) {
    display: block;
    padding-left: 60px;
  }
`;

const Header = styled.div`
  ${fonts.largeTitle};
  color: ${colors.azul};
  padding-bottom: 24px;
`;

const SubHeader = styled.div`
  ${fonts.bodyRegular}
  color: ${colors.charcoalGrey};
  padding-bottom: 42px;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.lightBlueGrey};
  margin: 16px 0 40px 0;
`;

const TOSWrapper = styled.div`
  padding-top: 48px;
  ${fonts.smallRegular}
  color: ${colors.charcoalGrey};
`;

class SignUp extends PureComponent<void> {
  validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    companyName: Yup.string().required('Required'),
    companyNamespace: Yup.string().required('Required'),
    productionTypes: Yup.mixed(), // FIXME
    email: Yup.string()
      .email('Invalid Email')
      .required('Required'),
    password: Yup.string().required('Required'),
  });

  formatNamespace = (namespace: string) => {
    return slugify(namespace).toLowerCase();
  };

  render() {
    return (
      <Page hideMenu noPadding>
        <PageHeaderLogo />
        <Wrapper>
          <FormWrapper>
            <Header>{i18n.t('Get Started With WorkClout')}</Header>
            <SubHeader>
              {i18n.t(
                `Sign up for a free account to start organizing and optimizing your team's workflow.`,
              )}
              <br />
              <br />
              <Trans>
                {`Already have an account? `}
                <Link to="/signin">Sign In</Link>
              </Trans>
            </SubHeader>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                companyName: '',
                companyNamespace: '',
                productionTypes: [],
                email: '',
                password: '',
              }}
              validationSchema={this.validationSchema}
              onSubmit={async (values, { props, setSubmitting }) => {
                setSubmitting(true);
                try {
                  // Execute the mutation
                  await SignUpMutation.commit({
                    variables: {
                      input: {
                        companyName: values.companyName,
                        namespace: values.companyNamespace,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        password: values.password,
                      },
                    },
                  });

                  window.location = '/';
                } catch (e) {
                  setSubmitting(false);
                  Actions.alertNotification(i18n.t(e.message || e), 'error');
                }
              }}
              render={({
                errors,
                values,
                isValid,
                isSubmitting,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form>
                  <FieldGroup
                    label={i18n.t('Your Company Name')}
                    name="companyName"
                    placeholder={i18n.t('Example, Inc')}
                    error={errors.companyName}
                    onChange={value =>
                      setFieldValue(
                        'companyNamespace',
                        this.formatNamespace(value),
                      )
                    }
                  />
                  <FieldGroup
                    label={i18n.t('Your Unique Namespace')}
                    name="companyNamespace"
                    placeholder={i18n.t('example-inc')}
                    extendedLabel=".workclout.com"
                    maxLength="24"
                    error={errors.companyNamespace}
                    onBlur={() =>
                      setFieldValue(
                        'companyNamespace',
                        this.formatNamespace(values.companyNamespace),
                      )
                    }
                  />
                  {/* <SelectProductionTypeField
                    label={i18n.t(`Your Company's Production Types`)}
                    name="productionTypes"
                    multi
                    isSearchable={false}
                    error={errors.productionTypes}
                  /> */}
                  <FieldGroupRow
                    label={i18n.t('Your Full Name')}
                    left={
                      <FieldGroup
                        name="firstName"
                        placeholder={i18n.t('John')}
                        error={errors.firstName}
                      />
                    }
                    right={
                      <FieldGroup
                        name="lastName"
                        placeholder={i18n.t('Doe')}
                        error={errors.lastName}
                      />
                    }
                  />
                  <FieldGroup
                    label={i18n.t('Email Address')}
                    name="email"
                    type="email"
                    placeholder={i18n.t('johndoe@example.com')}
                    error={errors.email}
                  />
                  <FieldGroup
                    label={i18n.t('Password')}
                    name="password"
                    type="password"
                    placeholder="Password"
                    error={errors.password}
                  />
                  {/* FIXME: switch out for common divider component */}
                  <Divider />
                  <Button
                    type="submit"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {i18n.t('Get Started')}
                  </Button>
                  <TOSWrapper>
                    <Trans>
                      {`By clicking 'Get Started', you agree to WorkClout's `}
                      <Link to="/tos">Terms and Privacy</Link>
                    </Trans>
                  </TOSWrapper>
                </Form>
              )}
            />
          </FormWrapper>
          <GraphicWrapper>
            <SignupGraphic />
          </GraphicWrapper>
        </Wrapper>
      </Page>
    );
  }
}

export default SignUp;

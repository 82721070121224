// @flow

import React, { PureComponent } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import debounce from 'lodash.debounce';
import type { RouterHistory } from 'react-router';
import { BrowserUtils } from 'shared/utils';
import LoadingMore from 'shared/components/common/LoadingMore';
import EmptySearchResults from 'shared/components/common/EmptySearchResults';
import ItemsTable from 'shared/components/common/ItemsTable';
import type { PaginatedItemsContainer_items as ItemsFragment } from './__generated__/PaginatedItemsContainer_items';

type Props = {
  history: RouterHistory,
  filtersDirty: boolean,
  onCreateNew: () => void,
  items: ItemsFragment,
  defaultForm: Object,
  deleteEnabled: boolean,
  editEnabled: boolean,
  relay: {
    environment: Object,
    hasMore: () => boolean,
    isLoading: () => boolean,
    loadMore: (pageSize: number, callback: Function) => void,
  },
};

class PaginatedItemsContainer extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onScrollEndReached = debounce(this.onScrollEndReached, 100);
  }
  componentDidMount = () => {
    document.addEventListener('scroll', this.onScrollEndReached);
  };

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.onScrollEndReached);
  };

  onScrollEndReached = () => {
    const { relay } = this.props;
    if (BrowserUtils.scrollEndReached(200)) {
      if (relay.hasMore()) {
        relay.loadMore(30);
      }
    }
  };

  render() {
    const {
      history,
      onCreateNew,
      items,
      defaultForm,
      relay,
      filtersDirty,
      editEnabled,
      deleteEnabled,
    } = this.props;

    if ((!items.items.edges || !items.items.edges.length) && filtersDirty) {
      return <EmptySearchResults />;
    }

    return (
      <>
        <ItemsTable
          history={history}
          onCreateNew={onCreateNew}
          itemEdges={items.items.edges}
          defaultForm={defaultForm}
          deleteEnabled={deleteEnabled}
          editEnabled={editEnabled}
        />
        {relay.hasMore() && relay.isLoading() ? <LoadingMore /> : null}
      </>
    );
  }
}

export default createPaginationContainer(
  PaginatedItemsContainer,
  {
    items: graphql`
      fragment PaginatedItemsContainer_items on Query
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 30 }
          cursor: { type: "String" }
          search: { type: "String" }
          sortBy: { type: "ItemSort" }
          partNumber: { type: "String" }
          itemNumbers: { type: "[String!]" }
          orderIds: { type: "[ID!]" }
          customerIds: { type: "[ID!]" }
          itemCategoryIds: { type: "[ID!]" }
        ) {
        items(
          first: $count
          after: $cursor
          search: $search
          sortBy: $sortBy
          partNumber: $partNumber
          itemNumbers: $itemNumbers
          orderIds: $orderIds
          customerIds: $customerIds
          itemCategoryIds: $itemCategoryIds
        ) @connection(key: "PaginatedItemsContainer_items", filters: []) {
          edges {
            cursor
            ...ItemsTable_itemEdges
          }
        }
      }
    `,
  },
  {
    query: graphql`
      query PaginatedItemsContainerQuery(
        $count: Int!
        $cursor: String
        $search: String
        $sortBy: ItemSort
        $partNumber: String
        $itemNumbers: [String!]
        $orderIds: [ID!]
        $customerIds: [ID!]
        $itemCategoryIds: [ID!]
      ) {
        ...PaginatedItemsContainer_items
          @arguments(
            count: $count
            cursor: $cursor
            search: $search
            sortBy: $sortBy
            partNumber: $partNumber
            itemNumbers: $itemNumbers
            orderIds: $orderIds
            customerIds: $customerIds
            itemCategoryIds: $itemCategoryIds
          )
      }
    `,
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return { ...fragmentVariables, count, cursor };
    },
  },
);

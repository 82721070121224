/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ItemLotsTable_itemLotEdges$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ItemLots_itemLotEdges$ref: FragmentReference;
declare export opaque type ItemLots_itemLotEdges$fragmentType: ItemLots_itemLotEdges$ref;
export type ItemLots_itemLotEdges = $ReadOnlyArray<{|
  +quantity: ?number,
  +node: {|
    +id: string,
    +lotNumber: string,
    +binNumber: ?string,
    +quantity: number,
    +expiresAt: ?any,
  |},
  +$fragmentRefs: ItemLotsTable_itemLotEdges$ref,
  +$refType: ItemLots_itemLotEdges$ref,
|}>;
export type ItemLots_itemLotEdges$data = ItemLots_itemLotEdges;
export type ItemLots_itemLotEdges$key = {
  +$data?: ItemLots_itemLotEdges$data,
  +$fragmentRefs: ItemLots_itemLotEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ItemLots_itemLotEdges",
  "type": "ItemLotEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Lot",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lotNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "binNumber",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "expiresAt",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ItemLotsTable_itemLotEdges",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2fd7cd450f5a6623ffa90ee5f59b3d40';
module.exports = node;

// @flow

import React from 'react';
import Panel from 'shared/components/common/Panel';
import { i18n } from 'shared/utils';
import moment from 'moment';
import { DateRangePicker } from 'shared/components/form';
import type { RouterHistory } from 'react-router';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import WorkflowProgress from './WorkflowProgress';

type Props = {
  history: RouterHistory,
  lineProgressStartAt: string,
  lineProgressEndAt: string,
  setFilters: (RouterHistory, Object) => void,
};

export default ({
  history,
  lineProgressStartAt,
  lineProgressEndAt,
  setFilters,
}: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    cacheConfig={{
      force: true,
    }}
    query={graphql`
      query WorkflowProgressPanelQuery(
        $lineProgressStartAt: DateTime!
        $lineProgressEndAt: DateTime!
      ) {
        metricsWorkflowProgress(
          startAt: $lineProgressStartAt
          endAt: $lineProgressEndAt
        ) {
          name
          quantity
          quantityCompleted
        }
      }
    `}
    variables={{
      lineProgressStartAt,
      lineProgressEndAt,
    }}
    render={query => {
      if (!query.props) {
        return (
          <Panel style={{ marginTop: 24, height: 356 }}>
            <Loader />
          </Panel>
        );
      }

      return (
        <Panel
          style={{
            marginTop: 24,
          }}
          title={i18n.t('Production Metrics by Workflow')}
          headerRight={
            <DateRangePicker
              value={{
                startDate: moment(lineProgressStartAt),
                endDate: moment(lineProgressEndAt),
              }}
              onChange={({ startDate, endDate }) =>
                setFilters(history, {
                  lineProgressStartAt: moment(startDate)
                    .startOf('day')
                    .format(),
                  lineProgressEndAt: moment(endDate)
                    .endOf('day')
                    .format(),
                })
              }
            />
          }
        >
          <WorkflowProgress data={query.props.metricsWorkflowProgress} />
        </Panel>
      );
    }}
  />
);

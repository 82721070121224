// @flow

import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import Loader from 'shared/components/common/Loader';
import OperatingHoursModal from './OperatingHoursModal';
import AddUpdateCalendarEventModal from './AddUpdateCalendarEventModal';

type CalendarViews = 'month' | 'week' | 'day';

type Props = {};

type State = {
  operatingHoursModalOpen: boolean,
  addCalendarEventModalRecord: ?{
    startDate: moment$Moment,
    endDate: moment$Moment,
    isFullDay: boolean,
  },
  updateCalendarEventRecord: ?Object,
  currentDate: moment$Moment,
  currentView: CalendarViews,
};

const ButtonWrapper = styled.div`
  padding: 16px 16px 24px 16px;

  @media (min-width: 1000px) {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 24px;
  }
`;

const localizer = momentLocalizer(moment);

class OperatingHours extends PureComponent<Props, State> {
  state = {
    operatingHoursModalOpen: false,
    addCalendarEventModalRecord: null,
    updateCalendarEventRecord: null,
    currentDate: moment(),
    currentView: 'month',
  };

  mergeDateWithTime = (date: moment$Moment, time: string) => {
    return moment(
      date.format('YYYY-MM-DD') +
        ' ' +
        moment(time, 'HH:mm:ss.SSSZ').format('HH:mm'),
    ).toDate();
  };

  massageEvents = (company: Object) => {
    const { currentDate, currentView } = this.state;
    const startRange = currentDate
      .clone()
      .startOf(currentView)
      .subtract(7, 'days');
    const endRange = currentDate
      .clone()
      .endOf(currentView)
      .add(7, 'days');
    const currentDay = startRange.clone();

    const events = company.calendarEvents.map(calendarEvent => {
      return {
        data: calendarEvent,
        allDay: calendarEvent.isFullDay,
        title: calendarEvent.name,
        startAt: moment(calendarEvent.startAt).toDate(),
        endAt: moment(calendarEvent.endAt).toDate(),
      };
    });

    while (endRange.diff(currentDay, 'day') !== -1) {
      if (
        currentDay.day() === 0 &&
        company.sundayHoursStart &&
        company.sundayHoursEnd
      ) {
        events.push({
          title: 'Sunday Operating Hours',
          startAt: this.mergeDateWithTime(currentDay, company.sundayHoursStart),
          endAt: this.mergeDateWithTime(currentDay, company.sundayHoursEnd),
        });
      }

      if (
        currentDay.day() === 1 &&
        company.mondayHoursStart &&
        company.mondayHoursEnd
      ) {
        events.push({
          title: 'Monday Operating Hours',
          startAt: this.mergeDateWithTime(currentDay, company.mondayHoursStart),
          endAt: this.mergeDateWithTime(currentDay, company.mondayHoursEnd),
        });
      }

      if (
        currentDay.day() === 2 &&
        company.tuesdayHoursStart &&
        company.tuesdayHoursEnd
      ) {
        events.push({
          title: 'Tuesday Operating Hours',
          startAt: this.mergeDateWithTime(
            currentDay,
            company.tuesdayHoursStart,
          ),
          endAt: this.mergeDateWithTime(currentDay, company.tuesdayHoursEnd),
        });
      }

      if (
        currentDay.day() === 3 &&
        company.wednesdayHoursStart &&
        company.wednesdayHoursEnd
      ) {
        events.push({
          title: 'Wednesday Operating Hours',
          startAt: this.mergeDateWithTime(
            currentDay,
            company.wednesdayHoursStart,
          ),
          endAt: this.mergeDateWithTime(currentDay, company.wednesdayHoursEnd),
        });
      }

      if (
        currentDay.day() === 4 &&
        company.thursdayHoursStart &&
        company.thursdayHoursEnd
      ) {
        events.push({
          title: 'Thursday Operating Hours',
          startAt: this.mergeDateWithTime(
            currentDay,
            company.thursdayHoursStart,
          ),
          endAt: this.mergeDateWithTime(currentDay, company.thursdayHoursEnd),
        });
      }

      if (
        currentDay.day() === 5 &&
        company.fridayHoursStart &&
        company.fridayHoursEnd
      ) {
        events.push({
          title: 'Friday Operating Hours',
          startAt: this.mergeDateWithTime(currentDay, company.fridayHoursStart),
          endAt: this.mergeDateWithTime(currentDay, company.fridayHoursEnd),
        });
      }

      if (
        currentDay.day() === 6 &&
        company.saturdayHoursStart &&
        company.saturdayHoursEnd
      ) {
        events.push({
          title: 'Saturday Operating Hours',
          startAt: this.mergeDateWithTime(
            currentDay,
            company.saturdayHoursStart,
          ),
          endAt: this.mergeDateWithTime(currentDay, company.saturdayHoursEnd),
        });
      }

      currentDay.add(1, 'day');
    }

    return events;
  };

  handleOpenOperatingHoursModal = () => {
    this.setState({
      operatingHoursModalOpen: true,
    });
  };

  handleOpenAddCalendarEventModal = ({ startDate, endDate, isFullDay }: *) => {
    this.setState({
      addCalendarEventModalRecord: {
        startDate,
        endDate,
        isFullDay,
      },
    });
  };

  handleOpenUpdateCalendarEventModal = (calendarEvent: Object) => {
    this.setState({
      updateCalendarEventRecord: calendarEvent,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      operatingHoursModalOpen: false,
      addCalendarEventModalRecord: null,
      updateCalendarEventRecord: null,
    });
  };

  handleCalendarNavigate = (date: Date, currentView: CalendarViews) => {
    this.setState({
      currentDate: moment(date),
      currentView,
    });
  };

  handleCalendarView = (currentView: CalendarViews) => {
    this.setState({
      currentView,
    });
  };

  handleSelectEvent = (event: Object, e: Event) => {
    // Make sure this isn't a cosmetic event (we can determine this by checking if the event has a data property)
    if (event.data) {
      this.handleOpenUpdateCalendarEventModal(event.data);
    }
  };

  handleSelectDate = (slotInfo: *) => {
    let isFullDay = false;
    const startDate = moment(slotInfo.start);
    const endDate = moment(slotInfo.end);

    if (
      moment(slotInfo.start).format('HH:mm:ss') ===
      moment(slotInfo.end).format('HH:mm:ss')
    ) {
      isFullDay = true;
    }

    this.handleOpenAddCalendarEventModal({
      startDate,
      endDate,
      isFullDay,
    });
  };

  render() {
    const {
      operatingHoursModalOpen,
      addCalendarEventModalRecord,
      updateCalendarEventRecord,
    } = this.state;

    return (
      <QueryRenderer
        query={graphql`
          query OperatingHoursQuery {
            viewer {
              id
              company {
                id
                sundayHoursStart
                sundayHoursEnd
                mondayHoursStart
                mondayHoursEnd
                tuesdayHoursStart
                tuesdayHoursEnd
                wednesdayHoursStart
                wednesdayHoursEnd
                thursdayHoursStart
                thursdayHoursEnd
                fridayHoursStart
                fridayHoursEnd
                saturdayHoursStart
                saturdayHoursEnd
                calendarEvents {
                  id
                  name
                  isFullDay
                  isOpen
                  startAt
                  endAt
                  ...AddUpdateCalendarEventModal_companyCalendarEvent
                }
                ...OperatingHoursModal_company
              }
            }
          }
        `}
        environment={relayEnvironment}
        render={({ props }) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <div style={{ flex: 1 }}>
              <ButtonWrapper>
                <Button
                  onClick={this.handleOpenOperatingHoursModal}
                  width="auto"
                  theme="border-white"
                >
                  {i18n.t('Update Operating Hours')}
                </Button>
              </ButtonWrapper>
              <Calendar
                selectable
                localizer={localizer}
                views={['month', 'week', 'day']}
                showMultiDayTimes
                events={this.massageEvents(props.viewer.company)}
                startAccessor="startAt"
                endAccessor="endAt"
                onNavigate={this.handleCalendarNavigate}
                onView={this.handleCalendarView}
                onSelectEvent={this.handleSelectEvent}
                onSelectSlot={this.handleSelectDate}
                eventPropGetter={event => ({
                  style: {
                    backgroundColor:
                      event?.data?.isOpen === false
                        ? colors.cherry
                        : colors.azul,
                  },
                })}
              />
              {operatingHoursModalOpen && (
                <OperatingHoursModal
                  company={props.viewer.company}
                  onClose={this.handleCloseAllModals}
                />
              )}
              {(addCalendarEventModalRecord || updateCalendarEventRecord) && (
                <AddUpdateCalendarEventModal
                  onClose={this.handleCloseAllModals}
                  companyCalendarEvent={updateCalendarEventRecord || null}
                  startDate={addCalendarEventModalRecord?.startDate || null}
                  endDate={addCalendarEventModalRecord?.endDate || null}
                  isFullDay={addCalendarEventModalRecord?.isFullDay || null}
                />
              )}
            </div>
          );
        }}
      />
    );
  }
}

export default OperatingHours;

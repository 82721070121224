/**
 * @flow
 * @relayHash 790bd3c86ee334af216c94083d82b7e1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddUpdateDowntimeCategoryModal_downtimeCategory$ref = any;
type DeleteDowntimeCategoryModal_downtimeCategory$ref = any;
export type DowntimeCategoriesQueryVariables = {||};
export type DowntimeCategoriesQueryResponse = {|
  +downtimeCategories: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +childCategories: {|
          +edges: ?$ReadOnlyArray<?{|
            +node: {|
              +id: string,
              +name: string,
              +$fragmentRefs: AddUpdateDowntimeCategoryModal_downtimeCategory$ref & DeleteDowntimeCategoryModal_downtimeCategory$ref,
            |}
          |}>
        |},
        +$fragmentRefs: AddUpdateDowntimeCategoryModal_downtimeCategory$ref & DeleteDowntimeCategoryModal_downtimeCategory$ref,
      |}
    |}>
  |}
|};
export type DowntimeCategoriesQuery = {|
  variables: DowntimeCategoriesQueryVariables,
  response: DowntimeCategoriesQueryResponse,
|};
*/


/*
query DowntimeCategoriesQuery {
  downtimeCategories(parentsOnly: true) {
    edges {
      node {
        id
        name
        childCategories {
          edges {
            node {
              id
              name
              ...AddUpdateDowntimeCategoryModal_downtimeCategory
              ...DeleteDowntimeCategoryModal_downtimeCategory
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        ...AddUpdateDowntimeCategoryModal_downtimeCategory
        ...DeleteDowntimeCategoryModal_downtimeCategory
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment AddUpdateDowntimeCategoryModal_downtimeCategory on DowntimeCategory {
  id
  parentCategory {
    id
    name
  }
  childCategories {
    totalCount
  }
  name
}

fragment DeleteDowntimeCategoryModal_downtimeCategory on DowntimeCategory {
  id
  name
  parentCategory {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "FragmentSpread",
  "name": "AddUpdateDowntimeCategoryModal_downtimeCategory",
  "args": null
},
v4 = {
  "kind": "FragmentSpread",
  "name": "DeleteDowntimeCategoryModal_downtimeCategory",
  "args": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = [
  {
    "kind": "Literal",
    "name": "parentsOnly",
    "value": true
  }
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "parentCategory",
  "storageKey": null,
  "args": null,
  "concreteType": "DowntimeCategory",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DowntimeCategoriesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "downtimeCategories",
        "name": "__DowntimeCategories_downtimeCategories_connection",
        "storageKey": null,
        "args": null,
        "concreteType": "DowntimeCategoryConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DowntimeCategoryEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DowntimeCategory",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": "childCategories",
                    "name": "__DowntimeCategories_downtimeCategories_childCategories_connection",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DowntimeCategoryConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DowntimeCategoryEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DowntimeCategory",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v1/*: any*/),
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ]
                          },
                          (v5/*: any*/)
                        ]
                      },
                      (v6/*: any*/)
                    ]
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v5/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DowntimeCategoriesQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "downtimeCategories",
        "storageKey": "downtimeCategories(parentsOnly:true)",
        "args": (v7/*: any*/),
        "concreteType": "DowntimeCategoryConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DowntimeCategoryEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DowntimeCategory",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "childCategories",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DowntimeCategoryConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DowntimeCategoryEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DowntimeCategory",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              (v1/*: any*/),
                              (v8/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "childCategories",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "DowntimeCategoryConnection",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/)
                                ]
                              },
                              (v2/*: any*/)
                            ]
                          },
                          (v5/*: any*/)
                        ]
                      },
                      (v6/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedHandle",
                    "alias": null,
                    "name": "childCategories",
                    "args": null,
                    "handle": "connection",
                    "key": "DowntimeCategories_downtimeCategories_childCategories",
                    "filters": null
                  },
                  (v8/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v5/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "downtimeCategories",
        "args": (v7/*: any*/),
        "handle": "connection",
        "key": "DowntimeCategories_downtimeCategories",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DowntimeCategoriesQuery",
    "id": null,
    "text": "query DowntimeCategoriesQuery {\n  downtimeCategories(parentsOnly: true) {\n    edges {\n      node {\n        id\n        name\n        childCategories {\n          edges {\n            node {\n              id\n              name\n              ...AddUpdateDowntimeCategoryModal_downtimeCategory\n              ...DeleteDowntimeCategoryModal_downtimeCategory\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        ...AddUpdateDowntimeCategoryModal_downtimeCategory\n        ...DeleteDowntimeCategoryModal_downtimeCategory\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AddUpdateDowntimeCategoryModal_downtimeCategory on DowntimeCategory {\n  id\n  parentCategory {\n    id\n    name\n  }\n  childCategories {\n    totalCount\n  }\n  name\n}\n\nfragment DeleteDowntimeCategoryModal_downtimeCategory on DowntimeCategory {\n  id\n  name\n  parentCategory {\n    id\n    name\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": null
        },
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "downtimeCategories"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9eaa499af10a86cebe58e33584e39b86';
module.exports = node;

// @flow

import { AbstractNodeFactory, DiagramEngine } from 'storm-react-diagrams';
import React from 'react';
import IconWidget from './IconWidget';
import IconNodeModel from './IconNodeModel';

class IconNodeFactory extends AbstractNodeFactory {
  constructor(icon: string) {
    super(icon);
    this.icon = icon;
  }

  generateReactWidget = (diagramEngine: DiagramEngine, node: IconNodeModel) => {
    const {
      data: { name, runMinutesOverride, form, defaultUser },
    } = node;
    const IconWidgetProps = {
      name,
      defaultUserLabel: defaultUser?.label,
      formLabel: form?.label,
      runMinutesOverride,
    };
    const model = diagramEngine.getDiagramModel();
    const isSelected = model.activeNodeId === node.id;
    const displayDefaultColor = isSelected || !model.activeNodeId;

    return (
      <IconWidget
        icon={this.icon}
        node={node}
        displayDefaultColor={
          displayDefaultColor ? displayDefaultColor : isSelected
        }
        {...IconWidgetProps}
      />
    );
  };

  getNewInstance = () => new IconNodeModel(this.icon);
}

export default IconNodeFactory;

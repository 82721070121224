// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import Button from 'shared/components/common/Button';
import { Link, type RouterHistory } from 'react-router-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import AddItemMovementModal from 'main-app/components/AddItemMovementModal';
import TransactionRecord from 'shared/components/common/TransactionRecord';
import UserLabel from 'shared/components/common/QueryWidget/UserLabel';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import type { ItemTransactionLog_item as ItemFragment } from './__generated__/ItemTransactionLog_item';

type Props = {
  history: RouterHistory,
  item: ItemFragment,
};

type State = {
  addModalOpen: boolean,
  createAnotherMovement: boolean,
};

const Lot = styled.span`
  color: ${colors.azul};
  ${fonts.bodyBold}
`;

const ButtonWrapper = styled.div`
  padding-bottom: 16px;
`;

class ItemTransactionLog extends PureComponent<Props, State> {
  state = {
    addModalOpen: false,
    createAnotherMovement: false,
  };

  handleToggleItemMovementModal = () => {
    const { addModalOpen } = this.state;

    this.setState({
      addModalOpen: !addModalOpen,
      createAnotherMovement: false,
    });
  };

  handleAddModalReopen = () => {
    this.setState(
      {
        addModalOpen: false,
        createAnotherMovement: true,
      },
      () => {
        this.setState({
          addModalOpen: true,
        });
      },
    );
  };

  handleCloseModal = () => {
    this.setState({
      addModalOpen: false,
      createAnotherMovement: false,
    });
  };

  getTitle = operation => {
    if (operation === 'ADD') {
      return i18n.t('Items Added');
    }
    if (operation === 'DEDUCT') {
      return i18n.t('Items Deducted');
    }
    if (operation === 'TRANSFER') {
      return i18n.t('Items Transferred');
    }
  };

  formatLot = lot => (
    <Lot>
      <Link to={`/lot/${lot.id}`}>{lot.lotNumber}</Link>
    </Lot>
  );

  sortTransactionsByDate = txs => {
    const txsCopy = [...txs];
    return txsCopy.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  };

  getTransactions = item => {
    if (!item.logs || !item.logs.length)
      return (
        <EmptyListResults
          graphic="ledger"
          message={i18n.t(
            'Your item ledger will display here. Create Item Movements to add, remove, or transfer between lots.',
          )}
        />
      );
    const unitOfMeasurement = item?.quantityUOM?.name || 'Quantity';
    const sortedTransactions = this.sortTransactionsByDate(item.logs);

    const transactions = sortedTransactions.map(tx => {
      const title = this.getTitle(tx.operation);
      const lot = item?.lotTracking ? this.formatLot(tx.lot) : null;
      const fromLot = tx.fromLot ? this.formatLot(tx.fromLot) : null;
      const iconType = `inventory-${tx.operation.toLowerCase()}`;
      const quantity =
        tx.operation === 'DEDUCT' ? tx.quantity * -1 : tx.quantity;
      const createdDate =
        moment(tx.createdAt).format('dddd MMMM Do YYYY @ h:mm A') || '';

      return (
        <TransactionRecord
          key={tx.id}
          title={title}
          headerRight={createdDate}
          iconType={iconType}
          notes={tx.notes}
        >
          <div>
            {tx?.user?.id && <UserLabel userId={tx.user.id} />}
            {tx.operation === 'ADD' ? (
              <span>
                &nbsp;added {i18n.t('{{quantity, number}}', { quantity })}{' '}
                {unitOfMeasurement} {lot ? <span>to {lot}</span> : null}
              </span>
            ) : tx.operation === 'DEDUCT' ? (
              <span>
                &nbsp;deducted {i18n.t('{{quantity, number}}', { quantity })}{' '}
                {unitOfMeasurement} {lot ? <span>from {lot}</span> : null}
              </span>
            ) : tx.operation === 'TRANSFER' ? (
              <span>
                &nbsp;transferred {i18n.t('{{quantity, number}}', { quantity })}{' '}
                {unitOfMeasurement} from {fromLot} to {lot}
              </span>
            ) : null}
          </div>
        </TransactionRecord>
      );
    });

    return transactions;
  };

  render() {
    const { item, history } = this.props;
    const { addModalOpen, createAnotherMovement } = this.state;
    const transactions = this.getTransactions(item);

    return (
      <>
        <ButtonWrapper>
          <Button
            theme="blue"
            width="auto"
            onClick={this.handleToggleItemMovementModal}
          >
            {i18n.t('Create Item Movement')}
          </Button>
        </ButtonWrapper>
        {transactions}
        {addModalOpen && (
          <AddItemMovementModal
            onClose={this.handleCloseModal}
            onSuccess={order => history.push(`/item/${item.id}`)}
            onReopen={this.handleAddModalReopen}
            createAnotherMovement={createAnotherMovement}
            item={item}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(ItemTransactionLog, {
  item: graphql`
    fragment ItemTransactionLog_item on Item {
      id
      itemNumber
      quantityUOM {
        id
        name
      }
      logs {
        id
        user {
          id
        }
        lot {
          id
          lotNumber
          binNumber
          expiresAt
        }
        fromLot {
          id
          lotNumber
          binNumber
          expiresAt
        }
        operation
        quantity
        createdAt
        notes
      }
      lots(first: null) @connection(key: "Item_lots", filters: []) {
        edges {
          quantity
          node {
            id
            lotNumber
          }
        }
      }
      lotTracking
      ...AddItemMovementModal_item
    }
  `,
});

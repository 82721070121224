// @flow

import { i18n } from 'shared/utils';

export default () => [
  {
    label: i18n.t('Customer # · Low to High'),
    value: { field: 'CUSTOMER_NUMBER', direction: 'ASC' },
  },
  {
    label: i18n.t('Customer # · High to Low'),
    value: { field: 'CUSTOMER_NUMBER', direction: 'DESC' },
  },
  {
    label: i18n.t('Customer Name · A to Z'),
    value: { field: 'NAME', direction: 'ASC' },
  },
  {
    label: i18n.t('Customer Name · Z to A'),
    value: { field: 'NAME', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Modified · Oldest'),
    value: { field: 'UPDATED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Modified · Most Recent'),
    value: { field: 'UPDATED_AT', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Created - Oldest'),
    value: { field: 'CREATED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Created - Most Recent'),
    value: { field: 'CREATED_AT', direction: 'DESC' },
  },
];

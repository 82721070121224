// @flow

import React from 'react';
import { FieldGroup } from 'shared/components/form';
import SpecialFieldsMap from '../SpecialFieldsMap';
import type { FilterOption } from '..';

type Props = {
  filterName: {
    value: string,
  },
  filterOptions: Array<FilterOption>,
};

export default ({ filterOptions, filterName }: Props) => {
  const currentFilterOption = filterOptions.find(
    a => a.name === filterName.value,
  );

  if (!currentFilterOption) {
    return null;
  }

  const specialField = SpecialFieldsMap[currentFilterOption.type];

  if (specialField && specialField.component) {
    return (
      <specialField.component
        name="filterValue"
        label={null}
        placeholder={currentFilterOption.label}
        {...(currentFilterOption.componentProps || {})}
      />
    );
  }

  return (
    <FieldGroup
      name="filterValue"
      type={currentFilterOption.type}
      options={currentFilterOption.selectOptions || undefined}
      placeholder={currentFilterOption.label}
    />
  );
};

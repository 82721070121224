// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateIntegrationMutation($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      integrationEdge {
        node {
          id
          isEnabled
          source {
            id
            name
            description
            logo
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

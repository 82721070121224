/**
 * @flow
 * @relayHash 5769b852f664aed282121a409be1ce51
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProductionScheduleChart_data$ref = any;
export type ProductionScheduleChartQueryVariables = {|
  machineTypeId?: ?string,
  workflowIds?: ?$ReadOnlyArray<string>,
  startAt: any,
  endAt: any,
|};
export type ProductionScheduleChartQueryResponse = {|
  +$fragmentRefs: ProductionScheduleChart_data$ref
|};
export type ProductionScheduleChartQuery = {|
  variables: ProductionScheduleChartQueryVariables,
  response: ProductionScheduleChartQueryResponse,
|};
*/


/*
query ProductionScheduleChartQuery(
  $machineTypeId: ID
  $workflowIds: [ID!]
  $startAt: DateTime!
  $endAt: DateTime!
) {
  ...ProductionScheduleChart_data_4hk1Sy
}

fragment ProductionScheduleChart_data_4hk1Sy on Query {
  machines(machineTypeId: $machineTypeId, workflowIds: $workflowIds, sortBy: {field: PRODUCTION_ORDER, direction: ASC}) {
    edges {
      node {
        id
        name
        type {
          id
          name
        }
        currentDowntime {
          id
        }
        workflows {
          id
        }
        ...AddUpdateRunModal_defaultMachine
      }
    }
  }
  scheduleEvents(startAt: $startAt, endAt: $endAt) {
    edges {
      node {
        id
        runId
        machineId
        jobId
        name
        type
        startAt
        endAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment AddUpdateRunModal_defaultMachine on Machine {
  id
  type {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "machineTypeId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "workflowIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "DateTime!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "endAt",
  "variableName": "endAt"
},
v2 = {
  "kind": "Variable",
  "name": "machineTypeId",
  "variableName": "machineTypeId"
},
v3 = {
  "kind": "Variable",
  "name": "startAt",
  "variableName": "startAt"
},
v4 = {
  "kind": "Variable",
  "name": "workflowIds",
  "variableName": "workflowIds"
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = [
  (v5/*: any*/)
],
v8 = [
  (v1/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProductionScheduleChartQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ProductionScheduleChart_data",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductionScheduleChartQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "machines",
        "storageKey": null,
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "sortBy",
            "value": {
              "direction": "ASC",
              "field": "PRODUCTION_ORDER"
            }
          },
          (v4/*: any*/)
        ],
        "concreteType": "MachineConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MachineEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Machine",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "type",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MachineType",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "currentDowntime",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MachineDowntime",
                    "plural": false,
                    "selections": (v7/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "workflows",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Workflow",
                    "plural": true,
                    "selections": (v7/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "scheduleEvents",
        "storageKey": null,
        "args": (v8/*: any*/),
        "concreteType": "ScheduleEventConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "ScheduleEventEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "ScheduleEvent",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "runId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "machineId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "jobId",
                    "args": null,
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "scheduleEvents",
        "args": (v8/*: any*/),
        "handle": "connection",
        "key": "ProductionScheduleChart_scheduleEvents",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProductionScheduleChartQuery",
    "id": null,
    "text": "query ProductionScheduleChartQuery(\n  $machineTypeId: ID\n  $workflowIds: [ID!]\n  $startAt: DateTime!\n  $endAt: DateTime!\n) {\n  ...ProductionScheduleChart_data_4hk1Sy\n}\n\nfragment ProductionScheduleChart_data_4hk1Sy on Query {\n  machines(machineTypeId: $machineTypeId, workflowIds: $workflowIds, sortBy: {field: PRODUCTION_ORDER, direction: ASC}) {\n    edges {\n      node {\n        id\n        name\n        type {\n          id\n          name\n        }\n        currentDowntime {\n          id\n        }\n        workflows {\n          id\n        }\n        ...AddUpdateRunModal_defaultMachine\n      }\n    }\n  }\n  scheduleEvents(startAt: $startAt, endAt: $endAt) {\n    edges {\n      node {\n        id\n        runId\n        machineId\n        jobId\n        name\n        type\n        startAt\n        endAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AddUpdateRunModal_defaultMachine on Machine {\n  id\n  type {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f7aca2b8a81791fd415639650b54de3';
module.exports = node;

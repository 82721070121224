// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateCustomerMutation($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customerEdge {
        node {
          id
          customerNumber
          name
          primaryContactName
          secondaryContactName
          email
          phone
          openOrders
          salesUser {
            ...UserAvatar_user
          }
          ...CustomerDetails_customer
          ...AddUpdateOrderModal_customer
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

/**
 * @flow
 * @relayHash 9e362f25b59203d1e0999c5dfba27be5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Diagram_workflow$ref = any;
export type WorkflowQueryVariables = {|
  id: string
|};
export type WorkflowQueryResponse = {|
  +workflow: {|
    +id: string,
    +name: string,
    +isDefault: boolean,
    +$fragmentRefs: Diagram_workflow$ref,
  |}
|};
export type WorkflowQuery = {|
  variables: WorkflowQueryVariables,
  response: WorkflowQueryResponse,
|};
*/


/*
query WorkflowQuery(
  $id: ID!
) {
  workflow(id: $id) {
    id
    name
    isDefault
    ...Diagram_workflow
  }
}

fragment Diagram_workflow on Workflow {
  id
  name
  isDefault
  states {
    id
    name
    type
    form {
      id
      name
    }
    machineType {
      id
      name
    }
    runQuantityPerHour
    runMinutesOverride
    postRunDelayMinutes
    isRequired
    isGangRun
    trackRunQuantityType
    isTrackingSetupQuantity
    isPromptingFinalQuantity
    isAssignable
    defaultLot {
      id
      lotNumber
    }
    defaultUser {
      id
      firstName
      lastName
    }
  }
  transitions {
    id
    toWorkflowState {
      id
    }
    fromWorkflowState {
      id
    }
    actions {
      id
      type
      metadata
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDefault",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v7 = [
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "WorkflowQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "workflow",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Workflow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "Diagram_workflow",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WorkflowQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "workflow",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Workflow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "states",
            "storageKey": null,
            "args": null,
            "concreteType": "WorkflowState",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "form",
                "storageKey": null,
                "args": null,
                "concreteType": "Form",
                "plural": false,
                "selections": (v6/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "machineType",
                "storageKey": null,
                "args": null,
                "concreteType": "MachineType",
                "plural": false,
                "selections": (v6/*: any*/)
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "runQuantityPerHour",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "runMinutesOverride",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "postRunDelayMinutes",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isRequired",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isGangRun",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "trackRunQuantityType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isTrackingSetupQuantity",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isPromptingFinalQuantity",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isAssignable",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "defaultLot",
                "storageKey": null,
                "args": null,
                "concreteType": "Lot",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lotNumber",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "defaultUser",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "transitions",
            "storageKey": null,
            "args": null,
            "concreteType": "WorkflowTransition",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "toWorkflowState",
                "storageKey": null,
                "args": null,
                "concreteType": "WorkflowState",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "fromWorkflowState",
                "storageKey": null,
                "args": null,
                "concreteType": "WorkflowState",
                "plural": false,
                "selections": (v7/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actions",
                "storageKey": null,
                "args": null,
                "concreteType": "WorkflowTransitionAction",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "metadata",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "WorkflowQuery",
    "id": null,
    "text": "query WorkflowQuery(\n  $id: ID!\n) {\n  workflow(id: $id) {\n    id\n    name\n    isDefault\n    ...Diagram_workflow\n  }\n}\n\nfragment Diagram_workflow on Workflow {\n  id\n  name\n  isDefault\n  states {\n    id\n    name\n    type\n    form {\n      id\n      name\n    }\n    machineType {\n      id\n      name\n    }\n    runQuantityPerHour\n    runMinutesOverride\n    postRunDelayMinutes\n    isRequired\n    isGangRun\n    trackRunQuantityType\n    isTrackingSetupQuantity\n    isPromptingFinalQuantity\n    isAssignable\n    defaultLot {\n      id\n      lotNumber\n    }\n    defaultUser {\n      id\n      firstName\n      lastName\n    }\n  }\n  transitions {\n    id\n    toWorkflowState {\n      id\n    }\n    fromWorkflowState {\n      id\n    }\n    actions {\n      id\n      type\n      metadata\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3a1e6229b7f0e1f5be276b676a50da41';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import type { RouterHistory } from 'react-router';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import UserWidget from 'shared/components/common/QueryWidget/UserWidget';
import Loader from 'shared/components/common/Loader';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import IconWithLabel from 'shared/components/common/Icon/IconWithLabel';
import Button from 'shared/components/common/Button';
import AddUpdateAPIKeyModal from 'main-app/components/AddUpdateAPIKeyModal';
import DeleteAPIKeyModal from 'main-app/components/DeleteAPIKeyModal';
import APIKeySecret from './APIKeySecret';

type Props = {
  history: RouterHistory,
};

type State = {
  addModalOpen: boolean,
  updateModalRecord: ?Object,
  deleteModalRecord: ?Object,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const GoToDocsWrapper = styled.div`
  padding-bottom: 16px;

  @media (min-width: 1000px) {
    flex: 1;
    padding-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  padding-bottom: 24px;

  @media (min-width: 1000px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;

const ListWrapper = styled.div`
  flex: 1;
`;

class APIKeys extends PureComponent<Props, State> {
  menuTriggerRefs = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      addModalOpen: false,
      updateModalRecord: null,
      deleteModalRecord: null,
    };
  }

  handleOpenUpdateModal = (target: *, apiKeys: ?Array<Object>) => {
    const apiKeyId = target.getAttribute('api-key-id');

    const apiKey = apiKeys
      ? apiKeys.find(apiKey => apiKey.id === apiKeyId)
      : null;

    if (apiKey) {
      this.setState({
        updateModalRecord: apiKey,
      });
    }
  };

  handleOpenDeleteModal = (target: *, apiKeys: ?Array<Object>) => {
    const apiKeyId = target.getAttribute('api-key-id');

    const apiKey = apiKeys
      ? apiKeys.find(apiKey => apiKey.id === apiKeyId)
      : null;

    if (apiKey) {
      this.setState({
        deleteModalRecord: apiKey,
      });
    }
  };

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      updateModalRecord: null,
      deleteModalRecord: null,
    });
  };

  render() {
    const { history } = this.props;
    const { addModalOpen, updateModalRecord, deleteModalRecord } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query APIKeysQuery {
            viewer {
              id
              organization {
                id
                apiKeys {
                  id
                  name
                  secret
                  lastUsedAt
                  createdAt
                  actingUser {
                    id
                    firstName
                    lastName
                    ...UserWidget_user
                  }
                  ...AddUpdateAPIKeyModal_apiKey
                  ...DeleteAPIKeyModal_apiKey
                }
              }
            }
          }
        `}
        variables={null}
        render={({ props }) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <Wrapper>
              <ButtonWrapper>
                <GoToDocsWrapper>
                  <IconWithLabel
                    onClick={() =>
                      window.open('https://developer.workclout.com')
                    }
                    label={i18n.t('Open the REST API Reference')}
                    type="circle-new-window"
                  />
                </GoToDocsWrapper>
                <Button
                  onClick={this.handleOpenCreateModal}
                  theme="border-white"
                  width="auto"
                >
                  {i18n.t('Create API Key')}
                </Button>
              </ButtonWrapper>

              {!props.viewer.organization.apiKeys.length ? (
                <div>{i18n.t(`You haven't added any api keys yet.`)}</div>
              ) : (
                <ListWrapper>
                  <Table
                    data={props.viewer.organization.apiKeys}
                    columns={[
                      {
                        Header: i18n.t('API User'),
                        accessor: 'actingUser',
                        width: 160,
                        Cell: ({ value }: *) => (
                          <UserWidget user={value} width={28} />
                        ),
                      },
                      {
                        Header: i18n.t('Name'),
                        accessor: 'name',
                        width: 160,
                      },
                      {
                        Header: i18n.t('API Secret'),
                        accessor: 'secret',
                        Cell: ({ value }) => <APIKeySecret secret={value} />,
                      },
                      {
                        Header: '',
                        accessor: 'id',
                        width: 48,
                        enforceWidth: true,
                        Cell: ({ value }) => (
                          <ContextMenuTrigger
                            ref={r => (this.menuTriggerRefs[value] = r)}
                            id="settings-api-keys-menu"
                            attributes={{
                              'api-key-id': value,
                            }}
                          >
                            <Icon
                              type="circle-context-menu"
                              size={24}
                              onClick={e => {
                                if (this.menuTriggerRefs[value]) {
                                  this.menuTriggerRefs[
                                    value
                                  ].handleContextClick(e);
                                }
                              }}
                            />
                          </ContextMenuTrigger>
                        ),
                      },
                    ]}
                  />
                </ListWrapper>
              )}
              {addModalOpen && (
                <AddUpdateAPIKeyModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  apiKey={null}
                />
              )}
              {updateModalRecord && (
                <AddUpdateAPIKeyModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  apiKey={updateModalRecord}
                />
              )}
              {deleteModalRecord && (
                <DeleteAPIKeyModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  apiKey={deleteModalRecord}
                />
              )}
              <ContextMenu id="settings-api-keys-menu">
                <MenuItem
                  onClick={(e, data, target) =>
                    this.handleOpenUpdateModal(
                      target,
                      props.viewer.organization.apiKeys,
                    )
                  }
                >
                  {i18n.t('Edit API Key')}
                </MenuItem>
                <MenuItem
                  onClick={(e, data, target) =>
                    this.handleOpenDeleteModal(
                      target,
                      props.viewer.organization.apiKeys,
                    )
                  }
                >
                  {i18n.t('Delete API Key')}
                </MenuItem>
              </ContextMenu>
            </Wrapper>
          );
        }}
      />
    );
  }
}

export default APIKeys;

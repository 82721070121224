// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import SidePanel from 'shared/components/common/SidePanel';
import PanelSection from 'shared/components/common/SidePanel/PanelSection';
import MultilineText from 'shared/components/common/MultilineText';
import Divider from 'shared/components/common/Divider';
import type { CustomerSidePanel_customer as CustomerFragment } from './__generated__/CustomerSidePanel_customer';

type Props = {
  customer: CustomerFragment,
};

const CustomerSidePanel = ({ customer }: Props) => (
  <>
    <SidePanel>
      <PanelSection
        title="Customer Info"
        fields={[
          {
            label: i18n.t('Customer #'),
            value: customer.customerNumber || '',
          },
          {
            label: i18n.t('Notes'),
            value: <MultilineText>{customer.notes}</MultilineText>,
          },
        ]}
      />
      <Divider />
      <PanelSection
        title="Secondary Contact"
        fields={[
          {
            label: i18n.t('Secondary Contact'),
            value: customer.secondaryContactName || '',
          },
          {
            label: i18n.t('Secondary Phone Number'),
            value: customer.phone2 || '',
          },
          {
            label: i18n.t('Fax'),
            value: customer.fax || '',
          },
          {
            label: i18n.t('Email'),
            value: customer.email || '',
          },
        ]}
      />
      <Divider />
      <PanelSection
        title="Address"
        fields={[
          {
            label: i18n.t('Billing Address'),
            value: (
              <div>
                {customer.billingAddressLine1}
                {customer.billingAddressLine2 && (
                  <div>{customer.billingAddressLine2}</div>
                )}
                {customer.billingAddressLine3 && (
                  <div>{customer.billingAddressLine3}</div>
                )}
                <div>
                  {customer.billingAddressCity} {customer.billingAddressState}{' '}
                  {customer.billingAddressZip}
                </div>
              </div>
            ),
          },
          {
            label: i18n.t('Shipping Address'),
            value: (
              <div>
                {customer.shippingAddressLine1}
                {customer.shippingAddressLine2 && (
                  <div>{customer.shippingAddressLine2}</div>
                )}
                {customer.shippingAddressLine3 && (
                  <div>{customer.shippingAddressLine3}</div>
                )}
                <div>
                  {customer.shippingAddressCity} {customer.shippingAddressState}{' '}
                  {customer.shippingAddressZip}
                </div>
              </div>
            ),
          },
        ]}
      />
    </SidePanel>
  </>
);

export default createFragmentContainer(CustomerSidePanel, {
  customer: graphql`
    fragment CustomerSidePanel_customer on Customer {
      id
      customerNumber
      secondaryContactName
      shippingAddressLine1
      shippingAddressLine2
      shippingAddressLine3
      shippingAddressCity
      shippingAddressState
      shippingAddressZip
      billingAddressLine1
      billingAddressLine2
      billingAddressLine3
      billingAddressCity
      billingAddressState
      billingAddressZip
      email
      phone
      phone2
      fax
      notes
    }
  `,
});

/**
 * @flow
 * @relayHash 9c0ceb059c8bd815c30eee1330a233d8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddUpdateJobModalQueryVariables = {|
  id: string
|};
export type AddUpdateJobModalQueryResponse = {|
  +order: {|
    +id: string,
    +yields: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +orderedQuantity: number,
          +item: {|
            +id: string,
            +name: string,
            +quantityUOM: {|
              +id: string,
              +symbol: string,
            |},
          |},
        |}
      |}>
    |},
  |}
|};
export type AddUpdateJobModalQuery = {|
  variables: AddUpdateJobModalQueryVariables,
  response: AddUpdateJobModalQueryResponse,
|};
*/


/*
query AddUpdateJobModalQuery(
  $id: ID!
) {
  order(id: $id) {
    id
    yields {
      edges {
        node {
          id
          orderedQuantity
          item {
            id
            name
            quantityUOM {
              id
              symbol
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "order",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Order",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "yields",
        "storageKey": null,
        "args": null,
        "concreteType": "OrderYieldConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "OrderYieldEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderYield",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderedQuantity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "item",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Item",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "quantityUOM",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UOM",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "symbol",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddUpdateJobModalQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddUpdateJobModalQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "AddUpdateJobModalQuery",
    "id": null,
    "text": "query AddUpdateJobModalQuery(\n  $id: ID!\n) {\n  order(id: $id) {\n    id\n    yields {\n      edges {\n        node {\n          id\n          orderedQuantity\n          item {\n            id\n            name\n            quantityUOM {\n              id\n              symbol\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ea5fc8ba16a3b0e8ad025605a9068573';
module.exports = node;

// @flow

import React from 'react';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';

const StyledAsterisk = styled.span`
  color: ${p => p.color};
  padding-left: 2px;
`;

type Props = {
  children: string,
  color?: string,
  style?: Object,
};

const Asterisk = ({ children, color, style }: Props) => (
  <StyledAsterisk color={color} style={style}>
    {children}
  </StyledAsterisk>
);

Asterisk.defaultProps = {
  style: undefined,
  color: colors.cherry,
};

export default Asterisk;

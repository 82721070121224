/**
 * @flow
 * @relayHash f14eb722f257635e930f747c3af5dc6d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCompanyCalendarEventInput = {|
  id: string,
  name?: ?string,
  isFullDay?: ?boolean,
  isOpen?: ?boolean,
  startAt?: ?any,
  endAt?: ?any,
|};
export type UpdateCompanyCalendarEventMutationVariables = {|
  input: UpdateCompanyCalendarEventInput
|};
export type UpdateCompanyCalendarEventMutationResponse = {|
  +updateCompanyCalendarEvent: {|
    +company: {|
      +id: string,
      +name: ?string,
      +namespace: string,
      +sundayHoursStart: ?string,
      +sundayHoursEnd: ?string,
      +mondayHoursStart: ?string,
      +mondayHoursEnd: ?string,
      +tuesdayHoursStart: ?string,
      +tuesdayHoursEnd: ?string,
      +wednesdayHoursStart: ?string,
      +wednesdayHoursEnd: ?string,
      +thursdayHoursStart: ?string,
      +thursdayHoursEnd: ?string,
      +fridayHoursStart: ?string,
      +fridayHoursEnd: ?string,
      +saturdayHoursStart: ?string,
      +saturdayHoursEnd: ?string,
      +calendarEvents: $ReadOnlyArray<{|
        +id: string,
        +name: string,
        +isFullDay: boolean,
        +isOpen: boolean,
        +startAt: any,
        +endAt: any,
      |}>,
    |}
  |}
|};
export type UpdateCompanyCalendarEventMutation = {|
  variables: UpdateCompanyCalendarEventMutationVariables,
  response: UpdateCompanyCalendarEventMutationResponse,
|};
*/


/*
mutation UpdateCompanyCalendarEventMutation(
  $input: UpdateCompanyCalendarEventInput!
) {
  updateCompanyCalendarEvent(input: $input) {
    company {
      id
      name
      namespace
      sundayHoursStart
      sundayHoursEnd
      mondayHoursStart
      mondayHoursEnd
      tuesdayHoursStart
      tuesdayHoursEnd
      wednesdayHoursStart
      wednesdayHoursEnd
      thursdayHoursStart
      thursdayHoursEnd
      fridayHoursStart
      fridayHoursEnd
      saturdayHoursStart
      saturdayHoursEnd
      calendarEvents {
        id
        name
        isFullDay
        isOpen
        startAt
        endAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCompanyCalendarEventInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateCompanyCalendarEvent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCompanyCalendarEventPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "company",
        "storageKey": null,
        "args": null,
        "concreteType": "Company",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "namespace",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sundayHoursStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sundayHoursEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "mondayHoursStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "mondayHoursEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tuesdayHoursStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "tuesdayHoursEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "wednesdayHoursStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "wednesdayHoursEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "thursdayHoursStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "thursdayHoursEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fridayHoursStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fridayHoursEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "saturdayHoursStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "saturdayHoursEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "calendarEvents",
            "storageKey": null,
            "args": null,
            "concreteType": "CompanyCalendarEvent",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isFullDay",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isOpen",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endAt",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateCompanyCalendarEventMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateCompanyCalendarEventMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateCompanyCalendarEventMutation",
    "id": null,
    "text": "mutation UpdateCompanyCalendarEventMutation(\n  $input: UpdateCompanyCalendarEventInput!\n) {\n  updateCompanyCalendarEvent(input: $input) {\n    company {\n      id\n      name\n      namespace\n      sundayHoursStart\n      sundayHoursEnd\n      mondayHoursStart\n      mondayHoursEnd\n      tuesdayHoursStart\n      tuesdayHoursEnd\n      wednesdayHoursStart\n      wednesdayHoursEnd\n      thursdayHoursStart\n      thursdayHoursEnd\n      fridayHoursStart\n      fridayHoursEnd\n      saturdayHoursStart\n      saturdayHoursEnd\n      calendarEvents {\n        id\n        name\n        isFullDay\n        isOpen\n        startAt\n        endAt\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '981d959efbfcbedb2f7f9252e3ce1a25';
module.exports = node;

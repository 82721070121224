// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import Icon from 'shared/components/common/Icon';

type Props = {
  secret: string,
};

type State = {
  show: boolean,
};

const SecretWrapper = styled.div`
  color: ${colors.charcoalGrey};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Secret = styled.div`
  overflow-x: auto;
  ${p => {
    if (p.show) {
      return fonts.smallRegular;
    }

    return fonts.headerBold;
  }};
`;

class APIKeySecret extends PureComponent<Props, State> {
  state = {
    show: false,
  };

  handleToggleSecret = () => {
    const { show } = this.state;

    this.setState({
      show: !show,
    });
  };

  render() {
    const { secret } = this.props;
    const { show } = this.state;

    return (
      <SecretWrapper>
        <Secret show={show}>
          {show ? (
            secret
          ) : (
            <>
              &#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;
            </>
          )}
        </Secret>
        <Icon
          type={show ? 'eye-active' : 'eye-inactive'}
          onClick={this.handleToggleSecret}
        />
      </SecretWrapper>
    );
  }
}

export default APIKeySecret;

// @flow

import React, { PureComponent } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddRunMutation from 'main-app/mutations/AddRun';
import UpdateRunMutation from 'main-app/mutations/UpdateRun';
import Button from 'shared/components/common/Button';
import Divider from 'shared/components/common/Divider';
import Icon from 'shared/components/common/Icon';
import IconWithLabel from 'shared/components/common/Icon/IconWithLabel';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldArray,
  FieldLabel,
  FieldGroup,
  SelectMachineField,
  SelectJobStateField,
} from 'shared/components/form';
import type { AddUpdateRunModal_job as JobFragment } from './__generated__/AddUpdateRunModal_job';
import type { AddUpdateRunModal_run as RunFragment } from './__generated__/AddUpdateRunModal_run';
import type { AddUpdateRunModal_defaultMachine as DefaultMachineFragment } from './__generated__/AddUpdateRunModal_defaultMachine';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  onRunChanged?: Object => void,
  job: JobFragment,
  run: ?RunFragment,
  defaultMachine: DefaultMachineFragment,
  defaultRunAt?: moment$Moment | string | number,
  disableSelectJobStateField?: boolean,
};

const ItemRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-bottom: 16px;
`;

class AddUpdateRunModal extends PureComponent<Props> {
  static defaultProps = {
    defaultRunAt: undefined,
    onSuccess: undefined,
    onRunChanged: undefined,
    disableSelectJobStateField: false,
  };

  render() {
    const {
      job,
      run,
      onClose,
      onSuccess,
      onRunChanged,
      defaultMachine,
      defaultRunAt,
      disableSelectJobStateField,
    } = this.props;
    const jobYields = run
      ? (run?.yields.edges || []).filter(Boolean).map(item => ({
          jobYield: { value: item.node.id },
        }))
      : (job.yields.edges || []).filter(Boolean).map(item => ({
          jobYield: { value: item.node.id },
        }));

    const selectedMachine = run?.machine || defaultMachine;

    const statesByMachineType =
      selectedMachine &&
      job.states.filter(
        step =>
          step.isEnabled &&
          step.workflowState.machineType?.id === selectedMachine.type.id,
      );

    const jobStateId = run?.jobState
      ? run?.jobState.id
      : !statesByMachineType || statesByMachineType.length > 1
      ? null
      : statesByMachineType[0].id;

    const scheduledStartAt = defaultRunAt
      ? moment(defaultRunAt)
      : run && run.scheduledStartAt
      ? moment(run.scheduledStartAt)
      : null;

    return (
      <Modal maxWidth={700}>
        <Formik
          initialValues={{
            machine: selectedMachine
              ? { value: selectedMachine.id }
              : run && {
                  value: run.machine.id,
                  data: run.machine,
                },
            scheduledStartAt,
            jobState: jobStateId && {
              value: jobStateId,
            },
            notes: run?.notes || '',
            jobYields,
          }}
          isInitialValid={selectedMachine && defaultRunAt}
          validationSchema={Yup.object({
            machine: Yup.object()
              .nullable()
              .required('Required'),
            scheduledStartAt: Yup.date().nullable(),
            notes: Yup.string(),
            jobState: Yup.object()
              .nullable()
              .required('Required'),
            jobYields: Yup.array().of(
              Yup.object().shape({
                jobYield: Yup.object().required('Required'),
              }),
            ),
          })}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const mutation = run ? UpdateRunMutation : AddRunMutation;
            const {
              machine,
              scheduledStartAt,
              notes,
              jobYields,
              jobState,
            } = values;
            const input: any = {
              machineId: machine.value,
              scheduledStartAt,
              notes,
              jobStateId: jobState.value,
              jobYieldIds: jobYields
                .filter(item => item.jobYield?.value)
                .map(item => item.jobYield.value),
            };

            if (run) {
              input.id = run.id;
            } else {
              input.jobId = job.id;
            }

            try {
              const response = await mutation.commit({
                variables: {
                  input,
                },
              });

              const runResponse = run
                ? response.updateRun.runEdge.node
                : response.addRun.runEdge.node;

              onClose();

              if (onSuccess) {
                onSuccess(runResponse);
              }

              if (
                run &&
                run.machine.id !== runResponse.machine.id &&
                onRunChanged
              ) {
                onRunChanged();
              }

              Actions.alertNotification(
                i18n.t(`Run Successfully ${run ? 'Updated' : 'Created'}`),
                'success',
              );

              Analytics.trackEvent(`${run ? 'Update' : 'Create'} Run`, {
                machineName: runResponse.machine.name,
                machineType: runResponse.machine.type.name,
                scheduledAt: moment(runResponse.scheduledStartAt).format(
                  'L h:mm:ss A',
                ),
                jobNumber: runResponse.job.jobNumber,
              });
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(e.message, 'error');
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <Form>
                <ModalHeader
                  header={i18n.t(run ? 'Edit Run' : 'Create Run')}
                  onClose={onClose}
                />
                <ModalBody withPadding>
                  <SelectMachineField
                    name="machine"
                    label={i18n.t('Work Center')}
                    placeholder={i18n.t('Work Center Name')}
                    disabled={Boolean(defaultMachine)}
                    error={errors.machine}
                    queryVariables={
                      selectedMachine && {
                        machineTypeId: selectedMachine.type.id,
                      }
                    }
                  />
                  <SelectJobStateField
                    name="jobState"
                    label={i18n.t('Work Center Run Step')}
                    placeholder={i18n.t('Work Center Run Step')}
                    error={errors.jobState}
                    disabled={
                      disableSelectJobStateField ||
                      statesByMachineType?.length === 1
                    }
                    queryVariables={{
                      jobId: job.id,
                      machineTypeId: selectedMachine?.type.id || undefined,
                    }}
                  />
                  <FieldGroup
                    type="text"
                    disabled
                    label={i18n.t('Run Date & Time')}
                    value={moment(scheduledStartAt).format(
                      'MM/DD/YYYY hh:mm A',
                    )}
                  />

                  <FieldGroup
                    name="notes"
                    type="textarea"
                    label={i18n.t('Notes For Operator')}
                    placeholder={i18n.t('Type here...')}
                    error={errors.notes}
                  />
                  <Divider style={{ marginBottom: 24 }} />
                  <FieldLabel>{i18n.t('Item(s) On Run')}</FieldLabel>
                  <FieldArray
                    name="jobYields"
                    render={arrayHelpers => (
                      <>
                        {!values.jobYields?.length
                          ? null
                          : values.jobYields.map((item, i) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <ItemRow key={`item-${i}`}>
                                <Icon
                                  type="circle-minus"
                                  size={24}
                                  onClick={() => arrayHelpers.remove(i)}
                                  style={{ marginRight: 16 }}
                                />
                                <FieldGroup
                                  type="select"
                                  name={`jobYields.${i}.jobYield`}
                                  size="medium"
                                  options={(job?.yields.edges || [])
                                    .filter(Boolean)
                                    .filter(itemEdge => {
                                      const alreadyChosenItem = values.jobYields.find(
                                        (a, j) => {
                                          return (
                                            a.jobYield?.value ===
                                              itemEdge.node.id && j !== i
                                          );
                                        },
                                      );

                                      return !alreadyChosenItem;
                                    })
                                    .map(itemEdge => ({
                                      label: itemEdge.node.item.name,
                                      value: itemEdge.node.id,
                                    }))}
                                  style={{ paddingBottom: 0 }}
                                />
                              </ItemRow>
                            ))}
                        <IconWithLabel
                          type="circle-plus"
                          label={i18n.t('Add Another Item')}
                          onClick={() =>
                            arrayHelpers.push({
                              jobYield: null,
                            })
                          }
                        />
                      </>
                    )}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    theme="blue"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {i18n.t(run ? 'Update Run' : 'Create Run')}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(AddUpdateRunModal, {
  job: graphql`
    fragment AddUpdateRunModal_job on Job {
      id
      states {
        id
        isEnabled
        workflowState {
          id
          name
          machineType {
            id
          }
        }
      }
      yields(first: null)
        @connection(key: "AddUpdateRunModal_job_yields", filters: []) {
        edges {
          node {
            id
            item {
              id
              name
            }
          }
        }
      }
    }
  `,
  run: graphql`
    fragment AddUpdateRunModal_run on Run {
      id
      scheduledStartAt
      notes
      machine {
        id
        name
        type {
          id
        }
      }
      jobState {
        id
      }
      yields(first: null) {
        edges {
          node {
            id
            item {
              id
              name
            }
          }
        }
      }
    }
  `,
  defaultMachine: graphql`
    fragment AddUpdateRunModal_defaultMachine on Machine {
      id
      type {
        id
      }
    }
  `,
});

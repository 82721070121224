// @flow

import React from 'react';
import Panel from 'shared/components/common/Panel';
import { i18n } from 'shared/utils';
import moment from 'moment';
import { DateRangePicker, SelectWorkflowField } from 'shared/components/form';
import type { RouterHistory } from 'react-router';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import ItemProgress from './ItemProgress';

type Props = {
  history: RouterHistory,
  itemProgressStartAt: string,
  itemProgressEndAt: string,
  itemProgressWorkflowId: string,
  setFilters: (RouterHistory, Object) => void,
};

export default ({
  history,
  itemProgressStartAt,
  itemProgressEndAt,
  itemProgressWorkflowId,
  setFilters,
}: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    cacheConfig={{
      force: true,
    }}
    query={graphql`
      query ItemProgressPanelQuery(
        $itemProgressStartAt: DateTime!
        $itemProgressEndAt: DateTime!
        $itemProgressWorkflowId: ID
      ) {
        metricsItemProgress(
          startAt: $itemProgressStartAt
          endAt: $itemProgressEndAt
          workflowId: $itemProgressWorkflowId
        ) {
          id
          itemNumber
          name
          quantity
          quantityCompleted
        }
      }
    `}
    variables={{
      itemProgressStartAt,
      itemProgressEndAt,
      itemProgressWorkflowId,
    }}
    render={query => {
      if (!query.props) {
        return (
          <Panel style={{ marginTop: 24, height: 386 }}>
            <Loader />
          </Panel>
        );
      }

      return (
        <Panel
          style={{
            marginTop: 24,
          }}
          title={i18n.t('Production Metrics by Item')}
          headerRight={[
            <SelectWorkflowField
              rawField
              label={null}
              error={null}
              style={{ padding: 0 }}
              size="small"
              value={{
                value: itemProgressWorkflowId || '',
              }}
              onChange={val => {
                setFilters(history, {
                  itemProgressWorkflowId: val?.value || '',
                });
              }}
            />,
            <DateRangePicker
              value={{
                startDate: moment(itemProgressStartAt),
                endDate: moment(itemProgressEndAt),
              }}
              onChange={({ startDate, endDate }) =>
                setFilters(history, {
                  itemProgressStartAt: moment(startDate)
                    .startOf('day')
                    .format(),
                  itemProgressEndAt: moment(endDate)
                    .endOf('day')
                    .format(),
                })
              }
            />,
          ]}
        >
          <ItemProgress
            history={history}
            data={query.props.metricsItemProgress}
          />
        </Panel>
      );
    }}
  />
);

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteMachineMutation from 'main-app/mutations/DeleteMachine';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteMachineModal_machine as MachineFragment } from './__generated__/DeleteMachineModal_machine';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  machine: MachineFragment,
};

type State = {
  deleting: boolean,
};

class DeleteMachineModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  static defaultProps = {
    onSuccess: undefined,
  };

  handleDelete = () => {
    const { machine, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteMachineMutation.commit({
            variables: {
              input: {
                id: machine.id,
              },
            },
          });

          onClose();

          if (onSuccess) {
            onSuccess();
          }

          Analytics.trackEvent('Delete Machine', {
            machineName: machine.name,
            machineId: machine.id,
          });
          Actions.alertNotification(
            i18n.t(`Machine Successfully Deleted`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { machine, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete Machine: {{machineName}}?', {
            machineName: machine.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            `All runs associated to this work center will remain. Please delete the runs manually as needed.`,
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Machine')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteMachineModal, {
  machine: graphql`
    fragment DeleteMachineModal_machine on Machine {
      id
      name
    }
  `,
});

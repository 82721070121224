// @flow

import { graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      jobId: string,
      itemId: string,
      usageQty: number,
    },
  },
};

const mutation = graphql`
  mutation AddJobSubComponentMutation($input: AddJobSubComponentInput!) {
    addJobSubComponent(input: $input) {
      jobEdge {
        ...JobsTable_jobEdges
        node {
          id
          importJobNumber
          jobNumber
          name
          status
          subComponents(first: null) {
            edges {
              usageQty
              node {
                id
                itemNumber
                name
                upc
                partNumber
                description
                category {
                  id
                  name
                }
                quantityUOM {
                  id
                  name
                  type
                  symbol
                }
                weightUOM {
                  id
                  name
                  type
                  symbol
                }
                dimensionUOM {
                  id
                  name
                  type
                  symbol
                }
                formFieldValues
                dimensionLength
                dimensionWidth
                dimensionHeight
                weight
              }
            }
          }
          user {
            id
            firstName
            lastName
          }
          order {
            id
            promisedAt
            orderNumber
            status
            customer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    const { jobId } = options.variables.input;

    return createRelayMutation(mutation, {
      ...options,
      updater: store => {
        // Traverse the mutation response until we find the updated job subComponents edge
        const updatedJobSubComponentEdges = store
          .getRootField('addJobSubComponent')
          .getLinkedRecord('jobEdge')
          .getLinkedRecord('node')
          .getLinkedRecord('subComponents')
          .getLinkedRecords('edges');

        // Retrieve the root job query
        const root = store.getRoot().getLinkedRecord('job', {
          id: jobId,
        });

        // Retrieve the root job query's subComponents array
        const conn = ConnectionHandler.getConnection(
          root,
          'JobSubComponents_subComponents',
        );

        conn.setLinkedRecords(updatedJobSubComponentEdges, 'edges');
      },
    });
  },
};

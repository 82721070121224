/**
 * @flow
 * @relayHash c3f00f0fe4b37f796a593ee0d30a33f9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type IntegrationOriginRule = "SOURCE_TO_WC" | "TWO_WAY" | "WC_TO_SOURCE";
export type UpdateIntegrationEventInput = {|
  id: string,
  eventTypeId?: ?string,
  originRule?: ?IntegrationOriginRule,
  lambdaId?: ?string,
  lambdaOnly?: ?boolean,
|};
export type UpdateIntegrationEventMutationVariables = {|
  input: UpdateIntegrationEventInput
|};
export type UpdateIntegrationEventMutationResponse = {|
  +updateIntegrationEvent: {|
    +integrationEventEdge: {|
      +node: {|
        +id: string,
        +originRule: IntegrationOriginRule,
        +lambdaOnly: boolean,
        +lambdaId: ?string,
        +eventType: {|
          +id: string,
          +name: string,
          +cosmeticName: string,
        |},
      |}
    |}
  |}
|};
export type UpdateIntegrationEventMutation = {|
  variables: UpdateIntegrationEventMutationVariables,
  response: UpdateIntegrationEventMutationResponse,
|};
*/


/*
mutation UpdateIntegrationEventMutation(
  $input: UpdateIntegrationEventInput!
) {
  updateIntegrationEvent(input: $input) {
    integrationEventEdge {
      node {
        id
        originRule
        lambdaOnly
        lambdaId
        eventType {
          id
          name
          cosmeticName
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateIntegrationEventInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateIntegrationEvent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateIntegrationEventPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "integrationEventEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "IntegrationEventEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "IntegrationEvent",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "originRule",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lambdaOnly",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lambdaId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "eventType",
                "storageKey": null,
                "args": null,
                "concreteType": "EventType",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cosmeticName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateIntegrationEventMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateIntegrationEventMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateIntegrationEventMutation",
    "id": null,
    "text": "mutation UpdateIntegrationEventMutation(\n  $input: UpdateIntegrationEventInput!\n) {\n  updateIntegrationEvent(input: $input) {\n    integrationEventEdge {\n      node {\n        id\n        originRule\n        lambdaOnly\n        lambdaId\n        eventType {\n          id\n          name\n          cosmeticName\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '29f39f63ba742bc181bda11f5599666f';
module.exports = node;

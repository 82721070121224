// @flow

import React, { PureComponent } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import relayEnvironment from 'shared/gql/relayEnvironment';
import SortableTree, {
  toggleExpandedForAll,
  getVisibleNodeCount,
} from 'react-sortable-tree';
import ReactTooltip from 'react-tooltip';
import isEqual from 'lodash.isequal';
import { createFragmentContainer, graphql, fetchQuery } from 'react-relay';
import { i18n, createTooltip, Analytics } from 'shared/utils';
import { Link } from 'react-router-dom';
import * as Actions from 'main-app/store/Actions';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import {
  Branch,
  Header,
  Column,
  ItemNumber,
  ButtonWrapper,
  TooltipTitle,
  TooltipContent,
} from 'shared/components/common/BOMTree';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import DeleteItemChildMutation from 'main-app/mutations/DeleteItemChild';
import AddUpdateItemChildModal from './AddUpdateItemChildModal';
import type { ItemSubComponentsTree_itemChildEdges as ItemChildEdgesFragment } from './__generated__/ItemSubComponentsTree_itemChildEdges';

type Props = {
  itemChildEdges: ItemChildEdgesFragment,
  parentItemId: string,
};

type State = {
  addModalOpen: boolean,
  childItem: ?Object,
  treeData: ?Array<Object>,
  toggle: ?boolean,
};

const ItemSubComponentsTreeQuery = graphql`
  query ItemSubComponentsTreeQuery($id: ID!) {
    item(id: $id) {
      id
      name
      itemNumber
      partNumber
      upc
      quantityUOM {
        id
        symbol
      }
      category {
        id
        name
      }
      childItems(first: null) @connection(key: "Item_childItems", filters: []) {
        edges {
          usageQty
          node {
            id
            itemNumber
            name
            partNumber
            upc
            quantityUOM {
              id
              symbol
            }
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`;

class ItemSubComponentsTree extends PureComponent<Props, State> {
  menuTriggerRefs = {};
  state = {
    addModalOpen: false,
    childItem: null,
    treeData: [],
    toggle: true,
  };

  componentDidMount() {
    this.formatTreeData();
  }

  componentDidUpdate(prevProps) {
    const { itemChildEdges } = this.props;
    const isSame = isEqual(prevProps.itemChildEdges, itemChildEdges);

    if (prevProps.itemChildEdges && itemChildEdges && !isSame) {
      this.formatTreeData();
    }
  }

  formatTreeData = async () => {
    const { itemChildEdges } = this.props;
    const componentTreeData = await this.getBranches([...itemChildEdges], true);
    const treeData = [...componentTreeData];

    this.setState({
      treeData,
      toggle: true,
    });
  };

  getBranchContent = (item: Object, root: ?boolean = false) => {
    if (!item) return null;

    const { id, itemNumber, name, quantityUOM, usageQty } = item;

    return (
      <Branch>
        <ItemNumber>
          <Link to={`/item/${id}`}>{itemNumber}</Link>
        </ItemNumber>
        <Column>
          <Header>{i18n.t('Item Component')}</Header>
          {i18n.t(name || 'No Name')}
        </Column>

        <Column>
          <Header>{i18n.t('Est. Usage')}</Header>
          {i18n.t(`${usageQty.toLocaleString()} ${quantityUOM?.symbol || ''}`)}
        </Column>
      </Branch>
    );
  };

  getBranches = async (data: Array<Object>, root: ?boolean = false) => {
    if (!data) return [];
    const branches = await Promise.all(
      data.map(async branch => {
        const node = branch.node.item || branch.node;
        const item = { ...node };
        item.usageQty = branch.usageQty || 0;

        const content = this.getBranchContent(item, root);
        const record = {
          itemId: item.id,
          title: content,
          children: [],
          tooltip: item,
        };

        // if the item doesn't have childItems as a property, fetch more details with the item Id, and use the resulting item in the next map
        const fetchedItem = !item.childItems
          ? await this.asyncFetchItem(item.id)
          : item;

        const normalizedItem = fetchedItem.item || fetchedItem;
        const updatedItem = { ...normalizedItem };
        updatedItem.usageQty = item.usageQty;

        if (updatedItem?.childItems.edges.length) {
          const children = await this.getNestedChildren(updatedItem);

          return {
            ...record,
            children,
          };
        }
        return record;
      }),
    );

    return branches;
  };

  getNestedChildren = async (updatedItem: Object) => {
    const children = await Promise.all(
      updatedItem.childItems.edges.map(async edge => {
        const { item } = await this.asyncFetchItem(edge.node.id);

        const fetchedItem = {
          ...item,
          usageQty: edge.usageQty || 0,
        };

        const fetchedItemContent = this.getBranchContent(fetchedItem);

        const fetchedItemChildren = await this.getBranches(
          fetchedItem.childItems.edges,
          false,
        );
        return {
          itemId: fetchedItem.id,
          title: fetchedItemContent,
          children: fetchedItemChildren,
          tooltip: fetchedItem,
        };
      }),
    );

    return children;
  };

  asyncFetchItem = async (itemId: string) => {
    return await fetchQuery(relayEnvironment, ItemSubComponentsTreeQuery, {
      id: itemId,
    });
  };

  getItemById = (itemId: string) => {
    const { itemChildEdges } = this.props;

    const item = itemChildEdges.find(edge => {
      return edge?.node?.id === itemId;
    });

    return (
      {
        usageQty: item?.usageQty,
        ...item?.node,
      } || null
    );
  };

  handleOpenAddUpdateModal = (e, data, target) => {
    if (!data) {
      this.setState({
        childItem: null,
        addModalOpen: true,
      });
      return;
    }

    const itemId = target.getAttribute('item-id');
    const childItem = this.getItemById(itemId);

    this.setState({
      childItem,
      addModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      childItem: null,
      addModalOpen: false,
    });
  };

  generateChildrenTooltips = (data: Array<Object>, result = []) => {
    const tooltips = data.flatMap(node => {
      const isExistingTooltip = result.some(
        record => record.props.id === node.itemId,
      );
      if (!isExistingTooltip) {
        const content = (
          <div>
            <TooltipTitle>{i18n.t('Item Name')}</TooltipTitle>
            <TooltipContent>{node.tooltip.name || ''}</TooltipContent>
            <TooltipTitle>{i18n.t('Part #')}</TooltipTitle>
            <TooltipContent>{node.tooltip.partNumber || ''}</TooltipContent>
            <TooltipTitle>{i18n.t('UPC')}</TooltipTitle>
            <TooltipContent>{node.tooltip.upc || ''}</TooltipContent>
            <TooltipTitle>{i18n.t('Category')}</TooltipTitle>
            <TooltipContent>
              {node.tooltip?.category?.name || ''}
            </TooltipContent>
          </div>
        );

        const tooltip = createTooltip({ id: node.itemId, content });
        result.push(tooltip);
      }
      if (node.children.length) {
        return this.generateChildrenTooltips(node.children, result);
      }
      return result;
    });

    return Array.from(new Set(tooltips));
  };

  getAllTooltips = (treeData: ?Array<Object>) =>
    !treeData || !treeData.length
      ? null
      : this.generateChildrenTooltips(treeData);

  toggleNodeExpansion = expanded => {
    this.setState(prevState => ({
      treeData: toggleExpandedForAll({
        treeData: prevState.treeData,
        expanded,
      }),
      toggle: !expanded,
    }));
  };

  handleUnlink = async (e, data, target) => {
    const { parentItemId } = this.props;
    const itemId = target.getAttribute('item-id');

    try {
      await DeleteItemChildMutation.commit({
        variables: {
          input: {
            parentItemId,
            childItemId: itemId,
          },
        },
      });

      Analytics.trackEvent('Unlink Component');
      Actions.alertNotification(
        i18n.t('Item Successfully Unlinked'),
        'success',
      );
    } catch (e) {
      Actions.alertNotification(e.message, 'error');
    }
  };

  render() {
    const { itemChildEdges, parentItemId } = this.props;
    const { childItem, addModalOpen, treeData, toggle } = this.state;
    const tooltips = this.getAllTooltips(treeData);

    return (
      <>
        <div style={{ paddingBottom: 16 }}>
          <Button onClick={this.handleOpenAddUpdateModal} width="auto">
            {i18n.t('Add Components')}
          </Button>
        </div>
        {!treeData || !treeData.length ? (
          <EmptyListResults
            graphic="items"
            message={i18n.t(
              'Components associated to inventory can be created and managed here. Operators will have access to component information.',
            )}
          />
        ) : (
          <>
            <Button
              theme="border-white"
              width="auto"
              onClick={() => this.toggleNodeExpansion(toggle)}
            >
              {i18n.t(`${toggle ? 'Expand' : 'Collapse'} all`)}
            </Button>
            <SortableTree
              treeData={treeData}
              onChange={treeData => {
                const treeDataLen = treeData.length;
                const visibleNodeCount = getVisibleNodeCount({ treeData });

                this.setState({
                  treeData,
                  toggle: treeDataLen === visibleNodeCount ? true : false,
                });
              }}
              canDrag={false}
              style={{ minHeight: '750px' }}
              rowHeight={74}
              generateNodeProps={row => {
                const tooltipIcon = (
                  <div
                    data-for={row.node.itemId}
                    data-tip
                    key={row.node.itemId}
                  >
                    <Icon
                      type="circle-question"
                      size={16}
                      style={{ marginRight: '24px', marginTop: '4px' }}
                    />
                  </div>
                );

                return {
                  buttons: !row.parentNode
                    ? [
                        <ButtonWrapper>
                          {tooltipIcon}
                          <ContextMenuTrigger
                            ref={r =>
                              (this.menuTriggerRefs[`${row.node.itemId}`] = r)
                            }
                            id="components-menu"
                            attributes={{
                              'item-id': row.node.itemId,
                            }}
                          >
                            <Icon
                              type="circle-context-menu"
                              size={24}
                              onClick={(e, data, target) => {
                                if (
                                  this.menuTriggerRefs[`${row.node.itemId}`]
                                ) {
                                  this.menuTriggerRefs[
                                    `${row.node.itemId}`
                                  ].handleContextClick(e, data, target);
                                }
                              }}
                            />
                          </ContextMenuTrigger>
                        </ButtonWrapper>,
                      ]
                    : [tooltipIcon],
                };
              }}
              reactVirtualizedListProps={{
                onRowsRendered: () => ReactTooltip.rebuild(),
              }}
            />
            <ContextMenu id="components-menu">
              <MenuItem onClick={this.handleOpenAddUpdateModal}>
                {i18n.t('Edit Estimated Usage')}
              </MenuItem>
              <MenuItem onClick={this.handleUnlink}>
                {i18n.t('Unlink Item')}
              </MenuItem>
            </ContextMenu>

            {tooltips}
          </>
        )}
        {addModalOpen && (
          <AddUpdateItemChildModal
            onClose={this.handleCloseAllModals}
            parentItemId={parentItemId}
            childItem={childItem}
            excludeIds={itemChildEdges.map(edge => edge?.node.id)}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(ItemSubComponentsTree, {
  itemChildEdges: graphql`
    fragment ItemSubComponentsTree_itemChildEdges on ItemChildEdge
      @relay(plural: true) {
      usageQty
      node {
        id
        itemNumber
        name
        partNumber
        upc
        quantityUOM {
          id
          symbol
        }
        category {
          id
          name
        }
      }
    }
  `,
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateItemModal_defaultForm$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderDetails_defaultForm$ref: FragmentReference;
declare export opaque type OrderDetails_defaultForm$fragmentType: OrderDetails_defaultForm$ref;
export type OrderDetails_defaultForm = {|
  +id: string,
  +$fragmentRefs: AddUpdateItemModal_defaultForm$ref,
  +$refType: OrderDetails_defaultForm$ref,
|};
export type OrderDetails_defaultForm$data = OrderDetails_defaultForm;
export type OrderDetails_defaultForm$key = {
  +$data?: OrderDetails_defaultForm$data,
  +$fragmentRefs: OrderDetails_defaultForm$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderDetails_defaultForm",
  "type": "Form",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AddUpdateItemModal_defaultForm",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7ee756c720088acf431d4c2f19fbbe82';
module.exports = node;

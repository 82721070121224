/**
 * @flow
 * @relayHash 81d55b96a489bb6e6700cd215b22c7f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportQueryVariables = {|
  id: string,
  startAt: any,
  endAt: any,
|};
export type ReportQueryResponse = {|
  +report: {|
    +id: string,
    +name: string,
    +description: ?string,
    +compiled: any,
  |}
|};
export type ReportQuery = {|
  variables: ReportQueryVariables,
  response: ReportQueryResponse,
|};
*/


/*
query ReportQuery(
  $id: ID!
  $startAt: DateTime!
  $endAt: DateTime!
) {
  report(id: $id) {
    id
    name
    description
    compiled(startAt: $startAt, endAt: $endAt)
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "endAt",
    "type": "DateTime!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "report",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Report",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "compiled",
        "args": [
          {
            "kind": "Variable",
            "name": "endAt",
            "variableName": "endAt"
          },
          {
            "kind": "Variable",
            "name": "startAt",
            "variableName": "startAt"
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReportQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReportQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "ReportQuery",
    "id": null,
    "text": "query ReportQuery(\n  $id: ID!\n  $startAt: DateTime!\n  $endAt: DateTime!\n) {\n  report(id: $id) {\n    id\n    name\n    description\n    compiled(startAt: $startAt, endAt: $endAt)\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4025fa058137f57fcb46985c5f2416c';
module.exports = node;

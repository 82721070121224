// @flow

import type { FilterOption } from 'main-app/components/FilterControls';
import { i18n } from 'shared/utils';

export default (): Array<FilterOption> => [
  {
    label: i18n.t('Job #'),
    name: 'jobNumbers',
    type: 'text',
    multi: true,
  },
  {
    label: i18n.t('Customer'),
    name: 'customerIds',
    type: 'customer',
    multi: true,
  },
  {
    label: i18n.t('Project Name'),
    name: 'name',
    type: 'text',
  },
  {
    label: i18n.t('Owner'),
    name: 'userIds',
    type: 'user',
    multi: true,
  },
  {
    label: i18n.t('Item Name'),
    name: 'itemName',
    type: 'text',
  },
  {
    label: i18n.t('Part #'),
    name: 'itemPartNumber',
    type: 'text',
  },
];

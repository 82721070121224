/**
 * @flow
 * @relayHash 28017d0e0e9b8d32565a703e3ffea8d2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateMachineInput = {|
  id: string,
  workflowIds?: ?$ReadOnlyArray<string>,
  name?: ?string,
  machineTypeId?: ?string,
  purchaseCost?: ?number,
  lifespanYears?: ?number,
  runQuantityPerHour?: ?number,
  runCostPerHour?: ?number,
  productionOrder?: ?number,
|};
export type UpdateMachineMutationVariables = {|
  input: UpdateMachineInput
|};
export type UpdateMachineMutationResponse = {|
  +updateMachine: {|
    +machineEdge: {|
      +cursor: string,
      +node: {|
        +id: string,
        +name: string,
        +type: {|
          +id: string,
          +name: string,
        |},
        +purchaseCost: ?number,
        +lifespanYears: ?number,
        +runQuantityPerHour: ?number,
        +runCostPerHour: ?number,
        +updatedAt: any,
        +createdAt: any,
        +workflows: $ReadOnlyArray<{|
          +id: string,
          +name: string,
        |}>,
      |},
    |}
  |}
|};
export type UpdateMachineMutation = {|
  variables: UpdateMachineMutationVariables,
  response: UpdateMachineMutationResponse,
|};
*/


/*
mutation UpdateMachineMutation(
  $input: UpdateMachineInput!
) {
  updateMachine(input: $input) {
    machineEdge {
      cursor
      node {
        id
        name
        type {
          id
          name
        }
        purchaseCost
        lifespanYears
        runQuantityPerHour
        runCostPerHour
        updatedAt
        createdAt
        workflows {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateMachineInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMachine",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMachinePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "machineEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "MachineEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Machine",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "type",
                "storageKey": null,
                "args": null,
                "concreteType": "MachineType",
                "plural": false,
                "selections": (v3/*: any*/)
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "purchaseCost",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lifespanYears",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "runQuantityPerHour",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "runCostPerHour",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "workflows",
                "storageKey": null,
                "args": null,
                "concreteType": "Workflow",
                "plural": true,
                "selections": (v3/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateMachineMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateMachineMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateMachineMutation",
    "id": null,
    "text": "mutation UpdateMachineMutation(\n  $input: UpdateMachineInput!\n) {\n  updateMachine(input: $input) {\n    machineEdge {\n      cursor\n      node {\n        id\n        name\n        type {\n          id\n          name\n        }\n        purchaseCost\n        lifespanYears\n        runQuantityPerHour\n        runCostPerHour\n        updatedAt\n        createdAt\n        workflows {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03ee7e46e7dac84a56f1c8d46c3dfe10';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateRunModal_run$ref: FragmentReference;
declare export opaque type AddUpdateRunModal_run$fragmentType: AddUpdateRunModal_run$ref;
export type AddUpdateRunModal_run = {|
  +id: string,
  +scheduledStartAt: any,
  +notes: ?string,
  +machine: {|
    +id: string,
    +name: string,
    +type: {|
      +id: string
    |},
  |},
  +jobState: {|
    +id: string
  |},
  +yields: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +item: {|
          +id: string,
          +name: string,
        |},
      |}
    |}>
  |},
  +$refType: AddUpdateRunModal_run$ref,
|};
export type AddUpdateRunModal_run$data = AddUpdateRunModal_run;
export type AddUpdateRunModal_run$key = {
  +$data?: AddUpdateRunModal_run$data,
  +$fragmentRefs: AddUpdateRunModal_run$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "AddUpdateRunModal_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "scheduledStartAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "machine",
      "storageKey": null,
      "args": null,
      "concreteType": "Machine",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "type",
          "storageKey": null,
          "args": null,
          "concreteType": "MachineType",
          "plural": false,
          "selections": (v2/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "jobState",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "yields",
      "storageKey": null,
      "args": null,
      "concreteType": "RunYieldConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RunYieldEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "JobYield",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "item",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a95499eac5051cd496225c25e5276487';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeleteJobLinkedItemModal_item$ref: FragmentReference;
declare export opaque type DeleteJobLinkedItemModal_item$fragmentType: DeleteJobLinkedItemModal_item$ref;
export type DeleteJobLinkedItemModal_item = {|
  +id: string,
  +name: string,
  +$refType: DeleteJobLinkedItemModal_item$ref,
|};
export type DeleteJobLinkedItemModal_item$data = DeleteJobLinkedItemModal_item;
export type DeleteJobLinkedItemModal_item$key = {
  +$data?: DeleteJobLinkedItemModal_item$data,
  +$fragmentRefs: DeleteJobLinkedItemModal_item$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DeleteJobLinkedItemModal_item",
  "type": "Item",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '15fa697f70cb9269115a543de56fa808';
module.exports = node;

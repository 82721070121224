/**
 * @flow
 * @relayHash 6162dfd00625b39eb3df0a1ffc4540b8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type JobStatus = "CLOSED" | "OPEN";
export type UpdateJobStatusInput = {|
  id: string,
  status: JobStatus,
  jobYieldQuantities?: ?$ReadOnlyArray<JobYieldQuantityUpdate>,
  lotId?: ?string,
  notes?: ?string,
|};
export type JobYieldQuantityUpdate = {|
  jobYieldId: string,
  itemId: string,
  finalQuantity: number,
  lotTracking?: ?boolean,
|};
export type UpdateJobStatusMutationVariables = {|
  input: UpdateJobStatusInput
|};
export type UpdateJobStatusMutationResponse = {|
  +updateJobStatus: {|
    +jobEdge: {|
      +node: {|
        +id: string,
        +jobNumber: string,
        +name: string,
        +status: JobStatus,
        +yields: {|
          +totalCount: number,
          +edges: ?$ReadOnlyArray<?{|
            +node: {|
              +id: string,
              +finalQuantity: number,
            |}
          |}>,
        |},
        +user: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
        |},
        +order: ?{|
          +id: string,
          +promisedAt: ?any,
          +customer: {|
            +id: string,
            +name: string,
          |},
        |},
        +workflow: {|
          +id: string,
          +name: string,
        |},
        +states: $ReadOnlyArray<{|
          +id: string,
          +formFieldValues: any,
          +status: JobStateStatus,
          +isEnabled: boolean,
          +workflowState: {|
            +id: string,
            +name: string,
            +form: ?{|
              +id: string
            |},
          |},
        |}>,
      |}
    |}
  |}
|};
export type UpdateJobStatusMutation = {|
  variables: UpdateJobStatusMutationVariables,
  response: UpdateJobStatusMutationResponse,
|};
*/


/*
mutation UpdateJobStatusMutation(
  $input: UpdateJobStatusInput!
) {
  updateJobStatus(input: $input) {
    jobEdge {
      node {
        id
        jobNumber
        name
        status
        yields {
          totalCount
          edges {
            node {
              id
              finalQuantity
            }
          }
        }
        user {
          id
          firstName
          lastName
        }
        order {
          id
          promisedAt
          customer {
            id
            name
          }
        }
        workflow {
          id
          name
        }
        states {
          id
          formFieldValues
          status
          isEnabled
          workflowState {
            id
            name
            form {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateJobStatusInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateJobStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateJobStatusPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "JobEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Job",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "jobNumber",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "yields",
                "storageKey": null,
                "args": null,
                "concreteType": "JobYieldConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalCount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobYieldEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobYield",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "finalQuantity",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "order",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "promisedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Customer",
                    "plural": false,
                    "selections": (v4/*: any*/)
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "workflow",
                "storageKey": null,
                "args": null,
                "concreteType": "Workflow",
                "plural": false,
                "selections": (v4/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "states",
                "storageKey": null,
                "args": null,
                "concreteType": "JobState",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "formFieldValues",
                    "args": null,
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isEnabled",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "workflowState",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "WorkflowState",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "form",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Form",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateJobStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateJobStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v5/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateJobStatusMutation",
    "id": null,
    "text": "mutation UpdateJobStatusMutation(\n  $input: UpdateJobStatusInput!\n) {\n  updateJobStatus(input: $input) {\n    jobEdge {\n      node {\n        id\n        jobNumber\n        name\n        status\n        yields {\n          totalCount\n          edges {\n            node {\n              id\n              finalQuantity\n            }\n          }\n        }\n        user {\n          id\n          firstName\n          lastName\n        }\n        order {\n          id\n          promisedAt\n          customer {\n            id\n            name\n          }\n        }\n        workflow {\n          id\n          name\n        }\n        states {\n          id\n          formFieldValues\n          status\n          isEnabled\n          workflowState {\n            id\n            name\n            form {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e8f283f30f9c7a3c34a03bcd6f36945';
module.exports = node;

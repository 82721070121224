/**
 * @flow
 * @relayHash 1668fba966335c9b6e17bb0e1258c5a2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddUpdateOrderModal_customer$ref = any;
type CustomerDetails_customer$ref = any;
type UserAvatar_user$ref = any;
export type AddCustomerInput = {|
  name: string,
  primaryContactName?: ?string,
  secondaryContactName?: ?string,
  shippingAddressLine1?: ?string,
  shippingAddressLine2?: ?string,
  shippingAddressLine3?: ?string,
  shippingAddressCity?: ?string,
  shippingAddressState?: ?string,
  shippingAddressZip?: ?string,
  billingAddressLine1?: ?string,
  billingAddressLine2?: ?string,
  billingAddressLine3?: ?string,
  billingAddressCity?: ?string,
  billingAddressState?: ?string,
  billingAddressZip?: ?string,
  email?: ?any,
  phone?: ?string,
  phone2?: ?string,
  fax?: ?string,
  salesUserId?: ?string,
  notes?: ?string,
|};
export type AddCustomerMutationVariables = {|
  input: AddCustomerInput
|};
export type AddCustomerMutationResponse = {|
  +addCustomer: {|
    +customerEdge: {|
      +node: {|
        +id: string,
        +customerNumber: string,
        +name: string,
        +primaryContactName: ?string,
        +secondaryContactName: ?string,
        +email: ?any,
        +phone: ?string,
        +openOrders: number,
        +salesUser: ?{|
          +$fragmentRefs: UserAvatar_user$ref
        |},
        +$fragmentRefs: CustomerDetails_customer$ref & AddUpdateOrderModal_customer$ref,
      |}
    |}
  |}
|};
export type AddCustomerMutation = {|
  variables: AddCustomerMutationVariables,
  response: AddCustomerMutationResponse,
|};
*/


/*
mutation AddCustomerMutation(
  $input: AddCustomerInput!
) {
  addCustomer(input: $input) {
    customerEdge {
      node {
        id
        customerNumber
        name
        primaryContactName
        secondaryContactName
        email
        phone
        openOrders
        salesUser {
          ...UserAvatar_user
          id
        }
        ...CustomerDetails_customer
        ...AddUpdateOrderModal_customer
      }
    }
  }
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}

fragment CustomerDetails_customer on Customer {
  id
  customerNumber
  shippingAddressLine1
  shippingAddressLine2
  shippingAddressLine3
  shippingAddressCity
  shippingAddressState
  shippingAddressZip
  billingAddressLine1
  billingAddressLine2
  billingAddressLine3
  billingAddressCity
  billingAddressState
  billingAddressZip
  email
  phone
  phone2
  fax
  notes
  salesUser {
    id
    ...UserWidget_user
  }
}

fragment AddUpdateOrderModal_customer on Customer {
  id
  shippingAddressLine1
  shippingAddressLine2
  shippingAddressLine3
  shippingAddressCity
  shippingAddressState
  shippingAddressZip
}

fragment UserWidget_user on User {
  id
  firstName
  lastName
  ...UserAvatar_user
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddCustomerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "customerNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "primaryContactName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "secondaryContactName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "openOrders",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddCustomerMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addCustomer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddCustomerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customerEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Customer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "salesUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "UserAvatar_user",
                        "args": null
                      }
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "CustomerDetails_customer",
                    "args": null
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "AddUpdateOrderModal_customer",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddCustomerMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addCustomer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddCustomerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customerEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Customer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "salesUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine1",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine2",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine3",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressCity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressState",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressZip",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressLine1",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressLine2",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressLine3",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressCity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressState",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressZip",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "phone2",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fax",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddCustomerMutation",
    "id": null,
    "text": "mutation AddCustomerMutation(\n  $input: AddCustomerInput!\n) {\n  addCustomer(input: $input) {\n    customerEdge {\n      node {\n        id\n        customerNumber\n        name\n        primaryContactName\n        secondaryContactName\n        email\n        phone\n        openOrders\n        salesUser {\n          ...UserAvatar_user\n          id\n        }\n        ...CustomerDetails_customer\n        ...AddUpdateOrderModal_customer\n      }\n    }\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n\nfragment CustomerDetails_customer on Customer {\n  id\n  customerNumber\n  shippingAddressLine1\n  shippingAddressLine2\n  shippingAddressLine3\n  shippingAddressCity\n  shippingAddressState\n  shippingAddressZip\n  billingAddressLine1\n  billingAddressLine2\n  billingAddressLine3\n  billingAddressCity\n  billingAddressState\n  billingAddressZip\n  email\n  phone\n  phone2\n  fax\n  notes\n  salesUser {\n    id\n    ...UserWidget_user\n  }\n}\n\nfragment AddUpdateOrderModal_customer on Customer {\n  id\n  shippingAddressLine1\n  shippingAddressLine2\n  shippingAddressLine3\n  shippingAddressCity\n  shippingAddressState\n  shippingAddressZip\n}\n\nfragment UserWidget_user on User {\n  id\n  firstName\n  lastName\n  ...UserAvatar_user\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '673e5f3839bddf9965ccab5f2f709b3b';
module.exports = node;

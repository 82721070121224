// @flow

import React from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { i18n } from 'shared/utils';
import Icon from 'shared/components/common/Icon';
import {
  FilterSummary,
  FilterSummaryLabel,
  SavedFilterSelection,
} from './styled';
import type { SavedFilter } from '.';

type Props = {
  savedFilterId?: ?string,
  onSelectSavedFilter: SavedFilter => *,
  onDeleteSavedFilter: SavedFilter => *,
  savedFilters: Array<SavedFilter>,
};

let menuTriggerRef = null;

const SavedFilterSelector = ({
  savedFilterId,
  onSelectSavedFilter,
  onDeleteSavedFilter,
  savedFilters,
}: Props) => {
  const selectedSavedFilter = savedFilterId
    ? savedFilters.find(a => a.id === savedFilterId)
    : null;

  return (
    <FilterSummary>
      <FilterSummaryLabel>{i18n.t('Saved Filters:')}</FilterSummaryLabel>
      <ContextMenuTrigger
        id="saved-filter-selector-menu"
        ref={r => (menuTriggerRef = r)}
      >
        <span
          onClick={e => {
            if (menuTriggerRef) {
              menuTriggerRef.handleContextClick(e);
            }
          }}
        >
          {selectedSavedFilter ? selectedSavedFilter.name : i18n.t('None')}
        </span>
      </ContextMenuTrigger>
      {Boolean(savedFilters.length) && (
        <ContextMenu id="saved-filter-selector-menu">
          {savedFilters.map(savedFilter => (
            <MenuItem
              key={savedFilter.name + savedFilter.id}
              data={savedFilter}
              onClick={(_, s) => onSelectSavedFilter(s)}
              selected={savedFilter.id === savedFilterId}
            >
              <SavedFilterSelection>
                {savedFilter.name}
                <Icon
                  type="circle-close"
                  size={24}
                  onClick={(e: Event) => {
                    e.preventDefault();
                    e.stopPropagation();

                    onDeleteSavedFilter(savedFilter);
                  }}
                />
              </SavedFilterSelection>
            </MenuItem>
          ))}
        </ContextMenu>
      )}
    </FilterSummary>
  );
};

SavedFilterSelector.defaultProps = {
  savedFilterId: null,
};

export default SavedFilterSelector;

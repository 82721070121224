// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteJobMutation from 'main-app/mutations/DeleteJob';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteJobModal_job as JobFragment } from './__generated__/DeleteJobModal_job';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  job: JobFragment,
};

type State = {
  deleting: boolean,
};

class DeleteJobModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  static defaultProps = {
    onSuccess: undefined,
  };

  handleDelete = () => {
    const { job, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteJobMutation.commit({
            variables: {
              input: {
                id: job.id,
              },
            },
            context: {
              orderId: job.order?.id,
            },
          });

          onClose();

          if (onSuccess) {
            onSuccess();
          }

          Actions.alertNotification(
            i18n.t(`Job Successfully Deleted`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { job, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete Job: {{jobName}}?', {
            jobName: job.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            'All information on this job ticket, including items and runs will be deleted permanently.',
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Job')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteJobModal, {
  job: graphql`
    fragment DeleteJobModal_job on Job {
      id
      name
      order {
        id
      }
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import {
  DiagramEngine,
  DiagramModel,
  // DiagramWidget,
} from 'storm-react-diagrams';
import { distributeElements } from 'shared/utils';
import { graphql, createFragmentContainer } from 'react-relay';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import Icon from 'shared/components/common/Icon';
import { IconNodeModel, IconNodeFactory, IconPortModel } from './IconNode';
import { CustomLinkFactory } from './CustomLink';
import SimplePortFactory from './IconNode/SimplePortFactory';
import { CustomLabelFactory } from './CustomLabel';
import TransitionDetails from './TransitionDetails';
import WorkflowPattern from './img/workflowPattern.png';
import type { WorkflowDiagram_job as JobFragment } from './__generated__/WorkflowDiagram_job';
import DiagramWidget from './DiagramWidget';

type Props = {
  job: JobFragment,
  dragEnabled?: boolean,
};

type State = {
  openDetailsTooltip: boolean,
  detailsTooltipRecordId: ?string,
};

const ICON_TYPE_MAP = {
  JOB_CREATED: 'square-job-created',
  JOB_COMPLETED: 'square-job-completed',
  TASK: 'circle-task',
  RUN: 'circle-run',
};

const WorkflowWrapper = styled.div`
  position: relative;
  display: flex;
  height: 300px;
  width: 100%;

  .srd-diagram {
    height: 100%;
    background-image: url(${WorkflowPattern});
    background-repeat: repeat;

    .srd-default-link__label {
      overflow: visible !important;
    }

    .srd-default-link {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const ZoomIconsWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
`;

class WorkflowDiagram extends PureComponent<Props, State> {
  static defaultProps = {
    dragEnabled: false,
  };
  engine: DiagramEngine = new DiagramEngine();

  constructor(props: Props) {
    super(props);
    // TODO: remove this when api-service is ready
    this.state = {
      openDetailsTooltip: false,
      detailsTooltipRecordId: null,
    };
    this.getEngineProps();
  }

  componentDidMount() {
    this.zoomToFit();
  }

  componentDidUpdate() {
    // Ask Bryan if we should zoomToFit or move it back to the last position when new props received
    this.getEngineProps();
    this.zoomToFit();
  }

  handleOpenDetailsTooltip = (recordId: string) => {
    this.setState({
      openDetailsTooltip: true,
      detailsTooltipRecordId: recordId,
    });
  };

  handleCloseDetailsTooltip = () => {
    this.setState({
      openDetailsTooltip: false,
      detailsTooltipRecordId: null,
    });
  };

  getEngineProps = () => {
    const {
      job: {
        states,
        workflow: { transitions },
      },
    } = this.props;
    const { dragEnabled } = this.props;

    this.engine.installDefaultFactories();
    this.engine.registerLinkFactory(
      new CustomLinkFactory(
        this.handleOpenDetailsTooltip,
        this.handleCloseDetailsTooltip,
      ),
    );
    this.engine.registerLabelFactory(new CustomLabelFactory());
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'square-job-created',
        config => new IconPortModel('right', 'square-job-created'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'square-job-completed',
        config => new IconPortModel('left', 'square-job-completed'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'circle-run',
        config => new IconPortModel('left', 'circle-run'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'circle-run',
        config => new IconPortModel('right', 'circle-run'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'circle-task',
        config => new IconPortModel('left', 'circle-task'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'circle-task',
        config => new IconPortModel('right', 'circle-task'),
      ),
    );
    this.engine.registerNodeFactory(new IconNodeFactory('square-job-created'));
    this.engine.registerNodeFactory(
      new IconNodeFactory('square-job-completed'),
    );
    this.engine.registerNodeFactory(new IconNodeFactory('circle-run'));
    this.engine.registerNodeFactory(new IconNodeFactory('circle-task'));

    const model = new DiagramModel();
    const nodes = [];
    const links = [];
    const workflowStateIdToNodeHash = {};

    if (states.length === 0) {
      const startNode = new IconNodeModel('square-job-created');
      startNode.data.type = 'JOB_CREATED';
      startNode.data.status = 'COMPLETE';
      startNode.setPosition(250, 250);
      model.addAll(startNode);
    } else {
      for (let i = 0; i < states.length; i++) {
        const state: any = states[i];
        const node = new IconNodeModel(ICON_TYPE_MAP[state.workflowState.type]);
        node.data.saved = true;
        node.data.jobStateId = state.id;

        const JOB_STATE_KEYS = ['isEnabled', 'form', 'status', 'user'];
        const WORKFLOW_STATE_KEYS = [
          'name',
          'isRequired',
          'type',
          'runMinutesOverride',
        ];

        if (
          state.workflowState.type === 'JOB_CREATED' ||
          state.workflowState.type === 'JOB_COMPLETED'
        ) {
          node.data.type = state.workflowState.type;
          node.data.status = state.status;
        } else {
          for (const key of JOB_STATE_KEYS) {
            if (key === 'user' && state[key]) {
              node.data[key] = {
                data: {
                  id: state[key].id,
                },
                value: state[key].id,
                label: state[key].firstName + ' ' + state[key].lastName,
              };
            } else if (key === 'form' && state.workflowState[key]) {
              node.data[key] = {
                data: {
                  id: state.workflowState[key].id,
                },
                value: state.workflowState[key].id,
                label: state.workflowState[key].name,
              };
            } else {
              node.data[key] = state[key];
            }
          }

          for (const key of WORKFLOW_STATE_KEYS) {
            node.data[key] = state.workflowState[key];
          }
        }

        workflowStateIdToNodeHash[state.workflowState.id] = node;
        nodes.push(node);
      }

      for (const transition of transitions) {
        const fromNode =
          workflowStateIdToNodeHash[transition.fromWorkflowState.id];
        const toNode = workflowStateIdToNodeHash[transition.toWorkflowState.id];
        fromNode.childNodeIds.push(toNode.id);
        toNode.parentNodeIds.push(fromNode.id);

        // connecting nodes from right to left will cause issues
        // need to set toNode and fromNode properly when link is created, on
        const link = fromNode.ports.right.link(toNode.ports.left);
        link.data.workflowTransitionId = transition.id;

        // TODO: uncomment when Transition Actions are added
        // for (const action of transition.actions) {
        //   if (action.type === 'SEND_EMAIL') {
        //     link.data.defaultUser = {
        //       value: action.metadata.defaultUserId,
        //     };
        //   } else if (action.type === 'ADD_JOB_TAG') {
        //     link.data.addTags.push({
        //       value: action.metadata.tagId,
        //     });
        //   } else if (action.type === 'REMOVE_JOB_TAG') {
        //     link.data.removeTags.push({
        //       value: action.metadata.tagId,
        //     });
        //   }
        // }

        // const totalActions =
        //   link.data.addTags.length +
        //   link.data.removeTags.length +
        //   (link.data.defaultUser?.value ? 1 : 0);
        // link.addLabel(totalActions || '+');
        // link.setWidth(3);

        if (fromNode.data.status === 'COMPLETE') {
          link.setColor(colors.charcoalGrey);
          link.data.complete = true;
        } else {
          link.setColor(colors.lightBlueGrey);
        }

        links.push(link);
      }

      model.addAll(...nodes, ...links);
    }

    const distributedModel = this.getDistributedModel(this.engine, model);

    if (!dragEnabled) {
      distributedModel.setLocked(true);
    }

    this.engine.setDiagramModel(distributedModel);
  };

  getDistributedModel = (engine, model) => {
    const serialized = model.serializeDiagram();
    const distributedSerializedDiagram = distributeElements(serialized);
    const deSerializedModel = new DiagramModel();

    deSerializedModel.deSerializeDiagram(distributedSerializedDiagram, engine);
    deSerializedModel.data = {
      ...deSerializedModel.data,
      graphDimensions: distributedSerializedDiagram.graphDimensions,
    };

    return deSerializedModel;
  };

  zoomToFit = () => {
    const model = this.engine.getDiagramModel();
    model.setZoomLevel(100);
    // canvasWidth changes when the component is updated, 15px is being added somehow
    const canvasWidth = this.engine.canvas.clientWidth;
    const canvasHeight = this.engine.canvas.clientHeight;
    const graphHeight = model.data.graphDimensions.height;
    const graphWidth = model.data.graphDimensions.width;
    const xFactor = canvasWidth / graphWidth;
    const yFactor = canvasHeight / graphHeight;
    const zoomFactor = xFactor < yFactor ? xFactor : yFactor;
    const pixelScaleFactor = 1 / zoomFactor;
    const xOffset =
      graphWidth > canvasWidth && yFactor >= xFactor
        ? 0
        : ((canvasWidth * pixelScaleFactor - graphWidth) / 2) * zoomFactor;
    const yOffset =
      graphHeight > canvasHeight && xFactor >= yFactor
        ? 10
        : ((canvasHeight * pixelScaleFactor - graphHeight) / 2) * zoomFactor;

    model.setZoomLevel(model.getZoomLevel() * zoomFactor);
    model.setOffset(xOffset, yOffset);
    this.engine.repaintCanvas();
  };

  handleZoomIn = () => {
    const model = this.engine.getDiagramModel();
    model.setZoomLevel(Math.min(200, model.getZoomLevel() + 20));
    this.engine.repaintCanvas();
  };

  handleZoomOut = () => {
    const model = this.engine.getDiagramModel();
    model.setZoomLevel(Math.max(20, model.getZoomLevel() - 20));
    this.engine.repaintCanvas();
  };

  render() {
    const diagramProps = {
      diagramEngine: this.engine,
      maxNumberPointsPerLink: 0,
    };
    const { openDetailsTooltip, detailsTooltipRecordId } = this.state;

    return (
      <WorkflowWrapper>
        {openDetailsTooltip && (
          <TransitionDetails transitionId={detailsTooltipRecordId} />
        )}
        <ZoomIconsWrapper>
          <Icon
            type="circle-minus-black"
            onClick={this.handleZoomOut}
            style={{ marginRight: 20 }}
          />
          <Icon type="circle-plus-black" onClick={this.handleZoomIn} />
        </ZoomIconsWrapper>
        <DiagramWidget
          diagramEngine={this.engine}
          {...diagramProps}
          // disables delete on backspace keyUp event
          deleteKeys={[]}
        />
      </WorkflowWrapper>
    );
  }
}

export default createFragmentContainer(WorkflowDiagram, {
  job: graphql`
    fragment WorkflowDiagram_job on Job {
      id
      states {
        id
        status
        user {
          id
          firstName
          lastName
        }
        runs {
          id
          status
        }
        workflowState {
          id
          type
          name
          isRequired
          runMinutesOverride
          form {
            id
            name
          }
        }
        isEnabled
        updatedAt
        createdAt
      }
      workflow {
        id
        transitions {
          id
          toWorkflowState {
            id
          }
          fromWorkflowState {
            id
          }
        }
      }
    }
  `,
});

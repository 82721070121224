// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateItemChildMutation($input: UpdateItemChildInput!) {
    updateItemChild(input: $input) {
      itemEdge {
        node {
          id
          itemNumber
          name
          upc
          quantityUOM {
            id
            name
            type
            symbol
          }
          childItems(first: null)
            @connection(key: "Item_childItems", filters: []) {
            edges {
              usageQty
              node {
                id
                name
              }
            }
          }
          formFieldValues
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

/**
 * @flow
 * @relayHash 412e6ae57be1f82aa8f2852dd7656938
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDowntimeCategoryInput = {|
  id: string
|};
export type DeleteDowntimeCategoryMutationVariables = {|
  input: DeleteDowntimeCategoryInput
|};
export type DeleteDowntimeCategoryMutationResponse = {|
  +deleteDowntimeCategory: {|
    +deletedId: string
  |}
|};
export type DeleteDowntimeCategoryMutation = {|
  variables: DeleteDowntimeCategoryMutationVariables,
  response: DeleteDowntimeCategoryMutationResponse,
|};
*/


/*
mutation DeleteDowntimeCategoryMutation(
  $input: DeleteDowntimeCategoryInput!
) {
  deleteDowntimeCategory(input: $input) {
    deletedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteDowntimeCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteDowntimeCategory",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteDowntimeCategoryPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteDowntimeCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteDowntimeCategoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteDowntimeCategoryMutation",
    "id": null,
    "text": "mutation DeleteDowntimeCategoryMutation(\n  $input: DeleteDowntimeCategoryInput!\n) {\n  deleteDowntimeCategory(input: $input) {\n    deletedId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa4eec9c8bb094e2d00200c4bdf2ebcb';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      orderId: string,
      itemId: string,
      orderedQuantity: number,
    },
  },
};

const mutation = graphql`
  mutation AddOrderYieldMutation($input: AddOrderYieldInput!) {
    addOrderYield(input: $input) {
      orderYieldEdge {
        cursor
        node {
          id
          order {
            id
            yields {
              edges {
                node {
                  id
                }
              }
            }
          }
          orderedQuantity
          item {
            id
            itemNumber
            name
            partNumber
            description
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: options.variables.input.orderId,
          connectionInfo: [
            {
              key: 'OrderDetails_order_yields',
              rangeBehavior: 'append',
            },
          ],
          edgeName: 'orderYieldEdge',
        },
        {
          type: 'RANGE_ADD',
          parentID: options.variables.input.orderId,
          connectionInfo: [
            {
              key: 'Order_yields',
              rangeBehavior: 'append',
            },
          ],
          edgeName: 'orderYieldEdge',
        },
      ],
    });
  },
};

// @flow

import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import { i18n, Analytics } from 'shared/utils';
import AddOrderMutation from 'main-app/mutations/AddOrder';
import UpdateOrderMutation from 'main-app/mutations/UpdateOrder';
import AddCustomerMutation from 'main-app/mutations/AddCustomer';
import Divider from 'shared/components/common/Divider';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
  SelectCustomerField,
  SelectUserField,
} from 'shared/components/form';
import Button from 'shared/components/common/Button';
import type { AddUpdateOrderModal_order as OrderFragment } from './__generated__/AddUpdateOrderModal_order';
import type { AddUpdateOrderModal_customer as CustomerFragment } from './__generated__/AddUpdateOrderModal_customer';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  onReopen?: () => void,
  order: ?OrderFragment,
  createAnotherOrder?: boolean,
  customer: ?CustomerFragment,
  companyId?: ?string,
};

const LANGUAGE = {
  update: {
    title: i18n.t('Edit Order'),
    submitButton: i18n.t('Update Order'),
    successTitle: i18n.t('Order Successfully Updated'),
    successMessage: userAssigned => {
      if (userAssigned) {
        return i18n.t('The assignee has been notified.');
      }

      return null;
    },
  },
  add: {
    title: i18n.t('Create Order'),
    submitButton: i18n.t('Create Order'),
    successTitle: i18n.t('Order Successfully Created'),
    successMessage: userAssigned => {
      if (userAssigned) {
        return i18n.t(
          'The assignee has been notified. Now create a Job Ticket!',
        );
      }

      return i18n.t('Now create a Job Ticket!');
    },
  },
};

const AddUpdateOrderModal = ({
  onSuccess,
  onClose,
  onReopen,
  order,
  createAnotherOrder,
  customer,
  companyId,
}: Props) => {
  const language = LANGUAGE[order ? 'update' : 'add'];

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          customer:
            (order?.customer && {
              value: order?.customer.id,
              data: order?.customer,
            }) ||
            (customer && {
              value: customer.id,
              data: customer,
            }),
          createAnotherOrder,
          customerOrderNumber: order?.customerOrderNumber || '',
          user: order?.user
            ? {
                value: order?.user?.id,
                data: order?.user,
              }
            : // FIXME: this is strictly here for UPBX since user is a required field
            companyId === 'e4e8b3c2-b4f3-47cd-a6ec-38c69c1ae86d'
            ? {
                value: 'ef08a502-d56c-4d0b-b62c-17de2326f864',
              }
            : undefined,
          shippingAddressLine1:
            order?.shippingAddressLine1 || customer?.shippingAddressLine1 || '',
          shippingAddressLine2:
            order?.shippingAddressLine2 || customer?.shippingAddressLine2 || '',
          shippingAddressLine3:
            order?.shippingAddressLine3 || customer?.shippingAddressLine3 || '',
          shippingAddressCity:
            order?.shippingAddressCity || customer?.shippingAddressCity || '',
          shippingAddressState:
            order?.shippingAddressState || customer?.shippingAddressState || '',
          shippingAddressZip:
            order?.shippingAddressZip || customer?.shippingAddressZip || '',
          notes: order?.notes || '',
          orderedAt: order?.orderedAt ? moment(order?.orderedAt) : moment(),
          requiredAt: order?.requiredAt ? moment(order?.requiredAt) : null,
          promisedAt: order?.promisedAt ? moment(order?.promisedAt) : null,
        }}
        validationSchema={Yup.object().shape({
          customer: Yup.object()
            .nullable()
            .required('Required'),
          user: Yup.object()
            .nullable()
            .required('Required'),
          createAnotherOrder: Yup.boolean(),
          customerOrderNumber: Yup.string(),
          orderedAt: Yup.date().nullable(),
          requiredAt: Yup.date().nullable(),
          promisedAt: Yup.date().nullable(),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const mutation = order ? UpdateOrderMutation : AddOrderMutation;

          const variables: any = {
            input: {
              customerId: values.customer?.value || null,
              customerOrderNumber: values.customerOrderNumber || null,
              userId: values.user?.value || null,
              shippingAddressLine1: values.shippingAddressLine1,
              shippingAddressLine2: values.shippingAddressLine2,
              shippingAddressLine3: values.shippingAddressLine3,
              shippingAddressCity: values.shippingAddressCity,
              shippingAddressState: values.shippingAddressState,
              shippingAddressZip: values.shippingAddressZip,
              notes: values.notes,
              orderedAt: values.orderedAt || null,
              requiredAt: values.requiredAt || null,
              promisedAt: values.promisedAt || null,
            },
          };

          if (order) {
            variables.input.id = order.id;
          }

          try {
            if (values.customer && values.customer.__isNew__) {
              const { addCustomer } = await AddCustomerMutation.commit({
                variables: {
                  input: {
                    name: values.customer?.value,
                    shippingAddressLine1: values.shippingAddressLine1,
                    shippingAddressLine2: values.shippingAddressLine2,
                    shippingAddressLine3: values.shippingAddressLine3,
                    shippingAddressCity: values.shippingAddressCity,
                    shippingAddressState: values.shippingAddressState,
                    shippingAddressZip: values.shippingAddressZip,
                  },
                },
              });

              variables.input.customerId = addCustomer.customerEdge.node.id;
            }

            const response = await mutation.commit({
              variables,
            });

            const newOrder = (response.updateOrder || response.addOrder)
              .orderEdge.node;

            if (values.createAnotherOrder && onReopen) {
              onReopen();
            } else {
              onClose();

              if (onSuccess) {
                onSuccess(newOrder);
              }
            }

            Analytics.trackEvent(`${order ? 'Update' : 'Create'} Order`, {
              customer: values.customer?.data?.name,
              dateOrdered: moment(values.orderedAt).format('L'),
              dateRequired: moment(values.requiredAt).format('L'),
              datePromised: moment(values.promisedAt).format('L'),
              assignee:
                values.user?.data?.firstName +
                ' ' +
                values.user?.data?.lastName,
            });
            Actions.alertNotification(
              {
                title: language.successTitle,
                body: language.successMessage(values.user?.value),
              },
              'success',
            );
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'Something Went Wrong');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <ModalHeader header={language.title} onClose={onClose} />
            <ModalBody withPadding>
              <FieldGroupRow
                left={
                  <SelectCustomerField
                    name="customer"
                    creatable
                    clearable
                    error={errors.customer}
                    creatingNewRecord={
                      values.customer && values.customer.__isNew__
                    }
                    onChange={value => {
                      if (!value || !value.value) {
                        return;
                      }

                      const customer = value.data;

                      if (customer) {
                        if (customer.shippingAddressLine1) {
                          setFieldValue(
                            'shippingAddressLine1',
                            customer.shippingAddressLine1,
                          );
                        }

                        if (customer.shippingAddressLine2) {
                          setFieldValue(
                            'shippingAddressLine2',
                            customer.shippingAddressLine2,
                          );
                        }

                        if (customer.shippingAddressLine3) {
                          setFieldValue(
                            'shippingAddressLine3',
                            customer.shippingAddressLine3,
                          );
                        }

                        if (customer.shippingAddressCity) {
                          setFieldValue(
                            'shippingAddressCity',
                            customer.shippingAddressCity,
                          );
                        }

                        if (customer.shippingAddressState) {
                          setFieldValue(
                            'shippingAddressState',
                            customer.shippingAddressState,
                          );
                        }

                        if (customer.shippingAddressZip) {
                          setFieldValue(
                            'shippingAddressZip',
                            customer.shippingAddressZip,
                          );
                        }
                      }
                    }}
                  />
                }
                right={
                  <FieldGroup
                    name="customerOrderNumber"
                    label={i18n.t('Customer Purchase Order #')}
                    placeholder={i18n.t(`Your Customer's Purchase Order #`)}
                    error={errors.customerOrderNumber}
                    tooltip={i18n.t(
                      `Add your customer's purchase order number for reference when searching through orders.`,
                    )}
                  />
                }
              />
              <SelectUserField
                name="user"
                label={i18n.t('Assignee')}
                error={errors.user}
                tooltip={i18n.t(
                  'The Order Assignee will receive regular updates when changes are made to an order.',
                )}
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="orderedAt"
                    type="date"
                    label={i18n.t('Date Ordered')}
                    placeholder={i18n.t('Date')}
                    error={errors.orderedAt}
                    allowPastDates
                  />
                }
                center={
                  <FieldGroup
                    name="requiredAt"
                    type="date"
                    label={i18n.t('Date Required')}
                    placeholder={i18n.t('Date')}
                    error={errors.requiredAt}
                    minDate={values.orderedAt}
                  />
                }
                right={
                  <FieldGroup
                    name="promisedAt"
                    type="date"
                    label={i18n.t('Date Promised')}
                    placeholder={i18n.t('Date')}
                    error={errors.promisedAt}
                    minDate={values.orderedAt}
                    tooltip="This date is used for auto-scheduling and to let assignees know when jobs are in jeopardy of passing the promise date."
                  />
                }
              />
              <Divider style={{ marginTop: 8, marginBottom: 24 }} />
              <FieldGroup
                name="shippingAddressLine1"
                label={i18n.t('Shipping Address')}
                placeholder={i18n.t('Shipping Address Line 1')}
                error={errors.shippingAddressLine1}
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="shippingAddressLine2"
                    label={i18n.t('Address 2')}
                    placeholder={i18n.t('Shipping Address Line 2')}
                    error={errors.shippingAddressLine2}
                  />
                }
                right={
                  <FieldGroup
                    name="shippingAddressLine3"
                    label={i18n.t('Address 3')}
                    placeholder={i18n.t('Shipping Address Line 3')}
                    error={errors.shippingAddressLine3}
                  />
                }
              />
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="shippingAddressCity"
                    label={i18n.t('City')}
                    placeholder={i18n.t('City')}
                    error={errors.shippingAddressCity}
                  />
                }
                center={
                  <FieldGroup
                    name="shippingAddressState"
                    label={i18n.t('State')}
                    placeholder={i18n.t('State')}
                    error={errors.shippingAddressState}
                  />
                }
                right={
                  <FieldGroup
                    name="shippingAddressZip"
                    label={i18n.t('Postal Code')}
                    placeholder={i18n.t('Zip Code')}
                    error={errors.shippingAddressZip}
                  />
                }
              />
              <Divider style={{ marginTop: 8, marginBottom: 24 }} />
              <FieldGroup
                name="notes"
                type="textarea"
                label={i18n.t('Notes')}
                placeholder={i18n.t('Enter notes here...')}
                error={errors.notes}
              />
            </ModalBody>
            <ModalFooter
              style={{
                alignItems: 'center',
              }}
            >
              {!order && (
                <FieldGroup
                  label={i18n.t('Create Another Order')}
                  sideLabel="right"
                  name="createAnotherOrder"
                  error={errors.createAnotherOrder}
                  type="toggle"
                />
              )}
              <Button
                type="submit"
                theme="blue"
                disabled={!isValid}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {language.submitButton}
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

AddUpdateOrderModal.defaultProps = {
  onSuccess: undefined,
  onReopen: undefined,
  createAnotherOrder: false,
  companyId: undefined,
};

export default createFragmentContainer(AddUpdateOrderModal, {
  order: graphql`
    fragment AddUpdateOrderModal_order on Order {
      id
      customerOrderNumber
      shippingAddressLine1
      shippingAddressLine2
      shippingAddressLine3
      shippingAddressCity
      shippingAddressState
      shippingAddressZip
      notes
      orderedAt
      requiredAt
      promisedAt
      customer {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  `,
  customer: graphql`
    fragment AddUpdateOrderModal_customer on Customer {
      id
      shippingAddressLine1
      shippingAddressLine2
      shippingAddressLine3
      shippingAddressCity
      shippingAddressState
      shippingAddressZip
    }
  `,
});

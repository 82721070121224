// @flow

import { DiagramEngine, PortModel } from 'storm-react-diagrams';
import { CustomLinkModel } from '../CustomLink';

class IconPortModel extends PortModel {
  constructor(pos: 'top' | 'bottom' | 'left' | 'right', icon: string) {
    super(pos, icon);
    this.position = pos;
  }

  serialize() {
    return { ...super.serialize(), position: this.position };
  }

  deSerialize(data: any, engine: DiagramEngine) {
    super.deSerialize(data, engine);
    this.position = data.position;
  }

  link(port: PortModel) {
    const link = this.createLinkModel();

    link.setSourcePort(this);
    link.setTargetPort(port);

    return link;
  }

  createLinkModel = () => new CustomLinkModel();
}

export default IconPortModel;

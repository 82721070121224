// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateLotMutation($input: UpdateLotInput!) {
    updateLot(input: $input) {
      lotEdge {
        node {
          id
          lotNumber
          binNumber
          expiresAt
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

// @flow

export default (val: any, defaultVal: any, format: ?Function = null) => {
  if (val === undefined || val === null) {
    if (format) {
      return format(defaultVal);
    }

    return defaultVal;
  }

  return val;
};

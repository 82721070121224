// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation SwitchSessionCompanyMutation($input: SwitchSessionCompanyInput!) {
    switchSessionCompany(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

// @flow

import React, { PureComponent } from 'react';
import { i18n } from 'shared/utils';
import { Trans } from 'react-i18next';
import Panel from 'shared/components/common/Panel';
import Icon from 'shared/components/common/Icon';
import {
  Formik,
  Form,
  FieldGroup,
  SelectFormField,
  SelectUserField,
  SelectMachineTypeField,
  SelectLotField,
} from 'shared/components/form';
import * as Yup from 'yup';
import * as Actions from 'main-app/store/Actions';
import Button from 'shared/components/common/Button';
import Divider from 'shared/components/common/Divider';
import styled from 'styled-components';
import { fonts } from 'shared/styleguide';
import DeleteRecordModal from '../Modals/DeleteRecordModal';
import { IconNodeModel } from '../IconNode';

type Props = {
  onClose: () => void,
  node: IconNodeModel,
  panelStyle: Object,
};

type State = {
  showDeleteRecordModal: boolean,
};

const ScrollableContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  ${fonts.largeBold};
`;

class AddUpdateRunPanel extends PureComponent<Props, State> {
  state = {
    showDeleteRecordModal: false,
  };

  handleShowDeleteRecordModal = () => {
    this.setState({ showDeleteRecordModal: true });
  };

  handleCloseDeleteRecordModal = (cb?: () => void) => {
    this.setState({ showDeleteRecordModal: false }, () => {
      if (cb) {
        cb();
      }
    });
  };

  handleDeleteNode = () => {
    const { node, onClose } = this.props;

    node.remove();
    this.handleCloseDeleteRecordModal(onClose);
    Actions.alertNotification(
      {
        title: 'Transition Deleted Successfully!',
        body: null,
      },
      'success',
    );
  };

  render() {
    const { node, onClose, panelStyle } = this.props;
    const { showDeleteRecordModal } = this.state;

    return (
      <Panel
        headerLeft={
          <HeaderLeft>
            <Icon type="circle-run" size={32} style={{ marginRight: 8 }} />
            {i18n.t('Run Settings')}
          </HeaderLeft>
        }
        headerRight={
          <>
            <Icon
              type="circle-trash"
              size={24}
              onClick={this.handleShowDeleteRecordModal}
              style={{
                marginRight: 12,
              }}
            />
            <Icon
              type="circle-close"
              size={24}
              onClick={() => {
                onClose();
              }}
            />
          </>
        }
        style={{
          ...panelStyle,
        }}
      >
        <Formik
          initialValues={{
            name: node.data?.name || '',
            form: node.data?.form?.data?.id
              ? {
                  data: node.data?.form?.data,
                  value: node.data?.form?.data.id,
                  label: node.data?.form?.label,
                }
              : null,
            isAssignable:
              node.data?.isAssignable !== undefined
                ? node.data?.isAssignable
                : false,
            isPromptingFinalQuantity:
              node.data?.isPromptingFinalQuantity !== undefined
                ? node.data?.isPromptingFinalQuantity
                : false,
            defaultUser: node.data?.defaultUser?.data?.id
              ? {
                  data: node.data?.defaultUser?.data,
                  value: node.data?.defaultUser?.data.id,
                  label: node.data?.defaultUser?.label,
                }
              : null,
            machineType: node.data?.machineType?.data?.id
              ? {
                  data: node.data?.machineType?.data,
                  value: node.data?.machineType?.data.id,
                  label: node.data?.machineType?.label,
                }
              : null,
            isTrackingSetupQuantity:
              node.data?.isTrackingSetupQuantity !== undefined
                ? node.data?.isTrackingSetupQuantity
                : false,
            trackRunQuantityType:
              node.data?.trackRunQuantityType?.value !== undefined
                ? {
                    value: node.data?.trackRunQuantityType?.value,
                    label: node.data?.trackRunQuantityType?.label,
                  }
                : null,
            isGangRun:
              node.data?.isGangRun !== undefined ? node.data?.isGangRun : false,
            isRequired:
              node.data?.isRequired !== undefined
                ? node.data?.isRequired
                : true,
            runQuantityPerHour: node.data?.runQuantityPerHour || '',
            runMinutesOverride: node.data?.runMinutesOverride || '',
            postRunDelayMinutes: node.data?.postRunDelayMinutes || '',
            defaultLot: node.data?.defaultLot?.data?.id
              ? {
                  data: node.data?.defaultLot?.data,
                  value: node.data?.defaultLot?.data.id,
                }
              : null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            form: Yup.object().nullable(),
            isAssignable: Yup.boolean().required('Required'),
            defaultUser: Yup.object().nullable(),
            machineType: Yup.object()
              .nullable()
              .required('Required'),
            isTrackingSetupQuantity: Yup.boolean().required('Required'),
            trackRunQuantityType: Yup.object()
              .nullable()
              .required('Required'),
            isGangRun: Yup.boolean().required('Required'),
            isRequired: Yup.boolean().required('Required'),
            isPromptingFinalQuantity: Yup.boolean().required('Required'),
            runQuantityPerHour: Yup.number()
              .moreThan(
                0,
                "Please enter a value greater than 0. We’ll default the run speed to the work center's run speed scheduled for this step if you leave this blank.",
              )
              .nullable(),
            runMinutesOverride: Yup.number()
              .moreThan(0, 'Please enter a value greater than 0.')
              .nullable(),
            postRunDelayMinutes: Yup.number()
              .moreThan(0, 'Please enter a value greater than 0.')
              .nullable(),
            defaultLot: Yup.object().nullable(),
          })}
          onSubmit={(values: *, { setSubmitting }: *) => {
            try {
              setSubmitting(true);

              const input: any = {
                name: values.name,
                machineType: values.machineType,
                trackRunQuantityType: values.trackRunQuantityType,
                form: values.form,
                isAssignable: values.isAssignable,
                isPromptingFinalQuantity: values.isPromptingFinalQuantity,
                defaultUser: values.defaultUser || null,
                isTrackingSetupQuantity: values.isTrackingSetupQuantity,
                isGangRun: values.isGangRun,
                isRequired: values.isRequired,
                runMinutesOverride: values.runMinutesOverride
                  ? parseFloat(values.runMinutesOverride)
                  : null,
                runQuantityPerHour: values.runQuantityPerHour
                  ? parseFloat(values.runQuantityPerHour)
                  : null,
                postRunDelayMinutes: values.postRunDelayMinutes
                  ? parseFloat(values.postRunDelayMinutes)
                  : null,
                defaultLot: values.defaultLot || null,
              };
              node.data = { ...node.data, ...input, saved: true };

              onClose();
              Actions.alertNotification(
                {
                  title: i18n.t('Run Successfully Updated'),
                  body: i18n.t(
                    'Please remember to click "Publish Workflow" to save any changes.',
                  ),
                },
                'success',
              );
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(
                e.message,
                i18n.t('Something Went Wrong'),
              );
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              style={{
                position: 'relative',
                paddingBottom: 60,
                height: 'calc(100% - 60px)',
              }}
            >
              <ScrollableContent>
                <FieldGroup
                  name="name"
                  label={i18n.t('Run Name')}
                  placeholder={i18n.t('Enter Run Name')}
                  error={errors.name}
                />
                <SelectMachineTypeField
                  name="machineType"
                  error={errors.machineType}
                  placeholder={i18n.t('Select A Work Center')}
                  tooltip={i18n.t(
                    'Select a work center type that this run can be scheduled for.',
                  )}
                  validateMachineType
                />
                <FieldGroup
                  type="select"
                  name="trackRunQuantityType"
                  label={i18n.t('Track Run Quantity')}
                  placeholder={i18n.t('Require some run quantity')}
                  options={[
                    {
                      label: 'None',
                      value: 'NONE',
                    },
                    {
                      label: 'Partial',
                      value: 'PARTIAL',
                    },
                    {
                      label: 'All',
                      value: 'ALL',
                    },
                  ]}
                  error={errors.trackRunQuantityType}
                  tooltip={
                    <Trans>
                      <>
                        {'None - Quantity is not tracked'}
                        <br />
                        {
                          'Partial - Quantity is tracked, does not need to equal 100%'
                        }
                        <br />
                        {'Full - Quantity is tracked, must equal to 100%"'}
                      </>
                    </Trans>
                  }
                />
                <SelectFormField
                  name="form"
                  label={i18n.t('Select Form')}
                  placeholder={i18n.t('Select A Form')}
                  error={errors.form}
                  queryVariables={{
                    types: ['JOB_STEP'],
                  }}
                  tooltip={i18n.t(
                    'Forms are available to fill out on the job ticket and operator portal.',
                  )}
                />
                <Divider />
                <FieldGroup
                  type="toggle"
                  name="isAssignable"
                  label={i18n.t('Assignable Run?')}
                  error={errors.isAssignable}
                  sideLabel="left"
                />
                {values.isAssignable && (
                  <SelectUserField
                    name="defaultUser"
                    label={i18n.t('Default Assignee')}
                    placeholder={i18n.t('Select A User')}
                    error={errors.defaultUser}
                    excludeOperators={false}
                  />
                )}
                <FieldGroup
                  type="toggle"
                  sideLabel="left"
                  name="isTrackingSetupQuantity"
                  label={i18n.t('Track Setup Quantity')}
                  error={errors.isTrackingSetupQuantity}
                  tooltip={i18n.t(
                    'If on, Setup Quantity is tracked after setup is completed.',
                  )}
                />
                <FieldGroup
                  type="toggle"
                  sideLabel="left"
                  name="isGangRun"
                  label={i18n.t('Gang Run')}
                  error={errors.isGangRun}
                  tooltip={i18n.t(
                    `Enable Gang Run if you'd like to prompt for a Number Up quantity when adding an item to a job ticket. Quantity Produced = Run Quantity X Number Up.`,
                  )}
                />
                <FieldGroup
                  type="toggle"
                  name="isPromptingFinalQuantity"
                  label={i18n.t('Prompt Quantity Produced?')}
                  error={errors.isPromptingFinalQuantity}
                  sideLabel="left"
                />
                {values.isPromptingFinalQuantity && (
                  <>
                    <Divider style={{ marginBottom: 16 }} />
                    <SelectLotField
                      name="defaultLot"
                      label={i18n.t('Select Default Lot (Optional)')}
                      placeholder={i18n.t('Select A Lot')}
                      error={errors.defaultLot}
                    />
                  </>
                )}
                <Divider />
                <FieldGroup
                  name="isRequired"
                  type="toggle"
                  sideLabel="left"
                  label={i18n.t('Required')}
                  error={errors.isRequired}
                  tooltip={i18n.t(
                    `If on, we'll let you know that this run should be completed on the workflow.`,
                  )}
                />
                <Divider style={{ marginTop: 8, marginBottom: 16 }} />
                <FieldGroup
                  name="runQuantityPerHour"
                  type="number"
                  label={i18n.t('Add Avg Run Qty Override')}
                  placeholder={i18n.t('Enter a Number')}
                  extendedLabel={i18n.t('/Hour')}
                  tooltip={i18n.t(
                    `The Average Run Quantity is used to estimate the run times and provide production reports.`,
                  )}
                  error={errors.runQuantityPerHour}
                />
                <FieldGroup
                  name="runMinutesOverride"
                  type="number"
                  label={i18n.t('Add Fixed Duration Override')}
                  placeholder={i18n.t('Enter Duration')}
                  extendedLabel={i18n.t('Min')}
                  tooltip={i18n.t(
                    'Enter the number of minutes that this run will take, regardless of any work center run speeds.',
                  )}
                  error={errors.runMinutesOverride}
                />
                <FieldGroup
                  name="postRunDelayMinutes"
                  type="number"
                  label={i18n.t('Add Delay After Run')}
                  placeholder={i18n.t('Enter Duration')}
                  extendedLabel={i18n.t('Min')}
                  tooltip={i18n.t(
                    'Enter the number of minutes that you would like to delay before auto-scheduling the next run.',
                  )}
                  error={errors.postRunDelayMinutes}
                />
              </ScrollableContent>
              <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <Divider style={{ marginBottom: 16 }} />
                <Button
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  width="100%"
                >
                  {i18n.t('Save')}
                </Button>
              </div>
            </Form>
          )}
        />
        {showDeleteRecordModal && (
          <DeleteRecordModal
            onDeleteRecord={this.handleDeleteNode}
            onClose={this.handleCloseDeleteRecordModal}
            type="node"
          />
        )}
      </Panel>
    );
  }
}

export default AddUpdateRunPanel;

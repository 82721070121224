/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateRunModal_job$ref = any;
type AddUpdateRunModal_run$ref = any;
type DeleteRunModal_run$ref = any;
type RunYieldsList_runYieldEdges$ref = any;
type ViewRunModal_run$ref = any;
export type RunStatus = "COMPLETE" | "IN_PROGRESS" | "PAUSED" | "SCHEDULED";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RunPanels_runEdges$ref: FragmentReference;
declare export opaque type RunPanels_runEdges$fragmentType: RunPanels_runEdges$ref;
export type RunPanels_runEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +status: RunStatus,
    +scheduledStartAt: any,
    +jobState: {|
      +id: string,
      +workflowState: {|
        +id: string,
        +name: string,
      |},
    |},
    +machine: {|
      +id: string,
      +name: string,
    |},
    +yields: {|
      +edges: ?$ReadOnlyArray<?{|
        +$fragmentRefs: RunYieldsList_runYieldEdges$ref
      |}>
    |},
    +job: {|
      +id: string,
      +$fragmentRefs: AddUpdateRunModal_job$ref,
    |},
    +$fragmentRefs: AddUpdateRunModal_run$ref & DeleteRunModal_run$ref & ViewRunModal_run$ref,
  |},
  +$refType: RunPanels_runEdges$ref,
|}>;
export type RunPanels_runEdges$data = RunPanels_runEdges;
export type RunPanels_runEdges$key = {
  +$data?: RunPanels_runEdges$data,
  +$fragmentRefs: RunPanels_runEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "RunPanels_runEdges",
  "type": "RunEdge",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "node",
          "yields"
        ]
      }
    ],
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Run",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "scheduledStartAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "jobState",
          "storageKey": null,
          "args": null,
          "concreteType": "JobState",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "workflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v1/*: any*/)
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "machine",
          "storageKey": null,
          "args": null,
          "concreteType": "Machine",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "kind": "LinkedField",
          "alias": "yields",
          "name": "__Runs_job_yields_connection",
          "storageKey": null,
          "args": null,
          "concreteType": "RunYieldConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "RunYieldEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "cursor",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "JobYield",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "__typename",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "RunYieldsList_runYieldEdges",
                  "args": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "pageInfo",
              "storageKey": null,
              "args": null,
              "concreteType": "PageInfo",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "endCursor",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hasNextPage",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "job",
          "storageKey": null,
          "args": null,
          "concreteType": "Job",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "AddUpdateRunModal_job",
              "args": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateRunModal_run",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "DeleteRunModal_run",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "ViewRunModal_run",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '976d792abb64824b3339387369f45d53';
module.exports = node;

/**
 * @flow
 * @relayHash cb7eebbc0fc335c0c90e4c12ee74c3c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TrackRunQuantityType = "ALL" | "NONE" | "PARTIAL";
export type UpdateWorkflowFullInput = {|
  workflowId: string,
  name?: ?string,
  isDefault?: ?boolean,
  transitions?: ?$ReadOnlyArray<WorkflowTransitionInput>,
  states?: ?$ReadOnlyArray<WorkflowStateInput>,
|};
export type WorkflowTransitionInput = {|
  fromClientId: string,
  toClientId: string,
  addTags?: ?$ReadOnlyArray<string>,
  removeTags?: ?$ReadOnlyArray<string>,
  defaultUserId?: ?string,
  clientId: string,
|};
export type WorkflowStateInput = {|
  clientId: string,
  runQuantityPerHour?: ?number,
  runMinutesOverride?: ?number,
  postRunDelayMinutes?: ?number,
  formId?: ?string,
  machineTypeId?: ?string,
  defaultUserId?: ?string,
  defaultLotId?: ?string,
  name?: ?string,
  type: string,
  isGangRun?: ?boolean,
  trackRunQuantityType?: ?TrackRunQuantityType,
  isTrackingSetupQuantity?: ?boolean,
  isAssignable?: ?boolean,
  isRequired?: ?boolean,
  isPromptingFinalQuantity?: ?boolean,
|};
export type UpdateWorkflowFullMutationVariables = {|
  input: UpdateWorkflowFullInput
|};
export type UpdateWorkflowFullMutationResponse = {|
  +updateWorkflowFull: {|
    +workflowEdge: {|
      +cursor: string,
      +node: {|
        +id: string,
        +name: string,
        +isDefault: boolean,
      |},
    |}
  |}
|};
export type UpdateWorkflowFullMutation = {|
  variables: UpdateWorkflowFullMutationVariables,
  response: UpdateWorkflowFullMutationResponse,
|};
*/


/*
mutation UpdateWorkflowFullMutation(
  $input: UpdateWorkflowFullInput!
) {
  updateWorkflowFull(input: $input) {
    workflowEdge {
      cursor
      node {
        id
        name
        isDefault
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateWorkflowFullInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateWorkflowFull",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateWorkflowFullPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "workflowEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "WorkflowEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Workflow",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isDefault",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateWorkflowFullMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateWorkflowFullMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateWorkflowFullMutation",
    "id": null,
    "text": "mutation UpdateWorkflowFullMutation(\n  $input: UpdateWorkflowFullInput!\n) {\n  updateWorkflowFull(input: $input) {\n    workflowEdge {\n      cursor\n      node {\n        id\n        name\n        isDefault\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3c7adb78b78f624d8aaad3050f53f53';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeleteDowntimeCategoryModal_downtimeCategory$ref: FragmentReference;
declare export opaque type DeleteDowntimeCategoryModal_downtimeCategory$fragmentType: DeleteDowntimeCategoryModal_downtimeCategory$ref;
export type DeleteDowntimeCategoryModal_downtimeCategory = {|
  +id: string,
  +name: string,
  +parentCategory: ?{|
    +id: string,
    +name: string,
  |},
  +$refType: DeleteDowntimeCategoryModal_downtimeCategory$ref,
|};
export type DeleteDowntimeCategoryModal_downtimeCategory$data = DeleteDowntimeCategoryModal_downtimeCategory;
export type DeleteDowntimeCategoryModal_downtimeCategory$key = {
  +$data?: DeleteDowntimeCategoryModal_downtimeCategory$data,
  +$fragmentRefs: DeleteDowntimeCategoryModal_downtimeCategory$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DeleteDowntimeCategoryModal_downtimeCategory",
  "type": "DowntimeCategory",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parentCategory",
      "storageKey": null,
      "args": null,
      "concreteType": "DowntimeCategory",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'abe00069a6b82f03457150634bd9dc83';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import type { RouterHistory } from 'react-router';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import AddUpdateDowntimeCategoryModal from 'main-app/components/AddUpdateDowntimeCategoryModal';
import DeleteDowntimeCategoryModal from 'main-app/components/DeleteDowntimeCategoryModal';

type Props = {
  history: RouterHistory,
};

type State = {
  addModalOpen: boolean,
  updateModalRecord: ?Object,
  deleteModalRecord: ?Object,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 16px 16px 24px 16px;

  @media (min-width: 1000px) {
    align-self: flex-end;
    padding: 0 0 24px;
  }
`;

const ListWrapper = styled.div`
  flex: 1;
`;

class DowntimeCategories extends PureComponent<Props, State> {
  menuTriggerRefs = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      addModalOpen: false,
      updateModalRecord: null,
      deleteModalRecord: null,
    };
  }

  handleOpenUpdateModal = (
    target: *,
    downtimeCategoryEdges: ?Array<Object>,
  ) => {
    const downtimeCategoryId = target.getAttribute('downtime-category-id');
    let downtimeCategory;

    if (!downtimeCategoryEdges) {
      return;
    }

    for (const category of downtimeCategoryEdges) {
      if (category.node.id === downtimeCategoryId) {
        downtimeCategory = category;
        break;
      }

      if (category.node.childCategories?.edges) {
        for (const childCategory of category.node.childCategories.edges) {
          if (childCategory.node.id === downtimeCategoryId) {
            downtimeCategory = childCategory;
            break;
          }
        }
      }
    }

    if (downtimeCategory) {
      this.setState({
        updateModalRecord: downtimeCategory.node,
      });
    }
  };

  handleOpenDeleteModal = (
    target: *,
    downtimeCategoryEdges: ?Array<Object>,
  ) => {
    const downtimeCategoryId = target.getAttribute('downtime-category-id');
    let downtimeCategory;

    if (!downtimeCategoryEdges) {
      return;
    }

    for (const category of downtimeCategoryEdges) {
      if (category.node.id === downtimeCategoryId) {
        downtimeCategory = category;
        break;
      }

      if (category.node.childCategories?.edges) {
        for (const childCategory of category.node.childCategories.edges) {
          if (childCategory.node.id === downtimeCategoryId) {
            downtimeCategory = childCategory;
            break;
          }
        }
      }
    }

    if (downtimeCategory) {
      this.setState({
        deleteModalRecord: downtimeCategory.node,
      });
    }
  };

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleOpenDeleteDowntimeCategoryModal = () => {
    this.setState({
      deleteModalRecord: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      updateModalRecord: null,
      deleteModalRecord: false,
    });
  };

  render() {
    const { history } = this.props;
    const { addModalOpen, updateModalRecord, deleteModalRecord } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query DowntimeCategoriesQuery {
            downtimeCategories(first: null, parentsOnly: true)
              @connection(
                key: "DowntimeCategories_downtimeCategories"
                filters: []
              ) {
              edges {
                node {
                  id
                  name
                  childCategories(first: null)
                    @connection(
                      key: "DowntimeCategories_downtimeCategories_childCategories"
                    ) {
                    edges {
                      node {
                        id
                        name
                        ...AddUpdateDowntimeCategoryModal_downtimeCategory
                        ...DeleteDowntimeCategoryModal_downtimeCategory
                      }
                    }
                  }
                  ...AddUpdateDowntimeCategoryModal_downtimeCategory
                  ...DeleteDowntimeCategoryModal_downtimeCategory
                }
              }
            }
          }
        `}
        variables={null}
        render={({ props }) => {
          if (!props) {
            return <Loader />;
          }

          const columnConfig = [
            {
              Header: i18n.t('Name'),
              accessor: 'name',
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              enforceWidth: true,
              Cell: ({ value }) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="settings-downtime-categories-menu"
                  attributes={{
                    'downtime-category-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={e => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(e);
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ];

          return (
            <Wrapper>
              <ButtonWrapper>
                <Button
                  onClick={this.handleOpenCreateModal}
                  theme="border-white"
                >
                  {i18n.t('Create Downtime Category')}
                </Button>
              </ButtonWrapper>

              {!props.downtimeCategories.edges.length ? (
                <div>
                  {i18n.t(`You haven't added any downtime categories yet.`)}
                </div>
              ) : (
                <ListWrapper>
                  <Table
                    data={props.downtimeCategories.edges.map(edge => edge.node)}
                    columns={columnConfig}
                    SubComponent={({ original }) => {
                      if (!original.childCategories.edges?.length) {
                        return undefined;
                      }

                      return (
                        <Table
                          nested
                          data={original.childCategories.edges.map(
                            edge => edge.node,
                          )}
                          columns={columnConfig}
                        />
                      );
                    }}
                  />
                </ListWrapper>
              )}
              {addModalOpen && (
                <AddUpdateDowntimeCategoryModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  downtimeCategory={null}
                />
              )}
              {updateModalRecord && (
                <AddUpdateDowntimeCategoryModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  downtimeCategory={updateModalRecord}
                />
              )}
              {deleteModalRecord && (
                <DeleteDowntimeCategoryModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  downtimeCategory={deleteModalRecord}
                  // onSuccess={this.handleDeleteSuccess}
                />
              )}
              <ContextMenu id="settings-downtime-categories-menu">
                <MenuItem
                  onClick={(e, data, target) =>
                    this.handleOpenUpdateModal(
                      target,
                      props.downtimeCategories.edges,
                    )
                  }
                >
                  {i18n.t('Edit Downtime Category')}
                </MenuItem>
                <MenuItem
                  onClick={(e, data, target) =>
                    this.handleOpenDeleteModal(
                      target,
                      props.downtimeCategories.edges,
                    )
                  }
                >
                  {i18n.t('Delete Downtime Category')}
                </MenuItem>
              </ContextMenu>
            </Wrapper>
          );
        }}
      />
    );
  }
}

export default DowntimeCategories;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateLotModal_lot$ref: FragmentReference;
declare export opaque type AddUpdateLotModal_lot$fragmentType: AddUpdateLotModal_lot$ref;
export type AddUpdateLotModal_lot = {|
  +id: string,
  +lotNumber: string,
  +binNumber: ?string,
  +expiresAt: ?any,
  +$refType: AddUpdateLotModal_lot$ref,
|};
export type AddUpdateLotModal_lot$data = AddUpdateLotModal_lot;
export type AddUpdateLotModal_lot$key = {
  +$data?: AddUpdateLotModal_lot$data,
  +$fragmentRefs: AddUpdateLotModal_lot$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AddUpdateLotModal_lot",
  "type": "Lot",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lotNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "binNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expiresAt",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2f66c6dd0c967a738f4c3f918a53d3d7';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import type { RouterHistory } from 'react-router';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import AddUpdateUserModal from 'main-app/components/AddUpdateUserModal';

type Props = {
  history: RouterHistory,
};

type State = {
  addModalOpen: boolean,
  updateModalRecord: ?Object,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 16px 16px 24px 16px;

  @media (min-width: 1000px) {
    align-self: flex-end;
    padding: 0 0 24px;
  }
`;

const ListWrapper = styled.div`
  flex: 1;
`;
class Users extends PureComponent<Props, State> {
  // class Users extends PureComponent<Props> {
  menuTriggerRefs = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      addModalOpen: false,
      updateModalRecord: null,
    };
  }

  handleOpenUpdateModal = (target: *, userEdges: ?Array<Object>) => {
    const userId = target.getAttribute('user-id');

    const user = userEdges
      ? userEdges.find(({ node }) => node.id === userId)
      : null;

    if (user) {
      this.setState({
        updateModalRecord: user.node,
      });
    }
  };

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      updateModalRecord: null,
    });
  };

  render() {
    const { history } = this.props;
    const { addModalOpen, updateModalRecord } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query UsersQuery {
            users(first: null, companyUserRoles: [ADMIN, USER, WORKER])
              @connection(key: "Users_users", filters: []) {
              edges {
                node {
                  id
                  userNumber
                  email
                  firstName
                  lastName
                  role
                  ...AddUpdateUserModal_user
                }
              }
            }
          }
        `}
        variables={null}
        render={({ props }) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <Wrapper>
              <ButtonWrapper>
                <Button
                  onClick={this.handleOpenCreateModal}
                  theme="border-white"
                >
                  {i18n.t('Create User')}
                </Button>
              </ButtonWrapper>

              {!props.users.edges.length ? (
                <div>{i18n.t(`You haven't added any users yet.`)}</div>
              ) : (
                <ListWrapper>
                  <Table
                    data={props.users.edges.map(edge => edge.node)}
                    columns={[
                      {
                        Header: i18n.t('Operator ID'),
                        accessor: 'userNumber',
                        width: 100,
                        Cell: ({ value }) => (
                          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                            {value}
                          </div>
                        ),
                      },
                      {
                        Header: i18n.t('Role'),
                        accessor: 'role',
                        width: 100,
                        Cell: ({ value }) => (
                          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                            {value === 'WORKER' ? 'OPERATOR' : value}
                          </div>
                        ),
                      },
                      {
                        Header: i18n.t('First Name'),
                        accessor: 'firstName',
                        width: 140,
                        Cell: ({ value }) => (
                          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                            {value}
                          </div>
                        ),
                      },
                      {
                        Header: i18n.t('Last Name'),
                        accessor: 'lastName',
                        width: 140,
                        Cell: ({ value }) => (
                          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                            {value}
                          </div>
                        ),
                      },
                      {
                        Header: i18n.t('Email'),
                        accessor: 'email',
                        // width: 200,
                        Cell: ({ value }) => (
                          <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                            {value}
                          </div>
                        ),
                      },
                      {
                        Header: '',
                        accessor: 'id',
                        width: 48,
                        enforceWidth: true,
                        Cell: ({ value }) => (
                          <ContextMenuTrigger
                            ref={r => (this.menuTriggerRefs[value] = r)}
                            id="settings-users-menu"
                            attributes={{
                              'user-id': value,
                            }}
                          >
                            <Icon
                              type="circle-context-menu"
                              size={24}
                              onClick={e => {
                                if (this.menuTriggerRefs[value]) {
                                  this.menuTriggerRefs[
                                    value
                                  ].handleContextClick(e);
                                }
                              }}
                            />
                          </ContextMenuTrigger>
                        ),
                      },
                    ]}
                  />
                </ListWrapper>
              )}
              {addModalOpen && (
                <AddUpdateUserModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  user={null}
                />
              )}
              {updateModalRecord && (
                <AddUpdateUserModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  user={updateModalRecord}
                />
              )}
              <ContextMenu id="settings-users-menu">
                <MenuItem
                  onClick={(e, data, target) =>
                    this.handleOpenUpdateModal(target, props.users.edges)
                  }
                >
                  {i18n.t('Edit User')}
                </MenuItem>
              </ContextMenu>
            </Wrapper>
          );
        }}
      />
    );
  }
}

export default Users;

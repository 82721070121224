// @flow

import { DiagramEngine, AbstractLinkFactory } from 'storm-react-diagrams';
import React from 'react';
import { colors } from 'shared/styleguide';
import CustomLinkModel from './CustomLinkModel';
import CustomLinkWidget from './CustomLinkWidget';

class CustomLinkFactory extends AbstractLinkFactory {
  constructor() {
    super('custom-link');
  }

  generateReactWidget = (
    diagramEngine: DiagramEngine,
    link: CustomLinkModel,
  ) => {
    return React.createElement(CustomLinkWidget, {
      link: link,
      diagramEngine: diagramEngine,
    });
  };

  getNewInstance = (initialConfig?: any) => {
    return new CustomLinkModel();
  };

  generateLinkSegment = (
    model: *,
    widget: *,
    selected: boolean,
    path: string,
  ) => {
    return (
      <path
        className={selected ? widget.bem('--path-selected') : ''}
        strokeWidth={model.width}
        stroke={colors.charcoalGrey}
        d={path}
      />
    );
  };
}

export default CustomLinkFactory;

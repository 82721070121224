/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomerSidePanel_customer$ref: FragmentReference;
declare export opaque type CustomerSidePanel_customer$fragmentType: CustomerSidePanel_customer$ref;
export type CustomerSidePanel_customer = {|
  +id: string,
  +customerNumber: string,
  +secondaryContactName: ?string,
  +shippingAddressLine1: ?string,
  +shippingAddressLine2: ?string,
  +shippingAddressLine3: ?string,
  +shippingAddressCity: ?string,
  +shippingAddressState: ?string,
  +shippingAddressZip: ?string,
  +billingAddressLine1: ?string,
  +billingAddressLine2: ?string,
  +billingAddressLine3: ?string,
  +billingAddressCity: ?string,
  +billingAddressState: ?string,
  +billingAddressZip: ?string,
  +email: ?any,
  +phone: ?string,
  +phone2: ?string,
  +fax: ?string,
  +notes: ?string,
  +$refType: CustomerSidePanel_customer$ref,
|};
export type CustomerSidePanel_customer$data = CustomerSidePanel_customer;
export type CustomerSidePanel_customer$key = {
  +$data?: CustomerSidePanel_customer$data,
  +$fragmentRefs: CustomerSidePanel_customer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CustomerSidePanel_customer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "customerNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "secondaryContactName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine1",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine3",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressCity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressState",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressZip",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddressLine1",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddressLine2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddressLine3",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddressCity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddressState",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billingAddressZip",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fax",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notes",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7fe8b941d7b2110ccb0d861c4633c735';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      workflowId: string,
      userId: string,
      name: string,
      orderId?: string,
    },
  },
};

const mutation = graphql`
  mutation AddJobMutation($input: AddJobInput!) {
    addJob(input: $input) {
      jobEdge {
        ...JobsTable_jobEdges
        node {
          id
          importJobNumber
          jobNumber
          name
          status
          yields(first: null) {
            totalCount
            edges {
              node {
                id
                finalQuantity
              }
            }
          }
          user {
            id
            firstName
            lastName
          }
          order {
            id
            promisedAt
            orderNumber
            status
            customer {
              id
              name
            }
          }
          workflow {
            id
            name
          }
          states {
            id
            workflowState {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: ROOT_ID,
          connectionInfo: [
            {
              key: 'PaginatedJobsContainer_jobs',
              rangeBehavior: 'prepend',
            },
            {
              key: 'Order_jobs',
              rangeBehavior: 'prepend',
              filters: {
                orderIds: [options.variables.input.orderId],
              },
            },
          ],
          edgeName: 'jobEdge',
        },
      ],
    });
  },
};

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteIntegrationEventMutation from 'main-app/mutations/DeleteIntegrationEvent';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteIntegrationEventModal_integrationEvent as IntegrationEventFragment } from './__generated__/DeleteIntegrationEventModal_integrationEvent';

type Props = {
  onClose: () => void,
  integrationId: string,
  integrationEvent: IntegrationEventFragment,
  onSuccess?: () => void,
};

type State = {
  deleting: boolean,
};

class DeleteIntegrationEventModal extends PureComponent<Props, State> {
  static defaultProps = {
    onSuccess: undefined,
  };

  state = {
    deleting: false,
  };

  handleDelete = () => {
    const { integrationId, integrationEvent, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteIntegrationEventMutation.commit({
            variables: {
              input: {
                id: integrationEvent.id,
              },
            },
            context: {
              integrationId,
            },
          });

          onClose();

          Actions.alertNotification(
            i18n.t(`Event Successfully Deleted`),
            'success',
          );

          Analytics.trackEvent('Delete Event', {
            integrationEventId: integrationEvent.id,
          });

          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { integrationEvent, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete Event: {{eventName}}?', {
            eventName: integrationEvent.eventType.cosmeticName,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            'Are you sure you want to delete this event? It could affect your integration.',
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Event')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteIntegrationEventModal, {
  integrationEvent: graphql`
    fragment DeleteIntegrationEventModal_integrationEvent on IntegrationEvent {
      id
      eventType {
        id
        cosmeticName
      }
    }
  `,
});

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation DeleteAPIKeyMutation($input: DeleteAPIKeyInput!) {
    deleteAPIKey(input: $input) {
      organization {
        id
        apiKeys {
          id
          name
          secret
          lastUsedAt
          updatedAt
          createdAt
          actingUser {
            id
            firstName
            lastName
            email
            avatar
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

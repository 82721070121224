/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type YieldProgressBar_jobYield$ref = any;
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type WorkflowStateType = "JOB_COMPLETED" | "JOB_CREATED" | "JOB_ITEM_COUNT" | "RUN" | "TASK";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RunYieldProgressBarPanels_job$ref: FragmentReference;
declare export opaque type RunYieldProgressBarPanels_job$fragmentType: RunYieldProgressBarPanels_job$ref;
export type RunYieldProgressBarPanels_job = {|
  +id: string,
  +workflow: {|
    +id: string,
    +states: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +isRequired: ?boolean,
      +type: ?WorkflowStateType,
    |}>,
    +transitions: $ReadOnlyArray<{|
      +id: string,
      +toWorkflowState: {|
        +id: string
      |},
      +fromWorkflowState: {|
        +id: string
      |},
    |}>,
  |},
  +states: $ReadOnlyArray<{|
    +id: string,
    +isEnabled: boolean,
    +status: JobStateStatus,
    +workflowState: {|
      +id: string,
      +name: string,
      +type: ?WorkflowStateType,
    |},
  |}>,
  +runs: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +runTotalSeconds: number,
        +jobState: {|
          +id: string,
          +workflowState: {|
            +id: string,
            +isGangRun: ?boolean,
            +name: string,
          |},
        |},
        +yields: {|
          +edges: ?$ReadOnlyArray<?{|
            +runQuantity: ?number,
            +node: {|
              +id: string,
              +$fragmentRefs: YieldProgressBar_jobYield$ref,
            |},
          |}>
        |},
      |}
    |}>
  |},
  +$refType: RunYieldProgressBarPanels_job$ref,
|};
export type RunYieldProgressBarPanels_job$data = RunYieldProgressBarPanels_job;
export type RunYieldProgressBarPanels_job$key = {
  +$data?: RunYieldProgressBarPanels_job$data,
  +$fragmentRefs: RunYieldProgressBarPanels_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "RunYieldProgressBarPanels_job",
  "type": "Job",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "runs"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflow",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "states",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isRequired",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "transitions",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowTransition",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "toWorkflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "fromWorkflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v3/*: any*/)
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "states",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "workflowState",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "runs",
      "name": "__Runs_job_runs_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "RunConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RunEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Run",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "runTotalSeconds",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "jobState",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "JobState",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "workflowState",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "WorkflowState",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "isGangRun",
                          "args": null,
                          "storageKey": null
                        },
                        (v1/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": "yields",
                  "name": "__Runs_job_yields_connection",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RunYieldConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "RunYieldEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "runQuantity",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "JobYield",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v4/*: any*/),
                            {
                              "kind": "FragmentSpread",
                              "name": "YieldProgressBar_jobYield",
                              "args": null
                            }
                          ]
                        },
                        (v5/*: any*/)
                      ]
                    },
                    (v6/*: any*/)
                  ]
                },
                (v4/*: any*/)
              ]
            },
            (v5/*: any*/)
          ]
        },
        (v6/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ccecc26bf18f1ade4bf67a68fe8082f';
module.exports = node;

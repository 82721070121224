/**
 * @flow
 * @relayHash 3b247b6b5a18043e49608f0efcf59a86
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddIntegrationInput = {|
  integrationSourceId: string
|};
export type AddIntegrationMutationVariables = {|
  input: AddIntegrationInput
|};
export type AddIntegrationMutationResponse = {|
  +addIntegration: {|
    +integrationEdge: {|
      +node: {|
        +id: string,
        +source: {|
          +id: string,
          +name: string,
          +description: ?string,
          +logo: ?string,
        |},
      |}
    |}
  |}
|};
export type AddIntegrationMutation = {|
  variables: AddIntegrationMutationVariables,
  response: AddIntegrationMutationResponse,
|};
*/


/*
mutation AddIntegrationMutation(
  $input: AddIntegrationInput!
) {
  addIntegration(input: $input) {
    integrationEdge {
      node {
        id
        source {
          id
          name
          description
          logo
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddIntegrationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addIntegration",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddIntegrationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "integrationEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "IntegrationEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Integration",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "source",
                "storageKey": null,
                "args": null,
                "concreteType": "IntegrationSource",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "logo",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddIntegrationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddIntegrationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddIntegrationMutation",
    "id": null,
    "text": "mutation AddIntegrationMutation(\n  $input: AddIntegrationInput!\n) {\n  addIntegration(input: $input) {\n    integrationEdge {\n      node {\n        id\n        source {\n          id\n          name\n          description\n          logo\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '654a26cc18d648660fa0189c27b756a0';
module.exports = node;

// @flow

import React from 'react';
import styled from 'styled-components';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import {
  Draggable,
  type DraggableProvided,
  type DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import Icon from 'shared/components/common/Icon';
import dragIcon from './img/dragIcon.svg';
import type { MachineCard_machine as MachineFragment } from './__generated__/MachineCard_machine';

type Props = {
  index: number,
  machine: MachineFragment,
  onUpdate: Object => void,
  onDelete: Object => void,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid ${colors.lightBlueGrey};
  background-color: ${colors.white};
  margin-bottom: 16px;
  box-shadow: ${p =>
    p.isDragging ? '0 2px 4px 0 rgba(66, 68, 76, 0.2)' : 'none'};
`;

const Content = styled.div`
  flex: 1;
  color: ${colors.charcoalGrey};
`;

const Title = styled.div`
  ${fonts.largeBold};
  padding-bottom: 8px;
`;

const Info = styled.div`
  ${fonts.bodyRegular};
  padding-bottom: 8px;
  display: flex;
  flex-flow: row wrap;

  strong {
    ${fonts.bodyBold};
    padding-right: 4px;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const DragIcon = styled(dragIcon)`
  width: 14px;
  height: 20px;
  margin-right: 16px;
  align-self: center;
`;

const WorkflowLabel = styled.div`
  color: ${colors.charcoalGrey};
  background-color: ${colors.paleGrey};
  border-radius: 5px;
  padding: 2px 5px;
  margin-right: 8px;
  margin-bottom: 8px;

  &:hover {
    ${fonts.bodyBold}
    color: ${colors.azul}
  }
`;

const menuTriggerRefs = {};

const filterAssociatedWorkflows = workflows =>
  workflows
    .filter(workflow => !workflow.deletedAt)
    .map(workflow => (
      <Link to={`/settings/workflow/${workflow.id}`}>
        <WorkflowLabel key={workflow.id}>{workflow.name}</WorkflowLabel>
      </Link>
    ));

const MachineCard = ({ index, machine, onUpdate, onDelete }: Props) => (
  <>
    <ContextMenuTrigger
      id={`machine-menu-${machine.id}`}
      ref={r => (menuTriggerRefs[machine.id] = r)}
    >
      <Draggable draggableId={machine.id} index={index}>
        {(
          dragProvided: DraggableProvided,
          dragSnapshot: DraggableStateSnapshot,
        ) => (
          <Wrapper
            isDragging={dragSnapshot.isDragging}
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
          >
            <DragIcon />
            <Content>
              <Title>{machine.name}</Title>
              {machine.type?.name ? (
                <Info>
                  <strong>{i18n.t('Work Center Type:')}</strong>{' '}
                  {machine.type?.name}
                </Info>
              ) : null}
              <Info>
                <strong>{i18n.t('Associated Workflows:')}</strong>{' '}
                {machine?.workflows?.length
                  ? filterAssociatedWorkflows(machine.workflows)
                  : null}
              </Info>
            </Content>
            <Icon
              type="circle-context-menu"
              size={24}
              onClick={e => {
                if (menuTriggerRefs[machine.id]) {
                  menuTriggerRefs[machine.id].handleContextClick(e);
                }
              }}
            />
          </Wrapper>
        )}
      </Draggable>
    </ContextMenuTrigger>
    <ContextMenu id={`machine-menu-${machine.id}`}>
      <MenuItem onClick={() => onUpdate(machine)}>{i18n.t('Edit')}</MenuItem>
      <MenuItem onClick={() => onDelete(machine)}>{i18n.t('Delete')}</MenuItem>
    </ContextMenu>
  </>
);

export default createFragmentContainer(MachineCard, {
  machine: graphql`
    fragment MachineCard_machine on Machine {
      id
      name
      type {
        id
        name
      }
      workflows {
        id
        name
        deletedAt
      }
      ...AddUpdateMachineModal_machine
      ...DeleteMachineModal_machine
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import type { RouterHistory } from 'react-router';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import AddUpdateFormModal from 'main-app/components/AddUpdateFormModal';

type Props = {
  history: RouterHistory,
};

type State = {
  addModalOpen: boolean,
  updateModalRecord: ?Object,
};

const DEFAULT_FORM_NAME_MAP = {
  ITEM: {
    name: 'Inventory',
    description: 'Custom form fields when creating an item.',
  },
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const ListWrapper = styled.div`
  flex: 1;
`;

const SystemFormsHeader = styled.div`
  ${fonts.headerBold}
  color: ${colors.charcoalGrey};
  padding-bottom: 16px;
`;

const JobStateFormsHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-top: 24px;
  padding-bottom: 16px;
  ${fonts.headerBold};
`;

class Forms extends PureComponent<Props, State> {
  menuTriggerRefs = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      addModalOpen: false,
      updateModalRecord: null,
    };
  }

  handleOpenUpdateModal = (target: *, formEdges: ?Array<Object>) => {
    const formId = target.getAttribute('form-id');

    const form = formEdges
      ? formEdges.find(({ node }) => node.id === formId)
      : null;

    if (form) {
      this.setState({
        updateModalRecord: form.node,
      });
    }
  };

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      updateModalRecord: null,
    });
  };

  render() {
    const { history } = this.props;
    const { addModalOpen, updateModalRecord } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query FormsQuery {
            forms(first: null) @connection(key: "Forms_forms") {
              edges {
                node {
                  id
                  name
                  description
                  type
                  ...AddUpdateFormModal_form
                }
              }
            }
          }
        `}
        variables={null}
        render={({ props }) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <Wrapper>
              <ListWrapper>
                <SystemFormsHeader>{i18n.t('Default Forms')}</SystemFormsHeader>
                <Table
                  onRowClick={rowData =>
                    history.push(`/settings/form/${rowData.id}`)
                  }
                  data={props.forms.edges
                    .map(edge => edge.node)
                    .filter(
                      node =>
                        node.type !== 'JOB_STEP' && node.type !== 'JOB_YIELD',
                    )}
                  columns={[
                    {
                      Header: i18n.t('Form Name'),
                      accessor: 'name',
                      width: 200,
                      Cell: ({ original }) =>
                        DEFAULT_FORM_NAME_MAP[original.type]?.name ||
                        original.name,
                    },
                    {
                      Header: i18n.t('Description'),
                      accessor: 'description',
                      Cell: ({ original }) =>
                        DEFAULT_FORM_NAME_MAP[original.type]?.description ||
                        original.description,
                    },
                  ]}
                />

                <JobStateFormsHeader>
                  <div style={{ flex: 1 }}>
                    {i18n.t('Production Step Forms')}
                  </div>
                  <Button
                    onClick={this.handleOpenCreateModal}
                    theme="border-white"
                    width="auto"
                  >
                    {i18n.t('Create Form')}
                  </Button>
                </JobStateFormsHeader>
                <Table
                  onRowClick={rowData =>
                    history.push(`/settings/form/${rowData.id}`)
                  }
                  data={props.forms.edges
                    .map(edge => edge.node)
                    .filter(node => node.type === 'JOB_STEP')}
                  columns={[
                    {
                      Header: i18n.t('Form Name'),
                      accessor: 'name',
                      width: 160,
                    },
                    {
                      Header: i18n.t('Description'),
                      accessor: 'description',
                    },
                    {
                      Header: '',
                      accessor: 'id',
                      width: 48,
                      enforceWidth: true,
                      Cell: ({ value }) => (
                        <ContextMenuTrigger
                          ref={r => (this.menuTriggerRefs[value] = r)}
                          id="settings-forms-menu"
                          attributes={{
                            'form-id': value,
                          }}
                        >
                          <Icon
                            type="circle-context-menu"
                            size={24}
                            onClick={e => {
                              if (this.menuTriggerRefs[value]) {
                                this.menuTriggerRefs[value].handleContextClick(
                                  e,
                                );
                              }
                            }}
                          />
                        </ContextMenuTrigger>
                      ),
                    },
                  ]}
                />
              </ListWrapper>
              {addModalOpen && (
                <AddUpdateFormModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  form={null}
                />
              )}
              {updateModalRecord && (
                <AddUpdateFormModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  form={updateModalRecord}
                />
              )}
              <ContextMenu id="settings-forms-menu">
                <MenuItem
                  onClick={(e, data, target) =>
                    this.handleOpenUpdateModal(target, props.forms.edges)
                  }
                >
                  {i18n.t('Edit Form')}
                </MenuItem>
              </ContextMenu>
            </Wrapper>
          );
        }}
      />
    );
  }
}

export default Forms;

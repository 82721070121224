/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RunList_job$ref = any;
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type RunStatus = "COMPLETE" | "IN_PROGRESS" | "PAUSED" | "SCHEDULED";
export type WorkflowStateType = "JOB_COMPLETED" | "JOB_CREATED" | "JOB_ITEM_COUNT" | "RUN" | "TASK";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DraggableJobCard_job$ref: FragmentReference;
declare export opaque type DraggableJobCard_job$fragmentType: DraggableJobCard_job$ref;
export type DraggableJobCard_job = {|
  +id: string,
  +jobNumber: string,
  +name: string,
  +workflow: {|
    +id: string,
    +states: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +isRequired: ?boolean,
      +type: ?WorkflowStateType,
    |}>,
    +transitions: $ReadOnlyArray<{|
      +id: string,
      +toWorkflowState: {|
        +id: string
      |},
      +fromWorkflowState: {|
        +id: string
      |},
    |}>,
    +isParallelWorkflow: boolean,
  |},
  +states: $ReadOnlyArray<{|
    +id: string,
    +isEnabled: boolean,
    +status: JobStateStatus,
    +workflowState: {|
      +id: string,
      +name: string,
      +type: ?WorkflowStateType,
    |},
    +runs: $ReadOnlyArray<{|
      +id: string,
      +createdAt: any,
      +status: RunStatus,
    |}>,
  |}>,
  +order: ?{|
    +id: string,
    +promisedAt: ?any,
  |},
  +$fragmentRefs: RunList_job$ref,
  +$refType: DraggableJobCard_job$ref,
|};
export type DraggableJobCard_job$data = DraggableJobCard_job;
export type DraggableJobCard_job$key = {
  +$data?: DraggableJobCard_job$data,
  +$fragmentRefs: DraggableJobCard_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DraggableJobCard_job",
  "type": "Job",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "jobNumber",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflow",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "states",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isRequired",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "transitions",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowTransition",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "toWorkflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "fromWorkflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v3/*: any*/)
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isParallelWorkflow",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "states",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isEnabled",
          "args": null,
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "workflowState",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "runs",
          "storageKey": null,
          "args": null,
          "concreteType": "Run",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdAt",
              "args": null,
              "storageKey": null
            },
            (v4/*: any*/)
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "order",
      "storageKey": null,
      "args": null,
      "concreteType": "Order",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "promisedAt",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "RunList_job",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b7256f9df66218026eee8250c8c7c7d3';
module.exports = node;

// @flow

import React from 'react';
import { BaseWidget, DiagramEngine } from 'storm-react-diagrams';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import { CustomLabelModel } from './index';

type Props = {
  labelModel: CustomLabelModel,
  diagramEngine: DiagramEngine,
};

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${colors.charcoalGrey};
  width: 24px;
  height: 24px;
  margin: 0 auto;
  background: ${colors.white};
`;

const Label = styled.div`
  ${fonts.bodyBold}
  margin-bottom: 1px;
`;

class CustomLabelWidget extends BaseWidget<Props> {
  constructor(props: Props) {
    super('custom-label', props);
  }

  render() {
    const { labelModel } = this.props;

    return (
      <LabelWrapper {...this.getProps()}>
        <Label>{labelModel.label}</Label>
      </LabelWrapper>
    );
  }
}

export default CustomLabelWidget;

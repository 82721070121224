/**
 * @flow
 * @relayHash 199232cbf73a2a841b47f11dc7f89712
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ItemLinkedJobsTable_itemLinkedJobEdges$ref = any;
export type ItemLinkedJobsQueryVariables = {|
  itemId: string
|};
export type ItemLinkedJobsQueryResponse = {|
  +item: {|
    +id: string,
    +linkedJobs: {|
      +edges: ?$ReadOnlyArray<?{|
        +$fragmentRefs: ItemLinkedJobsTable_itemLinkedJobEdges$ref
      |}>
    |},
  |}
|};
export type ItemLinkedJobsQuery = {|
  variables: ItemLinkedJobsQueryVariables,
  response: ItemLinkedJobsQueryResponse,
|};
*/


/*
query ItemLinkedJobsQuery(
  $itemId: ID!
) {
  item(id: $itemId) {
    id
    linkedJobs {
      edges {
        ...ItemLinkedJobsTable_itemLinkedJobEdges
        cursor
        node {
          __typename
          id
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}

fragment ItemLinkedJobsTable_itemLinkedJobEdges on ItemLinkedJobEdge {
  usageQty
  node {
    id
    jobNumber
    importJobNumber
    name
    status
    createdAt
    yields {
      totalCount
    }
    order {
      id
      promisedAt
      customer {
        id
        name
      }
    }
    states {
      id
    }
    ...AddUpdateJobModal_job
    ...DeleteJobModal_job
  }
}

fragment AddUpdateJobModal_job on Job {
  id
  name
  notes
  user {
    id
  }
  workflow {
    id
    name
    states {
      id
      isGangRun
    }
  }
  order {
    id
  }
  yields {
    edges {
      node {
        id
        quantity
        itemNumberUp
        brokerCustomerOrderNumber
        formFieldValues
        overUnderRule
        overQuantity
        item {
          id
          name
          quantityUOM {
            id
            name
            symbol
          }
        }
      }
    }
  }
}

fragment DeleteJobModal_job on Job {
  id
  name
  order {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "itemId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "itemId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = [
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ItemLinkedJobsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "item",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Item",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "linkedJobs",
            "name": "__Item_linkedJobs_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemLinkedJobConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ItemLinkedJobEdge",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Job",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "ItemLinkedJobsTable_itemLinkedJobEdges",
                    "args": null
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ItemLinkedJobsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "item",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Item",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "linkedJobs",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemLinkedJobConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ItemLinkedJobEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "usageQty",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Job",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "jobNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "importJobNumber",
                        "args": null,
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "yields",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobYieldConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "totalCount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "JobYieldEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "JobYield",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "quantity",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "itemNumberUp",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "brokerCustomerOrderNumber",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "formFieldValues",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "overUnderRule",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "overQuantity",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "item",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Item",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v6/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "quantityUOM",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "UOM",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          (v6/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "symbol",
                                            "args": null,
                                            "storageKey": null
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "order",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Order",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "promisedAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "customer",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Customer",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "states",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobState",
                        "plural": true,
                        "selections": (v7/*: any*/)
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "notes",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": false,
                        "selections": (v7/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "workflow",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Workflow",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "states",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "WorkflowState",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "isGangRun",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  },
                  (v3/*: any*/)
                ]
              },
              (v5/*: any*/)
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "linkedJobs",
            "args": null,
            "handle": "connection",
            "key": "Item_linkedJobs",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ItemLinkedJobsQuery",
    "id": null,
    "text": "query ItemLinkedJobsQuery(\n  $itemId: ID!\n) {\n  item(id: $itemId) {\n    id\n    linkedJobs {\n      edges {\n        ...ItemLinkedJobsTable_itemLinkedJobEdges\n        cursor\n        node {\n          __typename\n          id\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment ItemLinkedJobsTable_itemLinkedJobEdges on ItemLinkedJobEdge {\n  usageQty\n  node {\n    id\n    jobNumber\n    importJobNumber\n    name\n    status\n    createdAt\n    yields {\n      totalCount\n    }\n    order {\n      id\n      promisedAt\n      customer {\n        id\n        name\n      }\n    }\n    states {\n      id\n    }\n    ...AddUpdateJobModal_job\n    ...DeleteJobModal_job\n  }\n}\n\nfragment AddUpdateJobModal_job on Job {\n  id\n  name\n  notes\n  user {\n    id\n  }\n  workflow {\n    id\n    name\n    states {\n      id\n      isGangRun\n    }\n  }\n  order {\n    id\n  }\n  yields {\n    edges {\n      node {\n        id\n        quantity\n        itemNumberUp\n        brokerCustomerOrderNumber\n        formFieldValues\n        overUnderRule\n        overQuantity\n        item {\n          id\n          name\n          quantityUOM {\n            id\n            name\n            symbol\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment DeleteJobModal_job on Job {\n  id\n  name\n  order {\n    id\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "item",
            "linkedJobs"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be774daa80e36dfac2146ef43d42f502';
module.exports = node;

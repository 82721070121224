// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      jobId: string,
      itemId: string,
    },
  },
};

const mutation = graphql`
  mutation DeleteJobSubComponentMutation($input: DeleteJobSubComponentInput!) {
    deleteJobSubComponent(input: $input) {
      deletedItemId
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    const { jobId } = options.variables.input;

    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: jobId,
          connectionKeys: [{ key: 'JobSubComponents_subComponents' }],
          pathToConnection: ['job', 'subComponents'],
          deletedIDFieldName: 'deletedItemId',
        },
      ],
    });
  },
};

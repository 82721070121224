// @flow

import React, { PureComponent } from 'react';
import {
  DiagramEngine,
  DiagramModel,
  DiagramWidget,
} from 'storm-react-diagrams';
import { i18n, distributeElements } from 'shared/utils';
import { graphql, createFragmentContainer } from 'react-relay';
import type { RouterHistory } from 'react-router';
import Panel from 'shared/components/common/Panel';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import Icon from 'shared/components/common/Icon';
import InfoTooltip from 'shared/components/common/InfoTooltip';
import Button from 'shared/components/common/Button';
import UpdateWorkflowFullMutation from 'main-app/mutations/UpdateWorkflowFull';
import PageHeader from 'main-app/components/PageHeader';
import * as Actions from 'main-app/store/Actions';
import AddUpdateTaskPanel from './Panels/AddUpdateTaskPanel';
import AddUpdateRunPanel from './Panels/AddUpdateRunPanel';
import AddUpdateActionPanel from './Panels/AddUpdateActionPanel';
import TrayWidget from './TrayWidget/TrayWidget';
import TrayItemWidget from './TrayWidget/TrayItemWidget';
import { IconNodeModel, IconNodeFactory, IconPortModel } from './IconNode';
import { CustomLinkFactory, CustomLinkModel } from './CustomLink';
import SimplePortFactory from './IconNode/SimplePortFactory';
import { CustomLabelFactory } from './CustomLabel';
import type { Diagram_workflow as WorkflowFragment } from './__generated__/Diagram_workflow';
import WorkflowPattern from './img/workflowPattern.png';

type Props = {
  workflow: WorkflowFragment,
  history: RouterHistory,
};

type State = {
  isWrapperMounted: boolean,
  openEditPanel: boolean,
  editPanelRecord: ?IconNodeModel | ?CustomLinkModel,
};

const ICON_TYPE_MAP = {
  JOB_CREATED: 'square-job-created',
  JOB_COMPLETED: 'square-job-completed',
  TASK: 'circle-task',
  RUN: 'circle-run',
};

const WorkflowWrapper = styled.div`
  position: relative;
  display: flex;
  max-height: 100%;
  height: 100%;
  width: 100%;

  .srd-diagram {
    height: 100%;
    background-image: url(${WorkflowPattern});
    background-repeat: repeat;
    border-radius: 10px 0 0 10px;

    .srd-default-link__label {
      overflow: visible !important;
    }

    .srd-default-link:hover {
      cursor: pointer;
    }
  }
`;

const IconLabel = styled.div`
  margin-left: 12px;
  ${fonts.bodyRegular};
`;

const ZoomIconsWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
`;

const PanelGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  ${IconLabel} {
    ${fonts.smallRegular};
  }
`;

const PanelRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const DraggableIconCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.lightBlueGrey};
  border-radius: 5px;
  padding: 8px;
  margin-right: 16px;

  &:hover,
  &:hover svg {
    cursor: pointer;
  }
`;

class Diagram extends PureComponent<Props, State> {
  state = {
    isWrapperMounted: false,
    openEditPanel: false,
    editPanelRecord: null,
  };
  engine: DiagramEngine = null;
  panelStyle = null;
  wrapperRef = React.createRef();

  componentDidMount() {
    this.getEngineProps();
    this.panelStyle = {
      width: '300px',
      height: this.wrapperRef.current
        ? `${this.wrapperRef.current.getBoundingClientRect().height}px`
        : '100%',
    };
    this.setState({ isWrapperMounted: true }, () => {
      this.zoomToFit();
    });
  }

  // currently only works when initially mounted
  // to get this to work dynamically, we must calculate current graph's width and height
  // initial graph width/height values are calculated via dagre
  zoomToFit = () => {
    const model = this.engine.getDiagramModel();
    const canvasWidth = this.engine.canvas.clientWidth;
    const canvasHeight = this.engine.canvas.clientHeight;
    const graphHeight = model.data.graphDimensions.height;
    const graphWidth = model.data.graphDimensions.width;
    const xFactor = canvasWidth / this.engine.canvas.scrollWidth;
    const yFactor = canvasHeight / this.engine.canvas.scrollHeight;
    const zoomFactor = xFactor < yFactor ? xFactor : yFactor;
    const pixelScaleFactor = 1 / zoomFactor;
    const xOffset =
      graphWidth > canvasWidth && yFactor >= xFactor
        ? 0
        : ((canvasWidth * pixelScaleFactor - graphWidth) / 2) * zoomFactor;
    const yOffset =
      graphHeight > canvasHeight && xFactor >= yFactor
        ? 10
        : ((canvasHeight * pixelScaleFactor - graphHeight) / 2) * zoomFactor;

    model.setZoomLevel(model.getZoomLevel() * zoomFactor);
    model.setOffset(xOffset, yOffset);
    this.engine.repaintCanvas();
  };

  handleOpenEditPanel = editPanelRecord => {
    const model = this.engine.getDiagramModel();
    model.clearSelection();

    if (
      editPanelRecord instanceof IconNodeModel ||
      editPanelRecord instanceof CustomLinkModel
    ) {
      model.activeNodeId = editPanelRecord.id;
    }

    this.setState({
      openEditPanel: true,
      editPanelRecord,
    });
  };

  handleCloseEditPanel = (cb?: (record: *) => void) => {
    const { editPanelRecord } = this.state;
    const model = this.engine.getDiagramModel();

    if (
      editPanelRecord &&
      !editPanelRecord.data?.saved &&
      editPanelRecord.type !== 'custom-link'
    ) {
      if (editPanelRecord instanceof IconNodeModel) {
        model.activeNodeId = null;
      }
      editPanelRecord.remove();
    }

    this.setState(
      {
        openEditPanel: false,
        editPanelRecord: null,
      },
      () => {
        if (editPanelRecord instanceof IconNodeModel) {
          model.activeNodeId = null;
          this.engine.repaintCanvas();
        }

        if (cb) {
          cb();
        }
      },
    );
  };

  handleZoomIn = () => {
    const model = this.engine.getDiagramModel();
    model.setZoomLevel(Math.min(200, model.getZoomLevel() + 20));
    this.engine.repaintCanvas();
  };

  handleZoomOut = () => {
    const model = this.engine.getDiagramModel();
    model.setZoomLevel(Math.max(20, model.getZoomLevel() - 20));
    this.engine.repaintCanvas();
  };

  nodeAddListener = (node: IconNodeModel) => {
    node.addListener({
      selectionChanged: selectionChangedEvent => {
        const { openEditPanel, editPanelRecord } = this.state;

        if (
          openEditPanel &&
          editPanelRecord?.id === selectionChangedEvent.entity.id
        ) {
          return;
        } else if (
          selectionChangedEvent.entity.selected &&
          selectionChangedEvent.entity.id === node.id &&
          node.data?.type !== 'JOB_CREATED' &&
          node.data?.type !== 'JOB_COMPLETED'
        ) {
          this.handleCloseEditPanel(() => this.handleOpenEditPanel(node));
        }
      },
    });
  };

  linkAddListener = (link: CustomLinkModel) => {
    link.addListener({
      selectionChanged: ({ entity }) => {
        const { openEditPanel, editPanelRecord } = this.state;

        if (openEditPanel && editPanelRecord?.id === link.id) {
          return;
        } else if (entity.selected && entity.id === link.id) {
          this.handleCloseEditPanel(() => {
            this.handleOpenEditPanel(link);
          });
        }
      },
    });
  };

  getEngineProps = () => {
    const {
      workflow: { states, transitions },
    } = this.props;

    this.engine = new DiagramEngine();
    this.engine.installDefaultFactories();
    this.engine.registerLinkFactory(new CustomLinkFactory());
    this.engine.registerLabelFactory(new CustomLabelFactory());
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'square-job-created',
        config => new IconPortModel('right', 'square-job-created'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'square-job-completed',
        config => new IconPortModel('left', 'square-job-completed'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'circle-run',
        config => new IconPortModel('left', 'circle-run'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'circle-run',
        config => new IconPortModel('right', 'circle-run'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'circle-task',
        config => new IconPortModel('left', 'circle-task'),
      ),
    );
    this.engine.registerPortFactory(
      new SimplePortFactory(
        'circle-task',
        config => new IconPortModel('right', 'circle-task'),
      ),
    );
    this.engine.registerNodeFactory(new IconNodeFactory('square-job-created'));
    this.engine.registerNodeFactory(
      new IconNodeFactory('square-job-completed'),
    );
    this.engine.registerNodeFactory(new IconNodeFactory('circle-run'));
    this.engine.registerNodeFactory(new IconNodeFactory('circle-task'));

    const model = new DiagramModel();
    const nodes = [];
    const links = [];
    const workflowStateIdToNodeHash = {};

    if (states.length === 0) {
      const startNode = new IconNodeModel('square-job-created');
      startNode.data.type = 'JOB_CREATED';
      startNode.data.saved = true;
      startNode.setPosition(250, 250);
      model.addAll(startNode);
    } else {
      for (let i = 0; i < states.length; i++) {
        const state: any = states[i];
        const node = new IconNodeModel(ICON_TYPE_MAP[(state?.type)]);
        node.data.saved = true;
        const stateKeys = Object.keys(state);
        const BASE_KEYS = [
          'defaultUser',
          'isAssignable',
          'isRequired',
          'name',
          'type',
        ];
        const TASK_KEYS = ['form'];
        const RUN_KEYS = [
          'form',
          'isGangRun',
          'trackRunQuantityType',
          'isTrackingSetupQuantity',
          'isPromptingFinalQuantity',
          'machineType',
          'runQuantityPerHour',
          'runMinutesOverride',
          'postRunDelayMinutes',
          'defaultLot',
        ];
        const BOOLEAN_KEYS = [
          'isAssignable',
          'isRequired',
          'isTrackingSetupQuantity',
          'isPromptingFinalQuantity',
          'isGangRun',
        ];

        for (const key of BASE_KEYS) {
          if (key === 'defaultUser' && state[key]) {
            node.data[key] = {
              data: {
                id: state[key].id,
              },
              value: state[key].id,
              label: state[key].firstName + ' ' + state[key].lastName,
            };
          } else {
            node.data[key] = state[key];
          }
        }

        if (state.type === 'TASK') {
          const filteredStateKeys = stateKeys.filter(key =>
            TASK_KEYS.includes(key),
          );

          for (const key of filteredStateKeys) {
            if (key === 'form' && state[key]) {
              node.data[key] = {
                data: {
                  id: state[key].id,
                },
                value: state[key].id,
                label: state[key].label || state[key].name,
              };
            } else if (BOOLEAN_KEYS.includes(key) && state[key] === undefined) {
              node.data[key] = false;
            } else {
              node.data[key] = state[key];
            }
          }
        } else if (state.type === 'RUN') {
          const filteredStateKeys = stateKeys.filter(key =>
            RUN_KEYS.includes(key),
          );

          for (const key of filteredStateKeys) {
            if (['form', 'machineType'].includes(key) && state[key]) {
              node.data[key] = {
                data: {
                  id: state[key].id,
                },
                value: state[key].id,
                label: state[key].name,
              };
            } else if (key === 'trackRunQuantityType' && state[key]) {
              node.data[key] = {
                data: {
                  id: state[key].id,
                },
                value: state[key],
                label: state[key][0] + state[key].slice(1).toLowerCase(),
              };
            } else if (key === 'defaultLot' && state[key]) {
              node.data[key] = {
                data: {
                  id: state[key].id,
                },
                value: state[key].id,
                label: state[key].lotNumber,
              };
            } else if (BOOLEAN_KEYS.includes(key) && state[key] === undefined) {
              node.data[key] = false;
            } else {
              node.data[key] = state[key];
            }
          }
        } else {
          // for JOB_CREATED and JOB_COMPLETED only
          for (const key of BOOLEAN_KEYS) {
            node.data[key] = key === 'isRequired' ? true : false;
          }

          node.data.type = state.type;
        }

        workflowStateIdToNodeHash[state.id] = node;
        nodes.push(node);
      }

      for (const transition of transitions) {
        const fromNode =
          workflowStateIdToNodeHash[transition.fromWorkflowState.id];
        const toNode = workflowStateIdToNodeHash[transition.toWorkflowState.id];
        // connecting nodes from right to left will cause issues
        // need to set toNode and fromNode properly when link is created, on
        const link = fromNode.ports.right.link(toNode.ports.left);

        // TODO: uncomment when Transiion Actions are ready
        // for (const action of transition.actions) {
        //   if (action.type === 'SEND_EMAIL') {
        //     link.data.defaultUser = {
        //       value: action.metadata.defaultUserId,
        //     };
        //   } else if (action.type === 'ADD_JOB_TAG') {
        //     link.data.addTags.push({
        //       value: action.metadata.tagId,
        //     });
        //   } else if (action.type === 'REMOVE_JOB_TAG') {
        //     link.data.removeTags.push({
        //       value: action.metadata.tagId,
        //     });
        //   }
        // }

        // const totalActions =
        //   link.data.addTags.length +
        //   link.data.removeTags.length +
        //   (link.data.defaultUser?.value ? 1 : 0);
        // link.addLabel(totalActions || '+');
        // link.setColor(colors.charcoalGrey);
        // link.setWidth(4);

        links.push(link);
      }

      model.addAll(...nodes, ...links);
    }

    const distributedModel = this.getDistributedModel(this.engine, model);
    const distributedLinks = Object.values(distributedModel.getLinks());
    const distributedNodes = Object.values(distributedModel.getNodes());

    for (let i = 0; i < distributedLinks.length; i++) {
      const link: Object = distributedLinks[i];
      this.linkAddListener(link);
    }
    for (let i = 0; i < distributedNodes.length; i++) {
      const node: Object = distributedNodes[i];
      this.nodeAddListener(node);
    }

    distributedModel.addListener({
      nodesUpdated: ({ node }) => {
        this.nodeAddListener(node);
      },
      linksUpdated: ({ link }) => {
        this.linkAddListener(link);
        link.addListener({
          targetPortChanged: targetPortChangedEvent => {
            if (
              link.sourcePort &&
              link.targetPort &&
              link.sourcePort.parent.id === link.targetPort.parent.id
            ) {
              link.targetPort.removeLink(link);
              link.remove();
              Actions.alertNotification(
                {
                  title: 'Please Try Again',
                  body:
                    'Transitions cannot occur to and from the same Job State.',
                },
                'error',
              );
            } else {
              link.addLabel('+');
              link.setColor(colors.charcoalGrey);
              link.setWidth(4);
            }
          },
        });
      },
    });

    this.engine.setDiagramModel(distributedModel);
  };

  getEditPanelToDisplay = (record: IconNodeModel | CustomLinkModel) => {
    if (record.data.type === 'TASK') {
      return (
        <AddUpdateTaskPanel
          node={record}
          diagramEngine={this.engine}
          onClose={this.handleCloseEditPanel}
          panelStyle={this.panelStyle}
        />
      );
    } else if (record.data.type === 'RUN') {
      return (
        <AddUpdateRunPanel
          node={record}
          diagramEngine={this.engine}
          onClose={this.handleCloseEditPanel}
          panelStyle={this.panelStyle}
        />
      );
    } else {
      return (
        <AddUpdateActionPanel
          link={record}
          diagramEngine={this.engine}
          onClose={this.handleCloseEditPanel}
          panelStyle={this.panelStyle}
        />
      );
    }
  };

  handleSubmit = async (id, name, isDefault) => {
    try {
      const { history } = this.props;
      const model = this.engine.getDiagramModel();

      const updatedWorkflow = await UpdateWorkflowFullMutation.commit({
        variables: {
          input: {
            workflowId: id,
            name,
            isDefault,
            transitions: Object.values(model.links).map((link: Object) => {
              const filteredLink: Object = {
                clientId: link.id,
              };

              if (!link.targetPort || !link.sourcePort) {
                throw new Error(
                  'Please Connect All Transitions Before Publishing',
                );
              }

              // If user links states from right to left, then reorient the direction to left to right
              if (link.targetPort.position === 'left') {
                filteredLink.toClientId = link.targetPort.parent.id;
                filteredLink.fromClientId = link.sourcePort.parent.id;
              } else {
                filteredLink.toClientId = link.sourcePort.parent.id;
                filteredLink.fromClientId = link.targetPort.parent.id;
              }

              if (Object.prototype.hasOwnProperty.call(link, 'data')) {
                if (link.data.addTags && link.data.addTags.length) {
                  filteredLink.addTags = link.data.addTags.map(
                    tag => tag.value,
                  );
                }

                if (link.data.removeTags && link.data.removeTags.length) {
                  filteredLink.removeTags = link.data.removeTags.map(
                    tag => tag.value,
                  );
                }

                if (link.data.defaultUser && link.data.defaultUser.value) {
                  filteredLink.defaultUserId = link.data.defaultUser.value;
                }
              }

              return filteredLink;
            }),
            states: Object.values(model.nodes).map((node: Object) => {
              if (!node.data.saved) {
                throw new Error(
                  'Please Fill Out All Tasks and Runs Before Publishing',
                );
              }

              const {
                defaultUser,
                defaultLot,
                runQuantityPerHour,
                runMinutesOverride,
                postRunDelayMinutes,
                form,
                isRequired,
                isGangRun,
                machineType,
                name,
                type,
                trackRunQuantityType,
                isTrackingSetupQuantity,
                isPromptingFinalQuantity,
                isAssignable,
              } = node.data;

              const filteredNode: Object = {
                clientId: node.id,
                runQuantityPerHour,
                runMinutesOverride,
                postRunDelayMinutes,
                isRequired,
                isGangRun,
                name,
                type,
                trackRunQuantityType,
                isTrackingSetupQuantity,
                isPromptingFinalQuantity,
                isAssignable,
              };

              if (defaultUser) {
                filteredNode.defaultUserId = defaultUser.value;
              }

              if (defaultLot) {
                filteredNode.defaultLotId = defaultLot.value;
              }

              if (form) {
                filteredNode.formId = form.value;
              }

              if (machineType) {
                filteredNode.machineTypeId = machineType.value;
              }

              if (trackRunQuantityType) {
                filteredNode.trackRunQuantityType = trackRunQuantityType.value;
              }

              return filteredNode;
            }),
          },
        },
      });
      Actions.alertNotification(
        {
          title: 'Workflow Successfully Published',
          body: null,
        },
        'success',
      );

      history.replace(
        `/settings/workflow/${updatedWorkflow.updateWorkflowFull.workflowEdge.node.id}`,
      );
    } catch (e) {
      Actions.alertNotification(e.message, 'Something Went Wrong');
    }
  };

  getDistributedModel = (engine, model) => {
    const serialized = model.serializeDiagram();
    const distributedSerializedDiagram = distributeElements(serialized);
    const deSerializedModel = new DiagramModel();

    deSerializedModel.deSerializeDiagram(distributedSerializedDiagram, engine);
    deSerializedModel.data = {
      ...deSerializedModel.data,
      graphDimensions: distributedSerializedDiagram.graphDimensions,
    };

    return deSerializedModel;
  };

  handleZoomIn = () => {
    const model = this.engine.getDiagramModel();
    model.setZoomLevel(model.getZoomLevel() + 20);
    this.engine.repaintCanvas();
  };

  handleZoomOut = () => {
    const model = this.engine.getDiagramModel();
    model.setZoomLevel(model.getZoomLevel() - 20);
    this.engine.repaintCanvas();
  };

  render() {
    const diagramProps = {
      diagramEngine: this.engine,
      maxNumberPointsPerLink: 0,
    };

    const { workflow } = this.props;
    const { isWrapperMounted, openEditPanel, editPanelRecord } = this.state;

    return (
      <>
        <PageHeader
          intro={i18n.t('WORKFLOW')}
          title={workflow.name}
          right={
            <Button
              type="submit"
              theme="blue"
              onClick={() =>
                this.handleSubmit(
                  workflow.id,
                  workflow.name,
                  workflow.isDefault,
                )
              }
            >
              {i18n.t('Publish Workflow')}
            </Button>
          }
        />
        <WorkflowWrapper ref={this.wrapperRef}>
          {isWrapperMounted && (
            <>
              <ZoomIconsWrapper>
                <Icon
                  type="circle-minus-black"
                  onClick={this.handleZoomOut}
                  style={{ marginRight: 20 }}
                />
                <Icon type="circle-plus-black" onClick={this.handleZoomIn} />
              </ZoomIconsWrapper>
              <div
                style={{
                  flexGrow: 1,
                }}
                onDrop={event => {
                  const data = JSON.parse(
                    event.dataTransfer.getData('storm-diagram-node'),
                  );

                  let node = null;

                  if (data.type === 'task') {
                    node = new IconNodeModel('circle-task');
                    node.deSerialize(
                      { ...node.serialize(), data: { type: 'TASK' } },
                      this.engine,
                    );
                  } else if (data.type === 'run') {
                    node = new IconNodeModel('circle-run');
                    node.deSerialize(
                      { ...node.serialize(), data: { type: 'RUN' } },
                      this.engine,
                    );
                  } else if (data.type === 'job-item-count') {
                    node = new IconNodeModel('circle-job-item-count');
                    node.deSerialize(
                      { ...node.serialize(), data: { type: 'JOB_ITEM_COUNT' } },
                      this.engine,
                    );
                  } else {
                    node = new IconNodeModel('square-job-completed');
                    node.deSerialize(
                      {
                        ...node.serialize(),
                        data: { type: 'JOB_COMPLETED', saved: true },
                      },
                      this.engine,
                    );
                  }

                  const points = this.engine.getRelativeMousePoint(event);
                  node.x = points.x;
                  node.y = points.y;

                  this.engine.getDiagramModel().addNode(node);

                  if (
                    data.type === 'run' ||
                    data.type === 'task' ||
                    data.type === 'job-item-count'
                  ) {
                    node.selected = true;
                    this.handleOpenEditPanel(node);
                  } else {
                    this.forceUpdate();
                  }
                }}
                onDragOver={event => {
                  event.preventDefault();
                }}
              >
                <DiagramWidget
                  diagramEngine={this.engine}
                  {...diagramProps}
                  // disables delete on backspace keyUp event
                  deleteKeys={[]}
                />
              </div>
              {isWrapperMounted &&
                (openEditPanel && editPanelRecord ? (
                  this.getEditPanelToDisplay(editPanelRecord)
                ) : (
                  <Panel style={this.panelStyle}>
                    <PanelGrid>
                      <PanelHeader>
                        <Icon
                          type="drag"
                          size={20}
                          style={{
                            marginTop: '6px',
                            marginRight: '12px',
                            alignSelf: 'start',
                          }}
                        />
                        <IconLabel
                          style={{ marginLeft: 4, alignItems: 'center' }}
                        >
                          {i18n.t('Click and Drag')}
                        </IconLabel>
                      </PanelHeader>
                      <PanelRow>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 8,
                            marginBottom: '8px',
                          }}
                        >
                          <Icon type="square-job-created" size={32} />
                          <IconLabel>{i18n.t('Job Created')}</IconLabel>
                        </div>
                        <InfoTooltip
                          id="job-created-tooltip"
                          content={i18n.t(
                            'This is the beginning of the workflow when a job is created for production.',
                          )}
                        />
                      </PanelRow>
                      <PanelRow>
                        <TrayWidget>
                          <TrayItemWidget model={{ type: 'jobCompleted' }}>
                            <DraggableIconCard>
                              <Icon type="square-job-completed" size={32} />
                              <IconLabel>{i18n.t('Job Completed')}</IconLabel>
                            </DraggableIconCard>
                          </TrayItemWidget>
                        </TrayWidget>
                        <InfoTooltip
                          id="job-completed-tooltip"
                          content={i18n.t(
                            'This is the end of the workflow when the job status change from open to closed.',
                          )}
                        />
                      </PanelRow>
                      <PanelRow>
                        <TrayWidget>
                          <TrayItemWidget model={{ type: 'task' }}>
                            <DraggableIconCard>
                              <Icon type="circle-task" size={32} />
                              <IconLabel>{i18n.t('Task')}</IconLabel>
                            </DraggableIconCard>
                          </TrayItemWidget>
                        </TrayWidget>
                        <InfoTooltip
                          id="task-tooltip"
                          content={i18n.t(
                            'Create a task for actions that need to take place outside of the production schedule.',
                          )}
                        />
                      </PanelRow>
                      <PanelRow>
                        <TrayWidget>
                          <TrayItemWidget model={{ type: 'run' }}>
                            <DraggableIconCard>
                              <Icon type="circle-run" size={32} />
                              <IconLabel>{i18n.t('Run')}</IconLabel>
                            </DraggableIconCard>
                          </TrayItemWidget>
                        </TrayWidget>
                        <InfoTooltip
                          id="run-tooltip"
                          content={i18n.t(
                            'Runs are scheduled for production and tracked on work centers.',
                          )}
                        />
                      </PanelRow>
                    </PanelGrid>
                  </Panel>
                ))}
            </>
          )}
        </WorkflowWrapper>
      </>
    );
  }
}

export default createFragmentContainer(Diagram, {
  workflow: graphql`
    fragment Diagram_workflow on Workflow {
      id
      name
      isDefault
      states {
        id
        name
        type
        form {
          id
          name
        }
        machineType {
          id
          name
        }
        runQuantityPerHour
        runMinutesOverride
        postRunDelayMinutes
        isRequired
        isGangRun
        trackRunQuantityType
        isTrackingSetupQuantity
        isPromptingFinalQuantity
        isAssignable
        defaultLot {
          id
          lotNumber
        }
        defaultUser {
          id
          firstName
          lastName
        }
      }
      transitions {
        id
        toWorkflowState {
          id
        }
        fromWorkflowState {
          id
        }
        actions {
          id
          type
          metadata
        }
      }
    }
  `,
});

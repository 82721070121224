/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateCustomerModal_customer$ref = any;
type UserAvatar_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomerList_customerEdges$ref: FragmentReference;
declare export opaque type CustomerList_customerEdges$fragmentType: CustomerList_customerEdges$ref;
export type CustomerList_customerEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +customerNumber: string,
    +name: string,
    +openOrders: number,
    +salesUser: ?{|
      +$fragmentRefs: UserAvatar_user$ref
    |},
    +$fragmentRefs: AddUpdateCustomerModal_customer$ref,
  |},
  +$refType: CustomerList_customerEdges$ref,
|}>;
export type CustomerList_customerEdges$data = CustomerList_customerEdges;
export type CustomerList_customerEdges$key = {
  +$data?: CustomerList_customerEdges$data,
  +$fragmentRefs: CustomerList_customerEdges$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CustomerList_customerEdges",
  "type": "CustomerEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Customer",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "customerNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "openOrders",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "salesUser",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "UserAvatar_user",
              "args": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateCustomerModal_customer",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6c9a4f4e49523d1a2edc268f58ddd993';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation AddItemCategoryMutation($input: AddItemCategoryInput!) {
    addItemCategory(input: $input) {
      itemCategoryEdge {
        node {
          id
          name
          description
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

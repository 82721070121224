// @flow

import { graphql } from 'react-relay';
import createRelaySubscription from 'shared/gql/createRelaySubscription';

const subscription = graphql`
  subscription JobUpdatedSubscription($id: ID!) {
    jobUpdated(id: $id) {
      id
      name
    }
  }
`;

export default {
  commit: createRelaySubscription.bind(null, subscription),
};

/**
 * @flow
 * @relayHash adc769a53fea58dc82d576a501e1bf93
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteItemChildInput = {|
  parentItemId: string,
  childItemId: string,
|};
export type DeleteItemChildMutationVariables = {|
  input: DeleteItemChildInput
|};
export type DeleteItemChildMutationResponse = {|
  +deleteItemChild: {|
    +deletedItemId: string
  |}
|};
export type DeleteItemChildMutation = {|
  variables: DeleteItemChildMutationVariables,
  response: DeleteItemChildMutationResponse,
|};
*/


/*
mutation DeleteItemChildMutation(
  $input: DeleteItemChildInput!
) {
  deleteItemChild(input: $input) {
    deletedItemId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteItemChildInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteItemChild",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteItemChildPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedItemId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteItemChildMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteItemChildMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteItemChildMutation",
    "id": null,
    "text": "mutation DeleteItemChildMutation(\n  $input: DeleteItemChildInput!\n) {\n  deleteItemChild(input: $input) {\n    deletedItemId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '52d30edc6376de7680f9964197264e4c';
module.exports = node;

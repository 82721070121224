// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation AddMachineTypeMutation($input: AddMachineTypeInput!) {
    addMachineType(input: $input) {
      machineTypeEdge {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export default {
  commit: (options: *) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: ROOT_ID,
          connectionInfo: [
            {
              key: 'MachineTypes_machineTypes',
              rangeBehavior: 'prepend',
            },
          ],
          edgeName: 'machineTypeEdge',
        },
      ],
    });
  },
};

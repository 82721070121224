/**
 * @flow
 * @relayHash f482f8edecad7242f0f7a68c1fd9f14d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddItemMovementModalQueryVariables = {|
  lotNumbers?: ?$ReadOnlyArray<string>
|};
export type AddItemMovementModalQueryResponse = {|
  +lots: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string
      |}
    |}>
  |}
|};
export type AddItemMovementModalQuery = {|
  variables: AddItemMovementModalQueryVariables,
  response: AddItemMovementModalQueryResponse,
|};
*/


/*
query AddItemMovementModalQuery(
  $lotNumbers: [String!]
) {
  lots(first: 1, lotNumbers: $lotNumbers) {
    edges {
      node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "lotNumbers",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "lots",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      },
      {
        "kind": "Variable",
        "name": "lotNumbers",
        "variableName": "lotNumbers"
      }
    ],
    "concreteType": "LotConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "LotEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Lot",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddItemMovementModalQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddItemMovementModalQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "AddItemMovementModalQuery",
    "id": null,
    "text": "query AddItemMovementModalQuery(\n  $lotNumbers: [String!]\n) {\n  lots(first: 1, lotNumbers: $lotNumbers) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '489894031ee9307a519a13b090f9bedf';
module.exports = node;

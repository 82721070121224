// @flow

import React from 'react';
import styled from 'styled-components';
import { fonts, linkStyles } from 'shared/styleguide';

const StyledButton = styled.button`
  ${linkStyles};
  ${fonts.bodyBold};
  font-size: inherit;
  display: inline;
  padding: 0;
  margin: 0;
  min-width: auto;
  width: auto;
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  children: string,
  onClick: (SyntheticEvent<any>) => void,
  style?: Object,
  type?: string,
};

const ButtonLink = ({ onClick, children, style, type }: Props) => (
  <StyledButton onClick={onClick} style={style} type={type}>
    {children}
  </StyledButton>
);

ButtonLink.defaultProps = {
  style: undefined,
  type: undefined,
};

export default ButtonLink;

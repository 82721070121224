/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeleteCustomerModal_customer$ref: FragmentReference;
declare export opaque type DeleteCustomerModal_customer$fragmentType: DeleteCustomerModal_customer$ref;
export type DeleteCustomerModal_customer = {|
  +id: string,
  +name: string,
  +$refType: DeleteCustomerModal_customer$ref,
|};
export type DeleteCustomerModal_customer$data = DeleteCustomerModal_customer;
export type DeleteCustomerModal_customer$key = {
  +$data?: DeleteCustomerModal_customer$data,
  +$fragmentRefs: DeleteCustomerModal_customer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DeleteCustomerModal_customer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '24b0b0aba1562a029ce3ff566be4f403';
module.exports = node;

/**
 * @flow
 * @relayHash b2d7c2048d95e0db94303950a62685e6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddIntegrationModalQueryVariables = {||};
export type AddIntegrationModalQueryResponse = {|
  +integrationSources: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +description: ?string,
        +logo: ?string,
      |}
    |}>
  |}
|};
export type AddIntegrationModalQuery = {|
  variables: AddIntegrationModalQueryVariables,
  response: AddIntegrationModalQueryResponse,
|};
*/


/*
query AddIntegrationModalQuery {
  integrationSources {
    edges {
      node {
        id
        name
        description
        logo
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "integrationSources",
    "storageKey": null,
    "args": null,
    "concreteType": "IntegrationSourceConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "IntegrationSourceEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "IntegrationSource",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "logo",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddIntegrationModalQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddIntegrationModalQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "AddIntegrationModalQuery",
    "id": null,
    "text": "query AddIntegrationModalQuery {\n  integrationSources {\n    edges {\n      node {\n        id\n        name\n        description\n        logo\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b77faf39a47076e0dba93cfb593a391';
module.exports = node;

// @flow

import {
  LinkModel,
  LabelModel,
  BaseEvent,
  DiagramEngine,
} from 'storm-react-diagrams';
import { colors } from 'shared/styleguide';
import { CustomLabelModel } from '../CustomLabel';

class CustomLinkModel extends LinkModel {
  constructor() {
    super('custom-link');
    this.width = 4;
    this.curvyness = 50;
    this.color = colors.lightBlueGrey;
    this.data = {
      addTags: [],
      removeTags: [],
      defaultUser: {},
    };
  }

  serialize() {
    return {
      ...super.serialize(),
      width: this.width,
      color: this.color,
      curvyness: this.curvyness,
      data: this.data,
    };
  }

  deSerialize(ob: CustomLinkModel, engine: DiagramEngine) {
    super.deSerialize(ob, engine);
    this.width = ob.width;
    this.curvyness = ob.curvyness;
    this.color = ob.color;
    this.data = ob.data;
  }

  addLabel(label: LabelModel | string) {
    if (label instanceof LabelModel) {
      return super.addLabel(label);
    }
    const labelOb = new CustomLabelModel();
    labelOb.setLabel(label);
    return super.addLabel(labelOb);
  }

  removeLabels() {
    this.labels = [];
  }

  setWidth(width: number) {
    this.width = width;
    this.iterateListeners((listener: *, event: BaseEvent) => {
      if (listener.widthChanged) {
        listener.widthChanged({ ...event, width: width });
      }
    });
  }

  setColor(color: string) {
    this.color = color;
    this.iterateListeners((listener: *, event: BaseEvent) => {
      if (listener.colorChanged) {
        listener.colorChanged({ ...event, color: color });
      }
    });
  }
}

export default CustomLinkModel;

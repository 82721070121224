// @flow

import React from 'react';
import styled from 'styled-components';
import Panel from 'shared/components/common/Panel';
import { i18n } from 'shared/utils';
import moment from 'moment';
import { DateRangePicker } from 'shared/components/form';
import type { RouterHistory } from 'react-router';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import MachinePerformanceRates from './MachinePerformanceRates';
import AvailabilityFactors from './AvailabilityFactors';
import OEE from './OEE';

type Props = {
  history: RouterHistory,
  oeeStartAt: string,
  oeeEndAt: string,
  setFilters: (RouterHistory, Object) => void,
};

const Row = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
`;

const LeftColumn = styled.div`
  @media (min-width: 1000px) {
    flex: 1;
    width: 50%;
  }
`;

const RightColumn = styled.div`
  padding-top: 24px;

  @media (min-width: 1000px) {
    padding-top: 0;
    flex: 1;
    width: 50%;
    padding-left: 24px;
  }
`;

export default ({ history, oeeStartAt, oeeEndAt, setFilters }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    cacheConfig={{
      force: true,
    }}
    query={graphql`
      query OEEPanelQuery($oeeStartAt: DateTime!, $oeeEndAt: DateTime!) {
        metricsOEE(startAt: $oeeStartAt, endAt: $oeeEndAt) {
          oee
          machineAvailabilityRate
          machinePerformanceRate
          qualityRate
        }

        metricsMachineDowntimes(startAt: $oeeStartAt, endAt: $oeeEndAt) {
          id
          name
          percentage
        }

        metricsMachinePerformanceRates(startAt: $oeeStartAt, endAt: $oeeEndAt) {
          id
          name
          percentage
        }
      }
    `}
    variables={{
      oeeStartAt,
      oeeEndAt,
    }}
    render={query => {
      if (!query.props) {
        return (
          <Panel style={{ height: 873 }}>
            <Loader />
          </Panel>
        );
      }

      return (
        <Panel
          title={i18n.t('OEE Stats')}
          headerRight={
            <DateRangePicker
              value={{
                startDate: moment(oeeStartAt),
                endDate: moment(oeeEndAt),
              }}
              onChange={({ startDate, endDate }) =>
                setFilters(history, {
                  oeeStartAt: moment(startDate)
                    .startOf('day')
                    .format(),
                  oeeEndAt: moment(endDate)
                    .endOf('day')
                    .format(),
                })
              }
            />
          }
        >
          <OEE
            oee={query.props.metricsOEE.oee}
            machineAvailabilityRate={
              query.props.metricsOEE.machineAvailabilityRate
            }
            machinePerformanceRate={
              query.props.metricsOEE.machinePerformanceRate
            }
            qualityRate={query.props.metricsOEE.qualityRate}
          />
          <Row>
            <LeftColumn>
              <AvailabilityFactors
                machineDowntimes={query.props.metricsMachineDowntimes}
              />
            </LeftColumn>
            <RightColumn>
              <MachinePerformanceRates
                machinePerformanceRates={
                  query.props.metricsMachinePerformanceRates
                }
              />
            </RightColumn>
          </Row>
        </Panel>
      );
    }}
  />
);

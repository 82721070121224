/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateMachineTypeModal_machineType$ref: FragmentReference;
declare export opaque type AddUpdateMachineTypeModal_machineType$fragmentType: AddUpdateMachineTypeModal_machineType$ref;
export type AddUpdateMachineTypeModal_machineType = {|
  +id: string,
  +name: string,
  +$refType: AddUpdateMachineTypeModal_machineType$ref,
|};
export type AddUpdateMachineTypeModal_machineType$data = AddUpdateMachineTypeModal_machineType;
export type AddUpdateMachineTypeModal_machineType$key = {
  +$data?: AddUpdateMachineTypeModal_machineType$data,
  +$fragmentRefs: AddUpdateMachineTypeModal_machineType$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AddUpdateMachineTypeModal_machineType",
  "type": "MachineType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f073c04c5abe578d8a2af95219c6263d';
module.exports = node;

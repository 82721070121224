// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation AddMachineMutation($input: AddMachineInput!) {
    addMachine(input: $input) {
      machineEdge {
        cursor
        node {
          id
          name
          type {
            id
            name
          }
          purchaseCost
          lifespanYears
          runQuantityPerHour
          runCostPerHour
          updatedAt
          createdAt
          workflows {
            id
            name
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: *) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: ROOT_ID,
          connectionInfo: [
            {
              key: 'Machines_machines',
              rangeBehavior: 'append',
            },
          ],
          edgeName: 'machineEdge',
        },
      ],
    });
  },
};

// @flow

import React from 'react';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';

export default () => (
  <Page>
    <PageHeader title="Help" />
  </Page>
);

// @flow

import React from 'react';
import { i18n } from 'shared/utils';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import styled from 'styled-components';
import { fonts } from 'shared/styleguide';
import Loader from 'shared/components/common/Loader';
import Panel from 'shared/components/common/Panel';

type Props = {
  transitionId: ?string,
};

const SectionTitle = styled.div`
  ${fonts.largeBold};
`;

const TransitionDetails = ({ transitionId }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    dataFrom="STORE_THEN_NETWORK"
    variables={{
      id: transitionId,
    }}
    query={graphql`
      query TransitionDetailsQuery($id: ID!) {
        transition(id: $id) {
          id
          actions {
            id
            type
            metadata
            metadataRecord
          }
        }
      }
    `}
    render={({ props }: *) => {
      if (!props) {
        return (
          <Panel
            title={i18n.t('Transition Details')}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '240px',
              height: '240px',
              zIndex: 5,
              paddingBottom: '80px',
            }}
          >
            <Loader />
          </Panel>
        );
      }

      if (props.transition?.actions?.length) {
        const addTags = [];
        const removeTags = [];
        const sendEmails = [];
        const content = [];

        for (const action of props.transition.actions) {
          if (action.type === 'ADD_JOB_TAG') {
            addTags.push(
              <div key={action.metadataRecord.tagId}>
                {action.metadataRecord.name}
              </div>,
            );
          } else if (action.type === 'REMOVE_JOB_TAG') {
            removeTags.push(
              <div key={action.metadataRecord.tagId}>
                {action.metadataRecord.name}
              </div>,
            );
          } else if (action.type === 'SEND_EMAIL') {
            sendEmails.push(
              <div key={action.metadataRecord.userId}>
                {action.metadataRecord.firstName +
                  ' ' +
                  action.metadataRecord.lastName}
              </div>,
            );
          }
        }

        if (addTags.length) {
          content.push(
            <SectionTitle key={(transitionId || ' ') + 'add-tags'}>
              {i18n.t('Add Tags:')}
            </SectionTitle>,
          );
          content.push(...addTags);
        }

        if (removeTags.length) {
          if (addTags.length) {
            content.push(
              <br
                key={(transitionId || '-') + 'add-tags-to-remove-tags-break'}
              />,
            );
          }
          content.push(
            <SectionTitle key={(transitionId || '-') + 'remove-tags'}>
              {i18n.t('Remove Tags:')}
            </SectionTitle>,
          );
          content.push(...removeTags);
        }

        if (sendEmails.length) {
          if (addTags.length || removeTags.length) {
            content.push(
              <br key={(transitionId || '-') + 'tags-to-emails-break'} />,
            );
          }
          content.push(
            <SectionTitle key={(transitionId || '-') + 'send-emails'}>
              {i18n.t('Send Emails:')}
            </SectionTitle>,
          );
          content.push(...sendEmails);
        }

        return (
          <Panel
            title={i18n.t('Transition Details')}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '240px',
              zIndex: 5,
            }}
          >
            {content}
          </Panel>
        );
      }

      return null;
    }}
  />
);

export default TransitionDetails;

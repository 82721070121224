// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateOrderYieldMutation($input: UpdateOrderYieldInput!) {
    updateOrderYield(input: $input) {
      orderYieldEdge {
        cursor
        node {
          id
          orderedQuantity
          item {
            id
            itemNumber
            name
            partNumber
            description
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

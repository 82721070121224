/**
 * @flow
 * @relayHash 0c491e980939bfacda7b894900c76b32
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddUpdateCalendarEventModal_companyCalendarEvent$ref = any;
type OperatingHoursModal_company$ref = any;
export type OperatingHoursQueryVariables = {||};
export type OperatingHoursQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +company: {|
      +id: string,
      +sundayHoursStart: ?string,
      +sundayHoursEnd: ?string,
      +mondayHoursStart: ?string,
      +mondayHoursEnd: ?string,
      +tuesdayHoursStart: ?string,
      +tuesdayHoursEnd: ?string,
      +wednesdayHoursStart: ?string,
      +wednesdayHoursEnd: ?string,
      +thursdayHoursStart: ?string,
      +thursdayHoursEnd: ?string,
      +fridayHoursStart: ?string,
      +fridayHoursEnd: ?string,
      +saturdayHoursStart: ?string,
      +saturdayHoursEnd: ?string,
      +calendarEvents: $ReadOnlyArray<{|
        +id: string,
        +name: string,
        +isFullDay: boolean,
        +isOpen: boolean,
        +startAt: any,
        +endAt: any,
        +$fragmentRefs: AddUpdateCalendarEventModal_companyCalendarEvent$ref,
      |}>,
      +$fragmentRefs: OperatingHoursModal_company$ref,
    |},
  |}
|};
export type OperatingHoursQuery = {|
  variables: OperatingHoursQueryVariables,
  response: OperatingHoursQueryResponse,
|};
*/


/*
query OperatingHoursQuery {
  viewer {
    id
    company {
      id
      sundayHoursStart
      sundayHoursEnd
      mondayHoursStart
      mondayHoursEnd
      tuesdayHoursStart
      tuesdayHoursEnd
      wednesdayHoursStart
      wednesdayHoursEnd
      thursdayHoursStart
      thursdayHoursEnd
      fridayHoursStart
      fridayHoursEnd
      saturdayHoursStart
      saturdayHoursEnd
      calendarEvents {
        id
        name
        isFullDay
        isOpen
        startAt
        endAt
        ...AddUpdateCalendarEventModal_companyCalendarEvent
      }
      ...OperatingHoursModal_company
    }
  }
}

fragment AddUpdateCalendarEventModal_companyCalendarEvent on CompanyCalendarEvent {
  id
  name
  isFullDay
  isOpen
  startAt
  endAt
}

fragment OperatingHoursModal_company on Company {
  id
  sundayHoursStart
  sundayHoursEnd
  mondayHoursStart
  mondayHoursEnd
  tuesdayHoursStart
  tuesdayHoursEnd
  wednesdayHoursStart
  wednesdayHoursEnd
  thursdayHoursStart
  thursdayHoursEnd
  fridayHoursStart
  fridayHoursEnd
  saturdayHoursStart
  saturdayHoursEnd
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sundayHoursStart",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sundayHoursEnd",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mondayHoursStart",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mondayHoursEnd",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tuesdayHoursStart",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tuesdayHoursEnd",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wednesdayHoursStart",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "wednesdayHoursEnd",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "thursdayHoursStart",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "thursdayHoursEnd",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fridayHoursStart",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fridayHoursEnd",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saturdayHoursStart",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saturdayHoursEnd",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isFullDay",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isOpen",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startAt",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "endAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OperatingHoursQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "calendarEvents",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyCalendarEvent",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "AddUpdateCalendarEventModal_companyCalendarEvent",
                    "args": null
                  }
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "OperatingHoursModal_company",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OperatingHoursQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "company",
            "storageKey": null,
            "args": null,
            "concreteType": "Company",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "calendarEvents",
                "storageKey": null,
                "args": null,
                "concreteType": "CompanyCalendarEvent",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OperatingHoursQuery",
    "id": null,
    "text": "query OperatingHoursQuery {\n  viewer {\n    id\n    company {\n      id\n      sundayHoursStart\n      sundayHoursEnd\n      mondayHoursStart\n      mondayHoursEnd\n      tuesdayHoursStart\n      tuesdayHoursEnd\n      wednesdayHoursStart\n      wednesdayHoursEnd\n      thursdayHoursStart\n      thursdayHoursEnd\n      fridayHoursStart\n      fridayHoursEnd\n      saturdayHoursStart\n      saturdayHoursEnd\n      calendarEvents {\n        id\n        name\n        isFullDay\n        isOpen\n        startAt\n        endAt\n        ...AddUpdateCalendarEventModal_companyCalendarEvent\n      }\n      ...OperatingHoursModal_company\n    }\n  }\n}\n\nfragment AddUpdateCalendarEventModal_companyCalendarEvent on CompanyCalendarEvent {\n  id\n  name\n  isFullDay\n  isOpen\n  startAt\n  endAt\n}\n\nfragment OperatingHoursModal_company on Company {\n  id\n  sundayHoursStart\n  sundayHoursEnd\n  mondayHoursStart\n  mondayHoursEnd\n  tuesdayHoursStart\n  tuesdayHoursEnd\n  wednesdayHoursStart\n  wednesdayHoursEnd\n  thursdayHoursStart\n  thursdayHoursEnd\n  fridayHoursStart\n  fridayHoursEnd\n  saturdayHoursStart\n  saturdayHoursEnd\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3fab08ccf9152546ecd213f9afec14ed';
module.exports = node;

// @flow

import React from 'react';
import styled from 'styled-components';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Draggable,
  type DraggableProvided,
  type DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { colors } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import Icon from 'shared/components/common/Icon';
import dragIcon from './img/dragIcon.svg';
import type { FormFieldCard_formField as FormFieldFragment } from './__generated__/FormFieldCard_formField';
import FormFieldForm from './FormFieldForm';

type Props = {
  index: number,
  formField: FormFieldFragment,
  onDelete: Object => void,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid ${colors.lightBlueGrey};
  background-color: ${colors.white};
  margin-bottom: 16px;
  box-shadow: ${p =>
    p.isDragging ? '0 2px 4px 0 rgba(66, 68, 76, 0.2)' : 'none'};
`;

const DragIcon = styled(dragIcon)`
  width: 14px;
  height: 20px;
  margin-right: 16px;
  margin-top: 16px;
`;

const menuTriggerRefs = {};

const FormFieldCard = ({ index, formField, onDelete }: Props) => (
  <>
    <ContextMenuTrigger
      id={`form-field-menu-${formField.id}`}
      ref={r => (menuTriggerRefs[formField.id] = r)}
    >
      <Draggable draggableId={formField.id} index={index}>
        {(
          dragProvided: DraggableProvided,
          dragSnapshot: DraggableStateSnapshot,
        ) => (
          <Wrapper
            isDragging={dragSnapshot.isDragging}
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
          >
            <DragIcon />
            <FormFieldForm formField={formField} />
            <Icon
              type="circle-context-menu"
              size={24}
              onClick={e => {
                if (menuTriggerRefs[formField.id]) {
                  menuTriggerRefs[formField.id].handleContextClick(e);
                }
              }}
            />
          </Wrapper>
        )}
      </Draggable>
    </ContextMenuTrigger>
    <ContextMenu id={`form-field-menu-${formField.id}`}>
      <MenuItem onClick={() => onDelete(formField)}>
        {i18n.t('Delete')}
      </MenuItem>
    </ContextMenu>
  </>
);

export default createFragmentContainer(FormFieldCard, {
  formField: graphql`
    fragment FormFieldCard_formField on FormField {
      id
      ...FormFieldForm_formField
      ...DeleteFormFieldModal_formField
    }
  `,
});

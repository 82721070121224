// @flow

import React from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddAPIKeyMutation from 'main-app/mutations/AddAPIKey';
import UpdateAPIKeyMutation from 'main-app/mutations/UpdateAPIKey';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  SelectUserField,
} from 'shared/components/form';
import type { AddUpdateAPIKeyModal_apiKey as LineFragment } from './__generated__/AddUpdateAPIKeyModal_apiKey';

type Props = {
  onClose: () => void,
  apiKey: ?LineFragment,
};

const AddUpdateAPIKeyModal = ({ apiKey, onClose }: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        name: apiKey?.name || '',
        actingUser: apiKey?.actingUser
          ? {
              value: apiKey?.actingUser.id,
            }
          : null,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        actingUser: Yup.object()
          .nullable()
          .required('Required'),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        const mutation = apiKey ? UpdateAPIKeyMutation : AddAPIKeyMutation;

        const input: any = {
          name: values.name,
          actingUserId: values.actingUser.value,
        };

        if (apiKey) {
          input.id = apiKey.id;
        }

        try {
          await mutation.commit({
            variables: {
              input,
            },
          });

          onClose();
          Analytics.trackEvent(`${apiKey ? 'Update' : 'Create'} API Key`, {
            apiKeyName: values.name,
          });
          Actions.alertNotification(
            i18n.t(`API Key Successfully ${apiKey ? 'Updated' : 'Added'}`),
            'success',
          );
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader
            header={i18n.t(apiKey ? 'Edit API Key' : 'Create API Key')}
            onClose={onClose}
          />
          <ModalBody withPadding>
            <FieldGroup
              name="name"
              label={i18n.t('API Key Name')}
              placeholder={i18n.t('Website Service Key')}
              error={errors.name}
            />
            <SelectUserField
              name="actingUser"
              label={i18n.t('API User')}
              description={i18n.t(
                'API Keys must be associated with a user. The API Key will reflect the permissions of the user you select.',
              )}
              placeholder={i18n.t('Select one...')}
              error={errors.actingUser}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t(apiKey ? 'Update API Key' : 'Create API Key')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(AddUpdateAPIKeyModal, {
  apiKey: graphql`
    fragment AddUpdateAPIKeyModal_apiKey on APIKey {
      id
      name
      actingUser {
        id
      }
      createdAt
    }
  `,
});

// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import UpdateViewer from 'main-app/mutations/UpdateViewer';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
};

const fieldStyle = {
  width: '310px',
};

const UpdateViewerPasswordModal = ({ onClose, onSuccess }: Props) => {
  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required('Required'),
          newPassword: Yup.string()
            .required('Required')
            .min(8, 'Password should be 8 chars minimum.')
            .oneOf([Yup.ref('confirmPassword')], 'Passwords do not match'),
          confirmPassword: Yup.string()
            .required('Required')
            .min(8, 'Password should be 8 chars minimum.')
            .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const input: any = {
            currentPassword: values.currentPassword || '',
            // New password
            password: values.confirmPassword || '',
          };

          try {
            const response = await UpdateViewer.commit({
              variables: {
                input,
              },
            });

            const updatedUser = response.user;

            onClose();

            if (onSuccess) {
              onSuccess(updatedUser);
            }

            Analytics.trackEvent('Update Profile', {
              password: 'updated',
            });
            Actions.alertNotification(
              i18n.t('Your Password Has Been Updated!'),
              'success',
            );
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <ModalHeader header={i18n.t('Update Password')} onClose={onClose} />
            <ModalBody withPadding>
              <FieldGroup
                name="currentPassword"
                style={fieldStyle}
                type="password"
                label={i18n.t('Current Password')}
                placeholder={i18n.t('Current Password')}
                error={errors.currentPassword}
              />
              <FieldGroup
                name="newPassword"
                style={fieldStyle}
                type="password"
                label={i18n.t('New Password (8+ characters)')}
                placeholder={i18n.t('New Password')}
                error={errors.newPassword}
              />
              <FieldGroup
                name="confirmPassword"
                style={fieldStyle}
                type="password"
                label={i18n.t('Retype New Password (8+ characters)')}
                placeholder={i18n.t('New Password')}
                error={errors.confirmPassword}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                theme="blue"
                disabled={!isValid}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {i18n.t('Update Password')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

UpdateViewerPasswordModal.defaultProps = {
  onSuccess: undefined,
};

export default UpdateViewerPasswordModal;

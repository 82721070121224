/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeleteOrderYieldModal_orderYield$ref: FragmentReference;
declare export opaque type DeleteOrderYieldModal_orderYield$fragmentType: DeleteOrderYieldModal_orderYield$ref;
export type DeleteOrderYieldModal_orderYield = {|
  +id: string,
  +item: {|
    +id: string,
    +name: string,
  |},
  +$refType: DeleteOrderYieldModal_orderYield$ref,
|};
export type DeleteOrderYieldModal_orderYield$data = DeleteOrderYieldModal_orderYield;
export type DeleteOrderYieldModal_orderYield$key = {
  +$data?: DeleteOrderYieldModal_orderYield$data,
  +$fragmentRefs: DeleteOrderYieldModal_orderYield$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DeleteOrderYieldModal_orderYield",
  "type": "OrderYield",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "item",
      "storageKey": null,
      "args": null,
      "concreteType": "Item",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '59658651e8b7371348cc9de98be62cd8';
module.exports = node;

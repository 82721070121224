// @flow

import { i18n } from 'shared/utils';

export default () => [
  {
    label: i18n.t('Job # · Low to High'),
    value: { field: 'JOB_NUMBER', direction: 'ASC' },
  },
  {
    label: i18n.t('Job # · High to Low'),
    value: { field: 'JOB_NUMBER', direction: 'DESC' },
  },
  {
    label: i18n.t('Customer · A to Z'),
    value: { field: 'CUSTOMER', direction: 'ASC' },
  },
  {
    label: i18n.t('Customer · Z to A'),
    value: { field: 'CUSTOMER', direction: 'DESC' },
  },
  {
    label: i18n.t('Status · A to Z'),
    value: { field: 'STATUS', direction: 'ASC' },
  },
  {
    label: i18n.t('Status · Z to A'),
    value: { field: 'STATUS', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Modified · Oldest'),
    value: { field: 'UPDATED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Modified · Most Recent'),
    value: { field: 'UPDATED_AT', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Created - Oldest'),
    value: { field: 'CREATED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Created - Most Recent'),
    value: { field: 'CREATED_AT', direction: 'DESC' },
  },
];

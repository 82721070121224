// @flow

import React, { PureComponent } from 'react';
import { Link, type RouterHistory } from 'react-router-dom';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import Icon from 'shared/components/common/Icon';
import debounce from 'lodash.debounce';

const MORE_TAB_WIDTH = 56;

type Tab = {
  label: string,
  path: string,
  position?: 'left' | 'right',
};

type Props = {
  history?: RouterHistory,
  onTabClick?: Tab => void,
  tabs: Array<Tab>,
  activePath: ?string,
};

type State = {
  displayFirstNTabs: number,
};

const MobileWrapper = styled.div`
  display: block;
  padding-bottom: 16px;

  @media (min-width: 1000px) {
    display: none;
  }
`;

const MobileActiveTab = styled.div`
  border-bottom: 2px solid ${colors.azul};
  padding: 16px 32px 16px 16px;
  ${fonts.bodyBold};
  color: ${colors.charcoalGrey};
  text-align: center;
`;

const Caret = styled.span`
  position: absolute;
  right: 16px;
  color: ${colors.coolGrey};
`;

const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 1000px) {
    border-bottom: 3px solid ${colors.paleGrey};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
  }
`;

const DesktopTab = styled.div`
  /* Negative bottom margin isused to overlap the bottom-border with the DesktopWrapper's bottom border */
  margin: ${p => (p.rightJustified ? '0 0 -3px 40px' : '0 32px -3px 0')};
  white-space: nowrap;

  &:last-of-type {
    margin-right: 0;
  }

  a {
    display: block;
    ${fonts.bodyBold};
    color: ${colors.charcoalGrey};
    padding-bottom: 8px;
    border-bottom: 3px solid ${p => (p.active ? colors.azul : 'transparent')};

    &:visited {
      color: ${colors.charcoalGrey};
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const getTotalElementWidth = (el: *) => {
  const computedStyle = window.getComputedStyle(el);

  return (
    parseInt(computedStyle.width) +
    parseInt(computedStyle.marginLeft) +
    parseInt(computedStyle.marginRight) +
    parseInt(computedStyle.paddingLeft) +
    parseInt(computedStyle.paddingRight) +
    parseInt(computedStyle.borderLeft) +
    parseInt(computedStyle.borderRight)
  );
};

const MoreTabLabel = styled.div`
  display: flex;
  align-items: center;

  & svg:hover {
    cursor: pointer;
  }
`;

class TabNav extends PureComponent<Props, State> {
  static defaultProps = {
    history: undefined,
    onTabClick: undefined,
  };

  menuTriggerRefMobile = null;
  menuTriggerRefDesktop = null;
  desktopWrapper: any = null;
  desktopTabs: any = {};

  constructor(props: Props) {
    super(props);
    const { tabs } = this.props;

    this.state = {
      displayFirstNTabs: tabs.length,
    };

    this.resizeTabs = debounce(this.resizeTabs, 200);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeTabs);
    this.resizeTabs();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeTabs);
  }

  resizeTabs = () => {
    const { tabs } = this.props;
    this.setState(
      {
        displayFirstNTabs: tabs.length,
      },
      () => {
        const wrapperWidth = getTotalElementWidth(this.desktopWrapper);
        const tabWidths = Object.keys(this.desktopTabs).map(key =>
          getTotalElementWidth(this.desktopTabs[key]),
        );
        let availableWidth = wrapperWidth;
        let displayFirstNTabs = 0;

        for (let i = 0; i < tabWidths.length; i++) {
          if (availableWidth - tabWidths[i] - MORE_TAB_WIDTH >= 0) {
            availableWidth -= tabWidths[i];
            displayFirstNTabs++;
          } else if (availableWidth - tabWidths[i] - MORE_TAB_WIDTH < 0) {
            break;
          }
        }

        this.setState({
          displayFirstNTabs,
        });
      },
    );
  };

  render() {
    const { history, tabs, activePath, onTabClick } = this.props;
    const { displayFirstNTabs } = this.state;
    const activeTab = tabs.find(tab => tab.path === activePath);
    const leftJustifiedTabs = [];
    const rightJustifiedTabs = [];
    const desktopContextMenuTabs = [];
    const contextMenuId = `tab-nav-menu-${tabs.map(a => a.label).join('')}`;
    const handleTabClick = onTabClick || (t => {});
    let tabsToDisplay = displayFirstNTabs;

    tabs.forEach((tab, i) => {
      if (activePath === tab.path && i >= displayFirstNTabs) {
        leftJustifiedTabs.unshift(tab);
      } else if (tab.position === 'right') {
        rightJustifiedTabs.push(tab);
      } else {
        leftJustifiedTabs.push(tab);
      }
    });

    return (
      <>
        <MobileWrapper>
          <ContextMenuTrigger
            ref={r => (this.menuTriggerRefMobile = r)}
            id={contextMenuId}
          >
            <MobileActiveTab
              onClick={e => {
                if (this.menuTriggerRefMobile) {
                  this.menuTriggerRefMobile.handleContextClick(e);
                }
              }}
            >
              {activeTab?.label} <Caret>&#9660;</Caret>
            </MobileActiveTab>
          </ContextMenuTrigger>
        </MobileWrapper>
        <DesktopWrapper ref={r => (this.desktopWrapper = r)}>
          {leftJustifiedTabs.map(tab => {
            if (tabsToDisplay > 0) {
              tabsToDisplay--;
              return (
                <DesktopTab
                  key={tab.path + tab.label}
                  active={activeTab && activeTab.path === tab.path}
                  ref={r =>
                    (this.desktopTabs[tab.label.replace(/\s/g, '-')] = r)
                  }
                >
                  {history ? (
                    <Link to={tab.path}>{tab.label}</Link>
                  ) : (
                    <a href="#" onClick={() => handleTabClick(tab)}>
                      {tab.label}
                    </a>
                  )}
                </DesktopTab>
              );
            } else {
              desktopContextMenuTabs.push(tab);
            }
          })}
          {Boolean(rightJustifiedTabs.length) && <div style={{ flex: 1 }} />}
          {rightJustifiedTabs.map(tab => {
            if (tabsToDisplay > 0) {
              tabsToDisplay--;
              return (
                <DesktopTab
                  key={tab.path + tab.label}
                  active={Boolean(activePath === tab.path)}
                  ref={r =>
                    (this.desktopTabs[tab.label.replace(/\s/g, '-')] = r)
                  }
                  rightJustified
                >
                  {history ? (
                    <Link to={tab.path}>{tab.label}</Link>
                  ) : (
                    <a href="#" onClick={() => handleTabClick(tab)}>
                      {tab.label}
                    </a>
                  )}
                </DesktopTab>
              );
            } else {
              desktopContextMenuTabs.push(tab);
            }
          })}
          {displayFirstNTabs < tabs.length && (
            <>
              <ContextMenuTrigger
                ref={r => (this.menuTriggerRefDesktop = r)}
                id={contextMenuId + '-desktop'}
              >
                <DesktopTab
                  style={{ width: MORE_TAB_WIDTH }}
                  onClick={e => {
                    if (this.menuTriggerRefDesktop) {
                      this.menuTriggerRefDesktop.handleContextClick(e);
                    }
                  }}
                >
                  <a href="#">
                    <MoreTabLabel>
                      {i18n.t('More ')}
                      <Icon
                        type="more-down-arrow"
                        size={18}
                        style={{
                          alignSelf: 'bottom',
                        }}
                      />
                    </MoreTabLabel>
                  </a>
                </DesktopTab>
              </ContextMenuTrigger>
              <ContextMenu
                id={contextMenuId + '-desktop'}
                style={{
                  width: 'auto',
                }}
              >
                {desktopContextMenuTabs.map(tab => (
                  <MenuItem
                    key={tab.label}
                    onClick={e =>
                      history ? history.push(tab.path) : handleTabClick(tab)
                    }
                    selected={tab.path === activePath}
                  >
                    {tab.label}
                  </MenuItem>
                ))}
              </ContextMenu>
            </>
          )}
        </DesktopWrapper>
        <ContextMenu
          id={contextMenuId}
          style={{
            width: 'auto',
          }}
        >
          {tabs.map((tab, i) => (
            <MenuItem
              key={tab.label}
              onClick={e =>
                history ? history.push(tab.path) : handleTabClick(tab)
              }
              selected={tab.path === activePath}
            >
              {tab.label}
            </MenuItem>
          ))}
        </ContextMenu>
      </>
    );
  }
}

export default TabNav;

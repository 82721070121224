// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation AddItemMutation($input: AddItemInput!) {
    addItem(input: $input) {
      itemEdge {
        node {
          id
          itemNumber
          name
          upc
          partNumber
          description
          category {
            id
            name
          }
          quantityUOM {
            id
            name
            type
            symbol
          }
          weightUOM {
            id
            name
            type
            symbol
          }
          dimensionUOM {
            id
            name
            type
            symbol
          }
          form {
            id
            ...FormBuilder_form @relay(mask: false)
            fields {
              id
              options {
                id
                name
                value
              }
              ...AdditionalFieldInfo_formField
            }
          }
          formFieldValues
          dimensionLength
          dimensionWidth
          dimensionHeight
          weight
          lotTracking
          cost
        }
      }
    }
  }
`;

export default {
  commit: (options: *) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: ROOT_ID,
          connectionInfo: [
            {
              key: 'PaginatedItemsContainer_items',
              rangeBehavior: 'prepend',
            },
          ],
          edgeName: 'itemEdge',
        },
      ],
    });
  },
};

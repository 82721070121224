/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type OrdersTable_orderEdges$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomerOrders_orderEdges$ref: FragmentReference;
declare export opaque type CustomerOrders_orderEdges$fragmentType: CustomerOrders_orderEdges$ref;
export type CustomerOrders_orderEdges = $ReadOnlyArray<{|
  +$fragmentRefs: OrdersTable_orderEdges$ref,
  +$refType: CustomerOrders_orderEdges$ref,
|}>;
export type CustomerOrders_orderEdges$data = CustomerOrders_orderEdges;
export type CustomerOrders_orderEdges$key = {
  +$data?: CustomerOrders_orderEdges$data,
  +$fragmentRefs: CustomerOrders_orderEdges$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CustomerOrders_orderEdges",
  "type": "OrderEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "OrdersTable_orderEdges",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'ce5e84701952687efa776a2a0466a5a5';
module.exports = node;

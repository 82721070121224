// @flow
import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import JobsTable from 'shared/components/common/JobsTable';
import AddUpdateJobModal from 'main-app/components/AddUpdateJobModal';
import { colors, fonts } from 'shared/styleguide';
import type { OrderJobTickets_order as OrderFragment } from './__generated__/OrderJobTickets_order';
import type { OrderJobTickets_workflowEdges as WorkflowEdgesFragment } from './__generated__/OrderJobTickets_workflowEdges';
import type { JobEdgesDetails_jobEdges as JobEdgesFragment } from './__generated__/OrderJobTickets_jobEdges';

type Props = {
  history: RouterHistory,
  order: OrderFragment,
  jobEdges: JobEdgesFragment,
  workflowEdges: WorkflowEdgesFragment,
  companyId: ?string,
};

type State = {
  addJobModalOpen: boolean,
  createAnotherJob: boolean,
};

const SectionHeader = styled.div`
  ${fonts.headerBold};
  color: ${colors.charcoalGrey};
  padding: 24px 0 16px;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 16px;
`;

class OrderJobTickets extends PureComponent<Props, State> {
  state = {
    addJobModalOpen: false,
    createAnotherJob: false,
  };

  handleToggleJobTicketModal = () => {
    const { addJobModalOpen } = this.state;

    this.setState({
      addJobModalOpen: !addJobModalOpen,
      createAnotherJob: false,
    });
  };

  handleAddModalReopen = () => {
    this.setState(
      {
        addJobModalOpen: false,
        createAnotherJob: true,
      },
      () => {
        this.setState({
          addJobModalOpen: true,
        });
      },
    );
  };
  render() {
    const { order, jobEdges, workflowEdges, history, companyId } = this.props;
    const { addJobModalOpen, createAnotherJob } = this.state;

    return (
      <>
        <SectionHeader>{i18n.t('Job Tickets')}</SectionHeader>
        {jobEdges?.length ? (
          <ButtonWrapper>
            <Button
              theme="blue"
              width="auto"
              onClick={this.handleToggleJobTicketModal}
            >
              {i18n.t('Create Job Ticket')}
            </Button>
          </ButtonWrapper>
        ) : null}
        <JobsTable
          history={history}
          jobEdges={jobEdges}
          emptyListMessage={i18n.t(
            `Job Tickets for this order will show here. Create a new job to get started.`,
          )}
          onCreateNew={this.handleToggleJobTicketModal}
          editEnabled
          deleteEnabled
        />
        {addJobModalOpen && (
          <AddUpdateJobModal
            onClose={this.handleToggleJobTicketModal}
            onReopen={this.handleAddModalReopen}
            createAnotherJob={createAnotherJob}
            orderId={order.id}
            job={null}
            companyId={companyId}
            workflow={
              (workflowEdges &&
                workflowEdges.find(edge => edge?.node.isDefault)?.node) ||
              null
            }
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(OrderJobTickets, {
  order: graphql`
    fragment OrderJobTickets_order on Order {
      id
    }
  `,
  jobEdges: graphql`
    fragment OrderJobTickets_jobEdges on JobEdge @relay(plural: true) {
      ...JobsTable_jobEdges
    }
  `,
  workflowEdges: graphql`
    fragment OrderJobTickets_workflowEdges on WorkflowEdge
      @relay(plural: true) {
      node {
        id
        isDefault
        ...AddUpdateJobModal_workflow
      }
    }
  `,
});

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateJobMutation($input: UpdateJobInput!) {
    updateJob(input: $input) {
      jobEdge {
        node {
          id
          importJobNumber
          jobNumber
          name
          status
          notes
          yields(first: null) {
            totalCount
            edges {
              node {
                id
                finalQuantity
              }
            }
          }
          user {
            id
            firstName
            lastName
          }
          order {
            id
            promisedAt
            customer {
              id
              name
            }
          }
          states {
            id
            formFieldValues
            status
            isEnabled
            workflowState {
              id
              name
              form {
                id
              }
            }
          }
          ...JobDetails_job
          ...Runs_job
          ...AddUpdateJobModal_job
          ...CloseJobModal_job
          ...ReopenJobModal_job
          ...DeleteJobModal_job
          ...JobLinkedItems_job
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

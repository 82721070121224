// @flow
import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import OrdersTable from 'shared/components/common/OrdersTable';
import AddUpdateOrderModal from 'main-app/components/AddUpdateOrderModal';
import { colors, fonts } from 'shared/styleguide';
import type { CustomerOrders_customer as CustomerFragment } from './__generated__/CustomerOrders_customer';
import type { OrderEdgeDetails as OrderEdgesFragment } from './__generated__/CustomerOrders_orderEdges';

type Props = {
  history: RouterHistory,
  customer: CustomerFragment,
  orderEdges: OrderEdgesFragment,
  companyId: string,
};

type State = {
  addModalOpen: boolean,
  createAnotherOrder: boolean,
};

const SectionHeader = styled.div`
  ${fonts.headerBold};
  color: ${colors.charcoalGrey};
  padding: 24px 0 16px;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 16px;
`;

class CustomerOrders extends PureComponent<Props, State> {
  state = {
    addModalOpen: false,
    createAnotherOrder: false,
  };

  handleToggleCustomerOrderModal = () => {
    const { addModalOpen } = this.state;

    this.setState({
      addModalOpen: !addModalOpen,
      createAnotherOrder: false,
    });
  };

  handleAddModalReopen = () => {
    this.setState(
      {
        addModalOpen: false,
        createAnotherOrder: true,
      },
      () => {
        this.setState({
          addModalOpen: true,
        });
      },
    );
  };

  handleCloseModal = () => {
    this.setState({
      addModalOpen: false,
      createAnotherOrder: false,
    });
  };

  render() {
    const { customer, orderEdges, history, companyId } = this.props;
    const { addModalOpen, createAnotherOrder } = this.state;

    return (
      <>
        <SectionHeader>{i18n.t('Customer Orders')}</SectionHeader>
        {orderEdges?.length ? (
          <ButtonWrapper>
            <Button
              theme="blue"
              width="auto"
              onClick={this.handleToggleCustomerOrderModal}
            >
              {i18n.t('Create Order')}
            </Button>
          </ButtonWrapper>
        ) : null}
        <OrdersTable
          history={history}
          orderEdges={orderEdges}
          emptyListMessage={i18n.t(
            `Orders for your customer will show here. Create a new order to get started.`,
          )}
          onCreateNew={this.handleToggleCustomerOrderModal}
          editEnabled
          deleteEnabled
        />
        {addModalOpen && (
          <AddUpdateOrderModal
            onClose={this.handleCloseModal}
            onSuccess={order => history.push(`/customer/${customer.id}`)}
            onReopen={this.handleAddModalReopen}
            createAnotherOrder={createAnotherOrder}
            order={null}
            customer={customer}
            companyId={companyId}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(CustomerOrders, {
  customer: graphql`
    fragment CustomerOrders_customer on Customer {
      id
      ...AddUpdateOrderModal_customer
    }
  `,
  orderEdges: graphql`
    fragment CustomerOrders_orderEdges on OrderEdge @relay(plural: true) {
      ...OrdersTable_orderEdges
    }
  `,
});

// @flow
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import type { RouterHistory } from 'react-router-dom';
import LotItemsTable from './LotItemsTable';
import type { LotItems_lotItemEdges as LotItemEdgesFragment } from './__generated__/LotItems_lotItemEdges';

type Props = {
  history: RouterHistory,
  lotItemEdges: LotItemEdgesFragment,
};

const Wrapper = styled.div`
  width: 100%;
`;

const LotItems = ({ lotItemEdges, history }: Props) => (
  <>
    <Wrapper>
      <LotItemsTable
        history={history}
        lotItemEdges={lotItemEdges}
        defaultForm={null}
      />
    </Wrapper>
  </>
);

export default createFragmentContainer(LotItems, {
  lotItemEdges: graphql`
    fragment LotItems_lotItemEdges on LotItemEdge @relay(plural: true) {
      quantity
      node {
        id
        name
        itemNumber
        partNumber
        category {
          id
          name
        }
        quantityUOM {
          id
          name
          symbol
        }
      }
      ...LotItemsTable_lotItemEdges
    }
  `,
});

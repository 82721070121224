/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AdditionalFieldInfo_formField$ref = any;
export type ApplicationType = "MAIN_APP" | "OPERATOR_PORTAL";
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
export type UOMType = "LENGTH" | "QUANTITY" | "WEIGHT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateItemModal_item$ref: FragmentReference;
declare export opaque type AddUpdateItemModal_item$fragmentType: AddUpdateItemModal_item$ref;
export type AddUpdateItemModal_item = {|
  +id: string,
  +name: string,
  +upc: ?string,
  +partNumber: ?string,
  +description: ?string,
  +category: ?{|
    +id: string,
    +name: string,
  |},
  +quantityUOM: {|
    +id: string,
    +name: string,
    +type: UOMType,
    +symbol: string,
  |},
  +weightUOM: ?{|
    +id: string,
    +name: string,
    +type: UOMType,
    +symbol: string,
  |},
  +dimensionUOM: ?{|
    +id: string,
    +name: string,
    +type: UOMType,
    +symbol: string,
  |},
  +form: {|
    +id: string,
    +name: string,
    +type: FormType,
    +systemKey: ?string,
    +productionType: ?{|
      +id: string
    |},
    +fields: $ReadOnlyArray<{|
      +id: string,
      +systemKey: ?string,
      +type: FormFieldType,
      +name: string,
      +description: ?string,
      +placeholder: ?string,
      +isRequired: boolean,
      +isCreatable: boolean,
      +applicationType: ApplicationType,
      +displayOrder: number,
      +properties: ?any,
      +options: $ReadOnlyArray<{|
        +name: string,
        +value: string,
        +id: string,
      |}>,
      +$fragmentRefs: AdditionalFieldInfo_formField$ref,
    |}>,
  |},
  +formFieldValues: any,
  +dimensionLength: ?number,
  +dimensionWidth: ?number,
  +dimensionHeight: ?number,
  +weight: ?number,
  +lotTracking: ?boolean,
  +cost: ?number,
  +safetyQuantity: ?number,
  +totalValue: ?number,
  +$refType: AddUpdateItemModal_item$ref,
|};
export type AddUpdateItemModal_item$data = AddUpdateItemModal_item;
export type AddUpdateItemModal_item$key = {
  +$data?: AddUpdateItemModal_item$data,
  +$fragmentRefs: AddUpdateItemModal_item$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "symbol",
    "args": null,
    "storageKey": null
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemKey",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AddUpdateItemModal_item",
  "type": "Item",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "upc",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "partNumber",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "category",
      "storageKey": null,
      "args": null,
      "concreteType": "ItemCategory",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "quantityUOM",
      "storageKey": null,
      "args": null,
      "concreteType": "UOM",
      "plural": false,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "weightUOM",
      "storageKey": null,
      "args": null,
      "concreteType": "UOM",
      "plural": false,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dimensionUOM",
      "storageKey": null,
      "args": null,
      "concreteType": "UOM",
      "plural": false,
      "selections": (v4/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "form",
      "storageKey": null,
      "args": null,
      "concreteType": "Form",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        (v5/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "productionType",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductionType",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "fields",
          "storageKey": null,
          "args": null,
          "concreteType": "FormField",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v3/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "placeholder",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isRequired",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isCreatable",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "applicationType",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "displayOrder",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "properties",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "options",
              "storageKey": null,
              "args": null,
              "concreteType": "FormFieldOption",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "value",
                  "args": null,
                  "storageKey": null
                },
                (v0/*: any*/)
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "AdditionalFieldInfo_formField",
              "args": null
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "formFieldValues",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dimensionLength",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dimensionWidth",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dimensionHeight",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "weight",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lotTracking",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cost",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "safetyQuantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalValue",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0dc37c8a45eb45016d992ea8cb3b776';
module.exports = node;

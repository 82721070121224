// @flow

import type { FilterOption } from 'main-app/components/FilterControls';
import { i18n } from 'shared/utils';

export default (): Array<FilterOption> => [
  {
    label: i18n.t('Inventory #'),
    name: 'itemNumbers',
    type: 'text',
    multi: true,
  },
  {
    label: i18n.t('Order'),
    name: 'orderIds',
    type: 'order',
    multi: true,
  },
  {
    label: i18n.t('Customer'),
    name: 'customerIds',
    type: 'customer',
    multi: true,
  },
  {
    label: i18n.t('Item Category'),
    name: 'itemCategoryIds',
    type: 'item-category',
    multi: true,
  },
  {
    label: i18n.t('Part #'),
    name: 'partNumber',
    type: 'text',
  },
];

// @flow

import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import type { Match } from 'react-router';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import PageHeader from 'main-app/components/PageHeader';
import FormFieldBoard from './FormFieldBoard';
import FormPreview from './FormPreview';

type Props = {
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

const ContentWrapper = styled.div`
  @media (min-width: 1000px) {
    padding-right: 324px;
  }
`;

const DEFAULT_FORM_NAME_MAP = {
  JOB_YIELD: 'Job Ticket - Add Item',
  ITEM: 'Inventory',
};

export default ({ match }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    dataFrom="STORE_THEN_NETWORK"
    variables={{
      id: match.params.id,
    }}
    query={graphql`
      query FormQuery($id: ID!) {
        form(id: $id) {
          id
          name
          type
          ...FormFieldBoard_form
          ...FormPreview_form
        }
      }
    `}
    render={({ props }: *) => {
      if (!props) {
        return <Loader />;
      }

      const title = DEFAULT_FORM_NAME_MAP[props.form.type] || props.form.name;

      return (
        <>
          <PageHeader intro={i18n.t('FORM')} title={title} />
          <ContentWrapper>
            <FormFieldBoard form={props.form} />
          </ContentWrapper>
          <FormPreview form={props.form} />
        </>
      );
    }}
  />
);

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateItemModal_item$ref = any;
type AddUpdateOrderYieldModal_orderYield$ref = any;
type AddUpdateOrderYieldModal_orderYieldEdges$ref = any;
type DeleteOrderYieldModal_orderYield$ref = any;
type OrderYieldPanels_orderYieldEdges$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderDetails_order$ref: FragmentReference;
declare export opaque type OrderDetails_order$fragmentType: OrderDetails_order$ref;
export type OrderDetails_order = {|
  +id: string,
  +yields: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +order: {|
          +id: string
        |},
        +orderedQuantity: number,
        +item: {|
          +id: string,
          +name: string,
          +description: ?string,
          +$fragmentRefs: AddUpdateItemModal_item$ref,
        |},
        +$fragmentRefs: AddUpdateOrderYieldModal_orderYield$ref & DeleteOrderYieldModal_orderYield$ref,
      |},
      +$fragmentRefs: OrderYieldPanels_orderYieldEdges$ref & AddUpdateOrderYieldModal_orderYieldEdges$ref,
    |}>
  |},
  +customerOrderNumber: ?string,
  +orderedAt: ?any,
  +promisedAt: ?any,
  +requiredAt: ?any,
  +$refType: OrderDetails_order$ref,
|};
export type OrderDetails_order$data = OrderDetails_order;
export type OrderDetails_order$key = {
  +$data?: OrderDetails_order$data,
  +$fragmentRefs: OrderDetails_order$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrderDetails_order",
  "type": "Order",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "yields"
        ]
      }
    ]
  },
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": "yields",
      "name": "__OrderDetails_order_yields_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "OrderYieldConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "OrderYieldEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderYield",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "order",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Order",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "orderedQuantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "item",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "description",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "AddUpdateItemModal_item",
                      "args": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "AddUpdateOrderYieldModal_orderYield",
                  "args": null
                },
                {
                  "kind": "FragmentSpread",
                  "name": "DeleteOrderYieldModal_orderYield",
                  "args": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "OrderYieldPanels_orderYieldEdges",
              "args": null
            },
            {
              "kind": "FragmentSpread",
              "name": "AddUpdateOrderYieldModal_orderYieldEdges",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "customerOrderNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "orderedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "promisedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "requiredAt",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '750a391b076600426331b175df86408f';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateMachineModal_machine$ref = any;
type DeleteMachineModal_machine$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MachineCard_machine$ref: FragmentReference;
declare export opaque type MachineCard_machine$fragmentType: MachineCard_machine$ref;
export type MachineCard_machine = {|
  +id: string,
  +name: string,
  +type: {|
    +id: string,
    +name: string,
  |},
  +workflows: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +deletedAt: ?any,
  |}>,
  +$fragmentRefs: AddUpdateMachineModal_machine$ref & DeleteMachineModal_machine$ref,
  +$refType: MachineCard_machine$ref,
|};
export type MachineCard_machine$data = MachineCard_machine;
export type MachineCard_machine$key = {
  +$data?: MachineCard_machine$data,
  +$fragmentRefs: MachineCard_machine$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MachineCard_machine",
  "type": "Machine",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "type",
      "storageKey": null,
      "args": null,
      "concreteType": "MachineType",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflows",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "deletedAt",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "AddUpdateMachineModal_machine",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DeleteMachineModal_machine",
      "args": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b48f95beb40eeaacb413020ab557cc6';
module.exports = node;

// @flow

import React from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import type { RouterHistory } from 'react-router';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { renderCosmeticFieldValue } from 'shared/components/form/FormBuilder';
import Panel from 'shared/components/common/Panel';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Icon from 'shared/components/common/Icon';
import YieldProgressBar from 'shared/components/common/YieldProgressBar';
import Divider from 'shared/components/common/Divider';
import type { JobYieldPanels_jobYieldEdges as JobYieldEdges } from './__generated__/JobYieldPanels_jobYieldEdges';

type Props = {
  history: RouterHistory,
  jobYieldEdges: JobYieldEdges,
  onEditItem?: Object => void,
  onDeleteItem?: Object => void,
  editEnabled: boolean,
  workflowHasGangRun?: boolean,
};

const menuTriggerRefs: Object = {};

const HeaderBottom = styled.div`
  li {
    padding-bottom: 0;
  }
`;

const JobYieldPanels = ({
  history,
  jobYieldEdges,
  onEditItem,
  onDeleteItem,
  editEnabled,
  workflowHasGangRun,
}: Props) => {
  // Some views, such as on the operator portal, do not have edit or delete capabilities
  const hasContextMenu = Boolean(onEditItem || onDeleteItem);

  if (!jobYieldEdges) {
    return null;
  }

  return jobYieldEdges.map(edge => {
    const jobYield = edge?.node;
    const ITEM_NUMBER_UP_LABEL = i18n.t('# Up');

    if (!jobYield) {
      return null;
    }

    return (
      <Panel
        key={jobYield.id}
        style={{
          marginBottom: 16,
        }}
        title={jobYield.item.name}
        headerRight={
          hasContextMenu && editEnabled ? (
            <ContextMenuTrigger
              id={`item-list-menu-${jobYield.id}`}
              ref={r => (menuTriggerRefs[jobYield.id] = r)}
            >
              <Icon
                type="circle-context-menu"
                onClick={e => {
                  if (editEnabled && menuTriggerRefs[jobYield.id]) {
                    menuTriggerRefs[jobYield.id].handleContextClick(e);
                  }
                }}
              />
            </ContextMenuTrigger>
          ) : (
            <Icon type="circle-context-menu" style={{ opacity: 0.2 }} />
          )
        }
        headerBottom={
          <HeaderBottom>
            <YieldProgressBar jobYield={jobYield} />
            <KeyValueGrid
              fields={[
                {
                  label: ITEM_NUMBER_UP_LABEL,
                  value: jobYield.itemNumberUp,
                },
                {
                  label: i18n.t('Broker PO #'),
                  value: jobYield.brokerCustomerOrderNumber || '',
                },
              ].filter(field => {
                if (workflowHasGangRun) {
                  return true;
                }

                return field.label !== ITEM_NUMBER_UP_LABEL;
              })}
            />
          </HeaderBottom>
        }
      >
        {Boolean(jobYield.form.fields.length) && (
          <>
            <KeyValueGrid
              fields={jobYield.form.fields.map(customField => ({
                label: customField.name,
                value: renderCosmeticFieldValue({
                  formFieldType: customField.type,
                  value: jobYield.formFieldValues[customField.id] || '',
                }),
              }))}
            />
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          </>
        )}
        <KeyValueGrid
          singleColumn
          verticalMode
          fields={[
            {
              label: i18n.t('Part #'),
              value: jobYield.item.partNumber || '',
            },
            {
              label: i18n.t('Inventory #'),
              value: (
                <Link to={`/item/${jobYield.item.id}`}>
                  {jobYield.item.itemNumber}
                </Link>
              ),
            },
            {
              label: i18n.t('Description'),
              value: jobYield.item.description || '',
            },
          ].concat(
            jobYield.item.form.fields.map(customField => ({
              label: customField.name,
              value: renderCosmeticFieldValue({
                formFieldType: customField.type,
                value: jobYield.item.formFieldValues[customField.id] || '',
              }),
            })),
          )}
        />
        {editEnabled && (
          <ContextMenu id={`item-list-menu-${jobYield.id}`}>
            {onEditItem && (
              <MenuItem onClick={() => onEditItem(jobYield)}>
                {i18n.t('Edit Item')}
              </MenuItem>
            )}
            {onDeleteItem && (
              <MenuItem onClick={() => onDeleteItem(jobYield)}>
                {i18n.t('Remove Item')}
              </MenuItem>
            )}
          </ContextMenu>
        )}
      </Panel>
    );
  });
};

JobYieldPanels.defaultProps = {
  editEnabled: true,
  workflowHasGangRun: false,
};

export default createFragmentContainer(JobYieldPanels, {
  jobYieldEdges: graphql`
    fragment JobYieldPanels_jobYieldEdges on JobYieldEdge @relay(plural: true) {
      node {
        id
        quantity
        itemNumberUp
        brokerCustomerOrderNumber
        formFieldValues
        form {
          id
          ...FormBuilder_form
          fields {
            id
            systemKey
            name
            type
          }
        }
        item {
          id
          itemNumber
          name
          description
          partNumber
          formFieldValues
          quantityUOM {
            id
            symbol
          }
          form {
            id
            ...FormBuilder_form
            fields {
              id
              systemKey
              name
              type
            }
          }
        }
        ...AddUpdateJobYieldModal_jobYield
        ...DeleteJobYieldModal_jobYield
        ...YieldProgressBar_jobYield
      }
    }
  `,
});

// @flow

import React, { PureComponent } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { i18n } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';
import * as Actions from 'main-app/store/Actions';
import AddIntegrationMutation from 'main-app/mutations/AddIntegration';
import Button from 'shared/components/common/Button';
import Loader from 'shared/components/common/Loader';
import { Modal, ModalHeader, ModalBody } from 'shared/components/modal';
import defaultLogo from './img/defaultLogo.png';

type Props = {
  onClose: () => void,
  onSuccess: string => void,
};

type State = {
  addingIntegrationSourceId: ?string,
};

const GridWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
`;

const Source = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${colors.lightBlueGrey};
  background: ${colors.white};
  padding: 16px;
  width: 200px;
  min-height: 200px;
  margin: 8px;
`;

const Title = styled.div`
  ${fonts.bodyBold};
  color: ${colors.charcoalGrey};
  padding-bottom: 16px;
`;

const LogoWrapper = styled.div`
  padding-bottom: 16px;
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const Logo = styled.img`
  width: 100px;
`;

class AddIntegrationModal extends PureComponent<Props, State> {
  state = {
    addingIntegrationSourceId: null,
  };

  handleAddIntegration = (integrationSourceId: string) => {
    const { onClose, onSuccess } = this.props;

    this.setState(
      {
        addingIntegrationSourceId: integrationSourceId,
      },
      async () => {
        try {
          const res = await AddIntegrationMutation.commit({
            variables: {
              input: {
                integrationSourceId,
              },
            },
          });

          Actions.alertNotification(
            i18n.t('Integration Successfully Added'),
            'success',
          );

          onClose();
          onSuccess(res.addIntegration.integrationEdge.node.id);
        } catch (e) {
          this.setState({
            addingIntegrationSourceId: null,
          });
        }
      },
    );
  };

  render() {
    const { onClose } = this.props;
    const { addingIntegrationSourceId } = this.state;

    return (
      <Modal maxWidth={950}>
        <ModalHeader header={i18n.t('Add Integration')} onClose={onClose} />
        <ModalBody withPadding>
          <QueryRenderer
            environment={relayEnvironment}
            dataFrom="STORE_THEN_NETWORK"
            query={graphql`
              query AddIntegrationModalQuery {
                integrationSources(first: null) {
                  edges {
                    node {
                      id
                      name
                      description
                      logo
                    }
                  }
                }
              }
            `}
            render={({ props }: *) => {
              if (!props) {
                return <Loader />;
              }

              return (
                <GridWrapper>
                  {props.integrationSources.edges.map(({ node }) => (
                    <Source key={node.id}>
                      <Title>{node.name}</Title>
                      <LogoWrapper>
                        <Logo src={node.logo || defaultLogo} />
                      </LogoWrapper>
                      <Button
                        thin
                        onClick={() => this.handleAddIntegration(node.id)}
                        loading={addingIntegrationSourceId === node.id}
                        disabled={Boolean(addingIntegrationSourceId)}
                      >
                        {i18n.t('Add & Configure')}
                      </Button>
                    </Source>
                  ))}
                </GridWrapper>
              );
            }}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default AddIntegrationModal;

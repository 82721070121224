// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import type { RouterHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { fonts } from 'shared/styleguide';
import { i18n, WorkflowUtils } from 'shared/utils';
import Button from 'shared/components/common/Button';
import Panel from 'shared/components/common/Panel';
import AddUpdateItemModal from 'main-app/components/AddUpdateItemModal';
import AddUpdateJobYieldModal from 'main-app/components/AddUpdateJobYieldModal';
import DeleteJobYieldModal from 'main-app/components/DeleteJobYieldModal';
import JobYieldPanels from 'shared/components/common/JobYieldPanels';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import JobStateGrid from 'shared/components/common/JobStateGrid';
import Icon from 'shared/components/common/Icon';
import WorkflowDiagram from 'shared/components/common/WorkflowDiagram';
import UpdateJobStateModal from 'shared/components/common/UpdateJobStateModal';
import type { JobDetails_job as JobFragment } from './__generated__/JobDetails_job';
import type { JobDetails_defaultForm as DefaultFormFragment } from './__generated__/JobDetails_defaultForm';

type Props = {
  history: RouterHistory,
  job: JobFragment,
  defaultForm: DefaultFormFragment,
};

type State = {
  addJobYieldModalOpen: boolean,
  addItemAndJobYieldModalOpen: boolean,
  createAnotherItem: boolean,
  updateJobYieldRecord: ?Object,
  deleteJobYieldRecord: ?Object,
  updateJobStateRecord: ?Object,
};

const ListTitle = styled.div`
  ${fonts.largeBold}
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding-top: 8px;
  padding-bottom: 16px;

  button:first-of-type {
    margin-right: 16px;
  }
`;

const Row = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const LeftColumn = styled.div`
  padding-bottom: 16px;

  @media (min-width: 1000px) {
    padding-bottom: 0;
    padding-right: 24px;
    flex: 1.5;
  }
`;

const RightColumn = styled.div`
  @media (min-width: 1000px) {
    flex: 1;
  }
`;

const TooltipWrapper = styled.div`
  strong {
    display: block;
    ${fonts.bodyBold};
    padding-bottom: 8px;
  }
`;

const TitleIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

class JobDetails extends PureComponent<Props, State> {
  initialState = {
    addJobYieldModalOpen: false,
    addItemAndJobYieldModalOpen: false,
    createAnotherItem: false,
    updateJobYieldRecord: null,
    deleteJobYieldRecord: null,
    updateJobStateRecord: null,
  };
  workflowHasGangRun: boolean;

  constructor(props: Props) {
    super(props);

    this.state = this.initialState;
    this.workflowHasGangRun = props.job.workflow.states.some(
      state => state.isGangRun,
    );
  }

  handleOpenAddItemModal = (e: Event) => {
    this.setState({ addJobYieldModalOpen: true });
  };

  handleOpenEditItemModal = (jobYield: Object) => {
    this.setState({
      updateJobYieldRecord: jobYield,
    });
  };

  handleOpenDeleteItemModal = (jobYield: Object) => {
    this.setState({
      deleteJobYieldRecord: jobYield,
    });
  };

  handleOpenJobStateModal = (jobState: Object) => {
    this.setState({
      updateJobStateRecord: jobState,
    });
  };

  handleOpenCreateItemModal = () => {
    this.setState({
      addItemAndJobYieldModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState(this.initialState);
  };

  handleReopenAddItemAndJobYieldModal = () => {
    this.setState(
      {
        addItemAndJobYieldModalOpen: false,
        createAnotherItem: true,
      },
      () => {
        this.setState({
          addItemAndJobYieldModalOpen: true,
        });
      },
    );
  };

  render() {
    const {
      addJobYieldModalOpen,
      addItemAndJobYieldModalOpen,
      createAnotherItem,
      updateJobYieldRecord,
      deleteJobYieldRecord,
      updateJobStateRecord,
    } = this.state;
    const { job, history, defaultForm } = this.props;
    const hasYields = Boolean(job.yields.edges?.length);
    const enableJobYieldEdit = job.status === 'OPEN';
    const showWorkflowDiagram =
      job.states.length === job.workflow.states.length;
    const sortedJobStates = WorkflowUtils.getSortedJobStates(job);

    return (
      <Wrapper>
        {showWorkflowDiagram && (
          <WorkflowDiagram job={job} dragEnabled={false} />
        )}
        <ButtonsContainer>
          <Button
            width="auto"
            theme="blue"
            onClick={this.handleOpenAddItemModal}
            disabled={!enableJobYieldEdit}
          >
            {i18n.t('Add Item')}
          </Button>
          <div data-for={job.id} data-tip>
            <Button
              theme="border-white"
              width="auto"
              onClick={this.handleOpenCreateItemModal}
              disabled={!enableJobYieldEdit || Boolean(job.order?.orderNumber)}
            >
              {i18n.t('Create Item')}
            </Button>
          </div>
          {job.order?.orderNumber && (
            <ReactTooltip place="bottom" effect="solid" type="info" id={job.id}>
              <TooltipWrapper>
                <strong>
                  {i18n.t('Unable to create and add item to the job.')}
                </strong>
                {i18n.t(
                  'Please add it to Order {{ orderNumber, string }} first.',
                  {
                    orderNumber: job.order?.orderNumber,
                  },
                )}
              </TooltipWrapper>
            </ReactTooltip>
          )}
        </ButtonsContainer>
        <Row>
          <LeftColumn>
            <ListTitle>{i18n.t('Items to Produce')}</ListTitle>
            {!hasYields ? (
              <Panel>
                <EmptyListResults
                  graphic="items"
                  message={i18n.t(
                    'This job ticket is empty. Add an item to get started.',
                  )}
                />
              </Panel>
            ) : (
              <JobYieldPanels
                history={history}
                jobYieldEdges={job.yields.edges}
                onEditItem={this.handleOpenEditItemModal}
                onDeleteItem={this.handleOpenDeleteItemModal}
                editEnabled={enableJobYieldEdit}
                workflowHasGangRun={this.workflowHasGangRun}
              />
            )}
          </LeftColumn>
          <RightColumn>
            <ListTitle>{i18n.t('Workflow Forms')}</ListTitle>
            {job.states
              .filter(
                jobState =>
                  jobState.isEnabled &&
                  (jobState.workflowState.type === 'TASK' ||
                    (jobState.workflowState.isAssignable ||
                      jobState.workflowState.form)),
              )
              .sort((jobStateA, jobStateB) =>
                sortedJobStates
                  ? (sortedJobStates[jobStateA.id]?.order || 0) -
                    (sortedJobStates[jobStateB.id]?.order || 0)
                  : 0,
              )
              .map(jobState => (
                <Panel
                  key={jobState.id}
                  style={{
                    marginBottom: 16,
                  }}
                  title={
                    <TitleIconWrapper>
                      <Icon
                        type={
                          jobState.workflowState.type === 'TASK'
                            ? 'circle-task'
                            : 'circle-run'
                        }
                        size={24}
                        style={{ marginRight: 8 }}
                      />
                      <span>{jobState.workflowState.name}</span>
                    </TitleIconWrapper>
                  }
                  headerRight={
                    <Icon
                      type="circle-edit"
                      onClick={() => this.handleOpenJobStateModal(jobState)}
                    />
                  }
                  showHeaderBorder={
                    jobState.workflowState.isAssignable ||
                    jobState.workflowState.form
                      ? true
                      : false
                  }
                >
                  <JobStateGrid jobState={jobState} verticalMode />
                </Panel>
              ))}
          </RightColumn>
        </Row>
        {(addJobYieldModalOpen || updateJobYieldRecord) &&
          enableJobYieldEdit && (
            <AddUpdateJobYieldModal
              job={job}
              jobYield={updateJobYieldRecord}
              onClose={this.handleCloseAllModals}
              workflowHasGangRun={this.workflowHasGangRun}
            />
          )}
        {deleteJobYieldRecord && enableJobYieldEdit && (
          <DeleteJobYieldModal
            jobId={job.id}
            jobYield={deleteJobYieldRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
        {updateJobStateRecord && (
          <UpdateJobStateModal
            job={job}
            jobState={updateJobStateRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
        {addItemAndJobYieldModalOpen && (
          <AddUpdateItemModal
            onClose={this.handleCloseAllModals}
            onReopen={this.handleReopenAddItemAndJobYieldModal}
            createAnotherItem={createAnotherItem}
            item={null}
            defaultForm={defaultForm}
            jobId={job.id}
            workflowHasGangRun={this.workflowHasGangRun}
          />
        )}
      </Wrapper>
    );
  }
}

export default createFragmentContainer(JobDetails, {
  job: graphql`
    fragment JobDetails_job on Job {
      id
      status
      ...AddUpdateJobYieldModal_job
      ...WorkflowDiagram_job
      ...UpdateJobStateModal_job
      ...WorkflowUtils_job @relay(mask: false)
      yields(first: null)
        @connection(key: "JobDetails_job_yields", filters: []) {
        edges {
          ...JobYieldPanels_jobYieldEdges
        }
      }
      workflow {
        id
        states {
          id
          isGangRun
        }
      }
      states {
        id
        isEnabled
        workflowState {
          id
          name
          isAssignable
          type
          form {
            id
          }
        }
        ...JobStateGrid_jobState
        ...UpdateJobStateModal_jobState
      }
      order {
        id
        orderNumber
      }
    }
  `,
  defaultForm: graphql`
    fragment JobDetails_defaultForm on Form {
      id
      ...AddUpdateItemModal_defaultForm
    }
  `,
});

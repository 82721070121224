/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeleteAPIKeyModal_apiKey$ref: FragmentReference;
declare export opaque type DeleteAPIKeyModal_apiKey$fragmentType: DeleteAPIKeyModal_apiKey$ref;
export type DeleteAPIKeyModal_apiKey = {|
  +id: string,
  +name: string,
  +$refType: DeleteAPIKeyModal_apiKey$ref,
|};
export type DeleteAPIKeyModal_apiKey$data = DeleteAPIKeyModal_apiKey;
export type DeleteAPIKeyModal_apiKey$key = {
  +$data?: DeleteAPIKeyModal_apiKey$data,
  +$fragmentRefs: DeleteAPIKeyModal_apiKey$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "DeleteAPIKeyModal_apiKey",
  "type": "APIKey",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7f1c9f4203ea0a418854b511c2cd5421';
module.exports = node;

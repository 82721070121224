// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateJobYieldMutation($input: UpdateJobYieldInput!) {
    updateJobYield(input: $input) {
      jobYieldEdge {
        cursor
        node {
          id
          quantity
          itemNumberUp
          brokerCustomerOrderNumber
          overUnderRule
          overQuantity
          formFieldValues
          form {
            id
            ...FormBuilder_form
          }
          item {
            id
            itemNumber
            name
            partNumber
            description
            formFieldValues
            form {
              ...FormBuilder_form
            }
          }
          job {
            id
            status
            states {
              id
              status
            }
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

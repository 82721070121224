// @flow

import React from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import GlobalStyles from 'shared/GlobalStyles';
import store from './store';
import MainAppBootstrapper from './MainAppBootstrapper';
import SignIn from './scenes/SignIn';
import SignUp from './scenes/SignUp';
import Dashboard from './scenes/Dashboard';
import Reports from './scenes/Reports';
import Report from './scenes/Report';
import Orders from './scenes/Orders';
import Order from './scenes/Order';
import Jobs from './scenes/Jobs';
import Job from './scenes/Job';
import Customers from './scenes/Customers';
import Customer from './scenes/Customer';
import Items from './scenes/Items';
import Help from './scenes/Help';
import Settings from './scenes/Settings';
import ProductionSchedule from './scenes/ProductionSchedule';
import Item from './scenes/Item';
import Lots from './scenes/Lots';
import Lot from './scenes/Lot';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <>
        <MainAppBootstrapper>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/report/:id" component={Report} />
            <Route exact path="/orders" component={Orders} />
            <Route exact path="/order/:id/:section?" component={Order} />
            <Route exact path="/jobs" component={Jobs} />
            <Route exact path="/job/:id/:section?" component={Job} />
            <Route
              exact
              path="/production-schedule"
              component={ProductionSchedule}
            />
            <Route exact path="/customers" component={Customers} />
            <Route exact path="/customer/:id/:section?" component={Customer} />
            <Route exact path="/items" component={Items} />
            <Route exact path="/item/:id/:section?" component={Item} />
            <Route exact path="/lots" component={Lots} />
            <Route exact path="/lot/:id/:section?" component={Lot} />
            <Route exact path="/help" component={Help} />
            <Route path="/settings" component={Settings} />
            <Route exact render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </MainAppBootstrapper>
        <GlobalStyles />
      </>
    </BrowserRouter>
  </Provider>
);

export default hot(App);

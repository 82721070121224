/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FormBuilder_form$ref = any;
export type FormFieldType = "CHECKBOX" | "DATE" | "MULTILINE_TEXT" | "MULTISELECT" | "NUMBER" | "SELECT" | "SELECT_CUSTOMER" | "SELECT_MACHINE" | "SELECT_ORDER" | "SELECT_USER" | "TEXT";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ItemSidePanel_item$ref: FragmentReference;
declare export opaque type ItemSidePanel_item$fragmentType: ItemSidePanel_item$ref;
export type ItemSidePanel_item = {|
  +id: string,
  +itemNumber: string,
  +upc: ?string,
  +weight: ?number,
  +dimensionWidth: ?number,
  +dimensionLength: ?number,
  +dimensionHeight: ?number,
  +partNumber: ?string,
  +description: ?string,
  +safetyQuantity: ?number,
  +category: ?{|
    +id: string,
    +name: string,
  |},
  +quantityUOM: {|
    +id: string,
    +name: string,
    +symbol: string,
  |},
  +weightUOM: ?{|
    +id: string,
    +symbol: string,
  |},
  +dimensionUOM: ?{|
    +id: string,
    +symbol: string,
  |},
  +lotTracking: ?boolean,
  +cost: ?number,
  +formFieldValues: any,
  +form: {|
    +id: string,
    +fields: $ReadOnlyArray<{|
      +id: string,
      +systemKey: ?string,
      +name: string,
      +type: FormFieldType,
    |}>,
    +$fragmentRefs: FormBuilder_form$ref,
  |},
  +$refType: ItemSidePanel_item$ref,
|};
export type ItemSidePanel_item$data = ItemSidePanel_item;
export type ItemSidePanel_item$key = {
  +$data?: ItemSidePanel_item$data,
  +$fragmentRefs: ItemSidePanel_item$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "ItemSidePanel_item",
  "type": "Item",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "itemNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "upc",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "weight",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dimensionWidth",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dimensionLength",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dimensionHeight",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "partNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "safetyQuantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "category",
      "storageKey": null,
      "args": null,
      "concreteType": "ItemCategory",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "quantityUOM",
      "storageKey": null,
      "args": null,
      "concreteType": "UOM",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "weightUOM",
      "storageKey": null,
      "args": null,
      "concreteType": "UOM",
      "plural": false,
      "selections": (v3/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dimensionUOM",
      "storageKey": null,
      "args": null,
      "concreteType": "UOM",
      "plural": false,
      "selections": (v3/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lotTracking",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cost",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "formFieldValues",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "form",
      "storageKey": null,
      "args": null,
      "concreteType": "Form",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "fields",
          "storageKey": null,
          "args": null,
          "concreteType": "FormField",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "systemKey",
              "args": null,
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "type",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "FormBuilder_form",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dbf3e098639205d07d89fc9ef377e271';
module.exports = node;

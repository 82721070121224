// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateDowntimeCategoryMutation(
    $input: UpdateDowntimeCategoryInput!
  ) {
    updateDowntimeCategory(input: $input) {
      downtimeCategoryEdge {
        cursor
        node {
          id
          name
          parentCategory {
            id
            name
          }
          updatedAt
          createdAt
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

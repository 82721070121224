// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { fonts } from 'shared/styleguide';
import Icon from 'shared/components/common/Icon';

type Props = {
  title: string,
  children: *,
};

type State = {
  visible: boolean,
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`;

const Label = styled.div`
  ${fonts.largeBold}
`;

export default class CollapsiblePanel extends PureComponent<Props, State> {
  state = {
    visible: true,
  };

  handleVisibilityToggle = () => {
    this.setState(prevState => ({
      visible: !prevState.visible,
    }));
  };

  render() {
    const { visible } = this.state;
    const { title, children } = this.props;

    return (
      <>
        <Header>
          <Label>{title}</Label>
          <Icon
            type={visible ? 'circle-arrow-up' : 'circle-arrow-down'}
            onClick={this.handleVisibilityToggle}
          />
        </Header>
        {visible && children}
      </>
    );
  }
}

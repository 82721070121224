// @flow

import React from 'react';
import moment from 'moment';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import RunStatusPill from 'shared/components/common/Pill/RunStatusPill';
import KeyValueGrid from 'shared/components/common/KeyValueGrid';
import Divider from 'shared/components/common/Divider';
import RunLogs from 'shared/components/common/RunLogs';
import { Modal, ModalHeader, ModalBody } from 'shared/components/modal';
import RunYields from 'shared/components/common/RunYields';
import type { ViewRunModal_run as RunFragment } from './__generated__/ViewRunModal_run';

type Props = {
  onClose: () => void,
  run: RunFragment,
};

const ViewRunModal = ({ run, onClose }: Props) => {
  const fields = [
    {
      label: i18n.t('Scheduled Start Time'),
      value: moment(run.scheduledStartAt).format('MM/DD/YYYY h:mm A'),
    },
    {
      label: i18n.t('Scheduled End Time'),
      value: moment(run.scheduledEndAt).format('MM/DD/YYYY h:mm A'),
    },
    {
      label: i18n.t('Actual Start Time'),
      value: run.actualStartAt
        ? moment(run.actualStartAt).format('MM/DD/YYYY h:mm A')
        : '',
    },
    {
      label: i18n.t('Actual End Time'),
      value: run.actualEndAt
        ? moment(run.actualEndAt).format('MM/DD/YYYY h:mm A')
        : '',
    },
    {
      label: i18n.t('Notes'),
      value: run.notes || '',
    },
  ];

  return (
    <Modal maxWidth={800}>
      <ModalHeader
        header={run.machine.name + ' / ' + run.jobState.workflowState.name}
        onClose={onClose}
      />
      <ModalBody withPadding>
        <RunStatusPill status={run.status} width="auto" />
        <KeyValueGrid
          style={{
            paddingTop: 16,
            paddingBottom: 16,
          }}
          fields={fields}
        />
        <RunYields run={run} />
        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        <RunLogs run={run} />
      </ModalBody>
    </Modal>
  );
};

export default createFragmentContainer(ViewRunModal, {
  run: graphql`
    fragment ViewRunModal_run on Run {
      id
      status
      notes
      scheduledStartAt
      scheduledEndAt
      actualStartAt
      actualEndAt
      jobState {
        id
        workflowState {
          id
          name
        }
      }
      machine {
        id
        name
        type {
          id
          name
        }
      }
      ...RunLogs_run
      ...RunYields_run
    }
  `,
});

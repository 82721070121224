/**
 * @flow
 * @relayHash 83e4b57eaa381deaa6661df8b4c68022
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AttachmentsGrid_attachmentEdges$ref = any;
export type AttachmentParentType = "ITEM" | "JOB" | "ORDER";
export type AttachmentsQueryVariables = {|
  parentType: AttachmentParentType,
  parentId: string,
|};
export type AttachmentsQueryResponse = {|
  +attachments: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +urlPath: string,
        +thumbnailUrlPath: ?string,
        +createdAt: any,
        +user: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
        |},
      |},
      +$fragmentRefs: AttachmentsGrid_attachmentEdges$ref,
    |}>
  |}
|};
export type AttachmentsQuery = {|
  variables: AttachmentsQueryVariables,
  response: AttachmentsQueryResponse,
|};
*/


/*
query AttachmentsQuery(
  $parentType: AttachmentParentType!
  $parentId: ID!
) {
  attachments(parentType: $parentType, parentId: $parentId) {
    edges {
      node {
        id
        name
        urlPath
        thumbnailUrlPath
        createdAt
        user {
          id
          firstName
          lastName
        }
        __typename
      }
      ...AttachmentsGrid_attachmentEdges
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment AttachmentsGrid_attachmentEdges on AttachmentEdge {
  node {
    id
    name
    urlPath
    createdAt
    user {
      id
      firstName
      lastName
    }
    ...DeleteAttachmentModal_attachment
  }
}

fragment DeleteAttachmentModal_attachment on Attachment {
  id
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "parentType",
    "type": "AttachmentParentType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "parentId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "parentId",
  "variableName": "parentId"
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "node",
  "storageKey": null,
  "args": null,
  "concreteType": "Attachment",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "urlPath",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "thumbnailUrlPath",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "__typename",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "parentType",
    "variableName": "parentType"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AttachmentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "attachments",
        "name": "__Attachments_attachments_connection",
        "storageKey": null,
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "AttachmentConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "AttachmentEdge",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "AttachmentsGrid_attachmentEdges",
                "args": null
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AttachmentsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "attachments",
        "storageKey": null,
        "args": (v6/*: any*/),
        "concreteType": "AttachmentConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "AttachmentEdge",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          },
          (v5/*: any*/)
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "attachments",
        "args": (v6/*: any*/),
        "handle": "connection",
        "key": "Attachments_attachments",
        "filters": [
          "parentId"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AttachmentsQuery",
    "id": null,
    "text": "query AttachmentsQuery(\n  $parentType: AttachmentParentType!\n  $parentId: ID!\n) {\n  attachments(parentType: $parentType, parentId: $parentId) {\n    edges {\n      node {\n        id\n        name\n        urlPath\n        thumbnailUrlPath\n        createdAt\n        user {\n          id\n          firstName\n          lastName\n        }\n        __typename\n      }\n      ...AttachmentsGrid_attachmentEdges\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AttachmentsGrid_attachmentEdges on AttachmentEdge {\n  node {\n    id\n    name\n    urlPath\n    createdAt\n    user {\n      id\n      firstName\n      lastName\n    }\n    ...DeleteAttachmentModal_attachment\n  }\n}\n\nfragment DeleteAttachmentModal_attachment on Attachment {\n  id\n  name\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "attachments"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1cf00fd0e8114e812f64a4fe54c6bebc';
module.exports = node;

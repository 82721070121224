// @flow

import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { QueryRenderer, graphql } from 'react-relay';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import * as Actions from 'main-app/store/Actions';
import { colors, fonts } from 'shared/styleguide';
import { i18n, Analytics } from 'shared/utils';
import AddAttachmentsMutation from 'main-app/mutations/AddAttachments';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Button from 'shared/components/common/Button';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import DragActive from 'shared/components/common/DragActive';
import AttachmentsGrid from './AttachmentsGrid';

type Props = {
  orderId?: string,
  jobId?: string,
  itemId?: string,
};

type State = {
  uploading: boolean,
};

const MAX_UPLOAD_SIZE_MB = 50 * 1000 * 1000;

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  outline: none;
`;

const LoaderWrapper = styled.div`
  height: 300px;
`;

const MobileUploadInstructions = styled.div`
  text-align: center;
  padding-bottom: 24px;

  @media (min-width: 1000px) {
    display: none;
  }
`;

const DesktopUploadInstructions = styled.div`
  display: none;

  text-align: center;
  ${fonts.largeBold};
  color: ${colors.charcoalGrey};
  padding-bottom: 32px;

  span {
    color: ${colors.azul};
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media (min-width: 1000px) {
    display: block;
  }
`;

class Attachments extends PureComponent<Props, State> {
  static defaultProps = {
    orderId: undefined,
    jobId: undefined,
    itemId: undefined,
  };

  state = {
    uploading: false,
  };

  getParentType = () => {
    const { orderId, jobId } = this.props;
    const parentIdTypeMap = {
      jobId: 'JOB',
      orderId: 'ORDER',
      itemId: 'ITEM',
    };
    const parentType =
      (orderId && parentIdTypeMap.orderId) ||
      (jobId && parentIdTypeMap.jobId) ||
      parentIdTypeMap.itemId;

    return parentType;
  };

  getParentId = (): any => {
    const { orderId, jobId, itemId } = this.props;

    return orderId || jobId || itemId;
  };

  handleDrop = (acceptedFiles: Array<Object>, rejectedFiles: Array<Object>) => {
    if (rejectedFiles.length) {
      Actions.alertNotification(
        i18n.t(
          'There was an issue uploading your files. The maximum upload size is 50 mb. Please try again.',
        ),
        'error',
      );
      return;
    }

    const uploadables = {};

    for (const file of acceptedFiles) {
      uploadables[file.name] = file;
    }

    this.setState(
      {
        uploading: true,
      },
      async () => {
        const { orderId, jobId, itemId } = this.props;
        const parentIdTypeMap = {
          jobId: 'Job Ticket',
          orderId: 'Order',
          itemId: 'Inventory',
        };
        const page =
          (orderId && parentIdTypeMap.orderId) ||
          (jobId && parentIdTypeMap.jobId) ||
          (itemId && parentIdTypeMap.itemId);

        try {
          await AddAttachmentsMutation.commit({
            variables: {
              input: {
                parentType: this.getParentType(),
                parentId: this.getParentId(),
              },
            },
            uploadables,
          });

          Analytics.trackEvent('Upload Attachment', {
            page,
          });

          Actions.alertNotification(
            i18n.t('{{totalFiles}} file(s) successfully uploaded', {
              totalFiles: Object.keys(uploadables).length,
            }),
            'success',
          );

          this.setState({
            uploading: false,
          });
        } catch (e) {
          Actions.alertNotification(e.message, 'error');
          this.setState({
            uploading: false,
          });
        }
      },
    );
  };

  render() {
    const { uploading } = this.state;
    const { orderId, jobId, itemId } = this.props;

    if (uploading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    return (
      <QueryRenderer
        environment={relayEnvironment}
        variables={{
          parentType: this.getParentType(),
          parentId: this.getParentId(),
        }}
        query={graphql`
          query AttachmentsQuery(
            $parentType: AttachmentParentType!
            $parentId: ID!
          ) {
            attachments(
              first: null
              parentType: $parentType
              parentId: $parentId
            )
              @connection(
                key: "Attachments_attachments"
                filters: ["parentId"]
              ) {
              edges {
                node {
                  id
                  name
                  urlPath
                  thumbnailUrlPath
                  createdAt
                  user {
                    id
                    firstName
                    lastName
                  }
                }
                ...AttachmentsGrid_attachmentEdges
              }
            }
          }
        `}
        render={({ props }: *) => {
          if (!props) {
            return (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            );
          }

          const attachments = props.attachments.edges
            ? props.attachments.edges.filter(Boolean).map(edge => edge.node)
            : [];

          return (
            <Dropzone
              disableClick
              maxSize={MAX_UPLOAD_SIZE_MB}
              onDrop={this.handleDrop}
            >
              {({ getRootProps, getInputProps, isDragActive, open }) => (
                <Wrapper {...getRootProps()}>
                  <input {...getInputProps()} />

                  {isDragActive ? (
                    <DragActive />
                  ) : (
                    <>
                      <MobileUploadInstructions>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            open();
                          }}
                        >
                          {i18n.t('Upload File')}
                        </Button>
                      </MobileUploadInstructions>
                      <DesktopUploadInstructions>
                        <Trans>
                          Drag and drop to attach files, or{' '}
                          <span
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              open();
                            }}
                          >
                            browse
                          </span>
                        </Trans>
                      </DesktopUploadInstructions>
                      {attachments.length ? (
                        <AttachmentsGrid
                          attachmentEdges={props.attachments.edges}
                          parentId={orderId || jobId || itemId}
                        />
                      ) : (
                        <EmptyListResults
                          onGraphicClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            open();
                          }}
                          graphic="attachments"
                          message={i18n.t(
                            'When you or your team add attachments, they will show here.',
                          )}
                        />
                      )}
                    </>
                  )}
                </Wrapper>
              )}
            </Dropzone>
          );
        }}
      />
    );
  }
}

export default Attachments;

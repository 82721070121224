// @flow

import React, { PureComponent } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import type { RouterHistory } from 'react-router';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, WorkflowUtils } from 'shared/utils';
import Pill from 'shared/components/common/Pill';
import JobStatusPill from 'shared/components/common/Pill/JobStatusPill';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddUpdateJobModal from 'main-app/components/AddUpdateJobModal';
import DeleteJobModal from 'main-app/components/DeleteJobModal';
import UserAvatar from 'shared/components/common/UserAvatar';
import styled from 'styled-components';
import moment from 'moment';
import type { JobsTable_jobEdges as JobEdgesFragment } from './__generated__/JobsTable_jobEdges';

type Props = {
  history: RouterHistory,
  jobEdges: JobEdgesFragment,
  onCreateNew?: void => void,
  emptyListMessage?: string,
  deleteEnabled?: boolean,
  editEnabled?: boolean,
};

type State = {
  updateJobRecord: ?Object,
  deleteJobRecord: ?Object,
};

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 8px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

class JobsTable extends PureComponent<Props, State> {
  static defaultProps = {
    onCreateNew: false,
    emptyListMessage: null,
    deleteEnabled: false,
    editEnabled: false,
  };

  menuTriggerRefs = {};
  state = {
    updateJobRecord: null,
    deleteJobRecord: null,
  };

  getJobById = (jobId: string) => {
    const { jobEdges } = this.props;

    const job = jobEdges.find(edge => {
      return edge?.node?.id === jobId;
    });

    return job?.node || null;
  };

  handleGoToOrder = (e, data, target) => {
    const { history } = this.props;
    const jobId = target.getAttribute('job-id');
    const job = this.getJobById(jobId);

    if (!job || !job.order) {
      return;
    }

    history.push(`/order/${job.order.id}`);
  };

  handleOpenNewTab = (e, data, target) => {
    const jobId = target.getAttribute('job-id');

    window.open(`/job/${jobId}`);
  };

  handleOpenUpdateModal = (e, data, target) => {
    const jobId = target.getAttribute('job-id');

    this.setState({
      updateJobRecord: this.getJobById(jobId),
    });
  };

  handleOpenDeleteModal = (e, data, target) => {
    const jobId = target.getAttribute('job-id');

    this.setState({
      deleteJobRecord: this.getJobById(jobId),
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      updateJobRecord: null,
      deleteJobRecord: null,
    });
  };

  render() {
    const {
      history,
      jobEdges,
      emptyListMessage,
      onCreateNew,
      deleteEnabled,
      editEnabled,
    } = this.props;
    const { updateJobRecord, deleteJobRecord } = this.state;

    const edges = jobEdges ? jobEdges.filter(Boolean).map(a => a.node) : [];

    if (!edges.length) {
      return (
        <EmptyListResults
          graphic="jobs"
          message={
            emptyListMessage ||
            i18n.t(
              `Your job tickets will show here. Create a new job to get started.`,
            )
          }
          onCreateNew={onCreateNew}
          createNewLabel={i18n.t('Create Job Ticket')}
        />
      );
    }

    return (
      <>
        <Table
          data={edges}
          onRowClick={rowData => history.push(`/job/${rowData.id}`)}
          // renderMobileRow={job => <MobileCell job={job} />}
          columns={[
            {
              Header: i18n.t('Job #'),
              accessor: 'jobNumber',
              width: 160,
              headerStyle: {
                textAlign: 'center',
                paddingRight: '32px',
              },
              style: {
                paddingRight: '32px',
              },
              Cell: ({ value, original }: *) => (
                <Pill onClick={() => history.push(`/job/${original.id}`)}>
                  {original.importJobNumber || original.jobNumber}
                </Pill>
              ),
            },
            {
              Header: i18n.t('Project Name'),
              accessor: 'name',
              minWidth: 200,
              Cell: ({ value }) => value || '',
            },
            {
              Header: i18n.t('Workflow Step'),
              accessor: '',
              minWidth: 250,
              style: {
                display: 'flex',
              },
              Cell: props => {
                const job = props.original;

                if (job.states.length === job.workflow.states.length) {
                  const nextJobStates = WorkflowUtils.getNextJobStates(job);

                  if (nextJobStates.length) {
                    return nextJobStates.map(state => (
                      <IconWrapper key={'icon-' + state.id}>
                        <Icon
                          type={state.iconType}
                          size={24}
                          style={{ marginRight: 4 }}
                        />
                        <span>{state.name}</span>
                      </IconWrapper>
                    ));
                  }
                }

                return '';
              },
            },
            {
              Header: i18n.t('Customer'),
              accessor: 'order.customer.name',
              maxWidth: 250,
              Cell: ({ value }) => value || '',
            },
            {
              Header: i18n.t('Items'),
              accessor: 'yields.totalCount',
              headerStyle: {
                textAlign: 'center',
              },
              style: {
                textAlign: 'center',
              },
              width: 120,
              Cell: ({ value }) =>
                i18n.t('{{totalItems}} item(s)', {
                  totalItems: value || 0,
                }),
            },
            {
              Header: i18n.t('Status'),
              accessor: 'status',
              width: 90,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value }: *) => <JobStatusPill status={value} />,
            },
            {
              Header: i18n.t('Job Owner'),
              accessor: 'user',
              width: 96,
              headerStyle: {
                textAlign: 'center',
              },
              Cell: ({ value }) => (
                <UserAvatar
                  style={{ display: 'block', margin: '0 auto' }}
                  width={24}
                  user={value}
                />
              ),
            },
            {
              Header: i18n.t('Created'),
              accessor: 'createdAt',
              width: 120,
              Cell: ({ value }: *) => moment(value).format('MM/DD/YYYY'),
            },
            {
              Header: '',
              accessor: 'id',
              width: 48,
              Cell: ({ value }: *) => (
                <ContextMenuTrigger
                  ref={r => (this.menuTriggerRefs[value] = r)}
                  id="jobs-menu"
                  attributes={{
                    'job-id': value,
                  }}
                >
                  <Icon
                    type="circle-context-menu"
                    size={24}
                    onClick={(e, data, target) => {
                      if (this.menuTriggerRefs[value]) {
                        this.menuTriggerRefs[value].handleContextClick(
                          e,
                          data,
                          target,
                        );
                      }
                    }}
                  />
                </ContextMenuTrigger>
              ),
            },
          ]}
        />
        <ContextMenu id="jobs-menu">
          {editEnabled && (
            <MenuItem onClick={this.handleOpenUpdateModal}>
              {i18n.t('Edit Job Ticket')}
            </MenuItem>
          )}
          <MenuItem onClick={this.handleOpenNewTab}>
            {i18n.t('Open In New Tab')}
          </MenuItem>
          <MenuItem onClick={this.handleGoToOrder}>
            {i18n.t('View Order')}
          </MenuItem>
          {deleteEnabled && (
            <MenuItem onClick={this.handleOpenDeleteModal}>
              {i18n.t('Delete Job')}
            </MenuItem>
          )}
        </ContextMenu>
        {updateJobRecord && (
          <AddUpdateJobModal
            history={history}
            job={updateJobRecord}
            workflow={null}
            onClose={this.handleCloseAllModals}
          />
        )}
        {deleteJobRecord && (
          <DeleteJobModal
            history={history}
            job={deleteJobRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(JobsTable, {
  jobEdges: graphql`
    fragment JobsTable_jobEdges on JobEdge @relay(plural: true) {
      node {
        id
        jobNumber
        importJobNumber
        name
        status
        createdAt
        user {
          id
          ...UserAvatar_user
        }
        yields(first: null) {
          totalCount
        }
        order {
          id
          promisedAt
          customer {
            id
            name
          }
        }
        states {
          id
        }
        workflow {
          id
          states {
            id
          }
        }
        ...AddUpdateJobModal_job
        ...DeleteJobModal_job
        ...WorkflowUtils_job @relay(mask: false)
      }
    }
  `,
});

// @flow

import React from 'react';
import * as Yup from 'yup';
// import { Trans } from 'react-i18next';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import SignInMutation from 'main-app/mutations/SignIn';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import Page from 'main-app/components/Page';
import PageHeaderLogo from 'main-app/components/PageHeaderLogo';
import Button from 'shared/components/common/Button';
import { colors, fonts } from 'shared/styleguide';

const Wrapper = styled.div`
  padding: 24px 16px 16px 16px;

  @media (min-width: 1000px) {
    padding-top: 48px;
    margin: 0 auto;
    width: 480px;
  }
`;

const Header = styled.div`
  ${fonts.largeTitle};
  color: ${colors.azul};
  padding-bottom: 24px;
`;

// const SubHeader = styled.div`
//   ${fonts.bodyRegular};
//   color: ${colors.charcoalGrey};
//   padding-bottom: 42px;
// `;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.lightBlueGrey};
  margin: 16px 0 40px 0;
`;

export default () => (
  <Page hideMenu noPadding hideToolbar>
    <PageHeaderLogo />
    <Wrapper>
      <Header>{i18n.t('Welcome Back')}</Header>
      {/* TODO: bring back when we support self-serv
      <SubHeader>
        <Trans>
          {`Don't have an account? `}
          <Link to="/signup">Sign Up</Link>
        </Trans>
      </SubHeader> */}
      <Formik
        initialValues={{
          companyName: '',
          email: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          companyName: Yup.string().required('Required'),
          email: Yup.string()
            .email('Invalid Email')
            .required('Required'),
          password: Yup.string().required('Required'),
        })}
        onSubmit={async (values, { props, setSubmitting }) => {
          setSubmitting(true);
          try {
            // Execute the mutation
            await SignInMutation.commit({
              variables: {
                input: {
                  namespace: values.companyName,
                  email: values.email,
                  password: values.password,
                },
              },
            });

            window.location = '/';
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message || e, 'error');
          }
        }}
        render={({ errors, isValid, isSubmitting, handleSubmit }) => (
          <Form>
            <FieldGroup
              label={i18n.t('Your Company Name')}
              name="companyName"
              placeholder={i18n.t('Example, Inc')}
              extendedLabel=".workclout.com"
              error={errors.companyName}
            />
            <FieldGroup
              label={i18n.t('Email Address')}
              name="email"
              type="email"
              placeholder={i18n.t('johndoe@example.com')}
              error={errors.email}
            />
            <FieldGroup
              label={i18n.t('Password')}
              name="password"
              type="password"
              placeholder="Password"
              error={errors.password}
            />
            {/* FIXME: switch out for common divider component */}
            <Divider />
            <Button
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t('Sign In')}
            </Button>
          </Form>
        )}
      />
    </Wrapper>
  </Page>
);

/**
 * @flow
 * @relayHash 776dcedf21c2381e03c5ba96703298e5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserAvatar_user$ref = any;
export type OrderStatus = "CLOSED" | "OPEN";
export type AddOrderInput = {|
  customerId: string,
  userId: string,
  customerOrderNumber?: ?string,
  shippingAddressLine1?: ?string,
  shippingAddressLine2?: ?string,
  shippingAddressLine3?: ?string,
  shippingAddressCity?: ?string,
  shippingAddressState?: ?string,
  shippingAddressZip?: ?string,
  notes?: ?string,
  orderedAt?: ?any,
  requiredAt?: ?any,
  promisedAt?: ?any,
|};
export type AddOrderMutationVariables = {|
  input: AddOrderInput
|};
export type AddOrderMutationResponse = {|
  +addOrder: {|
    +orderEdge: {|
      +node: {|
        +id: string,
        +orderNumber: string,
        +customerOrderNumber: ?string,
        +totalJobs: number,
        +status: OrderStatus,
        +shippingAddressLine1: ?string,
        +shippingAddressLine2: ?string,
        +shippingAddressLine3: ?string,
        +shippingAddressCity: ?string,
        +shippingAddressState: ?string,
        +shippingAddressZip: ?string,
        +orderedAt: ?any,
        +requiredAt: ?any,
        +promisedAt: ?any,
        +customer: {|
          +id: string,
          +name: string,
        |},
        +user: ?{|
          +id: string,
          +firstName: string,
          +lastName: string,
          +$fragmentRefs: UserAvatar_user$ref,
        |},
      |}
    |}
  |}
|};
export type AddOrderMutation = {|
  variables: AddOrderMutationVariables,
  response: AddOrderMutationResponse,
|};
*/


/*
mutation AddOrderMutation(
  $input: AddOrderInput!
) {
  addOrder(input: $input) {
    orderEdge {
      node {
        id
        orderNumber
        customerOrderNumber
        totalJobs
        status
        shippingAddressLine1
        shippingAddressLine2
        shippingAddressLine3
        shippingAddressCity
        shippingAddressState
        shippingAddressZip
        orderedAt
        requiredAt
        promisedAt
        customer {
          id
          name
        }
        user {
          id
          firstName
          lastName
          ...UserAvatar_user
        }
      }
    }
  }
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddOrderInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "customerOrderNumber",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalJobs",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shippingAddressLine1",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shippingAddressLine2",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shippingAddressLine3",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shippingAddressCity",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shippingAddressState",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shippingAddressZip",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderedAt",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requiredAt",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "promisedAt",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "customer",
  "storageKey": null,
  "args": null,
  "concreteType": "Customer",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddOrderMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addOrder",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddOrderPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "orderEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "OrderEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "UserAvatar_user",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddOrderMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addOrder",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddOrderPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "orderEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "OrderEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddOrderMutation",
    "id": null,
    "text": "mutation AddOrderMutation(\n  $input: AddOrderInput!\n) {\n  addOrder(input: $input) {\n    orderEdge {\n      node {\n        id\n        orderNumber\n        customerOrderNumber\n        totalJobs\n        status\n        shippingAddressLine1\n        shippingAddressLine2\n        shippingAddressLine3\n        shippingAddressCity\n        shippingAddressState\n        shippingAddressZip\n        orderedAt\n        requiredAt\n        promisedAt\n        customer {\n          id\n          name\n        }\n        user {\n          id\n          firstName\n          lastName\n          ...UserAvatar_user\n        }\n      }\n    }\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3059c4d0f5ceb18a29a14e415ced4056';
module.exports = node;

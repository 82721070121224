// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateIntegrationEventMutation(
    $input: UpdateIntegrationEventInput!
  ) {
    updateIntegrationEvent(input: $input) {
      integrationEventEdge {
        node {
          id
          originRule
          lambdaOnly
          lambdaId
          eventType {
            id
            name
            cosmeticName
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

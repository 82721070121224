// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import * as Yup from 'yup';
import * as Actions from 'main-app/store/Actions';
import { i18n, Analytics } from 'shared/utils';
import UpdateFormFieldMutation from 'main-app/mutations/UpdateFormField';
import { FieldTypeConfigs } from 'shared/components/form/FormBuilder';
import {
  FormAutoSaver,
  Formik,
  FieldGroup,
  FieldLabel,
  FieldMessage,
  FieldArray,
} from 'shared/components/form';
import Divider from 'shared/components/common/Divider';
import Icon from 'shared/components/common/Icon';
import IconWithLabel from 'shared/components/common/Icon/IconWithLabel';
import type { FormFieldForm_formField as FormFieldFragment } from './__generated__/FormFieldForm_formField';

Yup.addMethod(Yup.array, 'unique', function(message, mapper = a => a) {
  return this.test('unique', message, function(list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

type Props = {
  formField: FormFieldFragment,
};

type State = {
  selectableOptionsExpanded: boolean,
};

const Content = styled.div`
  flex: 1;
  padding-right: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Left = styled.div`
  flex: 1;
`;

const Right = styled.div`
  padding-left: 16px;

  @media (min-width: 1000px) {
    width: 240px;
  }
`;

const SelectableOptionsToggle = styled.div`
  @media (min-width: 1000px) {
    &:hover {
      cursor: pointer;
    }
  }
`;

const OptionRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-bottom: 16px;

  @media (min-width: 1000px) {
    align-items: center;
  }
`;

class FormFieldForm extends PureComponent<Props, State> {
  state = {
    selectableOptionsExpanded: true,
  };

  handleToggleSelectableOptions = () => {
    const { selectableOptionsExpanded } = this.state;

    this.setState({
      selectableOptionsExpanded: !selectableOptionsExpanded,
    });
  };

  render() {
    const { formField } = this.props;
    const { selectableOptionsExpanded } = this.state;

    return (
      <Formik
        isInitialValid
        initialValues={{
          name: formField.name,
          type: { value: formField.type },
          description: formField.description || '',
          placeholder: formField.placeholder || '',
          isCreatable: formField.isCreatable || false,
          options: formField.options
            ? formField.options.map(option => option.value)
            : [],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          type: Yup.object().required('Required'),
          options: Yup.array()
            .of(Yup.string().required('Required'))
            .unique('Duplicate Options not allowed!'),
        })}
        render={({
          errors,
          values,
          handleSubmit,
          submitForm,
          isValid,
          dirty,
        }) => (
          <>
            <FormAutoSaver
              // values={values}
              onSave={async values => {
                if (!isValid) {
                  return;
                }

                try {
                  await UpdateFormFieldMutation.commit({
                    variables: {
                      input: {
                        id: formField.id,
                        name: values.name,
                        description: values.description,
                        placeholder: values.placeholder,
                        isCreatable: values.isCreatable,
                        options: values.options,
                        type: values.type.value,
                      },
                    },
                  });

                  Analytics.trackEvent('Update Form Field', {
                    fieldName: values.name,
                    fieldType: values.type.value,
                    placeholderText: values.placeholder,
                  });

                  Actions.alertNotification(
                    i18n.t('Form Successfully Saved'),
                    'success',
                  );
                } catch (e) {
                  Actions.alertNotification(e.message, 'error');
                }
              }}
            />
            <Content>
              <Row>
                <Left>
                  <FieldGroup
                    name="name"
                    label={i18n.t('Field Name')}
                    placeholder={i18n.t('Field Name')}
                    error={errors.name}
                  />
                </Left>
                <Right>
                  <FieldGroup
                    type="select"
                    label={i18n.t('Field Type')}
                    name="type"
                    placeholder={i18n.t('Field Type')}
                    options={Object.keys(FieldTypeConfigs).map(key => ({
                      label: FieldTypeConfigs[key].configLabel,
                      value: key,
                    }))}
                    error={errors.type}
                  />
                </Right>
              </Row>
              <Row>
                <Left>
                  <FieldGroup
                    name="placeholder"
                    label={i18n.t('Placeholder Text')}
                    placeholder={i18n.t('Placeholder Text')}
                  />
                </Left>
                <Right>
                  {values.type.value === 'SELECT' && (
                    <FieldGroup
                      type="toggle"
                      label={i18n.t('Creatable Options?')}
                      description={i18n.t(
                        'Allows creation of new options as the form is being filled out.',
                      )}
                      name="isCreatable"
                    />
                  )}
                </Right>
              </Row>
              {(values.type.value === 'SELECT' ||
                values.type.value === 'MULTISELECT') && (
                <>
                  <Divider style={{ marginBottom: 24 }} />
                  <FieldLabel style={{ paddingBottom: 16 }}>
                    <SelectableOptionsToggle
                      onClick={this.handleToggleSelectableOptions}
                    >
                      {i18n.t('Selectable Options')}&nbsp;
                      {selectableOptionsExpanded ? <>&#9650;</> : <>&#9660;</>}
                    </SelectableOptionsToggle>
                  </FieldLabel>
                  {selectableOptionsExpanded && (
                    <FieldArray
                      name="options"
                      render={arrayHelpers => (
                        <>
                          {!values.options?.length
                            ? null
                            : values.options.map((item, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <OptionRow key={`item-${i}`}>
                                  <Icon
                                    type="circle-minus"
                                    size={24}
                                    onClick={() => arrayHelpers.remove(i)}
                                    style={{ marginRight: 16 }}
                                  />
                                  <FieldGroup
                                    name={`options.${i}`}
                                    error={
                                      errors.options &&
                                      Array.isArray(errors.options)
                                        ? errors.options[i]
                                        : null
                                    }
                                    size="small"
                                    style={{ paddingBottom: 0 }}
                                  />
                                </OptionRow>
                              ))}
                          {errors.options &&
                          typeof errors.options === 'string' ? (
                            <FieldMessage
                              type="error"
                              style={{ paddingBottom: 16 }}
                            >
                              {errors.options}
                            </FieldMessage>
                          ) : null}
                          <IconWithLabel
                            type="circle-plus"
                            label={i18n.t('Add Option')}
                            onClick={() => arrayHelpers.push('')}
                          />
                        </>
                      )}
                    />
                  )}
                </>
              )}
            </Content>
          </>
        )}
      />
    );
  }
}

export default createFragmentContainer(FormFieldForm, {
  formField: graphql`
    fragment FormFieldForm_formField on FormField {
      id
      name
      description
      placeholder
      type
      displayOrder
      isCreatable
      properties
      options {
        id
        name
        value
      }
    }
  `,
});

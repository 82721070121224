/**
 * @flow
 * @relayHash 626cc86d35373e940cfea681a4a55806
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OEEPanelQueryVariables = {|
  oeeStartAt: any,
  oeeEndAt: any,
|};
export type OEEPanelQueryResponse = {|
  +metricsOEE: {|
    +oee: number,
    +machineAvailabilityRate: number,
    +machinePerformanceRate: number,
    +qualityRate: number,
  |},
  +metricsMachineDowntimes: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +percentage: number,
  |}>,
  +metricsMachinePerformanceRates: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +percentage: number,
  |}>,
|};
export type OEEPanelQuery = {|
  variables: OEEPanelQueryVariables,
  response: OEEPanelQueryResponse,
|};
*/


/*
query OEEPanelQuery(
  $oeeStartAt: DateTime!
  $oeeEndAt: DateTime!
) {
  metricsOEE(startAt: $oeeStartAt, endAt: $oeeEndAt) {
    oee
    machineAvailabilityRate
    machinePerformanceRate
    qualityRate
  }
  metricsMachineDowntimes(startAt: $oeeStartAt, endAt: $oeeEndAt) {
    id
    name
    percentage
  }
  metricsMachinePerformanceRates(startAt: $oeeStartAt, endAt: $oeeEndAt) {
    id
    name
    percentage
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "oeeStartAt",
    "type": "DateTime!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "oeeEndAt",
    "type": "DateTime!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "endAt",
    "variableName": "oeeEndAt"
  },
  {
    "kind": "Variable",
    "name": "startAt",
    "variableName": "oeeStartAt"
  }
],
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "percentage",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "metricsOEE",
    "storageKey": null,
    "args": (v1/*: any*/),
    "concreteType": "MetricsOEE",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "oee",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "machineAvailabilityRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "machinePerformanceRate",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "qualityRate",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "metricsMachineDowntimes",
    "storageKey": null,
    "args": (v1/*: any*/),
    "concreteType": "MetricsMachineDowntime",
    "plural": true,
    "selections": (v2/*: any*/)
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "metricsMachinePerformanceRates",
    "storageKey": null,
    "args": (v1/*: any*/),
    "concreteType": "MetricsMachinePerformanceRate",
    "plural": true,
    "selections": (v2/*: any*/)
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OEEPanelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OEEPanelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "OEEPanelQuery",
    "id": null,
    "text": "query OEEPanelQuery(\n  $oeeStartAt: DateTime!\n  $oeeEndAt: DateTime!\n) {\n  metricsOEE(startAt: $oeeStartAt, endAt: $oeeEndAt) {\n    oee\n    machineAvailabilityRate\n    machinePerformanceRate\n    qualityRate\n  }\n  metricsMachineDowntimes(startAt: $oeeStartAt, endAt: $oeeEndAt) {\n    id\n    name\n    percentage\n  }\n  metricsMachinePerformanceRates(startAt: $oeeStartAt, endAt: $oeeEndAt) {\n    id\n    name\n    percentage\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4efce851ec24cc8165931f7a1d8cf6d0';
module.exports = node;

// @flow

import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import { colors } from 'shared/styleguide';
import { i18n } from 'shared/utils';

type Props = {
  data: {
    name: string,
    quantity: number,
    quantityCompleted: number,
  }[],
};

export default ({ data }: Props) => (
  <ResponsiveContainer minHeight={250} height={Math.max(data.length * 80, 250)}>
    <BarChart
      layout="vertical"
      data={
        data.length
          ? data.map(record => ({
              name: record.name,
              // 'Quantity to Produce' here is used to determine the actual Bar's size, not what's on the label
              'Quantity to Produce':
                record.quantity - record.quantityCompleted >= 0
                  ? record.quantity - record.quantityCompleted
                  : 0,
              'Quantity Produced': record.quantityCompleted,
              'Total Quantity to Produce': record.quantity,
            }))
          : [
              {
                name: 'No Data Available for Selected Dates',
                'Quantity to Produce': 0,
                'Quantity Produced': 0,
                'Total Quantity to Produce': 0,
              },
            ]
      }
      width={500}
    >
      <XAxis
        type="number"
        tickFormatter={tick => i18n.t('{{value, number}}', { value: tick })}
        tick={{ fontSize: 14 }}
      />
      <YAxis
        dataKey="name"
        type="category"
        width={200}
        tick={{ fontSize: 14 }}
      />
      <Legend align="right" />
      <Tooltip
        cursor={{ fill: colors.veryLightBlue }}
        formatter={(value, name, { payload }) => {
          if (
            payload['Quantity Produced'] === 0 &&
            payload['Total Quantity to Produce'] === 0
          ) {
            if (
              name === 'Quantity Produced' ||
              name === 'Quantity to Produce'
            ) {
              return 0;
            }

            return payload['name'];
          } else if (name === 'Quantity Produced') {
            const percentage = Math.round(
              (payload['Quantity Produced'] /
                payload['Total Quantity to Produce']) *
                100,
            );

            return [
              i18n.t('{{value, number}} ({{percentage, number}}%)', {
                value,
                percentage,
              }),
              name,
            ];
          }

          // display the value of 'Total Quantity to Produce' as 'Quantity to Produce' to the user
          // the actual field name of 'Total Quantity to Produce' is not displayed
          return [
            i18n.t('{{totalQty, number}}', {
              totalQty: payload['Total Quantity to Produce'],
            }),
            'Quantity to Produce',
          ];
        }}
      />
      <Bar
        dataKey="Quantity Produced"
        stackId="a"
        fill={colors.sapphire}
        barSize={20}
      />
      <Bar
        dataKey="Quantity to Produce"
        stackId="a"
        fill={colors.coolGrey}
        barSize={20}
      />
    </BarChart>
  </ResponsiveContainer>
);

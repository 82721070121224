// @flow

import React from 'react';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';
import Button from 'shared/components/common/Button';

type Props = {
  onViewAttachment: () => void,
};

const Wrapper = styled.div`
  background-color: ${colors.paleGrey};
  text-align: center;
  color: ${colors.charcoalGrey};
  ${fonts.largeRegular};
  padding: 24px 0;
  border-bottom: 3px solid ${colors.paleGrey};
  margin-bottom: 64px;
  box-shadow: 0 2px 4px 0 rgba(106, 109, 123, 0.2);

  header {
    color: ${colors.azul};
    ${fonts.title};
    padding-bottom: 24px;
  }
`;

export default ({ onViewAttachment }: Props) => (
  <Wrapper>
    <header>{i18n.t('THIS JOB IS IMPORTED')}</header>
    {i18n.t(
      `The Job Ticket you're looking at has been imported from a previous system. Functionality and information may be limited.`,
    )}
    {onViewAttachment ? (
      <>
        <br />
        <br />
        <Button width="auto" onClick={onViewAttachment}>
          {i18n.t('VIEW ORIGINAL JOB TICKET')}
        </Button>
      </>
    ) : null}
  </Wrapper>
);

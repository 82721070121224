// @flow

import React from 'react';
import Loader from 'shared/components/common/Loader';

type Props = {
  queryResponse: {
    error?: ?Object,
    props?: ?Object,
  },
  error?: Object => React$Node,
  loading?: () => React$Node,
  render: () => React$Node,
};

export default ({ queryResponse, error, loading, render }: Props) => {
  if (queryResponse?.error) {
    return error ? error(queryResponse.error) : <div />;
  }

  if (!queryResponse?.props) {
    return loading ? loading() : <Loader />;
  }

  return render();
};

// @flow

import { NodeModel, DiagramEngine } from 'storm-react-diagrams';
import IconPortModel from './IconPortModel';

class IconNodeModel extends NodeModel {
  constructor(icon: string) {
    super(icon);
    this.data = {};
    this.childNodeIds = [];
    this.parentNodeIds = [];

    if (icon === 'square-job-created') {
      this.addPort(new IconPortModel('right', icon));
    } else if (icon === 'square-job-completed') {
      this.addPort(new IconPortModel('left', icon));
    } else {
      this.addPort(new IconPortModel('right', icon));
      this.addPort(new IconPortModel('left', icon));
    }
  }

  deSerialize(ob: IconNodeModel, engine: DiagramEngine) {
    super.deSerialize(ob, engine);
    this.data = ob.data;
    this.childNodeIds = ob.childNodeIds;
    this.parentNodeIds = ob.parentNodeIds;
  }

  serialize() {
    return {
      ...super.serialize(),
      data: this.data,
      childNodeIds: this.childNodeIds,
      parentNodeIds: this.parentNodeIds,
    };
  }

  getParentNodes(engine: DiagramEngine) {
    const model = engine.getDiagramModel();
    const nodeIds = Object.keys(model.nodes);
    const parentNodes: IconNodeModel[] = nodeIds
      .filter(nodeId => this.parentNodeIds.includes(nodeId))
      .map(nodeId => model.nodes[nodeId]);

    return parentNodes;
  }

  getChildNodes(engine: DiagramEngine) {
    const model = engine.getDiagramModel();
    const nodeIds = Object.keys(model.nodes);
    const childNodes: IconNodeModel[] = nodeIds
      .filter(nodeId => this.childNodeIds.includes(nodeId))
      .map(nodeId => model.nodes[nodeId]);

    return childNodes;
  }

  getDescendantNodes(engine: DiagramEngine) {
    const model = engine.getDiagramModel();
    const childNodes = Object.keys(model.nodes)
      .filter(nodeId => this.childNodeIds.includes(nodeId))
      .map(nodeId => model.nodes[nodeId]);

    if (childNodes.length) {
      return [
        ...childNodes,
        ...childNodes
          .map(child => child.getDescendantNodes(engine))
          .reduce((accumulator = [], current) => accumulator.concat(current)),
      ];
    } else {
      return [];
    }
  }
}

export default IconNodeModel;

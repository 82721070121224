// @flow

import * as dagre from 'dagre';
import cloneDeep from 'lodash.clonedeep';

const size = {
  width: 60,
  height: 60,
};

export default function distributeElements(model: *) {
  const clonedModel = cloneDeep(model);
  const { nodes, graphDimensions } = distributeGraph(clonedModel);

  for (const node of nodes) {
    const modelNode = clonedModel.nodes.find(item => item.id === node.id);
    modelNode.x = node.x - node.width / 2;
    modelNode.y = node.y - node.height / 2;
  }
  clonedModel.graphDimensions = graphDimensions;

  return clonedModel;
}

function distributeGraph(model) {
  const nodes = mapElements(model);
  const edges = mapEdges(model);
  const graph = new dagre.graphlib.Graph();
  graph.setGraph({
    rankDir: 'LR',
    edgesep: 220,
    nodesep: 220,
    ranksep: 220,
    marginx: 80,
    marginy: 80,
  });
  graph.setDefaultEdgeLabel(() => ({}));
  // Add elements to dagre graph
  for (const node of nodes) {
    graph.setNode(node.id, node.metadata);
  }
  for (const edge of edges) {
    if (edge.from && edge.to) {
      graph.setEdge(edge.from, edge.to);
    }
  }
  // Auto-distribute
  dagre.layout(graph);
  const dagreGraph = graph.graph();

  return {
    nodes: graph.nodes().map(node => graph.node(node)),
    graphDimensions: {
      width: dagreGraph.width,
      height: dagreGraph.height,
    },
  };
}

function mapElements(model) {
  // Dagre compatible format
  return model.nodes.map(node => ({
    id: node.id,
    metadata: { ...size, id: node.id },
  }));
}

function mapEdges(model) {
  // Returns links which connects nodes
  // We check if there are both from and to nodes in the model. Sometimes links can be detached
  return model.links
    .map(link => ({
      from: link.source,
      to: link.target,
    }))
    .filter(
      item =>
        model.nodes.find(node => node.id === item.from) &&
        model.nodes.find(node => node.id === item.to),
    );
}

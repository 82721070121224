/**
 * @flow
 * @relayHash de3786a589048e52766a5821eadbc1bd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PaginatedLotsContainer_lots$ref = any;
export type LotSortField = "BIN_NUMBER" | "CREATED_AT" | "EXPIRES_AT" | "LOT_NUMBER" | "UPDATED_AT";
export type SortDirection = "ASC" | "DESC";
export type LotSort = {|
  field: LotSortField,
  direction: SortDirection,
|};
export type PaginatedLotsContainerQueryVariables = {|
  count: number,
  cursor?: ?string,
  search?: ?string,
  sortBy?: ?LotSort,
  lotNumbers?: ?$ReadOnlyArray<string>,
  binNumbers?: ?$ReadOnlyArray<string>,
|};
export type PaginatedLotsContainerQueryResponse = {|
  +$fragmentRefs: PaginatedLotsContainer_lots$ref
|};
export type PaginatedLotsContainerQuery = {|
  variables: PaginatedLotsContainerQueryVariables,
  response: PaginatedLotsContainerQueryResponse,
|};
*/


/*
query PaginatedLotsContainerQuery(
  $count: Int!
  $cursor: String
  $search: String
  $sortBy: LotSort
  $lotNumbers: [String!]
  $binNumbers: [String!]
) {
  ...PaginatedLotsContainer_lots_1zQOzp
}

fragment PaginatedLotsContainer_lots_1zQOzp on Query {
  lots(first: $count, after: $cursor, search: $search, sortBy: $sortBy, lotNumbers: $lotNumbers, binNumbers: $binNumbers) {
    edges {
      cursor
      ...LotsTable_lotEdges
      node {
        __typename
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment LotsTable_lotEdges on LotEdge {
  node {
    id
    lotNumber
    binNumber
    quantity
    expiresAt
    ...AddUpdateLotModal_lot
  }
}

fragment AddUpdateLotModal_lot on Lot {
  id
  lotNumber
  binNumber
  expiresAt
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "LotSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lotNumbers",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "binNumbers",
    "type": "[String!]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "binNumbers",
  "variableName": "binNumbers"
},
v2 = {
  "kind": "Variable",
  "name": "lotNumbers",
  "variableName": "lotNumbers"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PaginatedLotsContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "PaginatedLotsContainer_lots",
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PaginatedLotsContainerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "lots",
        "storageKey": null,
        "args": (v5/*: any*/),
        "concreteType": "LotConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "LotEdge",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Lot",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lotNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "binNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quantity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "expiresAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "lots",
        "args": (v5/*: any*/),
        "handle": "connection",
        "key": "PaginatedLotsContainer_lots",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PaginatedLotsContainerQuery",
    "id": null,
    "text": "query PaginatedLotsContainerQuery(\n  $count: Int!\n  $cursor: String\n  $search: String\n  $sortBy: LotSort\n  $lotNumbers: [String!]\n  $binNumbers: [String!]\n) {\n  ...PaginatedLotsContainer_lots_1zQOzp\n}\n\nfragment PaginatedLotsContainer_lots_1zQOzp on Query {\n  lots(first: $count, after: $cursor, search: $search, sortBy: $sortBy, lotNumbers: $lotNumbers, binNumbers: $binNumbers) {\n    edges {\n      cursor\n      ...LotsTable_lotEdges\n      node {\n        __typename\n        id\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment LotsTable_lotEdges on LotEdge {\n  node {\n    id\n    lotNumber\n    binNumber\n    quantity\n    expiresAt\n    ...AddUpdateLotModal_lot\n  }\n}\n\nfragment AddUpdateLotModal_lot on Lot {\n  id\n  lotNumber\n  binNumber\n  expiresAt\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7070fbbf40cd0d547ea8ac64b6c7a31';
module.exports = node;

// @flow

import React from 'react';
import { localizeEnum } from 'shared/utils';
import Pill from '.';

type Props = {
  width?: string | number,
  status: 'OPEN' | 'CLOSED',
};

const ThemeStatusMap = {
  OPEN: 'blue',
  CLOSED: 'green',
};

const JobStatusPill = ({ status, width }: Props) => (
  <Pill theme={ThemeStatusMap[status]} width={width}>
    {localizeEnum(status)}
  </Pill>
);

JobStatusPill.defaultProps = {
  width: undefined,
};

export default JobStatusPill;

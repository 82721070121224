/**
 * @flow
 * @relayHash d1afe272d78b3dd799850847bc0de934
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MachineBoard_machineEdges$ref = any;
export type MachinesQueryVariables = {||};
export type MachinesQueryResponse = {|
  +machines: {|
    +edges: ?$ReadOnlyArray<?{|
      +$fragmentRefs: MachineBoard_machineEdges$ref
    |}>
  |}
|};
export type MachinesQuery = {|
  variables: MachinesQueryVariables,
  response: MachinesQueryResponse,
|};
*/


/*
query MachinesQuery {
  machines(sortBy: {field: PRODUCTION_ORDER, direction: ASC}) {
    edges {
      ...MachineBoard_machineEdges
      cursor
      node {
        __typename
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment MachineBoard_machineEdges on MachineEdge {
  node {
    id
    name
    productionOrder
    type {
      id
      name
    }
    ...MachineCard_machine
  }
}

fragment MachineCard_machine on Machine {
  id
  name
  type {
    id
    name
  }
  workflows {
    id
    name
    deletedAt
  }
  ...AddUpdateMachineModal_machine
  ...DeleteMachineModal_machine
}

fragment AddUpdateMachineModal_machine on Machine {
  id
  name
  type {
    id
    name
  }
  purchaseCost
  lifespanYears
  runQuantityPerHour
  runCostPerHour
  workflows {
    id
    name
  }
}

fragment DeleteMachineModal_machine on Machine {
  id
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = [
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": {
      "direction": "ASC",
      "field": "PRODUCTION_ORDER"
    }
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MachinesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "machines",
        "name": "__Machines_machines_connection",
        "storageKey": null,
        "args": null,
        "concreteType": "MachineConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MachineEdge",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Machine",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "MachineBoard_machineEdges",
                "args": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MachinesQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "machines",
        "storageKey": "machines(sortBy:{\"direction\":\"ASC\",\"field\":\"PRODUCTION_ORDER\"})",
        "args": (v3/*: any*/),
        "concreteType": "MachineConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MachineEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Machine",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "productionOrder",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "type",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "MachineType",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "workflows",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Workflow",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "deletedAt",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "purchaseCost",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lifespanYears",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "runQuantityPerHour",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "runCostPerHour",
                    "args": null,
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ]
              },
              (v0/*: any*/)
            ]
          },
          (v2/*: any*/)
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "machines",
        "args": (v3/*: any*/),
        "handle": "connection",
        "key": "Machines_machines",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "MachinesQuery",
    "id": null,
    "text": "query MachinesQuery {\n  machines(sortBy: {field: PRODUCTION_ORDER, direction: ASC}) {\n    edges {\n      ...MachineBoard_machineEdges\n      cursor\n      node {\n        __typename\n        id\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment MachineBoard_machineEdges on MachineEdge {\n  node {\n    id\n    name\n    productionOrder\n    type {\n      id\n      name\n    }\n    ...MachineCard_machine\n  }\n}\n\nfragment MachineCard_machine on Machine {\n  id\n  name\n  type {\n    id\n    name\n  }\n  workflows {\n    id\n    name\n    deletedAt\n  }\n  ...AddUpdateMachineModal_machine\n  ...DeleteMachineModal_machine\n}\n\nfragment AddUpdateMachineModal_machine on Machine {\n  id\n  name\n  type {\n    id\n    name\n  }\n  purchaseCost\n  lifespanYears\n  runQuantityPerHour\n  runCostPerHour\n  workflows {\n    id\n    name\n  }\n}\n\nfragment DeleteMachineModal_machine on Machine {\n  id\n  name\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "machines"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7bcef164061901000eaa780c5786b06d';
module.exports = node;

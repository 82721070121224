// @flow

import React, { PureComponent } from 'react';
import { i18n, Analytics } from 'shared/utils';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { colors, fonts } from 'shared/styleguide';
import * as Actions from 'main-app/store/Actions';
import UpdateViewerAvatarMutation from 'main-app/mutations/UpdateViewerAvatar';
import Button from 'shared/components/common/Button';
import { Link } from 'react-router-dom';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import UserAvatar from 'shared/components/common/UserAvatar';
import Loader from 'shared/components/common/Loader';
import DragActive from 'shared/components/common/DragActive';
import Divider from 'shared/components/common/Divider';
import DefaultAvatars from './DefaultAvatars';
import type { UpdateViewerAvatarModal_user as UserFragment } from './__generated__/UpdateViewerAvatarModal_user';

const MAX_UPLOAD_SIZE_MB = 2 * 1000 * 1000;
const CDN_URL =
  'https://bucketeer-a4493a50-2635-4655-b662-2d5095810a9f.s3.amazonaws.com/public/static/default-avatars/';

type Props = {
  onClose: () => void,
  user: ?UserFragment,
};

type State = {
  uploading: boolean,
  defaultAvatarSelection: string,
};

const SpanText = styled.span`
  margin-left: 24px;
  display: inline-block;
  color: ${colors.charcoalGrey};
  ${fonts.largeBold}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  img {
    border: 2px dashed ${colors.coolGrey};
    margin-bottom: 16px;
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;

    img {
      margin-bottom: 0;
    }
  }
`;

// displays loader in the center
const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

class UpdateViewerAvatarModal extends PureComponent<Props, State> {
  state = {
    uploading: false,
    defaultAvatarSelection: '',
  };

  onDefaultAvatarClick = name => {
    this.setState({
      defaultAvatarSelection: name,
    });
  };

  handleSubmit = () => {
    this.setState(
      {
        uploading: true,
      },
      async () => {
        try {
          const { onClose } = this.props;
          const { defaultAvatarSelection } = this.state;
          await UpdateViewerAvatarMutation.commit({
            variables: {
              input: {
                avatar: CDN_URL + defaultAvatarSelection + '.png',
              },
            },
          });
          Analytics.trackEvent('Update Avatar', {
            avatarType: 'workclout default',
          });
          Actions.alertNotification(
            i18n.t('Your Avatar Has Been Updated!'),
            'success',
          );
          this.setState({
            uploading: false,
          });
          onClose();
        } catch (e) {
          Actions.alertNotification(e.message, 'error');
          this.setState({
            uploading: false,
          });
        }
      },
    );
  };

  handleDrop = (files: Array<?Object>) => {
    this.setState(
      {
        uploading: true,
        defaultAvatarSelection: '',
      },
      async () => {
        try {
          const { onClose } = this.props;
          await UpdateViewerAvatarMutation.commit({
            variables: {
              input: {
                avatar: '',
              },
            },
            uploadables: {
              avatar: files[0],
            },
          });
          Actions.alertNotification(
            i18n.t('Your Avatar Has Been Updated!'),
            'success',
          );
          this.setState({
            uploading: false,
          });
          onClose();
        } catch (e) {
          Actions.alertNotification(e.message, 'error');
          this.setState({
            uploading: false,
          });
        }
      },
    );
  };

  render() {
    const { uploading, defaultAvatarSelection } = this.state;
    const { onClose, user } = this.props;

    if (uploading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    return (
      <Modal maxWidth={700}>
        <ModalHeader header={i18n.t('Update Avatar')} onClose={onClose} />
        <ModalBody withPadding>
          <Dropzone
            disableClick
            maxSize={MAX_UPLOAD_SIZE_MB}
            onDrop={this.handleDrop}
          >
            {({ getRootProps, getInputProps, isDragActive, open }) => (
              <Wrapper {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <DragActive />
                ) : (
                  <AvatarWrapper>
                    <UserAvatar width={100} user={user} />
                    <SpanText>
                      {i18n.t('Drag and drop to upload an image, or ')}
                      <Link
                        to="#"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          open();
                        }}
                      >
                        {i18n.t('browse')}
                      </Link>
                    </SpanText>
                  </AvatarWrapper>
                )}
              </Wrapper>
            )}
          </Dropzone>
          <Divider />
          <DefaultAvatars
            handleClick={this.onDefaultAvatarClick}
            selection={defaultAvatarSelection}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="blue"
            onClick={this.handleSubmit}
            disabled={defaultAvatarSelection ? false : true}
          >
            {i18n.t('Update Avatar')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(UpdateViewerAvatarModal, {
  user: graphql`
    fragment UpdateViewerAvatarModal_user on User {
      id
      avatar
      firstName
      ...UserAvatar_user
    }
  `,
});

/**
 * @flow
 * @relayHash cb6db8f44cdfa10a4f9d93fdfa9a42ab
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddUpdateDowntimeCategoryModal_downtimeCategory$ref = any;
export type AddDowntimeCategoryInput = {|
  name: string,
  parentDowntimeCategoryId?: ?string,
|};
export type AddDowntimeCategoryMutationVariables = {|
  input: AddDowntimeCategoryInput
|};
export type AddDowntimeCategoryMutationResponse = {|
  +addDowntimeCategory: {|
    +downtimeCategoryEdge: {|
      +cursor: string,
      +node: {|
        +id: string,
        +name: string,
        +updatedAt: any,
        +createdAt: any,
        +parentCategory: ?{|
          +id: string,
          +name: string,
        |},
        +childCategories: {|
          +edges: ?$ReadOnlyArray<?{|
            +node: {|
              +id: string,
              +name: string,
              +$fragmentRefs: AddUpdateDowntimeCategoryModal_downtimeCategory$ref,
            |}
          |}>
        |},
        +$fragmentRefs: AddUpdateDowntimeCategoryModal_downtimeCategory$ref,
      |},
    |}
  |}
|};
export type AddDowntimeCategoryMutation = {|
  variables: AddDowntimeCategoryMutationVariables,
  response: AddDowntimeCategoryMutationResponse,
|};
*/


/*
mutation AddDowntimeCategoryMutation(
  $input: AddDowntimeCategoryInput!
) {
  addDowntimeCategory(input: $input) {
    downtimeCategoryEdge {
      cursor
      node {
        id
        name
        updatedAt
        createdAt
        parentCategory {
          id
          name
        }
        childCategories {
          edges {
            node {
              id
              name
              ...AddUpdateDowntimeCategoryModal_downtimeCategory
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        ...AddUpdateDowntimeCategoryModal_downtimeCategory
      }
    }
  }
}

fragment AddUpdateDowntimeCategoryModal_downtimeCategory on DowntimeCategory {
  id
  parentCategory {
    id
    name
  }
  childCategories {
    totalCount
  }
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddDowntimeCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "parentCategory",
  "storageKey": null,
  "args": null,
  "concreteType": "DowntimeCategory",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "FragmentSpread",
  "name": "AddUpdateDowntimeCategoryModal_downtimeCategory",
  "args": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddDowntimeCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDowntimeCategory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddDowntimeCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "downtimeCategoryEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "DowntimeCategoryEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DowntimeCategory",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": "childCategories",
                    "name": "__DowntimeCategories_downtimeCategories_childCategories_connection",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DowntimeCategoryConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DowntimeCategoryEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DowntimeCategory",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      (v10/*: any*/)
                    ]
                  },
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddDowntimeCategoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addDowntimeCategory",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddDowntimeCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "downtimeCategoryEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "DowntimeCategoryEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DowntimeCategory",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "childCategories",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DowntimeCategoryConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DowntimeCategoryEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DowntimeCategory",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "childCategories",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "DowntimeCategoryConnection",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/)
                                ]
                              },
                              (v8/*: any*/)
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedHandle",
                    "alias": null,
                    "name": "childCategories",
                    "args": null,
                    "handle": "connection",
                    "key": "DowntimeCategories_downtimeCategories_childCategories",
                    "filters": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddDowntimeCategoryMutation",
    "id": null,
    "text": "mutation AddDowntimeCategoryMutation(\n  $input: AddDowntimeCategoryInput!\n) {\n  addDowntimeCategory(input: $input) {\n    downtimeCategoryEdge {\n      cursor\n      node {\n        id\n        name\n        updatedAt\n        createdAt\n        parentCategory {\n          id\n          name\n        }\n        childCategories {\n          edges {\n            node {\n              id\n              name\n              ...AddUpdateDowntimeCategoryModal_downtimeCategory\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        ...AddUpdateDowntimeCategoryModal_downtimeCategory\n      }\n    }\n  }\n}\n\nfragment AddUpdateDowntimeCategoryModal_downtimeCategory on DowntimeCategory {\n  id\n  parentCategory {\n    id\n    name\n  }\n  childCategories {\n    totalCount\n  }\n  name\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "addDowntimeCategory",
            "downtimeCategoryEdge",
            "node",
            "childCategories"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64d03140dbcf5e0e7329d58c35d930fd';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import { graphql, fetchQuery, createFragmentContainer } from 'react-relay';
import { fonts } from 'shared/styleguide';
import styled from 'styled-components';
import relayEnvironment from 'shared/gql/relayEnvironment';
import * as Actions from 'main-app/store/Actions';
import AddJobMutation from 'main-app/mutations/AddJob';
import AddJobYieldMutation from 'main-app/mutations/AddJobYield';
import UpdateJobMutation from 'main-app/mutations/UpdateJob';
import Button from 'shared/components/common/Button';
import Divider from 'shared/components/common/Divider';
import Icon from 'shared/components/common/Icon';
import Loader from 'shared/components/common/Loader';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldArray,
  FieldGroup,
  FieldGroupRow,
  SelectUserField,
  SelectOrderField,
  SelectWorkflowField,
  SelectItemField,
} from 'shared/components/form';
import type { AddUpdateJobModal_job as JobFragment } from './__generated__/AddUpdateJobModal_job';
import type { AddUpdateJobModal_workflow as WorkflowFragment } from './__generated__/AddUpdateJobModal_workflow';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  onReopen?: () => void,
  createAnotherJob?: boolean,
  duplicateJob?: boolean,
  orderId?: string,
  job: ?JobFragment,
  workflow: ?WorkflowFragment,
  companyId?: ?string,
};

type State = {
  order?: Object,
  loading: boolean,
};

const IconSelectItemFieldWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-top: 8px;
`;

const ItemRow = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`;

const Label = styled.div`
  ${fonts.bodyBold};
`;

const LoaderWrapper = styled.div`
  height: 300px;
`;

const AddItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 140px;
  margin-bottom: 24px;

  &:hover,
  *:hover {
    cursor: pointer;
  }
`;

const LANGUAGE = {
  update: {
    title: i18n.t('Edit Job Ticket'),
    submitButton: i18n.t('Update Job Ticket'),
    successTitle: i18n.t('Job Successfully Updated'),
    successBody: userAssigned =>
      userAssigned && i18n.t('The assignee has been notified.'),
  },
  add: {
    title: i18n.t('Create Job Ticket'),
    submitButton: i18n.t('Create Job Ticket'),
    successTitle: i18n.t('Job Successfully Created'),
    successBody: userAssigned =>
      i18n.t(
        'The assignee has been notified. Now create an item and assign Job Details!',
      ),
  },
  duplicate: {
    title: i18n.t('Duplicate Job Ticket'),
    submitButton: i18n.t('Duplicate Job Ticket'),
    successTitle: i18n.t('Job Successfully Duplicated'),
    successBody: userAssigned => i18n.t('Duplicate Job Added Successfully'),
  },
};

const OVER_UNDER_OPTIONS = {
  NO_OVERS: {
    label: 'No Overs',
    value: 'NO_OVERS',
  },
  NO_UNDERS: {
    label: 'No Unders',
    value: 'NO_UNDERS',
  },
  ALLOW_OVERS: {
    label: 'Allow Overs',
    value: 'ALLOW_OVERS',
  },
};

class AddUpdateJobModal extends PureComponent<Props, State> {
  state = {
    order: null,
    loading: true,
  };

  static defaultProps = {
    onSuccess: undefined,
    onReopen: undefined,
    createAnotherJob: false,
    duplicateJob: false,
    orderId: undefined,
    companyId: undefined,
  };

  componentDidMount() {
    const { duplicateJob, orderId } = this.props;

    if (!duplicateJob && orderId) {
      this.loadOrder(orderId);
    } else {
      this.setState({ loading: false });
    }
  }

  loadOrder = async (orderId: string) => {
    const { order } = await fetchQuery(
      relayEnvironment,
      graphql`
        query AddUpdateJobModalQuery($id: ID!) {
          order(id: $id) {
            id
            yields {
              edges {
                node {
                  id
                  orderedQuantity
                  item {
                    id
                    name
                    quantityUOM {
                      id
                      symbol
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        id: orderId,
      },
    );

    this.setState({
      order,
      loading: false,
    });
  };

  getOrder = () => {
    const { orderId, job } = this.props;
    const { order } = this.state;

    let result = null;

    if (job?.order) {
      result = {
        value: job?.order?.id,
        data: order,
      };
    } else if (orderId) {
      result = {
        value: orderId,
        data: order,
      };
    }

    return result;
  };

  getWorkflow = () => {
    const { workflow, duplicateJob, job } = this.props;

    // If duplicateJob, then return null and let the user choose the workflow
    let result = null;

    if (workflow) {
      result = {
        value: workflow.id,
        label: workflow.name,
        data: {
          hasGangRun: workflow.states.some(state => state.isGangRun),
        },
      };
    } else if (!duplicateJob && job) {
      result = {
        value: job.workflow.id,
        label: job.workflow.name,
        data: {
          hasGangRun: job.workflow.states.some(state => state.isGangRun),
        },
      };
    }

    return result;
  };

  render() {
    const {
      job,
      onClose,
      onSuccess,
      onReopen,
      createAnotherJob,
      duplicateJob,
      companyId,
    }: Props = this.props;
    const { loading } = this.state;

    const createJob = Boolean(!job);
    const editJob = Boolean(!duplicateJob && job);
    const language =
      LANGUAGE[duplicateJob ? 'duplicate' : job ? 'update' : 'add'];
    const order: any = this.getOrder();
    const workflow = this.getWorkflow();

    if (loading) {
      return (
        <Modal maxWidth={700}>
          <ModalHeader header={language.title} onClose={onClose} />
          <ModalBody withPadding>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" theme="blue" disabled>
              {language.submitButton}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    return (
      <Modal maxWidth={700}>
        <Formik
          initialValues={{
            workflow,
            createAnotherJob,
            user: job?.user
              ? {
                  value: job?.user?.id,
                }
              : // FIXME: this is strictly here for UPBX since user is a required field
              companyId === 'e4e8b3c2-b4f3-47cd-a6ec-38c69c1ae86d'
              ? {
                  value: 'ef08a502-d56c-4d0b-b62c-17de2326f864',
                }
              : undefined,
            name: job?.name || '',
            order,
            jobYields: editJob
              ? null
              : (
                  (job?.yields?.edges?.length && job?.yields.edges) ||
                  (order?.data?.yields?.edges.length &&
                    order.data.yields.edges) ||
                  []
                )
                  .filter(Boolean)
                  .map((edge: any) => ({
                    item: {
                      value: edge.node.item.id,
                      data: edge.node.item,
                      label: edge.node.item.name,
                    },
                    quantity:
                      (duplicateJob && edge.node.quantity) ||
                      edge.node.orderedQuantity ||
                      '',
                    itemNumberUp: 1,
                    brokerCustomerOrderNumber:
                      edge.node.brokerCustomerOrderNumber || '',
                    overUnderRule: edge.node.overUnderRule
                      ? OVER_UNDER_OPTIONS[edge.node.overUnderRule]
                      : '',
                    overQuantity: edge.node.overQuantity || '',
                  })),
            notes: job?.notes || '',
          }}
          validationSchema={Yup.object().shape({
            createAnotherJob: Yup.boolean(),
            workflow: Yup.object()
              .nullable()
              .required('Required'),
            user: Yup.object()
              .nullable()
              .required('Required'),
            name: Yup.string().required('Required'),
            order: Yup.object().nullable(),
            jobYields: Yup.array()
              .when('workflow', {
                is: workflow => Boolean(workflow?.data?.hasGangRun),
                then: Yup.array().of(
                  Yup.object().shape({
                    item: Yup.object()
                      .nullable()
                      .required('Required'),
                    quantity: Yup.string()
                      .nullable()
                      .required('Required'),
                    overQuantity: Yup.string().nullable(),
                    itemNumberUp: Yup.string()
                      .nullable()
                      .required('Required'),
                    brokerCustomerOrderNumber: Yup.string(),
                    overUnderRule: Yup.object().nullable(),
                  }),
                ),
                otherwise: Yup.array().of(
                  Yup.object().shape({
                    item: Yup.object()
                      .nullable()
                      .required('Required'),
                    quantity: Yup.string()
                      .nullable()
                      .required('Required'),
                    overQuantity: Yup.string().nullable(),
                    itemNumberUp: Yup.string().nullable(),
                    brokerCustomerOrderNumber: Yup.string(),
                    overUnderRule: Yup.object().nullable(),
                  }),
                ),
              })
              .nullable(),
          })}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const mutation = editJob ? UpdateJobMutation : AddJobMutation;
            const input: any = {
              name: values.name,
              userId: values?.user?.value || null,
              orderId: values?.order?.value || null,
              notes: values.notes,
            };

            if (duplicateJob || createJob) {
              input.workflowId = values?.workflow?.value;
            }

            if (editJob && job) {
              input.id = job.id;
            }

            try {
              const response = await mutation.commit({
                variables: {
                  input,
                },
              });

              const newJob = (response.updateJob || response.addJob).jobEdge
                .node;

              if ((duplicateJob || createJob) && values.jobYields?.length) {
                for (const jobYield of values.jobYields) {
                  const jobYieldInput: any = {
                    jobId: newJob.id,
                    itemId: jobYield.item.value,
                    overUnderRule: jobYield.overUnderRule?.value || null,
                    overQuantity:
                      jobYield.overUnderRule?.value &&
                      jobYield.overUnderRule?.value !== 'NO_OVERS' &&
                      jobYield.overQuantity
                        ? parseFloat(jobYield.overQuantity)
                        : null,
                    quantity: jobYield.quantity
                      ? parseFloat(jobYield.quantity)
                      : null,
                    itemNumberUp: jobYield.itemNumberUp
                      ? parseInt(jobYield.itemNumberUp)
                      : 1,
                    brokerCustomerOrderNumber:
                      jobYield.brokerCustomerOrderNumber,
                  };

                  const addJobYieldResponse = await AddJobYieldMutation.commit({
                    variables: {
                      input: jobYieldInput,
                    },
                  });

                  Analytics.trackEvent('Add Item on Job Ticket Form', {
                    inventoryNumber:
                      addJobYieldResponse.addJobYield.jobYieldEdge.node.item
                        .itemNumber,
                    unitsToProduce:
                      addJobYieldResponse.addJobYield.jobYieldEdge.node
                        .quantity,
                    numberUp:
                      addJobYieldResponse.addJobYield.jobYieldEdge.node
                        .itemNumberUp,
                  });
                }
              }

              if (values.createAnotherJob && onReopen) {
                onReopen();
              } else {
                onClose();

                if (onSuccess) {
                  onSuccess(newJob);
                }
              }

              Actions.alertNotification(
                {
                  title: language.successTitle,
                  body: language.successBody(values.user?.value),
                },
                'success',
              );

              if (createJob) {
                Analytics.trackEvent('Create Job Ticket', {
                  jobNumber: newJob.jobNumber,
                  orderNumber: newJob.order?.orderNumber,
                  jobOwner: newJob.user.firstName + ' ' + newJob.user.lastName,
                  workflow: newJob.workflow.name,
                });
              }
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(e.message, 'Something Went Wrong');
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
            dirty,
          }) => (
            <Form>
              <ModalHeader header={language.title} onClose={onClose} />
              <ModalBody withPadding>
                <FieldGroupRow
                  left={
                    <SelectOrderField
                      label={i18n.t(`Order`)}
                      name="order"
                      clearable
                      error={errors.order}
                    />
                  }
                  right={
                    <SelectUserField
                      name="user"
                      label={i18n.t('Job Owner')}
                      error={errors.user}
                      tooltip={i18n.t(
                        'The job owner will receive regular updates when changes are made to a job ticket.',
                      )}
                    />
                  }
                />
                <Divider style={{ marginBottom: 24 }} />
                {editJob ? (
                  <FieldGroup
                    name="workflow"
                    label={i18n.t('Workflow')}
                    type="select"
                    options={[workflow]}
                    error={errors.workflow}
                    disabled
                  />
                ) : (
                  <SelectWorkflowField
                    name="workflow"
                    clearable
                    error={errors.workflow}
                  />
                )}
                <FieldGroup
                  name="name"
                  label={i18n.t('Project')}
                  placeholder={i18n.t('Project Name')}
                  error={errors.name}
                />
                {(duplicateJob || createJob) && (
                  <FieldArray
                    name="jobYields"
                    render={arrayHelpers => (
                      <>
                        {values.jobYields?.length !== 0 &&
                          values.jobYields.map((jobYield, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`item-${i}`}>
                              <Divider style={{ marginBottom: 24 }} />
                              <ItemRow>
                                <Label>{i18n.t('Item')}</Label>
                                <IconSelectItemFieldWrapper>
                                  <Icon
                                    type="circle-minus"
                                    size={24}
                                    onClick={() => arrayHelpers.remove(i)}
                                    style={{
                                      marginTop: 6,
                                      marginRight: 16,
                                    }}
                                  />
                                  <SelectItemField
                                    name={`jobYields.${i}.item`}
                                    label={null}
                                    error={
                                      errors.jobYields &&
                                      errors.jobYields[i]?.item
                                    }
                                    queryVariables={
                                      !duplicateJob && values.order
                                        ? {
                                            orderIds: [values.order.value],
                                          }
                                        : undefined
                                    }
                                  />
                                </IconSelectItemFieldWrapper>
                              </ItemRow>
                              <FieldGroupRow
                                left={
                                  <FieldGroup
                                    name={`jobYields.${i}.quantity`}
                                    type="number"
                                    label={i18n.t('Quantity To Produce')}
                                    extendedLabel={
                                      jobYield.item?.data?.quantityUOM?.symbol
                                    }
                                    error={
                                      errors.jobYields &&
                                      errors.jobYields[i]?.quantity
                                    }
                                  />
                                }
                                center={
                                  values.workflow?.data?.hasGangRun && (
                                    <FieldGroup
                                      name={`jobYields.${i}.itemNumberUp`}
                                      type="number"
                                      label={i18n.t('# Up')}
                                      error={
                                        errors.jobYields &&
                                        errors.jobYields[i]?.itemNumberUp
                                      }
                                      tooltip={i18n.t(
                                        '# Up is used there is a gang run associated to the workflow. WorkClout will automatically total the quantities based on on the # Up for this item.',
                                      )}
                                    />
                                  )
                                }
                                right={
                                  <FieldGroup
                                    name={`jobYields.${i}.brokerCustomerOrderNumber`}
                                    label={i18n.t(`Broker Customer PO #`)}
                                    error={
                                      errors.jobYields
                                        ?.brokerCustomerOrderNumber
                                    }
                                    tooltip={i18n.t(
                                      'If your customers are brokers, you can reference their PO# here.',
                                    )}
                                  />
                                }
                              />
                              <FieldGroupRow
                                left={
                                  <FieldGroup
                                    name={`jobYields.${i}.overUnderRule`}
                                    label={i18n.t('Allow Overs / Unders?')}
                                    placeholder={i18n.t(`Overs or Unders`)}
                                    type="select"
                                    options={Object.values(OVER_UNDER_OPTIONS)}
                                    error={
                                      errors.jobYields &&
                                      errors.jobYields[i]?.overUnderRule
                                    }
                                  />
                                }
                                right={
                                  <FieldGroup
                                    name={`jobYields.${i}.overQuantity`}
                                    type="number"
                                    label={i18n.t(`Over Quantity`)}
                                    placeholder={i18n.t(`#`)}
                                    disabled={
                                      values.jobYields[i]?.overUnderRule
                                        ?.value !== 'NO_UNDERS' &&
                                      values.jobYields[i]?.overUnderRule
                                        ?.value !== 'ALLOW_OVERS'
                                    }
                                    extendedLabel={
                                      jobYield.item?.data?.quantityUOM?.symbol
                                    }
                                    error={
                                      errors.jobYields &&
                                      errors.jobYields[i]?.overQuantity
                                    }
                                  />
                                }
                              />
                            </div>
                          ))}
                        {(duplicateJob || createJob) && (
                          <>
                            <Divider style={{ marginBottom: 24 }} />
                            <AddItemIconWrapper
                              onClick={() =>
                                arrayHelpers.push({
                                  item: '',
                                  quantity: '',
                                  itemNumberUp: '',
                                  brokerCustomerOrderNumber: '',
                                  overUnderRule: '',
                                  overQuantity: '',
                                })
                              }
                            >
                              <Icon
                                type="circle-plus"
                                size={24}
                                style={{ marginRight: 16 }}
                              />
                              <Label>{i18n.t('Add Item')}</Label>
                            </AddItemIconWrapper>
                          </>
                        )}
                      </>
                    )}
                  />
                )}
                <Divider style={{ marginTop: 8, marginBottom: 24 }} />
                <FieldGroup
                  name="notes"
                  type="textarea"
                  label={i18n.t('Notes')}
                  placeholder={i18n.t('Enter notes here...')}
                  error={errors.notes}
                />
              </ModalBody>
              <ModalFooter
                style={{
                  alignItems: 'center',
                }}
              >
                {createJob && (
                  <FieldGroup
                    label={i18n.t('Create Another Job')}
                    sideLabel="right"
                    name="createAnotherJob"
                    error={errors.createAnotherJob}
                    type="toggle"
                  />
                )}
                <Button
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {language.submitButton}
                </Button>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(AddUpdateJobModal, {
  job: graphql`
    fragment AddUpdateJobModal_job on Job {
      id
      name
      notes
      user {
        id
      }
      workflow {
        id
        name
        states {
          id
          isGangRun
        }
      }
      order {
        id
      }
      yields(first: null) {
        edges {
          node {
            id
            quantity
            itemNumberUp
            brokerCustomerOrderNumber
            formFieldValues
            overUnderRule
            overQuantity
            item {
              id
              name
              quantityUOM {
                id
                name
                symbol
              }
            }
          }
        }
      }
    }
  `,
  workflow: graphql`
    fragment AddUpdateJobModal_workflow on Workflow {
      id
      name
      states {
        id
        isGangRun
      }
    }
  `,
});

// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  intro?: string,
  title?: string | React$Node,
  left?: React$Node,
  right?: React$Node,
  style?: Object,
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`;

const HeaderRight = styled.div`
  padding-left: 16px;

  @media (min-width: 1000px) {
    min-width: 250px;
  }
`;

const HeaderRightList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  li {
    padding-left: 16px;
  }
`;

const Intro = styled.div`
  ${fonts.smallRegular};
  color: ${colors.battleshipGrey};
  padding-bottom: 8px;
`;

const Title = styled.div`
  ${fonts.title};
  color: ${colors.charcoalGrey};
  flex: 1;
`;

const PageHeader = ({ intro, title, left, right, style }: Props) => (
  <Wrapper style={style}>
    <div>
      {intro && <Intro>{intro}</Intro>}
      {title && <Title>{title}</Title>}
      {left}
    </div>
    {right ? (
      <HeaderRight>
        <HeaderRightList>
          {Array.isArray(right) ? (
            right.map((node, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i}>{node}</li>
            ))
          ) : (
            <li>{right}</li>
          )}
        </HeaderRightList>
      </HeaderRight>
    ) : null}
  </Wrapper>
);

PageHeader.defaultProps = {
  intro: undefined,
  title: undefined,
  left: undefined,
  right: undefined,
  style: undefined,
};

export default PageHeader;

// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';

type Props = {
  itemCategoryId: string,
};

export default ({ itemCategoryId }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    query={graphql`
      query ItemCategoryLabelQuery($itemCategoryId: ID!) {
        itemCategory(id: $itemCategoryId) {
          id
          name
        }
      }
    `}
    variables={{ itemCategoryId }}
    render={query => (
      <span>
        {query.props
          ? query.props.itemCategory?.name || 'Unknown Item Category'
          : '. . .'}
      </span>
    )}
  />
);

// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation AddIntegrationMutation($input: AddIntegrationInput!) {
    addIntegration(input: $input) {
      integrationEdge {
        node {
          id
          source {
            id
            name
            description
            logo
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: *) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: ROOT_ID,
          connectionInfo: [
            {
              key: 'Integrations_integrations',
              rangeBehavior: 'prepend',
            },
          ],
          edgeName: 'integrationEdge',
        },
      ],
    });
  },
};

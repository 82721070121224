/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateOrderYieldModal_orderYield$ref: FragmentReference;
declare export opaque type AddUpdateOrderYieldModal_orderYield$fragmentType: AddUpdateOrderYieldModal_orderYield$ref;
export type AddUpdateOrderYieldModal_orderYield = {|
  +id: string,
  +order: {|
    +id: string
  |},
  +orderedQuantity: number,
  +item: {|
    +id: string,
    +name: string,
    +quantityUOM: {|
      +id: string,
      +name: string,
      +symbol: string,
    |},
  |},
  +$refType: AddUpdateOrderYieldModal_orderYield$ref,
|};
export type AddUpdateOrderYieldModal_orderYield$data = AddUpdateOrderYieldModal_orderYield;
export type AddUpdateOrderYieldModal_orderYield$key = {
  +$data?: AddUpdateOrderYieldModal_orderYield$data,
  +$fragmentRefs: AddUpdateOrderYieldModal_orderYield$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AddUpdateOrderYieldModal_orderYield",
  "type": "OrderYield",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "order",
      "storageKey": null,
      "args": null,
      "concreteType": "Order",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "orderedQuantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "item",
      "storageKey": null,
      "args": null,
      "concreteType": "Item",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "quantityUOM",
          "storageKey": null,
          "args": null,
          "concreteType": "UOM",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "symbol",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d3af74c22c6603e0930b1e9f700f132';
module.exports = node;

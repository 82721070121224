// @flow

import React from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import UpdateCompanyMutation from 'main-app/mutations/UpdateCompany';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Form, Formik } from 'shared/components/form';
import type { OperatingHoursModal_company as CompanyFragment } from './__generated__/OperatingHoursModal_company';
import OperatingHoursField from './OperatingHoursField';

type Props = {
  onClose: () => void,
  company: CompanyFragment,
};

const TIME_FIELD_FORMAT = 'HH:mm';
const TIMESTAMP_FORMAT = 'HH:mm:ss.SSSZ';

const getInitialTimeValue = time => {
  if (!time) {
    return '';
  }

  return moment(time, TIMESTAMP_FORMAT).format(TIME_FIELD_FORMAT);
};

const getFieldTimeValue = (open, value) => {
  if (!open || !value) {
    return null;
  }

  return moment.utc(value, TIME_FIELD_FORMAT).format(TIMESTAMP_FORMAT);
};

// using this function format in order to keep the correct scope for validation
function validateCloseHours(value) {
  if (!this.parent[`${this.type}Open`]) return true;

  if (this.parent[`${this.type}HoursStart`] && !value) {
    return this.createError({ message: 'Required' });
  }

  if (
    moment(value, 'HH:mm:ss').isSameOrBefore(
      moment(this.parent[`${this.type}HoursStart`], 'HH:mm:ss'),
    )
  ) {
    return this.createError({ message: 'Must be after the open time' });
  }

  return true;
}

const OperatingHoursModal = ({ company, onClose }: Props) => (
  <Modal maxWidth={700} onClickAway={onClose}>
    <Formik
      initialValues={{
        sundayOpen: company.sundayHoursStart && company.sundayHoursEnd,
        sundayHoursStart: getInitialTimeValue(company.sundayHoursStart),
        sundayHoursEnd: getInitialTimeValue(company.sundayHoursEnd),

        mondayOpen: company.mondayHoursStart && company.mondayHoursEnd,
        mondayHoursStart: getInitialTimeValue(company.mondayHoursStart),
        mondayHoursEnd: getInitialTimeValue(company.mondayHoursEnd),

        tuesdayOpen: company.tuesdayHoursStart && company.tuesdayHoursEnd,
        tuesdayHoursStart: getInitialTimeValue(company.tuesdayHoursStart),
        tuesdayHoursEnd: getInitialTimeValue(company.tuesdayHoursEnd),

        wednesdayOpen: company.wednesdayHoursStart && company.wednesdayHoursEnd,
        wednesdayHoursStart: getInitialTimeValue(company.wednesdayHoursStart),
        wednesdayHoursEnd: getInitialTimeValue(company.wednesdayHoursEnd),

        thursdayOpen: company.thursdayHoursStart && company.thursdayHoursEnd,
        thursdayHoursStart: getInitialTimeValue(company.thursdayHoursStart),
        thursdayHoursEnd: getInitialTimeValue(company.thursdayHoursEnd),

        fridayOpen: company.fridayHoursStart && company.fridayHoursEnd,
        fridayHoursStart: getInitialTimeValue(company.fridayHoursStart),
        fridayHoursEnd: getInitialTimeValue(company.fridayHoursEnd),

        saturdayOpen: company.saturdayHoursStart && company.saturdayHoursEnd,
        saturdayHoursStart: getInitialTimeValue(company.saturdayHoursStart),
        saturdayHoursEnd: getInitialTimeValue(company.saturdayHoursEnd),
      }}
      validationSchema={Yup.object().shape({
        sundayOpen: Yup.mixed(),
        sundayHoursStart: Yup.string().when(['sundayHoursEnd', 'sundayOpen'], {
          is: (sundayHoursEnd, sundayOpen) =>
            sundayOpen ? Boolean(sundayHoursEnd) : false,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        sundayHoursEnd: Yup.string().test({
          name: 'sunday',
          test: validateCloseHours,
        }),

        mondayOpen: Yup.mixed(),
        mondayHoursStart: Yup.string().when(['mondayHoursEnd', 'mondayOpen'], {
          is: (mondayHoursEnd, mondayOpen) =>
            mondayOpen ? Boolean(mondayHoursEnd) : false,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        mondayHoursEnd: Yup.string().test({
          name: 'monday',
          test: validateCloseHours,
        }),

        tuesdayOpen: Yup.mixed(),
        tuesdayHoursStart: Yup.string().when(
          ['tuesdayHoursEnd', 'tuesdayOpen'],
          {
            is: (tuesdayHoursEnd, tuesdayOpen) =>
              tuesdayOpen ? Boolean(tuesdayHoursEnd) : false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          },
        ),
        tuesdayHoursEnd: Yup.string().test({
          name: 'tuesday',
          test: validateCloseHours,
        }),

        wednesdayOpen: Yup.mixed(),
        wednesdayHoursStart: Yup.string().when(
          ['wednesdayHoursEnd', 'wednesdayOpen'],
          {
            is: (wednesdayHoursEnd, wednesdayOpen) =>
              wednesdayOpen ? Boolean(wednesdayHoursEnd) : false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          },
        ),
        wednesdayHoursEnd: Yup.string().test({
          name: 'wednesday',
          test: validateCloseHours,
        }),

        thursdayOpen: Yup.mixed(),
        thursdayHoursStart: Yup.string().when(
          ['thursdayHoursEnd', 'thursdayOpen'],
          {
            is: (thursdayHoursEnd, thursdayOpen) =>
              thursdayOpen ? Boolean(thursdayHoursEnd) : false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          },
        ),
        thursdayHoursEnd: Yup.string().test({
          name: 'thursday',
          test: validateCloseHours,
        }),

        fridayOpen: Yup.mixed(),
        fridayHoursStart: Yup.string().when(['fridayHoursEnd', 'fridayOpen'], {
          is: (fridayHoursEnd, fridayOpen) =>
            fridayOpen ? Boolean(fridayHoursEnd) : false,
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
        fridayHoursEnd: Yup.string().test({
          name: 'friday',
          test: validateCloseHours,
        }),

        saturdayOpen: Yup.mixed(),
        saturdayHoursStart: Yup.string().when(
          ['saturdayHoursEnd', 'saturdayOpen'],
          {
            is: (saturdayHoursEnd, saturdayOpen) =>
              saturdayOpen ? Boolean(saturdayHoursEnd) : false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          },
        ),
        saturdayHoursEnd: Yup.string().test({
          name: 'saturday',
          test: validateCloseHours,
        }),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        const input = {
          sundayHoursStart: getFieldTimeValue(
            values.sundayOpen,
            values.sundayHoursStart,
          ),
          sundayHoursEnd: getFieldTimeValue(
            values.sundayOpen,
            values.sundayHoursEnd,
          ),
          mondayHoursStart: getFieldTimeValue(
            values.mondayOpen,
            values.mondayHoursStart,
          ),
          mondayHoursEnd: getFieldTimeValue(
            values.mondayOpen,
            values.mondayHoursEnd,
          ),
          tuesdayHoursStart: getFieldTimeValue(
            values.tuesdayOpen,
            values.tuesdayHoursStart,
          ),
          tuesdayHoursEnd: getFieldTimeValue(
            values.tuesdayOpen,
            values.tuesdayHoursEnd,
          ),
          wednesdayHoursStart: getFieldTimeValue(
            values.wednesdayOpen,
            values.wednesdayHoursStart,
          ),
          wednesdayHoursEnd: getFieldTimeValue(
            values.wednesdayOpen,
            values.wednesdayHoursEnd,
          ),
          thursdayHoursStart: getFieldTimeValue(
            values.thursdayOpen,
            values.thursdayHoursStart,
          ),
          thursdayHoursEnd: getFieldTimeValue(
            values.thursdayOpen,
            values.thursdayHoursEnd,
          ),
          fridayHoursStart: getFieldTimeValue(
            values.fridayOpen,
            values.fridayHoursStart,
          ),
          fridayHoursEnd: getFieldTimeValue(
            values.fridayOpen,
            values.fridayHoursEnd,
          ),
          saturdayHoursStart: getFieldTimeValue(
            values.saturdayOpen,
            values.saturdayHoursStart,
          ),
          saturdayHoursEnd: getFieldTimeValue(
            values.saturdayOpen,
            values.saturdayHoursEnd,
          ),
        };

        try {
          await UpdateCompanyMutation.commit({
            variables: {
              input,
            },
          });

          onClose();
          Analytics.trackEvent('Update Operating Hours');
          Actions.alertNotification(
            i18n.t(`Operating Hours updated`),
            'success',
          );
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader
            header={i18n.t('Update Operating Hours')}
            onClose={onClose}
          />
          <ModalBody withPadding>
            <OperatingHoursField
              name="sundayOpen"
              label={i18n.t('Sunday')}
              hoursStartFieldName="sundayHoursStart"
              hoursEndFieldName="sundayHoursEnd"
              hoursEndError={errors.sundayHoursEnd}
              hoursStartError={errors.sundayHoursStart}
              isOpen={values.sundayOpen}
            />
            <OperatingHoursField
              name="mondayOpen"
              label={i18n.t('Monday')}
              hoursStartFieldName="mondayHoursStart"
              hoursEndFieldName="mondayHoursEnd"
              hoursEndError={errors.mondayHoursEnd}
              hoursStartError={errors.mondayHoursStart}
              isOpen={values.mondayOpen}
            />
            <OperatingHoursField
              name="tuesdayOpen"
              label={i18n.t('Tuesday')}
              hoursStartFieldName="tuesdayHoursStart"
              hoursEndFieldName="tuesdayHoursEnd"
              hoursEndError={errors.tuesdayHoursEnd}
              hoursStartError={errors.tuesdayHoursStart}
              isOpen={values.tuesdayOpen}
            />
            <OperatingHoursField
              name="wednesdayOpen"
              label={i18n.t('Wednesday')}
              hoursStartFieldName="wednesdayHoursStart"
              hoursEndFieldName="wednesdayHoursEnd"
              hoursEndError={errors.wednesdayHoursEnd}
              hoursStartError={errors.wednesdayHoursStart}
              isOpen={values.wednesdayOpen}
            />
            <OperatingHoursField
              name="thursdayOpen"
              label={i18n.t('Thursday')}
              hoursStartFieldName="thursdayHoursStart"
              hoursEndFieldName="thursdayHoursEnd"
              hoursEndError={errors.thursdayHoursEnd}
              hoursStartError={errors.thursdayHoursStart}
              isOpen={values.thursdayOpen}
            />
            <OperatingHoursField
              name="fridayOpen"
              label={i18n.t('Friday')}
              hoursStartFieldName="fridayHoursStart"
              hoursEndFieldName="fridayHoursEnd"
              hoursEndError={errors.fridayHoursEnd}
              hoursStartError={errors.fridayHoursStart}
              isOpen={values.fridayOpen}
            />
            <OperatingHoursField
              name="saturdayOpen"
              label={i18n.t('Saturday')}
              hoursStartFieldName="saturdayHoursStart"
              hoursEndFieldName="saturdayHoursEnd"
              hoursEndError={errors.saturdayHoursEnd}
              hoursStartError={errors.saturdayHoursStart}
              isOpen={values.saturdayOpen}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t('Update Operating Hours')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(OperatingHoursModal, {
  company: graphql`
    fragment OperatingHoursModal_company on Company {
      id
      sundayHoursStart
      sundayHoursEnd
      mondayHoursStart
      mondayHoursEnd
      tuesdayHoursStart
      tuesdayHoursEnd
      wednesdayHoursStart
      wednesdayHoursEnd
      thursdayHoursStart
      thursdayHoursEnd
      fridayHoursStart
      fridayHoursEnd
      saturdayHoursStart
      saturdayHoursEnd
    }
  `,
});

// @flow

import React from 'react';
import { BaseWidget, BaseWidgetProps } from 'storm-react-diagrams';
import IconNodeModel from './IconNodeModel';

type Props = BaseWidgetProps & {
  name: string,
  node: IconNodeModel,
  style?: Object,
};

type State = {
  selected: boolean,
};

class IconPortWidget extends BaseWidget<Props, State> {
  static defaultProps = {
    style: {},
  };

  constructor(props: Props) {
    super('srd-port', props);
    this.state = {
      selected: false,
    };
  }

  getClassName() {
    return (
      'port ' +
      super.getClassName() +
      (this.state.selected ? this.bem('--selected') : '')
    );
  }

  render() {
    const { style } = this.props;

    return (
      <div
        {...this.getProps()}
        style={style}
        onMouseEnter={() => {
          this.setState({ selected: true });
        }}
        onMouseLeave={() => {
          this.setState({ selected: false });
        }}
        data-name={this.props.name}
        data-nodeid={this.props.node.getID()}
      />
    );
  }
}

export default IconPortWidget;

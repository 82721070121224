/**
 * @flow
 * @relayHash 3f495f2fd73ace2824138addea43f3f8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AddUpdateIntegrationEventModal_integrationEvent$ref = any;
type DeleteIntegrationEventModal_integrationEvent$ref = any;
type IntegrationForm_integration$ref = any;
export type IntegrationOriginRule = "SOURCE_TO_WC" | "TWO_WAY" | "WC_TO_SOURCE";
export type IntegrationQueryVariables = {|
  id: string
|};
export type IntegrationQueryResponse = {|
  +integration: {|
    +id: string,
    +source: {|
      +id: string,
      +name: string,
    |},
    +events: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: {|
          +id: string,
          +eventType: {|
            +id: string,
            +cosmeticName: string,
          |},
          +originRule: IntegrationOriginRule,
          +$fragmentRefs: AddUpdateIntegrationEventModal_integrationEvent$ref & DeleteIntegrationEventModal_integrationEvent$ref,
        |}
      |}>
    |},
    +$fragmentRefs: IntegrationForm_integration$ref,
  |}
|};
export type IntegrationQuery = {|
  variables: IntegrationQueryVariables,
  response: IntegrationQueryResponse,
|};
*/


/*
query IntegrationQuery(
  $id: ID!
) {
  integration(id: $id) {
    id
    source {
      id
      name
    }
    events {
      edges {
        node {
          id
          eventType {
            id
            cosmeticName
          }
          originRule
          ...AddUpdateIntegrationEventModal_integrationEvent
          ...DeleteIntegrationEventModal_integrationEvent
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    ...IntegrationForm_integration
  }
}

fragment AddUpdateIntegrationEventModal_integrationEvent on IntegrationEvent {
  id
  originRule
  lambdaOnly
  lambdaId
  eventType {
    id
    name
  }
}

fragment DeleteIntegrationEventModal_integrationEvent on IntegrationEvent {
  id
  eventType {
    id
    cosmeticName
  }
}

fragment IntegrationForm_integration on Integration {
  id
  metadata
  isEnabled
  source {
    id
    name
    metadataSchema
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cosmeticName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "originRule",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "IntegrationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "integration",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Integration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source",
            "storageKey": null,
            "args": null,
            "concreteType": "IntegrationSource",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "events",
            "name": "__Integration_events_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "IntegrationEventConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IntegrationEventEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IntegrationEvent",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "eventType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EventType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "AddUpdateIntegrationEventModal_integrationEvent",
                        "args": null
                      },
                      {
                        "kind": "FragmentSpread",
                        "name": "DeleteIntegrationEventModal_integrationEvent",
                        "args": null
                      }
                    ]
                  },
                  (v7/*: any*/)
                ]
              },
              (v8/*: any*/)
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "IntegrationForm_integration",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "IntegrationQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "integration",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Integration",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "source",
            "storageKey": null,
            "args": null,
            "concreteType": "IntegrationSource",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "metadataSchema",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "events",
            "storageKey": null,
            "args": null,
            "concreteType": "IntegrationEventConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IntegrationEventEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IntegrationEvent",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "eventType",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "EventType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ]
                      },
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lambdaOnly",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lambdaId",
                        "args": null,
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ]
                  },
                  (v7/*: any*/)
                ]
              },
              (v8/*: any*/)
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "events",
            "args": null,
            "handle": "connection",
            "key": "Integration_events",
            "filters": []
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "metadata",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isEnabled",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "IntegrationQuery",
    "id": null,
    "text": "query IntegrationQuery(\n  $id: ID!\n) {\n  integration(id: $id) {\n    id\n    source {\n      id\n      name\n    }\n    events {\n      edges {\n        node {\n          id\n          eventType {\n            id\n            cosmeticName\n          }\n          originRule\n          ...AddUpdateIntegrationEventModal_integrationEvent\n          ...DeleteIntegrationEventModal_integrationEvent\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    ...IntegrationForm_integration\n  }\n}\n\nfragment AddUpdateIntegrationEventModal_integrationEvent on IntegrationEvent {\n  id\n  originRule\n  lambdaOnly\n  lambdaId\n  eventType {\n    id\n    name\n  }\n}\n\nfragment DeleteIntegrationEventModal_integrationEvent on IntegrationEvent {\n  id\n  eventType {\n    id\n    cosmeticName\n  }\n}\n\nfragment IntegrationForm_integration on Integration {\n  id\n  metadata\n  isEnabled\n  source {\n    id\n    name\n    metadataSchema\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "integration",
            "events"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '628cb790e0cb74c063f6f145f030b155';
module.exports = node;

/**
 * @flow
 * @relayHash 9c40c9655491fd73fb84219a2969bcf7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormBuilder_form$ref = any;
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type JobStatus = "CLOSED" | "OPEN";
export type OverUnderRule = "ALLOW_OVERS" | "NO_OVERS" | "NO_UNDERS";
export type UpdateJobYieldInput = {|
  id: string,
  itemId: string,
  quantity?: ?number,
  itemNumberUp?: ?number,
  brokerCustomerOrderNumber?: ?string,
  overUnderRule?: ?OverUnderRule,
  overQuantity?: ?number,
  formFieldValues?: ?any,
|};
export type UpdateJobYieldMutationVariables = {|
  input: UpdateJobYieldInput
|};
export type UpdateJobYieldMutationResponse = {|
  +updateJobYield: {|
    +jobYieldEdge: {|
      +cursor: string,
      +node: {|
        +id: string,
        +quantity: number,
        +itemNumberUp: number,
        +brokerCustomerOrderNumber: ?string,
        +overUnderRule: ?OverUnderRule,
        +overQuantity: ?number,
        +formFieldValues: any,
        +form: {|
          +id: string,
          +$fragmentRefs: FormBuilder_form$ref,
        |},
        +item: {|
          +id: string,
          +itemNumber: string,
          +name: string,
          +partNumber: ?string,
          +description: ?string,
          +formFieldValues: any,
          +form: {|
            +$fragmentRefs: FormBuilder_form$ref
          |},
        |},
        +job: {|
          +id: string,
          +status: JobStatus,
          +states: $ReadOnlyArray<{|
            +id: string,
            +status: JobStateStatus,
          |}>,
        |},
      |},
    |}
  |}
|};
export type UpdateJobYieldMutation = {|
  variables: UpdateJobYieldMutationVariables,
  response: UpdateJobYieldMutationResponse,
|};
*/


/*
mutation UpdateJobYieldMutation(
  $input: UpdateJobYieldInput!
) {
  updateJobYield(input: $input) {
    jobYieldEdge {
      cursor
      node {
        id
        quantity
        itemNumberUp
        brokerCustomerOrderNumber
        overUnderRule
        overQuantity
        formFieldValues
        form {
          id
          ...FormBuilder_form
        }
        item {
          id
          itemNumber
          name
          partNumber
          description
          formFieldValues
          form {
            ...FormBuilder_form
            id
          }
        }
        job {
          id
          status
          states {
            id
            status
          }
        }
      }
    }
  }
}

fragment FormBuilder_form on Form {
  id
  name
  type
  systemKey
  productionType {
    id
  }
  fields {
    id
    systemKey
    type
    name
    description
    placeholder
    isRequired
    isCreatable
    applicationType
    displayOrder
    properties
    options {
      name
      value
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateJobYieldInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemNumberUp",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "brokerCustomerOrderNumber",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "overUnderRule",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "overQuantity",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "formFieldValues",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "FragmentSpread",
  "name": "FormBuilder_form",
  "args": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemNumber",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "partNumber",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "job",
  "storageKey": null,
  "args": null,
  "concreteType": "Job",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v15/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "states",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v15/*: any*/)
      ]
    }
  ]
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemKey",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "form",
  "storageKey": null,
  "args": null,
  "concreteType": "Form",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v12/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "productionType",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductionType",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fields",
      "storageKey": null,
      "args": null,
      "concreteType": "FormField",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v18/*: any*/),
        (v17/*: any*/),
        (v12/*: any*/),
        (v14/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "placeholder",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isRequired",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isCreatable",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "applicationType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "displayOrder",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "properties",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "options",
          "storageKey": null,
          "args": null,
          "concreteType": "FormFieldOption",
          "plural": true,
          "selections": [
            (v12/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "value",
              "args": null,
              "storageKey": null
            },
            (v3/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateJobYieldMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJobYield",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJobYieldPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "jobYieldEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "JobYieldEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobYield",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "form",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Form",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v10/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "item",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Item",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v9/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "form",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Form",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v16/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateJobYieldMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateJobYield",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateJobYieldPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "jobYieldEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "JobYieldEdge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "JobYield",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v19/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "item",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Item",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v9/*: any*/),
                      (v19/*: any*/)
                    ]
                  },
                  (v16/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateJobYieldMutation",
    "id": null,
    "text": "mutation UpdateJobYieldMutation(\n  $input: UpdateJobYieldInput!\n) {\n  updateJobYield(input: $input) {\n    jobYieldEdge {\n      cursor\n      node {\n        id\n        quantity\n        itemNumberUp\n        brokerCustomerOrderNumber\n        overUnderRule\n        overQuantity\n        formFieldValues\n        form {\n          id\n          ...FormBuilder_form\n        }\n        item {\n          id\n          itemNumber\n          name\n          partNumber\n          description\n          formFieldValues\n          form {\n            ...FormBuilder_form\n            id\n          }\n        }\n        job {\n          id\n          status\n          states {\n            id\n            status\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment FormBuilder_form on Form {\n  id\n  name\n  type\n  systemKey\n  productionType {\n    id\n  }\n  fields {\n    id\n    systemKey\n    type\n    name\n    description\n    placeholder\n    isRequired\n    isCreatable\n    applicationType\n    displayOrder\n    properties\n    options {\n      name\n      value\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '986e204a6552ae9004007659b606d6d3';
module.exports = node;

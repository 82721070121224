/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateOrderModal_customer$ref: FragmentReference;
declare export opaque type AddUpdateOrderModal_customer$fragmentType: AddUpdateOrderModal_customer$ref;
export type AddUpdateOrderModal_customer = {|
  +id: string,
  +shippingAddressLine1: ?string,
  +shippingAddressLine2: ?string,
  +shippingAddressLine3: ?string,
  +shippingAddressCity: ?string,
  +shippingAddressState: ?string,
  +shippingAddressZip: ?string,
  +$refType: AddUpdateOrderModal_customer$ref,
|};
export type AddUpdateOrderModal_customer$data = AddUpdateOrderModal_customer;
export type AddUpdateOrderModal_customer$key = {
  +$data?: AddUpdateOrderModal_customer$data,
  +$fragmentRefs: AddUpdateOrderModal_customer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AddUpdateOrderModal_customer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine1",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine2",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressLine3",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressCity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressState",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shippingAddressZip",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a9af31a5a5b8c9daab986e9c61a62edf';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import UpdateIntegrationMutation from 'main-app/mutations/UpdateIntegration';
import RevokeOauthAccessMutation from 'main-app/mutations/RevokeOauthAccess';
import * as Actions from 'main-app/store/Actions';
import {
  Formik,
  Form,
  FieldGroup,
  FormAutoSaver,
} from 'shared/components/form';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Button from 'shared/components/common/Button';
import Panel from 'shared/components/common/Panel';
import Loader from 'shared/components/common/Loader';
import type { IntegrationForm_integration as IntegrationFragment } from './__generated__/IntegrationForm_integration';

/*
{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "integrationType": "oauth2",
  "properties": {
    "orgId": {
      "type": "string",
      "title": "Org ID",
      "description": "Your ZoHo Inventory Organization ID"
    },
    "authToken": {
      "type": "string",
      "title": "Auth Token",
      "description": "Your ZoHo Inventory API Auth Token"
    }
  },
  "required": ["orgId", "authToken"]
}
*/

type Props = {
  integration: IntegrationFragment,
};

type State = {
  isOauthAuthorized: boolean,
};

const FormWrapper = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
`;

const FormPanelWrapper = styled.div`
  padding-bottom: 24px;
  flex: 1;

  @media (min-width: 1000px) {
    padding: ${p => (p.left ? '0 16px 0 0' : '0 0 0 16px')};
  }
`;

class IntegrationForm extends PureComponent<Props, State> {
  validationSchema: Object;
  initialValues: Object;

  constructor(props: Props) {
    super(props);

    const {
      validationSchema,
      initialValues,
    } = this.buildMetadataFormProperties();

    this.validationSchema = Yup.object().shape({
      ...validationSchema,
      isEnabled: Yup.boolean().nullable(),
    });
    this.initialValues = {
      ...initialValues,
      isEnabled: props.integration.isEnabled,
    };

    this.state = {
      isOauthAuthorized: !!props.integration.metadata.isAuthorized,
    };
  }

  handleConnectOauth = uri => {
    window.location = uri;
  };

  handleRevokeOauthAccess = async integration => {
    const revokeResponse = await RevokeOauthAccessMutation.commit({
      variables: {
        input: {
          integrationId: integration.id,
        },
      },
    });

    if (revokeResponse.revokeOauthAccess.success) {
      Actions.alertNotification(
        i18n.t('Your Integration Has Been Revoked!'),
        'success',
      );

      this.setState({
        isOauthAuthorized: false,
      });
    } else {
      Actions.alertNotification(
        i18n.t('Failed to Revoke Your Integration!'),
        'error',
      );
    }
  };

  buildMetadataFormProperties() {
    const { integration } = this.props;
    const { metadataSchema } = integration.source;
    const validationSchema: Object = {};
    const initialValues = {};

    if (!metadataSchema || !metadataSchema.properties) {
      return {
        validationSchema: {},
        initialValues: {},
      };
    }

    for (const key in metadataSchema.properties) {
      if (metadataSchema.properties[key].type === 'string') {
        validationSchema[key] = Yup.string();
        initialValues[key] = integration.metadata[key] || '';
        // TODO: support different field types
      } else {
        validationSchema[key] = Yup.string();
      }

      if (metadataSchema.required && metadataSchema.required.includes(key)) {
        validationSchema[key] = validationSchema[key].required('Required');
      }
    }

    return {
      validationSchema: validationSchema,
      initialValues,
    };
  }

  renderMetadataField = (key, metadataProperty, error) => {
    return (
      <FieldGroup
        key={key}
        name={key}
        label={metadataProperty.title || key}
        description={metadataProperty.description}
        error={error}
      />
    );
  };

  renderOauth2Fields = integration => {
    const { isOauthAuthorized } = this.state;

    if (isOauthAuthorized) {
      // This customer already authorized third party service access
      return (
        <div>
          <div>
            {i18n.t(`You are connected to ${integration.source.name}.`)}
          </div>
          <Button
            onClick={this.handleRevokeOauthAccess.bind(this, integration)} // eslint-disable-line
            width="200"
          >
            {i18n.t(`Revoke Access`)}
          </Button>
        </div>
      );
    }

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        variables={{
          integrationId: integration.id,
        }}
        query={graphql`
          query IntegrationFormQuery($integrationId: ID!) {
            oauthRedirectUri(integrationId: $integrationId) {
              uri
            }
          }
        `}
        render={({ props }: *) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <Button
              onClick={this.handleConnectOauth.bind(  // eslint-disable-line
                this,
                props.oauthRedirectUri.uri,
              )}
              width="200"
            >
              {i18n.t(`Connect to ${integration.source.name}`)}
            </Button>
          );
        }}
      />
    );
  };

  render() {
    const { integration } = this.props;
    const { metadataSchema } = integration.source;

    return (
      <Formik
        validationSchema={this.validationSchema}
        initialValues={this.initialValues}
        onSubmit={() => {}}
        render={({ errors, isValid }) => (
          <>
            <FormAutoSaver
              onSave={async values => {
                const { isEnabled, ...metadata } = values;

                try {
                  await UpdateIntegrationMutation.commit({
                    variables: {
                      input: {
                        id: integration.id,
                        metadata,
                        isEnabled,
                      },
                    },
                  });

                  Actions.alertNotification(
                    i18n.t('Your Integration Has Been Updated!'),
                    'success',
                  );
                } catch (e) {
                  Actions.alertNotification(e.message, 'error');
                }
              }}
            />
            <Form>
              <FormWrapper>
                <FormPanelWrapper left>
                  <Panel title={i18n.t('Integration Settings')}>
                    {metadataSchema && metadataSchema.properties
                      ? Object.keys(metadataSchema.properties).map(key =>
                          this.renderMetadataField(
                            key,
                            metadataSchema.properties[key],
                            errors[key],
                          ),
                        )
                      : null}
                    {metadataSchema &&
                    metadataSchema.integrationType === 'oauth2'
                      ? this.renderOauth2Fields(integration)
                      : null}
                  </Panel>
                </FormPanelWrapper>
                <FormPanelWrapper>
                  <Panel title={i18n.t('Status')}>
                    <FieldGroup
                      type="toggle"
                      name="isEnabled"
                      label={i18n.t('Integration Enabled')}
                      error={errors.isEnabled}
                    />
                  </Panel>
                </FormPanelWrapper>
              </FormWrapper>
            </Form>
          </>
        )}
      />
    );
  }
}

export default createFragmentContainer(IntegrationForm, {
  integration: graphql`
    fragment IntegrationForm_integration on Integration {
      id
      metadata
      isEnabled
      source {
        id
        name
        metadataSchema
      }
    }
  `,
});

// @flow

import React from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddMachineMutation from 'main-app/mutations/AddMachine';
import AddMachineTypeMutation from 'main-app/mutations/AddMachineType';
import UpdateMachineMutation from 'main-app/mutations/UpdateMachine';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
  SelectWorkflowField,
  SelectMachineTypeField,
} from 'shared/components/form';
import type { AddUpdateMachineModal_machine as MachineFragment } from './__generated__/AddUpdateMachineModal_machine';

type Props = {
  onClose: () => void,
  machine: ?MachineFragment,
};

const AddUpdateMachineModal = ({ machine, onClose }: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        name: machine?.name || '',
        workflows: machine
          ? machine.workflows.map(workflow => ({
              value: workflow.id,
              data: workflow,
            }))
          : [],
        type:
          machine && machine?.type
            ? {
                value: machine.type.id,
                data: machine.type,
              }
            : null,
        purchaseCost: machine?.purchaseCost || '',
        lifespanYears: machine?.lifespanYears || '',
        runQuantityPerHour: machine?.runQuantityPerHour || '',
        runCostPerHour: machine?.runCostPerHour || '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        workflows: Yup.array()
          .of(Yup.object().required('Required'))
          .nullable()
          .required('Required'),
        type: Yup.object()
          .nullable()
          .required('Required'),
        purchaseCost: Yup.number(),
        lifespanYears: Yup.number(),
        runQuantityPerHour: Yup.number()
          .moreThan(
            0,
            'Please enter a value greater than 0. Work Center Production Rate metrics will not be available if you leave this field blank.',
          )
          .nullable(),
        runCostPerHour: Yup.number(),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        const mutation = machine ? UpdateMachineMutation : AddMachineMutation;

        const input: any = {
          name: values.name,
          workflowIds: values.workflows
            .filter(workflow => workflow?.value)
            .map(workflow => workflow.value),
          machineTypeId: values.type.value,
          purchaseCost: values.purchaseCost
            ? parseFloat(values.purchaseCost)
            : null,
          lifespanYears: values.lifespanYears
            ? parseInt(values.lifespanYears)
            : null,
          runQuantityPerHour: values.runQuantityPerHour
            ? parseFloat(values.runQuantityPerHour)
            : null,
          runCostPerHour: values.runCostPerHour
            ? parseFloat(values.runCostPerHour)
            : null,
        };

        if (machine) {
          input.id = machine.id;
        }

        try {
          if (values.type && values.type.__isNew__) {
            const { addMachineType } = await AddMachineTypeMutation.commit({
              variables: {
                input: {
                  name: values.type?.value,
                },
              },
            });

            input.machineTypeId = addMachineType.machineTypeEdge.node.id;
          }

          await mutation.commit({
            variables: {
              input,
            },
          });

          onClose();
          Analytics.trackEvent(`${machine ? 'Update' : 'Create'} Work Center`, {
            machineName: input.name,
            workflows: values.workflows
              .filter(workflow => workflow?.value)
              .map(workflow => workflow?.data?.name),
            machineType: values.type?.data?.name || values.type?.label,
            averageRunUnitsPerHour: input.runQuantityPerHour,
            averageRunCostPerHour: input.runCostPerHour,
          });
          Actions.alertNotification(
            i18n.t(`Work Center Successfully ${machine ? 'Updated' : 'Added'}`),
            'success',
          );
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader
            header={i18n.t(machine ? 'Edit Work Center' : 'Create Work Center')}
            onClose={onClose}
          />
          <ModalBody withPadding>
            <FieldGroup
              name="name"
              label={i18n.t('Work Center Name')}
              placeholder={i18n.t(
                machine ? 'Update' : 'Add' + ' Work Center Name',
              )}
              error={errors.name}
            />
            <SelectWorkflowField
              name="workflows"
              label={i18n.t('Workflows')}
              multi
              error={errors.workflows}
              filterPublishedWorkflows={false}
            />
            {!machine && (
              <SelectMachineTypeField
                name="type"
                creatable
                clearable
                error={errors.type}
                creatingNewRecord={values.type && values.type.__isNew__}
                tooltip={i18n.t(
                  'Select a work center type that this work center is associated to.',
                )}
              />
            )}
            <FieldGroupRow
              left={
                <FieldGroup
                  name="purchaseCost"
                  type="number"
                  label={i18n.t(`Work Center Purchase Cost`)}
                  placeholder={i18n.t('Enter a Number')}
                  error={errors.purchaseCost}
                />
              }
              right={
                <FieldGroup
                  name="lifespanYears"
                  type="number"
                  label={i18n.t(`Est. Work Center Lifespan in Years`)}
                  placeholder={i18n.t('Enter a Number')}
                  error={errors.lifespanYears}
                />
              }
            />
            <FieldGroup
              name="runQuantityPerHour"
              type="number"
              label={i18n.t(`Average Run Quantity Per Hour`)}
              placeholder={i18n.t('Enter a Number')}
              extendedLabel={i18n.t('/Hour')}
              error={errors.runQuantityPerHour}
              tooltip={i18n.t(
                'The Average Run Quantity is used to estimate the run times and provide production reports.',
              )}
            />
            <FieldGroup
              name="runCostPerHour"
              type="number"
              label={i18n.t(`Average Run Cost Per Hour`)}
              placeholder={i18n.t('Enter a Number')}
              extendedLabel={i18n.t('/Hour')}
              error={errors.runCostPerHour}
              tooltip={i18n.t(
                'The Average Run Cost is used to estimate the run costs for orders and jobs.',
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t(machine ? 'Update Work Center' : 'Add Work Center')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(AddUpdateMachineModal, {
  machine: graphql`
    fragment AddUpdateMachineModal_machine on Machine {
      id
      name
      type {
        id
        name
      }
      purchaseCost
      lifespanYears
      runQuantityPerHour
      runCostPerHour
      workflows {
        id
        name
      }
    }
  `,
});

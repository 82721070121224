/**
 * @flow
 * @relayHash aebd789558c4f4fac46116c8faf22305
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateDowntimeCategoryInput = {|
  id: string,
  name?: ?string,
  parentDowntimeCategoryId?: ?string,
|};
export type UpdateDowntimeCategoryMutationVariables = {|
  input: UpdateDowntimeCategoryInput
|};
export type UpdateDowntimeCategoryMutationResponse = {|
  +updateDowntimeCategory: {|
    +downtimeCategoryEdge: {|
      +cursor: string,
      +node: {|
        +id: string,
        +name: string,
        +parentCategory: ?{|
          +id: string,
          +name: string,
        |},
        +updatedAt: any,
        +createdAt: any,
      |},
    |}
  |}
|};
export type UpdateDowntimeCategoryMutation = {|
  variables: UpdateDowntimeCategoryMutationVariables,
  response: UpdateDowntimeCategoryMutationResponse,
|};
*/


/*
mutation UpdateDowntimeCategoryMutation(
  $input: UpdateDowntimeCategoryInput!
) {
  updateDowntimeCategory(input: $input) {
    downtimeCategoryEdge {
      cursor
      node {
        id
        name
        parentCategory {
          id
          name
        }
        updatedAt
        createdAt
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateDowntimeCategoryInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateDowntimeCategory",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDowntimeCategoryPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "downtimeCategoryEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "DowntimeCategoryEdge",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "DowntimeCategory",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "parentCategory",
                "storageKey": null,
                "args": null,
                "concreteType": "DowntimeCategory",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateDowntimeCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateDowntimeCategoryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateDowntimeCategoryMutation",
    "id": null,
    "text": "mutation UpdateDowntimeCategoryMutation(\n  $input: UpdateDowntimeCategoryInput!\n) {\n  updateDowntimeCategory(input: $input) {\n    downtimeCategoryEdge {\n      cursor\n      node {\n        id\n        name\n        parentCategory {\n          id\n          name\n        }\n        updatedAt\n        createdAt\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc13f4045428427df2e4308884483519';
module.exports = node;

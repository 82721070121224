/**
 * @flow
 * @relayHash 5a9582279300ca38168454df4a23eac6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ItemSubComponentsTree_itemChildEdges$ref = any;
export type UOMType = "LENGTH" | "QUANTITY" | "WEIGHT";
export type AddItemChildInput = {|
  parentItemId: string,
  childItemId: string,
  usageQty?: ?number,
|};
export type AddItemChildMutationVariables = {|
  input: AddItemChildInput
|};
export type AddItemChildMutationResponse = {|
  +addItemChild: {|
    +itemEdge: {|
      +node: {|
        +id: string,
        +itemNumber: string,
        +name: string,
        +upc: ?string,
        +partNumber: ?string,
        +description: ?string,
        +quantityUOM: {|
          +id: string,
          +name: string,
          +type: UOMType,
          +symbol: string,
        |},
        +weightUOM: ?{|
          +id: string,
          +name: string,
          +type: UOMType,
          +symbol: string,
        |},
        +dimensionUOM: ?{|
          +id: string,
          +name: string,
          +type: UOMType,
          +symbol: string,
        |},
        +dimensionLength: ?number,
        +dimensionWidth: ?number,
        +dimensionHeight: ?number,
        +weight: ?number,
        +childItems: {|
          +edges: ?$ReadOnlyArray<?{|
            +$fragmentRefs: ItemSubComponentsTree_itemChildEdges$ref
          |}>
        |},
      |}
    |}
  |}
|};
export type AddItemChildMutation = {|
  variables: AddItemChildMutationVariables,
  response: AddItemChildMutationResponse,
|};
*/


/*
mutation AddItemChildMutation(
  $input: AddItemChildInput!
) {
  addItemChild(input: $input) {
    itemEdge {
      node {
        id
        itemNumber
        name
        upc
        partNumber
        description
        quantityUOM {
          id
          name
          type
          symbol
        }
        weightUOM {
          id
          name
          type
          symbol
        }
        dimensionUOM {
          id
          name
          type
          symbol
        }
        dimensionLength
        dimensionWidth
        dimensionHeight
        weight
        childItems {
          edges {
            ...ItemSubComponentsTree_itemChildEdges
          }
        }
      }
    }
  }
}

fragment ItemSubComponentsTree_itemChildEdges on ItemChildEdge {
  usageQty
  node {
    id
    itemNumber
    name
    partNumber
    upc
    quantityUOM {
      id
      symbol
    }
    category {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddItemChildInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "itemNumber",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "upc",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "partNumber",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  (v4/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "type",
    "args": null,
    "storageKey": null
  },
  (v8/*: any*/)
],
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "quantityUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": (v9/*: any*/)
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "weightUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": (v9/*: any*/)
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "dimensionUOM",
  "storageKey": null,
  "args": null,
  "concreteType": "UOM",
  "plural": false,
  "selections": (v9/*: any*/)
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensionLength",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensionWidth",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dimensionHeight",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weight",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddItemChildMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addItemChild",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddItemChildPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "itemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Item",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "childItems",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItemChildConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ItemChildEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "FragmentSpread",
                            "name": "ItemSubComponentsTree_itemChildEdges",
                            "args": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddItemChildMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addItemChild",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddItemChildPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "itemEdge",
            "storageKey": null,
            "args": null,
            "concreteType": "ItemEdge",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Item",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "childItems",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ItemChildConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ItemChildEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "usageQty",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Item",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v6/*: any*/),
                              (v5/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "quantityUOM",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UOM",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v8/*: any*/)
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "category",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ItemCategory",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v4/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddItemChildMutation",
    "id": null,
    "text": "mutation AddItemChildMutation(\n  $input: AddItemChildInput!\n) {\n  addItemChild(input: $input) {\n    itemEdge {\n      node {\n        id\n        itemNumber\n        name\n        upc\n        partNumber\n        description\n        quantityUOM {\n          id\n          name\n          type\n          symbol\n        }\n        weightUOM {\n          id\n          name\n          type\n          symbol\n        }\n        dimensionUOM {\n          id\n          name\n          type\n          symbol\n        }\n        dimensionLength\n        dimensionWidth\n        dimensionHeight\n        weight\n        childItems {\n          edges {\n            ...ItemSubComponentsTree_itemChildEdges\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ItemSubComponentsTree_itemChildEdges on ItemChildEdge {\n  usageQty\n  node {\n    id\n    itemNumber\n    name\n    partNumber\n    upc\n    quantityUOM {\n      id\n      symbol\n    }\n    category {\n      id\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '001f8ed6450b37526de475f04ea09f9c';
module.exports = node;

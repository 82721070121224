// @flow

import { graphql } from 'react-relay';
import { ROOT_ID } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      id: string,
    },
  },
  context?: {
    parentId?: string,
  },
};

const mutation = graphql`
  mutation DeleteDowntimeCategoryMutation(
    $input: DeleteDowntimeCategoryInput!
  ) {
    deleteDowntimeCategory(input: $input) {
      deletedId
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: ROOT_ID,
          connectionKeys: [{ key: 'DowntimeCategories_downtimeCategories' }],
          pathToConnection: [ROOT_ID, 'downtimeCategories'],
          deletedIDFieldName: 'deletedId',
        },
        {
          type: 'RANGE_DELETE',
          parentID: options.context?.parentId,
          connectionKeys: [
            { key: 'DowntimeCategories_downtimeCategories_childCategories' },
          ],
          pathToConnection: ['downtimeCategories', 'childCategories'],
          deletedIDFieldName: 'deletedId',
        },
      ],
    });
  },
};

/**
 * @flow
 * @relayHash 298c1e27d2a68248c38219ddf3012c3e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAPIKeyInput = {|
  name: string,
  actingUserId: string,
|};
export type AddAPIKeyMutationVariables = {|
  input: AddAPIKeyInput
|};
export type AddAPIKeyMutationResponse = {|
  +addAPIKey: {|
    +organization: {|
      +id: string,
      +apiKeys: $ReadOnlyArray<{|
        +id: string,
        +name: string,
        +secret: string,
        +lastUsedAt: ?any,
        +updatedAt: any,
        +createdAt: any,
        +actingUser: {|
          +id: string,
          +firstName: string,
          +lastName: string,
          +email: any,
          +avatar: ?string,
        |},
      |}>,
    |}
  |}
|};
export type AddAPIKeyMutation = {|
  variables: AddAPIKeyMutationVariables,
  response: AddAPIKeyMutationResponse,
|};
*/


/*
mutation AddAPIKeyMutation(
  $input: AddAPIKeyInput!
) {
  addAPIKey(input: $input) {
    organization {
      id
      apiKeys {
        id
        name
        secret
        lastUsedAt
        updatedAt
        createdAt
        actingUser {
          id
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddAPIKeyInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addAPIKey",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddAPIKeyPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": null,
        "concreteType": "Organization",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "apiKeys",
            "storageKey": null,
            "args": null,
            "concreteType": "APIKey",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "secret",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastUsedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actingUser",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "avatar",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddAPIKeyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddAPIKeyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddAPIKeyMutation",
    "id": null,
    "text": "mutation AddAPIKeyMutation(\n  $input: AddAPIKeyInput!\n) {\n  addAPIKey(input: $input) {\n    organization {\n      id\n      apiKeys {\n        id\n        name\n        secret\n        lastUsedAt\n        updatedAt\n        createdAt\n        actingUser {\n          id\n          firstName\n          lastName\n          email\n          avatar\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bdeaca56244114872cd3b48669662c3d';
module.exports = node;

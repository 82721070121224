// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      jobId: string,
      ...*,
    },
  },
};

const mutation = graphql`
  mutation AddRunMutation($input: AddRunInput!) {
    addRun(input: $input) {
      runEdge {
        cursor
        node {
          id
          status
          notes
          scheduledStartAt
          scheduledEndAt
          notes
          jobState {
            id
            workflowState {
              id
              name
            }
          }
          machine {
            id
            name
            type {
              id
              name
            }
          }
          yields(first: null) {
            edges {
              node {
                id
                quantity
                item {
                  id
                  name
                }
              }
            }
          }
          job {
            id
            importJobNumber
            jobNumber
            name
            ...AddUpdateRunModal_job
            ...DraggableJobCard_job
          }
          ...AddUpdateRunModal_run
          ...DeleteRunModal_run
          ...ViewRunModal_run
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_ADD',
          parentID: options.variables.input.jobId,
          connectionInfo: [
            {
              key: 'Runs_job_runs',
              rangeBehavior: 'append',
            },
          ],
          edgeName: 'runEdge',
        },
      ],
    });
  },
};

// @flow

import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import styled from 'styled-components';
import {
  Route,
  Switch,
  Redirect,
  Link,
  type Match,
  type RouterHistory,
} from 'react-router-dom';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import relayEnvironment from 'shared/gql/relayEnvironment';
import ReactTooltip from 'react-tooltip';
import { i18n } from 'shared/utils';
import config from 'main-app/config';
import JobUpdatedSubscription from 'main-app/subscriptions/JobUpdated';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import PageDetailsSummary from 'main-app/components/PageDetailsSummary';
import Loader from 'shared/components/common/Loader';
import Button from 'shared/components/common/Button';
import TabNav from 'shared/components/common/TabNav';
import JobStatusPill from 'shared/components/common/Pill/JobStatusPill';
import Icon from 'shared/components/common/Icon';
import UserWidget from 'shared/components/common/QueryWidget/UserWidget';
import InfoWidget from 'shared/components/common/InfoWidget';
import AddUpdateJobModal from 'main-app/components/AddUpdateJobModal';
import CloseJobModal from 'main-app/components/CloseJobModal';
import ReopenJobModal from 'main-app/components/ReopenJobModal';
import DeleteJobModal from 'main-app/components/DeleteJobModal';
import moment from 'moment';
import JobLinkedItems from './JobLinkedItems';
import JobDetails from './JobDetails';
import JobAttachments from './JobAttachments';
import ImportedJobDisclaimer from './ImportedJobDisclaimer';
import Runs from './Runs';
// import JobProgressPanel from './JobProgressPanel';
// import JobTasks from './JobTasks';
// import Tags from './Tags';
import JobSubComponents from './JobSubComponents';

const NotesWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90vw;

  @media (min-width: 1000px) {
    max-width: 200px;
  }
`;

type Props = {
  history: RouterHistory,
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

type State = {
  updateJobModalOpen: boolean,
  duplicateJobModalOpen: boolean,
  closeJobModalOpen: boolean,
  reopenJobModalOpen: boolean,
  deleteJobModalOpen: boolean,
};

const ContentWrapper = styled.div``;

const ExportButtonWrapper = styled.div`
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

class Job extends PureComponent<Props, State> {
  menuTriggerRef = null;
  jobUpdatedSubscription = null;

  state = {
    updateJobModalOpen: false,
    closeJobModalOpen: false,
    duplicateJobModalOpen: false,
    reopenJobModalOpen: false,
    deleteJobModalOpen: false,
  };

  componentDidMount() {
    const { match } = this.props;

    this.jobUpdatedSubscription = JobUpdatedSubscription.commit({
      variables: {
        id: match.params.id,
      },
    });
  }

  componentWillUnmount() {
    if (this.jobUpdatedSubscription) {
      this.jobUpdatedSubscription.dispose();
    }
  }

  handleViewImportAttachment = (urlPath: string) => {
    window.open(config.cdnUrl + '/' + urlPath);
  };

  handleOpenDuplicateJobModal = () => {
    this.setState({
      duplicateJobModalOpen: true,
    });
  };

  handleOpenUpdateJobModal = () => {
    this.setState({
      updateJobModalOpen: true,
    });
  };

  handleOpenCloseJobModal = () => {
    this.setState({
      closeJobModalOpen: true,
    });
  };

  handleOpenReopenJobModal = () => {
    this.setState({
      reopenJobModalOpen: true,
    });
  };

  handleOpenDeleteJobModal = () => {
    this.setState({
      deleteJobModalOpen: true,
    });
  };

  handleDeleteSuccess = () => {
    const { history } = this.props;

    history.replace('/jobs');
  };

  handleCloseAllModals = () => {
    this.setState({
      updateJobModalOpen: false,
      closeJobModalOpen: false,
      duplicateJobModalOpen: false,
      reopenJobModalOpen: false,
      deleteJobModalOpen: false,
    });
  };

  handleExportJobTicket = () => {
    const { match } = this.props;

    window.open(`/api/v1/print/job-ticket/${match.params.id}`);
  };

  render() {
    const { match, history } = this.props;
    const {
      updateJobModalOpen,
      closeJobModalOpen,
      duplicateJobModalOpen,
      reopenJobModalOpen,
      deleteJobModalOpen,
    } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query JobQuery($id: ID!) {
            job(id: $id) {
              id
              jobNumber
              importJobNumber
              importPreviousJobNumber
              status
              name
              notes
              forceClosedAt
              createdAt
              importAttachment {
                id
                urlPath
              }
              user {
                ...UserWidget_user
              }
              order {
                id
                orderNumber
                customer {
                  id
                  name
                }
              }
              workflow {
                id
                name
              }
              ...JobDetails_job
              ...Runs_job
              ...AddUpdateJobModal_job
              ...CloseJobModal_job
              ...ReopenJobModal_job
              ...DeleteJobModal_job
              ...JobLinkedItems_job
              ...JobSubComponents_job
            }

            forms(types: [ITEM]) {
              edges {
                node {
                  id
                  ...JobDetails_defaultForm
                }
              }
            }

            viewer {
              id
              permissions
            }
          }
        `}
        variables={{
          id: match.params.id,
        }}
        render={query => (
          <Page>
            <PageHeader
              intro={i18n.t('JOB TICKET')}
              title={
                query.props
                  ? query.props.job.jobNumber
                  : i18n.t('Job Details Loading...')
              }
              right={
                query.props && [
                  <JobStatusPill status={query.props.job.status} />,
                  <ExportButtonWrapper>
                    <Button
                      theme="border-white"
                      onClick={this.handleExportJobTicket}
                    >
                      {i18n.t('Export Job Ticket')}
                    </Button>
                  </ExportButtonWrapper>,
                  <ContextMenuTrigger
                    id="job-details-menu"
                    ref={r => (this.menuTriggerRef = r)}
                  >
                    <Icon
                      type="circle-context-menu"
                      onClick={e => {
                        if (this.menuTriggerRef) {
                          this.menuTriggerRef.handleContextClick(e);
                        }
                      }}
                    />
                  </ContextMenuTrigger>,
                ]
              }
            />
            {!query.props ? (
              <Loader />
            ) : (
              <>
                <PageDetailsSummary
                  items={[
                    query.props.job.importJobNumber && (
                      <InfoWidget
                        title={i18n.t('Original Job #')}
                        content={query.props.job.importJobNumber}
                      />
                    ),
                    query.props.job.importPreviousJobNumber && (
                      <InfoWidget
                        title={i18n.t('Prev. Original Job #')}
                        content={query.props.job.importPreviousJobNumber}
                      />
                    ),
                    query.props.job.user && (
                      <UserWidget
                        user={query.props.job.user}
                        title={i18n.t('Job Owner')}
                      />
                    ),
                    <InfoWidget
                      title={i18n.t('Project')}
                      content={query.props.job.name}
                    />,
                    query.props.job.order && (
                      <InfoWidget
                        title={i18n.t('Customer')}
                        content={
                          <Link
                            to={`/customer/${query.props.job.order.customer.id}`}
                          >
                            {query.props.job.order.customer.name}
                          </Link>
                        }
                      />
                    ),
                    query.props.job.order && (
                      <InfoWidget
                        title={i18n.t('Order #')}
                        content={
                          <Link to={`/order/${query.props.job.order.id}`}>
                            {query.props.job.order.orderNumber}
                          </Link>
                        }
                      />
                    ),
                    query.props.job.notes && (
                      <div data-for={query.props.job.id + '-notes'} data-tip>
                        <InfoWidget
                          title={i18n.t('Notes')}
                          content={
                            <>
                              <NotesWrapper>
                                {query.props.job.notes}
                              </NotesWrapper>
                              <ReactTooltip
                                place="bottom"
                                effect="solid"
                                type="info"
                                id={query.props.job.id + '-notes'}
                              >
                                {query.props.job.notes}
                              </ReactTooltip>
                            </>
                          }
                        />
                      </div>
                    ),
                    <InfoWidget
                      title={i18n.t('Created')}
                      content={moment(query.props.job.createdAt).format(
                        'MM/DD/YYYY',
                      )}
                    />,
                    query.props.job.workflow.name && (
                      <InfoWidget
                        title={i18n.t('Workflow')}
                        content={query.props.job.workflow.name}
                      />
                    ),
                    // // TODO: replace with query.props.job.workflow
                    // true && (
                    //   <InfoWidget title={i18n.t('Tags')} content={<Tags />} />
                    // ),
                  ]}
                />
                {query.props.job.importJobNumber ? (
                  <ImportedJobDisclaimer
                    onViewAttachment={
                      query.props.job.importAttachment &&
                      (() =>
                        this.handleViewImportAttachment(
                          query.props.job.importAttachment.urlPath,
                        ))
                    }
                  />
                ) : null}
                <TabNav
                  history={history}
                  activePath={match.url}
                  tabs={[
                    {
                      label: i18n.t('Overview'),
                      path: `/job/${match.params.id}`,
                    },
                    {
                      label: i18n.t('Runs'),
                      path: `/job/${match.params.id}/runs`,
                    },
                    {
                      label: i18n.t('Components'),
                      path: `/job/${match.params.id}/components`,
                    },
                    {
                      label: i18n.t('Linked Tooling'),
                      path: `/job/${match.params.id}/linked-items`,
                    },
                    {
                      label: i18n.t('Attachments'),
                      path: `/job/${match.params.id}/attachments`,
                    },
                  ]}
                />
                <ContentWrapper>
                  <Switch>
                    <Route
                      exact
                      path="/job/:id"
                      render={props => (
                        <JobDetails
                          {...props}
                          job={query.props.job}
                          defaultForm={
                            query.props?.forms.edges[0]?.node || null
                          }
                          // defaultForm={query.props.forms.edges.map(
                          //   ({ node }) => node,
                          // )}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/job/:id/runs"
                      render={props => (
                        <Runs {...props} job={query.props.job} />
                      )}
                    />
                    <Route
                      exact
                      path="/job/:id/components"
                      render={props => (
                        <JobSubComponents {...props} job={query.props.job} />
                      )}
                    />
                    <Route
                      exact
                      path="/job/:id/linked-items"
                      render={props => (
                        <JobLinkedItems {...props} job={query.props.job} />
                      )}
                    />
                    <Route
                      exact
                      path="/job/:id/attachments"
                      component={JobAttachments}
                    />
                    <Route
                      exact
                      render={() => <Redirect to={`/job/${match.params.id}`} />}
                    />
                  </Switch>
                </ContentWrapper>
                <ContextMenu id="job-details-menu">
                  {query.props.viewer.permissions.includes('UPDATE_JOB') && (
                    <MenuItem onClick={this.handleOpenUpdateJobModal}>
                      {i18n.t('Edit Job Ticket')}
                    </MenuItem>
                  )}
                  <MenuItem onClick={this.handleOpenDuplicateJobModal}>
                    {i18n.t('Duplicate Job Ticket')}
                  </MenuItem>
                  {query.props.viewer.permissions.includes('UPDATE_JOB') &&
                    query.props.job.status === 'OPEN' && (
                      <MenuItem onClick={this.handleOpenCloseJobModal}>
                        {i18n.t('Close Job Ticket')}
                      </MenuItem>
                    )}
                  {query.props.viewer.permissions.includes('UPDATE_JOB') &&
                    query.props.job.status === 'CLOSED' && (
                      <MenuItem onClick={this.handleOpenReopenJobModal}>
                        {i18n.t('Re-Open Job Ticket')}
                      </MenuItem>
                    )}
                  {/* duplicate job ticket here */}
                  {query.props.job.order ? (
                    <MenuItem
                      onClick={() =>
                        history.push(`/order/${query.props.job.order.id}`)
                      }
                    >
                      {i18n.t('View Order')}
                    </MenuItem>
                  ) : null}
                  <MenuItem onClick={this.handleOpenDeleteJobModal}>
                    {i18n.t('Delete Job')}
                  </MenuItem>
                </ContextMenu>
                {updateJobModalOpen && (
                  <AddUpdateJobModal
                    onClose={this.handleCloseAllModals}
                    job={query.props.job}
                    workflow={null}
                  />
                )}
                {duplicateJobModalOpen && (
                  <AddUpdateJobModal
                    onClose={this.handleCloseAllModals}
                    onSuccess={job => history.push(`/job/${job.id}`)}
                    job={query.props.job}
                    workflow={null}
                    duplicateJob
                  />
                )}
                {closeJobModalOpen && (
                  <CloseJobModal
                    onClose={this.handleCloseAllModals}
                    job={query.props.job}
                  />
                )}
                {reopenJobModalOpen && (
                  <ReopenJobModal
                    onClose={this.handleCloseAllModals}
                    job={query.props.job}
                  />
                )}
                {deleteJobModalOpen && (
                  <DeleteJobModal
                    history={history}
                    job={query.props.job}
                    onClose={this.handleCloseAllModals}
                    onSuccess={this.handleDeleteSuccess}
                  />
                )}
              </>
            )}
          </Page>
        )}
      />
    );
  }
}

export default Job;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OverUnderRule = "ALLOW_OVERS" | "NO_OVERS" | "NO_UNDERS";
import type { FragmentReference } from "relay-runtime";
declare export opaque type YieldProgressBar_jobYield$ref: FragmentReference;
declare export opaque type YieldProgressBar_jobYield$fragmentType: YieldProgressBar_jobYield$ref;
export type YieldProgressBar_jobYield = {|
  +id: string,
  +itemNumberUp: number,
  +quantity: number,
  +finalQuantity: number,
  +overUnderRule: ?OverUnderRule,
  +overQuantity: ?number,
  +item: {|
    +id: string,
    +name: string,
    +quantityUOM: {|
      +id: string,
      +symbol: string,
    |},
  |},
  +$refType: YieldProgressBar_jobYield$ref,
|};
export type YieldProgressBar_jobYield$data = YieldProgressBar_jobYield;
export type YieldProgressBar_jobYield$key = {
  +$data?: YieldProgressBar_jobYield$data,
  +$fragmentRefs: YieldProgressBar_jobYield$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "YieldProgressBar_jobYield",
  "type": "JobYield",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "itemNumberUp",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "quantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "finalQuantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "overUnderRule",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "overQuantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "item",
      "storageKey": null,
      "args": null,
      "concreteType": "Item",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "quantityUOM",
          "storageKey": null,
          "args": null,
          "concreteType": "UOM",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "symbol",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f98b7ae580bd5beaa254aeb17ad61d8';
module.exports = node;

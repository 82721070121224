// @flow

import React, { PureComponent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import {
  Route,
  Switch,
  Redirect,
  type Match,
  type RouterHistory,
} from 'react-router-dom';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import relayEnvironment from 'shared/gql/relayEnvironment';
import { i18n } from 'shared/utils';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import Icon from 'shared/components/common/Icon';
import OrderStatusPill from 'shared/components/common/Pill/OrderStatusPill';
import TabNav from 'shared/components/common/TabNav';
import Loader from 'shared/components/common/Loader';
import TabContentWrapper from 'shared/components/common/TabContent/Wrapper';
import MainContent from 'shared/components/common/TabContent/MainContent';
import AddUpdateOrderModal from 'main-app/components/AddUpdateOrderModal';
import DeleteOrderModal from 'main-app/components/DeleteOrderModal';
import OrderDetails from './OrderDetails';
import OrderAttachments from './OrderAttachments';
import OrderJobTickets from './OrderJobTickets';
import OrderSidePanel from './OrderSidePanel';

type Props = {
  history: RouterHistory,
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

type State = {
  updateOrderModalOpen: boolean,
  deleteOrderModalOpen: boolean,
};

class Order extends PureComponent<Props, State> {
  menuTriggerRef = null;

  state = {
    updateOrderModalOpen: false,
    deleteOrderModalOpen: false,
  };

  handleOpenUpdateOrderModal = () => {
    this.setState({
      updateOrderModalOpen: true,
    });
  };

  handleOpenDeleteOrderModal = () => {
    this.setState({
      deleteOrderModalOpen: true,
    });
  };

  handleDeleteSuccess = () => {
    const { history } = this.props;

    history.replace('/orders');
  };

  handleCloseAllModals = () => {
    this.setState({
      updateOrderModalOpen: false,
      deleteOrderModalOpen: false,
    });
  };

  render() {
    const { match, history } = this.props;
    const { updateOrderModalOpen, deleteOrderModalOpen } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query OrderQuery($id: ID!, $orderIds: [ID!]!) {
            order(id: $id) {
              id
              orderNumber
              status
              yields(first: null) @connection(key: "Order_yields") {
                edges {
                  node {
                    id
                  }
                }
              }
              customer {
                id
                name
              }
              ...OrderSidePanel_order
              ...OrderDetails_order
              ...AddUpdateOrderModal_order
              ...DeleteOrderModal_order
              ...OrderJobTickets_order
            }
            jobs(first: null, orderIds: $orderIds)
              @connection(key: "Order_jobs", filters: ["orderIds"]) {
              edges {
                ...OrderJobTickets_jobEdges
              }
            }
            workflows(first: null) {
              edges {
                ...OrderJobTickets_workflowEdges
              }
            }
            viewer {
              id
              permissions
              company {
                id
              }
            }
            forms(types: [ITEM]) {
              edges {
                node {
                  id
                  type
                  ...OrderDetails_defaultForm
                }
              }
            }
          }
        `}
        variables={{
          id: match.params.id,
          orderIds: [match.params.id],
        }}
        render={query => {
          return (
            <Page>
              <PageHeader
                intro={i18n.t('ORDER')}
                title={
                  query.props
                    ? query.props.order.orderNumber
                    : i18n.t('Order Details Loading...')
                }
                right={
                  query.props && [
                    <OrderStatusPill status={query.props.order.status} />,
                    <ContextMenuTrigger
                      id="order-details-menu"
                      ref={r => (this.menuTriggerRef = r)}
                    >
                      <Icon
                        type="circle-context-menu"
                        onClick={e => {
                          if (this.menuTriggerRef) {
                            this.menuTriggerRef.handleContextClick(e);
                          }
                        }}
                      />
                    </ContextMenuTrigger>,
                  ]
                }
              />
              {!query.props ? (
                <Loader />
              ) : (
                <>
                  <TabNav
                    history={history}
                    activePath={match.url}
                    tabs={[
                      {
                        label: i18n.t('Order Details'),
                        path: `/order/${match.params.id}`,
                      },
                      {
                        label: i18n.t('Job Tickets'),
                        path: `/order/${match.params.id}/job-tickets`,
                      },
                      {
                        label: i18n.t('Attachments'),
                        path: `/order/${match.params.id}/attachments`,
                      },
                    ]}
                  />
                  <TabContentWrapper>
                    <OrderSidePanel
                      order={query.props?.order}
                      customer={query.props?.order?.customer}
                      user={query.props.order.user}
                    />
                    <MainContent>
                      <Switch>
                        <Route
                          exact
                          path="/order/:id"
                          render={props => (
                            <OrderDetails
                              {...props}
                              history={history}
                              order={query.props?.order}
                              defaultForm={
                                query.props?.forms.edges[0]?.node || null
                              }
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/order/:id/job-tickets"
                          render={props => (
                            <OrderJobTickets
                              {...props}
                              history={history}
                              order={query.props?.order}
                              jobEdges={query.props?.jobs?.edges}
                              workflowEdges={query.props?.workflows?.edges}
                              companyId={query.props?.viewer?.company.id}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/order/:id/attachments"
                          component={OrderAttachments}
                        />
                        <Route
                          exact
                          render={() => (
                            <Redirect to={`/order/${match.params.id}`} />
                          )}
                        />
                      </Switch>
                    </MainContent>
                  </TabContentWrapper>
                  <ContextMenu id="order-details-menu">
                    {query.props.viewer.permissions.includes(
                      'UPDATE_ORDER',
                    ) && (
                      <MenuItem onClick={this.handleOpenUpdateOrderModal}>
                        {i18n.t('Edit Order')}
                      </MenuItem>
                    )}
                    <MenuItem onClick={this.handleOpenDeleteOrderModal}>
                      {i18n.t('Delete Order')}
                    </MenuItem>
                  </ContextMenu>
                  {updateOrderModalOpen && (
                    <AddUpdateOrderModal
                      onClose={this.handleCloseAllModals}
                      order={query.props.order}
                      customer={null}
                    />
                  )}
                  {deleteOrderModalOpen && (
                    <DeleteOrderModal
                      history={history}
                      onClose={this.handleCloseAllModals}
                      order={query.props.order}
                      onSuccess={this.handleDeleteSuccess}
                    />
                  )}
                </>
              )}
            </Page>
          );
        }}
      />
    );
  }
}

export default Order;

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteOrderMutation from 'main-app/mutations/DeleteOrder';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteOrderModal_order as OrderFragment } from './__generated__/DeleteOrderModal_order';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  order: OrderFragment,
};

type State = {
  deleting: boolean,
};

class DeleteOrderModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  static defaultProps = {
    onSuccess: undefined,
  };

  handleDelete = () => {
    const { order, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteOrderMutation.commit({
            variables: {
              input: {
                id: order.id,
              },
            },
          });

          onClose();

          if (onSuccess) {
            onSuccess();
          }

          Analytics.trackEvent('Delete Order', {
            orderId: order.id,
          });
          Actions.alertNotification(
            i18n.t(`Order Successfully Deleted`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { order, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete Order: {{orderNumber}}?', {
            orderNumber: order.orderNumber,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(`Are you sure you'd like to delete Order {{orderNumber}}?`, {
            orderNumber: order.orderNumber,
          })}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Order')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteOrderModal, {
  order: graphql`
    fragment DeleteOrderModal_order on Order {
      id
      orderNumber
    }
  `,
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateJobModal_workflow$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderJobTickets_workflowEdges$ref: FragmentReference;
declare export opaque type OrderJobTickets_workflowEdges$fragmentType: OrderJobTickets_workflowEdges$ref;
export type OrderJobTickets_workflowEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +isDefault: boolean,
    +$fragmentRefs: AddUpdateJobModal_workflow$ref,
  |},
  +$refType: OrderJobTickets_workflowEdges$ref,
|}>;
export type OrderJobTickets_workflowEdges$data = OrderJobTickets_workflowEdges;
export type OrderJobTickets_workflowEdges$key = {
  +$data?: OrderJobTickets_workflowEdges$data,
  +$fragmentRefs: OrderJobTickets_workflowEdges$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OrderJobTickets_workflowEdges",
  "type": "WorkflowEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isDefault",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateJobModal_workflow",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'da4cd7f90f815d80853610d4175a3bfd';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      userEdge {
        node {
          id
          email
          role
          firstName
          lastName
          userNumber
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

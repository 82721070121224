// @flow

import { graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      parentItemId: string,
      childItemId: string,
      usageQty: number,
    },
  },
};

const mutation = graphql`
  mutation AddItemChildMutation($input: AddItemChildInput!) {
    addItemChild(input: $input) {
      itemEdge {
        node {
          id
          itemNumber
          name
          upc
          partNumber
          description
          quantityUOM {
            id
            name
            type
            symbol
          }
          weightUOM {
            id
            name
            type
            symbol
          }
          dimensionUOM {
            id
            name
            type
            symbol
          }
          dimensionLength
          dimensionWidth
          dimensionHeight
          weight
          childItems {
            edges {
              ...ItemSubComponentsTree_itemChildEdges
            }
          }
        }
      }
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    const { parentItemId } = options.variables.input;

    return createRelayMutation(mutation, {
      ...options,
      updater: store => {
        // Traverse the mutation response until we find the updated item edge
        const updatedItemChilds = store
          .getRootField('addItemChild')
          .getLinkedRecord('itemEdge')
          .getLinkedRecord('node')
          .getLinkedRecord('childItems')
          .getLinkedRecords('edges');

        // Retrieve the root query
        const root = store.getRoot().getLinkedRecord('item', {
          id: parentItemId,
        });

        // Retrieve the root query's items array
        const conn = ConnectionHandler.getConnection(root, 'Item_childItems');

        conn.setLinkedRecords(updatedItemChilds, 'edges');
      },
    });
  },
};

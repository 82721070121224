/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateAPIKeyModal_apiKey$ref: FragmentReference;
declare export opaque type AddUpdateAPIKeyModal_apiKey$fragmentType: AddUpdateAPIKeyModal_apiKey$ref;
export type AddUpdateAPIKeyModal_apiKey = {|
  +id: string,
  +name: string,
  +actingUser: {|
    +id: string
  |},
  +createdAt: any,
  +$refType: AddUpdateAPIKeyModal_apiKey$ref,
|};
export type AddUpdateAPIKeyModal_apiKey$data = AddUpdateAPIKeyModal_apiKey;
export type AddUpdateAPIKeyModal_apiKey$key = {
  +$data?: AddUpdateAPIKeyModal_apiKey$data,
  +$fragmentRefs: AddUpdateAPIKeyModal_apiKey$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AddUpdateAPIKeyModal_apiKey",
  "type": "APIKey",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actingUser",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4e1714034f14a1f5e2db1689a52d3a6';
module.exports = node;

/**
 * @flow
 * @relayHash c8962fca9d99be5823e8766d6c3bee65
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FormFieldBoard_form$ref = any;
type FormPreview_form$ref = any;
export type FormType = "ITEM" | "JOB_STEP" | "JOB_TASK" | "JOB_YIELD";
export type FormQueryVariables = {|
  id: string
|};
export type FormQueryResponse = {|
  +form: {|
    +id: string,
    +name: string,
    +type: FormType,
    +$fragmentRefs: FormFieldBoard_form$ref & FormPreview_form$ref,
  |}
|};
export type FormQuery = {|
  variables: FormQueryVariables,
  response: FormQueryResponse,
|};
*/


/*
query FormQuery(
  $id: ID!
) {
  form(id: $id) {
    id
    name
    type
    ...FormFieldBoard_form
    ...FormPreview_form
  }
}

fragment FormFieldBoard_form on Form {
  id
  fields {
    id
    displayOrder
    ...FormFieldCard_formField
  }
}

fragment FormPreview_form on Form {
  id
  name
  type
  systemKey
  productionType {
    id
  }
  fields {
    id
    systemKey
    type
    name
    description
    placeholder
    isRequired
    isCreatable
    applicationType
    displayOrder
    properties
    options {
      name
      value
      id
    }
  }
}

fragment FormFieldCard_formField on FormField {
  id
  ...FormFieldForm_formField
  ...DeleteFormFieldModal_formField
}

fragment FormFieldForm_formField on FormField {
  id
  name
  description
  placeholder
  type
  displayOrder
  isCreatable
  properties
  options {
    id
    name
    value
  }
}

fragment DeleteFormFieldModal_formField on FormField {
  id
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemKey",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "form",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Form",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "FormFieldBoard_form",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "FormPreview_form",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "form",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Form",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fields",
            "storageKey": null,
            "args": null,
            "concreteType": "FormField",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "displayOrder",
                "args": null,
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "placeholder",
                "args": null,
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isCreatable",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "properties",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "options",
                "storageKey": null,
                "args": null,
                "concreteType": "FormFieldOption",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "value",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isRequired",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "applicationType",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productionType",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductionType",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FormQuery",
    "id": null,
    "text": "query FormQuery(\n  $id: ID!\n) {\n  form(id: $id) {\n    id\n    name\n    type\n    ...FormFieldBoard_form\n    ...FormPreview_form\n  }\n}\n\nfragment FormFieldBoard_form on Form {\n  id\n  fields {\n    id\n    displayOrder\n    ...FormFieldCard_formField\n  }\n}\n\nfragment FormPreview_form on Form {\n  id\n  name\n  type\n  systemKey\n  productionType {\n    id\n  }\n  fields {\n    id\n    systemKey\n    type\n    name\n    description\n    placeholder\n    isRequired\n    isCreatable\n    applicationType\n    displayOrder\n    properties\n    options {\n      name\n      value\n      id\n    }\n  }\n}\n\nfragment FormFieldCard_formField on FormField {\n  id\n  ...FormFieldForm_formField\n  ...DeleteFormFieldModal_formField\n}\n\nfragment FormFieldForm_formField on FormField {\n  id\n  name\n  description\n  placeholder\n  type\n  displayOrder\n  isCreatable\n  properties\n  options {\n    id\n    name\n    value\n  }\n}\n\nfragment DeleteFormFieldModal_formField on FormField {\n  id\n  name\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a6c30506736442cc13794cf1f4a3866d';
module.exports = node;

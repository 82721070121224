// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import type { RouterHistory } from 'react-router';
import { i18n } from 'shared/utils';
import Button from 'shared/components/common/Button';
import AddUpdateJobLinkedItemModal from 'main-app/components/AddUpdateJobLinkedItemModal';
import JobLinkedItemsTable from './JobLinkedItemsTable';
import type { JobLinkedItems_job as JobFragment } from './__generated__/JobLinkedItems_job';

type Props = {
  history: RouterHistory,
  job: JobFragment,
};

type State = {
  addJobLinkedItemModalOpen: boolean,
};

const ButtonWrapper = styled.div`
  padding-bottom: 24px;
`;

class JobLinkedItems extends PureComponent<Props, State> {
  state = {
    addJobLinkedItemModalOpen: false,
  };

  handleOpenAddJobLinkedItemModal = () => {
    this.setState({
      addJobLinkedItemModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addJobLinkedItemModalOpen: false,
    });
  };

  render() {
    const { job, history } = this.props;
    const { addJobLinkedItemModalOpen } = this.state;

    const excludeIds = (job.linkedItems.edges || []).map(edge => edge?.node.id);

    return (
      <>
        <ButtonWrapper>
          <Button
            width="auto"
            theme="blue"
            onClick={this.handleOpenAddJobLinkedItemModal}
          >
            {i18n.t('Add Tooling')}
          </Button>
        </ButtonWrapper>
        <JobLinkedItemsTable
          history={history}
          jobId={job.id}
          jobLinkedItemEdges={job.linkedItems.edges}
        />
        {addJobLinkedItemModalOpen && (
          <AddUpdateJobLinkedItemModal
            job={job}
            onClose={this.handleCloseAllModals}
            excludeIds={excludeIds}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(JobLinkedItems, {
  job: graphql`
    fragment JobLinkedItems_job on Job {
      id
      ...AddJobLinkedItemModal_job
      ...AddUpdateJobLinkedItemModal_job
      linkedItems(first: null)
        @connection(key: "Job_linkedItems", filters: []) {
        edges {
          ...JobLinkedItemsTable_jobLinkedItemEdges
          node {
            id
            name
            partNumber
          }
        }
      }
    }
  `,
});

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateItemMutation($input: UpdateItemInput!) {
    updateItem(input: $input) {
      itemEdge {
        node {
          id
          itemNumber
          name
          upc
          partNumber
          description
          category {
            id
            name
          }
          quantityUOM {
            id
            name
            type
            symbol
          }
          weightUOM {
            id
            name
            type
            symbol
          }
          dimensionUOM {
            id
            name
            type
            symbol
          }
          childItems {
            edges {
              node {
                id
                name
              }
            }
          }
          form {
            id
            ...FormBuilder_form @relay(mask: false)
            fields {
              id
              options {
                id
                name
                value
              }
              ...AdditionalFieldInfo_formField
            }
          }
          formFieldValues
          dimensionLength
          dimensionWidth
          dimensionHeight
          weight
          lotTracking
          cost
          totalValue
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

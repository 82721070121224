// @flow

import React from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddIntegrationEventMutation from 'main-app/mutations/AddIntegrationEvent';
import UpdateIntegrationEventMutation from 'main-app/mutations/UpdateIntegrationEvent';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  SelectEventTypeField,
} from 'shared/components/form';
import type { AddUpdateIntegrationEventModal_integrationEvent as IntegrationEventFragment } from './__generated__/AddUpdateIntegrationEventModal_integrationEvent';

type Props = {
  onClose: () => void,
  integrationEvent: ?IntegrationEventFragment,
  integrationId: string,
};

const AddUpdateIntegrationEventModal = ({
  integrationEvent,
  onClose,
  integrationId,
}: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        eventType:
          integrationEvent && integrationEvent.eventType
            ? {
                value: integrationEvent.eventType.id,
              }
            : null,
        originRule:
          integrationEvent && integrationEvent.originRule
            ? {
                value: integrationEvent.originRule,
              }
            : null,
        lambdaId: integrationEvent?.lambdaId || '',
        lambdaOnly: integrationEvent?.lambdaOnly || false,
      }}
      validationSchema={Yup.object().shape({
        eventType: Yup.object()
          .nullable()
          .required('Required'),
        originRule: Yup.object()
          .nullable()
          .required('Required'),
        lambdaId: Yup.string(),
        lambdaOnly: Yup.boolean(),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        const mutation = integrationEvent
          ? UpdateIntegrationEventMutation
          : AddIntegrationEventMutation;

        const input: any = {
          eventTypeId: values.eventType?.value,
          originRule: values.originRule?.value,
          lambdaId: values.lambdaId,
          lambdaOnly: values.lambdaOnly,
        };

        if (integrationEvent) {
          input.id = integrationEvent.id;
        } else {
          input.integrationId = integrationId;
        }

        try {
          await mutation.commit({
            variables: {
              input,
            },
          });

          onClose();
          Actions.alertNotification(
            i18n.t(
              `Event Successfully ${integrationEvent ? 'Updated' : 'Added'}`,
            ),
            'success',
          );
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader
            header={i18n.t(
              integrationEvent
                ? 'Edit Integration Event'
                : 'Create Integration Event',
            )}
            onClose={onClose}
          />
          <ModalBody withPadding>
            <SelectEventTypeField
              name="eventType"
              label={i18n.t('Event Type')}
              error={errors.eventType}
            />
            <FieldGroup
              name="originRule"
              label={i18n.t('Sync Rule')}
              type="select"
              options={[
                {
                  label: i18n.t('Sync from WorkClout to Integration Source'),
                  value: 'WC_TO_SOURCE',
                },
                {
                  label: i18n.t('Sync from Integration Source to WorkClout'),
                  value: 'SOURCE_TO_WC',
                },
                {
                  label: i18n.t('Two-Way Sync'),
                  value: 'TWO_WAY',
                },
              ]}
              error={errors.originRule}
            />
            <FieldGroup
              name="lambdaId"
              label={i18n.t('Lambda ID')}
              error={errors.lambdaId}
            />
            <FieldGroup
              name="lambdaOnly"
              type="toggle"
              label={i18n.t('Lambda Only')}
              error={errors.lambdaOnly}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t(integrationEvent ? 'Update Event' : 'Add Event')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(AddUpdateIntegrationEventModal, {
  integrationEvent: graphql`
    fragment AddUpdateIntegrationEventModal_integrationEvent on IntegrationEvent {
      id
      originRule
      lambdaOnly
      lambdaId
      eventType {
        id
        name
      }
    }
  `,
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CustomerList_customerEdges$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PaginatedCustomersContainer_customers$ref: FragmentReference;
declare export opaque type PaginatedCustomersContainer_customers$fragmentType: PaginatedCustomersContainer_customers$ref;
export type PaginatedCustomersContainer_customers = {|
  +customers: {|
    +edges: ?$ReadOnlyArray<?{|
      +cursor: string,
      +$fragmentRefs: CustomerList_customerEdges$ref,
    |}>
  |},
  +$refType: PaginatedCustomersContainer_customers$ref,
|};
export type PaginatedCustomersContainer_customers$data = PaginatedCustomersContainer_customers;
export type PaginatedCustomersContainer_customers$key = {
  +$data?: PaginatedCustomersContainer_customers$data,
  +$fragmentRefs: PaginatedCustomersContainer_customers$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "PaginatedCustomersContainer_customers",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "customers"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 30
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "sortBy",
      "type": "CustomerSort",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "customerNumbers",
      "type": "[String!]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "salesUserIds",
      "type": "[ID!]",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "customers",
      "name": "__PaginatedCustomersContainer_customers_connection",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CustomerEdge",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Customer",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "CustomerList_customerEdges",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '292b748535b73abec8a0c2b20218ed68';
module.exports = node;

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import Panel from 'shared/components/common/Panel';
import EmptyListResults from 'shared/components/common/EmptyListResults';
import AddUpdateOrderYieldModal from 'main-app/components/AddUpdateOrderYieldModal';
import AddUpdateItemModal from 'main-app/components/AddUpdateItemModal';
import Button from 'shared/components/common/Button';
import DeleteOrderYieldModal from 'main-app/components/DeleteOrderYieldModal';
import OrderYieldPanels from 'shared/components/common/OrderYieldPanels';
import type { OrderDetails_order as OrderFragment } from './__generated__/OrderDetails_order';
import type { OrderDetails_defaultForm as DefaultFormFragment } from './__generated__/OrderDetails_defaultForm';

type Props = {
  order: OrderFragment,
  defaultForm: DefaultFormFragment,
};

type State = {
  addOrderYieldModalOpen: boolean,
  addItemAndOrderYieldModalOpen: boolean,
  createAnotherItem: boolean,
  updateOrderYieldRecord: ?Object,
  deleteOrderYieldRecord: ?Object,
};

const SectionHeader = styled.div`
  ${fonts.headerBold};
  color: ${colors.charcoalGrey};
  padding: 24px 0 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;

  button:first-of-type {
    margin-right: 16px;
  }
`;

const ButtonWrapper = styled.div`
  padding-bottom: 16px;
`;

class OrderDetails extends PureComponent<Props, State> {
  initialState = {
    addOrderYieldModalOpen: false,
    addItemAndOrderYieldModalOpen: false,
    createAnotherItem: false,
    updateOrderYieldRecord: null,
    deleteOrderYieldRecord: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = this.initialState;
  }

  handleCloseAllModals = () => {
    this.setState(this.initialState);
  };

  handleOpenItemModal = () => {
    this.setState({
      addOrderYieldModalOpen: true,
    });
  };

  handleOpenEditItemModal = (orderYield: Object) => {
    this.setState({
      updateOrderYieldRecord: orderYield,
    });
  };

  handleOpenDeleteItemModal = (orderYield: Object) => {
    this.setState({
      deleteOrderYieldRecord: orderYield,
    });
  };

  handleOpenCreateItemModal = () => {
    this.setState({
      addItemAndOrderYieldModalOpen: true,
    });
  };

  handleReopenAddItemAndOrderYieldModal = () => {
    this.setState({ ...this.initialState, createAnotherItem: true }, () =>
      this.setState({
        addItemAndOrderYieldModalOpen: true,
      }),
    );
  };

  render() {
    const { order, defaultForm } = this.props;
    const {
      addOrderYieldModalOpen,
      deleteOrderYieldRecord,
      updateOrderYieldRecord,
      addItemAndOrderYieldModalOpen,
      createAnotherItem,
    } = this.state;
    const hasYields = order.yields?.edges?.length;

    return (
      <>
        <SectionHeader>{i18n.t('Items')}</SectionHeader>
        <ButtonsContainer>
          <ButtonWrapper>
            <Button
              theme="blue"
              width="auto"
              onClick={this.handleOpenItemModal}
            >
              {i18n.t('Add Item')}
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              theme="border-white"
              width="auto"
              onClick={this.handleOpenCreateItemModal}
            >
              {i18n.t('Create Item')}
            </Button>
          </ButtonWrapper>
        </ButtonsContainer>
        {!hasYields ? (
          <Panel>
            <EmptyListResults
              graphic="items"
              message={i18n.t(
                'This Order is empty. Add an Item to get started.',
              )}
            />
          </Panel>
        ) : (
          <OrderYieldPanels
            history={history}
            orderYieldEdges={order.yields.edges}
            onEditItem={this.handleOpenEditItemModal}
            onDeleteItem={this.handleOpenDeleteItemModal}
          />
        )}
        {(addOrderYieldModalOpen || updateOrderYieldRecord) && (
          <AddUpdateOrderYieldModal
            orderId={order.id}
            orderYield={updateOrderYieldRecord}
            onClose={this.handleCloseAllModals}
            orderYieldEdges={order.yields.edges}
          />
        )}
        {deleteOrderYieldRecord && (
          <DeleteOrderYieldModal
            orderId={order.id}
            orderYield={deleteOrderYieldRecord}
            onClose={this.handleCloseAllModals}
          />
        )}
        {addItemAndOrderYieldModalOpen && (
          <AddUpdateItemModal
            onClose={this.handleCloseAllModals}
            onReopen={this.handleReopenAddItemAndOrderYieldModal}
            createAnotherItem={createAnotherItem}
            item={null}
            defaultForm={defaultForm}
            orderId={order.id}
          />
        )}
      </>
    );
  }
}

export default createFragmentContainer(OrderDetails, {
  order: graphql`
    fragment OrderDetails_order on Order {
      id
      yields(first: null)
        @connection(key: "OrderDetails_order_yields", filters: []) {
        edges {
          ...OrderYieldPanels_orderYieldEdges
          ...AddUpdateOrderYieldModal_orderYieldEdges
          node {
            id
            order {
              id
            }
            orderedQuantity
            item {
              id
              name
              description
              ...AddUpdateItemModal_item
            }
            ...AddUpdateOrderYieldModal_orderYield
            ...DeleteOrderYieldModal_orderYield
          }
        }
      }
      customerOrderNumber
      orderedAt
      promisedAt
      requiredAt
    }
  `,
  defaultForm: graphql`
    fragment OrderDetails_defaultForm on Form {
      id
      ...AddUpdateItemModal_defaultForm
    }
  `,
});

// @flow

import React from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { i18n } from 'shared/utils';
import { FilterSummary, FilterSummaryLabel } from './styled';
import type { FilterValue, SortOption } from '.';

type Props = {
  value: FilterValue,
  sortOptions: Array<SortOption>,
  onSortChange: (string, 'ASC' | 'DESC') => void,
};

let menuTriggerRef = null;

function getSortLabel(value: FilterValue, sortOptions: Array<SortOption>) {
  const sortOption = sortOptions.find(
    a => a.value.field === value.sort && a.value.direction === value.direction,
  );

  return sortOption ? sortOption.label : null;
}

export default ({ sortOptions, value, onSortChange }: Props) => (
  <FilterSummary>
    <FilterSummaryLabel>{i18n.t('Sort:')}</FilterSummaryLabel>
    <ContextMenuTrigger
      id="saved-filter-sort-selector-menu"
      ref={r => (menuTriggerRef = r)}
    >
      <span
        onClick={e => {
          if (menuTriggerRef) {
            menuTriggerRef.handleContextClick(e);
          }
        }}
      >
        {getSortLabel(value, sortOptions) || `${i18n.t('click to select')}`}
      </span>
    </ContextMenuTrigger>
    <ContextMenu id="saved-filter-sort-selector-menu">
      {sortOptions.map(option => (
        <MenuItem
          key={option.label}
          data={option}
          onClick={(_, s) => {
            onSortChange(s.value.field, s.value.direction);
          }}
          selected={
            option.value.field === value.sort &&
            option.value.direction === value.direction
          }
        >
          {option.label}
        </MenuItem>
      ))}
    </ContextMenu>
  </FilterSummary>
);

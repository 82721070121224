/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateOrderModal_customer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CustomerOrders_customer$ref: FragmentReference;
declare export opaque type CustomerOrders_customer$fragmentType: CustomerOrders_customer$ref;
export type CustomerOrders_customer = {|
  +id: string,
  +$fragmentRefs: AddUpdateOrderModal_customer$ref,
  +$refType: CustomerOrders_customer$ref,
|};
export type CustomerOrders_customer$data = CustomerOrders_customer;
export type CustomerOrders_customer$key = {
  +$data?: CustomerOrders_customer$data,
  +$fragmentRefs: CustomerOrders_customer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CustomerOrders_customer",
  "type": "Customer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "AddUpdateOrderModal_customer",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0c2b61f76b048672faa22cb64783a06e';
module.exports = node;

// @flow

import React from 'react';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { fonts, colors } from 'shared/styleguide';
import emptySearchGraphic from './img/emptySearch.svg';

const EmptyWrapper = styled.div`
  width: 100%;
  text-align: center;

  span {
    display: block;
    padding: 0 16px 16px 16px;
    ${fonts.bodyBold};
    color: ${colors.battleshipGrey};
  }

  a:hover {
    cursor: pointer;
  }
`;

const EmptySearchGraphic = styled(emptySearchGraphic)`
  width: 200px;
  height: 200px;
`;

export default () => (
  <EmptyWrapper>
    <EmptySearchGraphic />
    <span>{i18n.t(`We couldn’t find any results. Try searching again!`)}</span>
  </EmptyWrapper>
);

// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import UpdateUserSavedFilterMutation from 'main-app/mutations/UpdateUserSavedFilter';
import AddUserSavedFilterMutation from 'main-app/mutations/AddUserSavedFilter';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import Button from 'shared/components/common/Button';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import type { FilterValue, SavedFilter } from '.';

type Props = {
  onClose: () => void,
  savedFilterType: string,
  savedFilter: ?SavedFilter,
  filterValue: FilterValue,
  onSaveFilterComplete: SavedFilter => void,
};

export default ({
  onClose,
  savedFilterType,
  savedFilter,
  filterValue,
  onSaveFilterComplete,
}: Props) => (
  <Modal maxWidth={700} onClickAway={onClose}>
    <Formik
      initialValues={{
        name: savedFilter ? savedFilter.name : '',
      }}
      isInitialValid
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        try {
          const input = {
            name: values.name,
            filter: JSON.stringify(filterValue),
          };

          let savedFilter;

          if (savedFilter) {
            const {
              updateUserSavedFilter,
            } = await UpdateUserSavedFilterMutation.commit({
              variables: {
                input: {
                  id: savedFilter.id,
                  ...input,
                },
              },
            });

            savedFilter = updateUserSavedFilter;
          } else {
            const {
              addUserSavedFilter,
            } = await AddUserSavedFilterMutation.commit({
              variables: {
                input: {
                  type: savedFilterType,
                  ...input,
                },
              },
            });

            savedFilter = addUserSavedFilter.userSavedFilterEdge.node;
          }

          setSubmitting(false);
          onSaveFilterComplete(savedFilter);
          onClose();

          Analytics.trackEvent('Save Filter', {
            filterName: values.name,
            appliedFilters: Object.keys(filterValue),
          });

          Actions.alertNotification(
            i18n.t(`Filter Successfully Saved`),
            'success',
          );
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader header={i18n.t('Add Filters')} onClose={onClose} />
          <ModalBody withPadding>
            <FieldGroup
              name="name"
              label={i18n.t('Filter Name')}
              placeholder={i18n.t('(e.g. Pre-Production)')}
              error={errors.name}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t('Save Filter')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

// @flow

import React, { PureComponent } from 'react';
import type { RouterHistory } from 'react-router';
import { graphql, QueryRenderer } from 'react-relay';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import AddUpdateWorkflowModal from 'main-app/components/AddUpdateWorkflowModal';
import EmptyListResults from 'shared/components/common/EmptyListResults';

type Props = {
  history: RouterHistory,
};

type State = {
  addModalOpen: boolean,
  updateModalRecord: ?Object,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  padding: 16px 16px 24px 16px;

  @media (min-width: 1000px) {
    align-self: flex-end;
    padding: 0 0 24px;
  }
`;

const ListWrapper = styled.div`
  flex: 1;
`;

class Workflows extends PureComponent<Props, State> {
  state = {
    addModalOpen: false,
    updateModalRecord: null,
  };

  menuTriggerRefs = {};

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleOpenUpdateModal = (target: *, edges: ?Array<Object>) => {
    const workflowId = target.getAttribute('workflow-id');

    const workflow = edges
      ? edges.find(({ node }) => node.id === workflowId)
      : null;

    if (workflow) {
      this.setState({
        updateModalRecord: workflow.node,
      });
    }
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      updateModalRecord: null,
    });
  };

  render() {
    const { history } = this.props;
    const { addModalOpen, updateModalRecord } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        query={graphql`
          query WorkflowsQuery {
            workflows(first: null) @connection(key: "Workflows_workflows") {
              edges {
                node {
                  id
                  name
                  isDefault
                  ...AddUpdateWorkflowModal_workflow
                }
              }
            }
          }
        `}
        variables={null}
        render={({ props, retry }) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <Wrapper>
              <ButtonWrapper>
                <Button
                  onClick={this.handleOpenCreateModal}
                  theme="border-white"
                >
                  {i18n.t('Create Workflow')}
                </Button>
              </ButtonWrapper>

              {!props.workflows.edges.length ? (
                <EmptyListResults
                  graphic="workflows"
                  message={i18n.t(`Workflows you create can be managed here.`)}
                />
              ) : (
                <ListWrapper>
                  <Table
                    data={props.workflows.edges.map(edge => edge.node)}
                    onRowClick={rowData =>
                      history.push(`/settings/workflow/${rowData.id}`)
                    }
                    columns={[
                      {
                        Header: i18n.t('Name'),
                        accessor: 'name',
                      },
                      {
                        Header: i18n.t('Default'),
                        accessor: 'isDefault',
                        width: 160,
                        Cell: ({ value }) => (value ? 'YES' : 'NO'),
                      },
                      {
                        Header: '',
                        accessor: 'id',
                        width: 48,
                        enforceWidth: true,
                        Cell: ({ value }) => (
                          <ContextMenuTrigger
                            ref={r => (this.menuTriggerRefs[value] = r)}
                            id="settings-workflows-menu"
                            attributes={{
                              'workflow-id': value,
                            }}
                          >
                            <Icon
                              type="circle-context-menu"
                              size={24}
                              onClick={e => {
                                if (this.menuTriggerRefs[value]) {
                                  this.menuTriggerRefs[
                                    value
                                  ].handleContextClick(e);
                                }
                              }}
                            />
                          </ContextMenuTrigger>
                        ),
                      },
                    ]}
                  />
                </ListWrapper>
              )}
              {addModalOpen && (
                <AddUpdateWorkflowModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  onSuccess={() => retry()}
                  workflow={null}
                />
              )}
              {updateModalRecord && (
                <AddUpdateWorkflowModal
                  history={history}
                  onClose={this.handleCloseAllModals}
                  onSuccess={() => retry()}
                  workflow={updateModalRecord}
                />
              )}
              <ContextMenu id="settings-workflows-menu">
                <MenuItem
                  onClick={(e, data, target) => {
                    this.handleOpenUpdateModal(target, props.workflows.edges);
                  }}
                >
                  {i18n.t('Edit Workflow')}
                </MenuItem>
              </ContextMenu>
            </Wrapper>
          );
        }}
      />
    );
  }
}

export default Workflows;

/**
 * @flow
 * @relayHash f6240fbb9f250c57e831731092a49bd7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobsTable_jobEdges$ref = any;
export type ItemJobsQueryVariables = {|
  itemIds?: ?$ReadOnlyArray<string>
|};
export type ItemJobsQueryResponse = {|
  +jobs: {|
    +edges: ?$ReadOnlyArray<?{|
      +$fragmentRefs: JobsTable_jobEdges$ref
    |}>
  |}
|};
export type ItemJobsQuery = {|
  variables: ItemJobsQueryVariables,
  response: ItemJobsQueryResponse,
|};
*/


/*
query ItemJobsQuery(
  $itemIds: [ID!]
) {
  jobs(itemIds: $itemIds, sortBy: {field: UPDATED_AT, direction: DESC}) {
    edges {
      ...JobsTable_jobEdges
    }
  }
}

fragment JobsTable_jobEdges on JobEdge {
  node {
    id
    jobNumber
    importJobNumber
    name
    status
    createdAt
    user {
      id
      ...UserAvatar_user
    }
    yields {
      totalCount
    }
    order {
      id
      promisedAt
      customer {
        id
        name
      }
    }
    states {
      id
      isEnabled
      status
      workflowState {
        id
        name
        type
      }
    }
    workflow {
      id
      states {
        id
        name
        isRequired
        type
      }
      transitions {
        id
        toWorkflowState {
          id
        }
        fromWorkflowState {
          id
        }
      }
    }
    ...AddUpdateJobModal_job
    ...DeleteJobModal_job
  }
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}

fragment AddUpdateJobModal_job on Job {
  id
  name
  notes
  user {
    id
  }
  workflow {
    id
    name
    states {
      id
      isGangRun
    }
  }
  order {
    id
  }
  yields {
    edges {
      node {
        id
        quantity
        itemNumberUp
        brokerCustomerOrderNumber
        formFieldValues
        overUnderRule
        overQuantity
        item {
          id
          name
          quantityUOM {
            id
            name
            symbol
          }
        }
      }
    }
  }
}

fragment DeleteJobModal_job on Job {
  id
  name
  order {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "itemIds",
    "type": "[ID!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "itemIds",
    "variableName": "itemIds"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": {
      "direction": "DESC",
      "field": "UPDATED_AT"
    }
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
},
v6 = [
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ItemJobsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobs",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobEdge",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "JobsTable_jobEdges",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ItemJobsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "jobs",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "JobConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "JobEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Job",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "jobNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "importJobNumber",
                    "args": null,
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "yields",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobYieldConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "totalCount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "JobYieldEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "JobYield",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "quantity",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "itemNumberUp",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "brokerCustomerOrderNumber",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "formFieldValues",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "overUnderRule",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "overQuantity",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "item",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "Item",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "quantityUOM",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UOM",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "symbol",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "order",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Order",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "promisedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "customer",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Customer",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "states",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "JobState",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isEnabled",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "workflowState",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "WorkflowState",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "workflow",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Workflow",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "states",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "WorkflowState",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "isGangRun",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "isRequired",
                            "args": null,
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ]
                      },
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "transitions",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "WorkflowTransition",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "toWorkflowState",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "WorkflowState",
                            "plural": false,
                            "selections": (v6/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "fromWorkflowState",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "WorkflowState",
                            "plural": false,
                            "selections": (v6/*: any*/)
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ItemJobsQuery",
    "id": null,
    "text": "query ItemJobsQuery(\n  $itemIds: [ID!]\n) {\n  jobs(itemIds: $itemIds, sortBy: {field: UPDATED_AT, direction: DESC}) {\n    edges {\n      ...JobsTable_jobEdges\n    }\n  }\n}\n\nfragment JobsTable_jobEdges on JobEdge {\n  node {\n    id\n    jobNumber\n    importJobNumber\n    name\n    status\n    createdAt\n    user {\n      id\n      ...UserAvatar_user\n    }\n    yields {\n      totalCount\n    }\n    order {\n      id\n      promisedAt\n      customer {\n        id\n        name\n      }\n    }\n    states {\n      id\n      isEnabled\n      status\n      workflowState {\n        id\n        name\n        type\n      }\n    }\n    workflow {\n      id\n      states {\n        id\n        name\n        isRequired\n        type\n      }\n      transitions {\n        id\n        toWorkflowState {\n          id\n        }\n        fromWorkflowState {\n          id\n        }\n      }\n    }\n    ...AddUpdateJobModal_job\n    ...DeleteJobModal_job\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n\nfragment AddUpdateJobModal_job on Job {\n  id\n  name\n  notes\n  user {\n    id\n  }\n  workflow {\n    id\n    name\n    states {\n      id\n      isGangRun\n    }\n  }\n  order {\n    id\n  }\n  yields {\n    edges {\n      node {\n        id\n        quantity\n        itemNumberUp\n        brokerCustomerOrderNumber\n        formFieldValues\n        overUnderRule\n        overQuantity\n        item {\n          id\n          name\n          quantityUOM {\n            id\n            name\n            symbol\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment DeleteJobModal_job on Job {\n  id\n  name\n  order {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7fb2fe0034c3dc85d1cad76d4d5992e';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateOrderMutation($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      orderEdge {
        node {
          id
          orderNumber
          customerOrderNumber
          totalJobs
          status
          shippingAddressLine1
          shippingAddressLine2
          shippingAddressLine3
          shippingAddressCity
          shippingAddressState
          shippingAddressZip
          notes
          orderedAt
          requiredAt
          promisedAt
          customer {
            id
            name
          }
          user {
            id
            firstName
            lastName
            ...UserAvatar_user
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

// @flow

import React, { PureComponent } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import DeleteAttachmentMutation from 'main-app/mutations/DeleteAttachment';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import type { DeleteAttachmentModal_attachment as AttachmentFragment } from './__generated__/DeleteAttachmentModal_attachment';

type Props = {
  onClose: () => void,
  onSuccess?: () => void,
  parentId: string,
  attachment: AttachmentFragment,
};

type State = {
  deleting: boolean,
};

class DeleteAttachmentModal extends PureComponent<Props, State> {
  state = {
    deleting: false,
  };

  static defaultProps = {
    onSuccess: undefined,
  };

  handleDelete = e => {
    e.preventDefault();
    e.stopPropagation();
    const { attachment, parentId, onClose, onSuccess } = this.props;

    this.setState(
      {
        deleting: true,
      },
      async () => {
        try {
          await DeleteAttachmentMutation.commit({
            variables: {
              input: {
                id: attachment.id,
              },
            },
            context: {
              parentId,
            },
          });

          onClose();

          if (onSuccess) {
            onSuccess();
          }

          Analytics.trackEvent('Delete Attachment', {
            attachmentId: attachment.id,
          });
          Actions.alertNotification(
            i18n.t(`Attachment Successfully Deleted`),
            'success',
          );
        } catch (e) {
          this.setState({ deleting: false });

          Actions.alertNotification(e.message, 'error');
        }
      },
    );
  };

  render() {
    const { deleting } = this.state;
    const { attachment, onClose } = this.props;

    return (
      <Modal maxWidth={700}>
        <ModalHeader
          header={i18n.t('Delete {{fileName}}?', {
            fileName: attachment.name,
          })}
          onClose={onClose}
        />
        <ModalBody withPadding>
          {i18n.t(
            'Are you sure you want to delete {{fileName}} from attachments?',
            {
              fileName: attachment.name,
            },
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            theme="border-white"
            loading={deleting}
            onClick={this.handleDelete}
          >
            {i18n.t('Yes, Delete Attachment')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default createFragmentContainer(DeleteAttachmentModal, {
  attachment: graphql`
    fragment DeleteAttachmentModal_attachment on Attachment {
      id
      name
    }
  `,
});

// @flow

import React from 'react';
import { BaseWidget, DiagramEngine } from 'storm-react-diagrams';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import { CustomLabelModel } from './index';

type Props = {
  labelModel: CustomLabelModel,
  diagramEngine: DiagramEngine,
  color: string,
};

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 2px solid ${p => p.color || colors.charcoalGrey};
  background: ${p => p.color || colors.charcoalGrey};
  width: 24px;
  height: 24px;
  margin: 0 auto;
  color: ${colors.white};
`;

const Label = styled.div`
  ${fonts.bodyBold}
  margin-bottom: 1px;
`;

class CustomLabelWidget extends BaseWidget<Props> {
  constructor(props: Props) {
    super('custom-label', props);
  }

  render() {
    const { labelModel, diagramEngine, color } = this.props;
    const diagramLocked = diagramEngine.getDiagramModel().isLocked();

    return (
      <LabelWrapper {...this.getProps()} color={color}>
        <Label>
          {diagramLocked && !parseInt(labelModel.label)
            ? '0'
            : labelModel.label}
        </Label>
      </LabelWrapper>
    );
  }
}

export default CustomLabelWidget;

// @flow

import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';

export const Branch = styled.div`
  ${fonts.bodyRegular};
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
`;

export const ItemNumber = styled.div`
  ${fonts.bodyBold}
  display: flex;
  align-items: center;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  border-right: solid 1px ${colors.lightBlueGrey};
  padding-left: 10px;
  padding-right: 20px;
  margin-right: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  padding-top: 5px;
`;

export const Header = styled.div`
  ${fonts.smallRegular};
  color: ${colors.battleshipGrey};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;

  @media (min-width: 1000px) {
    padding-right: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const TooltipTitle = styled.div`
  display: block;
  ${fonts.bodyBold};
  padding-bottom: 4px;
  text-transform: uppercase;
`;

export const TooltipContent = styled.div`
  ${fonts.bodyRegular}
  padding-bottom: 12px;
`;

// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import { graphql, fetchQuery } from 'react-relay';
import styled from 'styled-components';
import { fonts, colors } from 'shared/styleguide';
import * as Actions from 'main-app/store/Actions';
import DeductItemQtyMutation from 'main-app/mutations/DeductItemQty';
import AddLotMutation from 'shared/mutations/AddLot';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldArray,
  FieldGroup,
  FieldGroupRow,
  SelectLotField,
} from 'shared/components/form';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  components: Array<Object>,
};

const LANGUAGE = {
  modalTitle: i18n.t('Deduct Component Quantity'),
  successMessage: i18n.t('Component Quantity Successfully Deducted'),
  submitButton: i18n.t('Deduct'),
  LABELS: {
    quantity: i18n.t('Available Quantity'),
    lot: i18n.t('Deduct From Lot'),
  },
};

const ComponentHeader = styled.div`
  ${fonts.headerBold};
  color: ${colors.charcoalGrey};
  padding-bottom: 24px;
`;

const Divider = styled.div`
  border-top: 2px solid ${colors.lightBlueGrey};
  margin-top: 16px;
  padding-bottom: 16px;
`;

class BulkDeductQtyModal extends PureComponent<Props> {
  form: Object;
  initialValues: Object;
  validationSchema: Object;
  formatValues: Function;
  getAvailableQty: Function;
  getDisabledLotId: Function;
  addLotMutation: Function;

  static defaultProps = {
    onSuccess: undefined,
  };

  formatValues = (component: Object) => {
    const lotId = component.lot?.value || component.lot;
    const result: Object = {
      lotId,
      itemId: component.itemId,
      quantity: Number(component.usageQty),
      notes: 'Bulk deduction',
    };

    return result;
  };

  getDisabledLotId = async () => {
    const QUERY = graphql`
      query BulkDeductQtyModalQuery($lotNumbers: [String!]) {
        lots(first: 1, lotNumbers: $lotNumbers) {
          edges {
            node {
              id
            }
          }
        }
      }
    `;
    try {
      const { lots } = await fetchQuery(relayEnvironment, QUERY, {
        lotNumbers: ['Default'],
      });

      if (lots?.edges.length) {
        return lots.edges[0].node.id;
      }

      return await this.addLotMutation(
        'Default',
        'Contains items w/o lot tracking',
      );
    } catch (e) {
      throw new Error(
        'There was a problem with this transaction. Please refresh the page and try again!',
      );
    }
  };

  addLotMutation = async (lotNumber: string, binNumber: string = '') => {
    const { addLot } = await AddLotMutation.commit({
      variables: {
        input: {
          lotNumber,
          binNumber,
        },
      },
    });

    return addLot.lotEdge.node.id;
  };

  flattenComponents = (
    components: Array<Object>,
    root: boolean = true,
    result: Array<Object> = [],
  ) => {
    components.forEach(component => {
      if (component.children?.length) {
        this.flattenComponents(component.children, false, result);
      }

      const existingIndex = result.findIndex(
        existingComponent => existingComponent.itemId === component.itemId,
      );

      if (existingIndex !== -1) {
        result[existingIndex].usageQty += component.tooltip.usageQty;
      } else if (!root || component.type === 'component') {
        result.push({
          itemId: component.itemId,
          lotTracking: component.tooltip.lotTracking,
          name: component.tooltip.name,
          usageQty: component.tooltip.usageQty,
          uom: component.tooltip.quantityUOM.symbol,
        });
      }
    });

    return result;
  };

  render() {
    const { onClose, onSuccess, components } = this.props;
    const flattenedComponents = this.flattenComponents(components);

    return (
      <Modal maxWidth={700}>
        <Formik
          initialValues={{
            components: flattenedComponents.map(component => ({
              name: component.name,
              itemId: component.itemId,
              usageQty: component.usageQty,
              lotTracking: component.lotTracking,
              lot: '',
              uom: component.uom,
            })),
          }}
          isInitialValid
          validationSchema={Yup.object().shape({
            components: Yup.array().of(
              Yup.object().shape({
                usageQty: Yup.number().typeError('Invalid Number'),
                lot: Yup.string().when('lotTracking', {
                  is: lotTracking => Boolean(lotTracking),
                  then: Yup.string()
                    .nullable()
                    .required('Required'),
                  otherwise: Yup.string(),
                }),
              }),
            ),
          })}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);
            const { components } = values;

            try {
              for (const component of components) {
                const input = this.formatValues(component);
                if (!component.lot) {
                  const disabledLotId = await this.getDisabledLotId();
                  input.lotId = disabledLotId;
                }

                const response = await DeductItemQtyMutation.commit({
                  variables: {
                    input,
                  },
                });

                const itemResponse = response.deductItemQty.itemEdge.node;

                onClose();
                if (onSuccess) {
                  onSuccess(itemResponse);
                }

                Analytics.trackEvent(`Deduct Inventory`, {
                  inventoryId: values.itemId,
                  lotId: values.lotId,
                  fromLotId: values.fromLotId,
                  quantity: values.quantity,
                });
                Actions.alertNotification(LANGUAGE.successMessage, 'success');
              }
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(e.message, 'Something Went Wrong');
            }
          }}
          render={({
            errors,
            values,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => {
            const movementType = 'DEDUCT';
            const { components } = values;

            return (
              <Form>
                <ModalHeader header={LANGUAGE.modalTitle} onClose={onClose} />
                <ModalBody withPadding>
                  {!components || !components.length ? (
                    <div>
                      Items to be produced by this job are not eligible for
                      quantity deduction.
                    </div>
                  ) : (
                    <FieldArray
                      name="components"
                      render={arrayHelpers =>
                        components.map((component, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <React.Fragment key={`component-${i}`}>
                            <ComponentHeader>
                              {i18n.t(component.name)}
                            </ComponentHeader>
                            <FieldGroupRow
                              left={
                                <FieldGroup
                                  name={`components.${i}.usageQty`}
                                  label={LANGUAGE.LABELS.quantity}
                                  error={
                                    errors.components &&
                                    errors.components[i]?.usageQty
                                  }
                                  type="number"
                                  extendedLabel={i18n.t(component.uom)}
                                />
                              }
                              right={
                                component.lotTracking ? (
                                  <SelectLotField
                                    name={`components.${i}.lot`}
                                    label={LANGUAGE.LABELS.lot}
                                    error={
                                      errors.components &&
                                      errors.components[i]?.lot
                                    }
                                    itemId={component.itemId}
                                    movement={movementType}
                                  />
                                ) : (
                                  <div />
                                )
                              }
                            />
                            <Divider style={{ marginBottom: 24 }} />
                          </React.Fragment>
                        ))
                      }
                    />
                  )}
                </ModalBody>
                <ModalFooter
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <Button
                    type="submit"
                    theme="blue"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {LANGUAGE.submitButton}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}

export default BulkDeductQtyModal;

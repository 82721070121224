/**
 * @flow
 * @relayHash fdd3c2a4ad2a0dbd530fcd0e5f9abb9b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobUpdatedSubscriptionVariables = {|
  id: string
|};
export type JobUpdatedSubscriptionResponse = {|
  +jobUpdated: {|
    +id: string,
    +name: string,
  |}
|};
export type JobUpdatedSubscription = {|
  variables: JobUpdatedSubscriptionVariables,
  response: JobUpdatedSubscriptionResponse,
|};
*/


/*
subscription JobUpdatedSubscription(
  $id: ID!
) {
  jobUpdated(id: $id) {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "jobUpdated",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Job",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "JobUpdatedSubscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "JobUpdatedSubscription",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "subscription",
    "name": "JobUpdatedSubscription",
    "id": null,
    "text": "subscription JobUpdatedSubscription(\n  $id: ID!\n) {\n  jobUpdated(id: $id) {\n    id\n    name\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a33fd0d54b6aa239e2bed71157851127';
module.exports = node;

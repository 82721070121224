// @flow

import React, { PureComponent } from 'react';
import interact from 'interactjs';
import { createFragmentContainer, graphql } from 'react-relay';
import type { JobList_jobEdges as JobEdgesFragment } from './__generated__/JobList_jobEdges';
import DraggableJobCard from './DraggableJobCard';

type Props = {
  jobEdges: JobEdgesFragment,
  onJobDropToSchedule: (number, number, Object) => void,
  onJobDropToAutoSchedule: Object => void,
  onJobDragStart: boolean => void,
  onJobDragEnd: () => void,
  onViewOnSchedule: Object => void,
};

class JobList extends PureComponent<Props> {
  draggable = null;
  droppable = null;

  componentDidMount() {
    const { onJobDragStart, onJobDragEnd } = this.props;
    let x;
    let y;
    let original;

    this.draggable = interact('.draggable-job')
      .draggable({
        allowFrom: '.draggable-icon',
        ignoreFrom: '.job-list',
        enabled: true,
      })
      .on('move', e => {
        const interaction = e.interaction;

        // if the pointer was moved while being held down
        // and an interaction hasn't started yet
        if (interaction.pointerIsDown && !interaction.interacting()) {
          original = e.currentTarget;
          original.style.opacity = 0;

          // create a clone of the currentTarget element
          const clone = e.currentTarget.cloneNode(true);

          const targetBounding = original.getBoundingClientRect();

          // translate the element
          clone.style = original.style;
          clone.style.transform = 'translate(0px, 0px)';
          clone.style.position = 'absolute';
          clone.style.top = targetBounding.top + window.scrollY + 'px';
          clone.style.left = targetBounding.left + window.scrollX + 'px';
          clone.style['z-index'] = 2;

          // update the posiion attributes
          clone.setAttribute('data-x', 0);
          clone.setAttribute('data-y', 0);

          // insert the clone to the page
          // TODO: position the clone appropriately
          document.body.appendChild(clone);

          // start a drag interaction targeting the clone
          interaction.start({ name: 'drag' }, e.interactable, clone);
        }
      })
      .on('dragstart', e => {
        const isParallelWorkflow =
          e.target.dataset.jobParallel_workflow === 'true';
        e.target.style.opacity = '0.8';

        x = e.pageX;
        y = e.pageY;

        onJobDragStart(isParallelWorkflow);
      })
      .on('dragmove', e => {
        const { pageX, pageY } = e;
        e.target.style.transform = `translate(${pageX - x}px, ${pageY - y}px)`;
      })
      .on('dragend', e => {
        e.target.style.width = 'auto';
        e.target.style.height = 'auto';
        e.target.style.opacity = '1';
        e.target.style.transform = '';
        e.target.parentNode.removeChild(e.target); // delete the clone

        if (original) {
          original.style.opacity = '1';
          original = null;
        }

        onJobDragEnd();
      });

    this.droppable = interact('.rct-scroll, .auto-schedule-dropzone, .job-list')
      .dropzone({
        accept: '.draggable-job',
      })
      .on('drop', e => {
        // ensure we are not dragging back into the job-list
        if (e.target.className === 'rct-scroll') {
          this.handleJobDropToSchedule(e.dragEvent);
        } else if (e.target.className.startsWith('auto-schedule-dropzone')) {
          this.handleJobDropToAutoSchedule(e.dragEvent);
        }
      });
  }

  componentWillUnmount() {
    if (this.draggable) {
      this.draggable.unset();
    }

    if (this.droppable) {
      this.droppable.unset();
    }
  }

  getJobById = (jobId: string) => {
    const { jobEdges } = this.props;

    const job = jobEdges.find(edge => {
      return edge?.node?.id === jobId;
    });

    return job?.node || null;
  };

  handleJobDropToSchedule = (e: *) => {
    const { onJobDropToSchedule } = this.props;

    const jobId = e.target.attributes['data-job-id'].value;

    if (jobId) {
      const job = this.getJobById(jobId);
      onJobDropToSchedule(e.pageX, e.pageY, job);
    }
  };

  handleJobDropToAutoSchedule = (e: *) => {
    const { onJobDropToAutoSchedule } = this.props;

    const jobId = e.target.attributes['data-job-id'].value;

    if (jobId) {
      const job = this.getJobById(jobId);
      onJobDropToAutoSchedule(job);
    }
  };

  render() {
    const { jobEdges, onViewOnSchedule } = this.props;

    return (
      <div className="job-list" style={{ zIndex: 1 }}>
        {jobEdges.filter(Boolean).map(({ node }) => (
          <DraggableJobCard
            key={node.id}
            job={node}
            history={history}
            onViewOnSchedule={onViewOnSchedule}
          />
        ))}
      </div>
    );
  }
}

export default createFragmentContainer(JobList, {
  jobEdges: graphql`
    fragment JobList_jobEdges on JobEdge @relay(plural: true) {
      node {
        id
        states {
          id
          isEnabled
          workflowState {
            id
            name
            machineType {
              id
              name
            }
          }
          runs {
            id
            createdAt
            status
          }
        }
        workflow {
          id
          isParallelWorkflow
        }
        ...DraggableJobCard_job
        ...AddUpdateRunModal_job
        ...AutoScheduleJobModal_job
      }
    }
  `,
});

/**
 * @flow
 * @relayHash a53e08269cc6c708a86d4e332794c136
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UpdateViewerAvatarModal_user$ref = any;
type UserAvatar_user$ref = any;
export type CompanyUserRole = "ADMIN" | "SUPERUSER" | "USER" | "WORKER";
export type ProfileQueryVariables = {||};
export type ProfileQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +avatar: ?string,
    +firstName: string,
    +lastName: string,
    +email: any,
    +role: CompanyUserRole,
    +$fragmentRefs: UserAvatar_user$ref & UpdateViewerAvatarModal_user$ref,
  |}
|};
export type ProfileQuery = {|
  variables: ProfileQueryVariables,
  response: ProfileQueryResponse,
|};
*/


/*
query ProfileQuery {
  viewer {
    id
    avatar
    firstName
    lastName
    email
    role
    ...UserAvatar_user
    ...UpdateViewerAvatarModal_user
  }
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}

fragment UpdateViewerAvatarModal_user on User {
  id
  avatar
  firstName
  ...UserAvatar_user
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "role",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProfileQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "UserAvatar_user",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "UpdateViewerAvatarModal_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProfileQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ProfileQuery",
    "id": null,
    "text": "query ProfileQuery {\n  viewer {\n    id\n    avatar\n    firstName\n    lastName\n    email\n    role\n    ...UserAvatar_user\n    ...UpdateViewerAvatarModal_user\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n\nfragment UpdateViewerAvatarModal_user on User {\n  id\n  avatar\n  firstName\n  ...UserAvatar_user\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7987fdf98638825a2df30a28bbaa3a9d';
module.exports = node;

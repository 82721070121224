// @flow

import React from 'react';
import styled from 'styled-components';
import AppliedTag from 'shared/components/common/AppliedTag';
import type { MassagedFilter } from '.';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row wrap;

  button {
    margin: 0 8px 8px 0;
  }
`;

type Props = {
  massagedFilters: Array<MassagedFilter>,
  onRemoveFilter: (string, any) => void,
  saveButton?: React$Node,
};

const AppliedFiltersList = ({
  massagedFilters,
  onRemoveFilter,
  saveButton,
}: Props) => (
  <Wrapper>
    {massagedFilters.map((massagedFilter, i) => {
      return (
        <AppliedTag
          key={`${massagedFilter.name}-${i}`} // eslint-disable-line react/no-array-index-key
          width="auto"
          theme="grey"
          margin
          onClose={() => {
            onRemoveFilter(massagedFilter.name, massagedFilter.value);
          }}
        >
          {massagedFilter.filterOption.label}
          :&nbsp;
          {massagedFilter.cosmeticValue}
        </AppliedTag>
      );
    })}
    {saveButton || null}
  </Wrapper>
);

AppliedFiltersList.defaultProps = {
  saveButton: null,
};
export default AppliedFiltersList;

// @flow

import React from 'react';
import { i18n } from 'shared/utils';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import Button from 'shared/components/common/Button';
import ButtonLink from 'shared/components/common/Button/ButtonLink';
import AppliedFiltersList from '../AppliedFiltersList';
import FilterValueInput from './FilterValueInput';
import {
  MobileFormSeparator,
  MobileButtonWrapper,
  MobileAppliedFiltersWrapper,
} from './styled';
import type { FilterOption, MassagedFilter } from '..';

type Props = {
  filterOptions: Array<FilterOption>,
  massagedFilters: Array<MassagedFilter>,
  onApplyFilters: (Array<Object>) => void,
  onRemoveFilter: (string, any) => void,
  onClose: () => void,
};

export default ({
  filterOptions,
  massagedFilters,
  onClose,
  onRemoveFilter,
  onApplyFilters,
}: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        filterName: {
          value: filterOptions[0].name,
        },
        filterValue: '',
      }}
      onSubmit={({ filterName, filterValue }: Object) => {
        if (!filterName?.value || !filterValue) {
          return;
        }

        onApplyFilters([
          {
            name: filterName.value,
            value: filterValue?.value || filterValue,
          },
        ]);
      }}
      render={({ values, errors, handleSubmit, setFieldValue }) => (
        <Form>
          <ModalHeader header={i18n.t('Add Filters')} onClose={onClose} />
          <ModalBody withPadding>
            <FieldGroup
              type="select"
              name="filterName"
              options={filterOptions.map(a => ({
                label: a.label,
                value: a.name,
              }))}
              onChange={() => {
                setFieldValue('filterValue', '');
              }}
            />
            <MobileFormSeparator>{i18n.t('Is')}</MobileFormSeparator>
            <FilterValueInput
              filterName={values.filterName}
              filterOptions={filterOptions}
            />
            <MobileButtonWrapper>
              <ButtonLink type="button" onClick={handleSubmit}>
                {i18n.t('Add Filter')}
              </ButtonLink>
            </MobileButtonWrapper>
          </ModalBody>
          <MobileAppliedFiltersWrapper>
            <AppliedFiltersList
              massagedFilters={massagedFilters}
              onRemoveFilter={onRemoveFilter}
            />
          </MobileAppliedFiltersWrapper>
          <ModalFooter>
            <Button
              theme="blue"
              type="submit"
              onClick={(...args) => {
                // Submit the form first
                handleSubmit(...args);

                // Then close the modal
                onClose();
              }}
            >
              {i18n.t(`View Results`)}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

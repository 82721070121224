// @flow

import React, { PureComponent } from 'react';
import Icon from 'shared/components/common/Icon';
import { fonts, colors } from 'shared/styleguide';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import Asterisk from 'shared/components/common/Asterisk';
import IconNodeModel from './IconNodeModel';
import IconPortWidget from './IconPortWidget';

type Props = {
  baseClass?: string,
  className?: string,
  extraProps?: any,
  icon: string,
  size?: number,
  node: IconNodeModel,
  name: string,
  defaultUserLabel?: string,
  formLabel?: string,
  runMinutesOverride?: string,
  displayDefaultColor: boolean,
};

const Label = styled.div`
  margin-top: 10px;
  margin-bottom: 4px;
  ${fonts.bodyBold}
  ${colors.charcoalGrey}
  word-break: break-word;
`;

const SubLabel = styled.div`
  margin-top: 4px;
  ${fonts.smallRegular}
  ${colors.battleshipGrey}
`;

const IconNodeDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
`;

const IconWidgetWrapper = styled.div`
  width: ${p => p.size};
  height: ${p => p.size};
`;

const IconWrapper = styled.div`
  position: relative;

  svg rect {
    fill: ${p => (p.displayDefaultColor ? p.color : colors.battleshipGrey)};
  }

  svg:hover {
    cursor: pointer;

    rect {
      fill: ${p => p.color};
    }
  }

  .srd-port {
    background: ${colors.coolGrey};
  }

  .port.srd-port--selected,
  .port.srd-port:hover {
    cursor: pointer;
    background: rgb(0, 192, 255);
    box-shadow: 0 0 4px 4px rgb(0, 192, 255, 0.5);
  }
`;

const IconLabels = styled.div`
  position: absolute;
  width: 200px;
  top: 60px;
`;

class IconWidget extends PureComponent<Props> {
  className: string;
  static defaultProps = {
    size: 56,
    defaultUserLabel: '',
    formLabel: '',
    runMinutesOverride: '',
    baseClass: '',
    className: '',
    extraProps: null,
  };

  constructor(props: Props) {
    super(props);
    this.className = props.icon;
  }

  getClassName(): string {
    const { baseClass, className } = this.props;

    return (
      (baseClass || this.className) + ' ' + (className ? className + ' ' : '')
    );
  }

  getProps(): any {
    const { extraProps } = this.props;
    return {
      ...((extraProps: any) || {}),
      className: this.getClassName(),
    };
  }

  bem(selector: string): string {
    const { baseClass } = this.props;
    return (baseClass || this.className) + selector + ' ';
  }

  render() {
    const {
      icon,
      size,
      name,
      runMinutesOverride,
      formLabel,
      defaultUserLabel,
      node,
      displayDefaultColor,
    } = this.props;
    const displayLeftPort = icon !== 'square-job-created';
    const displayRightPort = icon !== 'square-job-completed';
    let color;
    let primaryLabel;

    if (icon === 'square-job-created') {
      color = '#00BD83';
      primaryLabel = 'Job Created';
    } else if (icon === 'square-job-completed') {
      color = '#D46147';
      primaryLabel = 'Job Completed';
    } else if (icon === 'circle-task') {
      color = '#EFC036';
      primaryLabel = name || i18n.t('Untitled Task');
    } else if (icon === 'circle-run') {
      color = '#3050D5';
      primaryLabel = name || i18n.t('Untitled Run');
    } else if (icon === 'circle-job-item-count') {
      color = '#42444C';
      primaryLabel = i18n.t('Item Count');
    }

    return (
      <IconWidgetWrapper
        onContextMenu={e => {
          e.preventDefault();
        }}
      >
        <IconNodeDetails>
          <IconWrapper color={color} displayDefaultColor={displayDefaultColor}>
            <Icon type={icon} size={size} />
            {displayLeftPort && (
              <IconPortWidget
                name="left"
                node={node}
                style={{
                  position: 'absolute',
                  top: (size || 56) / 2 - 4,
                  left: -16,
                  borderRadius: '50%',
                  height: '8px',
                  width: '8px',
                }}
              />
            )}
            {displayRightPort && (
              <IconPortWidget
                name="right"
                node={node}
                style={{
                  position: 'absolute',
                  top: (size || 56) / 2 - 4,
                  left: size + 8,
                  borderRadius: '50%',
                  height: '8px',
                  width: '8px',
                }}
              />
            )}
          </IconWrapper>
          <IconLabels>
            <Label>
              {primaryLabel}
              {node.data.isRequired && <Asterisk>*</Asterisk>}
            </Label>
            {Boolean(formLabel) && (
              <SubLabel>
                {formLabel} {i18n.t('Form')}
              </SubLabel>
            )}
            {Boolean(defaultUserLabel) && (
              <SubLabel>
                {i18n.t('Assign to')} {defaultUserLabel}
              </SubLabel>
            )}
            {Boolean(runMinutesOverride) && (
              <SubLabel>
                {runMinutesOverride} {i18n.t('min duration')}
              </SubLabel>
            )}
          </IconLabels>
        </IconNodeDetails>
      </IconWidgetWrapper>
    );
  }
}

export default IconWidget;

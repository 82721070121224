/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CompanyUserRole = "ADMIN" | "SUPERUSER" | "USER" | "WORKER";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateUserModal_user$ref: FragmentReference;
declare export opaque type AddUpdateUserModal_user$fragmentType: AddUpdateUserModal_user$ref;
export type AddUpdateUserModal_user = {|
  +id: string,
  +firstName: string,
  +lastName: string,
  +role: CompanyUserRole,
  +email: any,
  +userNumber: string,
  +$refType: AddUpdateUserModal_user$ref,
|};
export type AddUpdateUserModal_user$data = AddUpdateUserModal_user;
export type AddUpdateUserModal_user$key = {
  +$data?: AddUpdateUserModal_user$data,
  +$fragmentRefs: AddUpdateUserModal_user$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AddUpdateUserModal_user",
  "type": "User",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "role",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userNumber",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'fab292c891a29a4f3613933c0a1e282d';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation AddDowntimeCategoryMutation($input: AddDowntimeCategoryInput!) {
    addDowntimeCategory(input: $input) {
      downtimeCategoryEdge {
        cursor
        node {
          id
          name
          updatedAt
          createdAt
          parentCategory {
            id
            name
          }
          childCategories(first: null)
            @connection(
              key: "DowntimeCategories_downtimeCategories_childCategories"
            ) {
            edges {
              node {
                id
                name
                ...AddUpdateDowntimeCategoryModal_downtimeCategory
              }
            }
          }
          ...AddUpdateDowntimeCategoryModal_downtimeCategory
        }
      }
    }
  }
`;

export default {
  commit: (options: *) => {
    return createRelayMutation(mutation, {
      ...options,
      updater: store => {
        const parentId = options.variables.input.parentDowntimeCategoryId;

        const newDowntimeCategory = store
          .getRootField('addDowntimeCategory')
          .getLinkedRecord('downtimeCategoryEdge');

        const root = store.getRoot();

        const rootConn = ConnectionHandler.getConnection(
          root,
          'DowntimeCategories_downtimeCategories',
        );

        if (parentId) {
          const parentEdge = rootConn
            .getLinkedRecords('edges')
            .find(edge => edge.getLinkedRecord('node')._dataID === parentId);

          if (parentEdge) {
            const childNode = parentEdge.getLinkedRecord('node');
            const childConn = ConnectionHandler.getConnection(
              childNode,
              'DowntimeCategories_downtimeCategories_childCategories',
            );

            if (childConn) {
              ConnectionHandler.insertEdgeBefore(
                childConn,
                newDowntimeCategory,
              );
            }
          }

          return;
        }

        ConnectionHandler.insertEdgeBefore(rootConn, newDowntimeCategory);
      },
    });
  },
};

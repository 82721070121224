// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation UpdateRunMutation($input: UpdateRunInput!) {
    updateRun(input: $input) {
      runEdge {
        cursor
        node {
          id
          status
          scheduledStartAt
          scheduledEndAt
          notes
          jobState {
            id
            workflowState {
              id
              name
            }
          }
          machine {
            id
            name
            type {
              id
              name
            }
          }
          yields(first: null) {
            edges {
              node {
                id
                quantity
                item {
                  id
                  name
                }
              }
            }
          }
          job {
            id
            importJobNumber
            jobNumber
            name
            ...AddUpdateRunModal_job
          }
          ...AddUpdateRunModal_run
          ...DeleteRunModal_run
          ...ViewRunModal_run
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

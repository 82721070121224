/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateJobModal_job$ref = any;
type DeleteJobModal_job$ref = any;
export type JobStatus = "CLOSED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ItemLinkedJobsTable_itemLinkedJobEdges$ref: FragmentReference;
declare export opaque type ItemLinkedJobsTable_itemLinkedJobEdges$fragmentType: ItemLinkedJobsTable_itemLinkedJobEdges$ref;
export type ItemLinkedJobsTable_itemLinkedJobEdges = $ReadOnlyArray<{|
  +usageQty: ?number,
  +node: {|
    +id: string,
    +jobNumber: string,
    +importJobNumber: ?string,
    +name: string,
    +status: JobStatus,
    +createdAt: any,
    +yields: {|
      +totalCount: number
    |},
    +order: ?{|
      +id: string,
      +promisedAt: ?any,
      +customer: {|
        +id: string,
        +name: string,
      |},
    |},
    +states: $ReadOnlyArray<{|
      +id: string
    |}>,
    +$fragmentRefs: AddUpdateJobModal_job$ref & DeleteJobModal_job$ref,
  |},
  +$refType: ItemLinkedJobsTable_itemLinkedJobEdges$ref,
|}>;
export type ItemLinkedJobsTable_itemLinkedJobEdges$data = ItemLinkedJobsTable_itemLinkedJobEdges;
export type ItemLinkedJobsTable_itemLinkedJobEdges$key = {
  +$data?: ItemLinkedJobsTable_itemLinkedJobEdges$data,
  +$fragmentRefs: ItemLinkedJobsTable_itemLinkedJobEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ItemLinkedJobsTable_itemLinkedJobEdges",
  "type": "ItemLinkedJobEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "usageQty",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Job",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "jobNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "importJobNumber",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "yields",
          "storageKey": null,
          "args": null,
          "concreteType": "JobYieldConnection",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "totalCount",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "order",
          "storageKey": null,
          "args": null,
          "concreteType": "Order",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "promisedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "customer",
              "storageKey": null,
              "args": null,
              "concreteType": "Customer",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "states",
          "storageKey": null,
          "args": null,
          "concreteType": "JobState",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateJobModal_job",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "DeleteJobModal_job",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '86376e766d2d4b42650cb05ae36c68d1';
module.exports = node;

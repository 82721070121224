// @flow

import React from 'react';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import { i18n } from 'shared/utils';
import EmptyListResults from 'shared/components/common/EmptyListResults';

const Wrapper = styled.div`
  border-radius: 15px;
  border: 2px solid ${colors.azul};
  width: 100%;
  height: 100%;
  padding-top: 48px;
  background-color: rgba(54, 90, 239, 0.1);
`;

export default () => (
  <Wrapper>
    <EmptyListResults
      graphic="attachmentsDragging"
      message={i18n.t('Drop your file(s) to begin uploading!')}
    />
  </Wrapper>
);

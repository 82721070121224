// @flow

import React, { PureComponent } from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import { createFragmentContainer, graphql, fetchQuery } from 'react-relay';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';
import * as Actions from 'main-app/store/Actions';
import AddItemQtyMutation from 'main-app/mutations/AddItemQty';
import DeductItemQtyMutation from 'main-app/mutations/DeductItemQty';
import TransferItemMutation from 'main-app/mutations/TransferItem';
import AddLotMutation from 'shared/mutations/AddLot';
import Asterisk from 'shared/components/common/Asterisk';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
  SelectLotField,
} from 'shared/components/form';
import { computeValidationSchema } from 'shared/components/form/FormBuilder';
import MovementOptions from './movementOptions';
import type { AddItemMovementModal_item as ItemFragment } from './__generated__/AddItemMovementModal_item';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  onReopen?: () => void,
  item: ItemFragment,
  createAnotherMovement?: boolean,
};

type State = {
  validationSchema: Object,
};

const LANGUAGE = {
  modalTitle: i18n.t('New Item Movement'),
  successMessage: i18n.t('Item Movement Successfully Created'),
  submitButton: i18n.t('Create Item Movement'),
  ADD: {
    title: i18n.t('Create Item Movement'),
    label: i18n.t('Add To Lot'),
  },
  DEDUCT: {
    label: i18n.t('Deduct From Lot'),
  },
  TRANSFER: {
    fromLabel: i18n.t('Transfer From Lot'),
    toLabel: i18n.t('Transfer To Lot'),
  },
  LABELS: {
    movementType: (
      <div>
        {i18n.t('Select a Movement Type')}
        <Asterisk>*</Asterisk>
      </div>
    ),
    quantity: i18n.t('Quantity'),
    notes: i18n.t('Enter a Note'),
    createAnotherToggle: i18n.t('Create Another Movement'),
  },
};

const Divider = styled.div`
  border-top: 2px solid ${colors.lightBlueGrey};
  margin-top: 16px;
  padding-bottom: 16px;
`;

class AddItemMovementModal extends PureComponent<Props, State> {
  form: Object;
  initialValues: Object;
  validationSchema: Object;
  determineMutation: Function;
  formatValues: Function;
  handleMovementChange: Function;
  getAvailableQty: Function;
  filterMovementOptions: Function;
  getDisabledLotId: Function;
  addLotMutation: Function;

  static defaultProps = {
    onSuccess: undefined,
    onReopen: undefined,
    createAnotherMovement: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      validationSchema: {
        movementType: Yup.string().required('Required'),
        fromLot: Yup.string(),
        toLot: Yup.string().when('$lotTracking', {
          is: () => this.props?.item?.lotTracking,
          then: Yup.string()
            .nullable()
            .required('Required'),
          otherwise: Yup.string(),
        }),
        quantity: Yup.number()
          .moreThan(0)
          .required('Required'),
        notes: Yup.string(),
        createAnotherMovement: Yup.boolean(),
      },
    };

    const { createAnotherMovement } = this.props;

    this.initialValues = {
      movementType: '',
      fromLot: '',
      toLot: '',
      quantity: '',
      notes: '',
      createAnotherMovement,
    };
  }

  determineMutation = (movementType: Object) => {
    switch (movementType.value) {
      case 'ADD':
        return AddItemQtyMutation;
      case 'DEDUCT':
        return DeductItemQtyMutation;
      case 'TRANSFER':
        return TransferItemMutation;
      default:
        return null;
    }
  };

  formatValues = (values: Object, movementType: Object) => {
    const {
      item: { id },
    } = this.props;

    const result: Object = {
      itemId: id,
      lotId: values.toLot.value,
      quantity: Number(values.quantity),
      notes: values.notes,
    };

    if (movementType?.value === 'TRANSFER') {
      result.fromLotId = values.fromLot?.value;
    }

    return result;
  };

  handleMovementChange = (type: Object) => {
    const { validationSchema } = this.state;
    const schemaCopy = { ...validationSchema };

    if (type?.value === 'TRANSFER') {
      schemaCopy.fromLot = Yup.string()
        .required('Required')
        .nullable();
    } else {
      schemaCopy.fromLot = Yup.string();
    }

    this.setState({ validationSchema: schemaCopy });
  };

  getAvailableQty = (lotId: string) => {
    const {
      item: {
        lots: { edges },
      },
    } = this.props;

    const defaultValue = { quantity: 0 };
    const { quantity } =
      edges && edges.length
        ? edges
            .filter(Boolean)
            .find(a =>
              lotId ? a.node.id === lotId : a.node.lotNumber === 'Default',
            ) || defaultValue
        : defaultValue;
    return quantity;
  };

  filterMovementOptions = () => {
    const {
      item: { lotTracking },
    } = this.props;

    return !lotTracking
      ? MovementOptions.filter(option => option.value !== 'TRANSFER')
      : MovementOptions;
  };

  getDisabledLotId = async () => {
    const QUERY = graphql`
      query AddItemMovementModalQuery($lotNumbers: [String!]) {
        lots(first: 1, lotNumbers: $lotNumbers) {
          edges {
            node {
              id
            }
          }
        }
      }
    `;
    try {
      const { lots } = await fetchQuery(relayEnvironment, QUERY, {
        lotNumbers: ['Default'],
      });

      if (lots?.edges.length) {
        return lots.edges[0].node.id;
      }

      return await this.addLotMutation(
        'Default',
        'Contains items w/o lot tracking',
      );
    } catch (e) {
      throw new Error(
        'There was a problem with this transaction. Please refresh the page and try again!',
      );
    }
  };

  addLotMutation = async (lotNumber: string, binNumber: string = '') => {
    const { addLot } = await AddLotMutation.commit({
      variables: {
        input: {
          lotNumber,
          binNumber,
        },
      },
    });

    return addLot.lotEdge.node.id;
  };

  render() {
    const { onClose, onSuccess, onReopen, item } = this.props;
    const { validationSchema } = this.state;
    const unitOfMeasurement = item?.quantityUOM?.name || 'Quantity';
    const movementOptions = this.filterMovementOptions();

    const computedValidationSchema = computeValidationSchema(
      null,
      validationSchema,
    );

    return (
      <Modal maxWidth={700}>
        <Formik
          initialValues={this.initialValues}
          validationSchema={computedValidationSchema}
          onSubmit={async (values: *, { setSubmitting }: *) => {
            setSubmitting(true);

            const { movementType, createAnotherMovement } = values;
            const mutation: any = this.determineMutation(movementType);
            const input = this.formatValues(values, movementType);

            try {
              if (!item?.lotTracking) {
                const disabledLotId = await this.getDisabledLotId();
                input.lotId = disabledLotId;
              } else if (values.toLot && values.toLot.__isNew__) {
                const newLotId = await this.addLotMutation(values.toLot?.value);
                input.lotId = newLotId;
              }

              const response = await mutation.commit({
                variables: {
                  input,
                },
              });

              const itemResponse =
                movementType.value === 'ADD'
                  ? response.addItemQty.itemEdge.node
                  : movementType.value === 'DEDUCT'
                  ? response.deductItemQty.itemEdge.node
                  : movementType.value === 'TRANSFER'
                  ? response.transferItem.itemEdge.node
                  : null;

              if (createAnotherMovement && onReopen) {
                onReopen();
              } else {
                onClose();
                if (onSuccess) {
                  onSuccess(itemResponse);
                }
              }

              Analytics.trackEvent(`${movementType.value} Inventory`, {
                inventoryId: values.itemId,
                lotId: values.lotId,
                fromLotId: values.fromLotId,
                quantity: values.quantity,
              });
              Actions.alertNotification(LANGUAGE.successMessage, 'success');
            } catch (e) {
              setSubmitting(false);
              Actions.alertNotification(e.message, 'Something Went Wrong');
            }
          }}
          render={({
            errors,
            values,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => {
            const movementType = values?.movementType?.value;
            const fromLotId = values?.fromLot?.value;
            const toLotId = values?.toLot?.value;
            let availableQty = 0;

            if (movementType === 'TRANSFER') {
              availableQty = this.getAvailableQty(fromLotId);
            }

            if (movementType === 'DEDUCT') {
              availableQty = this.getAvailableQty(toLotId);
            }

            return (
              <Form>
                <ModalHeader header={LANGUAGE.modalTitle} onClose={onClose} />
                <ModalBody withPadding>
                  <FieldGroup
                    type="select"
                    name="movementType"
                    label={LANGUAGE.LABELS.movementType}
                    placeholder={i18n.t('Movement')}
                    options={movementOptions}
                    error={errors.movementType}
                    onChange={this.handleMovementChange}
                  />{' '}
                  <Divider />
                  {!item?.lotTracking ? null : movementType === 'TRANSFER' ? (
                    <FieldGroupRow
                      left={
                        <SelectLotField
                          name="fromLot"
                          label={LANGUAGE.TRANSFER.fromLabel}
                          error={errors.fromLot}
                          itemId={item.id}
                          movement={movementType}
                        />
                      }
                      right={
                        <SelectLotField
                          name="toLot"
                          label={LANGUAGE.TRANSFER.toLabel}
                          creatable
                          clearable
                          error={errors.toLot}
                          creatingNewRecord={
                            values.toLot && values.toLot.__isNew__
                          }
                        />
                      }
                    />
                  ) : movementType === 'DEDUCT' ? (
                    <SelectLotField
                      name="toLot"
                      label={LANGUAGE[movementType]?.label}
                      error={errors.toLot}
                      itemId={item.id}
                      movement={movementType}
                    />
                  ) : movementType === 'ADD' ? (
                    <SelectLotField
                      name="toLot"
                      label={LANGUAGE[movementType]?.label}
                      creatable
                      clearable
                      error={errors.toLot}
                      itemId={item.id}
                      movement={movementType}
                      creatingNewRecord={
                        movementType === 'ADD'
                          ? values.toLot && values.toLot.__isNew__
                          : false
                      }
                    />
                  ) : null}
                  <FieldGroup
                    name="quantity"
                    label={LANGUAGE.LABELS.quantity}
                    error={errors.quantity}
                    type="number"
                    extendedLabel={i18n.t(unitOfMeasurement)}
                    style={{ width: '50%' }}
                  />
                  {movementType === 'TRANSFER' || movementType === 'DEDUCT' ? (
                    <div>
                      {i18n.t(
                        '{{availableQty, number}} {{uom}} available to {{movementType}}',
                        {
                          availableQty,
                          uom: unitOfMeasurement,
                          movementType: movementType.toLowerCase(),
                        },
                      )}
                    </div>
                  ) : null}
                  <Divider />
                  <FieldGroup
                    name="notes"
                    label={LANGUAGE.LABELS.notes}
                    error={errors.notes}
                    type="textarea"
                  />
                </ModalBody>
                <ModalFooter
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <FieldGroup
                    label={LANGUAGE.LABELS.createAnotherToggle}
                    sideLabel="right"
                    name="createAnotherMovement"
                    error={errors.createAnotherMovement}
                    type="toggle"
                  />

                  <Button
                    type="submit"
                    theme="blue"
                    disabled={!isValid}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {LANGUAGE.submitButton}
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}

export default createFragmentContainer(AddItemMovementModal, {
  item: graphql`
    fragment AddItemMovementModal_item on Item {
      id
      itemNumber
      totalQty
      quantityUOM {
        id
        name
      }
      logs {
        id
        user {
          id
        }
        lot {
          id
        }
        fromLot {
          id
        }
        operation
        quantity
        notes
        createdAt
      }
      lots(first: null) @connection(key: "Item_lots", filters: []) {
        edges {
          quantity
          node {
            id
            lotNumber
          }
        }
      }
      lotTracking
    }
  `,
});

/**
 * @flow
 * @relayHash 3c8e513a5a2ba283b9701da5ca565d0d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrdersDue_orderEdges$ref = any;
export type DashboardQueryVariables = {|
  promisedAtStart: any,
  promisedAtEnd: any,
|};
export type DashboardQueryResponse = {|
  +orders: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string
      |},
      +$fragmentRefs: OrdersDue_orderEdges$ref,
    |}>
  |}
|};
export type DashboardQuery = {|
  variables: DashboardQueryVariables,
  response: DashboardQueryResponse,
|};
*/


/*
query DashboardQuery(
  $promisedAtStart: Date!
  $promisedAtEnd: Date!
) {
  orders(promisedAtStart: $promisedAtStart, promisedAtEnd: $promisedAtEnd, statuses: [OPEN], sortBy: {field: PROMISED_AT, direction: ASC}) {
    edges {
      node {
        id
      }
      ...OrdersDue_orderEdges
    }
  }
}

fragment OrdersDue_orderEdges on OrderEdge {
  ...OrdersTable_orderEdges
}

fragment OrdersTable_orderEdges on OrderEdge {
  node {
    id
    orderNumber
    customerOrderNumber
    status
    orderedAt
    requiredAt
    promisedAt
    totalJobs
    customer {
      id
      name
    }
    ...AddUpdateOrderModal_order
    ...DeleteOrderModal_order
  }
}

fragment AddUpdateOrderModal_order on Order {
  id
  customerOrderNumber
  shippingAddressLine1
  shippingAddressLine2
  shippingAddressLine3
  shippingAddressCity
  shippingAddressState
  shippingAddressZip
  notes
  orderedAt
  requiredAt
  promisedAt
  customer {
    id
    name
  }
  user {
    id
    firstName
    lastName
  }
}

fragment DeleteOrderModal_order on Order {
  id
  orderNumber
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "promisedAtStart",
    "type": "Date!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "promisedAtEnd",
    "type": "Date!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "promisedAtEnd",
    "variableName": "promisedAtEnd"
  },
  {
    "kind": "Variable",
    "name": "promisedAtStart",
    "variableName": "promisedAtStart"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": {
      "direction": "ASC",
      "field": "PROMISED_AT"
    }
  },
  {
    "kind": "Literal",
    "name": "statuses",
    "value": [
      "OPEN"
    ]
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DashboardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "orders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "OrderEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "OrdersDue_orderEdges",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DashboardQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "orders",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrderConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "OrderEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "customerOrderNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "requiredAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "promisedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalJobs",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Customer",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine1",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine2",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine3",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressCity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressState",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressZip",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DashboardQuery",
    "id": null,
    "text": "query DashboardQuery(\n  $promisedAtStart: Date!\n  $promisedAtEnd: Date!\n) {\n  orders(promisedAtStart: $promisedAtStart, promisedAtEnd: $promisedAtEnd, statuses: [OPEN], sortBy: {field: PROMISED_AT, direction: ASC}) {\n    edges {\n      node {\n        id\n      }\n      ...OrdersDue_orderEdges\n    }\n  }\n}\n\nfragment OrdersDue_orderEdges on OrderEdge {\n  ...OrdersTable_orderEdges\n}\n\nfragment OrdersTable_orderEdges on OrderEdge {\n  node {\n    id\n    orderNumber\n    customerOrderNumber\n    status\n    orderedAt\n    requiredAt\n    promisedAt\n    totalJobs\n    customer {\n      id\n      name\n    }\n    ...AddUpdateOrderModal_order\n    ...DeleteOrderModal_order\n  }\n}\n\nfragment AddUpdateOrderModal_order on Order {\n  id\n  customerOrderNumber\n  shippingAddressLine1\n  shippingAddressLine2\n  shippingAddressLine3\n  shippingAddressCity\n  shippingAddressState\n  shippingAddressZip\n  notes\n  orderedAt\n  requiredAt\n  promisedAt\n  customer {\n    id\n    name\n  }\n  user {\n    id\n    firstName\n    lastName\n  }\n}\n\nfragment DeleteOrderModal_order on Order {\n  id\n  orderNumber\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6665918cdf54e1c30db4246edce0bd13';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      id: string,
    },
  },
  context: {
    integrationId: string,
  },
};

const mutation = graphql`
  mutation DeleteIntegrationEventMutation(
    $input: DeleteIntegrationEventInput!
  ) {
    deleteIntegrationEvent(input: $input) {
      deletedId
    }
  }
`;

export default {
  commit: ({ context, ...options }: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
      configs: [
        {
          type: 'RANGE_DELETE',
          parentID: context.integrationId,
          connectionKeys: [
            {
              key: 'Integration_events',
            },
          ],
          pathToConnection: [context.integrationId, 'runs'],
          deletedIDFieldName: 'deletedId',
        },
      ],
    });
  },
};

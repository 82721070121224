// @flow

import {
  requestSubscription,
  type GraphQLTaggedNode,
  type GraphQLSubscriptionConfig,
  type GraphQLMutationConfig,
} from 'react-relay';
import relayEnvironment from './relayEnvironment';

type SubscriptionOptions = {
  variables?: Object,
  onError?: GraphQLSubscriptionConfig.onError,
  onCompleted?: GraphQLSubscriptionConfig.onCompleted,
  onNext?: GraphQLSubscriptionConfig.onNext,
  updater?: GraphQLSubscriptionConfig.updater,
  configs?: Array<GraphQLMutationConfig.configs>,
};

export default function(
  subscription: GraphQLTaggedNode,
  options?: SubscriptionOptions = {},
) {
  return requestSubscription(relayEnvironment, {
    subscription,
    ...options,
  });
}

// @flow

import React, { PureComponent } from 'react';
import qs from 'qs';
import debounce from 'lodash.debounce';
import { Map } from 'immutable';
import { graphql, QueryRenderer } from 'react-relay';
import type { RouterHistory } from 'react-router';
import { i18n, Analytics } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Page from 'main-app/components/Page';
import PageHeaderList from 'main-app/components/PageHeaderList';
import AddUpdateJobModal from 'main-app/components/AddUpdateJobModal';
import ExportCSVModal from 'main-app/components/ExportCSVModal';
import FilterControls, {
  type FilterValue,
} from 'main-app/components/FilterControls';
import getSortOptions from './getSortOptions';
import getFilterOptions from './getFilterOptions';
import PaginatedJobsContainer from './PaginatedJobsContainer';

type Props = {
  history: RouterHistory,
};

type State = {
  addModalOpen: boolean,
  exportModalOpen: boolean,
  search: string,
  graphqlSearchValue: string,
  createAnotherJob: boolean,
};

const DEFAULT_FILTER = {
  sort: 'CREATED_AT',
  direction: 'DESC',
};

class Jobs extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addModalOpen: false,
      exportModalOpen: false,
      createAnotherJob: false,
      search: '',
      graphqlSearchValue: '',
    };

    this.handleDebouncedSearch = debounce(this.handleDebouncedSearch, 500);
  }

  getFilters = (): FilterValue => {
    const { history } = this.props;
    const { search } = history.location;

    if (!search) {
      return DEFAULT_FILTER;
    }

    return {
      ...DEFAULT_FILTER,
      ...qs.parse(search, { ignoreQueryPrefix: true }),
    };
  };

  setFilters = (filters: Object) => {
    const { history } = this.props;

    history.replace(
      '/jobs?' +
        qs.stringify(filters, {
          encode: true,
          arrayFormat: 'brackets',
        }),
    );
  };

  filtersDirty = () => {
    const { graphqlSearchValue } = this.state;

    return (
      !Map(this.getFilters()).equals(Map(DEFAULT_FILTER)) || graphqlSearchValue
    );
  };

  compileQueryVariables = () => {
    const { graphqlSearchValue } = this.state;
    const filters = this.getFilters();

    const queryVariables = {
      ...filters,
      search: graphqlSearchValue.trim() || null,
      sortBy: {
        field: filters.sort,
        direction: filters.direction,
      },
    };

    if (queryVariables.createdAt) {
      const [start, end] = queryVariables.createdAt.split(' - ');
      queryVariables.createdAtStart = start;
      queryVariables.createdAtEnd = end;

      delete queryVariables.createdAt;
    }

    delete queryVariables.sort;
    delete queryVariables.direction;

    return queryVariables;
  };

  handleSearchInput = (search: string) => {
    this.setState(
      {
        search,
      },
      this.handleDebouncedSearch,
    );
  };

  handleDebouncedSearch = () => {
    const { search } = this.state;

    // Updating graphqlSearchValue will auto-kick-off a graphql query re-fetch
    this.setState({
      graphqlSearchValue: search,
    });

    Analytics.trackEvent('Search Applied', {
      listName: 'Job Tickets',
      searchString: search,
    });
  };

  handleCSVExport = async (columns: string[]) => {
    const gqlQueryArgs = this.compileQueryVariables();

    Analytics.trackEvent('Export List', {
      page: 'Job Ticket List',
    });
    window.open(
      `/api/v1/csv-export-jobs?` +
        qs.stringify(
          {
            gqlQueryArgs,
            columns,
          },
          {
            encode: true,
            arrayFormat: 'brackets',
          },
        ),
    );
  };

  handleOpenCreateModal = () => {
    this.setState({
      addModalOpen: true,
    });
  };

  handleOpenExportModal = () => {
    this.setState({
      exportModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addModalOpen: false,
      exportModalOpen: false,
      createAnotherJob: false,
    });
  };

  handleAddModalReopen = () => {
    this.setState(
      {
        addModalOpen: false,
        exportModalOpen: false,
        createAnotherJob: true,
      },
      () => {
        this.setState({
          addModalOpen: true,
        });
      },
    );
  };

  render() {
    const { history } = this.props;
    const {
      search,
      addModalOpen,
      exportModalOpen,
      createAnotherJob,
    } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        cacheConfig={{
          force: true,
        }}
        query={graphql`
          query JobsQuery(
            $count: Int!
            $cursor: String
            $search: String
            $sortBy: JobSort
            $jobNumbers: [String!]
            $importJobNumbers: [String!]
            $customerIds: [ID!]
            $name: String
            $statuses: [JobStatus!]
            $userIds: [ID!]
            $itemName: String
            $itemPartNumber: String
            $createdAtStart: Date
            $createdAtEnd: Date
          ) {
            ...PaginatedJobsContainer_jobs
              @arguments(
                search: $search
                sortBy: $sortBy
                jobNumbers: $jobNumbers
                importJobNumbers: $importJobNumbers
                customerIds: $customerIds
                name: $name
                statuses: $statuses
                userIds: $userIds
                itemName: $itemName
                itemPartNumber: $itemPartNumber
                createdAtStart: $createdAtStart
                createdAtEnd: $createdAtEnd
              )

            viewer {
              id
              permissions
              company {
                id
              }
            }

            workflows {
              edges {
                node {
                  id
                  isDefault
                  ...AddUpdateJobModal_workflow
                }
              }
            }
          }
        `}
        variables={{
          ...this.compileQueryVariables(),
          count: 30,
          cursor: null,
        }}
        render={({ props }) => (
          <Page>
            <PageHeaderList
              title={i18n.t('Job Tickets')}
              exportButtonLabel={props && i18n.t('Export to CSV')}
              onExportButtonClick={this.handleOpenExportModal}
              createButtonLabel={
                props &&
                props.viewer.permissions.includes('CREATE_JOB') &&
                i18n.t('New Job Ticket')
              }
              onCreateButtonClick={this.handleOpenCreateModal}
              searchPlaceholder={i18n.t('Job #, Customer, or Project')}
              searchValue={search}
              onSearchChange={this.handleSearchInput}
            />
            <FilterControls
              value={this.getFilters()}
              defaultValue={DEFAULT_FILTER}
              sortOptions={getSortOptions()}
              filterOptions={getFilterOptions()}
              savedFilterType="JOB"
              onFiltersChange={filters => this.setFilters(filters)}
            />
            {!props ? (
              <Loader />
            ) : (
              <PaginatedJobsContainer
                history={history}
                filtersDirty={this.filtersDirty()}
                onCreateNew={this.handleOpenCreateModal}
                jobs={props}
                deleteEnabled
                editEnabled={props.viewer.permissions.includes('UPDATE_JOB')}
              />
            )}
            {addModalOpen && (
              <AddUpdateJobModal
                onClose={this.handleCloseAllModals}
                onSuccess={job => history.push(`/job/${job.id}`)}
                onReopen={this.handleAddModalReopen}
                createAnotherJob={createAnotherJob}
                job={null}
                companyId={props.viewer.company.id}
                workflow={
                  (props.workflowEdges &&
                    props.workflowEdges.find(edge => edge?.node.isDefault)
                      ?.node) ||
                  null
                }
              />
            )}
            {exportModalOpen && (
              <ExportCSVModal
                onClose={this.handleCloseAllModals}
                title={i18n.t('Export Jobs to CSV')}
                columnsApiUrl="/api/v1/csv-export-jobs/columns"
                onSubmit={this.handleCSVExport}
              />
            )}
          </Page>
        )}
      />
    );
  }
}

export default Jobs;

// @flow

import React from 'react';
import styled from 'styled-components';

type Props = {
  items: Array<React$Node>,
};

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  li {
    padding-bottom: 16px;
    padding-right: 16px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  @media (min-width: 1000px) {
    padding-bottom: 16px;
    flex-flow: row nowrap;
    /* align-items: center; */

    li {
      padding-bottom: 0;
      padding-right: 32px;
    }
  }
`;

const PageDetailsSummary = ({ items }: Props) => (
  <Wrapper>
    {items.filter(Boolean).map((item, i) => (
      <li key={i}>{item}</li> // eslint-disable-line react/no-array-index-key
    ))}
  </Wrapper>
);

export default PageDetailsSummary;

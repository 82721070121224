// @flow

import React from 'react';
import request from 'superagent';
import { Route, Switch, Redirect, type RouterHistory } from 'react-router-dom';
import { LocalStorage, i18n } from 'shared/utils';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import TabNav from 'shared/components/common/TabNav';
import Button from 'shared/components/common/Button';
import Profile from './Profile';
import Company from './Company';
import FieldConfiguration from './FieldConfigurations';
import Machines from './Machines';
import MachineTypes from './MachineTypes';
import DowntimeCategories from './DowntimeCategories';
import OperatingHours from './OperatingHours';
import Users from './Users';
import Forms from './Forms';
import Form from './Form';
import APIKeys from './APIKeys';
import Workflows from './Workflows';
import Workflow from './Workflow';
import Integrations from './Integrations';
import Integration from './Integration';

type Props = {
  history: RouterHistory,
};

const handleLogout = async () => {
  await request.delete('/api/v1/session');

  LocalStorage.clear();

  window.location = '/';
};

const getActivePath = (pathname: string) => {
  if (pathname.startsWith('/settings/line')) {
    return '/settings/lines';
  }

  if (pathname.startsWith('/settings/form')) {
    return '/settings/forms';
  }

  if (pathname.startsWith('/settings/workflow')) {
    return '/settings/workflows';
  }

  return pathname;
};

export default ({ history }: Props) => {
  const tabs = [
    {
      label: i18n.t('My Profile'),
      path: `/settings/profile`,
    },
    // {
    //   label: i18n.t('Company'),
    //   path: `/settings/company`,
    // },
    // {
    //   label: i18n.t('Team'),
    //   path: `/settings/team`,
    // },
    {
      label: i18n.t('Users'),
      path: `/settings/users`,
    },
    {
      label: i18n.t('Workflows'),
      path: `/settings/workflows`,
    },
    {
      label: i18n.t('Work Centers'),
      path: `/settings/machines`,
    },
    {
      label: i18n.t('Work Center Types'),
      path: `/settings/machine-types`,
    },
    {
      label: i18n.t('Downtime'),
      path: `/settings/downtime-categories`,
    },
    {
      label: i18n.t('Operating Hours'),
      path: `/settings/operating-hours`,
    },
    {
      label: i18n.t('Forms'),
      path: `/settings/forms`,
    },
    {
      label: i18n.t('Integrations'),
      path: `/settings/integrations`,
    },
    {
      label: i18n.t('API Keys'),
      path: `/settings/api-keys`,
    },
  ];

  return (
    <Page>
      <PageHeader
        title={i18n.t('Settings')}
        right={
          <Button onClick={handleLogout} theme="border-white">
            {i18n.t('Logout')}
          </Button>
        }
      />
      <TabNav
        history={history}
        activePath={getActivePath(history.location.pathname)}
        tabs={tabs}
      />
      <Switch>
        <Route exact path="/settings/profile" component={Profile} />
        <Route exact path="/settings/users" component={Users} />
        <Route exact path="/settings/workflows" component={Workflows} />
        <Route exact path="/settings/workflow/:id" component={Workflow} />
        <Route exact path="/settings/company" component={Company} />
        <Route exact path="/settings/fields" component={FieldConfiguration} />
        <Route exact path="/settings/machines" component={Machines} />
        <Route exact path="/settings/machine-types" component={MachineTypes} />
        <Route
          exact
          path="/settings/downtime-categories"
          component={DowntimeCategories}
        />
        <Route
          exact
          path="/settings/operating-hours"
          component={OperatingHours}
        />
        <Route exact path="/settings/forms" component={Forms} />
        <Route exact path="/settings/form/:id" component={Form} />
        <Route exact path="/settings/integrations" component={Integrations} />
        <Route exact path="/settings/integration/:id" component={Integration} />
        <Route exact path="/settings/api-keys" component={APIKeys} />
        <Route exact render={() => <Redirect to="/settings/profile" />} />
      </Switch>
    </Page>
  );
};

/**
 * @flow
 * @relayHash 661173cf1c397e15740f82dbc8bb0683
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SwitchSessionCompanyInput = {|
  companyId: string
|};
export type SwitchSessionCompanyMutationVariables = {|
  input: SwitchSessionCompanyInput
|};
export type SwitchSessionCompanyMutationResponse = {|
  +switchSessionCompany: {|
    +viewer: {|
      +id: string
    |}
  |}
|};
export type SwitchSessionCompanyMutation = {|
  variables: SwitchSessionCompanyMutationVariables,
  response: SwitchSessionCompanyMutationResponse,
|};
*/


/*
mutation SwitchSessionCompanyMutation(
  $input: SwitchSessionCompanyInput!
) {
  switchSessionCompany(input: $input) {
    viewer {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SwitchSessionCompanyInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "switchSessionCompany",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SwitchSessionCompanyPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SwitchSessionCompanyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SwitchSessionCompanyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SwitchSessionCompanyMutation",
    "id": null,
    "text": "mutation SwitchSessionCompanyMutation(\n  $input: SwitchSessionCompanyInput!\n) {\n  switchSessionCompany(input: $input) {\n    viewer {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e1767e6d40a40ed71c0d8c652239cce';
module.exports = node;

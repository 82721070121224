// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation DeleteCompanyCalendarEventMutation(
    $input: DeleteCompanyCalendarEventInput!
  ) {
    deleteCompanyCalendarEvent(input: $input) {
      company {
        id
        name
        namespace
        sundayHoursStart
        sundayHoursEnd
        mondayHoursStart
        mondayHoursEnd
        tuesdayHoursStart
        tuesdayHoursEnd
        wednesdayHoursStart
        wednesdayHoursEnd
        thursdayHoursStart
        thursdayHoursEnd
        fridayHoursStart
        fridayHoursEnd
        saturdayHoursStart
        saturdayHoursEnd
        calendarEvents {
          id
          name
          isFullDay
          isOpen
          startAt
          endAt
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

/**
 * @flow
 * @relayHash d7bf49c6740e1c21729754b278c7dc92
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteAttachmentInput = {|
  id: string
|};
export type DeleteAttachmentMutationVariables = {|
  input: DeleteAttachmentInput
|};
export type DeleteAttachmentMutationResponse = {|
  +deleteAttachment: {|
    +deletedId: string
  |}
|};
export type DeleteAttachmentMutation = {|
  variables: DeleteAttachmentMutationVariables,
  response: DeleteAttachmentMutationResponse,
|};
*/


/*
mutation DeleteAttachmentMutation(
  $input: DeleteAttachmentInput!
) {
  deleteAttachment(input: $input) {
    deletedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteAttachmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteAttachment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteAttachmentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteAttachmentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DeleteAttachmentMutation",
    "id": null,
    "text": "mutation DeleteAttachmentMutation(\n  $input: DeleteAttachmentInput!\n) {\n  deleteAttachment(input: $input) {\n    deletedId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6056a5e7868749ef4ccd7a36f57b598';
module.exports = node;

// @flow
/* eslint-disable react/no-will-update-set-state */

import * as React from 'react';
import {
  DiagramEngine,
  LinkLayerWidget,
  NodeLayerWidget,
  Toolkit,
  BaseAction,
  MoveCanvasAction,
  MoveItemsAction,
  SelectingAction,
  NodeModel,
  PointModel,
  PortModel,
  LinkModel,
  BaseWidget,
  BaseWidgetProps,
} from 'storm-react-diagrams';

export type DiagramProps = BaseWidgetProps & {
  diagramEngine: DiagramEngine,

  allowLooseLinks?: boolean,
  allowCanvasTranslation?: boolean,
  allowCanvasZoom?: boolean,
  inverseZoom?: boolean,
  maxNumberPointsPerLink?: number,
  smartRouting?: boolean,

  actionStartedFiring?: (action: BaseAction) => boolean,
  actionStillFiring?: (action: BaseAction) => void,
  actionStoppedFiring?: (action: BaseAction) => void,

  deleteKeys?: number[],
};

export type DiagramState = {
  action: BaseAction | null,
  wasMoved: boolean,
  renderedNodes: boolean,
  windowListener: any,
  diagramEngineListener: any,
  document: any,
  canvas: any,
};

// This is a modified version of DiagramWidget from storm-react-diagrams
export default class DiagramWidget extends BaseWidget<
  DiagramProps,
  DiagramState,
> {
  static defaultProps: DiagramProps = {
    diagramEngine: null,
    allowLooseLinks: true,
    allowCanvasTranslation: true,
    allowCanvasZoom: true,
    inverseZoom: false,
    maxNumberPointsPerLink: Infinity, // backwards compatible default
    smartRouting: false,
    deleteKeys: [46, 8],
  };

  onKeyUpPointer: (window: any, keyboardEvent: any) => void;

  constructor(props: DiagramProps) {
    super('srd-diagram', props);
    this.state = {
      action: null,
      wasMoved: false,
      renderedNodes: false,
      windowListener: null,
      diagramEngineListener: null,
      document: null,
      canvas: null,
    };
  }

  componentWillUnmount() {
    this.props.diagramEngine.removeListener(this.state.diagramEngineListener);
    this.props.diagramEngine.setCanvas(null);
    window.removeEventListener('keyup', this.onKeyUpPointer);
    window.removeEventListener('mouseUp', this.onMouseUp);
    window.removeEventListener('mouseMove', this.onMouseMove);
    this.state.canvas.removeEventListener('wheel', this.onWheel, {
      passive: false,
    });
  }

  componentWillReceiveProps(nextProps: DiagramProps) {
    if (this.props.diagramEngine !== nextProps.diagramEngine) {
      this.props.diagramEngine.removeListener(this.state.diagramEngineListener);
      const diagramEngineListener = nextProps.diagramEngine.addListener({
        repaintCanvas: () => this.forceUpdate(),
      });
      this.setState({ diagramEngineListener });
    }
  }

  componentWillUpdate(nextProps: DiagramProps) {
    if (
      this.props.diagramEngine.diagramModel.id !==
      nextProps.diagramEngine.diagramModel.id
    ) {
      this.setState({ renderedNodes: false });
      nextProps.diagramEngine.diagramModel.rendered = true;
    }
    if (!nextProps.diagramEngine.diagramModel.rendered) {
      this.setState({ renderedNodes: false });
      nextProps.diagramEngine.diagramModel.rendered = true;
    }
  }

  componentDidUpdate() {
    if (!this.state.renderedNodes) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        renderedNodes: true,
      });
    }
  }

  componentDidMount() {
    this.onKeyUpPointer = this.onKeyUp;

    // add a keyboard listener
    this.setState({
      document: document,
      canvas: this.props.diagramEngine.canvas,
      renderedNodes: true,
      diagramEngineListener: this.props.diagramEngine.addListener({
        repaintCanvas: () => {
          this.forceUpdate();
        },
      }),
    });

    window.addEventListener('keyup', this.onKeyUpPointer, false);
    this.props.diagramEngine.canvas.addEventListener('wheel', this.onWheel, {
      passive: false,
    });

    // dont focus the window when in test mode - jsdom fails
    if (process.env.NODE_ENV !== 'test') {
      window.focus();
    }
  }

  getMouseElement(event: SyntheticMouseEvent<any>) {
    const target = event.target;
    const diagramModel = this.props.diagramEngine.diagramModel;

    // is it a port
    let element = Toolkit.closest(target, '.port[data-name]');
    if (element) {
      const nodeElement = Toolkit.closest(target, '.node[data-nodeid]');
      return {
        model: diagramModel
          .getNode(nodeElement.getAttribute('data-nodeid'))
          .getPort(element.getAttribute('data-name')),
        element: element,
      };
    }

    // look for a point
    element = Toolkit.closest(target, '.point[data-id]');
    if (element) {
      return {
        model: diagramModel
          .getLink(element.getAttribute('data-linkid'))
          .getPointModel(element.getAttribute('data-id')),
        element: element,
      };
    }

    // look for a link
    element = Toolkit.closest(target, '[data-linkid]');
    if (element) {
      return {
        model: diagramModel.getLink(element.getAttribute('data-linkid')),
        element: element,
      };
    }

    // look for a node
    element = Toolkit.closest(target, '.node[data-nodeid]');
    if (element) {
      return {
        model: diagramModel.getNode(element.getAttribute('data-nodeid')),
        element: element,
      };
    }

    return null;
  }

  fireAction() {
    if (this.state.action && this.props.actionStillFiring) {
      this.props.actionStillFiring(this.state.action);
    }
  }

  stopFiringAction(shouldSkipEvent?: boolean) {
    if (this.props.actionStoppedFiring && !shouldSkipEvent) {
      this.props.actionStoppedFiring(this.state.action);
    }
    this.setState({ action: null });
  }

  startFiringAction(action: BaseAction) {
    let setState = true;
    if (this.props.actionStartedFiring) {
      setState = this.props.actionStartedFiring(action);
    }
    if (setState) {
      this.setState({ action: action });
    }
  }

  onMouseMove = (event: SyntheticMouseEvent<any>) => {
    const diagramEngine = this.props.diagramEngine;
    const diagramModel = diagramEngine.getDiagramModel();
    // select items so draw a bounding box
    if (this.state.action instanceof SelectingAction) {
      const relative = diagramEngine.getRelativePoint(
        event.clientX,
        event.clientY,
      );

      diagramModel.getNodes().forEach(node => {
        if (this.state.action.containsElement(node.x, node.y, diagramModel)) {
          node.setSelected(true);
        }
      });

      diagramModel.getLinks().forEach(link => {
        let allSelected = true;

        link.points.forEach(point => {
          if (
            this.state.action.containsElement(point.x, point.y, diagramModel)
          ) {
            point.setSelected(true);
          } else {
            allSelected = false;
          }
        });

        if (allSelected) {
          link.setSelected(true);
        }
      });

      this.state.action.mouseX2 = relative.x;
      this.state.action.mouseY2 = relative.y;

      this.fireAction();
      this.setState(prevState => ({ action: prevState.action }));
      return;
    } else if (this.state.action instanceof MoveItemsAction) {
      const amountX = event.clientX - this.state.action.mouseX;
      const amountY = event.clientY - this.state.action.mouseY;
      const amountZoom = diagramModel.getZoomLevel() / 100;

      this.state.action.selectionModels.forEach(model => {
        // in this case we need to also work out the relative grid position
        if (
          model.model instanceof NodeModel ||
          (model.model instanceof PointModel &&
            !model.model.isConnectedToPort())
        ) {
          model.model.x = diagramModel.getGridPosition(
            model.initialX + amountX / amountZoom,
          );
          model.model.y = diagramModel.getGridPosition(
            model.initialY + amountY / amountZoom,
          );

          if (model.model instanceof NodeModel) {
            model.model.positionChanged();

            // update port coordinates as well
            model.model.getPorts().forEach(port => {
              const portCoords = this.props.diagramEngine.getPortCoords(port);
              port.updateCoords(portCoords);
            });
          }

          if (diagramEngine.isSmartRoutingEnabled()) {
            diagramEngine.calculateRoutingMatrix();
          }
        } else if (model.model instanceof PointModel) {
          // we want points that are connected to ports, to not necessarily snap to grid
          // this stuff needs to be pixel perfect, dont touch it
          model.model.x =
            model.initialX + diagramModel.getGridPosition(amountX / amountZoom);
          model.model.y =
            model.initialY + diagramModel.getGridPosition(amountY / amountZoom);
        }
      });

      if (diagramEngine.isSmartRoutingEnabled()) {
        diagramEngine.calculateCanvasMatrix();
      }

      this.fireAction();
      if (!this.state.wasMoved) {
        this.setState({ wasMoved: true });
      } else {
        this.forceUpdate();
      }
    } else if (this.state.action instanceof MoveCanvasAction) {
      // translate the actual canvas
      if (this.props.allowCanvasTranslation) {
        diagramModel.setOffset(
          this.state.action.initialOffsetX +
            (event.clientX - this.state.action.mouseX),
          this.state.action.initialOffsetY +
            (event.clientY - this.state.action.mouseY),
        );
        this.fireAction();
        this.forceUpdate();
      }
    }
  };

  onKeyUp = (event: SyntheticKeyboardEvent<any>) => {
    // delete all selected
    if (this.props.deleteKeys.indexOf(event.keyCode) !== -1) {
      this.props.diagramEngine
        .getDiagramModel()
        .getSelectedItems()
        .forEach(element => {
          // only delete items which are not locked
          if (!this.props.diagramEngine.isModelLocked(element)) {
            element.remove();
          }
        });
      this.forceUpdate();
    }
  };

  onMouseUp = (event: SyntheticMouseEvent<any>) => {
    const diagramEngine = this.props.diagramEngine;
    // are we going to connect a link to something?
    if (this.state.action instanceof MoveItemsAction) {
      const element = this.getMouseElement(event);
      this.state.action.selectionModels.forEach(model => {
        // only care about points connecting to things
        if (!(model.model instanceof PointModel)) {
          return;
        }
        if (
          element &&
          element.model instanceof PortModel &&
          !diagramEngine.isModelLocked(element.model)
        ) {
          const link = model.model.getLink();
          if (link.getTargetPort() !== null) {
            // if this was a valid link already and we are adding a node in the middle, create 2 links from the original
            if (
              link.getTargetPort() !== element.model &&
              link.getSourcePort() !== element.model
            ) {
              const targetPort = link.getTargetPort();
              const newLink = link.clone({});
              newLink.setSourcePort(element.model);
              newLink.setTargetPort(targetPort);
              link.setTargetPort(element.model);
              targetPort.removeLink(link);
              newLink.removePointsBefore(
                newLink.getPoints()[link.getPointIndex(model.model)],
              );
              link.removePointsAfter(model.model);
              diagramEngine.getDiagramModel().addLink(newLink);
              // if we are connecting to the same target or source, remove tweener points
            } else if (link.getTargetPort() === element.model) {
              link.removePointsAfter(model.model);
            } else if (link.getSourcePort() === element.model) {
              link.removePointsBefore(model.model);
            }
          } else {
            link.setTargetPort(element.model);
          }
          delete this.props.diagramEngine.linksThatHaveInitiallyRendered[
            link.getID()
          ];
        }
      });

      // check for / remove any loose links in any models which have been moved
      if (!this.props.allowLooseLinks && this.state.wasMoved) {
        this.state.action.selectionModels.forEach(model => {
          // only care about points connecting to things
          if (!(model.model instanceof PointModel)) {
            return;
          }

          const selectedPoint: PointModel = model.model;
          const link: LinkModel = selectedPoint.getLink();
          if (link.getSourcePort() === null || link.getTargetPort() === null) {
            link.remove();
          }
        });
      }

      // remove any invalid links
      this.state.action.selectionModels.forEach(model => {
        // only care about points connecting to things
        if (!(model.model instanceof PointModel)) {
          return;
        }

        const link: LinkModel = model.model.getLink();
        const sourcePort: PortModel = link.getSourcePort();
        const targetPort: PortModel = link.getTargetPort();
        if (sourcePort !== null && targetPort !== null) {
          if (!sourcePort.canLinkToPort(targetPort)) {
            // link not allowed
            link.remove();
          } else if (
            Object.values(targetPort.getLinks()).some(
              (l: LinkModel) =>
                l !== link &&
                (l.getSourcePort() === sourcePort ||
                  l.getTargetPort() === sourcePort),
            )
          ) {
            // link is a duplicate
            link.remove();
          }
        }
      });

      diagramEngine.clearRepaintEntities();
      this.stopFiringAction(!this.state.wasMoved);
    } else {
      diagramEngine.clearRepaintEntities();
      this.stopFiringAction();
    }
    this.state.document.removeEventListener('mousemove', this.onMouseMove);
    this.state.document.removeEventListener('mouseup', this.onMouseUp);
  };

  onWheel = (event: SyntheticWheelEvent<any>) => {
    if (this.props.allowCanvasZoom) {
      const diagramEngine = this.props.diagramEngine;
      const diagramModel = diagramEngine.getDiagramModel();

      event.preventDefault();
      event.stopPropagation();
      const oldZoomFactor = diagramModel.getZoomLevel() / 100;
      let scrollDelta = this.props.inverseZoom ? -event.deltaY : event.deltaY;
      // check if it is pinch gesture
      if (event.ctrlKey && scrollDelta % 1 !== 0) {
        /* Chrome and Firefox sends wheel event with deltaY that
                have fractional part, also `ctrlKey` prop of the event is true
                though ctrl isn't pressed
              */
        scrollDelta /= 3;
      } else {
        scrollDelta /= 60;
      }
      if (diagramModel.getZoomLevel() + scrollDelta > 10) {
        diagramModel.setZoomLevel(diagramModel.getZoomLevel() + scrollDelta);
      }

      const zoomFactor = diagramModel.getZoomLevel() / 100;

      const boundingRect = event.currentTarget.getBoundingClientRect();
      const clientWidth = boundingRect.width;
      const clientHeight = boundingRect.height;
      // compute difference between rect before and after scroll
      const widthDiff = clientWidth * zoomFactor - clientWidth * oldZoomFactor;
      const heightDiff =
        clientHeight * zoomFactor - clientHeight * oldZoomFactor;
      // compute mouse coords relative to canvas
      const clientX = event.clientX - boundingRect.left;
      const clientY = event.clientY - boundingRect.top;

      // compute width and height increment factor
      const xFactor =
        (clientX - diagramModel.getOffsetX()) / oldZoomFactor / clientWidth;
      const yFactor =
        (clientY - diagramModel.getOffsetY()) / oldZoomFactor / clientHeight;

      diagramModel.setOffset(
        diagramModel.getOffsetX() - widthDiff * xFactor,
        diagramModel.getOffsetY() - heightDiff * yFactor,
      );

      diagramEngine.enableRepaintEntities([]);
      this.forceUpdate();
    }
  };

  drawSelectionBox() {
    const dimensions = this.state.action.getBoxDimensions();
    return (
      <div
        className={this.bem('__selector')}
        style={{
          top: dimensions.top,
          left: dimensions.left,
          width: dimensions.width,
          height: dimensions.height,
        }}
      />
    );
  }

  render() {
    const diagramEngine = this.props.diagramEngine;
    diagramEngine.setMaxNumberPointsPerLink(this.props.maxNumberPointsPerLink);
    diagramEngine.setSmartRoutingStatus(this.props.smartRouting);
    const diagramModel = diagramEngine.getDiagramModel();

    return (
      <div
        {...this.getProps()}
        ref={ref => {
          if (ref) {
            this.props.diagramEngine.setCanvas(ref);
          }
        }}
        onMouseDown={event => {
          if (event.nativeEvent.which === 3) return;
          this.setState(prevState => ({ ...prevState, wasMoved: false }));

          diagramEngine.clearRepaintEntities();
          const model = this.getMouseElement(event);
          // the canvas was selected
          if (model === null) {
            // is it a multiple selection
            if (event.shiftKey) {
              const relative = diagramEngine.getRelativePoint(
                event.clientX,
                event.clientY,
              );
              this.startFiringAction(
                new SelectingAction(relative.x, relative.y),
              );
            } else {
              // its a drag the canvas event
              diagramModel.clearSelection();
              this.startFiringAction(
                new MoveCanvasAction(
                  event.clientX,
                  event.clientY,
                  diagramModel,
                ),
              );
            }
          } else if (model.model instanceof PortModel) {
            // its a port element, we want to drag a link
            if (!this.props.diagramEngine.isModelLocked(model.model)) {
              const relative = diagramEngine.getRelativeMousePoint(event);
              const sourcePort = model.model;
              const link = sourcePort.createLinkModel();
              link.setSourcePort(sourcePort);

              if (link) {
                link.removeMiddlePoints();
                if (link.getSourcePort() !== sourcePort) {
                  link.setSourcePort(sourcePort);
                }
                link.setTargetPort(null);

                link.getFirstPoint().updateLocation(relative);
                link.getLastPoint().updateLocation(relative);

                diagramModel.clearSelection();
                link.getLastPoint().setSelected(true);
                diagramModel.addLink(link);

                this.startFiringAction(
                  new MoveItemsAction(
                    event.clientX,
                    event.clientY,
                    diagramEngine,
                  ),
                );
              }
            } else {
              diagramModel.clearSelection();
            }
          } else {
            // its some or other element, probably want to move it
            if (!event.shiftKey && !model.model.isSelected()) {
              diagramModel.clearSelection();
            }
            model.model.setSelected(true);

            this.startFiringAction(
              new MoveItemsAction(event.clientX, event.clientY, diagramEngine),
            );
          }
          this.state.document.addEventListener('mousemove', this.onMouseMove);
          this.state.document.addEventListener('mouseup', this.onMouseUp);
        }}
      >
        {this.state.renderedNodes && (
          <LinkLayerWidget
            diagramEngine={diagramEngine}
            pointAdded={(point: PointModel, event) => {
              this.state.document.addEventListener(
                'mousemove',
                this.onMouseMove,
              );
              this.state.document.addEventListener('mouseup', this.onMouseUp);
              event.stopPropagation();
              diagramModel.clearSelection(point);
              this.setState({
                action: new MoveItemsAction(
                  event.clientX,
                  event.clientY,
                  diagramEngine,
                ),
              });
            }}
          />
        )}
        <NodeLayerWidget diagramEngine={diagramEngine} />
        {this.state.action instanceof SelectingAction &&
          this.drawSelectionBox()}
      </div>
    );
  }
}

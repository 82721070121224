// @flow

import { LocalStorage } from 'shared/utils';
import { ActionTypes } from './Reducer';
import store from '.';

const { dispatch, getState } = store;

/**
 * Toggle the main menu.
 * @param {?boolean} showMenu
 * @return {Object}
 */
export function toggleMainMenu(showMenu?: boolean) {
  if (showMenu === undefined) {
    showMenu = !getState().mainMenuOpen;
  }

  return dispatch({
    type: ActionTypes.TOGGLE_MAIN_MENU,
    data: showMenu,
  });
}

/**
 * Queue up an alert notification
 * @param  {string} message
 * @param  {string} type
 * @return {Object}
 */
export function alertNotification(
  message: string | { title: string, body: ?string },
  type: string = 'info',
) {
  const data = {
    id: Date.now(),
    message,
    type,
  };

  setTimeout(() => {
    dispatch(removeFadingMessage(data.id));
  }, 6000);

  return dispatch({
    type: ActionTypes.QUEUE_ALERT_NOTIFICATION,
    data,
  });
}

/**
 * Expire an alert notification
 * @param {number} id
 * @return {Object}
 */
export function removeFadingMessage(id: number) {
  return dispatch({
    type: ActionTypes.REMOVE_ALERT_NOTIFICATION,
    data: id,
  });
}

export function markTutorialTooltipSeen(tooltipId: string) {
  const tutorialTooltips = LocalStorage.get('tutorialTooltips', []);
  tutorialTooltips.push(tooltipId);
  LocalStorage.set('tutorialTooltips', tutorialTooltips);

  return dispatch({
    type: ActionTypes.MARK_TUTORIAL_TOOLTIP_SEEN,
    data: tooltipId,
  });
}

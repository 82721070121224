// @flow

import React, { PureComponent } from 'react';
import request from 'superagent';
import * as Yup from 'yup';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  Toggle,
  FieldLabel,
  FieldMessage,
} from 'shared/components/form';
import Button from 'shared/components/common/Button';
import Loader from 'shared/components/common/Loader';

type Props = {
  onClose: () => void,
  onSubmit: (string[]) => Promise<void>,
  columnsApiUrl: string,
  title: string,
};

type State = {
  loadingColumns: boolean,
  exportColumns: Object,
};

const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const Cell = styled.div`
  padding: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 33.3%;

  span {
    display: block;
    padding-left: 8px;
  }
`;

class ExportCSVModal extends PureComponent<Props, State> {
  state = {
    loadingColumns: true,
    exportColumns: {},
  };

  async componentDidMount() {
    const { columnsApiUrl } = this.props;

    const { body: exportColumns } = await request.get(columnsApiUrl);

    this.setState({
      loadingColumns: false,
      exportColumns,
    });
  }

  render() {
    const { title, onClose, onSubmit } = this.props;
    const { loadingColumns, exportColumns } = this.state;

    return (
      <Modal maxWidth={700}>
        <Formik
          enableReinitialize
          isInitialValid={
            Object.keys(exportColumns).filter(
              column => exportColumns[column].isDefault,
            ).length
          }
          initialValues={{
            columns: Object.keys(exportColumns).filter(
              column => exportColumns[column].isDefault,
            ),
          }}
          validationSchema={Yup.object().shape({
            columns: Yup.array()
              .of(Yup.string())
              .min('1', 'At least one column must be selected.'),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
              await onSubmit(values.columns);

              Actions.alertNotification(
                i18n.t('CSV successfully exported'),
                'success',
              );

              onClose();
            } catch (e) {
              Actions.alertNotification(e.message, 'error');
              setSubmitting(false);
            }
          }}
          render={({
            values,
            errors,
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <ModalHeader header={title} onClose={onClose} />
              <ModalBody withPadding>
                {loadingColumns ? (
                  <Loader />
                ) : (
                  <>
                    <FieldLabel>{i18n.t('Columns to Export')}</FieldLabel>
                    <Grid>
                      {Object.keys(exportColumns).map(column => (
                        <Cell key={column}>
                          <Toggle
                            value={Boolean(
                              values.columns.find(c => c === column),
                            )}
                            onChange={checked => {
                              if (checked) {
                                setFieldValue(
                                  'columns',
                                  values.columns.concat(column),
                                );
                              } else {
                                setFieldValue(
                                  'columns',
                                  values.columns.filter(c => c !== column),
                                );
                              }
                            }}
                          />
                          <span>{exportColumns[column].label}</span>
                        </Cell>
                      ))}
                    </Grid>
                    {errors.columns && (
                      <FieldMessage type="error">{errors.columns}</FieldMessage>
                    )}
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  theme="blue"
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                >
                  {i18n.t('Export to CSV')}
                </Button>
              </ModalFooter>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default ExportCSVModal;

// @flow

import * as React from 'react';
import {
  DiagramEngine,
  PointModel,
  Toolkit,
  DefaultLinkModel,
  PathFinding,
  LabelModel,
  BaseWidget,
  BaseWidgetProps,
} from 'storm-react-diagrams';

type Props = {
  ...$Exact<BaseWidgetProps>,
  color?: string,
  width?: number,
  smooth?: boolean,
  link: DefaultLinkModel,
  diagramEngine: DiagramEngine,
  handleOpenDetailsTooltip: any => void,
  handleCloseDetailsTooltip: () => void,
  pointAdded?: (point: PointModel, event: MouseEvent) => any,
};

type State = {
  selected: boolean,
};

class CustomLinkWidget extends BaseWidget<Props, State> {
  static defaultProps = {
    color: 'black',
    width: 3,
    link: null,
    engine: null,
    smooth: false,
    diagramEngine: null,
  };

  // DOM references to the label and paths (if label is given), used to calculate dynamic positioning
  refLabels: { [id: string]: any };
  refPaths: any[];

  pathFinding: PathFinding; // only set when smart routing is active

  constructor(props: Props) {
    super('srd-default-link', props);

    this.refLabels = {};
    this.refPaths = [];
    this.state = {
      selected: false,
    };

    if (props.diagramEngine.isSmartRoutingEnabled()) {
      this.pathFinding = new PathFinding(this.props.diagramEngine);
    }
  }

  calculateAllLabelPosition() {
    this.props.link.labels.forEach((label, index) => {
      this.calculateLabelPosition(label, index + 1);
    });
  }

  componentDidUpdate() {
    if (this.props.link.labels.length > 0) {
      window.requestAnimationFrame(this.calculateAllLabelPosition.bind(this));
    }
  }

  componentDidMount() {
    if (this.props.link.labels.length > 0) {
      window.requestAnimationFrame(this.calculateAllLabelPosition.bind(this));
    }
  }

  addPointToLink = (event: MouseEvent, index: number): void => {
    if (
      !event.shiftKey &&
      !this.props.diagramEngine.isModelLocked(this.props.link) &&
      this.props.link.points.length - 1 <=
        this.props.diagramEngine.getMaxNumberPointsPerLink()
    ) {
      const point = new PointModel(
        this.props.link,
        this.props.diagramEngine.getRelativeMousePoint(event),
      );
      point.setSelected(true);
      this.forceUpdate();
      this.props.link.addPoint(point, index);
      this.props.pointAdded(point, event);
    }
  };

  generatePoint(pointIndex: number) {
    const x = this.props.link.points[pointIndex].x;
    const y = this.props.link.points[pointIndex].y;

    return (
      <g key={'point-' + this.props.link.points[pointIndex].id}>
        <circle
          cx={x}
          cy={y}
          r={5}
          className={
            'point ' +
            this.bem('__point') +
            (this.props.link.points[pointIndex].isSelected()
              ? this.bem('--point-selected')
              : '')
          }
        />
        <circle
          onMouseLeave={() => {
            this.setState({ selected: false });
          }}
          onMouseEnter={() => {
            this.setState({ selected: true });
          }}
          data-id={this.props.link.points[pointIndex].id}
          data-linkid={this.props.link.id}
          cx={x}
          cy={y}
          r={15}
          opacity={0}
          className={'point ' + this.bem('__point')}
        />
      </g>
    );
  }

  generateLabel(label: LabelModel) {
    const canvas = this.props.diagramEngine.canvas;

    return (
      <foreignObject
        key={label.id}
        className={this.bem('__label')}
        width={canvas.offsetWidth}
        height={canvas.offsetHeight}
      >
        <div ref={ref => (this.refLabels[label.id] = ref)}>
          {this.props.diagramEngine
            .getFactoryForLabel(label)
            .generateReactWidget(this.props.diagramEngine, label)}
        </div>
      </foreignObject>
    );
  }

  generateLink(path: string, extraProps: any, id: string | number) {
    const props = this.props;

    const Bottom = React.cloneElement(
      props.diagramEngine
        .getFactoryForLink(this.props.link)
        .generateLinkSegment(
          this.props.link,
          this,
          this.state.selected || this.props.link.isSelected(),
          path,
        ),
      {
        ref: ref => ref && this.refPaths.push(ref),
      },
    );

    const Top = React.cloneElement(Bottom, {
      ...extraProps,
      strokeLinecap: 'round',
      onMouseEnter: () => {
        if (
          this.props.link.data &&
          (this.props.link.data.addTags.length || 0) +
            (this.props.link.data.removeTags.length || 0) +
            ((this.props.link.data.defaultUser.value && 1) || 0) >=
            1
        ) {
          props.handleOpenDetailsTooltip(
            this.props.link.data.workflowTransitionId,
          );
        } else {
          props.handleCloseDetailsTooltip();
        }
        this.setState({ selected: true });
      },
      onMouseLeave: () => {
        props.handleCloseDetailsTooltip();
        this.setState({ selected: false });
      },
      ref: null,
      'data-linkid': this.props.link.getID(),
      strokeOpacity: this.state.selected ? 0.1 : 0,
      strokeWidth: 20,
      onContextMenu: e => {
        if (!this.props.diagramEngine.isModelLocked(this.props.link)) {
          e.preventDefault();
          this.props.link.remove();
        }
      },
    });

    return (
      <g key={'link-' + id}>
        {Bottom}
        {Top}
      </g>
    );
  }

  findPathAndRelativePositionToRenderLabel = (index: number) => {
    // an array to hold all path lengths, making sure we hit the DOM only once to fetch this information
    const lengths = this.refPaths.map(path => path.getTotalLength());

    // calculate the point where we want to display the label
    let labelPosition =
      lengths.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
      ) *
      (index / (this.props.link.labels.length + 1));

    // find the path where the label will be rendered and calculate the relative position
    let pathIndex = 0;
    while (pathIndex < this.refPaths.length) {
      if (labelPosition - lengths[pathIndex] < 0) {
        return {
          path: this.refPaths[pathIndex],
          position: labelPosition,
        };
      }

      // keep searching
      labelPosition -= lengths[pathIndex];
      pathIndex++;
    }

    return {
      path: this.refPaths[pathIndex],
      position: labelPosition,
    };
  };

  calculateLabelPosition = (label: any, index: number) => {
    if (!this.refLabels[label.id]) {
      // no label? nothing to do here
      return;
    }

    const { path, position } = this.findPathAndRelativePositionToRenderLabel(
      index,
    );

    const labelDimensions = {
      width: this.refLabels[label.id].offsetWidth,
      height: this.refLabels[label.id].offsetHeight,
    };

    const pathCentre = path.getPointAtLength(position);

    const labelCoordinates = {
      x: pathCentre.x - labelDimensions.width / 2 + label.offsetX,
      y: pathCentre.y - labelDimensions.height / 2 + label.offsetY,
    };
    this.refLabels[label.id].setAttribute(
      'style',
      `transform: translate(${labelCoordinates.x}px, ${labelCoordinates.y}px);`,
    );
  };

  isSmartRoutingApplicable() {
    const { diagramEngine, link } = this.props;

    if (!diagramEngine.isSmartRoutingEnabled()) {
      return false;
    }

    if (link.points.length !== 2) {
      return false;
    }

    if (link.sourcePort === null || link.targetPort === null) {
      return false;
    }

    return true;
  }

  render() {
    const { diagramEngine } = this.props;
    if (!diagramEngine.nodesRendered) {
      return null;
    }

    // ensure id is present for all points on the path
    const points = this.props.link.points;
    const paths = [];

    if (this.isSmartRoutingApplicable()) {
      // first step: calculate a direct path between the points being linked
      const directPathCoords = this.pathFinding.calculateDirectPath(
        points[0],
        points[points.length - 1],
      );

      const routingMatrix = diagramEngine.getRoutingMatrix();
      // now we need to extract, from the routing matrix, the very first walkable points
      // so they can be used as origin and destination of the link to be created
      const smartLink = this.pathFinding.calculateLinkStartEndCoords(
        routingMatrix,
        directPathCoords,
      );

      if (smartLink) {
        const { start, end, pathToStart, pathToEnd } = smartLink;

        // second step: calculate a path avoiding hitting other elements
        const simplifiedPath = this.pathFinding.calculateDynamicPath(
          routingMatrix,
          start,
          end,
          pathToStart,
          pathToEnd,
        );

        paths.push(
          // smooth: boolean, extraProps: any, id: string | number, firstPoint: PointModel, lastPoint: PointModel
          this.generateLink(
            Toolkit.generateDynamicPath(simplifiedPath),
            {
              onMouseDown: event => {
                this.addPointToLink(event, 1);
              },
            },
            '0',
          ),
        );
      }
    }

    // true when smart routing was skipped or not enabled.
    // See @link{#isSmartRoutingApplicable()}.
    if (paths.length === 0) {
      if (points.length === 2) {
        const isHorizontal =
          Math.abs(points[0].x - points[1].x) >
          Math.abs(points[0].y - points[1].y);
        const xOrY = isHorizontal ? 'x' : 'y';

        let pointLeft = points[0];
        let pointRight = points[1];

        // some defensive programming to make sure the smoothing is
        // always in the right direction
        if (pointLeft[xOrY] > pointRight[xOrY]) {
          pointLeft = points[1];
          pointRight = points[0];
        }

        paths.push(
          this.generateLink(
            Toolkit.generateCurvePath(
              pointLeft,
              pointRight,
              this.props.link.curvyness,
            ),
            {
              onMouseDown: event => {
                this.addPointToLink(event, 1);
              },
            },
            '0',
          ),
        );

        // draw the link as dangeling
        if (this.props.link.targetPort === null) {
          paths.push(this.generatePoint(1));
        }
      } else {
        // draw the multiple anchors and complex line instead
        for (let j = 0; j < points.length - 1; j++) {
          paths.push(
            this.generateLink(
              Toolkit.generateLinePath(points[j], points[j + 1]),
              {
                'data-linkid': this.props.link.id,
                'data-point': j,
                onMouseDown: (event: MouseEvent) => {
                  this.addPointToLink(event, j + 1);
                },
              },
              j,
            ),
          );
        }

        // render the circles
        for (let i = 1; i < points.length - 1; i++) {
          paths.push(this.generatePoint(i));
        }

        if (this.props.link.targetPort === null) {
          paths.push(this.generatePoint(points.length - 1));
        }
      }
    }

    this.refPaths = [];

    return (
      <g {...this.getProps()} data-for={this.props.link.id} data-tip>
        {paths}
        {this.props.link.labels.map(labelModel => {
          return this.generateLabel(labelModel);
        })}
      </g>
    );
  }
}

export default CustomLinkWidget;

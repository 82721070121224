/**
 * @flow
 * @relayHash 369b9dab548284873def0eb8411bf26c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type IntegrationFormQueryVariables = {|
  integrationId: string
|};
export type IntegrationFormQueryResponse = {|
  +oauthRedirectUri: {|
    +uri: string
  |}
|};
export type IntegrationFormQuery = {|
  variables: IntegrationFormQueryVariables,
  response: IntegrationFormQueryResponse,
|};
*/


/*
query IntegrationFormQuery(
  $integrationId: ID!
) {
  oauthRedirectUri(integrationId: $integrationId) {
    uri
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "integrationId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "oauthRedirectUri",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "integrationId",
        "variableName": "integrationId"
      }
    ],
    "concreteType": "OauthRedirectUri",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "uri",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "IntegrationFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "IntegrationFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "IntegrationFormQuery",
    "id": null,
    "text": "query IntegrationFormQuery(\n  $integrationId: ID!\n) {\n  oauthRedirectUri(integrationId: $integrationId) {\n    uri\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a91dd9fbde64ca1f0ba430f9e638ff0e';
module.exports = node;

// @flow

import React from 'react';

type Props = {
  model: any,
  children: *,
};

const TrayItemWidget = ({ model, children }: Props) => {
  return (
    <div
      draggable
      onDragStart={event => {
        event.dataTransfer.setData('storm-diagram-node', JSON.stringify(model));
      }}
      className="tray-item"
    >
      {children}
    </div>
  );
};

export default TrayItemWidget;

// @flow

import { i18n } from 'shared/utils';

export default () => [
  {
    label: i18n.t('Order # · Low to High'),
    value: { field: 'ORDER_NUMBER', direction: 'ASC' },
  },
  {
    label: i18n.t('Order # · High to Low'),
    value: { field: 'ORDER_NUMBER', direction: 'DESC' },
  },
  {
    label: i18n.t('Customer PO # · Low to High'),
    value: { field: 'CUSTOMER_ORDER_NUMBER', direction: 'ASC' },
  },
  {
    label: i18n.t('Customer PO # · High to Low'),
    value: { field: 'CUSTOMER_ORDER_NUMBER', direction: 'DESC' },
  },
  {
    label: i18n.t('Customer · A to Z'),
    value: { field: 'CUSTOMER', direction: 'ASC' },
  },
  {
    label: i18n.t('Customer · Z to A'),
    value: { field: 'CUSTOMER', direction: 'DESC' },
  },
  {
    label: i18n.t('Status · A to Z'),
    value: { field: 'STATUS', direction: 'ASC' },
  },
  {
    label: i18n.t('Status · Z to A'),
    value: { field: 'STATUS', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Ordered - Oldest'),
    value: { field: 'ORDERED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Ordered - Most Recent'),
    value: { field: 'ORDERED_AT', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Required - Oldest'),
    value: { field: 'REQUIRED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Required - Most Recent'),
    value: { field: 'REQUIRED_AT', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Promised - Oldest'),
    value: { field: 'PROMISED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Promised - Most Recent'),
    value: { field: 'PROMISED_AT', direction: 'DESC' },
  },
  {
    label: i18n.t('Last Updated - Oldest'),
    value: { field: 'UPDATED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Last Updated - Most Recent'),
    value: { field: 'UPDATED_AT', direction: 'DESC' },
  },
  {
    label: i18n.t('Date Created - Oldest'),
    value: { field: 'CREATED_AT', direction: 'ASC' },
  },
  {
    label: i18n.t('Date Created - Most Recent'),
    value: { field: 'CREATED_AT', direction: 'DESC' },
  },
];

/**
 * @flow
 * @relayHash 43dd0500963edcb7f7cefaf505ce72b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSavedFilterType = "CUSTOMER" | "ITEM" | "JOB" | "LOT" | "ORDER";
export type FilterControlsUserSavedFiltersQueryVariables = {|
  type?: ?UserSavedFilterType,
  skipSavedFilters: boolean,
|};
export type FilterControlsUserSavedFiltersQueryResponse = {|
  +userSavedFilters?: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: {|
        +id: string,
        +name: string,
        +type: UserSavedFilterType,
        +filter: string,
      |}
    |}>
  |}
|};
export type FilterControlsUserSavedFiltersQuery = {|
  variables: FilterControlsUserSavedFiltersQueryVariables,
  response: FilterControlsUserSavedFiltersQueryResponse,
|};
*/


/*
query FilterControlsUserSavedFiltersQuery(
  $type: UserSavedFilterType
  $skipSavedFilters: Boolean!
) {
  userSavedFilters(type: $type) @skip(if: $skipSavedFilters) {
    edges {
      node {
        id
        name
        type
        filter
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "UserSavedFilterType",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skipSavedFilters",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "UserSavedFilterEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "UserSavedFilter",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "filter",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FilterControlsUserSavedFiltersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipSavedFilters",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "userSavedFilters",
            "name": "__FilterControls_userSavedFilters_connection",
            "storageKey": null,
            "args": null,
            "concreteType": "UserSavedFilterConnection",
            "plural": false,
            "selections": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FilterControlsUserSavedFiltersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "Condition",
        "passingValue": false,
        "condition": "skipSavedFilters",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userSavedFilters",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "UserSavedFilterConnection",
            "plural": false,
            "selections": (v1/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "userSavedFilters",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "FilterControls_userSavedFilters",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FilterControlsUserSavedFiltersQuery",
    "id": null,
    "text": "query FilterControlsUserSavedFiltersQuery(\n  $type: UserSavedFilterType\n  $skipSavedFilters: Boolean!\n) {\n  userSavedFilters(type: $type) @skip(if: $skipSavedFilters) {\n    edges {\n      node {\n        id\n        name\n        type\n        filter\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "userSavedFilters"
          ]
        }
      ]
    }
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b70ecaca9179d573e2d42fe95c53825f';
module.exports = node;

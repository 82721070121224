/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type IntegrationOriginRule = "SOURCE_TO_WC" | "TWO_WAY" | "WC_TO_SOURCE";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateIntegrationEventModal_integrationEvent$ref: FragmentReference;
declare export opaque type AddUpdateIntegrationEventModal_integrationEvent$fragmentType: AddUpdateIntegrationEventModal_integrationEvent$ref;
export type AddUpdateIntegrationEventModal_integrationEvent = {|
  +id: string,
  +originRule: IntegrationOriginRule,
  +lambdaOnly: boolean,
  +lambdaId: ?string,
  +eventType: {|
    +id: string,
    +name: string,
  |},
  +$refType: AddUpdateIntegrationEventModal_integrationEvent$ref,
|};
export type AddUpdateIntegrationEventModal_integrationEvent$data = AddUpdateIntegrationEventModal_integrationEvent;
export type AddUpdateIntegrationEventModal_integrationEvent$key = {
  +$data?: AddUpdateIntegrationEventModal_integrationEvent$data,
  +$fragmentRefs: AddUpdateIntegrationEventModal_integrationEvent$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AddUpdateIntegrationEventModal_integrationEvent",
  "type": "IntegrationEvent",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "originRule",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lambdaOnly",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lambdaId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "eventType",
      "storageKey": null,
      "args": null,
      "concreteType": "EventType",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3189a0d9729d99df3fd3da36b124496e';
module.exports = node;

// @flow

import React from 'react';
import * as Yup from 'yup';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n, Analytics } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import AddFormMutation from 'main-app/mutations/AddForm';
import UpdateFormMutation from 'main-app/mutations/UpdateForm';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import { Formik, Form, FieldGroup } from 'shared/components/form';
import type { AddUpdateFormModal_form as FormFragment } from './__generated__/AddUpdateFormModal_form';

type Props = {
  onClose: () => void,
  form: ?FormFragment,
};

const AddUpdateFormModal = ({ form, onClose }: Props) => (
  <Modal maxWidth={700}>
    <Formik
      initialValues={{
        name: form?.name || '',
        description: form?.description || '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
      })}
      onSubmit={async (values: *, { setSubmitting }: *) => {
        setSubmitting(true);

        const mutation = form ? UpdateFormMutation : AddFormMutation;

        const input: any = {
          name: values.name,
          description: values.description,
        };

        if (form) {
          input.id = form.id;
        }

        try {
          await mutation.commit({
            variables: {
              input,
            },
          });

          onClose();

          Analytics.trackEvent(`${form ? 'Update' : 'Create'} Form`, {
            formName: input.name,
            description: input.description,
          });

          Actions.alertNotification(
            i18n.t(`Form Successfully ${form ? 'Updated' : 'Added'}`),
            'success',
          );
        } catch (e) {
          setSubmitting(false);
          Actions.alertNotification(e.message, 'error');
        }
      }}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <ModalHeader
            header={i18n.t(form ? 'Edit Form' : 'Create Form')}
            onClose={onClose}
          />
          <ModalBody withPadding>
            <FieldGroup
              name="name"
              label={i18n.t('Form Name')}
              placeholder={i18n.t('Form Name')}
              error={errors.name}
            />
            <FieldGroup
              name="description"
              label={i18n.t('Description')}
              placeholder={i18n.t('Type here...')}
              error={errors.description}
              type="textarea"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              theme="blue"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              {i18n.t(form ? 'Update Form' : 'Create Form')}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  </Modal>
);

export default createFragmentContainer(AddUpdateFormModal, {
  form: graphql`
    fragment AddUpdateFormModal_form on Form {
      id
      name
      description
    }
  `,
});

// @flow

import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import type { Match } from 'react-router';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import MachineBoard from './MachineBoard';

type Props = {
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

export default ({ match }: Props) => (
  <QueryRenderer
    environment={relayEnvironment}
    dataFrom="STORE_THEN_NETWORK"
    variables={{
      id: match.params.id,
    }}
    query={graphql`
      query MachinesQuery {
        machines(
          first: null
          sortBy: { field: PRODUCTION_ORDER, direction: ASC }
        ) @connection(key: "Machines_machines", filters: []) {
          edges {
            ...MachineBoard_machineEdges
          }
        }
      }
    `}
    render={({ props }: *) => {
      if (!props) {
        return <Loader />;
      }

      return (
        <>
          <MachineBoard machineEdges={props.machines.edges} />
        </>
      );
    }}
  />
);

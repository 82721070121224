/**
 * @flow
 * @relayHash e63928f5447ec1c4c36b7466f839314a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PaginatedOrdersContainer_orders$ref = any;
export type OrderSortField = "CREATED_AT" | "CUSTOMER" | "CUSTOMER_ORDER_NUMBER" | "ORDERED_AT" | "ORDER_NUMBER" | "PROMISED_AT" | "REQUIRED_AT" | "STATUS" | "UPDATED_AT";
export type OrderStatus = "CLOSED" | "OPEN";
export type SortDirection = "ASC" | "DESC";
export type OrderSort = {|
  field: OrderSortField,
  direction: SortDirection,
|};
export type PaginatedOrdersContainerQueryVariables = {|
  count: number,
  cursor?: ?string,
  search?: ?string,
  sortBy?: ?OrderSort,
  orderNumbers?: ?$ReadOnlyArray<string>,
  customerOrderNumbers?: ?$ReadOnlyArray<string>,
  customerIds?: ?$ReadOnlyArray<string>,
  statuses?: ?$ReadOnlyArray<OrderStatus>,
  promisedAtStart?: ?any,
  promisedAtEnd?: ?any,
  orderedAtStart?: ?any,
  orderedAtEnd?: ?any,
  requiredAtStart?: ?any,
  requiredAtEnd?: ?any,
|};
export type PaginatedOrdersContainerQueryResponse = {|
  +$fragmentRefs: PaginatedOrdersContainer_orders$ref
|};
export type PaginatedOrdersContainerQuery = {|
  variables: PaginatedOrdersContainerQueryVariables,
  response: PaginatedOrdersContainerQueryResponse,
|};
*/


/*
query PaginatedOrdersContainerQuery(
  $count: Int!
  $cursor: String
  $search: String
  $sortBy: OrderSort
  $orderNumbers: [String!]
  $customerOrderNumbers: [String!]
  $customerIds: [ID!]
  $statuses: [OrderStatus!]
  $promisedAtStart: Date
  $promisedAtEnd: Date
  $orderedAtStart: Date
  $orderedAtEnd: Date
  $requiredAtStart: Date
  $requiredAtEnd: Date
) {
  ...PaginatedOrdersContainer_orders_4b0TWL
}

fragment PaginatedOrdersContainer_orders_4b0TWL on Query {
  orders(first: $count, after: $cursor, search: $search, sortBy: $sortBy, orderNumbers: $orderNumbers, customerOrderNumbers: $customerOrderNumbers, customerIds: $customerIds, statuses: $statuses, promisedAtStart: $promisedAtStart, promisedAtEnd: $promisedAtEnd, orderedAtStart: $orderedAtStart, orderedAtEnd: $orderedAtEnd, requiredAtStart: $requiredAtStart, requiredAtEnd: $requiredAtEnd) {
    edges {
      cursor
      ...OrdersTable_orderEdges
      node {
        __typename
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment OrdersTable_orderEdges on OrderEdge {
  node {
    id
    orderNumber
    customerOrderNumber
    status
    orderedAt
    requiredAt
    promisedAt
    totalJobs
    customer {
      id
      name
    }
    ...AddUpdateOrderModal_order
    ...DeleteOrderModal_order
  }
}

fragment AddUpdateOrderModal_order on Order {
  id
  customerOrderNumber
  shippingAddressLine1
  shippingAddressLine2
  shippingAddressLine3
  shippingAddressCity
  shippingAddressState
  shippingAddressZip
  notes
  orderedAt
  requiredAt
  promisedAt
  customer {
    id
    name
  }
  user {
    id
    firstName
    lastName
  }
}

fragment DeleteOrderModal_order on Order {
  id
  orderNumber
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "OrderSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderNumbers",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customerOrderNumbers",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customerIds",
    "type": "[ID!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "statuses",
    "type": "[OrderStatus!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "promisedAtStart",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "promisedAtEnd",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderedAtStart",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderedAtEnd",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "requiredAtStart",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "requiredAtEnd",
    "type": "Date",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "customerIds",
  "variableName": "customerIds"
},
v2 = {
  "kind": "Variable",
  "name": "customerOrderNumbers",
  "variableName": "customerOrderNumbers"
},
v3 = {
  "kind": "Variable",
  "name": "orderNumbers",
  "variableName": "orderNumbers"
},
v4 = {
  "kind": "Variable",
  "name": "orderedAtEnd",
  "variableName": "orderedAtEnd"
},
v5 = {
  "kind": "Variable",
  "name": "orderedAtStart",
  "variableName": "orderedAtStart"
},
v6 = {
  "kind": "Variable",
  "name": "promisedAtEnd",
  "variableName": "promisedAtEnd"
},
v7 = {
  "kind": "Variable",
  "name": "promisedAtStart",
  "variableName": "promisedAtStart"
},
v8 = {
  "kind": "Variable",
  "name": "requiredAtEnd",
  "variableName": "requiredAtEnd"
},
v9 = {
  "kind": "Variable",
  "name": "requiredAtStart",
  "variableName": "requiredAtStart"
},
v10 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v11 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v12 = {
  "kind": "Variable",
  "name": "statuses",
  "variableName": "statuses"
},
v13 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PaginatedOrdersContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "PaginatedOrdersContainer_orders",
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PaginatedOrdersContainerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "orders",
        "storageKey": null,
        "args": (v13/*: any*/),
        "concreteType": "OrderConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "OrderEdge",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Order",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "customerOrderNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "requiredAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "promisedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "totalJobs",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "customer",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Customer",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine1",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine2",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine3",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressCity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressState",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressZip",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "orders",
        "args": (v13/*: any*/),
        "handle": "connection",
        "key": "PaginatedOrdersContainer_orders",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PaginatedOrdersContainerQuery",
    "id": null,
    "text": "query PaginatedOrdersContainerQuery(\n  $count: Int!\n  $cursor: String\n  $search: String\n  $sortBy: OrderSort\n  $orderNumbers: [String!]\n  $customerOrderNumbers: [String!]\n  $customerIds: [ID!]\n  $statuses: [OrderStatus!]\n  $promisedAtStart: Date\n  $promisedAtEnd: Date\n  $orderedAtStart: Date\n  $orderedAtEnd: Date\n  $requiredAtStart: Date\n  $requiredAtEnd: Date\n) {\n  ...PaginatedOrdersContainer_orders_4b0TWL\n}\n\nfragment PaginatedOrdersContainer_orders_4b0TWL on Query {\n  orders(first: $count, after: $cursor, search: $search, sortBy: $sortBy, orderNumbers: $orderNumbers, customerOrderNumbers: $customerOrderNumbers, customerIds: $customerIds, statuses: $statuses, promisedAtStart: $promisedAtStart, promisedAtEnd: $promisedAtEnd, orderedAtStart: $orderedAtStart, orderedAtEnd: $orderedAtEnd, requiredAtStart: $requiredAtStart, requiredAtEnd: $requiredAtEnd) {\n    edges {\n      cursor\n      ...OrdersTable_orderEdges\n      node {\n        __typename\n        id\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment OrdersTable_orderEdges on OrderEdge {\n  node {\n    id\n    orderNumber\n    customerOrderNumber\n    status\n    orderedAt\n    requiredAt\n    promisedAt\n    totalJobs\n    customer {\n      id\n      name\n    }\n    ...AddUpdateOrderModal_order\n    ...DeleteOrderModal_order\n  }\n}\n\nfragment AddUpdateOrderModal_order on Order {\n  id\n  customerOrderNumber\n  shippingAddressLine1\n  shippingAddressLine2\n  shippingAddressLine3\n  shippingAddressCity\n  shippingAddressState\n  shippingAddressZip\n  notes\n  orderedAt\n  requiredAt\n  promisedAt\n  customer {\n    id\n    name\n  }\n  user {\n    id\n    firstName\n    lastName\n  }\n}\n\nfragment DeleteOrderModal_order on Order {\n  id\n  orderNumber\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57ac27cd44594b9a42e7f2e27b14bf2e';
module.exports = node;

/**
 * @flow
 * @relayHash ef9458d83e2a68fbe40d730f18622a73
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WorkflowTransitionActionType = "ADD_JOB_TAG" | "ADD_NOTE" | "REMOVE_JOB_TAG" | "SEND_API_REQUEST" | "SEND_EMAIL";
export type TransitionDetailsQueryVariables = {|
  id: string
|};
export type TransitionDetailsQueryResponse = {|
  +transition: {|
    +id: string,
    +actions: $ReadOnlyArray<{|
      +id: string,
      +type: WorkflowTransitionActionType,
      +metadata: any,
      +metadataRecord: ?any,
    |}>,
  |}
|};
export type TransitionDetailsQuery = {|
  variables: TransitionDetailsQueryVariables,
  response: TransitionDetailsQueryResponse,
|};
*/


/*
query TransitionDetailsQuery(
  $id: ID!
) {
  transition(id: $id) {
    id
    actions {
      id
      type
      metadata
      metadataRecord
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "transition",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "WorkflowTransition",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "actions",
        "storageKey": null,
        "args": null,
        "concreteType": "WorkflowTransitionAction",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "metadata",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "metadataRecord",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TransitionDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TransitionDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "TransitionDetailsQuery",
    "id": null,
    "text": "query TransitionDetailsQuery(\n  $id: ID!\n) {\n  transition(id: $id) {\n    id\n    actions {\n      id\n      type\n      metadata\n      metadataRecord\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '97a20cf633718a9e04ab2004c96a0070';
module.exports = node;

/**
 * @flow
 * @relayHash 4b284d8a95e161f719d6ad8a6abd5a2c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSavedFilterType = "CUSTOMER" | "ITEM" | "JOB" | "LOT" | "ORDER";
export type AddUserSavedFilterInput = {|
  name: string,
  type: UserSavedFilterType,
  filter: string,
|};
export type AddUserSavedFilterMutationVariables = {|
  input: AddUserSavedFilterInput
|};
export type AddUserSavedFilterMutationResponse = {|
  +addUserSavedFilter: {|
    +userSavedFilterEdge: {|
      +node: {|
        +id: string,
        +name: string,
        +type: UserSavedFilterType,
        +filter: string,
      |}
    |}
  |}
|};
export type AddUserSavedFilterMutation = {|
  variables: AddUserSavedFilterMutationVariables,
  response: AddUserSavedFilterMutationResponse,
|};
*/


/*
mutation AddUserSavedFilterMutation(
  $input: AddUserSavedFilterInput!
) {
  addUserSavedFilter(input: $input) {
    userSavedFilterEdge {
      node {
        id
        name
        type
        filter
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddUserSavedFilterInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addUserSavedFilter",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddUserSavedFilterPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userSavedFilterEdge",
        "storageKey": null,
        "args": null,
        "concreteType": "UserSavedFilterEdge",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "UserSavedFilter",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "type",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "filter",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddUserSavedFilterMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddUserSavedFilterMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddUserSavedFilterMutation",
    "id": null,
    "text": "mutation AddUserSavedFilterMutation(\n  $input: AddUserSavedFilterInput!\n) {\n  addUserSavedFilter(input: $input) {\n    userSavedFilterEdge {\n      node {\n        id\n        name\n        type\n        filter\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1132cb16f3e24b68cc6599004068b64d';
module.exports = node;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateLotModal_lot$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type LotsTable_lotEdges$ref: FragmentReference;
declare export opaque type LotsTable_lotEdges$fragmentType: LotsTable_lotEdges$ref;
export type LotsTable_lotEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +lotNumber: string,
    +binNumber: ?string,
    +quantity: number,
    +expiresAt: ?any,
    +$fragmentRefs: AddUpdateLotModal_lot$ref,
  |},
  +$refType: LotsTable_lotEdges$ref,
|}>;
export type LotsTable_lotEdges$data = LotsTable_lotEdges;
export type LotsTable_lotEdges$key = {
  +$data?: LotsTable_lotEdges$data,
  +$fragmentRefs: LotsTable_lotEdges$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "LotsTable_lotEdges",
  "type": "LotEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Lot",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lotNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "binNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "expiresAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateLotModal_lot",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b2b0bfe2c1bead927efd7734501d5980';
module.exports = node;

// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

const mutation = graphql`
  mutation ScheduleJobMutation($input: ScheduleJobInput!) {
    scheduleJob(input: $input) {
      runEdges {
        cursor
        node {
          id
          status
          scheduledStartAt
          scheduledEndAt
          notes
          jobState {
            id
            workflowState {
              id
              name
            }
          }
          machine {
            id
            name
            type {
              id
              name
            }
          }
          yields(first: null) {
            edges {
              node {
                id
                quantity
                item {
                  id
                  name
                }
              }
            }
          }
          job {
            id
            importJobNumber
            jobNumber
            name
            runs(first: null) @connection(key: "Runs_job_runs", filters: []) {
              edges {
                ...RunPanels_runEdges
              }
            }
            states {
              id
              runs {
                id
              }
            }
            ...AddUpdateRunModal_job
          }
        }
      }
    }
  }
`;

export default {
  commit: createRelayMutation.bind(null, mutation),
};

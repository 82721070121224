// @flow

import React, { PureComponent } from 'react';
// import debounce from 'lodash.debounce';
import { graphql, QueryRenderer } from 'react-relay';
import type { RouterHistory } from 'react-router';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Page from 'main-app/components/Page';
import PageHeader from 'main-app/components/PageHeader';
import Table from 'shared/components/common/Table';

type Props = {
  history: RouterHistory,
};

// TODO: reimplement search later when logic is fixed
type State = {
  // search: string,
  graphqlSearchValue: string,
};

class Reports extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      // search: '',
      graphqlSearchValue: '',
    };

    // this.handleDebouncedSearch = debounce(this.handleDebouncedSearch, 500);
  }

  compileQueryVariables = () => {
    const { graphqlSearchValue } = this.state;

    const queryVariables = {
      search: graphqlSearchValue.trim() || null,
    };

    return queryVariables;
  };

  // handleSearchInput = (search: string) => {
  //   this.setState(
  //     {
  //       search,
  //     },
  //     this.handleDebouncedSearch,
  //   );
  // };

  // handleDebouncedSearch = () => {
  //   const { search } = this.state;

  //   // Updating graphqlSearchValue will auto-kick-off a graphql query re-fetch
  //   this.setState({
  //     graphqlSearchValue: search,
  //   });

  //   Analytics.trackEvent('Search Applied', {
  //     listName: 'Reports',
  //     searchString: search,
  //   });
  // };

  render() {
    const { history } = this.props;
    // TODO: reimplement search state when search logic is fixed
    // const { search } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        cacheConfig={{
          force: true,
        }}
        query={graphql`
          query ReportsQuery {
            reports {
              id
              name
              description
            }
          }
        `}
        variables={this.compileQueryVariables()}
        render={({ props }) => (
          <Page>
            <PageHeader
              title={i18n.t('Reports')}
              // TODO: reimplement PageHeaderList when search logic is fixed
              // For PageHeaderList
              // searchPlaceholder={i18n.t('Report Name')}
              // searchValue={search}
              // onSearchChange={this.handleSearchInput}
            />
            {!props ? (
              <Loader />
            ) : (
              <Table
                data={props.reports}
                onRowClick={rowData => history.push(`/report/${rowData.id}`)}
                columns={[
                  {
                    Header: i18n.t('Report Name'),
                    accessor: 'name',
                    width: 300,
                  },
                  {
                    Header: i18n.t('Description'),
                    accessor: 'description',
                  },
                ]}
              />
            )}
          </Page>
        )}
      />
    );
  }
}

export default Reports;

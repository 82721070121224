// @flow

import React from 'react';
import * as Yup from 'yup';
import { i18n, Analytics } from 'shared/utils';
import { createFragmentContainer, graphql } from 'react-relay';
import * as Actions from 'main-app/store/Actions';
import AddUserMutation from 'main-app/mutations/AddUser';
import UpdateUserMutation from 'main-app/mutations/UpdateUser';
import Button from 'shared/components/common/Button';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'shared/components/modal';
import {
  Formik,
  Form,
  FieldGroup,
  FieldGroupRow,
} from 'shared/components/form';
import type { AddUpdateUserModal_user as UserFragment } from './__generated__/AddUpdateUserModal_user';

type Props = {
  onClose: () => void,
  onSuccess?: Object => void,
  user: ?UserFragment,
};

const LANGUAGE = {
  update: {
    title: i18n.t('Edit User'),
    submitButton: i18n.t('Update User'),
    successMessage: i18n.t('User Successfully Updated'),
    passwordMessage: i18n.t('Update Password'),
  },
  add: {
    title: i18n.t('Create User'),
    submitButton: i18n.t('Create User'),
    successMessage: i18n.t('User Successfully Created'),
    passwordMessage: i18n.t('Password'),
  },
};

const AddUpdateUserModal = ({ onClose, onSuccess, user }: Props) => {
  const language = LANGUAGE[user ? 'update' : 'add'];

  return (
    <Modal maxWidth={700}>
      <Formik
        initialValues={{
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          email: user?.email || '',
          role: {
            value: user?.role ? user?.role : 'ADMIN',
          },
          password: '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .trim()
            .required('Required'),
          lastName: Yup.string()
            .trim()
            .required('Required'),
          role: Yup.object().required('Required'),
          password: Yup.string().when('role', {
            is: ({ value }) => {
              if (user && (user.role === 'ADMIN' || user.role === 'USER')) {
                return true;
              } else {
                return value === 'WORKER';
              }
            },
            then: Yup.string()
              .nullable()
              .min(8, 'Password should be 8 chars minimum.'),
            otherwise: Yup.string()
              .required('Required')
              .min(8, 'Password should be 8 chars minimum.'),
          }),
          email: Yup.string()
            .email('Invalid Email')
            .required('Required'),
        })}
        onSubmit={async (values: *, { setSubmitting }: *) => {
          setSubmitting(true);

          const mutation = user ? UpdateUserMutation : AddUserMutation;

          const input: any = {
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role.value,
            password: values.password || '',
            email: values.email,
          };

          if (user) {
            input.id = user.id;
          }

          try {
            const response = await mutation.commit({
              variables: {
                input,
              },
            });

            const newUser = (response.updateUser || response.addUser).userEdge
              .node;

            onClose();

            if (onSuccess) {
              onSuccess(newUser);
            }

            Analytics.trackEvent(`${user ? 'Update' : 'Create'} User`, {
              firstName: values.firstName,
              lastName: values.lastName,
              role: values.role.value,
              email: values.email,
            });

            Actions.alertNotification(language.successMessage, 'success');
          } catch (e) {
            setSubmitting(false);
            Actions.alertNotification(e.message, 'error');
          }
        }}
        render={({
          values,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <ModalHeader header={language.title} onClose={onClose} />
            <ModalBody withPadding>
              <FieldGroupRow
                left={
                  <FieldGroup
                    name="firstName"
                    label={i18n.t('First Name')}
                    placeholder={i18n.t('First Name')}
                    error={errors.firstName}
                  />
                }
                right={
                  <FieldGroup
                    name="lastName"
                    label={i18n.t('Last Name')}
                    placeholder={i18n.t('Last Name')}
                    error={errors.lastName}
                  />
                }
              />
              <FieldGroup
                name="password"
                type="password"
                label={language.passwordMessage}
                placeholder={language.passwordMessage}
                error={errors.password}
              />
              <FieldGroup
                name="email"
                label={i18n.t('Email')}
                placeholder={i18n.t('Email')}
                error={errors.email}
              />
              <FieldGroup
                type="select"
                name="role"
                label={i18n.t('Role')}
                placeholder={i18n.t('Role')}
                options={[
                  {
                    label: 'ADMIN',
                    value: 'ADMIN',
                  },
                  {
                    label: 'USER',
                    value: 'USER',
                  },
                  {
                    label: 'OPERATOR',
                    value: 'WORKER',
                  },
                ]}
                defaultOptions
                error={errors.role}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                theme="blue"
                disabled={!isValid}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                {language.submitButton}
              </Button>
            </ModalFooter>
          </Form>
        )}
      />
    </Modal>
  );
};

AddUpdateUserModal.defaultProps = {
  onSuccess: undefined,
};

export default createFragmentContainer(AddUpdateUserModal, {
  user: graphql`
    fragment AddUpdateUserModal_user on User {
      id
      firstName
      lastName
      role
      email
      userNumber
    }
  `,
});

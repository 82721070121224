// @flow

import React, { useEffect, useRef } from 'react';
import { withRouter, type Match, type RouterHistory } from 'react-router';
import { connect } from 'react-redux';
import config from 'main-app/config';
import { i18n } from 'shared/utils';
import * as Actions from 'main-app/store/Actions';
import Icon from 'shared/components/common/Icon';
import {
  Wrapper,
  InnerWrapper,
  TopArea,
  Header,
  MenuList,
  BottomSpacer,
} from './styled';
import MenuItem from './MenuItem';

type Props = {
  match: Match,
  history: RouterHistory,
  mainMenuOpen: boolean,
};

const Menu = ({ history, mainMenuOpen, match: { path } }: Props) => {
  const getMainMenuItems = () => {
    return {
      top: [
        {
          key: 'menu-item-dashboard',
          icon: <Icon type="circle-dashboard" size={29} />,
          title: i18n.t('Dashboard'),
          linkTo: '/dashboard',
          active: path === '/' || path.startsWith('/dashboard'),
        },
        {
          key: 'menu-item-reports',
          icon: <Icon type="circle-reports" size={29} />,
          title: i18n.t('Reports'),
          linkTo: '/reports',
          active: path === '/' || path.startsWith('/report'),
        },
        {
          key: 'menu-item-orders',
          icon: <Icon type="circle-orders" size={29} />,
          title: i18n.t('Orders'),
          linkTo: '/orders',
          active: path.startsWith('/order'),
        },
        {
          key: 'menu-item-jobs',
          icon: <Icon type="circle-jobs" size={29} />,
          title: i18n.t('Job Tickets'),
          linkTo: '/jobs',
          active: path.startsWith('/job'),
        },
        {
          key: 'menu-item-production-schedule',
          icon: (
            <Icon
              type="circle-production-schedule"
              style={{
                width: 29,
                height: 29,
              }}
            />
          ),
          title: i18n.t('Production Schedule'),
          linkTo: '/production-schedule',
          active: path.startsWith('/production-schedule'),
        },
        {
          key: 'menu-item-customers',
          icon: <Icon type="circle-customers" size={29} />,
          title: i18n.t('Customers'),
          linkTo: '/customers',
          active: path.startsWith('/customer'),
        },
        {
          key: 'menu-item-inventory',
          icon: <Icon type="circle-inventory" size={29} />,
          title: i18n.t('Inventory'),
          linkTo: '/items',
          active: path.startsWith('/item'),
        },
        {
          key: 'menu-item-lots',
          icon: <Icon type="circle-lot" size={29} />,
          title: i18n.t('Lots'),
          linkTo: '/lots',
          active: path.startsWith('/lot'),
        },
      ],
      bottom: [
        {
          key: 'menu-item-settings',
          icon: <Icon type="circle-settings" size={29} />,
          title: i18n.t('Settings'),
          linkTo: '/settings',
          active: path.startsWith('/settings'),
        },
      ],
    };
  };

  const handleMenuClickAway = (e: Event) => {
    if (mainMenuOpen && wrapperRef && !wrapperRef.contains(e.target)) {
      Actions.toggleMainMenu(false);
    }
  };

  const handleGoTo = (path: string, e: Event) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (mainMenuOpen) {
      Actions.toggleMainMenu(false);
    }

    setTimeout(() => {
      history.push(path);
    }, 0);
  };

  const renderMenuList = (list: Array<Object>, bottom: boolean = false) => {
    const whitelistedList =
      !config.blacklistedMenuItems || !config.blacklistedMenuItems.length
        ? list
        : list.filter(
            menuItem => !config.blacklistedMenuItems.includes(menuItem.key),
          );

    return (
      <MenuList bottom={bottom}>
        {whitelistedList.map((menuItemProps, i) => (
          <MenuItem
            onClick={e => handleGoTo(menuItemProps.linkTo, e)}
            {...menuItemProps}
          />
        ))}
      </MenuList>
    );
  };

  /**
   * By default, useEffect runs after every render
   * The returned value from useEffect is used to cleanup the effect, similar to componentWillUnmount
   * The second arg is an array of values that the effect depends on
   * If there are changes to those values between renders, then the effect will fire, similar to componentDidUpdate
   * If the second arg is an empty array, then the effect only fires once after layout and paint, sort of similar to componentDidMount
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleMenuClickAway);
    document.addEventListener('touchend', handleMenuClickAway);

    return () => {
      document.removeEventListener('mousedown', handleMenuClickAway);
      document.removeEventListener('touchend', handleMenuClickAway);
    };
  }, []);

  const menuItems = getMainMenuItems();
  const wrapperRef: any = useRef(null);

  return (
    <Wrapper show={mainMenuOpen} ref={wrapperRef}>
      <InnerWrapper>
        <TopArea>
          <Header>WorkClout</Header>
        </TopArea>
        {renderMenuList(menuItems.top)}
        {renderMenuList(menuItems.bottom, true)}
        <BottomSpacer />
      </InnerWrapper>
    </Wrapper>
  );
};

// $FlowFixMe
export default withRouter(
  connect(state => ({
    mainMenuOpen: state.mainMenuOpen,
  }))(Menu),
);

// @flow

import React from 'react';
import { DiagramEngine, AbstractLabelFactory } from 'storm-react-diagrams';
import { CustomLabelWidget, CustomLabelModel } from './index';

class CustomLabelFactory extends AbstractLabelFactory<CustomLabelModel> {
  constructor() {
    super('custom-label');
  }

  generateReactWidget = (
    diagramEngine: DiagramEngine,
    label: CustomLabelModel,
  ) => {
    return (
      <CustomLabelWidget
        labelModel={label}
        diagramEngine={diagramEngine}
        color={label.parent.color}
      />
    );
  };

  getNewInstance = (initialConfig?: any) => {
    return new CustomLabelModel();
  };
}

export default CustomLabelFactory;

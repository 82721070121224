/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AddUpdateOrderModal_order$ref = any;
type DeleteOrderModal_order$ref = any;
export type OrderStatus = "CLOSED" | "OPEN";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrdersTable_orderEdges$ref: FragmentReference;
declare export opaque type OrdersTable_orderEdges$fragmentType: OrdersTable_orderEdges$ref;
export type OrdersTable_orderEdges = $ReadOnlyArray<{|
  +node: {|
    +id: string,
    +orderNumber: string,
    +customerOrderNumber: ?string,
    +status: OrderStatus,
    +orderedAt: ?any,
    +requiredAt: ?any,
    +promisedAt: ?any,
    +totalJobs: number,
    +customer: {|
      +id: string,
      +name: string,
    |},
    +$fragmentRefs: AddUpdateOrderModal_order$ref & DeleteOrderModal_order$ref,
  |},
  +$refType: OrdersTable_orderEdges$ref,
|}>;
export type OrdersTable_orderEdges$data = OrdersTable_orderEdges;
export type OrdersTable_orderEdges$key = {
  +$data?: OrdersTable_orderEdges$data,
  +$fragmentRefs: OrdersTable_orderEdges$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrdersTable_orderEdges",
  "type": "OrderEdge",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "node",
      "storageKey": null,
      "args": null,
      "concreteType": "Order",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "orderNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "customerOrderNumber",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "orderedAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "requiredAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "promisedAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalJobs",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "customer",
          "storageKey": null,
          "args": null,
          "concreteType": "Customer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "AddUpdateOrderModal_order",
          "args": null
        },
        {
          "kind": "FragmentSpread",
          "name": "DeleteOrderModal_order",
          "args": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae114c593743ee542d3bf07d7beaaec4';
module.exports = node;

// @flow

import React from 'react';
import styled from 'styled-components';
import { i18n } from 'shared/utils';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  data: {
    quantity: number,
    quantityCompleted: number,
  },
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 24px;
  justify-content: center;

  @media (min-width: 1000px) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
`;

const MetricWrapper = styled.div`
  text-align: center;
  color: ${colors.charcoalGrey};
`;

const Metric = styled.div`
  ${fonts.metricBold};
  padding-bottom: 16px;
`;

const Label = styled.div`
  ${fonts.largeRegular};
  padding-bottom: 24px;
`;

export default ({ data }: Props) => (
  <Wrapper>
    <MetricWrapper>
      <Metric>
        {i18n.t('{{metric, number}}', { metric: data.quantityCompleted })}
      </Metric>
      <Label>{i18n.t('Total Quantity Produced')}</Label>
    </MetricWrapper>
    <MetricWrapper>
      <Metric>{i18n.t('{{metric, number}}', { metric: data.quantity })}</Metric>
      <Label>{i18n.t('Total Quantity To Produce')}</Label>
    </MetricWrapper>
    <MetricWrapper>
      <Metric>
        {i18n.t('{{percentage, number}}%', {
          percentage: Math.round(
            (data.quantityCompleted / data.quantity) * 100,
          ),
        })}
      </Metric>
      <Label>{i18n.t('Completion Rate')}</Label>
    </MetricWrapper>
  </Wrapper>
);

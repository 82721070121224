// @flow

import React from 'react';
import styled from 'styled-components';
import { colors } from 'shared/styleguide';

type Props = {
  children: React$Node,
  style?: Object,
};

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${colors.lightBlueGrey};
  background-color: ${colors.white};
  margin-bottom: 8px;
  height: 100%;
  overflow-y: auto;
  padding: 16px;

  @media (min-width: 1000px) {
    border-left-style: none;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 10px;
    margin-left: -25px;
    margin-right: 16px;
    width: 300px;
    min-width: 300px;
  }
`;

const SidePanel = ({ children, style }: Props) => (
  <Wrapper style={style}>{children}</Wrapper>
);

SidePanel.defaultProps = {
  style: {},
};

export default SidePanel;

// @flow

import {
  DiagramEngine,
  AbstractLinkFactory,
  // DefaultLinkWidget,
} from 'storm-react-diagrams';
import React from 'react';
import CustomLinkModel from './CustomLinkModel';
import CustomLinkWidget from './CustomLinkWidget';

class CustomLinkFactory extends AbstractLinkFactory {
  constructor(
    handleOpenDetailsTooltip: any => void,
    handleCloseDetailsTooltip: () => void,
  ) {
    super('custom-link');
    this.handleOpenDetailsTooltip = handleOpenDetailsTooltip;
    this.handleCloseDetailsTooltip = handleCloseDetailsTooltip;
  }

  generateReactWidget = (
    diagramEngine: DiagramEngine,
    link: CustomLinkModel,
  ) => {
    return React.createElement(CustomLinkWidget, {
      link: link,
      diagramEngine: diagramEngine,
      handleOpenDetailsTooltip: this.handleOpenDetailsTooltip,
      handleCloseDetailsTooltip: this.handleCloseDetailsTooltip,
    });
  };

  getNewInstance = (initialConfig?: any) => {
    return new CustomLinkModel();
  };

  generateLinkSegment = (
    model: *,
    widget: *,
    selected: boolean,
    path: string,
  ) => {
    const color = widget.props.link?.color || 'gray';
    return (
      <path
        className={selected ? widget.bem('--path-selected') : ''}
        strokeWidth={model.width}
        stroke={color}
        d={path}
      />
    );
  };
}

export default CustomLinkFactory;

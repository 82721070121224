// @flow

import React, { PureComponent } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import { i18n } from 'shared/utils';

type Props = {
  oee: number,
  machineAvailabilityRate: number,
  machinePerformanceRate: number,
  qualityRate: number,
};

type State = {
  width: number,
  height: number,
};

const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: calc(100vw - 355px);
`;

const Cell = styled.div`
  text-align: center;
  width: 100%;
  header {
    ${fonts.bodyBold};
    color: ${colors.charcoalGrey};
    min-height: 4em;
    padding: 0 0 16px 16px;
  }
  @media (min-width: 1000px) {
    width: 22%;
  }
`;

class OEE extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const clientWidth = document.body.clientWidth;
    let meterWidth;
    if (clientWidth + 15 < 1000) {
      meterWidth = clientWidth * 0.4;
    } else {
      meterWidth = (clientWidth - 235) * 0.22;
    }
    const meterHeight = meterWidth * 0.7;

    this.state = {
      width: meterWidth,
      height: meterHeight,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const clientWidth = document.body.clientWidth;
    let meterWidth;

    if (clientWidth + 15 < 1000) {
      meterWidth = clientWidth * 0.4;
    } else {
      meterWidth = (clientWidth - 235) * 0.22;
    }

    this.setState({ width: meterWidth, height: meterWidth * 0.7 });
  };

  render = () => {
    const {
      oee,
      machineAvailabilityRate,
      machinePerformanceRate,
      qualityRate,
    } = this.props;
    const { width: meterWidth, height: meterHeight } = this.state;
    return (
      <Grid>
        <Cell>
          <header>{i18n.t('Overall Equipment Efficiency')}</header>
          <ReactSpeedometer
            width={meterWidth}
            height={meterHeight}
            forceRender
            minValue={0}
            maxValue={100}
            value={oee}
            endColor={colors.greenBlue}
            needleColor={colors.cobalt}
            currentValueText={`${oee}%`}
          />
        </Cell>
        <Cell>
          <header>{i18n.t('Work Center Availability Rate')}</header>
          <ReactSpeedometer
            width={meterWidth}
            height={meterHeight}
            forceRender
            minValue={0}
            maxValue={100}
            value={machineAvailabilityRate}
            startColor={colors.coolGrey}
            endColor={colors.paleGreyThree}
            needleColor={colors.cobalt}
            currentValueText={`${machineAvailabilityRate}%`}
          />
        </Cell>
        <Cell>
          <header>{i18n.t('Work Center Performance Rate')}</header>
          <ReactSpeedometer
            width={meterWidth}
            height={meterHeight}
            forceRender
            minValue={0}
            maxValue={100}
            value={machinePerformanceRate}
            startColor={colors.coolGrey}
            endColor={colors.paleGreyThree}
            needleColor={colors.cobalt}
            currentValueText={`${machinePerformanceRate}%`}
          />
        </Cell>
        <Cell>
          <header>{i18n.t('Quality Rate')}</header>
          <ReactSpeedometer
            width={meterWidth}
            height={meterHeight}
            forceRender
            minValue={0}
            maxValue={100}
            value={qualityRate}
            startColor={colors.coolGrey}
            endColor={colors.paleGreyThree}
            needleColor={colors.cobalt}
            currentValueText={`${qualityRate}%`}
          />
        </Cell>
      </Grid>
    );
  };
}

export default OEE;

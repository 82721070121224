/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type JobStateStatus = "COMPLETE" | "INCOMPLETE" | "IN_PROGRESS" | "NEEDS_ATTENTION" | "PAUSED";
export type RunStatus = "COMPLETE" | "IN_PROGRESS" | "PAUSED" | "SCHEDULED";
export type WorkflowStateType = "JOB_COMPLETED" | "JOB_CREATED" | "JOB_ITEM_COUNT" | "RUN" | "TASK";
import type { FragmentReference } from "relay-runtime";
declare export opaque type WorkflowDiagram_job$ref: FragmentReference;
declare export opaque type WorkflowDiagram_job$fragmentType: WorkflowDiagram_job$ref;
export type WorkflowDiagram_job = {|
  +id: string,
  +states: $ReadOnlyArray<{|
    +id: string,
    +status: JobStateStatus,
    +user: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
    |},
    +runs: $ReadOnlyArray<{|
      +id: string,
      +status: RunStatus,
    |}>,
    +workflowState: {|
      +id: string,
      +type: ?WorkflowStateType,
      +name: string,
      +isRequired: ?boolean,
      +runMinutesOverride: ?number,
      +form: ?{|
        +id: string,
        +name: string,
      |},
    |},
    +isEnabled: boolean,
    +updatedAt: any,
    +createdAt: any,
  |}>,
  +workflow: {|
    +id: string,
    +transitions: $ReadOnlyArray<{|
      +id: string,
      +toWorkflowState: {|
        +id: string
      |},
      +fromWorkflowState: {|
        +id: string
      |},
    |}>,
  |},
  +$refType: WorkflowDiagram_job$ref,
|};
export type WorkflowDiagram_job$data = WorkflowDiagram_job;
export type WorkflowDiagram_job$key = {
  +$data?: WorkflowDiagram_job$data,
  +$fragmentRefs: WorkflowDiagram_job$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "WorkflowDiagram_job",
  "type": "Job",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "states",
      "storageKey": null,
      "args": null,
      "concreteType": "JobState",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "runs",
          "storageKey": null,
          "args": null,
          "concreteType": "Run",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "workflowState",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowState",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "type",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isRequired",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "runMinutesOverride",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "form",
              "storageKey": null,
              "args": null,
              "concreteType": "Form",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "updatedAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflow",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "transitions",
          "storageKey": null,
          "args": null,
          "concreteType": "WorkflowTransition",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "toWorkflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "fromWorkflowState",
              "storageKey": null,
              "args": null,
              "concreteType": "WorkflowState",
              "plural": false,
              "selections": (v3/*: any*/)
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '51cf93e8595ebdf82e93e3a694956af7';
module.exports = node;

// @flow

import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { i18n } from 'shared/utils';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';
import {
  computeValidationSchema,
  computeFieldValues,
  DynamicFormField,
} from 'shared/components/form/FormBuilder';
import { Formik } from 'shared/components/form';
import type { FormPreview_form as FormFragment } from './__generated__/FormPreview_form';

type Props = {
  form: FormFragment,
};

const Wrapper = styled.div`
  display: none; /* don't show on mobile */
  position: fixed;
  box-shadow: -5px 0 10px 0 rgba(66, 68, 76, 0.1);
  background-color: ${colors.white};
  width: 320px;
  padding: 16px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  top: 128px;
  right: 0;
  bottom: 48px;
  overflow-y: auto;

  @media (min-width: 1000px) {
    display: block;
  }
`;

const Header = styled.div`
  ${fonts.largeBold};
  color: ${colors.charcoalGrey};
  padding-bottom: 16px;
  border-bottom: 2px solid ${colors.paleGrey};
  margin-bottom: 16px;
`;

const FormPreview = ({ form }: Props) => (
  <Wrapper>
    <Header>{i18n.t('Form Preview')}</Header>
    <Formik
      enableReinitialize
      initialValues={computeFieldValues(form.fields, {}, true)}
      validationSchema={computeValidationSchema(form.fields)}
      onSubmit={() => {}}
      render={() =>
        form.fields.map(formField => (
          <DynamicFormField
            key={formField.id}
            form={form}
            formField={formField}
          />
        ))
      }
    />
  </Wrapper>
);

export default createFragmentContainer(FormPreview, {
  form: graphql`
    fragment FormPreview_form on Form {
      id
      ...FormBuilder_form @relay(mask: false)
      fields {
        id
      }
    }
  `,
});

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RunControls_run$ref = any;
type RunEndModal_run$ref = any;
type RunPauseModal_run$ref = any;
type RunStartModal_run$ref = any;
type SetupEndModal_run$ref = any;
type SetupPauseModal_run$ref = any;
type SetupStartModal_run$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type OperatorControls_run$ref: FragmentReference;
declare export opaque type OperatorControls_run$fragmentType: OperatorControls_run$ref;
export type OperatorControls_run = {|
  +id: string,
  +$fragmentRefs: RunControls_run$ref & SetupStartModal_run$ref & SetupPauseModal_run$ref & SetupEndModal_run$ref & RunStartModal_run$ref & RunPauseModal_run$ref & RunEndModal_run$ref,
  +$refType: OperatorControls_run$ref,
|};
export type OperatorControls_run$data = OperatorControls_run;
export type OperatorControls_run$key = {
  +$data?: OperatorControls_run$data,
  +$fragmentRefs: OperatorControls_run$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "OperatorControls_run",
  "type": "Run",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RunControls_run",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SetupStartModal_run",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SetupPauseModal_run",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "SetupEndModal_run",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RunStartModal_run",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RunPauseModal_run",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "RunEndModal_run",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '07ac459c999296800f671abb3d5d2074';
module.exports = node;

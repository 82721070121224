// @flow

import React from 'react';
import moment from 'moment';
import {
  SelectCustomerField,
  SelectUserField,
  SelectOrderField,
  SelectItemCategoryField,
} from 'shared/components/form';
import UserLabel from 'shared/components/common/QueryWidget/UserLabel';
import CustomerLabel from 'shared/components/common/QueryWidget/CustomerLabel';
import ItemCategoryLabel from 'shared/components/common/QueryWidget/ItemCategoryLabel';
import OrderLabel from 'shared/components/common/QueryWidget/OrderLabel';

type SpecialFields = {
  [key: string]: Object,
};

const SpecialFieldsMap: SpecialFields = {
  'date-range': {
    cosmeticValue: ({ value }: *) => {
      const [startDate, endDate] = value.split(' - ');

      return (
        <span>
          {[
            startDate ? moment(startDate).format('MM/DD/YYYY') : '',
            endDate ? moment(endDate).format('MM/DD/YYYY') : '',
          ].join(' - ')}
        </span>
      );
    },
  },
  order: {
    component: SelectOrderField,
    cosmeticValue: ({ value }: *) => <OrderLabel orderId={value} />,
  },
  user: {
    component: SelectUserField,
    cosmeticValue: ({ value }: *) => <UserLabel userId={value} />,
  },
  customer: {
    component: SelectCustomerField,
    cosmeticValue: ({ value }: *) => <CustomerLabel customerId={value} />,
  },
  'item-category': {
    component: SelectItemCategoryField,
    cosmeticValue: ({ value }: *) => (
      <ItemCategoryLabel itemCategoryId={value} />
    ),
  },
};

export default SpecialFieldsMap;

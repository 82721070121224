// @flow

import { graphql } from 'react-relay';
import createRelayMutation from 'shared/gql/createRelayMutation';

type MutationOptions = {
  variables: {
    input: {
      integrationId: string,
    },
  },
};

const mutation = graphql`
  mutation RevokeOauthAccessMutation($input: RevokeOauthAccessInput!) {
    revokeOauthAccess(input: $input) {
      success
    }
  }
`;

export default {
  commit: (options: MutationOptions) => {
    return createRelayMutation(mutation, {
      ...options,
    });
  },
};

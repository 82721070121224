// @flow

import React, { PureComponent } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import type { Match } from 'react-router';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import { i18n } from 'shared/utils';
import relayEnvironment from 'shared/gql/relayEnvironment';
import Loader from 'shared/components/common/Loader';
import Table from 'shared/components/common/Table';
import Icon from 'shared/components/common/Icon';
import Button from 'shared/components/common/Button';
import PageHeader from 'main-app/components/PageHeader';
import IntegrationForm from './IntegrationForm';
import AddUpdateIntegrationEventModal from './AddUpdateIntegrationEventModal';
import DeleteIntegrationEventModal from './DeleteIntegrationEventModal';

type Props = {
  match: {
    ...Match,
    params: {
      id: string,
    },
  },
};

type State = {
  addEventModalOpen: boolean,
  editEventRecord: ?Object,
  deleteEventRecord: ?Object,
};

class Integration extends PureComponent<Props, State> {
  menuTriggerRefs = {};

  state = {
    addEventModalOpen: false,
    editEventRecord: null,
    deleteEventRecord: null,
  };

  getEventById = (integrationEventId: string, eventEdges: Object[]) => {
    const record = eventEdges.find(edge => {
      return edge?.node?.id === integrationEventId;
    });

    return record?.node || null;
  };

  handleEditEvent = (integrationEventId: string, eventEdges: Object[]) => {
    const integrationEvent = this.getEventById(integrationEventId, eventEdges);

    this.setState({
      editEventRecord: integrationEvent,
    });
  };

  handleDeleteEvent = (integrationEventId: string, eventEdges: Object[]) => {
    const integrationEvent = this.getEventById(integrationEventId, eventEdges);

    this.setState({
      deleteEventRecord: integrationEvent,
    });
  };

  handleOpenCreateModal = () => {
    this.setState({
      addEventModalOpen: true,
    });
  };

  handleCloseAllModals = () => {
    this.setState({
      addEventModalOpen: false,
      editEventRecord: null,
      deleteEventRecord: null,
    });
  };

  render() {
    const { match } = this.props;
    const {
      addEventModalOpen,
      editEventRecord,
      deleteEventRecord,
    } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        dataFrom="STORE_THEN_NETWORK"
        variables={{
          id: match.params.id,
        }}
        query={graphql`
          query IntegrationQuery($id: ID!) {
            integration(id: $id) {
              id
              source {
                id
                name
              }
              events(first: null)
                @connection(key: "Integration_events", filters: []) {
                edges {
                  node {
                    id
                    eventType {
                      id
                      cosmeticName
                    }
                    originRule
                    ...AddUpdateIntegrationEventModal_integrationEvent
                    ...DeleteIntegrationEventModal_integrationEvent
                  }
                }
              }
              ...IntegrationForm_integration
            }
          }
        `}
        render={({ props }: *) => {
          if (!props) {
            return <Loader />;
          }

          return (
            <>
              <PageHeader
                intro={i18n.t('INTEGRATION')}
                title={props.integration.source.name}
              />
              <IntegrationForm integration={props.integration} />
              <PageHeader
                title={i18n.t('Events')}
                style={{ paddingTop: 24 }}
                right={
                  <Button
                    onClick={this.handleOpenCreateModal}
                    theme="border-white"
                  >
                    {i18n.t('Add Event')}
                  </Button>
                }
              />
              <Table
                data={props.integration.events.edges.map(edge => edge.node)}
                columns={[
                  {
                    Header: 'Event',
                    accessor: 'eventType.cosmeticName',
                  },
                  {
                    Header: 'Origin Rule',
                    accessor: 'originRule',
                    width: 160,
                  },
                  {
                    Header: '',
                    accessor: 'id',
                    width: 48,
                    enforceWidth: true,
                    Cell: ({ value }) => (
                      <ContextMenuTrigger
                        ref={r => (this.menuTriggerRefs[value] = r)}
                        id="settings-integration-event-menu"
                        attributes={{
                          'integration-event-id': value,
                        }}
                      >
                        <Icon
                          type="circle-context-menu"
                          size={24}
                          onClick={e => {
                            if (this.menuTriggerRefs[value]) {
                              this.menuTriggerRefs[value].handleContextClick(e);
                            }
                          }}
                        />
                      </ContextMenuTrigger>
                    ),
                  },
                ]}
              />
              <ContextMenu id="settings-integration-event-menu">
                <MenuItem
                  onClick={(e, data, target) => {
                    this.handleEditEvent(
                      target.getAttribute('integration-event-id'),
                      props.integration.events.edges,
                    );
                  }}
                >
                  {i18n.t('Edit Event')}
                </MenuItem>
                <MenuItem
                  onClick={(e, data, target) => {
                    this.handleDeleteEvent(
                      target.getAttribute('integration-event-id'),
                      props.integration.events.edges,
                    );
                  }}
                >
                  {i18n.t('Delete Event')}
                </MenuItem>
              </ContextMenu>
              {addEventModalOpen && (
                <AddUpdateIntegrationEventModal
                  integrationEvent={null}
                  integrationId={props.integration.id}
                  onClose={this.handleCloseAllModals}
                />
              )}
              {editEventRecord && (
                <AddUpdateIntegrationEventModal
                  integrationEvent={editEventRecord}
                  integrationId={props.integration.id}
                  onClose={this.handleCloseAllModals}
                />
              )}
              {deleteEventRecord && (
                <DeleteIntegrationEventModal
                  integrationEvent={deleteEventRecord}
                  integrationId={props.integration.id}
                  onClose={this.handleCloseAllModals}
                />
              )}
            </>
          );
        }}
      />
    );
  }
}

export default Integration;

/**
 * @flow
 * @relayHash 2b66ccd74de0cf266a2a6e88494a521c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PaginatedCustomersContainer_customers$ref = any;
export type CustomerSortField = "CREATED_AT" | "CUSTOMER_NUMBER" | "NAME" | "UPDATED_AT";
export type Permission = "CREATE_API_KEY" | "CREATE_CUSTOMER" | "CREATE_ITEM" | "CREATE_JOB" | "CREATE_LOT" | "CREATE_ORDER" | "DELETE_API_KEY" | "DELETE_CUSTOMER" | "DELETE_ITEM" | "DELETE_JOB" | "DELETE_LOT" | "DELETE_ORDER" | "READ_API_KEY" | "READ_CUSTOMER" | "READ_ITEM" | "READ_JOB" | "READ_LOT" | "READ_ORDER" | "UPDATE_API_KEY" | "UPDATE_CUSTOMER" | "UPDATE_ITEM" | "UPDATE_JOB" | "UPDATE_LOT" | "UPDATE_ORDER";
export type SortDirection = "ASC" | "DESC";
export type CustomerSort = {|
  field: CustomerSortField,
  direction: SortDirection,
|};
export type CustomersQueryVariables = {|
  count: number,
  cursor?: ?string,
  search?: ?string,
  sortBy?: ?CustomerSort,
  customerNumbers?: ?$ReadOnlyArray<string>,
  salesUserIds?: ?$ReadOnlyArray<string>,
|};
export type CustomersQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +permissions: $ReadOnlyArray<Permission>,
  |},
  +$fragmentRefs: PaginatedCustomersContainer_customers$ref,
|};
export type CustomersQuery = {|
  variables: CustomersQueryVariables,
  response: CustomersQueryResponse,
|};
*/


/*
query CustomersQuery(
  $search: String
  $sortBy: CustomerSort
  $customerNumbers: [String!]
  $salesUserIds: [ID!]
) {
  ...PaginatedCustomersContainer_customers_4Aw28E
  viewer {
    id
    permissions
  }
}

fragment PaginatedCustomersContainer_customers_4Aw28E on Query {
  customers(first: 30, search: $search, sortBy: $sortBy, customerNumbers: $customerNumbers, salesUserIds: $salesUserIds) {
    edges {
      cursor
      ...CustomerList_customerEdges
      node {
        __typename
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment CustomerList_customerEdges on CustomerEdge {
  node {
    id
    customerNumber
    name
    openOrders
    salesUser {
      ...UserAvatar_user
      id
    }
    ...AddUpdateCustomerModal_customer
  }
}

fragment UserAvatar_user on User {
  id
  avatar
  firstName
}

fragment AddUpdateCustomerModal_customer on Customer {
  id
  name
  primaryContactName
  secondaryContactName
  shippingAddressLine1
  shippingAddressLine2
  shippingAddressLine3
  shippingAddressCity
  shippingAddressState
  shippingAddressZip
  billingAddressLine1
  billingAddressLine2
  billingAddressLine3
  billingAddressCity
  billingAddressState
  billingAddressZip
  email
  phone
  phone2
  fax
  notes
  salesUser {
    id
    firstName
    lastName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sortBy",
    "type": "CustomerSort",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customerNumbers",
    "type": "[String!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "salesUserIds",
    "type": "[ID!]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "viewer",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "Variable",
  "name": "customerNumbers",
  "variableName": "customerNumbers"
},
v4 = {
  "kind": "Variable",
  "name": "salesUserIds",
  "variableName": "salesUserIds"
},
v5 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v6 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v7 = [
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CustomersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v2/*: any*/),
      {
        "kind": "FragmentSpread",
        "name": "PaginatedCustomersContainer_customers",
        "args": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CustomersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "customers",
        "storageKey": null,
        "args": (v7/*: any*/),
        "concreteType": "CustomerConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Customer",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "customerNumber",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "openOrders",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "salesUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "avatar",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "primaryContactName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "secondaryContactName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine1",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine2",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressLine3",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressCity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressState",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "shippingAddressZip",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressLine1",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressLine2",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressLine3",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressCity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressState",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "billingAddressZip",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "phone",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "phone2",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fax",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "customers",
        "args": (v7/*: any*/),
        "handle": "connection",
        "key": "PaginatedCustomersContainer_customers",
        "filters": []
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CustomersQuery",
    "id": null,
    "text": "query CustomersQuery(\n  $search: String\n  $sortBy: CustomerSort\n  $customerNumbers: [String!]\n  $salesUserIds: [ID!]\n) {\n  ...PaginatedCustomersContainer_customers_4Aw28E\n  viewer {\n    id\n    permissions\n  }\n}\n\nfragment PaginatedCustomersContainer_customers_4Aw28E on Query {\n  customers(first: 30, search: $search, sortBy: $sortBy, customerNumbers: $customerNumbers, salesUserIds: $salesUserIds) {\n    edges {\n      cursor\n      ...CustomerList_customerEdges\n      node {\n        __typename\n        id\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CustomerList_customerEdges on CustomerEdge {\n  node {\n    id\n    customerNumber\n    name\n    openOrders\n    salesUser {\n      ...UserAvatar_user\n      id\n    }\n    ...AddUpdateCustomerModal_customer\n  }\n}\n\nfragment UserAvatar_user on User {\n  id\n  avatar\n  firstName\n}\n\nfragment AddUpdateCustomerModal_customer on Customer {\n  id\n  name\n  primaryContactName\n  secondaryContactName\n  shippingAddressLine1\n  shippingAddressLine2\n  shippingAddressLine3\n  shippingAddressCity\n  shippingAddressState\n  shippingAddressZip\n  billingAddressLine1\n  billingAddressLine2\n  billingAddressLine3\n  billingAddressCity\n  billingAddressState\n  billingAddressZip\n  email\n  phone\n  phone2\n  fax\n  notes\n  salesUser {\n    id\n    firstName\n    lastName\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3f9c03522e7c8b6f373317d2d5e9a183';
module.exports = node;

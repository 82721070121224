// @flow

/* eslint-disable react/forbid-component-props */

import React, { PureComponent, createRef } from 'react';
import moment from 'moment';
import type Moment from 'moment';
import Timeline, {
  TimelineMarkers,
  TodayMarker,
  TimelineHeaders,
  DateHeader,
  SidebarHeader,
} from 'react-calendar-timeline';
import {
  getSumOffset,
  getSumScroll,
} from 'react-calendar-timeline/lib/lib/utility/dom-helpers';
import { coordinateToTimeRatio } from 'react-calendar-timeline/lib/lib/utility/calendar';
import AddUpdateRunModal from 'main-app/components/AddUpdateRunModal';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { fonts } from 'shared/styleguide';
import {
  MachineWrapper,
  renderRun,
  ChartControlsList,
  // LeftIcon,
  // RightIcon,
  PlusIcon,
  MinusIcon,
  SidebarHeaderWrapper,
} from '../styled';

export type Props = {
  onEventMove: (string, number, number) => void,
  onEventResize: (string, number, 'left' | 'right') => void,
  onVisibleTimeChange: () => void,
  onCloseAllModals: () => void,
  onRunSelect: Object => void,
  onRunDeselect: () => void,
  onRunDoubleClick: string => void,
  initialStartTime: moment$Moment,
  initialEndTime: moment$Moment,
  machines: Array<Object>,
  scheduleEvents: Array<Object>,
  selectedJobId: ?string,
  addRunModalProps: ?{
    job: Object,
    defaultMachine: Object,
    defaultRunAt: number,
    onSuccess: () => void,
  },
  onItemDrag: ({
    eventType: 'move' | 'resize',
    itemId: any,
    time: number,
    edge: 'left' | 'right',
    newGroupOrder: number,
  }) => void,
  onMouseEnterRun: (run: Object) => void,
  onMouseLeaveRun: () => void,
};

const Header = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 4px;
  ${fonts.bodyBold}
`;

class ChartView extends PureComponent<Props> {
  timelineRef: any = createRef();
  scrollRef: any = createRef();

  getEventById = (eventId: string) => {
    const { scheduleEvents } = this.props;

    return scheduleEvents.find(event => event.id === eventId);
  };

  handleEventSelect = (eventId: string) => {
    const { onRunSelect } = this.props;
    const event = this.getEventById(eventId);

    if (event && event.type !== 'machineDowntime') {
      onRunSelect(event);
    }
  };

  handleEventDeselect = () => {
    const { onRunDeselect } = this.props;
    onRunDeselect();
  };

  handleEventDoubleClick = (eventId: string) => {
    const { onRunDoubleClick } = this.props;
    const event = this.getEventById(eventId);

    if (event && event.type !== 'machineDowntime') {
      onRunDoubleClick(event);
    }
  };

  computeDropParams = (pageX: number, pageY: number) => {
    const { machines } = this.props;
    const {
      canvasTimeStart,
      visibleTimeStart,
      visibleTimeEnd,
      groupTops,
      width,
    } = this.timelineRef.state;
    const canvasWidth = width * 3;
    const zoom = visibleTimeEnd - visibleTimeStart;
    const canvasTimeEnd = zoom * 3 + canvasTimeStart;
    const ratio = coordinateToTimeRatio(
      canvasTimeStart,
      canvasTimeEnd,
      canvasWidth,
    );
    const { offsetTop, offsetLeft } = getSumOffset(this.scrollRef);
    const { scrollTop, scrollLeft } = getSumScroll(this.scrollRef);

    const x = pageX - offsetLeft + scrollLeft;
    const y = pageY - offsetTop + scrollTop;

    const start = x * ratio + canvasTimeStart;
    const end = start + 1000 * 60 * 60 * 24;

    let machineId = machines[0].id;

    for (const key of Object.keys(groupTops)) {
      const groupTop = groupTops[key];
      if (y > groupTop) {
        machineId = machines[parseInt(key)].id;
      } else {
        break;
      }
    }

    return {
      start,
      end,
      machineId,
    };
  };

  handleZoomIn = () => {
    if (this.timelineRef) {
      this.timelineRef.changeZoom(0.5);
    }
  };

  handleZoomOut = () => {
    if (this.timelineRef) {
      this.timelineRef.changeZoom(3);
    }
  };

  formatLabelPrimary = (
    interval: [Moment, Moment],
    unit: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year',
    labelWidth: number,
  ): string => {
    const [startTime] = interval;
    if (unit === 'year') {
      return startTime.format('YYYY');
    }
    if (unit === 'month') {
      return startTime.format('MMMM YYYY');
    }
    if (unit === 'day') {
      return startTime.format('dddd, LL');
    }
    if (unit === 'hour') {
      return startTime.format('dddd, LL, h A');
    }
    return startTime.format('MM/dd');
  };

  formatLabelSecondary = (
    interval: [Moment, Moment],
    unit: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year',
    labelWidth: number,
  ): string => {
    const [startTime] = interval;
    if (unit === 'year') {
      return startTime.format('YYYY');
    }
    if (unit === 'month') {
      return startTime.format('MMM');
    }
    if (unit === 'day') {
      return startTime.format('D');
    }
    if (unit === 'hour') {
      return startTime.format('h A');
    }
    if (unit === 'minute') {
      if (labelWidth >= 40) return startTime.format('h:mm A');
    }
    return startTime.format('mm');
  };

  render() {
    const {
      onEventMove,
      onEventResize,
      onVisibleTimeChange,
      onCloseAllModals,
      initialStartTime,
      initialEndTime,
      machines,
      scheduleEvents,
      addRunModalProps,
      selectedJobId,
      onItemDrag,
      onMouseEnterRun,
      onMouseLeaveRun,
    } = this.props;

    return (
      <>
        <div style={{ flex: 1 }}>
          <Timeline
            ref={r => (this.timelineRef = r)}
            scrollRef={r => (this.scrollRef = r)}
            groups={machines}
            items={scheduleEvents.map(event => ({
              ...event,
              canResizeLeft: false,
              canResize: event.type === 'RUN_SCHEDULED',
              canMove: event.type === 'RUN_SCHEDULED',
              startAt: moment(event.startAt),
              endAt: moment(event.endAt),
              handleMouseEnter: onMouseEnterRun,
              handleMouseLeave: onMouseLeaveRun,
              selectedJobId,
            }))}
            groupRenderer={({ group }) => (
              <MachineWrapper isDown={Boolean(group.currentDowntime)}>
                <Header data-for={group.id} data-tip>
                  {group.title}
                </Header>
                {group.title.length >= 20 && (
                  <ReactTooltip
                    id={group.id}
                    place="top"
                    type="info"
                    effect="solid"
                  >
                    {group.title}
                  </ReactTooltip>
                )}
                <span>{group.type.name.toUpperCase()}</span>
              </MachineWrapper>
            )}
            sidebarContent={<div />}
            sidebarWidth={200}
            itemsSorted
            stackItems
            lineHeight={50}
            itemHeightRatio={0.85}
            showCursorLine
            maxZoom={31 * 24 * 60 * 60 * 1000} // 1 month
            onItemMove={onEventMove}
            onItemResize={onEventResize}
            onItemSelect={this.handleEventSelect}
            onItemDoubleClick={this.handleEventDoubleClick}
            onCanvasClick={this.handleEventDeselect}
            onBoundsChange={onVisibleTimeChange}
            defaultTimeStart={initialStartTime}
            defaultTimeEnd={initialEndTime}
            itemRenderer={renderRun}
            keys={{
              groupIdKey: 'id',
              groupTitleKey: 'title',
              groupRightTitleKey: 'rightTitle',
              groupLabelKey: 'title', // key for what to show in `InfoLabel`
              itemIdKey: 'id',
              itemTitleKey: 'name', // key for item div content
              itemDivTitleKey: 'name', // key for item div title (<div title="text"/>)
              itemGroupKey: 'machineId',
              itemTimeStartKey: 'startAt',
              itemTimeEndKey: 'endAt',
            }}
            timeSteps={{
              second: 0,
              minute: 5,
              hour: 1,
              day: 1,
              month: 1,
              year: 1,
            }}
            onItemDrag={onItemDrag}
          >
            <TimelineHeaders className="sticky">
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <SidebarHeaderWrapper {...getRootProps()}>
                      <ChartControlsList>
                        <li>
                          <MinusIcon onClick={this.handleZoomOut} />
                        </li>
                        <li>
                          <PlusIcon onClick={this.handleZoomIn} />
                        </li>
                      </ChartControlsList>
                    </SidebarHeaderWrapper>
                  );
                }}
              </SidebarHeader>
              <DateHeader
                labelFormat={this.formatLabelPrimary}
                className="primary-header"
                height={35}
                unit="primaryHeader"
              />
              <DateHeader labelFormat={this.formatLabelSecondary} height={35} />
            </TimelineHeaders>
            <TimelineMarkers>
              <TodayMarker />
            </TimelineMarkers>
          </Timeline>
        </div>
        {addRunModalProps && (
          <AddUpdateRunModal
            {...addRunModalProps}
            run={null}
            onClose={onCloseAllModals}
          />
        )}
      </>
    );
  }
}

export default ChartView;

// @flow

import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

export const Wrapper = styled.div``;

export const InnerWrapper = styled.div`
  padding-bottom: 16px;

  @media (min-width: 1000px) {
    padding-bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
`;

export const FilterSummary = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-bottom: 8px;

  span {
    ${fonts.bodyBold};
    color: ${colors.charcoalGrey};
  }

  @media (min-width: 1000px) {
    ${fonts.largeBold};

    span:hover {
      cursor: pointer;
    }
  }
`;

export const FilterSummaryLabel = styled.label`
  ${fonts.bodyRegular};
  color: ${colors.coolGrey};
  padding-right: 8px;

  @media (min-width: 1000px) {
    ${fonts.largeRegular};
    padding: 0 8px 0 16px;
  }
`;

export const SavedFilterSelection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SaveButtonWrapper = styled.div`
  /* Saved filters are a desktop-only feature */
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

export const DesktopAppliedFiltersListWrapper = styled.div`
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`;

/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddUpdateMachineModal_machine$ref: FragmentReference;
declare export opaque type AddUpdateMachineModal_machine$fragmentType: AddUpdateMachineModal_machine$ref;
export type AddUpdateMachineModal_machine = {|
  +id: string,
  +name: string,
  +type: {|
    +id: string,
    +name: string,
  |},
  +purchaseCost: ?number,
  +lifespanYears: ?number,
  +runQuantityPerHour: ?number,
  +runCostPerHour: ?number,
  +workflows: $ReadOnlyArray<{|
    +id: string,
    +name: string,
  |}>,
  +$refType: AddUpdateMachineModal_machine$ref,
|};
export type AddUpdateMachineModal_machine$data = AddUpdateMachineModal_machine;
export type AddUpdateMachineModal_machine$key = {
  +$data?: AddUpdateMachineModal_machine$data,
  +$fragmentRefs: AddUpdateMachineModal_machine$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "AddUpdateMachineModal_machine",
  "type": "Machine",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "type",
      "storageKey": null,
      "args": null,
      "concreteType": "MachineType",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "purchaseCost",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lifespanYears",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "runQuantityPerHour",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "runCostPerHour",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "workflows",
      "storageKey": null,
      "args": null,
      "concreteType": "Workflow",
      "plural": true,
      "selections": (v2/*: any*/)
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8a772c5d2307a70ca7a81262c12b88d';
module.exports = node;

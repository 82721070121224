// @flow

import React from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'shared/styleguide';

type Props = {
  title: string,
  content: React$Node,
};

const Title = styled.div`
  ${fonts.smallRegular};
  color: ${colors.battleshipGrey};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding-bottom: 6px;
  white-space: nowrap;

  @media (min-width: 1000px) {
    padding-right: 0;
  }
`;

const Content = styled.div`
  ${fonts.bodyBold};
  color: ${colors.charcoalGrey};
  min-height: 30px;
`;

export default ({ title, content }: Props) => (
  <div>
    <Title>{title}</Title>
    <Content>{content}</Content>
  </div>
);
